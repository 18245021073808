const ACTION = {
  LOAD_PLACEMENT_REQ: "LOAD_PLACEMENT_REQ",
  LOAD_PLACEMENT_SUCCESS: "LOAD_PLACEMENT_SUCCESS",
  LOAD_PLACEMENT_FAILURE: "LOAD_PLACEMENT_FAILURE",

  LOAD_TIMESHEET_SETTINGS_REQ: "LOAD_TIMESHEET_SETTINGS_REQ",
  LOAD_TIMESHEET_SETTINGS_SUCCESS: "LOAD_TIMESHEET_SETTINGS_SUCCESS",
  LOAD_TIMESHEET_SETTINGS_FAILURE: "LOAD_TIMESHEET_SETTINGS_FAILURE",

  LOAD_EXISTING_TIMESHEET_DATA: "LOAD_EXISTING_TIMESHEET_DATA",

  APPROVE_TIMESHEET_SUBMIT_STATUS: "APPROVE_TIMESHEET_SUBMIT_STATUS",

  NOTIFY_DEFAULTER_REQ: "NOTIFY_DEFAULTER_REQ",
  NOTIFY_DEFAULTER_SUCCESS: "NOTIFY_DEFAULTER_SUCCESS",
  NOTIFY_DEFAULTER_FAILURE: "NOTIFY_DEFAULTER_FAILURE",

  LOAD_TIMESHEETS_LIST_REQ: "LOAD_TIMESHEETS_LIST_REQ",
  LOAD_TIMESHEETS_LIST_SUCCESS: "LOAD_TIMESHEETS_LIST_SUCCESS",
  LOAD_TIMESHEETS_LIST_FAILURE: "LOAD_TIMESHEETS_LIST_FAILURE",

  LOAD_COLUMN_DETAILS_REQ: "LOAD_COLUMN_DETAILS_REQ",
  LOAD_COLUMN_DETAILS_SUCCESS: "LOAD_COLUMN_DETAILS_SUCCESS",
  LOAD_COLUMN_DETAILS_FAILURE: "LOAD_COLUMN_DETAILS_FAILURE",

}

export default ACTION
