export default {
  modulesData: [],
  isLoaded: false,
  isEmpty: false,
  error: null,

  update_succes_msg: "",
  update_failure_msg: "",
  isUpdating: false,
}
