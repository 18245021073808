export default{
    isLoading:true,
    invoiceLocation:{
        city:"",
        country:"",
        line1:"",
        line2:"",
        state:"",
        zipCode:"",
    },
    isError:false,
    error:""
}