import MaterialTable from 'material-table'
import React, { useEffect,useRef } from 'react'
import { Link } from "react-router-dom"
import MetaInfo from '../../../../shared/getMetaInfo'
import { useBulkPaymentsContext } from './context'
import EditIcon from "@material-ui/icons/Edit";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom"
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { Tooltip } from '@material-ui/core';
import parser from 'html-react-parser'
import UpdateIndividualPayment from './UpdateIndividualPayment'
import * as XLSX from 'xlsx';
import InvoicePreviewAction from '../ListingInvoices/Actions/InvoicePreviewAction'

function SearchByReference(props) {
  const { state, services } = useBulkPaymentsContext()
  const metaData = new MetaInfo()
  const history = useHistory()
  const tableRef = useRef()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  useEffect(() => {
    services.loadAllPayments()
    services.getPaymentAmounts()
  }, [])

  const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html ?? "<p></p>";
    return tmp.textContent || tmp.innerText || "";
  }

  const handleLoad = async()=>{
    services.loadAllPayments()
  }

  const columns = [
    {
      title: "Unique ID",
      field: "bulkPaymentId",
      dataType: "String",
      defaultGroupOrder: 0,
      customSort: (a, b) => {
        const aVal = a.replace("PAY-", "")
        const bVal = b.replace("PAY-", "")
        return Number(bVal) - Number(aVal)
      }
    },
    {
      title: "Client",
      field: "clientName",
      dataType: "String",
      render: (row) => {
        if (row.clientID)
          return <Link to={"/console/clientslist/" + row.clientID}>{row.clientName}</Link>
        return "--"
      },
    },
    {
      title: "Reference number",
      field: "referenceNum",
    },
    {
      title: "Invoice Number",
      field: "invoiceID",
      dataType: "String",
      render: (row) => {
        return <InvoicePreviewAction
          invoiceID={row.invoiceID}
          isBulkPaymentPreviewAction={true}
          renderComponent={() => { return <span style={{
            backgroundColor: "#2C9273",
            color: "#FFFFFF",
            display: "inline-block",
            alignItems: "center",
            padding: "0 12px",
            borderRadius: "25px",
            height: "24px", 
            fontSize: "0.875rem",
            lineHeight: "24px"
        }}>{row?.invoiceID}</span> }}
        />
      },
    },
    {
      title: "Payment Date",
      field: "paymentDate",
      dataType: "Date",
    },
    {
      title: "Payment type",
      field: "paymentType",
      lookup: {
        0: "online",
        1: "cheque"
      }
    },
    {
      title: "Payment Amount",
      field: "paymentAmount",
      dataType: "Numeric",
      type: "currency",
    },
    {
      title:"Total Credit",
      field:"totalCredit",
      dataType:"String", 
    },
    {
      title:"Linked Amount",
      field:"linkedAmount",
      dataType:"String", 
    },
    {
      title:"Unlinked Amount",
      field:"unlinkedAmount",
      dataType:"String", 
    },
    {
      title: "Actions",
      field: "",
      dataType: "",
      render: (row) => {
        return (
          <div style={{display:"flex",alignItems:"center"}}>
            {
              row.bulkPaymentId.trim(" ").length ? 
              <IconButton
              onClick={(e) => {
                if (row.bulkPaymentId.trim(" ").length) {
                  history.push("?tab=7&bulkPaymentId=" + row.bulkPaymentId);
                  return window.location.reload();
                }
              }}
            >
              <EditIcon />
            </IconButton> 
            :
            <UpdateIndividualPayment invoiceID = {row?.invoiceID} PaymentData = {row?.PaymentData} handleLoad = {handleLoad}/>
            }
            {stripHtml(row?.paymentNotes)?.length > 0 && (
              <Tooltip
                placement="top"
                title={
                  <div style={{ fontSize: "13px" }}>
                    {parser(row?.paymentNotes)}
                  </div>
                }
              >
                <span style={{ fontSize: "20px" }}>
                  <AiOutlineInfoCircle />
                </span>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ]

  const rows = state.allPayments.map((payment) => {
    return {
      invoiceID: payment.invoiceID,
      bulkPaymentId: payment?.bulkPaymentId || '',
      clientName: metaData.clientIdToName(payment.clientID),
      referenceNum: payment?.otherInfo?.referenceNumber || '',
      paymentDate: payment.paymentDate,
      paymentType: payment?.otherInfo?.paymentType || '',
      paymentAmount: payment.paymentAmount,
      clientID: payment.clientID,
      paymentNotes: stripHtml(payment?.paymentNotes) || '',
      PaymentData: payment,
      unlinkedAmount:(state.allUnLinkedAmount[payment?.bulkPaymentId] || 0),
      linkedAmount:(state.allLinkedAmount[payment?.bulkPaymentId] || 0),
      totalCredit:(state.totalCreditAmount[payment?.bulkPaymentId] || 0),
    }
  })

  const handleExport = () => {
    const currentPage = tableRef.current?.dataManager?.currentPage
    const pageSize = tableRef.current?.dataManager?.pageSize
    const visibleRows = rows.slice(currentPage * pageSize, currentPage * pageSize + pageSize)
    const worksheet = XLSX.utils.json_to_sheet(visibleRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet);
    XLSX.writeFile(workbook, "invoices.xlsx");
  };

  const handleExportAll = () => {
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet);
    XLSX.writeFile(workbook, "allinvoices.xlsx");
  }

  const handleExportOption = (option) => {
    if (option === "export") {
      handleExport(); 
    } else if (option === "exportAll") {
      handleExportAll();
    }
  };

  return (
    <div>
      <Menu  
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleExportOption('export')}>Export All</MenuItem>
        <MenuItem onClick={() => handleExportOption('exportAll')}>Export All (Data)</MenuItem>
      </Menu>
      <MaterialTable
        title=""
        tableRef={tableRef}
        data={rows}
        columns={columns}
        isLoading={state.showLoader}
        // onSelectionChange={(rows) => setSelectedRows(rows)}
        options={{
          pageSize: 50,
          pageSizeOptions: [50,100,200],
          filtering: true,
          columnsButton: true,
          paginationPosition: "top",
          paginationType: "normal",
          selection: true,
          grouping: true
        }}
        actions={[
          {
            icon: 'save_alt',
            tooltip: 'Export',
            isFreeAction: true,
            onClick: handleClick
          }
        ]}
      />
    </div>
  )
}

export default SearchByReference
