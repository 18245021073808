import React from 'react'
import ViewAnnouncement from "../Services/Announcements/ViewAnnouncement"

function ViewAnnouncementPage(props) {
  const { announcementId } = props.match.params;
  return (
    <div>
        <ViewAnnouncement announcementId = {announcementId} />
    </div>
  )
}

export default ViewAnnouncementPage