import React from "react"
import Labels from "../Services/TaskManagement/components/Labels"

function DeductionListPage(props) {
  return (
    <div>
      <Labels projectId={props.match.params.projectId} />
    </div>
  )
}

export default DeductionListPage
