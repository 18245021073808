import React from "react";
import { Col, Button } from "react-bootstrap";

const defaulters = ({ handleTabChange, ...props }) => {
  const { Defaulters } = props;
  const firstFiveDefaulters = Defaulters?.slice(0, 5);
  return (
    <div className="card-border mt-24">
      <div className="card-border-title">Pending Defaulters</div>
      <div className="card-lists py-2">
        <div className="d-flex justify-content-between">
          <Col xs={8}>
            <span className="align-self-center fs-5 fw-600">Employee Name</span>
          </Col>
          <Col xs={4}>
            <span className="align-self-center fs-5 fw-600">
              Pending Timesheets
            </span>
          </Col>
        </div>
      </div>

      {firstFiveDefaulters?.map((item) => (
        <div className="card-lists py-2">
          <div className="d-flex justify-content-between">
            <Col xs={8}>
              <span className="align-self-center fs-5 fw-400">
                {item.empName}
              </span>
            </Col>
            <Col xs={4} style={{ textAlign: "center" }}>
              <div class="badge rounded-pill badge-soft-warning  px-2 py-1 fs-12">
                {" "}
                {item.pendingCount}
              </div>
            </Col>
          </div>
        </div>
      ))}
      <div className="card-lists py-2 smorebtn">
        <div className="d-grid gap-2">
          <Button variant="success" onClick={() => handleTabChange("3")}>
            See More
          </Button>
        </div>
      </div>
    </div>
  );
};

export default defaulters;
