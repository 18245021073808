import React from 'react'
import { Grid, TextField, Paper, MenuItem } from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { CustomField } from '../../../../../shared/components/formFields'
function Presentation(props) {
  const { formData, handleChange, handleDateChange } = props
  const handleChangeCustom = (K, V, H) => {
    handleChange(K, V, H)
  }

  return (
    <div
      style={{
        paddingTop: "30px",
        paddingBottom: "30px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div></div>
      <Grid container spacing={2}>
        <Paper style={{ padding: "15px" }}>
          {formData
            .filter(
              (item) =>
                item.section !== "workauthorization" &&
                item.section !== "employement" &&
                item.section !== "mailingaddress"
            )
            .map((portion, index) => {
              const { section, fields, heading } = portion;
              // console.log(props.includes(section))
              return (
                <div key={index}>
                  <h3>
                    <u>{heading}</u>
                  </h3>
                  <Grid container spacing={3}>
                    {fields.map((item) => {
                      const { label, name, required } = item;
                      if (item.isExist) {
                        // if (item.type === "text")

                        console.log(props, section, item, name);

                        const disableEmail =
                          section === "personalinformation" &&
                          name === "emailid";

                        return (
                          <Grid item xs={6}>
                            <CustomField
                              disabled={disableEmail ? true : false}
                              type={item.type}
                              required={required}
                              label={label}
                              menuItems={item.values}
                              // handleChange={(e) => handleChange(name,e.target.value,section)}
                              handleChange={
                                item.type === "date" ||
                                item.type === "fromdate" ||
                                item.type === "todate"
                                  ? (date) =>
                                      handleDateChange(
                                        name,
                                        date.target.value,
                                        section
                                      )
                                  : (e) =>
                                      handleChangeCustom(
                                        name,
                                        e.target.value,
                                        section
                                      )
                              }
                              name={name}
                              value={props[section][name]}
                              section={section}
                              minDate={
                                item.type === "date" ||
                                item.type === "fromdate" ||
                                item.type === "todate"
                                  ? props[section][
                                      item.type === "fromdate"
                                        ? item
                                        : "minDate"
                                    ]
                                  : ""
                              }
                              maxDate={
                                item.type === "date" ||
                                item.type === "fromdate" ||
                                item.type === "todate"
                                  ? props[section][
                                      item.type === "fromdate"
                                        ? item
                                        : "maxDate"
                                    ]
                                  : ""
                              }
                            />
                            {/* <TextField
                              label={label}
                              name={name}
                              required={required}
                              variant="outlined"
                              size="small"
                              fullWidth
                              onChange={(event) => handleChange(event, section)}
                              value={props[section][name]}
                            /> */}
                          </Grid>
                        );
                        // else if (item.type === "email")
                        // 	return (
                        //     <Grid item xs={6}>
                        //       <TextField
                        //         label={label}
                        //         name={name}
                        //         disabled={
                        //           section === "personalinformation" &&
                        //           name === "emailid"
                        //         }
                        //         required={required}
                        //         variant="outlined"
                        //         size="small"
                        //         fullWidth
                        //         onChange={(event) => handleChange(event, section)}
                        //         value={props[section][name]}
                        //       />
                        //     </Grid>
                        //   )
                        // else if (item.type === "number")
                        // 	return (
                        // 		<Grid item xs={6}>
                        // 			<TextField
                        // 				label={label}
                        // 				name={name}
                        // 				required={required}
                        // 				variant='outlined'
                        // 				size='small'
                        // 				fullWidth
                        // 				onChange={(event) => handleChange(event, section)}
                        // 				value={props[section][name]}
                        // 			/>
                        // 		</Grid>
                        // 	)
                        // else if (item.type === "zip")
                        // 	return (
                        // 		<Grid item xs={6}>
                        // 			<TextField
                        // 				label={label}
                        // 				name={name}
                        // 				required={required}
                        // 				variant='outlined'
                        // 				size='small'
                        // 				fullWidth
                        // 				onChange={(event) => handleChange(event, section)}
                        // 				value={props[section][name]}
                        // 			/>
                        // 		</Grid>
                        // 	)
                        // else if (item.type === "select")
                        // 	return (
                        // 		<Grid item xs={6}>
                        // 			<div style={{ minWidth: "100%" }}>
                        // 				<TextField
                        // 					select
                        // 					label={label}
                        // 					name={name}
                        // 					required={required}
                        // 					fullWidth
                        // 					style={{ marginTop: "4px" }}
                        // 					onChange={(event) =>
                        // 						handleChange(event, section)
                        // 					}
                        // 					value={props[section][name]}
                        // 				>
                        // 					{item.values.map((option, index) => {
                        // 						return (
                        // 							<MenuItem key={index} value={option}>
                        // 								{option}
                        // 							</MenuItem>
                        // 						)
                        // 					})}
                        // 				</TextField>
                        // 			</div>
                        // 		</Grid>
                        // 	)
                        // else if (item.type === "address")
                        // 	return (
                        // 		<Grid item xs={6}>
                        // 			<TextField
                        // 				label={label}
                        // 				name={name}
                        // 				required={required}
                        // 				variant='outlined'
                        // 				size='small'
                        // 				fullWidth
                        // 				onChange={(event) => handleChange(event, section)}
                        // 				value={props[section][name]}
                        // 			/>
                        // 		</Grid>
                        // 	)
                        // else if (item.type === "fromdate")
                        // 	return (
                        // 		<Grid item xs={6}>
                        // 			<MuiPickersUtilsProvider utils={DateFnsUtils}>
                        // 				<KeyboardDatePicker
                        // 					label={label}
                        // 					name={name}
                        // 					required={required}
                        // 					fullWidth
                        // 					format='MM/dd/yyyy'
                        // 					style={{ marginTop: "4px" }}
                        // 					onChange={(date) =>
                        // 						handleDateChange(name, date, section)
                        // 					}
                        // 					value={
                        // 						props[section][name]
                        // 							? props[section][name]
                        // 							: null
                        // 					}
                        // 				/>
                        // 			</MuiPickersUtilsProvider>
                        // 		</Grid>
                        // 	)
                        // else if (item.type === "todate")
                        // 	return (
                        // 		<Grid item xs={6}>
                        // 			<MuiPickersUtilsProvider utils={DateFnsUtils}>
                        // 				<KeyboardDatePicker
                        // 					label={label}
                        // 					name={name}
                        // 					required={required}
                        // 					fullWidth
                        // 					format='MM/dd/yyyy'
                        // 					style={{ marginTop: "4px" }}
                        // 					onChange={(date) =>
                        // 						handleDateChange(name, date, section)
                        // 					}
                        // 					value={
                        // 						props[section][name]
                        // 							? props[section][name]
                        // 							: null
                        // 					}
                        // 				/>
                        // 			</MuiPickersUtilsProvider>
                        // 		</Grid>
                        // 	)
                        // else if (item.type === "date")
                        // 	return (
                        // 		<Grid item xs={6}>
                        // 			<MuiPickersUtilsProvider utils={DateFnsUtils}>
                        // 				<KeyboardDatePicker
                        // 					label={label}
                        // 					name={name}
                        // 					required={required}
                        // 					format='MM/dd/yyyy'
                        // 					fullWidth
                        // 					style={{ marginTop: "4px" }}
                        // 					onChange={(date) =>
                        // 						handleDateChange(name, date, section)
                        // 					}
                        // 					value={
                        // 						props[section][name]
                        // 							? props[section][name]
                        // 							: null
                        // 					}
                        // 				/>
                        // 			</MuiPickersUtilsProvider>
                        // 		</Grid>
                        // 	)
                        // else if (item.type === "alphanum")
                        // 	return (
                        // 		<Grid item xs={6}>
                        // 			<TextField
                        // 				label={label}
                        // 				name={name}
                        // 				required={required}
                        // 				variant='outlined'
                        // 				size='small'
                        // 				fullWidth
                        // 				onChange={(event) => handleChange(event, section)}
                        // 				value={props[section][name]}
                        // 			/>
                        // 		</Grid>
                        // 	)
                      }
                      return null;
                    })}
                  </Grid>
                  <br />
                  <br />
                </div>
              );
            })}
        </Paper>
      </Grid>
      <div></div>
    </div>
  );
}

export default Presentation
