import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Tab, Card } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import DateFormField from "../../../../../shared/components/BootStrapFormFields/DateFormField";
import { Animations } from "../../../../../shared/skeletonLoader";
import { Skeleton } from "@material-ui/lab";
import { CalendarFormatter } from "../../../../../shared/CalendarFormatter";
import Overview from "./overview";
import Recentrequest from "./recentrequest";
import Keystates from "./keystates";
import Letterrequest from "./letterrequest";
import Issuers from "./issuers";
import Statistics from "./statistics";

const schema = yup.object().shape({
  fromDate: yup.string().required("From Date is required"),
  toDate: yup.string().required("To Date is required"),
});

const LetterrequestsDashboard = ({ handleTabValue, ...props }) => {
  const [dashboardData, setdashboardData] = React.useState({});
  const [recents, setrecentsData] = React.useState({});
  const [issuersData, setissuersData] = React.useState([]);
  const [fromDate, setFromDate] = useState(
    CalendarFormatter.mmddyyyyFormat(new Date(new Date().getFullYear(), 0, 1))
  );
  const [toDate, setToDate] = useState(
    CalendarFormatter.mmddyyyyFormat(new Date())
  );
  let initialDates = {
    fromDate,
    toDate,
  };

  useEffect(() => {}, [initialDates.fromDate, initialDates.toDate]);

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    setFromDate(values.fromDate);
    setToDate(values.toDate);
    actions.setSubmitting(false);
  };

  return (
    <>
      <Tab.Container defaultActiveKey="Dashboard">
        <Card>
          <Card.Body>
            <Tab.Content className="text-muted pt-0">
              <Tab.Pane eventKey="Dashboard" id="10" key="10">
                <Row className="justify-content-between pb-1" xs={12}>
                  <Col xs={4}>
                    <h4 className="page-title">Letter Requests Dashboard</h4>
                  </Col>
                  <Col
                    xs={8}
                    className="d-flex justify-content-end d-filter-form"
                  >
                    <Formik
                      validationSchema={schema}
                      initialValues={{
                        ...initialDates,
                      }}
                      onSubmit={handleSubmit}
                    >
                      {(formik) => {
                        return (
                          <Form>
                            <Row>
                              <Col>
                                <Field
                                  name="fromDate"
                                  type="text"
                                  component={DateFormField}
                                />
                              </Col>
                              <Col style={{ marginTop: 6 }}>
                                <div className="px-2 align-self-center">to</div>
                              </Col>
                              <Col>
                                <Field
                                  name="toDate"
                                  type="text"
                                  component={DateFormField}
                                />
                              </Col>
                              <Col>
                                <button
                                  type="submit"
                                  className="btn btn-success px-3 btn-sm"
                                  disabled={
                                    !formik.isValid ||
                                    formik.isSubmitting ||
                                    !formik.dirty
                                  }
                                >
                                  Apply
                                </button>
                              </Col>
                            </Row>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xl={4}>
                    <Overview />
                    <Recentrequest />
                  </Col>
                  <Col xl={8}>
                    <Keystates />
                    <Letterrequest />
                    <Row>
                      <Col lg={4}>
                        <Issuers />
                      </Col>
                      <Col lg={8}>
                        <Statistics />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Card.Body>
        </Card>
      </Tab.Container>
    </>
  );
};

export default LetterrequestsDashboard;
