import React from 'react'
import ClientApprove from "../Services/Invoices/components/ClientsInterest/Approve"
function ClientApprovePage() {
  return (
    <div>
      <ClientApprove />
    </div>
  )
}

export default ClientApprovePage
