import React, { useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import TextFormField from "../../../../../../shared/components/BootStrapFormFields/TextFormField";
import SelectFormField from "../../../../../../shared/components/BootStrapFormFields/SelectFormField";
import DateFormField from "../../../../../../shared/components/BootStrapFormFields/DateFormField";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../../middleware/dynamicProfileRender";
import FileUploadForm from "../../../../../../shared/components/BootStrapFormFields/FileUploadForm";
import AppModalFooter from "../../../../../../shared/components/Modals/AppModalFooter";
import AppModalHeader from "../../../../../../shared/components/Modals/AppModalHeader";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateEmployee } from "../../../../../../api/services/default/employee";

const schema = yup.object().shape({
	"e-verifynumber": yup.string().required("E-Verify Number is required"),
	verifiedby: yup.string().required("Verified By is required"),
	status: yup.string().required("Status is required"),
	"e-verifeddate": yup.date().required("Expiry Date is required"),
	["e-verifydocument"]:yup.string().required()
});

export default function EVerifyEditModal(props) {
	const {
		data,
		employeeID,
		idx,
		onHide,
		isFromWorkQueue = false,
		onUpdate = () => {},
	} = props;
	console.log({ props });
	const optionMapper = (i) => ({ value: i, label: i });
	
	const everifyStatus = useSelector(state => state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.['e-verification']?.fields?.filter((field) => field.name === "status")?.[0]?.values || [])
	const queryClient = useQueryClient();
  const useUpdateProfile = useMutation({
		queryKey: ["updateEmployee", employeeID],
		mutationFn: ({ employeeID, data }) => updateEmployee(employeeID, data),
		onSuccess: () => {
			queryClient.invalidateQueries(["getEmployee", employeeID]);
			onHide();
		},
	});
	const handleSubmit = async (values, actions) => {
		actions.setSubmitting(true);
		const formData = { ...values };
		if (isFromWorkQueue && onUpdate) {
			onUpdate(formData);
			return;
		}

		let docs = data?.map((item) => item) || [];

		if (!isNaN(idx)) {
			docs[idx] = formData;
		} else {
			docs.push(formData);
		}

		const payload = {
			"e-verification": docs,
		};
		await useUpdateProfile.mutateAsync({ employeeID, data: payload });
		actions.setSubmitting(false);
		
	};

	const loadInitialValues = () => {
		if (isFromWorkQueue && data) {
			return data;
		} else if (!isNaN(idx)) {
			return data[idx];
		} else {
			return {};
		}
	};

	const getSaveButtonText = () => {
		if (isFromWorkQueue && data) {
			return "Approve";
		} else if (!isNaN(idx)) {
			return "Save Changes";
		} else {
			return "Create";
		}
	};

	return (
		<Modal
			{...props}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			enforceFocus={!isFromWorkQueue}
		>
			<Formik
				validationSchema={schema}
				initialValues={loadInitialValues()}
				onSubmit={handleSubmit}
				enableReinitialize={true}
			>
				{(formik) => {
					return (
						<Form>
							<AppModalHeader
								title={
									!isNaN(idx) ? "Edit E-Verification" : "Create E-Verification"
								}
							/>
							<Modal.Body>
								<Row>
									<Col xl={4}>
										<Field
											name="e-verifynumber"
											type="text"
											label={"E-Verify Number *"}
											component={TextFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="verifiedby"
											type="text"
											label={"Verified By *"}
											component={TextFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="status"
											type="select"
											options={everifyStatus?.map(optionMapper)}
											label={"Status *"}
											component={SelectFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="e-verifeddate"
											type="date"
											label={"E-Verify Date *"}
											component={DateFormField}
										/>
									</Col>
									<Col xl={4}>
										 <Field
											name="e-verifydocument"
											component={FileUploadForm}
											label="E-Verify Document *"
											type="text"
											employeeId={employeeID}
											sectionKey="e-verification"
											/>
									</Col>
								</Row>
							</Modal.Body>
							{props.actions ? (
								props.actions(formik)
							) : (
								<AppModalFooter
									saveButtonText={getSaveButtonText()}
									saveButtonDisabled={
										!formik.isValid ||
										formik.isSubmitting ||
										!formik.dirty 			
									}
								/>
							)}
						</Form>
					);
				}}
			</Formik>
		</Modal>
	);
}
