import { combineReducers } from "redux";
import { geoStateReducer } from "./geoLocationReducer";
import { newClientReducer } from "./newClientReducer";
import { clientProjectsReducer } from "./clientProjectsReducer";
import { clientsLocationReducer } from "./clientsLocationsReducer";
import { invoiceLocationReducer } from "./invoiceLocationReducer";
import clientDocumentsReducer from "./clientDocumentsReducer";
import { clientsMetaInfoReducer } from "./clientsMetaInfoReducer";

const rootReducer = combineReducers({
  newClient: newClientReducer,
  geo_info: geoStateReducer,
  clientsProjects: clientProjectsReducer,
  clientsDocuments: clientDocumentsReducer,
  clientLocations: clientsLocationReducer,
  invoiceLocations: invoiceLocationReducer,
  clientsMetaInfo: clientsMetaInfoReducer
});

export default rootReducer;
