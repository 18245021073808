import React, { useMemo } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { useDialog } from "../../../../../hooks/useDialog";
import useExcelReader from "../../../../../hooks/useExcelReader";
import * as yup from "yup";
import AppModalHeader from "../../../../../shared/components/Modals/AppModalHeader";
import validate from "../../../../../shared/validation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { markAsPaid } from "../../../../../api/services/default/payrolls/reports";

const schema = yup.object().shape({
	payrollId: yup.string().required(),
	pay: yup.number().required(),
	deductions: yup.number().required(),
});

export default function PayrollCheckerModal({
	freezedRows = [],
	reportingDate,
}) {
	const fileRef = React.useRef();

	const { open, handleOpen, handleClose } = useDialog();
	const { rows, handleFileChange, errors, reset, totalRows } = useExcelReader({
		validationSchema: schema,
	});

	const queryClient = useQueryClient();

	const { mutate } = useMutation({
		mutationFn: ({ data }) => markAsPaid(data),
		onSuccess: () => {
			queryClient.invalidateQueries(["getGeneralReport", reportingDate]);
			onClose();
		},
	});

	const columns = [
		{ title: "PayrollId", field: "payrollId" },
		{ title: "Pay", field: "pay" },
		{ title: "Deductions", field: "deductions" },
		{ title: "Calculated Pay", field: "calculatedPay" },
	];

	const downloadErrors = () => {
		const csvContent =
			"data:text/csv;charset=utf-8," +
			"Row, Error\n" +
			errors.map((e) => e.id + "," + e.error).join("\n") +
			"\n";
		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", "errors.csv");
		document.body.appendChild(link);
		link.click();
	};

	const { mismatched = [], matched = [] } = useMemo(() => {
		if (!rows.length) return [];

		const mismatched = [];
		const matched = [];
		// now we need to match the payrollId with the freezedRows
		// if the payrollId is not found in the freezedRows, then it is a mismatch
		// if the pay and deductions are not equal, then it is a mismatch
		freezedRows.forEach((row) => {
			const excelRow = rows.find((r) => r.payrollId === row.payrollId);
			const calculatedPay = validate.currencyFormatterUs(row.totalPay);
			const pay = excelRow?.pay
				? validate.currencyFormatterUs(excelRow?.pay)
				: "--";
			if (!excelRow || pay !== calculatedPay) {
				mismatched.push({
					...row,
					calculatedPay,
					pay,
				});
			} else {
				matched.push(row.soaId);
			}
		});
		return { mismatched, matched };
	}, [rows, freezedRows]);

	const onClose = () => {
		handleClose();
		reset();
		fileRef.current.value = "";
	};

	return (
		<>
			<Button onClick={handleOpen} color="primary">
				<i className="fas fa-file-excel"></i> Payroll Checker
			</Button>
			<Modal show={open} onHide={onClose} size="lg">
				<AppModalHeader title="Payroll Checker" />
				<Modal.Body style={{ maxHeight: "80vh" }}>
					<Form.Group>
						<Form.Label>Upload Excel File</Form.Label>
						<Form.Control
							type="file"
							ref={fileRef}
							onChange={handleFileChange}
							onClick={(e) => {
								e.currentTarget.value = "";
							}}
							accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
						/>
					</Form.Group>
					<div className="d-flex gap-2 mt-2 flex-wrap">
						<Card style={{ width: "140px" }}>
							<Card.Body>
								<Card.Title>Total Freezed Rows</Card.Title>
								<Card.Text>{freezedRows.length}</Card.Text>
							</Card.Body>
						</Card>
						<Card style={{ width: "140px" }}>
							<Card.Body>
								<Card.Title>Total Rows Uploaded</Card.Title>
								<Card.Text>{totalRows}</Card.Text>
							</Card.Body>
						</Card>
						<Card style={{ width: "140px" }}>
							<Card.Body>
								<Card.Title>Errors</Card.Title>
								<Card.Text>{errors.length}</Card.Text>
							</Card.Body>
						</Card>
						<Card style={{ width: "140px" }}>
							<Card.Body>
								<Card.Title>Matched</Card.Title>
								<Card.Text>{matched.length}</Card.Text>
							</Card.Body>
						</Card>
						<Card style={{ width: "140px" }}>
							<Card.Body>
								<Card.Title>MisMatched</Card.Title>
								<Card.Text>{mismatched.length}</Card.Text>
							</Card.Body>
						</Card>
					</div>
					<hr />
					<h5 className="text-danger">Mismatched</h5>
					<div
						style={{ maxHeight: "65vh", overflowY: "auto" }}
						className="mt-2"
					>
						<table className="table table-bordered table-striped table-fixed">
							<thead>
								<tr>
									<th>#</th>
									{columns.map((column) => (
										<th key={column.field}>{column.title}</th>
									))}
								</tr>
							</thead>
							<tbody>
								{mismatched.map((row, index) => {
									return (
										<tr key={row.payrollId}>
											<th scope="row">{index + 1}</th>
											{columns.map((column) => {
												return <td key={column.field}>{row[column.field]}</td>;
											})}
										</tr>
									);
								})}
							</tbody>
							{mismatched.length === 0 && (
								<tfoot>
									<tr>
										<td colSpan={columns.length + 1} className="text-center">
											No mismatched found
										</td>
									</tr>
								</tfoot>
							)}
						</table>
					</div>
				</Modal.Body>
				<Modal.Footer>
					{errors.length > 0 && (
						<Button variant="danger" onClick={downloadErrors}>
							Download Errors
						</Button>
					)}
					<Button variant="secondary" onClick={reset}>
						Reset
					</Button>
					<Button
						variant="success"
						onClick={() => {
							mutate({
								data: {
									soaIds: matched,
								},
							});
						}}
						disabled={matched.length === 0}
					>
						Mark matched as paid
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
