import { initialState } from "./holdAccountState";
import { ACTION } from "../actions/actionTypes";

export const holdAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.HOLD_ACCOUNT_REQUEST:
      return {
        ...state,
        isHolading: true,
      };

    case ACTION.HOLD_ACCOUNT_SUCCESS:
      return {
        ...state,
        isHolading: false,
      };

    case ACTION.HOLD_ACCOUNT_FAILURE:
      return {
        ...state,
        isHolading: false,
      };

    default:
      return state;
  }
};
