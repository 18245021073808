import React from "react"
import ESSRequest from "../Services/LetterRequests/components/MyRequests/Index/index"

function ESSRequests(props) {
  return (
    <div>
      <ESSRequest myReq={false} />
    </div>
  )
}

export default ESSRequests
