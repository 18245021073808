import ACTION from "../actions/actionTypes"
import timesheetListState from "../states/timesheetListState"

const timesheetListReducer = (state = timesheetListState, { type, payload }) => {
  switch (type) {
    case ACTION.LOAD_TIMESHEETS_LIST_REQ:
      return {
        ...state,
        isLoading: true,
      }

    case ACTION.LOAD_TIMESHEETS_LIST_SUCCESS:
      return {
        ...state,
        data: payload.data,
        firstDoc: payload.firstDoc,
        lastDoc: payload.lastDoc,
        page: payload.page,
        rowsPerPage: payload.rowsPerPage,
        isLoading: false,
      }

    case ACTION.LOAD_TIMESHEETS_LIST_FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
      }

    case ACTION.LOAD_COLUMN_DETAILS_REQ:

    case ACTION.LOAD_COLUMN_DETAILS_SUCCESS:

    case ACTION.LOAD_COLUMN_DETAILS_FAILURE:
      return {
        ...state,
        ...payload
      }

    default:
      return state
  }
}

export default timesheetListReducer
