import React from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import TaskViewer from "../taskHandleComponents/TaskViewer"
import SubtasksTable from "../taskHandleComponents/SubTaskTable"
import NewTask from "../taskHandleComponents/NewTask"
import Comments from "../taskHandleComponents/Comments"
import Alert from "@material-ui/lab/Alert"
import Linktask from "../taskHandleComponents/LinkTask"
import Unlinktask from "../taskHandleComponents/UnLinkTask"
import TaskTimeLine from "../taskHandleComponents/TimeLine"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Badge from "@material-ui/core/Badge"
import AppBar from "@material-ui/core/AppBar"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import PropTypes from "prop-types"
import validate from "../../../../../shared/validation"
import useStyles from "../../../Styles/TaskHandlerStyles"
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export default function Presentation(props) {
  const classes = useStyles()
  // const [state] = useContext(DataContext)

  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  let comments = props.comments
  // checking project access real time
  let employee = {}
  // const id = props.projectId
  if (props.employee.role === "user") {
    const employees = Object.values(props.project[0].Users)
    employee = employees.filter((user) => user.uid === props.employee.uid)[0]
  }
  let access = employee ? true : false

  //  checking supervisor count
  let supervisorCount = 0
  try {
    const employees = Object.values(props.project[0].Users)
    employees.forEach((member) => {
      if (validate.checkSupervisor(member.uid)) supervisorCount++
    })
  } catch (error) { }

  if (supervisorCount > 1)
    return (
      <Alert className="text-center" variant="filled" severity="warning">
        This project contain multiple Supervisors, Please contact Admin/Manager.
      </Alert>
    )
  if (
    props.employee.role === "admin" ||
    props.employee.role === "manager" ||
    (access && employee.create) ||
    (access && employee.update) ||
    (access && employee.read)
  )
    return (
      <div>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Card className={classes.root}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TaskViewer
                      taskId={props.taskId}
                      projectId={props.projectId}
                      comments={comments}
                    />
                  </Grid>
                </Grid>
                <br />
                <div className="d-flex">
                  <NewTask
                    projectId={props.projectId}
                    taskId={props.taskId}
                    category="subtask"
                    text="New subtask"
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Linktask taskId={props.taskId} projectId={props.projectId} />
                  &nbsp;&nbsp;&nbsp;
                  <Unlinktask
                    taskId={props.taskId}
                    projectId={props.projectId}
                  />
                </div>
                <br />
                <Grid item xs={12}>
                  <SubtasksTable
                    taskId={props.taskId}
                    projectId={props.projectId}
                  />
                </Grid>
                <br />
                <AppBar position="static" color="default">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab
                      label={<Badge color="primary" children="Comments" />}
                    />
                    <Tab
                      label={<Badge color="primary" children="Timeline" />}
                    />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  <Grid item xs={12}>
                    <Card className={classes.root}>
                      <CardContent>
                        <h2>Comments:</h2>
                        <Comments
                          taskId={props.taskId}
                          projectId={props.projectId}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Grid item xs={12}>
                    <TaskTimeLine TaskTimeLine={props.tasktimeline} />
                  </Grid>
                </TabPanel>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    )
  return (
    <Alert className="text-center" variant="filled" severity="error">
      You are unauthorized to view this content
    </Alert>
  )
}
