import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function AppModalFooter({
	onClickCancel,
	cancelButtonText = 'Cancel',
	saveButtonText = 'Save Changes',
	saveButtonDisabled = false,
	cancelButtonDisabled = false,
	children,
	formId,
}) {
	return (
		<Modal.Footer>
			{children}
			<Button className="btn btn-success rounded-pill px-4 btn-lg" type="submit" form={formId} disabled={saveButtonDisabled}>
				{saveButtonText}
			</Button>
		</Modal.Footer>
	)
}
