export const ACTION = {
  SET_STATE: "SET_STATE",

  SET_LISTENER: "SET_LISTENER",
  UNSET_LISTENER: "UNSET_LISTENER",


  LOAD_PAYROLL_LIST_REQ: "LOAD_PAYROLL_LIST_REQ",
  LOAD_PAYROLL_LIST_SUCCESS: "LOAD_PAYROLL_LIST_SUCCESS",
  LOAD_PAYROLL_LIST_FAILURE: "LOAD_PAYROLL_LIST_FAILURE",

  LOAD_PAYROLL_SETTINGS_REQ: "LOAD_PAYROLL_SETTINGS_REQ",
  LOAD_PAYROLL_SETTINGS_SUCCESS: "LOAD_PAYROLL_SETTINGS_SUCCESS",
  LOAD_PAYROLL_SETTINGS_FAILURE: "LOAD_PAYROLL_SETTINGS_FAILURE",

  UPDATE_PAYROLL_SETTINGS_REQ: "UPDATE_PAYROLL_SETTINGS_REQ",
  UPDATE_PAYROLL_SETTINGS_SUCCESS: "UPDATE_PAYROLL_SETTINGS_SUCCESS",
  UPDATE_PAYROLL_SETTINGS_FAILURE: "UPDATE_PAYROLL_SETTINGS_FAILURE",

  GENERATE_PAYROLL_FOR_EMPLOYEE_REQ: "GENERATE_PAYROLL_FOR_EMPLOYEE_REQ",
  GENERATE_PAYROLL_FOR_EMPLOYEE_SUCCESS: "GENERATE_PAYROLL_FOR_EMPLOYEE_SUCCESS",
  GENERATE_PAYROLL_FOR_EMPLOYEE_FAILURE: "GENERATE_PAYROLL_FOR_EMPLOYEE_FAILURE",

  LOAD_ABOUT_TO_GENERATE_PAYROLLS_REQ: "LOAD_ABOUT_TO_GENERATE_PAYROLLS_REQ",
  LOAD_ABOUT_TO_GENERATE_PAYROLLS_SUCCESS: "LOAD_ABOUT_TO_GENERATE_PAYROLLS_SUCCESS",
  LOAD_ABOUT_TO_GENERATE_PAYROLLS_FAILURE: "LOAD_ABOUT_TO_GENERATE_PAYROLLS_FAILURE",

  GENERATE_PAYROLL_FOR_ABOUT_TO_REQ: "GENERATE_PAYROLL_FOR_ABOUT_TO_REQ",
  GENERATE_PAYROLL_FOR_ABOUT_TO_SUCCESS: "GENERATE_PAYROLL_FOR_ABOUT_TO_SUCCESS",
  GENERATE_PAYROLL_FOR_ABOUT_TO_FAILURE: "GENERATE_PAYROLL_FOR_ABOUT_TO_FAILURE",

  LOAD_PAYROLLS_TABLE_REQ: "LOAD_PAYROLLS_TABLE_REQ",
  LOAD_PAYROLLS_TABLE_SUCCESS: "LOAD_PAYROLLS_TABLE_SUCCESS",
  LOAD_PAYROLLS_TABLE_FAILURE: "LOAD_PAYROLLS_TABLE_FAILURE",
}