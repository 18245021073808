import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: "20px",
    fontWeight: theme.typography.fontWeightRegular,
  },
  layout: {
    width: "auto",
  },
}))

export default useStyles
