export default {
  data: [],
  isLoading: false,
  error: null,
  firstDoc: null,
  lastDoc: null,
  page: 0,
  rowsPerPage: 10,
  employees: {},
  timesheetCycles: {},
}
