import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Popover, Avatar, Grid } from "@material-ui/core"
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    width: "400px"
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));


function CustomPopover({ RenderContent, isInfo, anchorEl, handlePopoverOpen, handlePopoverClose, open }) {

  const classes = useStyles();
  
  
  return (
    <div >
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onClick={handlePopoverOpen}
        // onMouseEnter={handlePopoverOpen}
        // onMouseLeave={handlePopoverClose}
      >
        {
          isInfo ?
            <InfoOutlinedIcon style={{fontSize:17,marginBottom:3}}/>
            :
            null
        }
      </Typography>
      <Popover
        id="mouse-over-popover"
        // className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorPosition={{ top: 200, left: 380 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div>
          <RenderContent />
        </div>
      </Popover>
    </div>
  )
}


export default CustomPopover
