import React from "react"
import { connect } from "react-redux"
import Presentation from "./Presentation"

function Container(props) {
  const { id, loggedInEmployee, names } = props
  return <Presentation id={id} loggedInEmployee={loggedInEmployee} names={names} />
}

const mapStateToProps = (state, ownProps) => {
  return {
    loggedInEmployee: state.firebase.auth.uid,
    names: state.employee.employeeList.metaInfoofEmployees.data
  }
}

export default connect(mapStateToProps)(Container)
