import React from "react"
import Presentation from "./Presentation"
import firebase from "../../../../../../config/fbConfig"
function Container(props) {
  const [state, setState] = React.useState({
    taskLabels: [],
  })
  const attachLabelsToTask = () => {
    firebase
      .firestore()
      .collection("PROJECTS")
      .doc(props.project.id)
      .collection("TASKS")
      .doc(props.task[0].id)
      .set(
        {
          labels: state.taskLabels.map((item) => item.id),
        },
        { merge: true }
      )
  }
  const handleChange = (key, value) => {
    setState({
      ...state,
      [key]: value,
    })
    attachLabelsToTask()
  }
  return (
    <div>
      <Presentation {...props} handleChange={handleChange} />
    </div>
  )
}

export default Container
