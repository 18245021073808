const initState = {
  data: {
    placements: [],
    profile: [],
    deductions: [],
  },
  isLoaded: false,
  error: null,
};

export default initState;
