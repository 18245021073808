import React, { useContext } from "react"
import Button from 'react-bootstrap/Button';
import SuspendEmployee from "../EmployeeSuspension/SuspendEmployee"
import SunEditor from "suneditor-react"
import AppModalFooter from "../../../../shared/components/Modals/AppModalFooter";
import { Modal } from "react-bootstrap"
import { Field, Formik, Form } from "formik";
import AppModalHeader from "../../../../shared/components/Modals/AppModalHeader"
import * as Yup from "yup";
import DateFormField from "../../../../shared/components/BootStrapFormFields/DateFormField";

const schema = Yup.object().shape({
  dateOfEnable: Yup.string(),
	reasonforEnabling: Yup.string()
		.required("Reason for enabling is required")
		.test("html", "Reason for enabling is required", (value) => {
			if (value) {
				const regex = /<p><br><\/p>/g;
				const result = value.replace(regex, "");
				return result.length > 0;
			}
			return false;
		}),
});


export default function Presentation(props) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [reason_for_enabling] = React.useState("")
  

const handleSubmit = (values, actions) => {
  onEnable(values.reasonforEnabling, values.dateOfEnable)
  setOpen(false);
}
  const handleClose = () => {
    setOpen(false);
  };
  const {
    onPromote,
    onDemote,
    onSuspend,
    onEnable,
    processing,
    id,
    access_modules,
    loginProfile,
    profile,
    loggedInUser,
  } = props

  return (
    <div>
      {loggedInUser != id ? (
        profile.isDisabled ? (
          !processing ? (
            <div>
              <button
                className="btn btn-danger rounded-pill px-2 ms-2 mt-1"
                onClick={handleClickOpen}
              >
                Enable this employee{" "}
              </button>
            </div>
          ) : (
            <button
            className="btn btn-danger rounded-pill px-2 ms-2 mt-1"
            disabled={true}
          >
            . . .
          </button>
          )
        ) : !processing ? (
          <SuspendEmployee onSuspend={onSuspend} employeeID={profile.uid} />
        ) : (
          <button
          className="btn btn-danger rounded-pill px-2 ms-2 mt-1"
          disabled={true}
        >
          . . .
        </button>
        )
      ) : null}

      <Modal
      show={open}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
       <Formik
          validateOnMount
          validateOnChange
          initialValues={{
            dateOfEnable: "",
            reasonforEnabling: reason_for_enabling,
          }}
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
            {(formik) => {
            return (
              <Form>
        <AppModalHeader title="Reason to enable employee" />
          <Modal.Body>
          <Field
                      name="dateOfEnable"
                      type="date"
                      component={DateFormField}
                      label="Enable Date *"
                    />
            <Field
                    name="Reason to enable"
                    component={ReasonEnableField}
                    label="Reason Note"
                    type="text"
                  />
       </Modal.Body>
       <Modal.Footer>
       <AppModalFooter
                  saveButtonText={"SUBMIT"}
                  saveButtonDisabled={!formik.isValid || formik.isSubmitting}
                />
		</Modal.Footer>
              </Form>
            )}}

        </Formik>
      
      </Modal>
    </div>
  );
}

function ReasonEnableField({ field, form, required, ...props }) {
  const [editorData, setEditorData] = React.useState('')

  return (
    <SunEditor
      placeholder="Reason for enabling"
      setContents={editorData}
      onChange={(data) => {
        setEditorData(data)
        return form.setFieldValue("reasonforEnabling", data, true);
      }}
      height={200}
      setOptions={{
        buttonList: [
          [
            "undo",
            "redo",
            "font",
            "fontSize",
            "formatBlock",
            "bold",
            "underline",
            "italic",
            "strike",
            "subscript",
            "superscript",
            "fontColor",
            "hiliteColor",
            "removeFormat",
            "outdent",
            "indent",
            "align",
            "horizontalRule",
            "list",
            "lineHeight",
            "table",
            "link",
            "image",
            "video",
            "showBlocks",
            "codeView",
          ],
        ],
      }} />
  )
}
