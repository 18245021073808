import React, { useState } from "react";
import { connect } from "react-redux";
import Presentation from "./Presentation";
import {
  uploadBusinessInformation,
  uploadLocationsInformation,
} from "../../../../../middleware";
import { setContactsInformation } from "../../../../../actions/actionCreators";

function Container(props) {
  const {
    locationsList,
    setLocationsInformation,
    setBusinessInformation,
    businessInformation,
    contactsList,
  } = props;

  const [value, setValue] = useState(0);

  const addLocation = () => {
    let id = locationsList[locationsList.length - 1].id + 1;
    let data = locationsList;
    let newLocation = {
      key: id,
      id: id,
      locationsline1: "",
      locationsline2: "",
      locationscountry: "",
      locationsstate_name: "",
      locationscity: "",
      locationszip: "",
      isDraft: true,
    };
    data = [...data, newLocation];
    setLocationsInformation({ locationsList: data, status: false });
  };

  const deleteLocation = (e) => {
    e.stopPropagation();

    if (locationsList.length === 1) {
      return;
    }
    let tabID = parseInt(e.target.id);
    let tabIDIndex = 0;
    let locationId = ""
    let tabList = locationsList.filter((value, index) => {
      if (value.id === tabID) {
        tabIDIndex = index;
        locationId=value.locationId
      }
      return value.id !== tabID;
    });

    let curValue = parseInt(value);
    if (curValue === tabID) {
      if (tabIDIndex === 0) {
        curValue = locationsList[tabIDIndex + 1].id;
      } else {
        curValue = locationsList[tabIDIndex - 1].id;
      }
    }
    setValue(curValue);
    let data = contactsList;
    let objIndex = data.findIndex((obj) => obj.location === tabIDIndex);
    console.log(objIndex, tabID, tabIDIndex,locationId,"locationId",locationsList[0]);
    if (objIndex > -1) {
      data[objIndex].location = null;
      data[objIndex].isDraft = true;
    }
    let name = "invoiceLocationId";
    let value1 = locationId === businessInformation.invoiceLocationId ? locationsList[0].locationId : tabID;
    setLocationsInformation({ locationsList: tabList });
    setBusinessInformation({ name, value: value1 });
    setContactsInformation({ contactsList: data });
  };

  const handleTabChange = (_, value) => {
    setValue(value);
  };

  const state = {
    locationsList,
    value,
  };

  return (
    <div>
      <Presentation
        {...state}
        handleTabChange={handleTabChange}
        addLocation={addLocation}
        deleteLocation={deleteLocation}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    locationsList: state.client.newClient.locations.locationsList,
    businessInformation: state.client.newClient.businessInformation,
    contactsList: state.client.newClient.contacts.contactsList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setBusinessInformation: (payload) => {
      dispatch(uploadBusinessInformation(payload));
    },
    setLocationsInformation: (payload) => {
      dispatch(uploadLocationsInformation(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
