import React, { useState } from "react"
import { connect } from "react-redux"
import Presentation from "./Presentation"
import { uploadToStorage } from "../../../../../shared/fileUploader"
import { addSignature } from "../../../middleware/authorizedSignatures"

function Container(props) {
  const { names, _add_signature } = props
  const [state, setState] = useState({
    employeeID: "",
    attachmentDetails: {},
    isUploading: false,
  })

  const handleChange = (key, value) => {
    setState((state) => ({ ...state, [key]: value }))
  }

  const handleFile = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      setState((state) => ({ ...state, file }))
      fileUpload(file)
    }
  }

  const handleNewSignature = (e) => {
    e.preventDefault()
    const { attachmentDetails, employeeID } = state
    const payload = {
      attachmentDetails,
    }
    _add_signature(payload, employeeID)
  }

  const fileUpload = async (file) => {
    const fileName = file.name.split(".")[0]
    const filePath = `Console/Signatures/${state.employeeID}/${fileName}`
    setState((prevState) => ({ ...prevState, isUploading: true }))
    uploadToStorage(file, filePath, fileName, "file")
      .then((url) => {
        console.log(url)
        setState((prevState) => ({
          ...prevState,
          attachmentDetails: {
            ...prevState.attachmentDetails,
            publicURL: url,
            sourcePath: `${filePath}.${file.name.split(".")[1]}`,
            name: file.name,
          },
          isUploading: false,
        }))
      })
      .catch((err) => {
        console.error(err)
        setState((prevState) => ({ ...prevState, isUploading: false }))
      })
  }
  return (
    <div>
      <Presentation
        state={state}
        names={names}
        handleNewSignature={handleNewSignature}
        handleChange={handleChange}
        handleFile={handleFile}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { data, ordered } = state.firestore
  const managers = ordered.managers.map((manager) => manager.uid)
  return {
    names: Object.entries(data.names)
      .map(([_, value]) => value)
      .filter((item) => managers.includes(item.uid)),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    _add_signature: (payload, employeeID) => {
      dispatch(addSignature(employeeID, payload))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
