const state = {
  placement: {},
  myPlacements: [],
  settings_info: null,
  loadExistingTimesheetInfo: {
    placement: {},
    timesheet: {}
  },
  notifyDefaulters: {
    notifying: [],
    errorWhileNotifying: []
  }
}

export default state