export default {
  LOAD_PROFILE_TEMPLATE_REQ: "LOAD_PROFILE_TEMPLATE_REQ",
  LOAD_PROFILE_TEMPLATE_SUCCESS: "LOAD_PROFILE_TEMPLATE_SUCCESS",
  LOAD_PROFILE_TEMPLATE_FAILURE: "LOAD_PROFILE_TEMPLATE_FAILURE",

  LOAD_EMPLOYEE_PROFILE_REQ: "LOAD_EMPLOYEE_PROFILE_REQ",
  LOAD_EMPLOYEE_PROFILE_SUCCESS: "LOAD_EMPLOYEE_PROFILE_SUCCESS",
  LOAD_EMPLOYEE_PROFILE_FAILURE: "LOAD_EMPLOYEE_PROFILE_FAILURE",

  UPDATE_EMPLOYEE_PROFILE_REQUEST: "UPDATE_EMPLOYEE_PROFILE_REQUEST",
  UPDATE_EMPLOYEE_PROFILE_SUCCESS: "UPDATE_EMPLOYEE_PROFILE_SUCCESS",
  UPDATE_EMPLOYEE_PROFILE_FAILURE: "UPDATE_EMPLOYEE_PROFILE_FAILURE",

  SAVE_WORK_AUTHORIZATION_ACTIVE_REQUEST: "SAVE_WORK_AUTHORIZATION_ACTIVE_REQUEST",
  SAVE_WORK_AUTHORIZATION_ACTIVE_SUCCESS: "SAVE_WORK_AUTHORIZATION_ACTIVE_SUCCESS",
  SAVE_WORK_AUTHORIZATION_ACTIVE_FAILURE: "SAVE_WORK_AUTHORIZATION_ACTIVE_FAILURE",
}