export default {
  tabValue: 0,
  tabCondition: 0,
  generatedTillNow: 0,
  aboutTo: 0,
  defaulters: 0,
  payrollsTable:{
    isLoading:false,
    data:[],
    error:""
  },
  payrolls: {
    isLoading: true,
    data: {},
    error: "",
    copyData: {}
  },
  payrollsAboutToGenerate: {
    isLoading: true,
    data: [],
    error: "",
    copyData: []
  },
  payrollSettings: {
    isLoading: true,
    data: {},
    error: ""
  },
  actions: {
    updatingPayroll: [],
    errorWhileUpdating: [],
    generatingPayroll: [],
    errorWhileGenerating: [],
    generatingPayrollForAboutTo: [],
    errorWhileGeneratingForAboutTo: [],
  },
  listAll: null,
  employeeID: null,
  listener: () => { },
  filterCriteria: {
    isMonthly: false,
    year: 0,
    month: "",
    monthPortion: 1
  }
}