import { ACTION } from "../actions/actionTypes"
import initState from "./paymentsHistoryState"

export function paymentsHistoryReducer(state = initState, action) {
  switch (action.type) {
    case ACTION.LOAD_INVOICE_PAYMENTS_HISTORY_REQ:
      return {
        ...state,
        isLoaded: false,
        isEmpty: false,
        error: null,
        payments_history: [],
      }

    case ACTION.LOAD_INVOICE_PAYMENTS_HISTORY_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        isEmpty: false,
        payments_history: action.payload,
      }

    case ACTION.LOAD_CLIENT_INVOICES_FAILURE:
      return {
        ...state,
        isLoaded: true,
        isEmpty: true,
        payments_history: [],
        error: action.err,
      }

    case ACTION.LOAD_INVOICE_HISTORY_REQ:
      return {
        ...state,
        invoiceHistory: {
          isLoading: true,
          data: [],
          error: ""
        }
      }

    case ACTION.LOAD_INVOICE_HISTORY_SUCCESS:
      return {
        ...state,
        invoiceHistory: {
          isLoading: false,
          data: action.payload,
          error: ""
        }
      }

    case ACTION.LOAD_INVOICE_HISTORY_FAILURE:
      return {
        ...state,
        invoiceHistory: {
          isLoading: false,
          data: [],
          error: action.payload
        }
      }

    default:
      return state
  }
}
