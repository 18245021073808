import React, { useEffect } from "react"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import Presentation from "./Presentation"
import { compose } from "redux"
import { connect } from "react-redux"
import { loadTask, setState } from "../../../../middleware/tasks"
import { loadProject } from "../../../../middleware/projectsList"

function Container(props) {
  const {
    project,
    _load_task,
    isLoadingTask,
    taskData,
    _load_project,
    projectData,
  } = props
  let payload = {
    taskID: props.taskId,
    projectID: props.projectId,
  }
  let task = []
  useEffect(() => {
    _load_task(payload)
    _load_project(payload)
  }, [JSON.stringify(payload)])
  
    if(!isLoadingTask){
      task = Object.values(taskData["taskData"])
      const project = Object.values(projectData["projectData"])
     if(!project[1])
        return (
          <div>
            <Presentation
              {...props}
              task={task}
              project = {project}
            />
          </div>)
     return <p>Loading.</p>
    }
    else {
      return <p>Loading....</p>
    }
 
}

const mapStateToProps = (state, ownProps) => {
  const taskId = ownProps.taskId
  const projectId = ownProps.projectId
  const tasksList = state.firestore.ordered.taskslist
  const project = state.firestore.ordered.project

  return {
    taskData: state.projects.task,
    projectData: state.projects.project,
    taskId: taskId,
    projectId: projectId,
    isLoadingTask: state.projects.task.taskData.isLoading,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    _load_task: (payload) => {
      dispatch(loadTask(payload))
    },
    _load_project: (payload) => {
      dispatch(loadProject(payload))
    },
    // _un_subscribe_listener: (type) => dispatch(unSubscribeListener(type)),
    _set_state: (obj) => setState(obj),
  }
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (!props.tasksList )
      return [
        {
          collection: "PROJECTS",
          doc: props.projectId,
          storeAs: "project",
        },
        {
          collection: "PROJECTS",
          doc: props.projectId,
          subcollections: [
            {
              collection: "TASKS",
              storeAs: "taskslist",
            },
          ],
          storeAs: "taskslist",
        },
      ]
    return []
  })
)(Container)
