import React from "react"
import { Chip, Badge, Tooltip } from "@material-ui/core"
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons"
import { Link } from "react-router-dom"
import FlairTable from "../../../../shared/FlairTable"
import { Button } from "react-bootstrap"
import ActiveChip from "../../../../shared/lib/Chips/ActiveChip"; 
import RedChip from "../../../../shared/lib/Chips/RedChip"; 

function Presentation(props) {
  const { info, onDelete } = props
  const columns = [
    {
      title: "Business name",
      field: "businessDisplayName",
      dataType: "String",
      width: 200,
      render: (rowData) => {
        return (
          <Link to={"/console/clientslist/" + rowData.clientId}>
            {rowData.businessDisplayName}
          </Link>
        )
      },
    },
    {
      title: "Client ID",
      field: "clientId",
      width: 100,
      dataType: "String",
      render: (rowData) => {
        return rowData.status === 0 ? (
          <Link to={"/console/clientslist/" + rowData.clientId}>
            <ActiveChip message={rowData.clientId} />
          </Link>
        ) : (
          <Link to={"/console/clientslist/" + rowData.clientId}>
            <RedChip message={rowData.clientId} />
          </Link>
        )
      },
    },
    {
      title: "Status",
      field: "status",
      width: 100,
      dataType: "",
      lookup: { 0: "Active", 1: "Inactive" },
      render: (rowData) => {
        if (rowData.status === 0)
          return (
            <ActiveChip message="Active" />
          )
        return (
          <RedChip message="Inactive" />
        )
      },
      customFilterAndSearch: (value, rowData) => {
        if (value.length === 0) return true
        return value.includes(rowData.status.toString())
      },
    },
    {
      title: "Termination Notice",
      field: "jobTerminationNotice",
      width: 170,
      dataType: "",
      customFilterAndSearch: (term, rowData) => {
        const value = term === 1 ? term + "-week" : term + "-weeks"
        console.log(value)
        return rowData.jobTerminationNotice.includes(value)
      },
    },
    {
      title: "Net terms",
      field: "netTerms",
      width: 100,
      dataType: "Numeric",
    },
    // {
    //   title: "Active consultants",
    //   field: "activeConsultants",
    //   dataType: "Numeric",
    //   render: (rowData) => {
    //     return (
    //       <Badge
    //         color="primary"
    //         showZero={true}
    //         badgeContent={rowData.activeConsultants}
    //       />
    //     );
    //   },
    //   customFilterAndSearch: (term, rowData) => {
    //     return rowData.activeConsultants === Number(term);
    //   },
    // },
    {
      title: "Total placements",
      field: "totalPlacements",
      width: 150,
      dataType: "Numeric",
      render: (rowData) => {
        return (
          <Badge
            color="primary"
            showZero={true}
            badgeContent={rowData.totalPlacements}
          />
        )
      },
      customFilterAndSearch: (term, rowData) => {
        return rowData.activeConsultants === Number(term)
      }
    },
    {
      title: "Active placements",
      field: "activePlacements",
      width: 150,
      dataType: "Numeric",
      render: (rowData) => {
        return (
          <Badge
            color="primary"
            showZero={true}
            badgeContent={rowData.activePlacements}
          />
        );
      }
    },
    {
      title: "Actions",
      field: "",
      dataType: "",
      width: 150,
      render: (rowData) => {
        return (
          <div className="d-flex">
            <Tooltip title="Edit">
              <Link style={{ color: "red", paddingLeft: "5px", paddingRight: "5px"}} to={"/console/clientslist/" + rowData.clientId}>
                <EditIcon size={20} /></Link>
            </Tooltip>
            {/* {rowData.activeConsultants === 0 ? (
              <IconButton
                onClick={() => onDelete(rowData.clientId)}
                color="inherit"
              >
                <DeleteIcon />
              </IconButton>
            ) : null} */}
          </div>
        )
      },
      width: "auto",
    },
  ]
  const [newColumns, setNewColumns] = React.useState([])
  const [filterBy, setFilterBy] = React.useState({
    data: [],
    isFiltered: false,
  })
  const handleChange = (
    filteredData = [],
    isFiltered = false,
    newCol = columns
  ) => {
    console.log(filteredData, isFiltered, newCol)
    setFilterBy({
      data: filteredData,
      isFiltered: isFiltered,
    })

    setNewColumns(newCol)
  }

  const sendActions = {
    icon:()=> <Link to="/console/newclient"><Button className="btn btn-success px-4 btn-md text-high">Add New Client</Button></Link>,
              tooltip: "Add New Client",
              isFreeAction: true,
              position: "toolbar",
  }

  return (
    <div>
      <FlairTable
        title="Clients Report"
        data={!filterBy.isFiltered ? info : filterBy.data}
        columns={columns}
        actions={sendActions}
      />
    </div>
  )
}

export default Presentation
