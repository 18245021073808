import { flairSharedInstance } from "../../instances/flair-shared.instance";

/**
 * @param {Record} data
 * @param {AbortSignal?} signal
 */
export const createSchool = (data, signal) => {
  return flairSharedInstance({
    method: 'POST',
    url: '/schools',
    data,
    signal,
  });
};

/**
 * @param {string} id
 * @param {Record} data
 * @param {AbortSignal?} signal
 */
export const updateSchool = (id, data, signal) => {
  return flairSharedInstance({
    method: 'PATCH',
    url: `/schools/${id}`,
    data,
    signal,
  });
};

/**
 * @param {string} schoolId
 * @param {string} dsoId
 * @param {AbortSignal?} signal
 */
export const attchDsoToSchool = (schoolId, dsoId, signal) => {
  return flairSharedInstance({
    method: 'PATCH',
    url: `/schools/${schoolId}/attach-dso`,
    data: { dsoId },
    signal,
  });
};

/**
 * @param {string} schoolId
 * @param {string} dsoId
 * @param {AbortSignal?} signal
 */
export const detachDsoFromSchool = (schoolId, dsoId, signal) => {
  return flairSharedInstance({
    method: 'PATCH',
    url: `/schools/${schoolId}/detach-dso`,
    data: { dsoId },
    signal,
  });
}

/**
 * @param {string} id
 * @param {AbortSignal} signal
 */
export const deleteSchool = (id, signal) => {
  return flairSharedInstance({
    method: 'DELETE',
    url: `/schools/${id}`,
    signal,
  });
}

/**
 * @param {string} id
 * @param {AbortSignal} signal
 */
export const getSchool = (id, signal) => {
  return flairSharedInstance({
    method: 'GET',
    url: `/schools/${id}`,
    signal,
  });
}

/**
 * @param {AbortSignal} signal
 */
export const getAllSchools = (signal) => {
  return flairSharedInstance({
    method: 'GET',
    url: '/schools',
    signal,
  });
}

/**
 * @param {Record} data
 * @param {AbortSignal} signal
 */
export const createDso = (data, signal) => {
  return flairSharedInstance({
    method: 'POST',
    url: '/dso',
    data,
    signal,
  });
}

/**
 * @param {string} id
 * @param {Record} data
 * @param {AbortSignal} signal
 */
export const updateDso = (id, data, signal) => {
  return flairSharedInstance({
    method: 'PATCH',
    url: `/dso/${id}`,
    data,
    signal,
  });
}

/**
 * @param {string} id
 * @param {AbortSignal} signal
 */
export const deleteDso = (id, signal) => {
  return flairSharedInstance({
    method: 'DELETE',
    url: `/dso/${id}`,
    signal,
  });
}

/**
 * @param {string} id
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const getDso = (id, signal) => {
  return flairSharedInstance({
    method: 'GET',
    url: `/dso/${id}`,
    signal,
  });
}

/**
 * @param {AbortSignal?} signal
 * @returns {Promise<Record[]>}
 */
export const getAllDsos = (signal) => {
  return flairSharedInstance({
    method: 'GET',
    url: '/dso',
    signal,
  });
}

/**
 * @param {string} schoolId
 * @param {AbortSignal?} signal
 * @returns {Promise<Record[]>}
 */
export const getDsosBySchoolId = (schoolId, signal) => {
  return flairSharedInstance({
    method: 'GET',
    url: `/dso?schoolId=${schoolId}`,
    signal,
  });
};