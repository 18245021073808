import React, { useEffect, useContext, useState } from 'react'
import Presentation from "./Presentation"
import { Context } from "../component_state/context"

function Container() {
  const [state, handle] = useContext(Context);
  const setState = (obj) => {
    handle({
      type: "SET_STATE",
      data: obj
    })
  }
  return (
    <div>
      <Presentation
        state={state}
        setState={setState}
      />
    </div>
  )
}

export default Container
