import React, { useState } from "react"
import { Tab, Tabs, Grid } from "@material-ui/core"
import TabPanel from "../../../../../shared/tabPanel"
import useStyles from "../../../styles/moreArticleStyles"
import Articles from "../Articles"

function Presentation(props) {
  const { categories } = props
  const [value, setValue] = useState(0)
  const handleChange = (_, v) => {
    setValue(v)
  }
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {categories.map((category, index) => {
          return <Tab label={category.name} key={index} />
        })}
      </Tabs>
      <Grid container spacing={2}>
        {categories.map((category, index) => {
          return (
            <Grid key={index} item xs={12}>
              <TabPanel value={value} index={index}>
                <Articles
                  categoryName={category.name}
                  categoryId={category.id}
                />
              </TabPanel>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

export default Presentation
