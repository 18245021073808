import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Constants } from "../../../../../../shared/constants";
import { Formik, Form, Field } from "formik";
import State from "../../../../../../shared/components/BootStrapFormFields/StateField";
import Country from "../../../../../../shared/components/BootStrapFormFields/CountryField";
import * as yup from "yup";
import TextFormField from "../../../../../../shared/components/BootStrapFormFields/TextFormField";
import SelectFormField from "../../../../../../shared/components/BootStrapFormFields/SelectFormField";
import FileUploadForm from "../../../../../../shared/components/BootStrapFormFields/FileUploadForm";
import AppModalHeader from "../../../../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../../../../shared/components/Modals/AppModalFooter";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateEmployee } from "../../../../../../api/services/default/employee";
import YearPickerField from "../../../../../../shared/components/BootStrapFormFields/DatePickerField";
import { useSelector } from "react-redux";

const schema = yup.object().shape({
	schoolname: yup.string().required("School name is required"),
	selectfield: yup.string().required("Degree is required"),
	certificate: yup.string().required("Certificate is required"),
	startdate: yup.string().required("Start date is required"),
	enddate: yup
		.string()
		.required("End date is required")
		.test(
			"checkEndDate",
			"End date should be greater than start date",
			function (value) {
				const { startdate } = this.parent;
				return new Date(value) > new Date(startdate);
			}
		),
	line1: yup.string().required("Address is required"),
  line2: yup.string(),
	city: yup.string().required("City is required"),
	country: yup.string().required("Country is required"),
	state: yup.string().required("State is required"),
	ZipCode: yup.number().required("ZipCode is required"),
});

export default function EducationHistoryV2EditModal(props) {
	const { data, employeeID, idx, onHide } = props;
	const optionMapper = (i) => ({ value: i, label: i });

	const educationFields = useSelector(
		(state) =>
			state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.educationhistory?.fields?.filter(
				(field) => field.name === "selectfield"
			)?.[0]?.values || []
	);

  const queryClient = useQueryClient();

  const useUpdateProfile = useMutation({
		queryKey: ["updateEmployee", employeeID],
		mutationFn: ({ employeeID, data }) => updateEmployee(employeeID, data),
		onSuccess: () => {
			queryClient.invalidateQueries(["getEmployee", employeeID]);
			onHide();
		},
	});

	const loadInitialValues = () => {
		if (!isNaN(idx)) {
			return data[idx];
		} else {
			return {};
		}
	};

	const getTitleText = () => {
		if (!isNaN(idx)) {
			return "Edit Education History";
		} else {
			return "Create Education History";
		}
	};

	const getSaveButtonText = () => {
		if (!isNaN(idx)) {
			return "Save Changes";
		} else {
			return "Create";
		}
	};

	const handleSubmit = async (values, actions) => {
		actions.setSubmitting(true);
		const formData = { ...values };

		let educationhistory = data?.map((item) => item) || [];
		if (!isNaN(idx)) {
			educationhistory[idx] = formData;
		} else {
			educationhistory.push(formData);
		}

		const payload = {
			educationhistory,
		};

		await useUpdateProfile.mutateAsync({ employeeID, data: payload });
		actions.setSubmitting(false);
	};

	return (
		<Modal
			{...props}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Formik
				validationSchema={schema}
				initialValues={loadInitialValues()}
				onSubmit={handleSubmit}
			>
				{(formik) => {
					return (
						<Form>
							<AppModalHeader title={getTitleText()} />
							<Modal.Body>
								<Row>
									<Col xl={4}>
										<Field
											name="schoolname"
											type="text"
											label={"School Name *"}
											component={TextFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="selectfield"
											type="select"
											options={educationFields.map(optionMapper)}
											label={"Select Field *"}
											component={SelectFormField}
										/>
									</Col>

									<Col xl={4}>
										<Field
											name="startdate"
											type="text"
											label={"Start Date *"}
											component={YearPickerField}
											showYearPicker={true}
											dateFormat={"yyyy"}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="enddate"
											type="text"
											label={"End Date *"}
											component={YearPickerField}
											showYearPicker={true}
											dateFormat={"yyyy"}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="line1"
											type="text"
											label={"Line 1 *"}
											component={TextFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="line2"
											type="text"
											label={"Line 2"}
											component={TextFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="city"
											type="text"
											label={"City *"}
											component={TextFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="country"
											type="select"
											label={"Country *"}
											component={Country}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="state"
											type="select"
											country={formik.values.country}
											label={"State *"}
											component={State}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="ZipCode"
											type="text"
											label={"Zip Code *"}
											component={TextFormField}
										/>
									</Col>
									<Col xl={4}>
										<Field
											name="certificate"
											component={FileUploadForm}
											label="Certificate *"
											type="text"
											employeeId={employeeID}
											sectionKey="educationhistory"
										/>
									</Col>
								</Row>
							</Modal.Body>
							<AppModalFooter
								saveButtonText={getSaveButtonText()}
								saveButtonDisabled={
									!formik.isValid || formik.isSubmitting || !formik.dirty
								}
							/>
						</Form>
					);
				}}
			</Formik>
		</Modal>
	);
}
