import React, { useEffect, useState } from "react";
import HomePage from "../HomePage";
import { useSelector } from "react-redux";
import Presentation from "./Presentation";
import ExpiredDocTable from "../ExpiredDocTable";
const Container = () => {
  const [state, setstate] = useState(false);
  let employeeId = useSelector((state) => state.firebase.auth.uid);
  let empModules = useSelector(
    (state) => state.employee.employeeModules.accessModules
  );
  useEffect(() => {
    if (
      empModules.includes("employees-manager") ||
      empModules.includes("console-customization")
    ) {
      setstate(true);
    }
  }, []);

  return (
    <div>
      {state ? <HomePage /> : <ExpiredDocTable employeeId={employeeId} />}
      <Presentation />
    </div>
  );
};

export default Container;
