import { getIn } from "formik";
import moment from "moment";
import React from "react";
import { Form } from "react-bootstrap";

export default function DateFormField({ field, form, required, ...props }) {
  const errorText =
 getIn(form.errors, field.name);

  const handleDate = (e) => {
    if (e.target.value) {
      const convertedValue = moment(e.target.value, "YYYY-MM-DD").format("MM/DD/YYYY");
      return form.setFieldValue(field.name, convertedValue, true);
    } else {
      return form.setFieldValue(field.name, null, true);
    }
  };

  const value = field.value
    ? moment(field.value, "MM/DD/YYYY").format("YYYY-MM-DD")
    : null;

  return (
    <Form.Group className="mb-3">
      {props.label && <Form.Label>{props.label}</Form.Label>}
      <Form.Control
        //value={data?.firstname}
        isInvalid={Boolean(errorText)}
        {...field}
        {...props}
        type="date"
        value={value}
        onChange={handleDate}
      />
      <Form.Control.Feedback type="invalid" tooltip>
        {errorText}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
