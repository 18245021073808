import ACTION from "../actions/actionTypes";
import initState from "./sectionsDataState";

const sectionsDataReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case ACTION.SET_LISTENER:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          listener: payload.listener,
        },
      };

    case ACTION.TIMESHEETS_PLACEMENTS_REQ:
      return {
        ...state,
        timesheets: {
          ...state.timesheets,
          isLoading: true,
        },
      };

    case ACTION.TIMESHEETS_PLACEMENTS_SUCCESS:
      return {
        ...state,
        timesheets: {
          ...state.timesheets,
          ...payload,
          isLoading: false,
        },
      };

    case ACTION.TIMESHEETS_PLACEMENTS_FAILURE:
      return {
        ...state,
        timesheets: {
          ...state.timesheets,
          error: payload,
          isLoading: false,
        },
      };

    default:
      return state;
  }
};

export default sectionsDataReducer;
