import { useQuery } from "@tanstack/react-query";
import { getIn } from "formik";
import React from "react";
import { loadCountries } from "../../../api/services/default/geo";
import { Form } from "react-bootstrap";
import Select from "react-select";

export default function CountryField({ field, form, required, onChangeCallback, ...props }) {
	const errorText =
		 getIn(form.errors, field.name);

	const { data } = useQuery({
		queryKey: ["countries"],
		queryFn: () => loadCountries(),
	});

	const options =
		data?.map((item) => ({
			value: item.name,
			label: item.name,
      isoCode: item.iso2,
		})) || [];

	return (
		<Form.Group className="mb-3">
			{props.label && <Form.Label>{props.label}</Form.Label>}
			<Select
				theme={(theme) => ({
					...theme,
					colors: {
						...theme.colors,
						primary: "#ced4da",
					},
				})}
				value={options?.find((res) => res.value === field.value)}
				options={options}
				onBlur={() => form.setFieldTouched(field.name, true, true)}
				onChange={(option) => {
          // form.setFieldValue("isoCode", option.isoCode);
					if (onChangeCallback) {
						onChangeCallback(option);
					}
					return form.setFieldValue(field.name, option.value, true);
				}}
				name={field.name}
				closeMenuOnSelect={true}
				escapeClearsValue={true}
			/>
			{errorText && <Form.Text className="text-danger">{errorText}</Form.Text>}
		</Form.Group>
	);
}
