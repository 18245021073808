import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const Timesheets = (props) => {
    const { timesheets } = props
    return (
        <Card>
            <Card.Body>
                <h5 className="card-title mb-2 header-title">Timesheets</h5>
                <Row>
                <Col>
                        <div className="uoweg pb-0">
                            <div className="text-muted">All timesheets</div>
                            <div className="fs-18 fw-bold text-base">{timesheets?.allTimesheetsRef}</div>
                        </div>
                    </Col>
                    <Col>
                        <div className="uoweg">
                            <div className="text-muted">Approved Timesheets</div>
                            <div className="fs-18 fw-bold text-base">{timesheets?.approvedTimesheets}</div>
                        </div>
                    </Col>                    
                </Row>
                <Row>
                <Col>
                        <div className="uoweg red">
                            <div className="text-muted red">Rejected</div>
                            <div className="fs-18 fw-bold text-base red">{timesheets?.rejectedTimesheets}</div>
                        </div>
                    </Col>
                    <Col>
                        <div className="uoweg pb-0">
                            <div className="text-muted">Submitted</div>
                            <div className="fs-18 fw-bold text-base">{timesheets?.submittedTimesheets}</div>
                        </div>
                    </Col>  
                </Row>
            </Card.Body>
        </Card>
    );
};
export default Timesheets;