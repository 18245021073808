import React from "react";
import { useDialog } from "../../../hooks/useDialog";
import { Modal } from "react-bootstrap";
import AppModalHeader from "../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../shared/components/Modals/AppModalFooter";
import { Formik, Form, Field } from "formik";
import TextFormField from "../../../shared/components/BootStrapFormFields/TextFormField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
	createBonusPay,
	updateBonusPay,
} from "../../../api/services/default/payrolls/reports";
import { CalendarFormatter } from "../../../shared/CalendarFormatter";

export default function AddBonusPay({
	employeeID,
	reportingDate,
	isFreezed,
	canEdit,
	bonusPay,
	bonusPayData = {},
}) {
	const { open, handleOpen, handleClose } = useDialog();

	const queryClient = useQueryClient();

	const useCreateBonusPay = useMutation({
		mutationFn: ({ data }) => createBonusPay(data),
	});

	const useUpdateBonusPay = useMutation({
		mutationFn: ({ id, data }) => updateBonusPay(id, data),
	});

	const handleSubmit = async (values, actions) => {
		actions.setSubmitting(true);
		const data = {
			employeeID,
			reportingDate: CalendarFormatter.standardDateFormat(reportingDate),
			bonusPay: values.bonusPay,
		};
		if (bonusPayData?.id) {
			await useUpdateBonusPay.mutateAsync({ id: bonusPayData?.id, data });
		} else {
			await useCreateBonusPay.mutateAsync({ data });
		}
		await queryClient.invalidateQueries(["getGeneralReport", reportingDate]);
		actions.resetForm();
		actions.setSubmitting(false);
		handleClose();
	};

	if (!canEdit) {
		return <span className="text-black">{bonusPay}</span>;
	}

	return (
		<>
			<button
				className="btn"
				style={{ marginTop: "-8px" }}
				onClick={handleOpen}
			>
				<span className="text-black">{bonusPay}</span>
			</button>
			<Modal show={open} onHide={handleClose} centered size="md">
				<Formik
					onSubmit={handleSubmit}
					initialValues={{
						bonusPay: bonusPayData?.bonusPay || "",
					}}
				>
					{({ dirty, isSubmitting, values }) => (
						<Form id="addBonusPayForm">
							<AppModalHeader title="Edit Deduction" />
							<Modal.Body>
								<Field
									name="bonusPay"
									type="number"
									label="Bonus Pay"
									component={TextFormField}
								/>
							</Modal.Body>
							<AppModalFooter
								formId="addBonusPayForm"
								saveButtonDisabled={!dirty || isSubmitting || !values.bonusPay}
							/>
						</Form>
					)}
				</Formik>
			</Modal>
		</>
	);
}
