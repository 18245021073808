import React from "react";
import { Link } from "react-router-dom";
import profilePic from "../../../../../assets/images/users/avatar-1.jpg";

const Issuers = (props) => {
  const { Issuers } = props;
  console.log("issueeeeeeee", Issuers);
  return (
    <div className="card-border mt-24">
      <div className="d-flex justify-content-between card-border-title">
        <div className="">Issuers</div>
      </div>
      <div className="scrollspy-example" style={{ height: 280 }}>
        <div className="card-lists py-1">
          <div className="d-flex">
            <img src={profilePic} className="avatar-sm rounded-circle" alt="" />
            <span className="align-self-center ps-2 fs-5">1Devid John</span>
          </div>
        </div>
        <div className="card-lists py-1">
          <div className="d-flex">
            <img src={profilePic} className="avatar-sm rounded-circle" alt="" />
            <span className="align-self-center ps-2 fs-5">
              2Arthur N. Adame
            </span>
          </div>
        </div>
        <div className="card-lists py-1">
          <div className="d-flex">
            <img src={profilePic} className="avatar-sm rounded-circle" alt="" />
            <span className="align-self-center ps-2 fs-5">3Devid John</span>
          </div>
        </div>
        <div className="card-lists py-1">
          <div className="d-flex">
            <img src={profilePic} className="avatar-sm rounded-circle" alt="" />
            <span className="align-self-center ps-2 fs-5">
              4James J. Ernest
            </span>
          </div>
        </div>
        <div className="card-lists py-1">
          <div className="d-flex">
            <img src={profilePic} className="avatar-sm rounded-circle" alt="" />
            <span className="align-self-center ps-2 fs-5">5Devid John</span>
          </div>
        </div>
        <div className="card-lists py-1">
          <div className="d-flex">
            <img src={profilePic} className="avatar-sm rounded-circle" alt="" />
            <span className="align-self-center ps-2 fs-5">
              6Arthur N. Adame
            </span>
          </div>
        </div>
        <div className="card-lists py-1">
          <div className="d-flex">
            <img src={profilePic} className="avatar-sm rounded-circle" alt="" />
            <span className="align-self-center ps-2 fs-5">7Devid John</span>
          </div>
        </div>
        <div className="card-lists py-1">
          <div className="d-flex">
            <img src={profilePic} className="avatar-sm rounded-circle" alt="" />
            <span className="align-self-center ps-2 fs-5">
              8James J. Ernest
            </span>
          </div>
        </div>
        <div className="card-lists py-1">
          <div className="d-flex">
            <img src={profilePic} className="avatar-sm rounded-circle" alt="" />
            <span className="align-self-center ps-2 fs-5">9Devid John</span>
          </div>
        </div>
        <div className="card-lists py-1">
          <div className="d-flex">
            <img src={profilePic} className="avatar-sm rounded-circle" alt="" />
            <span className="align-self-center ps-2 fs-5">
              10Arthur N. Adame
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Issuers;
