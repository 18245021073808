import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";

function TimesheetsByBranches(props) {
  const { TimesheetsByBranches: data } = props;
  const totalBranchList = useSelector(
    (state) =>
      state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.personal?.fields?.filter(
        (field) => field.name === "branch"
      )?.[0]?.values || []
  );
  const [branches, setBranches] = useState([]);
  const [submittedCountByBranch, setSubmittedCountByBranch] = useState([]);
  const [approvedCountByBranch, setApprovedCountByBranch] = useState([]);
  const [rejectedCountByBranch, setRejectedCountByBranch] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const branchCounts = {};

    data?.submittedTimesheetByBranch?.forEach((timesheet) => {
      timesheet.branches.forEach((branch) => {
        if (!branchCounts[branch.branch]) {
          branchCounts[branch.branch] = {
            submittedCount: branch.count,
            approvedCount: 0,
            rejectedCount: 0,
          };
        } else {
          branchCounts[branch.branch].submittedCount += branch.count;
        }
      });
    });

    data?.approvedTimesheetsByBranch?.forEach((timesheet) => {
      timesheet.branches.forEach((branch) => {
        if (!branchCounts[branch.branch]) {
          branchCounts[branch.branch] = {
            submittedCount: 0,
            approvedCount: branch.count,
            rejectedCount: 0,
          };
        } else {
          branchCounts[branch.branch].approvedCount += branch.count;
        }
      });
    });

    data?.rejectedTimesheetsByBranch?.forEach((timesheet) => {
      timesheet.branches.forEach((branch) => {
        if (!branchCounts[branch.branch]) {
          branchCounts[branch.branch] = {
            submittedCount: 0,
            approvedCount: 0,
            rejectedCount: branch.count,
          };
        } else {
          branchCounts[branch.branch].rejectedCount += branch.count;
        }
      });
    });

    const _branches = Object.keys(branchCounts);
    totalBranchList?.forEach((i) => {
      if (!_branches.includes(i)) {
        _branches.push(i);
      }
    });
    const _submittedCountByBranch = _branches.map(
      (branch) => branchCounts[branch]?.submittedCount ?? 0
    );
    const _approvedCountByBranch = _branches.map(
      (branch) => branchCounts[branch]?.approvedCount ?? 0
    );
    const _rejectedCountByBranch = _branches.map(
      (branch) => branchCounts[branch]?.rejectedCount ?? 0
    );

    setBranches(_branches);
    setSubmittedCountByBranch(_submittedCountByBranch);
    setApprovedCountByBranch(_approvedCountByBranch);
    setRejectedCountByBranch(_rejectedCountByBranch);
    setLoading(false);
  }, [data, totalBranchList]);

  const apexBarChartOpts = {
    chart: {
      foreColor: "#979797",
      height: 300,
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "60%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 7,
      colors: ["transparent"],
    },
    colors: ["#FFAB00", "#A9CB8E", "#FF8F73"],
    legend: {
      show: true,
    },
    xaxis: {
      categories: branches,
      axisBorder: {
        show: true,
        color: "rgba(0,0,0,0.05)",
      },
      axisTicks: {
        show: true,
        color: "rgba(0,0,0,0.05)",
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
        color: "rgba(0,0,0,0.05)",
      },
      axisTicks: {
        show: true,
        color: "rgba(0,0,0,0.05)",
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: ["transparent", "transparent"],
        opacity: 0.2,
      },
      borderColor: "rgba(0, 0, 0, .1)",
    },
    fill: {
      opacity: 1,
    },
  };

  const apexBarChartData = [
    {
      name: "Submitted",
      data: submittedCountByBranch,
    },
    {
      name: "Approved",
      data: approvedCountByBranch,
    },
    {
      name: "Rejected",
      data: rejectedCountByBranch,
    },
  ];

  if (loading) return <p>Loading...</p>;
  return (
    <div>
      <Card>
        <Card.Body>
          <h5 className="card-title mb-0 header-title">
            Timesheets By Branches
          </h5>
          <Chart
            options={apexBarChartOpts}
            series={apexBarChartData}
            type="bar"
            className="apex-charts mt-1"
            height={270}
            dir="ltr"
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default TimesheetsByBranches;
