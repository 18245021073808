import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { deleteEmployee, inviteEmployee, getTotalAndActivePlacements,movingEmployee } from "../../middleware";
import Presentation from "./Presentation";
import { compose } from "redux";
import { firestoreConnect,isLoaded } from "react-redux-firebase";
import CircularSpinner from "../../../../shared/circularSpinner"

function Container(props) {
const { branchList,onDelete,staff_list } = props
const [placements, setPlacements] = useState([])
  const onInviteAgain = (listOfMails) => {
    const { inviteEmployee } = props;
    listOfMails.forEach((list) => {
      inviteEmployee({
        firstname: list.firstname,
        lastname: list.lastname,
        phonenumber: list.phone,
        email: list.email,
        dob: "",
        branch: list.employeeBranch,
      });
    });
  };

  const fetchPlacements = async () => {
    try {
      const data = await getTotalAndActivePlacements()
      setPlacements(data)
    } catch (error) {
      console.error(error);
    }
  }

  const onMovingEmployee = async (uid,payload) => {
    await movingEmployee(uid,payload)
  }

  useEffect(() => {
    fetchPlacements()
  }, [])

  return isLoaded(staff_list) ? (
    <div>
      <Presentation branchList={branchList} onDelete={onDelete} onInviteAgain={onInviteAgain} placements={placements} staff_list={staff_list} onMovingEmployee={onMovingEmployee} />
    </div>
  ) : (
    <CircularSpinner />
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    branchList: state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.personal?.fields?.filter((field) => field.name === "branch")?.[0]?.values || [],
    staff_list:state.firestore.ordered.staff_list
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    inviteEmployee: (payload) => {
      dispatch(inviteEmployee(payload));
    },
    onDelete: (uid) => {
      dispatch(deleteEmployee(uid));
    }
  };
};

export default compose(
  connect(mapStateToProps,mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "EMPLOYEES",
        where: [["isExist", "==", true],["type","==","staff"]],
        storeAs: "staff_list",
      }
    ]
  })
)(Container)
