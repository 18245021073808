import React from "react"
import ViewCompanyDetails from "../Services/Console/components/CompanyDetails/ViewCompanyDetails"

function CompanyDetailsPage(props) {
  return (
    <div>
      <ViewCompanyDetails />
    </div>
  )
}

export default CompanyDetailsPage
