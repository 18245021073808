import React from "react";
import { Row, Col, Card } from "react-bootstrap";

const dateCount = (props) => {
  const { Timesheets, Defaulters } = props;
  return (
    <Row className="mt-2">
      <Col lg={3} md={6} sm={6}>
        <div className="card-border keystates mb-24">
          <div className="d-flex justify-content-between">
            <div className="iconfont">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2141_24175)">
                  <path
                    d="M4 28H6V30C6 30.5304 6.21071 31.0391 6.58579 31.4142C6.96086 31.7893 7.46957 32 8 32H28C28.5304 32 29.0391 31.7893 29.4142 31.4142C29.7893 31.0391 30 30.5304 30 30V6C30 5.46957 29.7893 4.96086 29.4142 4.58579C29.0391 4.21071 28.5304 4 28 4H26V2C26 1.46957 25.7893 0.960859 25.4142 0.585786C25.0391 0.210714 24.5304 0 24 0L4 0C3.46957 0 2.96086 0.210714 2.58579 0.585786C2.21071 0.960859 2 1.46957 2 2V26C2 26.5304 2.21071 27.0391 2.58579 27.4142C2.96086 27.7893 3.46957 28 4 28ZM28 6V30H8V28H24C24.5304 28 25.0391 27.7893 25.4142 27.4142C25.7893 27.0391 26 26.5304 26 26V6H28ZM21 4C21.2652 4 21.5196 4.10536 21.7071 4.29289C21.8946 4.48043 22 4.73478 22 5C22 5.26522 21.8946 5.51957 21.7071 5.70711C21.5196 5.89464 21.2652 6 21 6H12.916C12.6508 6 12.3964 5.89464 12.2089 5.70711C12.0214 5.51957 11.916 5.26522 11.916 5C11.916 4.73478 12.0214 4.48043 12.2089 4.29289C12.3964 4.10536 12.6508 4 12.916 4H21ZM6.916 10H21C21.2652 10 21.5196 10.1054 21.7071 10.2929C21.8946 10.4804 22 10.7348 22 11C22 11.2652 21.8946 11.5196 21.7071 11.7071C21.5196 11.8946 21.2652 12 21 12H6.916C6.65078 12 6.39643 11.8946 6.20889 11.7071C6.02136 11.5196 5.916 11.2652 5.916 11C5.916 10.7348 6.02136 10.4804 6.20889 10.2929C6.39643 10.1054 6.65078 10 6.916 10ZM6.916 16H21C21.2652 16 21.5196 16.1054 21.7071 16.2929C21.8946 16.4804 22 16.7348 22 17C22 17.2652 21.8946 17.5196 21.7071 17.7071C21.5196 17.8946 21.2652 18 21 18H6.916C6.65078 18 6.39643 17.8946 6.20889 17.7071C6.02136 17.5196 5.916 17.2652 5.916 17C5.916 16.7348 6.02136 16.4804 6.20889 16.2929C6.39643 16.1054 6.65078 16 6.916 16ZM6.916 22H21C21.2652 22 21.5196 22.1054 21.7071 22.2929C21.8946 22.4804 22 22.7348 22 23C22 23.2652 21.8946 23.5196 21.7071 23.7071C21.5196 23.8946 21.2652 24 21 24H6.916C6.65078 24 6.39643 23.8946 6.20889 23.7071C6.02136 23.5196 5.916 23.2652 5.916 23C5.916 22.7348 6.02136 22.4804 6.20889 22.2929C6.39643 22.1054 6.65078 22 6.916 22Z"
                    fill="#ffbe0b"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2141_24175">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div className="mt-1 fw-bold fs-15">Submitted</div>
            </div>
            <h1 className="my-0 align-self-center text-warning">
              {Timesheets?.submittedTimesheets}
            </h1>
          </div>
        </div>
      </Col>
      <Col lg={3} md={6} sm={6}>
        <div className="card-border keystates mb-24">
          <div className="d-flex justify-content-between">
            <div className="iconfont">
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.0992 17.1992C20.6992 17.1992 17.1992 20.6992 17.1992 25.0992C17.1992 29.4992 20.6992 32.9992 25.0992 32.9992C29.3992 32.9992 32.9992 29.4992 32.9992 25.0992C32.8992 20.6992 29.3992 17.1992 25.0992 17.1992ZM28.6992 23.6992L24.6992 27.6992C24.4992 27.8992 24.1992 27.9992 23.9992 27.9992C23.7992 27.9992 23.4992 27.8992 23.2992 27.6992L21.2992 25.6992C20.8992 25.2992 20.8992 24.6992 21.2992 24.2992C21.6992 23.8992 22.2992 23.8992 22.6992 24.2992L23.9992 25.5992L27.2992 22.2992C27.6992 21.8992 28.2992 21.8992 28.6992 22.2992C29.0992 22.6992 29.0992 23.2992 28.6992 23.6992Z"
                  fill="#2C9273"
                />
                <path
                  d="M21.1016 1H4.10156C2.40156 1 1.10156 2.4 1.10156 4V28C1.10156 29.7 2.40156 31 4.10156 31H17.1016C15.9016 29.4 15.1016 27.3 15.1016 25.1C15.1016 24.7 15.1016 24.4 15.2016 24H6.10156C5.50156 24 5.10156 23.6 5.10156 23C5.10156 22.5 5.50156 22 6.10156 22H15.7016C15.9016 21.3 16.2016 20.6 16.6016 20H6.10156C5.50156 20 5.10156 19.6 5.10156 19C5.10156 18.5 5.50156 18 6.10156 18H18.2016C19.8016 16.5 21.8016 15.5 24.1016 15.2V4C24.1016 2.4 22.7016 1 21.1016 1ZM6.10156 6H14.1016C14.6016 6 15.1016 6.5 15.1016 7C15.1016 7.6 14.6016 8 14.1016 8H6.10156C5.50156 8 5.10156 7.6 5.10156 7C5.10156 6.5 5.50156 6 6.10156 6ZM19.1016 16H6.10156C5.50156 16 5.10156 15.6 5.10156 15C5.10156 14.5 5.50156 14 6.10156 14H19.1016C19.6016 14 20.1016 14.5 20.1016 15C20.1016 15.6 19.6016 16 19.1016 16ZM19.1016 12H6.10156C5.50156 12 5.10156 11.6 5.10156 11C5.10156 10.5 5.50156 10 6.10156 10H19.1016C19.6016 10 20.1016 10.5 20.1016 11C20.1016 11.6 19.6016 12 19.1016 12Z"
                  fill="#2C9273"
                />
              </svg>
              <div className="mt-1 fw-medium fs-15">Approved</div>
            </div>
            <h1 className="my-0 align-self-center text-success">
              {Timesheets?.approvedTimesheets}
            </h1>
          </div>
        </div>
      </Col>
      <Col lg={3} md={6} sm={6}>
        <div className="card-border keystates mb-24">
          <div className="d-flex justify-content-between">
            <div className="iconfont">
              <svg
                width="31"
                height="35"
                viewBox="0 0 31 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 28H4V30C4 30.5304 4.21071 31.0391 4.58579 31.4142C4.96086 31.7893 5.46957 32 6 32H26C26.5304 32 27.0391 31.7893 27.4142 31.4142C27.7893 31.0391 28 30.5304 28 30V6C28 5.46957 27.7893 4.96086 27.4142 4.58579C27.0391 4.21071 26.5304 4 26 4H24V2C24 1.46957 23.7893 0.960859 23.4142 0.585786C23.0391 0.210714 22.5304 0 22 0L2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V26C0 26.5304 0.210714 27.0391 0.585786 27.4142C0.960859 27.7893 1.46957 28 2 28ZM26 6V30H6V28H22C22.5304 28 23.0391 27.7893 23.4142 27.4142C23.7893 27.0391 24 26.5304 24 26V6H26ZM19 4C19.2652 4 19.5196 4.10536 19.7071 4.29289C19.8946 4.48043 20 4.73478 20 5C20 5.26522 19.8946 5.51957 19.7071 5.70711C19.5196 5.89464 19.2652 6 19 6H10.916C10.6508 6 10.3964 5.89464 10.2089 5.70711C10.0214 5.51957 9.916 5.26522 9.916 5C9.916 4.73478 10.0214 4.48043 10.2089 4.29289C10.3964 4.10536 10.6508 4 10.916 4H19ZM4.916 10H19C19.2652 10 19.5196 10.1054 19.7071 10.2929C19.8946 10.4804 20 10.7348 20 11C20 11.2652 19.8946 11.5196 19.7071 11.7071C19.5196 11.8946 19.2652 12 19 12H4.916C4.65078 12 4.39643 11.8946 4.20889 11.7071C4.02136 11.5196 3.916 11.2652 3.916 11C3.916 10.7348 4.02136 10.4804 4.20889 10.2929C4.39643 10.1054 4.65078 10 4.916 10ZM4.916 16H19C19.2652 16 19.5196 16.1054 19.7071 16.2929C19.8946 16.4804 20 16.7348 20 17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H4.916C4.65078 18 4.39643 17.8946 4.20889 17.7071C4.02136 17.5196 3.916 17.2652 3.916 17C3.916 16.7348 4.02136 16.4804 4.20889 16.2929C4.39643 16.1054 4.65078 16 4.916 16ZM4.916 22H19C19.2652 22 19.5196 22.1054 19.7071 22.2929C19.8946 22.4804 20 22.7348 20 23C20 23.2652 19.8946 23.5196 19.7071 23.7071C19.5196 23.8946 19.2652 24 19 24H4.916C4.65078 24 4.39643 23.8946 4.20889 23.7071C4.02136 23.5196 3.916 23.2652 3.916 23C3.916 22.7348 4.02136 22.4804 4.20889 22.2929C4.39643 22.1054 4.65078 22 4.916 22Z"
                  fill="#ff5c75"
                />
                <circle cx="23.5" cy="27.5" r="7.5" fill="white" />
                <path
                  d="M23.5 20C19.375 20 16 23.375 16 27.5C16 31.625 19.375 35 23.5 35C27.625 35 31 31.625 31 27.5C31 23.375 27.625 20 23.5 20ZM26.275 29.225C26.575 29.525 26.575 29.975 26.275 30.275C25.975 30.575 25.525 30.575 25.225 30.275L23.5 28.55L21.775 30.275C21.475 30.575 21.025 30.575 20.725 30.275C20.425 29.975 20.425 29.525 20.725 29.225L22.45 27.5L20.725 25.775C20.425 25.475 20.425 25.025 20.725 24.725C21.025 24.425 21.475 24.425 21.775 24.725L23.5 26.45L25.225 24.725C25.525 24.425 25.975 24.425 26.275 24.725C26.575 25.025 26.575 25.475 26.275 25.775L24.55 27.5L26.275 29.225Z"
                  fill="#D92D20"
                />
              </svg>
              <div className="mt-1 fw-medium fs-15">Rejected</div>
            </div>
            <h1 className="my-0 align-self-center text-danger">
              {Timesheets?.rejectedTimesheets}
            </h1>
          </div>
        </div>
      </Col>
      <Col lg={3} md={6} sm={6}>
        <div className="card-border keystates mb-24">
          <div className="d-flex justify-content-between">
            <div className="iconfont">
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 3C9.75 3 3 9.75 3 18C3 26.25 9.75 33 18 33C26.25 33 33 26.25 33 18C33 9.75 26.25 3 18 3ZM18 25.5C17.1 25.5 16.5 24.9 16.5 24C16.5 23.1 17.1 22.5 18 22.5C18.9 22.5 19.5 23.1 19.5 24C19.5 24.9 18.9 25.5 18 25.5ZM19.5 18C19.5 18.9 18.9 19.5 18 19.5C17.1 19.5 16.5 18.9 16.5 18V12C16.5 11.1 17.1 10.5 18 10.5C18.9 10.5 19.5 11.1 19.5 12V18Z"
                  fill="#6f42c1"
                />
              </svg>
              <div className="mt-1 fw-medium fs-15">Defaulters</div>
            </div>
            <h1 className="my-0 align-self-center text-purple">{Defaulters}</h1>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default dateCount;
