import { combineReducers } from "redux";
import { createInvoiceReducer } from "./createInvoiceReducer";
import invoiceListReducer from "./invoiceList";
import { getInvoiceLocationClients } from "./clientLocationInvoice";

const rootReducer = combineReducers({
  createInvoice: createInvoiceReducer,
  invoiceList: invoiceListReducer,
  clientsInvoiceLocation: getInvoiceLocationClients,
});

export default rootReducer;
