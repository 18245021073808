import React from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import validate from "../../../../shared/validation";
import { BsDownload } from "react-icons/bs";
import { Chip, Button } from "@material-ui/core";
import { downloadasZip as downloadDocuments } from "../../../../shared/downloadZip";
import MetaInfo from "../../../../shared/getMetaInfo";

function Presentation(props) {
  const { state, getDocuments, employeeID } = props;
  const columns = [
    {
      title: "Document type",
      field: "documentType",
      lookup: {
        0: "Incorporation",
        1: "PO",
        2: "MSA",
        3: "COI",
        4: "W9",
        5: "MSA &PO",
        6: "Others",
        7: "Profile",
        8: "Deductions",
      },
    },
    {
      title: "Status",
      field: "status",
      lookup: { 0: "Active", 1: "Inactive", 2: "--" },
      render: (rowData) => {
        if (rowData.status === 0)
          return (
            <Chip
              style={{
                width: "100%",
                backgroundColor: "#21ba45",
                color: "white",
              }}
              label="Active"
            />
          );
        else if (rowData.status === 1)
          return (
            <Chip
              style={{
                width: "100%",
                backgroundColor: "orange",
                color: "white",
              }}
              label="Inactive"
            />
          );
        return (
          <Chip
            style={{ width: "100%", backgroundColor: "grey", color: "white" }}
            label="-----"
          />
        );
      },
      customFilterAndSearch: (value, rowData) => {
        if (value.length === 0) return true;
        return value.includes(rowData.status.toString());
      },
      width: "125px",
    },
    { title: "Effective Date", field: "effectiveDate", type: "date" },
    { title: "Effective Until", field: "effectiveUntilDate", type: "date" },
    {
      title: "Document",
      field: "work_doc",
      render: (row) => {
        const { url } = row.work_doc;
        return (
          <a target="_blank" rel="noopener noreferrer" href={url}>
            {row.fileName}
          </a>
        );
      },
    },
  ];
  const documentTypes = [
    "Incorporation",
    "PO",
    "MSA",
    "COI",
    "W9",
    "MSA &PO",
    "Others",
  ];
  const data = [];
  if (state.isLoaded) {
    state.data["placements"].forEach((item) => {
      item.map((doc) => {
        return data.push({
          documentType: documentTypes.indexOf(doc.documentType),
          status: new Date(doc.effectiveUntilDate) < new Date() ? 1 : 0,
          work_doc: doc.work_doc,
          fileName: doc.work_doc.name.substr(
            0,
            doc.work_doc.name.lastIndexOf("_")
          ),
          effectiveDate: validate.dateFormatter(doc.effectiveDate),
          effectiveUntilDate: validate.dateFormatter(doc.effectiveUntilDate),
        });
      });
    });
    state.data["profile"].forEach((item, index) => {
      console.log(item);
      if (item.url !== null && item.url !== undefined) {
        data.push({
          documentType: 7,
          status: 0,
          work_doc: {
            url: item.url,
            name: `${new MetaInfo().emailToName(employeeID)}_${
              item.category
            }_${index}`,
          },
          fileName: `${new MetaInfo().emailToName(employeeID)}_${
            item.category
          }_${index}`,
          effectiveDate: "--",
          effectiveUntilDate: "--",
        });
      }
    });
    state.data["deductions"].forEach((item, index) => {
      console.log(item)
      if(Object.keys(item).length>0){
        data.push({
          documentType: 8,
          status: 0,
          work_doc: {
            url: item.url,
            name: `${new MetaInfo().emailToName(
              employeeID
            )}_Deductions_${item.name.substr(0, item.name.lastIndexOf("."))}`,
          },
          fileName: `${new MetaInfo().emailToName(
            employeeID
          )}_Deductions_${item.name.substr(0, item.name.lastIndexOf("."))}`,
          effectiveDate: "--",
          effectiveUntilDate: "--",
        });
      }
    });
  }

  console.log("data", data);
  return (
    <div>
      <MaterialTable
        title=""
        data={data}
        columns={columns}
        isLoading={!state.isLoaded}
        options={{
          pageSize: 10,
          paginationPosition: "top",
          paginationType: "normal",
          selection: true,
          columnsButton: true,
          filtering: true,
        }}
        components={{
          Toolbar: (props) => {
            return (
              <div>
                <MTableToolbar {...props} />
                <div style={{ padding: "0 10px" }}>
                  <Button
                    color="secondary"
                    onClick={(e) => downloadDocuments(e, data, "documents")}
                    variant="contained"
                  >
                    Download all <BsDownload size={20} />
                  </Button>
                </div>
              </div>
            );
          },
        }}
        actions={[
          {
            icon: "refresh",
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () => getDocuments(employeeID),
          },
          {
            tooltip: "Download selected",
            icon: () => <BsDownload size={18} />,
            onClick: (evt, data) => downloadDocuments(evt, data, "documents"),
          },
        ]}
      />
    </div>
  );
}

export default Presentation;
/*
import React, { useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import validate from "../../../../shared/validation";
import { BsDownload } from "react-icons/bs";
import { Chip, Button } from "@material-ui/core";
import { downloadasZip as downloadDocuments } from "../../../../shared/downloadZip";
import MetaInfo from "../../../../shared/getMetaInfo";

function Presentation(props) {
  const { state, getDocuments, employeeID } = props;
  const [isLoading, setIsLoading] = useState(false);
  const columns = [
    {
      title: "Document type",
      field: "documentType",
      lookup: {
        0: "Incorporation",
        1: "PO",
        2: "MSA",
        3: "COI",
        4: "W9",
        5: "MSA &PO",
        6: "Others",
        7: "Profile",
      },
    },
    {
      title: "Status",
      field: "status",
      lookup: { 0: "Active", 1: "Inactive", 2: "--" },
      render: (rowData) => {
        if (rowData.status === 0)
          return (
            <Chip
              style={{
                width: "100%",
                backgroundColor: "#21ba45",
                color: "white",
              }}
              label="Active"
            />
          );
        else if (rowData.status === 1)
          return (
            <Chip
              style={{
                width: "100%",
                backgroundColor: "orange",
                color: "white",
              }}
              label="Inactive"
            />
          );
        return (
          <Chip
            style={{ width: "100%", backgroundColor: "grey", color: "white" }}
            label="-----"
          />
        );
      },
      customFilterAndSearch: (value, rowData) => {
        if (value.length === 0) return true;
        return value.includes(rowData.status.toString());
      },
      width: "125px",
    },
    { title: "Effective Date", field: "effectiveDate", type: "date" },
    { title: "Effective Until", field: "effectiveUntilDate", type: "date" },
    {
      title: "Document",
      field: "work_doc",
      render: (row) => {
        const { url } = row.work_doc;
        return (
          <a target="_blank" rel="noopener noreferrer" href={url}>
            {row.fileName}
          </a>
        );
      },
    },
  ];
  const documentTypes = [
    "Incorporation",
    "PO",
    "MSA",
    "COI",
    "W9",
    "MSA &PO",
    "Others",
  ];
  const data = [];
  if (state.isLoaded) {
    state.data["placements"].forEach((item) => {
      item.map((doc) => {
        return data.push({
          documentType: documentTypes.indexOf(doc.documentType),
          status: new Date(doc.effectiveUntilDate) < new Date() ? 1 : 0,
          work_doc: doc.work_doc,
          fileName: doc.work_doc.name.substr(
            0,
            doc.work_doc.name.lastIndexOf("_")
          ),
          effectiveDate: validate.dateFormatter(doc.effectiveDate),
          effectiveUntilDate: validate.dateFormatter(doc.effectiveUntilDate),
        });
      });
    });
    state.data["profile"].forEach((item, index) => {
      console.log(item);
      if (item.url !== null && item.url !== undefined) {
        data.push({
          documentType: 7,
          status: 0,
          work_doc: {
            url: item.url,
            name: `${new MetaInfo().emailToName(employeeID)}_${
              item.category
            }_${index}`,
          },
          fileName: `${new MetaInfo().emailToName(employeeID)}_${
            item.category
          }_${index}`,
          effectiveDate: "--",
          effectiveUntilDate: "--",
        });
      }
    });
  }

  console.log("data", data);
  return (
    <div>
      <MaterialTable
        title=""
        data={data}
        columns={columns}
        isLoading={isLoading || !state.isLoaded}
        options={{
          pageSize: 10,
          paginationPosition: "top",
          paginationType: "normal",
          selection: true,
          columnsButton: true,
          filtering: true,
        }}
        components={{
          Toolbar: (props) => {
            return (
              <div>
                <MTableToolbar {...props} />
                <div style={{ padding: "0 10px" }}>
                  <Button
                    color="secondary"
                    onClick={(e) => downloadDocuments(e, data, "documents")}
                    variant="contained"
                  >
                    Download all <BsDownload size={20} />
                  </Button>
                </div>
              </div>
            );
          },
        }}
        actions={[
          {
            icon: "refresh",
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () => getDocuments(employeeID),
          },
          {
            tooltip: "Download selected",
            icon: () => <BsDownload size={18} />,
            onClick: (evt, data) => {
              setIsLoading(true);
              downloadDocuments(evt, data, "documents")
                .then(() => {
                  setIsLoading(false);
                })
                .catch((err) => {
                  console.error(err);
                  setIsLoading(false);
                });
            },
          },
        ]}
      />
    </div>
  );
}

export default Presentation;*/