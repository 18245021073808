const ACTION = {
	LOGIN_REQUEST: "LOGIN_REQUEST",
	LOGIN_SUCCESS: "LOGIN_SUCCESS",
	LOGIN_FAILURE: "LOGIN_FAILURE",

	SIGNOUT_REQUEST: "SIGNOUT_REQUEST",
	SIGNOUT_SUCCESS: "SIGNOUT_SUCCESS",
	SIGNOUT_FAILURE: "SIGNOUT_FAILURE",
}

export default ACTION
