import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import TextFormField from "../../../../shared/components/BootStrapFormFields/TextFormField";
import { resetUserTempPassword } from "../../middleware/employeeList";

const schema = Yup.object().shape({
	newPassword: Yup.string()
		.required("Required")
		.test(
			"passwords-length",
			"Password must be at least 8 characters",
			function (value) {
				return value?.length >= 8;
			}
		),
	confirmPassword: Yup.string()
		.required("Required")
		.test("passwords-match", "Passwords must match", function (value) {
			return this.parent.newPassword === value;
		}),
});

export default function ChangeTempPassword() {
	const handleSubmit = async (values, { setSubmitting }) => {
		setSubmitting(true);
		await resetUserTempPassword({ password: values.newPassword });
		setSubmitting(false);
	};

	return (
		<Container>
			<h1>Change Temporary Password</h1>
			<Formik
				initialValues={{ newPassword: "", confirmPassword: "" }}
				validationSchema={schema}
				onSubmit={handleSubmit}
			>
				{({ isSubmitting }) => (
					<Form>
						<Field
							type="password"
							name="newPassword"
							component={TextFormField}
							label="New Password"
							placeholder="Enter new password"
							id="newPassword"
						/>
						<Field
							type="password"
							name="confirmPassword"
							component={TextFormField}
							label="Confirm Password"
							placeholder="Confirm new password"
							id="confirmPassword"
						/>
						<Button variant="primary" type="submit" disabled={isSubmitting}>
							Submit
						</Button>
					</Form>
				)}
			</Formik>
		</Container>
	);
}
