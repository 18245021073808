import React, { useEffect, useContext, useState } from 'react'
import Presentation from "./Presentation"
import { Context } from "../component_state/context"

function Container() {
  const [state, handle] = useContext(Context);

  return (
    <div>
      <Presentation
        state={state}
      />
    </div>
  )
}

export default Container
