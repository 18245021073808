import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Presentation from "./Presentation";
import {
  uploadBusinessInformation,
  uploadLocationsInformation,
} from "../../../../../middleware";

function Container(props) {
  const {
    item,
    index,
    locationsList,
    setLocationsInformation,
    setBusinessInformation,
    businessInformation,
  } = props;
  const [state, setState] = useState({
    locationsline1: "",
    locationsline2: "",
    locationscountry: "",
    locationsstate_name: "",
    locationscity: "",
    locationszip: "",
    countries: [],
    states: [],
    locationId : ""
  });
  const [invoiceLocation, setInvoiceLocation] = useState(false);
  const [locationId,setLocationId] = useState("")
  useEffect(() => {
    Object.entries(item).forEach(([key, value]) => {
      setState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    });
    setInvoiceLocation(
      item.locationId === businessInformation.invoiceLocationId ? true : false
    );
    setLocationId(item.locationId ? item.locationId  : "id" + Math.random().toString(18).slice(2))
  }, [item]);
  const handleInvoiceLocation = () => {
    setInvoiceLocation(!invoiceLocation);
    // const ID = state.id;
    // setLocationsInformation({ invoiceId: ID });
  };

  const onSubmit = (e) => {
    let name = "invoiceLocationId";
    console.log(state,"ID")
    e.preventDefault();
    console.log(locationId ,"ID")

    let payload = {
      ...state,
      key: index,
      id: index ,
      locationId: locationId ,
      isDraft: false,
    };
    // let value = index;
    let data = locationsList;
    data[index] = payload;

    setLocationsInformation({ locationsList: data, invoiceId: "null" });
    setBusinessInformation({
      name,
      value: invoiceLocation
        ? locationId
        : businessInformation.invoiceLocationId === locationId
        ? locationId
        : businessInformation.invoiceLocationId,
    });
  };

  const handleKeyValuePair = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let key = "locationId"
    let locationvalue = state.locationId.length ? state.locationId : "id" + Math.random().toString(16).slice(2)
    setState({
      ...state,
      [key]: locationvalue,
    });
    setState({
      ...state,
      [name]: value,
    });
  };

  return (
    <div>
      <Presentation
        location={state}
        index={index}
        handleChange={handleChange}
        onSubmit={onSubmit}
        handleKeyValuePair={handleKeyValuePair}
        invoiceLocation={invoiceLocation}
        handleInvoiceLocation={handleInvoiceLocation}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    businessInformation: state.client.newClient.businessInformation,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setBusinessInformation: (payload) => {
      dispatch(uploadBusinessInformation(payload));
    },
    setLocationsInformation: (payload) => {
      dispatch(uploadLocationsInformation(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
