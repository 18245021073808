import React, { useEffect } from "react"
import { connect } from "react-redux"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import { compose } from "redux"
import Presentation from "./Presentation"
import { _set_state } from "../../../middleware/letterTemplate"
import CircularSpinner from "../../../../../shared/circularSpinner"

function Container(props) {
  const { state, letterTemplates, _set_state } = props

  useEffect(() => {
    const payload = {
      id: "",
      name: "",
      type: "",
      defaultContent: state.defaultContent,
      content: state.defaultContent,
      updating: false,
    }
    return () => _set_state(payload)
  }, [])

  return isLoaded(letterTemplates) ? (
    <div>
      <Presentation state={state} />
    </div>
  ) : (
    <CircularSpinner />
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    state: state.console.letterTemplates,
    letterTemplates: state.firestore.ordered.letterTemplates,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    _set_state: (payload) => {
      dispatch(_set_state(payload))
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(() => {
    return [
      {
        collection: "ID_TRACKER",
        doc: "letterTemplates",
        storeAs: "letterTemplates",
      },
    ]
  })
)(Container)
