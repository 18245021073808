import React from "react"
import PropTypes from "prop-types"
import { Grid, Paper } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import TaskManager from "../../Tasks/TaskManager"
import validate from "../../../../../shared/validation"
import Alert from "@material-ui/lab/Alert"
import Settings from "../Settings"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export default function Presentation(props) {
  const { project, modules, EmpDetails } = props
  let employee = {}
  let access = false
  //  const id = project[0].id
  let supervisorCount = 0
  try {
    const employees = Object.values(project[0].Users)
    employee = employees.filter((user) => user.uid === EmpDetails.uid)[0]
    if (employees.map((user) => user.uid).includes(EmpDetails.uid)) access = true
    else if (project[0].createdBy === EmpDetails.email) access = true
  } catch (error) {
    employee = {}
    access = false
  }
  //  checking supervisor count
  try {
    const employees = project[0].Users
    employees.forEach((member) => {
      if (validate.checkSupervisor(member.uid)) supervisorCount++
    })
  } catch (error) {}

  if (
    EmpDetails.role === "admin" ||
    (access && (employee.create || employee.update || employee.read))
  )
    return (
      <div>
        <div style={{ marginLeft: "10px" }}>
          <Paper>
            <div className="d-flex justify-content-between">
              <div>
                <h1 style={{ padding: "20px", textTransform: "uppercase" }}>{project[0].title} </h1>
              </div>
              <div>
                {modules.includes("task-management-manager") ||
                modules.includes("console-customization") ? (
                  <Grid item xs={2} style={{ padding: "20px", align: "right" }}>
                    <Settings project={project} id={project[0].id} isDashboard={false} />
                  </Grid>
                ) : null}
              </div>
            </div>
            {supervisorCount < 2 ? (
              <TaskManager {...props} />
            ) : (
              <Alert className="text-center" variant="filled" severity="warning">
                This project contain multiple Supervisors, Please contact Admin/Manager.
              </Alert>
            )}
          </Paper>
        </div>
      </div>
    )
  return (
    <Alert className="text-center" variant="filled" severity="error">
      You are unauthorized to view this content
    </Alert>
  )
}
