import React from "react"
import { configuration } from "../config/companyConfig"
// import "../../custom.css"

function CompanyConfiguration() {
  let companyName = configuration.client
  let address = configuration.contactdetails.address
  let phoneno = configuration.contactdetails.phoneno
  let hrMailId = configuration.contactdetails.mailId
  let originalString = address
  let separatedArray = originalString.split(", ")
  let line1 = separatedArray[0] + "," + separatedArray[1] + ","
  let line2 = separatedArray[2] + "," + separatedArray[3]
  console.log(separatedArray)
  return (
    <div>
      <p style={{ fontSize: "xx-small", color: "white" }}>
        {companyName}
        <br />
        {line1}
        <br />
        {line2}
        <br />
        {phoneno}
        <br />
        {hrMailId}
        <span style={{ opacity: "0" }}>
          hakdkajdajldnnkn,mnlkml;ml;ml;ml;.m;lm;lm;;lm;l/m;lm.,n,mbm,blkbn;ln;lnm'ajdgnngkflhfnkdlhnlfdknhlkfndhklfdnhlfndlhnlf
        </span>
      </p>
    </div>
  )
}

export default function PageTemplate() {
  return (
    <div>
      <header style={{ position: "absolute", top: "1px", left: "1px" }}>
        <div className="d-flex justify-content-between">
          <span>
            <img
              src={configuration.PDF_header}
              alt="logo"
              height="60"
              width="600"
            />
          </span>
        </div>
      </header>
      <footer style={{ position: "absolute", bottom: "0px", left: "1px" }}>
        <div
          style={{
            backgroundImage: `url(${configuration.PDF_footer})`,
            backgroundSize: "100% 100%",
            padding: "5px",
          }}
        >
          <CompanyConfiguration />
        </div>
      </footer>
    </div>
  )
}
