import React from "react";
import {
  TextField,
  MenuItem,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  IconButton,
  Tooltip,
  FormHelperText
} from "@material-ui/core";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon
} from "@material-ui/icons";
import { useNewInvoiceContext } from "./context";
import { CurrencyFormatter as CustomCurrencyFormat } from "../../../../shared/customNumberFormats";
import validate from "../../../../shared/validation"

function Discount() {
  const { state, services, stateSetters } = useNewInvoiceContext()

  return (
    <Paper className="p-2">
      <List dense>
        <ListItem>
        <ListItemText>
            {state.invoiceBy === "external" ? (
              <div className="d-flex flex-row">
                <div className="mr-1">
                  <TextField
                    style={{ width: "130px" }}
                    size="small"
                    variant="outlined"
                    label="Name"
                    required
                    onChange={(e) =>
                      stateSetters.setInvoiceName(e.target.value)
                    }
                    value={state.invoiceName}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </div>
                <div className="mr-1">
                  <TextField
                    style={{ width: "100px" }}
                    size="small"
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val === "") {
                        stateSetters.setExternalAmount(0);
                      } else if (Number(val) >= 0) {
                        stateSetters.setExternalAmount(Number(val));
                      }
                    }}
                    variant="outlined"
                    label="Amount"
                    disabled={state.invoiceName === ""}
                    value={state.externalAmount}
                    InputProps={{
                      inputComponent: CustomCurrencyFormat,
                      shrink: true
                    }}
                  />
                </div>
              </div>
            ) : (
              <h2 style={{ fontWeight: 500 }}>Total</h2>
            )}
          </ListItemText>
          <ListItemSecondaryAction>
            <h1>
              ${state.subTotal}
            </h1>
          </ListItemSecondaryAction>
        </ListItem>
        {state?.additionalItems?.map((item) => {
          return (
            <ListItem>
              <ListItemText>
                <h2 style={{ fontWeight: 500 }}>{item?.serviceSummary}</h2>
              </ListItemText>
              <ListItemSecondaryAction>
                <h2 style={{ fontWeight: 500 }}>
                  {validate.currencyFormatterUs(item?.amount)}
                </h2>
              </ListItemSecondaryAction>
            </ListItem>
          )}
        )}
        {state.discount.map((item, index) => {
          return (
            <ListItem style={{ marginTop: '5px' }} >
              <ListItemIcon>
                <div className="d-flex flex-row">
                  <div className="mr-1">
                    <TextField
                      style={{ width: "130px" }}
                      size="small"
                      variant="outlined"
                      value={item["name"]}
                      onChange={(e) => {
                        const details = JSON.parse(JSON.stringify(state.discount))
                        details[index]["name"] = e.target.value;
                        stateSetters.setDiscount(details);
                      }}
                      label="Name"
                    />
                  </div>
                  <div className="mr-1 mb-1">
                    <TextField
                      style={{ width: "130px" }}
                      size="small"
                      label="Select"
                      variant="outlined"
                      value={item["type"]}
                      disabled={item.name === ""}
                      onChange={(e) => {
                        const details = JSON.parse(JSON.stringify(state.discount))
                        details[index]["type"] = e.target.value;
                        details[index]["value"] = "";
                        stateSetters.setDiscount(details);
                      }}
                      select
                    >
                      <MenuItem value="byValue">By Value</MenuItem>
                      <MenuItem value="byPercentage">By Percentage</MenuItem>
                    </TextField>
                  </div>
                  <div className="mr-1">
                    <TextField
                      style={{ width: "100px" }}
                      size="small"
                      type="number"
                      variant="outlined"
                      value={item["value"]}
                      disabled={item.name === ""}
                      onChange={(e) => {
                        const details = JSON.parse(JSON.stringify(state.discount))
                        if (e.target.value === "") {
                          details[index]["value"] = e.target.value;
                          stateSetters.setDiscount(details);
                        } else {
                          const val = Number(e.target.value);
                          if (
                            (details[index]["type"] ===
                              "byPercentage" &&
                              val < 100) ||
                            details[index]["type"] === "byValue"
                          ) {
                            details[index]["value"] = val;
                            stateSetters.setDiscount(details);
                          }
                        }
                      }}
                      label="Discount"
                    />
                  </div>
                  {state.discount.length > 1 ? (
                    <div className="mr-1">
                      <Tooltip title="Remove this discount">
                        <IconButton
                          onClick={() => {
                            const details = JSON.parse(JSON.stringify(state.discount))
                            stateSetters.setDiscount(details.filter(
                              (_, i) => index !== i
                            ));
                          }}
                        >
                          <RemoveCircleOutlineIcon className="text-danger" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  ) : null}
                  {index === state.discount.length - 1 ? (
                    <div className="mr-1">
                      <Tooltip title="Add multiple discounts">
                        <IconButton
                          disabled={state.grandTotal < 0}
                          onClick={() => {
                            const details = JSON.parse(JSON.stringify(state.discount))
                            stateSetters.setDiscount([
                              ...details,
                              {
                                name: "",
                                value: 0,
                                type: ""
                              }
                            ]);
                          }}
                        >
                          <AddCircleOutlineIcon
                            color={state.grandTotal < 0 ? "default" : "primary"}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
              </ListItemIcon>
              <ListItemSecondaryAction>
                <h2 style={{ fontWeight: 500 }}>
                  $ {services.calculateDiscount([state.discount[index]])}
                </h2>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}

        <ListItem>
          <ListItemText>
            <h2 style={{ fontWeight: 500 }}>Grand Total</h2>
          </ListItemText>
          <ListItemSecondaryAction>
            <h2 style={{ fontWeight: 500 }}>
              ${state.grandTotal}
            </h2>
          </ListItemSecondaryAction>
        </ListItem>
        {state.grandTotal < 0 ? (
          <ListItem>
            <ListItemText></ListItemText>
            <ListItemSecondaryAction>
              <FormHelperText>Grand total cannot be negative</FormHelperText>
            </ListItemSecondaryAction>
          </ListItem>
        ) : null}
      </List>
    </Paper>
  )
}

export default Discount