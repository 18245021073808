import React from "react"
import { IconButton } from "@material-ui/core"
import { Delete as DeleteIcon } from "@material-ui/icons"
import { isLoaded } from "react-redux-firebase"
import AddSignature from "../AddSignature"
//import CustomTable from "../../../../../shared/customTable"
import MaterialTable, { MTableToolbar } from "material-table"
import { Link } from "react-router-dom"

export default function Presentation(props) {
  const { handleDelete, authorizedSignatures, names } = props

  let data = []

  if (isLoaded(authorizedSignatures)) {
    data = authorizedSignatures.map((signature) => {
      return {
        authorizationsignatory: names[signature.employeeID].name,
        designation: names[signature.employeeID].jobtitle,
        eSigndo: signature.attachmentDetails.publicURL,
        signatureId: signature.id,
        signatureUID: signature.employeeID,
      }
    })
  }

  const columns = [
    {
      title: "Authorization Signatory",
      field: "authorizationsignatory",
      render: (row) => (
        <Link to={`employees/${row.signatureUID}`}>{row.authorizationsignatory}</Link>
      ),
    },
    { title: "Designation", field: "designation" },
    {
      title: "e-Sign do",
      field: "eSigndo",
      render: (row) => <img alt="e-sign" src={row.eSigndo} height={20} />,
    },
    {
      title: "Actions",
      field: "",
      render: (row) => (
        <IconButton
          onClick={() => handleDelete(row.signatureId, row.signatureUID)}
          color="secondary"
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ]
  return (
    <div>
      <MaterialTable
        title=""
        data={data}
        columns={columns}
        isLoading={!isLoaded(authorizedSignatures)}
        options={{
          pageSize: 10,
          paginationPosition: "top",
          paginationType: "normal",
          filtering: true,
          selection: true,
          columnsButton: true,
        }}
        components={{
          Toolbar: (props) => {
            return (
              <div>
                <MTableToolbar {...props} />
                <div style={{ padding: "0 10px" }}>
                  <AddSignature />
                </div>
              </div>
            )
          },
        }}
      />
    </div>
  )
}
