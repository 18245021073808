import React, { useState } from 'react'
import {
  Grid,
  Typography,
  AccordionSummary,
  Accordion,
  Badge,
  AccordionDetails,
  FormControlLabel,
  Checkbox
} from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import validate from "../../../../../shared/validation"
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import { useNewInvoiceContext } from '../context'


function ExpenseSelector(props) {
  const { state, stateSetters } = useNewInvoiceContext()

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Badge color="primary" ><Typography>Expenses</Typography></Badge>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          {
            state.expenses.map(item => {
              return (
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    checked={state.selectedExpenses.map(exp => exp.id).includes(item.id)}
                    control={<Checkbox onChange={(e) => {
                      if (e.target.checked) stateSetters.setSelectedExpenses([...state.selectedExpenses, item])
                      else stateSetters.setSelectedExpenses([...state.selectedExpenses].filter(exp => exp.id !== item.id))
                    }} />}
                    label={validate.dateFormatter(item.spentDate) + "-" + item.expenseType}
                  />
                </Grid>
              )

            })
          }
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default ExpenseSelector
