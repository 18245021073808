import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import Presentation from "./Presentation";
import { uploadConatctsInformation } from "../../../../../middleware";

function Container(props) {
  const statelocations = useSelector(
    (state) => state.client.newClient.locations.locationsList
  );
  console.log(statelocations, "🚀", "heloo");
  const locations = statelocations.filter((item) => item.isDraft === false);
  console.log(locations);
  console.log("heloo");
  const { item, index, contactsList, setConatctsInformation } = props;
  const [state, setState] = useState({
    representativeName: "",
    jobTitle: "",
    contactType: "",
    gender: "",
    email: "",
    mobile: "",
    workPhone: "",
    homePhone: "",
    workPhoneExtension: "",
    countries: [],
    states: [],
    location: null,
  });

  useEffect(() => {
    Object.entries(item).forEach(([key, value]) => {
      setState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    });
  }, [item]);
  console.log(state, "state=========================================");
  const onSubmit = (e) => {
    e.preventDefault();
    let payload = {
      ...state,
      key: index,
      id: index,
      isDraft: false,
    };

    let data = contactsList;
    data[index] = payload;

    setConatctsInformation({ contactsList: data });
  };

  const handleKeyValuePair = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((state) => {
      return {
        ...state,
        [name]: value,
      };
    });
  };
  const handleLocation = (value) => {
    setState((state) => {
      return {
        ...state,
        location: value,
      };
    });
  };
  return (
    <div>
      <Presentation
        contact={state}
        index={index}
        handleChange={handleChange}
        onSubmit={onSubmit}
        handleLocation={handleLocation}
        handleKeyValuePair={handleKeyValuePair}
        locations={locations}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setConatctsInformation: (payload) => {
      dispatch(uploadConatctsInformation(payload));
    },
  };
};

export default connect(null, mapDispatchToProps)(Container);
