/* eslint-disable import/no-anonymous-default-export */
const initialState =  {
  editingSection: {
    access_key: "",
    sortPriority: null,
    fields: [],
    sectionName: "",
  },
  editingIndex: null,
  editingData: {},
};

export default initialState