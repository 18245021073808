import React from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import Revenue from "../../../../assets/icons/revenue.png";
import Deductions from "../../../../assets/icons/deductions.png";
import EarnedPay from "../../../../assets/icons/earnedPay.png";
import Pay from "../../../../assets/icons/pay.png";
import Insurance from "../../../../assets/icons/insurance.png";
import Bonus from "../../../../assets/icons/bonus.png";
import validate from "../../../../shared/validation";

const TableCards = ({ result }) => {
  const { totalRevenue, earnedPay, pay, deductions, insurance, bonus } = result;

  return (
    <Container>
      <Row xl="auto">
        <Col xl="4">
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between text-success">
                <div className="iconfont">
                  <img
                    alt="Revenue"
                    src={Revenue}
                    style={{ width: "40px", height: "40px" }}
                  />
                  <div className="mt-1 fw-medium fs-15">Total Revenue</div>
                </div>
                <h1 className="my-0 align-self-center text-success">
                  {validate.currencyFormatterUs(totalRevenue)}
                </h1>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl="4">
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between text-success">
                <div className="iconfont">
                  <img
                    alt="Earned Pay"
                    src={EarnedPay}
                    style={{ width: "40px", height: "40px" }}
                  />
                  <div className="mt-1 fw-medium fs-15">Earned Pay</div>
                </div>
                <h1 className="my-0 align-self-center text-success">
                  {validate.currencyFormatterUs(earnedPay)}
                </h1>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl="4">
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between text-success">
                <div className="iconfont">
                  <img
                    alt="Pay"
                    src={Pay}
                    style={{ width: "40px", height: "40px" }}
                  />
                  <div className="mt-1 fw-medium fs-15">Pay</div>
                </div>
                <h1 className="my-0 align-self-center text-success">
                  {validate.currencyFormatterUs(pay)}
                </h1>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl="4">
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between text-success">
                <div className="iconfont">
                  <img
                    alt="Deductions"
                    src={Deductions}
                    style={{ width: "40px", height: "40px" }}
                  />
                  <div className="mt-1 fw-medium fs-15">Deductions</div>
                </div>
                <h1 className="my-0 align-self-center text-success">
                  {validate.currencyFormatterUs(deductions)}
                </h1>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl="4">
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between text-success">
                <div className="iconfont">
                  <img
                    alt="Insurance"
                    src={Insurance}
                    style={{ width: "40px", height: "40px" }}
                  />
                  <div className="mt-1 fw-medium fs-15">Insurance</div>
                </div>
                <h1 className="my-0 align-self-center text-success">
                  {validate.currencyFormatterUs(insurance)}
                </h1>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl="4">
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between text-success">
                <div className="iconfont">
                  <img
                    alt="Bonus"
                    src={Bonus}
                    style={{ width: "40px", height: "40px" }}
                  />
                  <div className="mt-1 fw-medium fs-15">Bonus</div>
                </div>
                <h1 className="my-0 align-self-center text-success">
                  {validate.currencyFormatterUs(bonus)}
                </h1>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default TableCards;
