import React from "react"
import AccessTable from '../AccessTables'
import ActiveMembers from "../../ActiveMembers"
import Button from "@material-ui/core/Button"
import UpdateProject from '../UpdateProject'
import validate from "../../../../../shared/validation"
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Timeline from '../Timeline'
import Labels from "../../Labels"
import useStyles from '../../../Styles/ProjectSettingsStyles'
import MetaInfo from "../../../../../shared/getMetaInfo"

function Presentation(props) {

  const classes = useStyles()
  const {
    handleEmployees,
    onAddMembers,
    employees,
    projectMembers,
    project,
    projectId,
    isAdded,
    viewingProject,
    projectAssignee
  } = props
  console.log("projectAssignee",projectAssignee)
  const metaInfo = new MetaInfo()
  let isCountExceeded = false
  let assignees = projectAssignee.map(i => i.uid)
  let supervisorCount = 0
  assignees.forEach(employee => {
    if (metaInfo.checkSupervisor(employee)) supervisorCount++
  })
  employees.forEach(item => {
    if (item.isSupervisor)
      supervisorCount++
  })
  if (supervisorCount > 1) {
    isCountExceeded = true
  }

  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={clsx(classes.root)}>
      <main className={clsx(classes.layout)}>
        <ExpansionPanel
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="tm-settings-general-settings"
          >
            <Typography className={classes.heading}>
              General settings
            </Typography>
            <Typography className={classes.secondaryHeading}></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ActiveMembers
              projectMembers={
                assignees
              }
              isAdded={isAdded}
              projectID={project[0].id}
              inProject={true}
              handleEmployeesDefault={handleEmployees}
            />
          </ExpansionPanelDetails>
          <Divider />
          <ExpansionPanelActions>
            <Button
              onClick={onAddMembers}
              disabled={employees.length && !isCountExceeded ? false : true}
              variant="contained"
            >
              Add selected
            </Button>
          </ExpansionPanelActions>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="tm-settings-members-permissions"
          >
            <Typography className={classes.heading}>
              Members & Permissions
            </Typography>
            <Typography className={classes.secondaryHeading}>
              Manage Permissions/Members added to this Project.
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <AccessTable assignees={projectMembers} projectId={projectId} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="tm-settings-project-settings"
          >
            <Typography className={classes.heading}>Project Details</Typography>
            <Typography className={classes.secondaryHeading}>
              Manage Project Settings
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <UpdateProject projectId={projectId} project={project} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {project[0].useTimeline && (
          <ExpansionPanel
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="tm-settings-project-timelines"
            >
              <Typography className={classes.heading}>
                Project Timeline
              </Typography>
              <Typography className={classes.secondaryHeading}></Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Timeline projectId={projectId} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
        <ExpansionPanel
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="tm-settings-labels"
          >
            <Typography className={classes.heading}>Labels</Typography>
            <Typography className={classes.secondaryHeading}></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: "100%" }}>
              <Labels projectId={project[0].id} />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </main>
    </div>
  )
}

export default Presentation
