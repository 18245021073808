import React from "react";
import TransactionsList from "./TransactionsListModal";
import FlairTable from "../../shared/FlairTable";
import CreateDeductionModal from "./CreateDeductionModal";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getAllDeductions } from "../../api/services/default/deductions";
import MetaInfo from "../../shared/getMetaInfo";
import EmployeeHoverCard from "../../shared/components/EmployeeHoverCard";
import { JSutils } from "../../shared/JSutils";

export default function DeductionsList({ listAll, employeeId }) {
  const loginId = useSelector((state) => state.firebase?.auth?.uid ?? "");
  const metaInfo = new MetaInfo();

  const { data: deductions, isLoading } = useQuery({
    queryKey: ["getAllDeductions", employeeId],
    queryFn: () => getAllDeductions({ employeeId }),
  })

  const totalColumns = [
    {
      title: "Employee",
      field: "employeeId",
      dataType: "String",
      render: (rowData) => <EmployeeHoverCard employeeID={rowData.employeeId} />,
    },
    {
      title: "Branch",
      field: "branch",
      dataType: "",
    },
    {
      title: "Deduction type",
      field: "advanceType",
      dataType: "String",
    },
    {
      title: "Given Date",
      field: "givenDate",
      dataType: "Date",
    },
    {
      title: "Deduction Effective from",
      field: "effectiveFrom",
      dataType: "Date",
    },
    {
      title: "Deduct Per",
      field: "deductPer",
      dataType: "String",
    },
    { title: "Cheque Number", field: "chequeNumber" },
    {
      title: "Remaining Balance",
      field: "remainingBalance",
      dataType: "Numeric",
    },
    {
      title: "Deduction Amount",
      field: "amountTobeDeducted",
      dataType: "Numeric",
    },
    { title: "Advance", field: "amount", dataType: "Numeric" },
    { title: "Notes", field: "notes", dataType: "String" },
    {
      title: "Cheque",
      field: "attachment",
      render: ({ attachment }) => (
        <a target="_blank" href={attachment}>
          View Cheque
        </a>
      ),
      dataType: "",
    },
    {
      title: "Actions",
      field: "",
      dataType: "",
      render: (rowData) => {
        return (
          <span className="d-flex">
            <TransactionsList deductionId={rowData.id} />
            {listAll || loginId === rowData.employeeId ? null : (
              <CreateDeductionModal id={rowData.id} employeeId={rowData.employeeId} />
            )}
          </span>
        );
      },
    },
  ];

  let columns = [];

  if (!listAll) {
    columns = totalColumns.filter((col) => col.field !== "employeeId" && col.field !== "branch");
  } else {
    columns = totalColumns;
  }

  const rows = deductions?.map((deduction) => ({
    ...deduction,
    employeeName: metaInfo.emailToName(deduction.employeeId),
    imageURL: metaInfo.getEmployeeKey(deduction.employeeId, "photoURL"),
    branch: metaInfo.getEmployeeKey(deduction.employeeId, "branch"),
    notes: JSutils.extractTextFromHTML(deduction.notes ?? ""),
  })) || [];

  const NewDeductionAction = {
    icon: () => <CreateDeductionModal employeeId={employeeId} />,
    tooltip: "New Deduction",
    isFreeAction: true,
    position: "toolbar",
  };
  return (
    <div>
      <FlairTable
        title="Deductions"
        columns={columns}
        data={rows}
        actions={!listAll ? NewDeductionAction : {}}
        isLoading={isLoading}
      />
    </div>
  );
}
