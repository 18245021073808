import React, { useEffect, useState } from "react";
import { Row, Col, Tab, Card } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import DateFormField from "../../../../shared/components/BootStrapFormFields/DateFormField";
import { Animations } from "../../../../shared/skeletonLoader";
import { CalendarFormatter } from "../../../../shared/CalendarFormatter";
import { loadDashboardTimesheets } from "../../middleware/timesheetsList";
import { getTimesheetReviewers } from "../../middleware/timesheetsList";
import { getTimesheetdefaulters } from "../../middleware/timesheetsList";
import DashboardCount from "./dateCount";
import Defaulters from "./defaulters";
import Reviewers from "./reviewers";
import TimesheetsByMonths from "./timesheetsByMonths";
import TimesheetsByBranches from "./timesheetsByBranches";

const schema = yup.object().shape({
  fromDate: yup.string().required("From Date is required"),
  toDate: yup.string().required("To Date is required"),
});

const TimesheetsDashboard = ({ handleTabValue, ...props }) => {
  const [dashboardData, setdashboardData] = React.useState({});
  const [defaultersData, setdefaultersData] = React.useState({});
  const [reviewersData, setreviewersData] = React.useState([]);
  const [fromDate, setFromDate] = useState(
    CalendarFormatter.mmddyyyyFormat(new Date(new Date().getFullYear(), 0, 1))
  );
  const [toDate, setToDate] = useState(
    CalendarFormatter.mmddyyyyFormat(new Date())
  );
  let initialDates = {
    fromDate,
    toDate,
  };
  const getReviewersData = async () => {
    const timesheetData = await getTimesheetReviewers();
    setreviewersData(timesheetData?.data);
  };

  const getDefaultersData = async () => {
    const timesheetData = await getTimesheetdefaulters();
    setdefaultersData(timesheetData?.data);
  };

  const getDashboardData = async (fromDate, toDate) => {
    const timesheetData = await loadDashboardTimesheets(fromDate, toDate);
    setdashboardData(timesheetData?.data);
  };

  useEffect(() => {
    getDashboardData(initialDates.fromDate, initialDates.toDate);
    getDefaultersData();
    getReviewersData();
  }, [initialDates.fromDate, initialDates.toDate]);

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    setFromDate(values.fromDate);
    setToDate(values.toDate);
    getDashboardData(values.fromDate, values.toDate);
    actions.setSubmitting(false);
  };

  return (
    <>
      <Tab.Container defaultActiveKey="Dashboard">
        <Card>
          <Card.Body>
            <Tab.Content className="text-muted pt-0">
              <Tab.Pane eventKey="Dashboard" id="10" key="10">
                <Row className="justify-content-between pb-1" xs={12}>
                  <Col xs={4}>
                    <h4 className="page-title">Timesheets Dashboard</h4>
                  </Col>
                  <Col
                    xs={8}
                    className="d-flex justify-content-end d-filter-form"
                  >
                    <Formik
                      validationSchema={schema}
                      initialValues={{
                        ...initialDates,
                      }}
                      onSubmit={handleSubmit}
                    >
                      {(formik) => {
                        return (
                          <Form>
                            <Row>
                              <Col>
                                <Field
                                  name="fromDate"
                                  type="text"
                                  component={DateFormField}
                                />
                              </Col>
                              <Col style={{ marginTop: 6 }}>
                                <div className="px-2 align-self-center">to</div>
                              </Col>
                              <Col>
                                <Field
                                  name="toDate"
                                  type="text"
                                  component={DateFormField}
                                />
                              </Col>
                              <Col>
                                <button
                                  type="submit"
                                  className="btn btn-success px-4 btn-md"
                                  disabled={
                                    !formik.isValid ||
                                    formik.isSubmitting ||
                                    !formik.dirty
                                  }
                                >
                                  Apply
                                </button>
                              </Col>
                            </Row>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Col>
                </Row>
                {Object.keys(dashboardData).length ? (
                  <DashboardCount
                    Timesheets={dashboardData?.timesheetsDashboard}
                    Defaulters={defaultersData?.defaulterTimesheets}
                  />
                ) : (
                  <Animations />
                )}
                <Row>
                  <Col lg={4}>
                    {Object.keys(reviewersData).length ? (
                      <Reviewers
                        Reviewers={reviewersData?.timesheetReviewers}
                      />
                    ) : (
                      <Animations />
                    )}
                    {Object.keys(defaultersData).length ? (
                      <Defaulters
                        handleTabChange={handleTabValue}
                        {...props}
                        Defaulters={
                          defaultersData?.defaultersWithNamesAndCounts
                        }
                      />
                    ) : (
                      <Animations />
                    )}
                  </Col>
                  <Col lg={8}>
                    {Object.keys(dashboardData).length ? (
                      <TimesheetsByMonths TimesheetsByMonths={dashboardData} />
                    ) : (
                      <Animations />
                    )}
                    {Object.keys(dashboardData).length ? (
                      <TimesheetsByBranches
                        TimesheetsByBranches={dashboardData}
                      />
                    ) : (
                      <Animations />
                    )}
                  </Col>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Card.Body>
        </Card>
      </Tab.Container>
    </>
  );
};

export default TimesheetsDashboard;
