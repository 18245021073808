import React from "react"
import ViewClient from "../Services/Clients/components/ViewClient/Index"

function ViewClientPage(props) {
  return (
    <div>
      <ViewClient clientId={props.match.params.clientId} />
    </div>
  )
}

export default ViewClientPage
