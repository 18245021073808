import React from 'react'
import { HistoryContextProvider } from '../context'
import Container from './Container'

export default function History(props) {
	return (
		<HistoryContextProvider>
			<Container {...props} />
		</HistoryContextProvider>
	)
}
