import React from 'react';
import { Card } from 'react-bootstrap';

const Deductions = (props) => {
    const {data} = props
    return (
        <Card>
            <Card.Body>
                <h5 className="card-title mb-3 header-title">Deductions</h5>
                <div className="d-flex mb-3">
                    <div className="ms-0">
                        <h6 className="text-muted fw-normal mt-0 mb-0">Advance Amount</h6>
                        <h4 className="mb-0 mt-1 align-self-center fw-bold">{data?.[0]?.advanceAmount}</h4>
                    </div>
                </div>
                <div className="d-flex mb-3">
                    <div className="ms-0">
                        <h6 className="text-muted fw-normal mt-0 mb-0">Premimum Amount</h6>
                        <h4 className="mb-0 mt-1 align-self-center fw-bold">{data?.[0]?.premiumAmount}</h4>
                    </div>
                </div>
                <div className="d-flex mb-3">
                    <div className="ms-0">
                        <h6 className="text-muted fw-normal mt-0 mb-0">Miscellaneous</h6>
                        <h4 className="mb-0 mt-1 align-self-center fw-bold">{data?.[0]?.miscellaneousAmount}</h4>
                    </div>
                </div>
                <div className="totcirs">
                    <div className="d-flex">
                        <div className="ms-0">
                            <h6 className="fw-medium mt-0 mb-0">Total Deductions</h6>
                            <h4 className="mb-0 mt-1 pt-1 align-self-center fw-bold">{data?.[0]?.totalAmount}</h4>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};
export default Deductions;