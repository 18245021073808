import { getDay } from "date-fns";
import format from "date-fns/format";
import { configuration } from "../config/companyConfig";
import { DateUtils } from "react-day-picker";
import dateFnsParse from "date-fns/parse";
import moment from "moment";

export class CalendarFormatter {
  static standardDateFormat(date) {
    const d1 = new Date(date);
    if (!isNaN(Date.parse(d1))) return format(d1, configuration.dateformatter);
    return null;
  }

  static customDateFormat(date, formatType = "yyyy-MM-dd") {
    if (!formatType) return "invalid format type";
    const d = new Date(date);
    console.log(
      `CalendarFormatter.customDateFormat() : ${date} - ${formatType}`
    );
    return format(d, formatType);
  }

  static parseDate(str) {
    // use this for mostly material ui keyboard date pickers
    const parsed = dateFnsParse(str, configuration.dateformatter, new Date());
    if (DateUtils.isDate(parsed)) {
      console.log(CalendarFormatter.standardDateFormat(parsed));
      return CalendarFormatter.standardDateFormat(parsed);
    }
    return undefined;
  }

  static stringToDate(str) {
    const parsed = dateFnsParse(str, configuration.dateformatter, new Date());
    return !isNaN(Date.parse(parsed)) ? parsed : undefined;
  }

  static getDayFromDate(date) {
    const d = new Date(date);
    const dayIndex = getDay(d);
    switch (dayIndex) {
      case 0:
        return "Sunday";

      case 1:
        return "Monday";

      case 2:
        return "Tuesday";

      case 3:
        return "Wednesday";

      case 4:
        return "Thursday";

      case 5:
        return "Friday";

      case 6:
        return "Saturday";

      default:
        return "";
    }
  }

  /*
    @param date in string format yyyy-mm-dd 
  */
  static mmddyyyyFormat(date) {
    return moment(date, "YYYY-MM-DD").format("MM/DD/YYYY");
  }

  /*
    @param date in string format mm/dd/yyyy
  */
  static yyyymmddFormat(date) {
    return moment(date, "MM/DD/YYYY").format("YYYY-MM-DD");
  }
}
