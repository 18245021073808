import React from "react"
import { Paper, Tabs, Tab, Badge } from "@material-ui/core"
import ExpenseList from "../TaskSort"
import TabPanel from "../../../../../../shared/tabPanel"

export default function TabsView(props) {
  const {
    handleTabChange,
    tabValue,
    //modules,
    //listAll,
    trackProjectTasksCount = {
      InProgress: {
        count: 0,
      },
      Open: {
        count: 0,
      },
      totalCount: 0,
      Review: {
        count: 0,
      },
      Closed: {
        count: 0,
      },
      SubTasks: {
        count: 0,
      },
    },
  } = props
  console.log(trackProjectTasksCount)
  return (
    <div>
      <Paper square>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="disabled tabs example"
        >
          <Tab
            label="All"
            // label={
            //   <Badge
            //     color="primary"
            //     children="All"
            //     max="100000"
            //     badgeContent={trackProjectTasksCount.totalCount}
            //   />
            // }
          />
          <Tab
            label="Open"
            // label={
            //   <Badge
            //     color="primary"
            //     children="Open"
            //     max="100000"
            //     badgeContent={trackProjectTasksCount.Open["count"]}

            //     // badgeContent={trackInvoiceSubmissions.open}
            //   />
            // }
          />
          <Tab
            label="InProgress"
            // label={
            //   <Badge
            //     color="primary"
            //     children="InProgress"
            //     max="100000"
            //     badgeContent={trackProjectTasksCount.InProgress["count"]}

            //     // badgeContent={trackInvoiceSubmissions.void}
            //   />
            // }
          />

          <Tab
            label="Over Due"
            // label={
            //   <Badge
            //     color="primary"
            //     children="Over Due"
            //     max="100000"
            //     badgeContent={trackProjectTasksCount.Overdue["count"]}
            //   />
            // }
          />
          <Tab
            label="Review"
            // label={
            //   <Badge
            //     color="primary"
            //     children="Review"
            //     max="100000"
            //     badgeContent={trackProjectTasksCount.Review["count"]}

            //     // badgeContent={trackInvoiceSubmissions.paid}
            //   />
            // }
          />
          <Tab
            label="Closed"
            // label={
            //   <Badge
            //     color="primary"
            //     children="Closed"
            //     max="100000"
            //     badgeContent={trackProjectTasksCount.Closed["count"]}

            //     // badgeContent={trackInvoiceSubmissions.paid}
            //   />
            // }
          />
          <Tab
            label="Sub Tasks"
            // label={
            //   <Badge
            //     color="primary"
            //     children="Sub Tasks"
            //     max="100000"
            //     badgeContent={trackProjectTasksCount.SubTasks["count"]}
            //   />
            // }
          />
        </Tabs>
      </Paper>
      <TabPanel value={tabValue} index={0}>
        <ExpenseList condition={0} id={props.id} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ExpenseList condition={1} id={props.id} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <ExpenseList condition={2} id={props.id} />
      </TabPanel>

      <TabPanel value={tabValue} index={3}>
        <ExpenseList condition={3} id={props.id} />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <ExpenseList condition={4} id={props.id} />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <ExpenseList condition={5} id={props.id} />
      </TabPanel>
      <TabPanel value={tabValue} index={6}>
        <ExpenseList condition={6} id={props.id} />
      </TabPanel>
    </div>
  )
}
