import React from "react";
import { connect } from "react-redux";
import Presentation from "./Presentation";
import { inviteEmployee } from "../../../middleware";
import validate from "../../../../../shared/validation";
import readXlsxFile from "read-excel-file";
import { setExcelInvite } from "../../../middleware";
import { useHistory } from "react-router-dom";

function Container(props) {
  const { _setExcelInvite, employeelist, handleClose } = props;
  const history = useHistory();

  const handleChange = (e) => {
    readXlsxFile(e.target.files[0])
      .then((rows) => {
        // `rows` is an array of rows
        // each row being an array of cells.
        console.log("objectdateCheckdateCheck", rows);
        let emails = [];
        rows
          .filter((v, i) => i !== 0)
          .forEach((row) => {
            let emailCheck = validate.checkEmail(row[2]);
            //TODO:Convert date to mm/dd/yyyy
            let todayTime = new Date(row[4]);
            let month = todayTime.getMonth() + 1;
            let year = todayTime.getFullYear();
            let day = todayTime.getDate();

            let parseDate = month + "/" + day + "/" + year;

            console.log("objectrow", row[4]);
            let dateCheck = validate.checkDate(parseDate);
            if (
              emailCheck &&
              !employeelist
                .map((user) => user.email)
                .includes(row[2].toString()) &&
              !employeelist.map((user) => user.uid).includes(row[2].toString())
            ) {
              emails.push({
                firstname: row[0] ? row[0].toString() : "",
                lastname: row[1] ? row[1].toString() : "",
                email: row[2] ? row[2].toString().trim() : "",
                phonenumber: row[3] ? row[3].toString() : "",
                dob: dateCheck ? parseDate : "Invalid date",

                branch: row[5] ? row[5].toString() : "",
                isInvited: false,
                isInviting: true,
                comment: "",
                validEmail: true,
                shalliInvite: true,
              });
            } else if (
              emailCheck &&
              (employeelist
                .map((user) => user.email)
                .includes(row[2].toString()) ||
                employeelist
                  .map((user) => user.uid)
                  .includes(row[2].toString()))
            ) {
              emails.push({
                firstname: row[0] ? row[0].toString() : "",
                lastname: row[1] ? row[1].toString() : "",
                email: row[2] ? row[2].toString().trim() : "",
                phonenumber: row[3] ? row[3].toString() : "",
                dob: dateCheck ? parseDate : "Invalid date",
                branch: row[5] ? row[5].toString() : "",
                isInvited: false,
                isInviting: false,
                comment: "Already registered",
                validEmail: true,
                shalliInvite: false,
              });
            } else {
              emails.push({
                firstname: row[0] ? row[0].toString() : "",
                lastname: row[1] ? row[1].toString() : "",
                email: row[2] ? row[2].toString().trim() : "",
                phonenumber: row[3] ? row[3].toString() : "",
                dob: dateCheck ? parseDate : "Invalid date",

                branch: row[5] ? row[5].toString() : "",
                isInvited: false,
                isInviting: false,
                comment: "Invalid email format",
                validEmail: false,
                shalliInvite: false,
              });
            }
          });
        return emails;
      })
      .then((emails) => {
        console.log(emails);
        _setExcelInvite(emails);
        history.push("/console/inviteemployee");
      })
      .then(() => {
        handleClose();
        return;
      })
      .catch((err) => {
        console.error(err);
        handleClose();
      });
  };

  return (
    <div>
      <Presentation {...props} handleFileChange={handleChange} />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { data } = state.firestore;
  return {
    isSending: state.employee.employeeInvite.isSending,
    error: state.employee.employeeInvite.error,
    employeelist: Object.values(data.names),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    inviteEmployee: (payload) => {
      dispatch(inviteEmployee(payload));
    },
    _setExcelInvite: (payload) => {
      dispatch(setExcelInvite(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
