import React, { useContext, useState, useEffect } from 'react'
import Presentation from "./Presentation"
import { connect } from "react-redux"
import { submitTimesheet, submitTimesheetforManager } from "../../../../middleware"
import { Context } from "../../component_state/context"
import { useHistory } from "react-router-dom"
import { checkIfTimesheetContainsConnectingMonths } from '../../../../../../utils/TimesheetsUtils'

function Container(props) {
  const { loggedInEmployee } = props
  const [open, setOpen] = useState(false);
  const [state, handle] = useContext(Context)
  const [disableBtn, setDisableBtn] = useState(true)
  const history = useHistory();

  const setState = (obj) => {
    handle({
      type: "SET_STATE",
      data: obj
    })
  }

  // button disable conditions
  const standardTimeLength = state.standardTime.length
  const isDocRequired = state.timesheetSettings.attachMandatory
  const isDocUploading = state.isDocumentUploading
  const statusReport = state?.statusReport?.replace(/<[^>]+>/g, '').length;
  const isDocUploaded = isDocRequired ? state.timesheetInfo.attachmentDetails.length > 0 : true

  useEffect(() => {
    setDisableBtn(true)
    if ((standardTimeLength > 0) && isDocUploaded && !isDocUploading &&statusReport >99)
      setDisableBtn(false)
  }, [standardTimeLength, isDocUploading, isDocUploaded,statusReport])
  const isManager = props?.modules?.includes("timesheets-manager") || props?.modules?.includes("console-customization");


  const onSubmit = async () => {
    handleClose();
    setDisableBtn(true)
    if (!open) return;
    const { timesheetStartDate, timesheetEndDate, standardTime, OTtime, placementID, publicURL, fileExtension, sourcePath, timesheetInfo,statusReport, timesheetEmployeeID } = state
    const timesheet = {
      ...timesheetInfo,
      workdetails: {
        standardTime: standardTime,
        OTtime: OTtime
      },
      fileExtension,
      statusReport
    }

    setState({
      isSubmitting: true
    })

   // const checkInfo = checkIfTimesheetContainsConnectingMonths(standardTime, OTtime)
    if(isManager) {
      await submitTimesheetforManager(timesheet, timesheetEmployeeID, placementID)
    }else {
        await submitTimesheet(timesheet, loggedInEmployee, placementID) 
    }
    setDisableBtn(false)
    history.push("/console/timesheets");
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Presentation
        handleClickOpen={handleClickOpen}
        onSubmit={onSubmit}
        handleClose={handleClose}
        open={open}
        state={state}
        disableBtn={disableBtn}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loggedInEmployee: state.firebase.auth.uid,
    modules: state.employee.employeeModules.accessModules,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)