import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    appBar: {
      position: 'relative',
      background: 'linear-gradient(45deg, #280071 10%, #c42053 90%)',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    new: {
      backgroundColor: "black",
      color: "white"
    }
  }));

  export default useStyles