import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import TextFormField from "../../../../../../shared/components/BootStrapFormFields/TextFormField";
import { RegexExp } from "../../../../../../shared/regex";
import SelectFormField from "../../../../../../shared/components/BootStrapFormFields/SelectFormField";
import DateFormField from "../../../../../../shared/components/BootStrapFormFields/DateFormField";
import CheckBoxField from "../../../../../../shared/components/BootStrapFormFields/CheckBoxField";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../../middleware/dynamicProfileRender";
import MetaInfo from "../../../../../../shared/getMetaInfo";
import AppModalHeader from "../../../../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../../../../shared/components/Modals/AppModalFooter";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateEmployee } from "../../../../../../api/services/default/employee";

const schema = yup.object().shape({
  firstname: yup
    .string()
    .required()
    .matches(RegexExp.name, "name should be valid"),
  middlename: yup.string().matches(RegexExp.name, "name should be valid"),
  lastname: yup
    .string()
    .required()
    .matches(RegexExp.name, "name should be valid"),
  emailid: yup
    .string()
    .required()
    .matches(RegexExp.email, "emailId should be valid"),
  phonenumber: yup
    .string()
    .required()
    .matches(RegexExp.phonenumber, "phone number should be valid"),
  department: yup.string().required(),
  maritalstatus: yup.string().required(),
  gender: yup.string().required(),
  dob: yup.date().required("Date of birth is a required field"),
  dateofjoining: yup.date().required("Date of Joining is required field"),
  category: yup.string().required(),
  branch : yup.string().required("Branch is a required field"),
  jobtitle: yup.string().required(),
  reportingmanager: yup.string().required(),
  payrollid: yup
    .string()
    .matches(RegexExp.alphanumeric, "payroll Id should be in alphanumeric"),
  alternativeemail :yup
  .string()
  .matches(RegexExp.email, "emailId should be valid"),
  salary:yup.string().matches(RegexExp.number),
  supevisor:yup.string()

});

export default function PersonalinfoEditModal(props) {
  const { data, employeeID, names, access_modules, onHide } = props;
  const metaInfo = new MetaInfo()
  const branchList = useSelector(state => state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.personal?.fields?.filter((field) => field.name === "branch")?.[0]?.values || [])
  const genderList = useSelector(state => state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.personal?.fields?.filter((field) => field.name === "gender")?.[0]?.values || [])
  const employeeCategory = useSelector(state => state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.personal?.fields?.filter((field) => field.name === "category")?.[0]?.values || [])
  const maritalStatus = useSelector(state => state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.personal?.fields?.filter((field) => field.name === "maritalstatus")?.[0]?.values || [])
  const department = useSelector(state => state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.personal?.fields?.filter((field) => field.name === "department")?.[0]?.values || [])

  const managers = [];
  if (
    access_modules.includes("employees-manager") ||
    access_modules.includes("console-customization")
  )
    Object.values(names).forEach((employee) => {
      if (
        (employee.isSupervisor && employee.status === "active") ||
        employee.designation === "admin"
      ) {
        managers.push(employee.uid);
      }
    });

  const optionMapper = (i) => ({ value: i, label: i });
  const queryClient = useQueryClient();
  const useUpdateProfile = useMutation({
		queryKey: ["updateEmployee", employeeID],
		mutationFn: ({ employeeID, data }) => updateEmployee(employeeID, data),
		onSuccess: () => {
			queryClient.invalidateQueries(["getEmployee", employeeID]);
			onHide();
		},
	});

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    const formData = { ...values, firstname : values?.firstname?.trim(), lastname : values?.lastname?.trim(), middlename : values?.middlename?.trim() };
    const payload = {
      personal: formData,
    };
    await useUpdateProfile.mutateAsync({ employeeID, data: payload });
		actions.setSubmitting(false);
   
    
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        validationSchema={schema}
        validateOnMount={true}
        initialValues={data}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <AppModalHeader title="Edit Personal Information" />
              <Modal.Body>
                <Row>
                  <Col xl={4}>
                    <Field
                      name="firstname"
                      type="text"
                      label={"First Name *"}
                      component={TextFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="middlename"
                      type="text"
                      label={"Middle Name"}
                      component={TextFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="lastname"
                      type="text"
                      label={"Last Name *"}
                      component={TextFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="emailid"
                      type="text"
                      label={"Email *"}
                      disabled={true}
                      component={TextFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="phonenumber"
                      type="number"
                      label={"Phone *"}
                      component={TextFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="department"
                      type="select"
                      options={department?.map(optionMapper)}
                      label={"Department *"}
                      component={SelectFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="maritalstatus"
                      type="select"
                      options={maritalStatus?.map(optionMapper)}
                      label={"Marital Status *"}
                      component={SelectFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="gender"
                      type="select"
                      options={genderList?.map(optionMapper)}
                      label={"Gender *"}
                      component={SelectFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="category"
                      type="select"
                      options={employeeCategory?.map(optionMapper)}
                      label={"Category *"}
                      component={SelectFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="branch"
                      type="select"
                      options={branchList?.map(optionMapper)}
                      label={"Branch *"}
                      component={SelectFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="dob"
                      type="date"
                      label={"Date of Birth *"}
                      component={DateFormField}
                    />
                  </Col>

                  <Col xl={4}>
                    <Field
                      name="dateofjoining"
                      type="date"
                      label={"Date of Joining *"}
                      component={DateFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="jobtitle"
                      type="text"
                      label={"Job Title *"}
                      component={TextFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="payrollid"
                      type="text"
                      label={"PayRoll Id"}
                      component={TextFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="alternativeemail"
                      type="text"
                      label={"Alternative Email"}
                      component={TextFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="reportingmanager"
                      type="select"
                      options={managers.map((i) => ({ value: i, label: metaInfo.emailToName(i) }) )}
                      label={"Reporting Manager *"}
                      component={SelectFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="supervisor"
                      type="checkbox"
                      label={"SuperVisor"}
                      component={CheckBoxField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="salary"
                      type="text"
                      label={"Salary"}
                      component={TextFormField}
                    />
                  </Col>
                </Row>
              </Modal.Body>
              {/* {props.actions ? (
								props.actions(formik)
							) : ( */}
								<AppModalFooter
									saveButtonText="Save Changes"
									saveButtonDisabled={
										!formik.isValid ||
										formik.isSubmitting ||
										!formik.dirty 
									}
								/>
							{/* )} */}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
