import React from "react"
import { connect } from "react-redux"
import { firestoreConnect } from "react-redux-firebase"
import { compose } from "redux"
import { locationActions } from "../../../../../middleware"
import Presentation from "./Presentation"
import { useQuery } from "@tanstack/react-query"
import { getClientLocations } from "../../../../../../../api/services/default/clients"

function Container(props) {
  const {client} = props
  const { data: clientsLocationsData,isFetched :isLoadingLoactions } = useQuery
  ({
		queryKey: ["getClientLocations",client.id],
		queryFn: () => getClientLocations(client.id),
	});
  if(isLoadingLoactions)
  return (
    <div>
      <Presentation {...props} locations = {clientsLocationsData} />
    </div>
  )
  return (
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  </div>
  )
}



const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteLocation: (payload) => {
      dispatch(locationActions(payload, "delete"))
    },
  }
}

export default compose(
  connect(null, mapDispatchToProps)
)(Container)
