export class Constants {
  static AR_AGING_RANGES = {
    0: "Current",
    1: "1-30",
    2: "31-60",
    3: "61-90",
    4: "91 Above",
  };

  static ACTIVE_STATUS = [
    { value: "Y", label: "Active" },
    { value: "N", label: "Inactive" },
  ]

  static employeeStatusList = ["Bench", "Working", "Training", "Prospect"];
  static REGION_OF_CONTACT = [
    { value: "USA", label: "USA Contact" },
    { value: "India", label: "India Contact" },
  ]
  
  static SKILL_RATINGS = [
    { value: '0', label: "0" },
    { value: '1', label: "1" },
    { value: '2', label: "2" },
    { value: '3', label: "3" },
    { value: '4', label: "4" }
  ]
}
