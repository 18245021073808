import React, { useState } from 'react'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import InDetailTable from './inDetailTable'
import { withStyles } from '@material-ui/core'

const Accordion = withStyles({
	root: {
		border: '1px solid rgba(0, 0, 0, .125)',
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
	root: {
		backgroundColor: 'rgba(0, 0, 0, .03)',
		borderBottom: '1px solid rgba(0, 0, 0, .125)',
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiAccordionDetails)

const panels = [
	{ title: '91 or more days past due', range: 4 },
	{ title: '61-90 days past due', range: 3 },
	{ title: '31-60 days past due', range: 2 },
	{ title: '1-30 days past due', range: 1 },
	{ title: 'Current', range: 0 },
]

export default function InDetail() {
	const [expanded, setExpanded] = useState(panels[0].range)

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false)
	}

	return panels.map(({ range, title }) => {
		return (
			<React.Fragment key={range}>
				<Accordion
					square
					expanded={expanded === range}
					onChange={handleChange(range)}
				>
					<AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
						<Typography variant='subtitle1'>{title}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Container maxWidth='xl'>
							<InDetailTable range={range} />
						</Container>
					</AccordionDetails>
				</Accordion>
			</React.Fragment>
		)
	})
}
