import ACTION from "../actions/actionTypes";
import initState from "./clientsMetaInfoState";

export function clientsMetaInfoReducer(state = initState, action) {
  switch (action.type) {
    case ACTION.GET_META_INFO_CLIENTS_REQUEST:
      return {
        ...state,
        metaInfoofClients: {
          ...state.metaInfoofClients,
          isLoading: true,
          error: "",
        },
      }

    case ACTION.GET_META_INFO_CLIENTS_SUCCESS:
      return {
        ...state,
        metaInfoofClients: {
          ...state.metaInfoofClients,
          isLoading: false,
          data: action.payload,
          error: "",
        },
      }

    case ACTION.GET_META_INFO_CLIENTS_FAILURE:
      return {
        ...state,
        metaInfoofClients: {
          ...state.metaInfoofClients,
          isLoading: false,
          error: "",
        },
      }

    case ACTION.SET_TO_INITIAL_STATE:
      return {
        metaInfoofClients:{
          isLoading: true,
          data:{},
          error:""
        }
      };

    default:
      return state;
  }
}
