import React from "react"
import Grid from "@material-ui/core/Grid"
import EditTask from "../EditTask"

export default function Presentation(props) {
  const { task, project } = props
  console.log(task, project)
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EditTask task={task[0]} project={project[0]} />
        </Grid>
      </Grid>
    </div>
  )
}
