import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { firestoreConnect } from "react-redux-firebase"
import { compose } from "redux"
import Presentation from "./Presentation"
import { clearExcelInvite } from "../../../middleware"

function Container(props) {
	const { inviteeInfo, clearExcelInvite } = props
	const [state, setState] = useState({
		placeHtml: null,
		userMail: "",
		showInviteBtn: true,
		inviteeInfo: [],
	})

	useEffect(() => {
		setState(prevState =>(
			{	
			...prevState,
			inviteeInfo,
		}))
	}, [inviteeInfo])

	const clearList = () => {
		clearExcelInvite()
	}

	return (
		<div>
			<Presentation {...props} {...state} clearList={clearList} />
		</div>
	)
}

const mapStateToProps = (state, ownProps) => {
	return {
		inviteeInfo: state.firestore.ordered.inviteeInfo
			? state.firestore.ordered.inviteeInfo
			: [],
		excel_invite: state.employee.employeeInvite.excel_invite,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		clearExcelInvite: () => {
			dispatch(clearExcelInvite())
		},
	}
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props, reduxState) => {
    return [
      {
        collection: "EXCEL_INVITE",
        storeAs: "inviteeInfo",
      },
    ]
  })
)(Container)
