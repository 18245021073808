import MetaInfo from "../../../shared/getMetaInfo"
import {
  projectListDashboardDataReq,
  projectListDashboardSuccess,
  projectListDashboardFailure,
} from "../actions/actionCreators"

export const projectListData = () => (dispatch, getState, { getFirebase }) => {
  dispatch(projectListDashboardDataReq())
  const projectsCount = {
    totalCount: 0,
    Open: 0,
    OverDue: 0,
    Closed: 0,
  }
  const db = getFirebase().firestore()
  return db
    .collection("ID_TRACKER")
    .doc("task_management")
    .get()
    .then((doc) => {
      const tracker = doc.data().hasOwnProperty("projects_tracker")
        ? doc.data().projects_tracker
        : {}
      if (Object.keys(tracker).length > 0) {
        Object.entries(tracker).forEach(([key, value]) => {
          projectsCount[key] += value
        })
      }
      return db.collection("PROJECTS").get()
    })
    .then((snap) => {
      const validate = new MetaInfo()
      let users = []
      snap.docs.forEach((doc) => {
        const { Users } = doc.data()
        const supervisors = Object.keys(Users).filter((uid) => validate.checkSupervisor(uid))
        users = [...users, ...supervisors]
      })
      return dispatch(projectListDashboardSuccess({ projectsCount, users: [...new Set(users)] }))
    })
    .catch((err) => {
      console.error(err)
      return dispatch(projectListDashboardFailure(err))
    })
}
