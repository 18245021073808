import React from "react";
import {
  FaHistory,
  FaTasks,
  FaEdit,
  FaUsers,
  FaEnvelopeOpenText,
} from "react-icons/fa";
import { IoMdChatboxes, IoMdAlarm } from "react-icons/io";
import { TiDocumentText, TiUserAdd } from "react-icons/ti";
import { AiOutlineSafetyCertificate,AiOutlineAudit } from "react-icons/ai";
import { MdBusinessCenter } from "react-icons/md";
import ReceiptIcon from "@material-ui/icons/Receipt";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { RiGroup2Line } from "react-icons/ri";
import { GiPayMoney, GiTakeMyMoney } from "react-icons/gi";

const modules = [
  {
    text: "Task management",
    icon: <FaTasks size={18} />,
    link: "/console/projects?tab=0",
    moduleName: "task-management",
  },
  {
    text: "Timesheets",
    icon: <IoMdAlarm size={18} />,
    link: "/console/timesheets?tab=0",
    moduleName: "timesheets",
  },
  {
    text: "Expenses",
    icon: <GiPayMoney size={18} />,
    link: "/console/expenseslist?tab=0",
    moduleName: "timesheets",
  },
  {
    text: "Invoices",
    icon: <ReceiptIcon size={18} />,
    link: "/console/invoiceslist?tab=0",
    moduleName: "timesheets-manager",
  },
  {
    text: "Deductions",
    icon: <GiTakeMyMoney size={18} />,
    link: "/console/deductionslist",
    moduleName: "accounts-manager",
  },
  {
    text: "Payrolls",
    icon: <AttachMoneyIcon size={18} />,
    link: "/console/payrolls",
    moduleName: "accounts-manager",
  },
  {
    text: "Wiki",
    icon: <TiDocumentText size={18} />,
    link: "/console/wiki",
    moduleName: "wiki",
  },
  {
    text: "Discussions",
    icon: <IoMdChatboxes size={18} />,
    link: "/console/discussions",
    moduleName: "discussions",
  },
  {
    text: "Invite employee",
    icon: <TiUserAdd size={18} />,
    link: "/console/inviteemployee",
    moduleName: "console-customization",
  },

  {
    text: "Employees",
    icon: <FaUsers size={18} />,
    link: "/console/employees?tab=0",
    moduleName: "employees-manager",
  },
  {
    text: "ESS Requests",
    icon: <FaEnvelopeOpenText size={18} />,
    link: "/console/myrequests",
    moduleName: "employee-self-services",
  },
  {
    text: "Letter requests",
    icon: <AiOutlineSafetyCertificate size={18} />,
    link: "/console/letterrequests",
    moduleName: "employee-self-services-manager",
  },
  {
    text: "Placements",
    icon: <MdBusinessCenter size={18} />,
    link: "/console/placements",
    moduleName: "timesheets-manager",
  },
  {
    text: "Clients",
    icon: <RiGroup2Line size={20} />,
    link: "/console/clientslist",
    moduleName: "timesheets-manager",
  },
  {
    text: "Create template",
    icon: <FaEdit size={18} />,
    link: "/console/createtemplate",
    moduleName: "console-customization",
  },
  {
    text: "RMG",
    icon: <AiOutlineAudit size={18} />,
    link: "/console/ResourceManagementGroup",
    moduleName: "employees-manager",
  },
  {
    text: "History",
    icon: <FaHistory size={18} />,
    link: "/console/history",
    moduleName: "common-module",
  },
]

export default modules;
