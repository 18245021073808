import { getIn } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

export default function SelectFormField({
	field,
	form,
	required,
	isMulti = false,
	onSelect = (value) => {},
	...props
}) {
	const errorText =
		 getIn(form.errors, field.name);

	//const value = props?.options?.find((res) => res.value === field.value) || null;
	return (
		<Form.Group className="mb-3">
			<Form.Label>{props.label}</Form.Label>
			<Select
				isClearable
				isSearchable
				theme={(theme) => ({
					...theme,
					colors: {
						...theme.colors,
						primary: "#ced4da",
					},
				})}
				key={`select-${field.name}`}
				value={
					!isMulti
					? props?.options?.find((res) => res.value === field.value) || ''
					: props?.options?.filter((res) => field.value.includes(res.value)) || []
				}
				options={props?.options}
				onBlur={() => form.setFieldTouched(field.name, true, true)}
				onChange={(option) => {
					if (isMulti) {
						const value = option ? option.map((item) => item.value) : [];
						form.setFieldValue(field.name, value);
						onSelect?.(value);
					} else {
						const value = option ? option.value : "";
						form.setFieldValue(field.name, value);
						onSelect?.(value);
					}
				}}
				isMulti={isMulti}
				name={field.name}
				closeMenuOnSelect={true}
				escapeClearsValue={true}
				{...props}
			/>
			{errorText && <Form.Text className="text-danger">{errorText}</Form.Text>}
		</Form.Group>
	);
}
