import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import AppModalHeader from "../../../shared/components/Modals/AppModalHeader";
import { Field, Form, Formik } from "formik";
import TextFormField from "../../../shared/components/BootStrapFormFields/TextFormField";
import AppModalFooter from "../../../shared/components/Modals/AppModalFooter";
import { useDialog } from "../../../hooks/useDialog";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
	getGlobalPayrollSettings,
	updateGlobalPayrollSettings,
} from "../../../api/services/default/payrolls/global-settings";
import * as yup from "yup";

const schema = yup.object().shape({
	dealPay: yup.number().required("Required"),
	dealPeriod: yup.number().required("Required"),
	insurance: yup.number().required("Required"),
});

export default function GlobalSettings() {
	const { open, handleOpen, handleClose } = useDialog();
	const { data, refetch } = useQuery({
		queryKey: ["getGlobalPayrollSettings"],
		queryFn: () => getGlobalPayrollSettings(),
	});

	const useUpdate = useMutation({
		mutationFn: ({ data }) => updateGlobalPayrollSettings(data),
		onSuccess() {
			handleClose();
			refetch();
		},
	});

	const handleSubmit = async (values, actions) => {
		actions.setSubmitting(true);
		await useUpdate.mutateAsync({ data: values });
		actions.setSubmitting(false);
	};

	return (
		<>
			<Button variant="primary" onClick={handleOpen}>
				Edit Global Settings
			</Button>
			<Modal show={open} onHide={handleClose} centered size="md">
				<Formik
					initialValues={data}
					validationSchema={schema}
					onSubmit={handleSubmit}
				>
					{({ isValid, isSubmitting, dirty }) => (
						<Form>
							<AppModalHeader title="Global Settings" />
							<Modal.Body>
								<Row>
									<Col xl={12}>
										<Field
											component={TextFormField}
											label="Deal Pay"
											name="dealPay"
											placeholder="Deal Pay"
											type="number"
										/>
									</Col>
									<Col xl={12}>
										<Field
											component={TextFormField}
											label="Deal Period"
											name="dealPeriod"
											placeholder="Deal Period"
											type="number"
										/>
									</Col>
									<Col xl={12}>
										<Field
											component={TextFormField}
											label="Insurance"
											name="insurance"
											placeholder="Insurance"
											type="number"
										/>
									</Col>
								</Row>
							</Modal.Body>
							<AppModalFooter
								saveButtonDisabled={!isValid || isSubmitting || !dirty}
								onClickCancel={handleClose}
							/>
						</Form>
					)}
				</Formik>
			</Modal>
		</>
	);
}
