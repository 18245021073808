import React from "react"
import useStylesFacebook from "./styles/facebookStyles"
import CircularProgress from "@material-ui/core/CircularProgress"

function FacebookProgress(props) {
	const classes = useStylesFacebook()

	return (
		<div className={classes.root}>
			<CircularProgress
				variant='determinate'
				value={100}
				className={classes.top}
				size={15}
				thickness={4}
				{...props}
			/>
			<CircularProgress
				variant='indeterminate'
				disableShrink
				className={classes.bottom}
				size={15}
				thickness={4}
				{...props}
			/>
		</div>
	)
}

export default FacebookProgress
