import React, { useState } from "react"
import InviteDialog from "../InviteDialog"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import DataSorter from "../ExcelSort"
import Template from "../../InvitationTemplate/Template"

function Presentation(props) {
	const [showTemplate, setShowTemplate] = useState(false)
	const { inviteeInfo, excel_invite, clearList } = props
	return (
		<div>
			<div>
				<div class='row bg-white p-3 ml-1 mr-1 rounded shadow'>
					<div className='col'>
						<div className='d-block'>
							<Button
								onClick={() => setShowTemplate(!showTemplate)}
								variant='info'
								className='ver-btn w-100 ml-auto mr-auto m-1 text-high'
							>
								Old Template
							</Button>
							<Link to='/console/createtemplate'>
								<Button className='w-100 m-1 mr-1 text-high' variant="light">Create new template</Button>
							</Link>
						</div>
					</div>
					<div className='col text-center'>
						<InviteDialog
							btnText='Invite'
							template={false}
							heading='Invite employee'
							text='Enter the email address of the new employee'
						/>
					</div>
				</div>
			</div>
			<div>
				{excel_invite.length || inviteeInfo.length ? (
					<div className='p-2 rounded shadow rounded mt-2 bg-white'>
						<div className='d-flex justify-content-between'>
							<div>
								<h5>Note: Employees are going to be invited if and only if "INVITE BELOW LIST" button is clicked. The button exists in "TO BE INVITED" tab.</h5>
							</div>
							<div>
								<Button onClick={clearList} color='google plus'>
									Clear list
								</Button>
							</div>
						</div>
						<DataSorter />
					</div>
				) : null}
			</div>
			<div className='ml-auto mr-auto w-50'>
				{showTemplate ? <Template registeringEmail={""} /> : null}
			</div>
		</div>
	)
}

export default Presentation
