import React, { useEffect } from 'react'
import {
  Grid,
  TextField,
} from "@material-ui/core"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { useNewInvoiceContext } from './context'
import NumberFormatCustom from "../../../../shared/numberFormatCustom"
import { CalendarFormatter } from '../../../../shared/CalendarFormatter'
import { addDays, differenceInCalendarDays } from 'date-fns'

function DatesSelection() {
  const { state, stateSetters } = useNewInvoiceContext()


  return (
    <Grid container spacing={1} alignItems='flex-end'>
      <Grid item xs={12} sm={4}>
        <TextField
          style={{marginBottom:4}}
          variant="outlined"
          size="small"
          required
          fullWidth
          label="Net Terms"
          name="netTerms"
          value={state.netTerms}
          onChange={(e) => {
            if (!isNaN(parseInt(e.target.value)) && (parseInt(e.target.value) < 367)) {
              const val = parseInt(e.target.value)
              stateSetters.setNetTerms(val)
              stateSetters.setInvoiceDueDate(CalendarFormatter.standardDateFormat(addDays(new Date(state.invoiceDate), val)));
            } else if (e.target.value === "") {
              stateSetters.setNetTerms(0)
            }
          }}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
        />
      </Grid>
      {
        state.invoiceSettings?.POnumber || state.poNumber?.length > 1 ? (
          <>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                required
                type="number"
                size="small"
                onChange={(e) => stateSetters.setPoNumber(e.target.value)}
                label="PO Number"
                value={state.poNumber}
                name="POnumber"
                variant="outlined"
              />
            </Grid>
          </>
        ) : null
      }

      <Grid item xs={12} sm={4}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            required
            fullWidth
            margin="normal"
            label="Invoice date"
            maxDate={state.dueDate}
            value={state.invoiceDate ? state.invoiceDate : null}
            onChange={(date) => {
              stateSetters.setInvoiceDate(CalendarFormatter.standardDateFormat(new Date(date)))
              const days = differenceInCalendarDays(
                new Date(state.dueDate),
                new Date(date)
              );
              stateSetters.setNetTerms(days);
            }}
            format="MM/dd/yyyy"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} sm={4}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            required
            fullWidth
            margin="normal"
            minDate={state.invoiceDate}
            value={state.dueDate ? state.dueDate : null}
            // onChange={(date) => {
            //   if (!isNaN(Date.parse(date)))
            //     stateSetters.setInvoiceDueDate(new Date(date).setHours(0, 0, 0, 0))
            // }}
            disabled={true}
            label="Due Date"
            format="MM/dd/yyyy"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  )
}

export default DatesSelection