import store from "../store/Index";
import { JSutils } from "./JSutils";

export default class MetaInfo {
  constructor() {
    this.names = store.getState().employee.employeeList.metaInfoofEmployees.data;
    this.clients = store.getState().client.clientsMetaInfo.metaInfoofClients.data
  }

  clientIdToProperty = (id, property) => {
    try {
      return this.clients[id][property];
    } catch (error) {
      return id;
    }
  };

  clientIdToName = (id) => {
    try {
      return this.clients[id] ? this.clients[id]?.businessDisplayName : "";
    } catch (error) {
      console.error(error);
      return id;
    }
  };

  emailToName = (id) => {
    const names = this.names;
    try {
      if (names[id]) return names[id].name;
      return "";
    } catch (error) {
      console.error(error);
      return id;
    }
  };

  getEmployeeKey(id, key) {
    const names = this.names;
    try {
      return names[id][key];
    } catch (error) {
      return id;
    }
  }

  getCategory = (id) => {
    const names = this.names;
    try {
      return names[id].category;
    } catch (error) {
      console.error(error);
      return id;
    }
  };

  checkSupervisor = (id) => {
    const names = this.names;
    try {
      return names[id].isSupervisor ? true : false;
    } catch (error) {
      return false;
    }
  };
  checkDesignation = (id) => {
    const names = this.names;
    try {
      if (names[id].designation === "User") return "Employee";
      return names[id].designation;
    } catch (error) {
      return "";
    }
  };
  getEmployeeAdress = (employeeAddress) => {
    try {
      return [employeeAddress?.line1,
        employeeAddress?.line2,
        employeeAddress?.city,
        employeeAddress?.state,
        employeeAddress?.country,
        employeeAddress?.zip].filter((i) => i).join(', ')
    } catch (error) {
      console.error(error);
      return "";
    }
  };
  getGender = (id) => {
    const names = this.names;
    try {
      return names[id].gender;
    } catch (error) {
      return "";
    }
  };
  getStatus = (id) => {
    const names = this.names;
    try {
      return names[id].status;
    } catch (error) {
      return "";
    }
  };
  getImage = (id) => {
    const names = this.names;
    let url = "";
    try {
      url = names[id].photoURL;
    } catch (error) {
      url = "";
    }

    return url;
  };
}
