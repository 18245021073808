import { initialState } from "./context"

const handler = (state, action) => {
  switch (action.type) {
    case "SET_STATE":
      return { ...state, ...action.data }

    case "RESET":
      return initialState

    default: return state
  }
}

export default handler