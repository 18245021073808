import Axios, { AxiosRequestConfig } from "axios";
import { configuration } from "../../config/companyConfig";
import { InterceptorService } from "../../shared/InterceptorService";

const AXIOS_INSTANCE = Axios.create({
  baseURL: configuration.flair_shared_api,
  headers: {
    'X-API-Key': configuration.flair_shared_api_key,
  },
});
const interceptorService = new InterceptorService(AXIOS_INSTANCE);
interceptorService.addRequestInterceptor().addResponseInterceptor();

/**
 * @param {AxiosRequestConfig} config
 * @returns {Promise<any>}
 */
export const flairSharedInstance = (config) => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token }).then(
    ({ data }) => data,
  );

  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
}