import React from "react";
import { Button } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Chip from "@material-ui/core/Chip";
import { Checkbox } from "@material-ui/core";
// import { DataContext } from "../../../../../../src/contexts/data"
import MaterialTable, { MTableToolbar } from "material-table";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import useStyles from "../../../../Styles/LinkTaskStyles";
import validate from "../../../../../../shared/validation";
import MetaInfo from "../../../../../../shared/getMetaInfo";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Presentation(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const {
    handleClick,
    handleChange,
    clearValues,
    project,
    task,
    taskList,
    listOfTasksToBeLinked,
  } = props;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    clearValues();
  };
  // const validate = {
  //   emailToName: (uid) => {
  //     try {
  //       return state.names.filter((item) => item.uid === uid)[0].name
  //     } catch (error) {
  //       console.log(error)
  //       return uid
  //     }
  //   },
  // }
  const metaInfo = new MetaInfo();
  const projectLabels = project[0].labels;

  const diff = dayjs();
  const getDueBy = (enddate, status) => {
    if (status === "completed" || status === "closed") return false;
    return new Date() - new Date(enddate) >= 0 ? true : false;
  };

  const getDueByDays = (startDate, endDate, status) => {
    {
      if (getDueBy(endDate, status)) return diff.from(endDate, true);

      return "---";
    }
  };

  const customStyles = {
    employeeExist: {
      color: "#4183c4",
      textTransform: "capitalize",
    },
    employeeNonExist: {
      color: "#626466",
      textTransform: "capitalize",
    },
  };

  // let subTaskIds = [
  //   ...new Set(
  //     state.taskList
  //       .filter((task) => task.category === "subtask")
  //       .map((subtask) => subtask.taskId)
  //   ),
  // ]
  let parentTasks = taskList.filter(
    (tasks) =>
      tasks.category === "task" &&
      tasks.id !== task[0].taskId &&
      tasks.id !== task[0].id
  );
  const columns = [
    {
      title: "Select",
      field: "select",
      width: "2%",
      render: ({ checking, id }) => (
        <Checkbox
          checked={checking}
          name="wiki"
          onChange={(e) => handleChange(e, id)}
        />
      ),
    },
    {
      title: "Task ID",
      field: "cid",
      defaultSort: "desc",
      width: "10%",
    },
    {
      title: "Labels",
      field: "labels",
      width: "10%",
      render: (rowData) => {
        return rowData.labels.map((element) => (
          <span>
            <Chip
              size="small"
              style={{
                backgroundColor: `${element.colorCode}`,
                color: "white",
                marginBottom: "2px",
              }}
              label={element.name}
            />
            <br />
          </span>
        ));
      },
    },
    {
      title: "Title",
      field: "title",
      width: "38%",
    },
    {
      title: "Due by",
      field: "dueBy",
      defaultSort: "desc",
      width: "5%",
    },
    {
      title: "Priority",
      field: "priority",
      defaultSort: "desc",
      width: "5%",
    },
    {
      title: "Created by",
      field: "createdByName",
      width: "15%",
    },
    {
      title: "Assignee",
      field: "assignee",
      width: "25%",
      render: (rowData) => {
        return rowData.assignee.map((employee) => (
          <span>
            <Link
            // to={"/console/employees/" + employee}
            >
              <Button
                type="a"
                style={
                  Object.values(project[0].Users)
                    .map((user) => user.uid)
                    .includes(employee)
                    ? customStyles.employeeExist
                    : customStyles.employeeNonExist
                }
                size="small"
              >
                {employee}
                {/* {metaInfo.emailToName */}

                {/* } */}
              </Button>
            </Link>
            <br />
          </span>
        ));
      },
    },
  ];
  let data = [];
  parentTasks &&
    parentTasks.map((task) => {
      data.push({
        title: task.title,
        id: task.id,
        startDate: task.startDate,
        endDate: task.endDate,
        labels: Object.values(projectLabels).filter(
          (item) =>
            item.isExist &&
            task.hasOwnProperty("labels") &&
            Object.values(task.labels).includes(item.id)
        ),
        dueBy: getDueByDays(task.startDate, task.endDate, task.status),
        cid: project[0].cid + "-" + task.cid,
        assignee: task.assignee.map((e) => metaInfo.emailToName(e)),
        priority: task.priority,
        createdByName: metaInfo.emailToName(task.createdBy),
        createdBy: task.createdBy,
      });
    });
  return (
    <div>
      {parentTasks.length === 0 ? (
        <Button  className="btn btn-success px-6 btn-md text-high" disabled>
          Link Tasks
        </Button>
      ) : (
        <Button className="btn btn-success px-6 btn-md text-high" onClick={handleClickOpen}>
          Link tasks
        </Button>
      )}

      <Dialog
        fullScreen
        disableEscapeKeyDown
        disableBackdropClick
        open={open}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ padding: "25px" }}>
          <MaterialTable
            title=""
            data={data}
            columns={columns}
            options={{
              paginationType: "stepped",
              pageSize: 10,
            }}
            components={{
              Toolbar: (props) => (
                <div>
                  <MTableToolbar {...props} />
                  <div
                    style={{
                      marginTop: "-50px",
                      marginBottom: "10px",
                      marginLeft: "8px",
                    }}
                  >
                    <Button
                      className="btn btn-success px-6 btn-md text-high"
                      disabled={listOfTasksToBeLinked.length === 0}
                      onClick={(e) => {
                        handleClick(e, handleClose);
                      }}
                    >
                      Link
                    </Button>
                  </div>
                </div>
              ),
            }}
          />
        </div>
      </Dialog>
    </div>
  );
}
