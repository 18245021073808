export const initState = {
  expiredItems: [],
  updateWorkQueueDocument: {
    isLoading: false,
    err: null,
    payload: null,
  },
  reviewWorkQueueDocument: {
    isLoading: false,
    err: null,
    payload: null,
  },
  getReviewDocument: {
    isLoading: true,
    err: null,
    data: {},
  },
};
