import {
  setAccountsInformation,
  setBusinessInformation,
  setContactsInformation,
  setLocationsInformation,
  countriesError,
  countriesRequest,
  countriesSuccess,
  documentsError,
  documentsReq,
  documentsSuccess,
  setToInitial,
  clientsLocationReq,
  clientsLocationSucc,
  clientsLocationFailure,
  invoiceLocationReq,
  invoiceLocationSucc,
  invoiceLocationFailure,
  getMetaInfoClientsRequest,
  getMetaInfoClientsSuccess,
  getMetaInfoClientsFailure,
} from "../actions/actionCreators";
import {
  stopWaitMsg,
  waitingMsg,
  errorMsg,
  successMsg,
} from "../../../shared/SnackBars/index";
import make_API_Call from "../../../providers/REST_API";
import { storage } from "../../../config/fbConfig";
import validate from "../../../shared/validation";
import firebase from "../../../config/fbConfig";

export function setCountries() {
  return (dispatch) => {
    dispatch(countriesRequest());
    make_API_Call("get", "/loadcountries")
      .then((data) => {
        dispatch(countriesSuccess(data));
      })
      .catch((err) => {
        dispatch(countriesError(err));
      });
  };
}

export function uploadBusinessInformation(payload) {
  return (dispatch, getState) => {
    const { name, value } = payload;
    console.log(name, value);
    const businessInformation = getState().client.newClient.businessInformation;
    if (!name.includes("-")) {
      const data = {
        ...businessInformation,
        [name]: value,
      };
      dispatch(setBusinessInformation(data));
    } else {
      const words = name.split("-");
      const word1 = words[0];
      const word2 = words[1];
      const data = {
        ...businessInformation,
        [word1]: {
          ...businessInformation[word1],
          [word2]: value,
        },
      };
      dispatch(setBusinessInformation(data));
    }
  };
}

export function uploadConatctsInformation(payload) {
  return (dispatch) => {
    const { contactsList, ID } = payload;
    console.log(contactsList);
    dispatch(
      setContactsInformation({
        contactsList,
        status: contactsList.filter((contact) => !contact.isDraft).length > 0,
        invoiceLocationId: ID,
      })
    );
  };
}

export function uploadAccountsInformation(payload) {
  return (dispatch, getState) => {
    const { name, value } = payload;
    const accounts = getState().client.newClient.accounts;
    if (!name.includes("-")) {
      dispatch(
        setAccountsInformation({
          ...accounts,
          [name]: value,
        })
      );
    } else {
      const words = name.split("-");
      const word1 = words[0];
      const word2 = words[1];
      dispatch(
        setAccountsInformation({
          ...accounts,
          [word1]: {
            ...accounts[word1],
            [word2]: value,
          },
        })
      );
    }
  };
}

export function uploadLocationsInformation(payload) {
  return (dispatch, getState) => {
    const { locationsList, invoiceId } = payload;
    console.log("objectlocationsList", locationsList);
    dispatch(
      setLocationsInformation({
        locationsList,
        // invoiceLocationId: invoiceId,
        status:
          locationsList.filter((location) => !location.isDraft).length > 0,
      })
    );
  };
}

export function deleteClient(id) {
  return (dispatch) => {
    waitingMsg(`Deleting ${id}...`);
    make_API_Call("delete", `/clients/delete/${id}`)
      .then((data) => {
        console.log(data);
        stopWaitMsg();
        successMsg(data.message);
      })
      .catch((err) => {
        stopWaitMsg();
        errorMsg(err.message);
      });
  };
}

export const checkName = (name) => async (dispatch, getState) => {
  dispatch(uploadBusinessInformation({ name: "isChecking", value: true }));
  try {
    if (!name) throw new Error("name-is-undefined");
    const data = await make_API_Call(
      "get",
      `/clients/checkbusinessName?businessName=${name}`
    );
    console.log(data);
    dispatch(uploadBusinessInformation({ name: "isChecking", value: false }));
    return dispatch(
      uploadBusinessInformation({ name: "helperText", value: "" })
    );
  } catch (err) {
    console.error(err);
    dispatch(uploadBusinessInformation({ name: "isChecking", value: false }));
    if (err.toString().match(`name-is-undefined`))
      return dispatch(
        uploadBusinessInformation({
          name: "helperText",
          value: "Name should not be empty",
        })
      );
    return dispatch(
      uploadBusinessInformation({ name: "helperText", value: err.message })
    );
  }
};

export function createClient(history) {
  return (dispatch, getState) => {
    const newClient = getState().client.newClient;
console.log(newClient)
    let businessInformation = {};
    let accounts = {};
    let locations = [];
    let contacts = [];

    newClient.contacts.contactsList
      .filter((item) => !item.isDraft)
      .forEach((contact) => {
        let req_contact = {};
        Object.entries(contact).forEach(([key, value]) => {
          if (
            key !== "key" &&
            key !== "id" &&
            key !== "countries" &&
            key !== "states" &&
            key !== "isDraft"
          ) {
            req_contact[key] = value;
          }
        });
        contacts.push(req_contact);
      });

    newClient.locations.locationsList
      .filter((item) => !item.isDraft)
      .forEach((location) => {
        let req_location = {};
        Object.entries(location).forEach(([key, value]) => {
          if (
            key !== "key" &&
            key !== "id" &&
            key !== "countries" &&
            key !== "states" &&
            key !== "isDraft"
          ) {
            req_location[key] = value;
          }
        });
        locations.push(req_location);
      });

    Object.entries(newClient.businessInformation).forEach(([key, value]) => {
      if (key !== "status" && key !== "countries" && key !== "states") {
        businessInformation[key] = value;
      }
    });

    Object.entries(newClient.accounts).forEach(([key, value]) => {
      if (key !== "discountStatus" && key !== "helperText") {
        accounts[key] = value;
      }
    });

    const payload = {
      ...businessInformation,
      locations,
      contacts,
      accounts,
    };
    console.log(payload);
    waitingMsg("Creating client...");
    make_API_Call("post", "/clients/new", payload)
      .then((response) => {
        stopWaitMsg();
        successMsg(response.message);
        dispatch(setToInitial());
        history.push("/console/clientslist");
      })
      .catch((err) => {
        stopWaitMsg();
        console.error(err);
        errorMsg(err.message);
      });
  };
}

export function editClientDetails(payload, clientId) {
  console.log("payload",payload,clientId)
  return function (dispatch) {
    waitingMsg(`Updating ${clientId}`);
    make_API_Call("put", `/clients/update/${clientId}`, payload)
      .then((data) => {
        console.log(data);
        stopWaitMsg();
        successMsg(data.message);
      })
      .catch((err) => {
        stopWaitMsg();
        errorMsg(err.message);
      });
  };
}

export function contactActions(payload, actionType) {
  return function () {
    console.log(payload, actionType);
    waitingMsg(`Please wait...`);
    make_API_Call("post", `/clients/contacts/${actionType}`, payload)
      .then((data) => {
        console.log(data);
        stopWaitMsg();
        successMsg(data.message);
      })
      .catch((err) => {
        stopWaitMsg();
        errorMsg(err.message);
      });
  };
}

export function locationActions(payload, actionType) {
  return function () {
    waitingMsg(`Please wait...`);
    make_API_Call("post", `/clients/locations/${actionType}`, payload)
      .then((data) => {
        console.log(data);
        stopWaitMsg();
        successMsg(data.message);
      })
      .catch((err) => {
        stopWaitMsg();
        errorMsg(err.message);
      });
  };
}

export function accountsUpdate(payload, clientId, accountsId) {
  return function () {
    waitingMsg(`Please wait...`);
    make_API_Call(
      "put",
      `/clients/accounts/update/${clientId}/${accountsId}`,
      payload
    )
      .then((data) => {
        console.log(data);
        stopWaitMsg();
        successMsg(data.message);
      })
      .catch((err) => {
        stopWaitMsg();
        errorMsg(err.message);
      });
  };
}

export const clientDocuments =
  (clientId, isSubmit) =>
  async (dispatch, getState, { getFirebase }) => {
    const listRef = storage.ref(`Clients/Documents/${clientId}`);
    dispatch(documentsReq());
    const metaInfoPromises = [];
    const urlPromises = [];
    let data = [];
    return listRef
      .listAll()
      .then((res) => {
        res.items.forEach((item) => {
          metaInfoPromises.push(item.getMetadata());
          urlPromises.push(item.getDownloadURL());
        });
        return Promise.all(metaInfoPromises);
      })
      .then((metaInfo) => {
        const documentTypes = [
          "Incorporation",
          "PO",
          "MSA",
          "COI",
          "W9",
          "MSA &PO",
          "Others",
        ];
        metaInfo.forEach((item, index) => {
          const { documentType, effectiveDate, effectiveUntilDate } =
            item.customMetadata;
          data[index] = {
            work_doc: {
              name: item.name,
              url: "",
            },
            documentType: documentTypes.indexOf(documentType),
            status: new Date(effectiveUntilDate) < new Date() ? 1 : 0,
            effectiveDate: validate.dateFormatter(effectiveDate),
            effectiveUntilDate: validate.dateFormatter(effectiveUntilDate),
          };
        });
        return Promise.all(urlPromises);
      })
      .then((urls) => {
        urls.forEach((url, index) => {
          data[index] = {
            ...data[index],
            work_doc: {
              ...data[index].work_doc,
              url: url,
            },
          };
        });
        console.log(data);
        if (isSubmit) successMsg("Submitted Successfully");
        return dispatch(documentsSuccess(data));
      })
      .catch((err) => {
        console.error(err);
        return dispatch(documentsError(err));
      });
  };

export function getClientLocations(clientId) {
  return function (dispatch, getState, { getFirebase }) {
    dispatch(clientsLocationReq());
    return firebase
      .firestore()
      .collectionGroup("CLIENTS_LOCATIONS")
      .where("isExist", "==", true)
      .where("clientId", "==", clientId)
      .get()
      .then((snap) => {
        if (snap.size < 1) return dispatch(clientsLocationFailure(null));
        return dispatch(
          clientsLocationSucc(snap.docs.map((doc) => doc.data()))
        );
      })
      .catch((err) => {
        console.error(err);
        return dispatch(clientsLocationFailure(err));
      });
  };
}

export function getInvoiceLocations(clientId) {
  return function (dispatch, getState, { getFirebase }) {
    dispatch(invoiceLocationReq());
    return firebase
      .firestore()
      .collection("META_INFO")
      .doc("clients")
      .get()
      .then((snap) => {
        if (snap.size < 1) return dispatch(invoiceLocationFailure(null));
        return dispatch(
          invoiceLocationSucc(snap.data())
          //console.log(snap.data())
        );
      })
      .catch((err) => {
        console.error(err);
        return dispatch(invoiceLocationFailure(err));
      });
  };
}
export function getInvoiceLocationId(clientId) {
  console.log("heloo");
  return function (dispatch) {
    return firebase
      .firestore()
      .collection("CLIENTS")
      .doc(clientId)
      .get()
      .then((data) => {
        console.log(data);
      });
  };
}

export function getMetaInfoofClients() {
  return async (dispatch) => {
    dispatch(getMetaInfoClientsRequest())
    await make_API_Call("get","/clients/getMetaInfoofClients")
      .then((data) => {
        dispatch(getMetaInfoClientsSuccess(data))
      })
      .catch((err) => {
        console.error(err)
        dispatch(getMetaInfoClientsFailure())
      })
  }
}