import React from "react"
import EmployeeProfile from "../Services/EmployeeManagment/components/MenuBar"

function EmployeeProfilePage(props) {
  return (
    <div>
      <EmployeeProfile Id={props.match.params.id} />
    </div>
  )
}

export default EmployeeProfilePage

