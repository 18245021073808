import React from "react";
import ExpiredList from "../Services/Dashboard/components/ExpiredDocTable/index";

function EmployeeExpiredListPage(props) {
  const { empId } = props.match.params;
  return (
    <div>
      <ExpiredList employeeId={empId} />
    </div>
  );
}

export default EmployeeExpiredListPage;
