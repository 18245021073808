import React from "react"
import TaskHandler from "../Services/TaskManagement/components/ProjectHandler/Tasks"

function ProjectTasksPage(props) {
  return (
    <div>
      <TaskHandler {...props} />
    </div>
  )
}

export default ProjectTasksPage
