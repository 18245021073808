const ACTION = {
  PLACEMENTLIST_REQUEST: "PLACEMENTLIST_REQUEST",
  PLACEMENTLIST_SUCCESS: "PLACEMENTLIST_SUCCESS",
  PLACEMENTLIST_FAILURE: "PLACEMENTLIST_FAILURE",

  DOCUMENTS_REQUEST: "DOCUMENTS_REQUEST",
  DOCUMENTS_SUCCESS: "DOCUMENTS_SUCCESS",
  DOCUMENTS_FAILURE: "DOCUMENTS_FAILURE",

  LOCATIONS_REQUEST: "LOCATIONS_REQUEST",
  LOCATIONS_SUCCESS: "LOCATIONS_SUCCESS",
  LOCATIONS_FAILURE: "LOCATIONS_FAILURE",

  TIMESHEETS_PLACEMENTS_REQ: "TIMESHEETS_PLACEMENTS_REQ",
  TIMESHEETS_PLACEMENTS_SUCCESS: "TIMESHEETS_PLACEMENTS_SUCCESS",
  TIMESHEETS_PLACEMENTS_FAILURE: "TIMESHEETS_PLACEMENTS_FAILURE",

  SET_LISTENER: "SECTIONS_DATA_LISTENER",

  EMPLOYEE_PLACEMENTS_REQ: "EMPLOYEE_PLACEMENTS_REQ",
  EMPLOYEE_PLACEMENTS_SUCCESS: "EMPLOYEE_PLACEMENTS_SUCCESS",
  EMPLOYEE_PLACEMENTS_FAILURE: "EMPLOYEE_PLACEMENTS_FAILURE",
};

export default ACTION
