import React from "react";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import { useDialog } from "../../../../hooks/useDialog";
import AppModalHeader from "../../../../shared/components/Modals/AppModalHeader";

function ProspectPlacement() {
  const { handleClose, handleOpen, open } = useDialog();
  return (
    <div>
      <Button
        className="btn btn-success px-4 btn-md text-high"
        onClick={handleOpen}
      >
        {"New Placement"}
      </Button>
      <Modal show={open} onHide={handleClose} size="md" centered>
        <Formik enableReinitialize>
          {(formik) => {
            return (
              <Form>
                <AppModalHeader title={"Placement Alert"} />
                <Modal.Body>
                  <h4 style={{fontSize:18}}>
                    Placement cannot be created to an employee with{" "}
                    <span className="text-danger" style={{fontWeight: "bold" }}>Prospect</span> Status.
                  </h4>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="btn btn-success rounded-pill px-4 btn-md"
                    onClick={handleClose}
                  >
                    Okay
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}

export default ProspectPlacement;
