import React, { useEffect, useState } from "react"
import {
  Grid,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextareaAutosize,
  makeStyles,
  Button,
  Typography,
} from "@material-ui/core"
import { LocationOn as LocationOnIcon } from "@material-ui/icons"
import Autocomplete from "@material-ui/lab/Autocomplete"

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

function Presentation(props) {
  const {
    onChange,
    handleCheck,
    handleChange,
    handleSubmit,
    clients_list,
    reqClient,
    clients_locations_state,
    clientIds,
    isEnableBtn
  } = props
  const {
    clientId,
    workLocation,
    clientType,
    selectAddress,
    // subContracting,
    // contingencyinPayment,
    // prohibitionPeriod,
    // rightToHire,
    // liquidatedDamages,
    // identification,
    comments,
    isDraft,
  } = props.item
  console.log(Object.values(clients_list.filter((item)=>!clientIds.includes(item.id) && Object.keys(item).length !== 0)))
  const classes = useStyles()
  const clientTypeList = ["End Client", "Prime-Contractor", "Sub-Contractor"]
  const [address, setAddress] = useState({})
  useEffect(() => {
    if (clients_locations_state.isLoaded)
      setAddress(
        clients_locations_state.client_locations.filter((item) => item.id === selectAddress)[0]
      )
  }, [selectAddress, clients_locations_state.client_locations, clients_locations_state.isLoaded])
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            {clientType !== "Billable Client" ? (
              <Autocomplete
                fullWidth
                options={clients_list.filter((item)=>!clientIds.includes(item.id) && Object.keys(item).length !== 0)}
                onChange={(_, value) => {
                  if (value) {
                    onChange("clientId", value.id)
                  }
                }}
                value={reqClient}
                autoHighlight
                getOptionLabel={(option) => option.businessDisplayName}
                renderOption={(option) => (
                  <React.Fragment>{option.businessDisplayName}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Client *"
                    size="small"
                    fullWidth
                    id="placements-clients-client"
                  />
                )}
              />
            ) : (
              <TextField
                label="Client *"
                size="small"
                id="placements-clients-client"
                fullWidth
                value={reqClient.businessDisplayName}
                InputProps={{
                  readOnly: true,
                }}
              />
            )}
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox onChange={handleCheck} checked={workLocation} />
                }
                label="Set as work location"
                name="workLocation"
                id="placements-clients-worklocation"
                style={{ marginTop: "3px" }}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            {clientType !== "Billable Client" ? (
              <TextField
                fullWidth
                select
                label="Client Type"
                value={clientType}
                name="clientType"
                onChange={handleChange}
                id="placements-clients-clientType"
              >
                {clientTypeList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <TextField
                label="Client Type"
                size="small"
                fullWidth
                id="placements-clients-client"
                value={clientType}
                InputProps={{
                  readOnly: true,
                }}
              />
            )}
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              id="placements-clients-address-select"
              fullWidth
              options={clients_locations_state.client_locations}
              classes={{
                option: classes.icon,
              }}
              value={address}
              onChange={(_, value) => {
                if (value) {
                  onChange("selectAddress", value.id)
                }
              }}
              style={{ marginTop: "3px" }}
              autoHighlight
              getOptionLabel={(option) =>
                option.locationsline1 +
                " " +
                option.locationsline2 +
                " " +
                option.locationscity +
                " " +
                option.locationsstate_name +
                " " +
                option.locationszip +
                " " +
                option.locationscountry
              }
              renderOption={(option) => (
                <React.Fragment>
                  <Grid container alignItems="center">
                    <Grid item>
                      <LocationOnIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                      <span style={{ fontWeight: 700 }}>
                        {option.locationsline1 + " " + option.locationsline2}
                      </span>
                      <Typography variant="body2" color="textSecondary">
                        {option.locationscity +
                          " " +
                          option.locationsstate_name +
                          " " +
                          option.locationscountry +
                          " " +
                          option.locationszip}
                      </Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Address *"
                  size="small"
                  autoComplete="off"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextareaAutosize
              placeholder="Comments"
              onChange={handleChange}
              size="small"
              variant="outlined"
              name="comments"
              value={comments}
              rowsMin={5}
              style={{ width: "100%" }}
              id="placements-clients-comments"
            />
          </Grid>
        </Grid>
        <br />
        <div className="text-center">
          <Button
            type="submit"
            disabled={(!clientId || !selectAddress || !clientType) || !isEnableBtn}
            variant="contained"
            color={isDraft ? "primary" : "secondary"}
          >
            {isDraft ? "Done" : "Update"}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Presentation
