export default {
  data: {
    req_placements: [],
    clients_layers: [],
    vendors_data: [],
  },
  isLoaded: false,
  error: null,
  meta_info: {
    listener: () => {},
    isLoaded: false,
    error: null,
    data: {
      totalTickets: 0,
      ticketsMetaInfo: {
        open: 0,
        inProgress: 0,
        review: 0,
        closed: 0,
      },
      totalOverDue: 0,
      overDueData: {
        urgenet: 0,
        high: 0,
        medium: 0,
        low: 0,
      },
      projectMembersData: {},
    },
  },
}
