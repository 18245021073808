import React, { useState } from "react"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import { Grid } from "@material-ui/core"
import validate from "../../../validation"

const getInitialDates = (column) => {
  const dates = [null, null]
  const startDate = Date.parse(column.filterText)
  if (!isNaN(startDate)) {
    dates[0] = validate.dateFormatter(new Date(startDate).setHours(0, 0, 0, 0))
  }
  const toDate = Date.parse(
    column.filterArgument && column.filterArgument[0] ? column.filterArgument[0] : null
  )
  if (!isNaN(startDate)) {
    dates[1] = validate.dateFormatter(new Date(toDate).setHours(0, 0, 0, 0))
  }
  return dates
}

export function DateFilter(props) {
  const { column } = props
  const [dates, setDates] = useState(getInitialDates(column))
  const handleDateChange = (isSecondInput) => (date) => {
    if (!isNaN(Date.parse(date))) {
      const normalizedDate = !!date
        ? validate.dateFormatter(new Date(date).setHours(0, 0, 0, 0))
        : null
      if (isSecondInput) {
        column.filterArgument = []
        setDates([dates[0], normalizedDate])
        column.filterArgument[0] = normalizedDate ? normalizedDate : null
      } else {
        setDates([normalizedDate, dates[1]])
        column.filterText = normalizedDate ? normalizedDate : null
      }
    }
  }

  const isBetween = column.filterOperator === "Between"

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <Grid item>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            autoOk={true}
            clearable={true}
            label={isBetween ? "From" : "Select a date"}
            value={dates[0]}
            onChange={handleDateChange()}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
        {isBetween && (
          <Grid>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              minDate={dates[0]}
              margin="normal"
              id="date-picker-inline"
              label={"To"}
              autoOk={true}
              clearable={true}
              value={dates[1]}
              onChange={handleDateChange(true)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  )
}
