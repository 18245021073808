import React, { useState } from 'react'
import MaterialTable from 'material-table'
import { Link } from 'react-router-dom'
import MetaInfo from '../../../shared/getMetaInfo'
import parser from 'html-react-parser'
import validate from '../../../shared/validation'
import { useHistoryContext } from '../context'
import Popover from "@material-ui/core/Popover";

function PopoverContent({ popoverHtml }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <span
        onClick={handleClick}
        style={{
          cursor: "pointer",
          textDecoration: "underline",
          color: "#2c9273",
        }}
      >
        See detailed info...
      </span>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {parser(popoverHtml ?? "")}
      </Popover>
    </>
  );
}

export default function Presentation(props) {
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const { state, services } = useHistoryContext()

	const metaInfo = new MetaInfo()
	const columns = [
		{
			title: 'Action by',
			field: 'actionbyName',
			render: (rowData) => {
				if (state.modules.includes('console-customization')) {
					return (
						<Link to={'/console/employees/' + rowData.actionby}>
							{rowData.actionbyName}
						</Link>
					)
				} else {
					return <Link to={'/console/profile'}>{rowData.actionbyName}</Link>
					// this would completely remove any detail panel for this row
				}
			},
			//   (

			//   <Link to={"/console/employees/" + rowData.actionby}>
			//     {rowData.actionbyName}
			//   </Link>
			// ),
		},
		{ title: 'Timestamp', field: 'timestamp' },
		{
			title: 'History',
			field: 'history',
			render: (rowData) => formatSubject(rowData),
		},
	]

	function formatSubject(rowData) {
		const { type, subject, ActionBy, historyObj } = rowData
		console.log(type, subject)
		switch (type) {
			case 'demoteuser':
				return (
					<span>
						Demoted <Link to={'/console/employees/' + subject}>{subject}</Link>{' '}
						to user.
					</span>
				)

			case 'enableuser':
				return (
					<span>
						<Link to={'/console/employees/' + subject}>{subject}</Link> is
						activated.
					</span>
				)

			case 'promoteuser':
				return (
					<span>
						Promoted <Link to={'/console/employees/' + subject}>{subject}</Link>{' '}
						to manager.
					</span>
				)

			case 'disableuser':
				return (
					<span>
						<Link to={'/console/employees/' + subject}>{subject}</Link> is
						suspended.
					</span>
				)

			case 'createProject':
				return (
					<span>
						Created project{' '}
						<Link to={'/console/projects/' + subject.pid}>{subject.title}</Link>
						.
					</span>
				)
			case 'newLabel':
				return <span>Created New Label {subject.name}</span>
			case 'updateLabel':
				return <span>Updated Label {subject.name}</span>

			case 'createTask':
				return (
					<span>
						Added task{' '}
						<span to={'/console/projects/' + subject.pid + '/' + subject.tid}>
							{subject.title}
						</span>
						.
					</span>
				)

			case 'updateTask':
				return (
					<span>
						Updated task{' '}
						<span to={'/console/projects/' + subject.pid + '/' + subject.tid}>
							{subject.title}
						</span>
						.
					</span>
				)
			case 'expenseRejected':
				return (
					<span>
						<span to={'/console/expenseslist'}> Rejected Expense {''}</span>
					</span>
				)
			case 'expenseApproved':
				return (
					<span>
						<span to={'/console/expenseslist'}>Approved Expense {''}</span>
					</span>
				)
			case 'expenseUpdated':
				return (
					<span>
						<span to={'/console/expenseslist'}>Updated Expense </span>
					</span>
				)
			case 'placementCreated':
				return (
					<span>
						Placement Created With ID {''}
						{subject.placementID}
					</span>
				)

			case 'inviteUser':
				return (
					<span>
						Invited <Link to={'/console/employees/' + subject}>{subject}</Link>.
					</span>
				)

			case 'userRegister':
				return (
					<span>
						Employee <Link to={'/console/employees/' + subject}>{subject}</Link>{' '}
						registered.
					</span>
				)

			case 'newCommentOnTask':
				return (
					<span>
						Added comment to a task{' '}
						<span to={'/console/projects/' + subject.pid + '/' + subject.tid}>
							{subject.title}
						</span>{' '}
						.
					</span>
				)

			case 'updateCommentOnTask':
				return (
					<span>
						Updated Comment{' '}
						<span to={'/console/projects/' + subject.pid + '/' + subject.tid}>
							{subject.title}
						</span>{' '}
						.
					</span>
				)
			case 'newClient':
				return (
					<span>
						Created New Client{' '}
						<span to={'/console/clientlist/' + subject.clientId}>
							{subject.title}
						</span>{' '}
						.
					</span>
				)
			case 'updateCompanyDetails':
				return <span>Updated the company Details .</span>

			case 'deleteCommentInTask':
				return (
					<span>
						Deleted comment{' '}
						<span to={'/console/projects/' + subject.pid + '/' + subject.tid}>
							{subject.title ? subject.title : ''}
						</span>{' '}
						.
					</span>
				)
			// case "deleteCommentInTask":
			//   return (
			//     <span>
			//       {" "}
			//       <span to={"/console/projects/" + subject.pid + "/" + subject.tid}>
			//         {subject.title}
			//       </span>{" "}
			//       .
			//     </span>
			//   );

			case 'inviteAgain':
				return (
					<span>
						Invited again{' '}
						<Link to={'/console/employees/' + subject}>{subject}</Link> .
					</span>
				)

			case 'createSubTask':
				return (
					<span>
						Added Subtask{' '}
						<Link
							to={
								'/console/projects/' +
								subject.pid +
								'/' +
								subject.tid +
								'/' +
								subject.subtid
							}
						>
							{subject.title}
						</Link>
						.
					</span>
				)

			case 'deleteUser':
				return (
					<span>
						Removed <Link to={'/console/employees/' + subject}>{subject}</Link>.
					</span>
				)

			case "updateProfile":
				if ("actionDesc" in historyObj) {
					return (
						<span>
							{parser(historyObj.actionDesc?.action_desc_msg ?? "")}
							{historyObj.actionDesc?.popoverHtml?.length > 1 ? (
								<PopoverContent
									popoverHtml={
										historyObj.actionDesc?.popoverHtml ?? ""
									}
								/>
							) : (
								""
							)}
						</span>
					);
				} else {
					return <span>Updated Profile.</span>;
				}

			case 'addMemberToProject':
				return (
					<span>
						Added{' '}
						{/* <Link to={"/console/employees/" + subject.uid}>{subject.uid}</Link> */}{' '}
						<span>to</span>{' '}
						{/* <Link to={"/console/projects/" + subject.pid}> */}
						<span>Project</span>
						{/* </Link> */}.
					</span>
				)

			case 'deleteProjectMember':
				return (
					<span>
						Removed{' '}
						<Link to={'/console/employees/' + subject.uid}>{subject.uid}</Link>{' '}
						from <Link to={'/console/projects/' + subject.pid}>Project</Link>.
					</span>
				)

			case 'updateProjectAccessLevels':
				return (
					<span>
						Updated{' '}
						<Link to={'/console/employees/' + subject.uid}>{subject.uid}</Link>{' '}
						<Link to={'/console/projects/' + subject.pid}>Project</Link>{' '}
						permissions.
					</span>
				)

			case 'updateProject':
				return (
					<span>
						Updated project:{' '}
						<Link to={'/console/projects/' + subject.pid}>{subject.title}</Link>
						.
					</span>
				)

			case 'employeeRequestLetter':
				return <span>Requested {subject}.</span>

			case 'officerRejectsLetter':
				return (
					<span>
						Rejected{' '}
						<Link to={'/console/employees/' + subject.ActionOn}>
							{subject.ActionOn}
						</Link>{' '}
						{subject.docType} letter.
					</span>
				)

			case 'officerIssuesLetter':
				return (
					<span>
						Issued{' '}
						<Link to={'/console/employees/' + subject.ActionOn}>
							{subject.ActionOn}
						</Link>{' '}
						{subject.docType} letter.
					</span>
				)

			case 'addAuthorisedSignature':
				return (
					<span>
						<Link to={'/console/employees/' + subject}>{subject}</Link>
						's signature added.
					</span>
				)

			case 'deletedAuthorisedSignature':
				return (
					<span>
						<Link to={'/console/employees/' + subject}>{subject}</Link>
						's signature removed.
					</span>
				)

			case 'addLetterTemplate':
				return (
					<span>
						Created new letter template:<b>{subject.name}</b> in {subject.type}{' '}
					</span>
				)

			case 'updateLetterTemplate':
				return (
					<span>
						Updated letter template:<b>{subject.name}</b> in {subject.type}{' '}
					</span>
				)

			case 'deleteLetterTemplate':
				return (
					<span>
						Deleted letter template:<b>{subject.name}</b> from {subject.type}{' '}
					</span>
				)

			case 'newCategoryInWiki':
				return (
					<span>
						New wiki category added: <b>{subject.categoryName} </b>.
					</span>
				)

			case 'deleteCategoryInWiki':
				return (
					<span>
						A wiki category deleted: <b>{subject.categoryName} </b>.
					</span>
				)

			case 'updateCategoryInWiki':
				return (
					<span>
						A wiki category updated: <b>{subject.categoryName}</b>.
					</span>
				)

			case 'newPageInWiki':
				return (
					<span>
						New wiki page created:{' '}
						<Link to={`/console/wiki/${subject.wikiArticleId}`}>
							{subject.title}{' '}
						</Link>
						.
					</span>
				)

			case 'updatePageInWiki':
				return (
					<span>
						Wiki page updated:{' '}
						<Link to={`/console/wiki/${subject.wikiArticleId}`}>
							{subject.title}{' '}
						</Link>
						.
					</span>
				)

			case 'deletePageInWiki':
				return (
					<span>
						Wiki page deleted:{' '}
						<Link to={`/console/wiki/${subject.wikiArticleId}`}>
							{subject.title}{' '}
						</Link>
						.
					</span>
				)

			case 'voteForWikiArticle':
				return (
					<span>
						Voted for an article:{' '}
						<Link to={`/console/wiki/${subject.wikiArticleId}`}>
							{subject.title}{' '}
						</Link>
						.
					</span>
				)

			case 'downVoteForWikiArticle':
				return (
					<span>
						Devoted for an article:{' '}
						<Link to={`/console/wiki/${subject.wikiArticleId}`}>
							{subject.title}{' '}
						</Link>
						.
					</span>
				)

			case 'followWikiArticle':
				return (
					<span>
						Started following the article{' '}
						<Link to={`/console/wiki/${subject.wikiArticleId}`}>
							{subject.title}{' '}
						</Link>
						.
					</span>
				)

			case 'unFollowWikiArticle':
				return (
					<span>
						Unfollowed the article:{' '}
						<Link to={`/console/wiki/${subject.wikiArticleId}`}>
							{subject.title}{' '}
						</Link>
					</span>
				)

			case 'newArticleComment':
				return (
					<span>
						Commented on the article:{' '}
						<Link to={`/console/wiki/${subject.wikiArticleId}`}>
							{subject.hasOwnProperty('title') ? subject.title : 'article'}{' '}
						</Link>
						.
					</span>
				)

			case 'updateArticleComment':
				return (
					<span>
						Updated comment on the article:{' '}
						<Link to={`/console/wiki/${subject.wikiArticleId}`}>
							{subject.hasOwnProperty('title') ? subject.title : 'article'}
						</Link>
						.
					</span>
				)

			case 'deleteArticleComment':
				return (
					<span>
						Deleted comment on the article:{' '}
						<Link to={`/console/wiki/${subject.wikiArticleId}`}>
							{subject.hasOwnProperty('title') ? subject.title : 'article'}
						</Link>
						.
					</span>
				)
			case 'updatePlacement':
				return (
					<span>
						Updated Placement
						{/* :{" "} */}
						{/* <Link to={`/console/newplacement/${subject.placementID}`}>
              {subject.hasOwnProperty("title")
                ? subject.title
                : subject.sectionName}
            </Link> */}
						.
					</span>
				)
			case 'addSectionToPlacement':
				return (
					<span>
						Added Section For Placement
						{/* :{" "} */}
						{/* <Link to={`/console/wiki/${subject.placementID}`}> */}
						{/* {subject.hasOwnProperty("title")
                ? subject.title
                : subject.sectionName + "For" + subject.placementID} */}
						{/* </Link> */}.
					</span>
				)

			case 'closeplacement':
				return (
					<div className='d-flex'>
						{`${historyObj?.subject?.placementID}`}{' '}
						{parser(
							'Closed Placement' +
								(historyObj?.eventDetails?.after?.closingReason
									? ' - ' + historyObj?.eventDetails?.after?.closingReason
									: ' ')
						)}
					</div>
				)

			case 'reopenplacement':
				return (
					<div className='d-flex'>
						{`${historyObj?.subject?.placementID}`}{' '}
						{parser(
							'Reopened Placement' +
								(historyObj?.eventDetails?.after?.reopeningReason
									? ' - ' + historyObj?.eventDetails?.after?.reopeningReason
									: ' ')
						)}
					</div>
				)

			
			case "updateWorkQueueDocument":
				return (
					<span className="d-flex">
						<Link to={`/console/employees/${historyObj?.subject?.uid}`}>
							{historyObj?.subject?.uid}
						</Link>{" "}
						&nbsp; submitted expired document for review.
					</span>
				);
			
			case "reviewWorkQueueDocument":
				return (
					<span className="d-flex">
						<Link to={`/console/employees/${historyObj?.subject?.uid}`}>
							{historyObj?.subject?.uid}
						</Link>{" "}
						&nbsp; document is {historyObj?.eventDetails?.after?.status}
						{historyObj?.eventDetails?.after?.status === "rejected"
							? " - " + (historyObj?.eventDetails?.after?.rejectedReason || "")
							: ""}
					</span>
				);
			case 'invoiceUpdated':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return (
						<span>Updated invoice {historyObj.subject.invoiceID}</span>
					)
				}
			case 'invoiceCreated':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return (
						<span>Created invoice {historyObj.subject.invoiceID}</span>
					)
				}
			case 'invoiceToVoid':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return (
						<span>
							<span>Invoice Void {historyObj.subject.invoiceID}</span>{' '}
							<span>{parser(
								'Void remarks' +
									(historyObj?.eventDetails?.after?.voidReason
										? ' - ' + historyObj?.eventDetails?.after.voidReason
										: '')
							)}</span>
						</span>
					)
				}
			case 'invoiceToVoidReasonUpdated':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return (
						<span>
							<span>Invoice follow up {historyObj.subject.invoiceID}</span>{' '}
							<span>{parser(
								'Void remarks updated' +
									(historyObj?.eventDetails?.after?.voidReason
										? ' - ' + historyObj?.eventDetails?.after.voidReason
										: '')
							)}</span>
						</span>
					)
				}
			case 'sendInvoiceToClient':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return(
						<span>
							Invoice sent to client {historyObj.subject.invoiceID}
						</span>
					)
				}
			case 'invoiceFollowup':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return (
						<span>
							<span>Invoice follow up {historyObj.subject.invoiceID}</span>{' '}
							<span>{parser(
								'Follow up' +
									(historyObj?.eventDetails?.after?.followupComment
										? ' - ' + historyObj?.eventDetails?.after.followupComment
										: '')
							)}</span>
						</span>
					)
				}
			case 'invoiceFollowupUpdated':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return (
						<span>
							<span>Invoice follow up {historyObj.subject.invoiceID}</span>{' '}
							<span>{parser(
								'Follow up updated' +
									(historyObj?.eventDetails?.after?.followupComment
										? ' - ' + historyObj?.eventDetails?.after.followupComment
										: '')
							)}</span>
						</span>
					)
				}
			case 'reminderToClient':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return(
						<span>
							Sent invoice remainder to client {historyObj.subject.invoiceID}
						</span>
					)
				}
            case 'clientApproveInvoice':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return(
						<span>
							Client Approved invoice {historyObj.subject.invoiceID}
						</span>
					)
				}
            case 'clientRejectInvoice':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return(
						<span>
							Client Rejected invoice {historyObj.subject.invoiceID}
						</span>
					)
				}
			case 'newPaymentForInvoice':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return(
						<span>
							{type}
						</span>
					)
				}
			case 'updatePaymentForInvoice':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return(
						<span>
							{type}
						</span>
					)
					}
			case 'timesheetUpdated':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return <span>Updated Timesheet .</span>
				}
			case 'timesheetSubmitted':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return <span>Submitted Timesheet .</span>
				}
			case 'timesheetApproved':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return <span>Approved Timesheet.</span>
				}
			case 'timesheetRejected':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return <span>Rejected Timesheet .</span>
				}
			case 'timesheetHoursUpdated':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return <span>Timesheet working hours updated .</span>
				}
			case 'updateTimesheetDetailsinPlacement':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return <span>Updated Timesheet Details in placement.</span>
				}

			default:
				return <span>{type}.</span>
		}
	}

	function formatSearch(type, subject, ActionBy) {
		switch (type) {
			case 'demoteuser':
				return 'Demoted ' + subject + ' to user.'

			case 'enableuser':
				return subject + ' is activated.'

			case 'promoteuser':
				return 'Promoted ' + subject + ' to manager.'

			case 'disableuser':
				return subject + ' is suspended.'

			case 'createProject':
				return 'Created project ' + subject.title + '.'

			case 'createTask':
				return 'Added task ' + subject.title + '.'

			case 'updateTask':
				return 'Updated task ' + subject.title + '.'

			case 'inviteUser':
				return 'Invited ' + subject + '.'

			case 'userRegister':
				return 'Employee ' + subject + ' registered.'

			case 'newComment':
				return subject.title

			case 'updateComment':
				return subject.title

			case 'deleteCommentInTask':
				return subject.title

			case 'inviteAgain':
				return 'Invited again ' + subject + '.'

			case 'employeeRequestLetter':
				return 'Requested ' + subject + '.'

			case 'officerRejectsLetter':
				return (
					'Rejected ' + subject.ActionOn + ' ' + subject.docType + ' letter.'
				)

			case 'officerIssuesLetter':
				return 'Issued ' + subject.ActionOn + ' ' + subject.docType + ' letter.'

			case 'createSubTask':
				return 'Added Subtask ' + subject.title + '.'

			case 'deleteUser':
				return 'Removed ' + subject + '.'

			case 'updateProfile':
				return 'Updated profile ' + subject + '.'

			case 'addMemberToProject':
				return 'Added ' + subject.uid + ' to project.'

			case 'deleteProjectMember':
				return 'Removed ' + subject.uid + ' from  Project.'

			case 'updateAccessLevels':
				return 'Updated ' + subject.uid + ' Project permissions.'

			case 'updateProject':
				return 'Updated project: ' + subject.pid + '.'

			case 'addAuthorisedSignature':
				return subject + "'s signature added."

			case 'deletedAuthorisedSignature':
				return subject + "'s signature removed."

			case 'addLetterTemplate':
				return (
					'Created new letter template:' + subject.name + ' in ' + subject.type
				)

			case 'updateLetterTemplate':
				return 'Updated letter template:' + subject.name + ' in ' + subject.type

			case 'deleteLetterTemplate':
				return (
					'Deleted letter template:' + subject.name + ' from ' + subject.type
				)

			case 'newCategoryInWiki':
				return `New wiki category added: ${subject.categoryName}.</span>`

			case 'deleteCategoryInWiki':
				return `A wiki category deleted: ${subject.categoryName}.`

			case 'updateCategoryInWiki':
				return `A wiki category updated: ${subject.categoryName}.`

			case 'newPageInWiki':
				return `New wiki page created: ${subject.title}.`

			case 'updatePageInWiki':
				return `Wiki page updated: ${subject.title}.`

			case 'deletePageInWiki':
				return `Wiki page deleted: ${subject.title}.`

			case 'voteForWikiArticle':
				return `Voted for an article: ${subject.title}.`

			case 'downVoteForWikiArticle':
				return `Devoted for an article: ${subject.title}.`

			case 'followWikiArticle':
				return `Started following the article: ${subject.title}.`

			case 'unFollowWikiArticle':
				return `Unfollowed the article: ${subject.title}`

			case 'newArticleComment':
				return `Commented on the article: ${
					subject.hasOwnProperty('title') ? subject.title : 'article'
				}.`

			case 'updateArticleComment':
				return `Updated comment on the article: ${
					subject.hasOwnProperty('title') ? subject.title : 'article'
				}.`

			case 'deleteArticleComment':
				return `Deleted comment on the article: ${
					subject.hasOwnProperty('title') ? subject.title : 'article'
				}.`

			default:
				return <span>-----------------</span>
		}
	}

	const getData = async (query) => {
		const { pageSize, page } = query
		let historyList
		if (page === 0) {
			setRowsPerPage(pageSize)
			historyList = await services.getCurrentPage(pageSize)
		} else {
			if (page > state.page) {
				historyList = await services.getNextPage(page, pageSize)
			} else {
				historyList = await services.getPrevPage(page, pageSize)
			}
		}

		const data = historyList.map((history) => {
			return {
				actionby: history.actionBy,
				actionbyName: metaInfo.emailToName(history.actionBy),
				timestamp:
					history.createdAt === ''
						? history.createdAt
						: validate.dateAndTimeFormatter(history.createdAt),
				history: formatSearch(history.type, history.subject),
				type: history.type,
				subject: Object.values(history.subject),
				historyObj: history,
			}
		})

		return { data, page, totalCount: Number.MAX_SAFE_INTEGER } // TODO: need to get current history count
	}

	const localization = {
		pagination: {
			labelDisplayedRows: `Page: ${state.page}`,
		},
	}

	return (
		<MaterialTable
			title='History'
			columns={columns}
			data={(query) => getData(query)}
			localization={localization}
			options={{
				filtering: false,
				columnsButton: false,
				showFirstLastPageButtons: false,
				pageSize: rowsPerPage,
				paginationPosition: 'both',
				paginationType: 'normal',
				pageSizeOptions: [10, 20, 50, 100, 250],
				padding: 'dense',
				rowStyle: (_, index) =>
					index % 2 === 0 ? { background: '#f5f5f5' } : null,
				emptyRowsWhenPaging: false,
				search: false,
			}}
		/>
	)
}
