import {
	useMutation,
	useQueries,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
	detachDsoFromSchool,
	getDso,
	getSchool,
} from "../../../../api/services/flair-shared/schools";
import { getEmployeesBySchoolId } from "../../../../api/services/default/employee";
import { useDialog } from "../../../../hooks/useDialog";
import AddSchoolModal from "./AddSchoolModal";
import { Link } from "react-router-dom";
import { AlertService } from "../../../../shared/alerts";
import AddDsoModal from "./AddDsoModal";

function ViewDso({ dso, schoolId }) {
	const { open, handleOpen, handleClose } = useDialog();
	const alertService = new AlertService();

	const queryClient = useQueryClient();

	const useDetachDso = useMutation({
		mutationFn: () => detachDsoFromSchool(schoolId, dso.id),
		onSuccess: () => {
			queryClient.invalidateQueries(["getSchool", schoolId]);
		},
	});

	const handleDelete = async () => {
		const result = await alertService.confirmMessage(
			"Are you sure?",
			"You want to delete this DSO?"
		);
		if (result.isConfirmed) {
			await useDetachDso.mutateAsync();
		}
	};

	return (
		<>
			<AddDsoModal
				open={open}
				handleClose={handleClose}
				dso={dso}
				isEdit={true}
				schoolId={schoolId}
			/>
			<Row>
				<Col xl={11}>
					<Row>
						<Col xl={4}>
							<div className="mb-3">
								<div className="text-muted">DSO Name</div>
								<div className="fs-16 fw-medium text-base">{dso?.name}</div>
							</div>
						</Col>
						<Col xl={4}>
							<div className="mb-3">
								<div className="text-muted">DSO Email</div>
								<div className="fs-16 fw-medium text-base">{dso?.email}</div>
							</div>
						</Col>
						<Col xl={4}>
							<div className="mb-3">
								<div className="text-muted">DSO Phone</div>
								<div className="fs-16 fw-medium text-base">{dso?.phone}</div>
							</div>
						</Col>
					</Row>
				</Col>
				<Col xl={1}>
					<div className="d-flex justify-content-end">
						<button className="linkbtn mt-1" onClick={handleOpen}>
							<i className="fa-solid fa-pen"></i>
						</button>
						<button className="linkbtn danger m-1" onClick={handleDelete}>
							<i className="fa-solid fa-trash"></i>
						</button>
					</div>
				</Col>
			</Row>
		</>
	);
}

export default function ViewSchool({ schoolId }) {
	const { open, handleOpen, handleClose } = useDialog();
	const { open: openAddDso, handleOpen: handleOpenAddDso, handleClose: handleCloseAddDso } = useDialog();

	const { data: school = {} } = useQuery({
		queryKey: ["getSchool", schoolId],
		queryFn: () => getSchool(schoolId),
		enabled: !!schoolId,
	});

	const dsoQueryResult = useQueries({
		queries:
			school?.dsoIds?.map((dsoId) => ({
				queryKey: ["getDso", dsoId],
				queryFn: () => getDso(dsoId),
			})) || [],
	});

	const { data: employees = [] } = useQuery({
		queryKey: ["getEmployeesBySchoolId", schoolId],
		queryFn: () => getEmployeesBySchoolId(schoolId),
		enabled: !!schoolId,
	});

	const dsos = dsoQueryResult.map((result) => result.data);

	return (
		<>
			<AddSchoolModal
				open={open}
				handleClose={handleClose}
				isEdit={true}
				school={school}
			/>
			<AddDsoModal
				open={openAddDso}
				handleClose={handleCloseAddDso}
				schoolId={schoolId}
				isEdit={false}
			/>
			<Container>
				<Card>
					<Card.Body>
						<Row>
							<Col xl={12}>
								<div className="d-flex justify-content-between">
									<h5 className="card-title mb-3 mt-1 header-title">
										School Info
									</h5>
									<button className="linkbtn mt-1" onClick={handleOpen}>
										<i className="fa-solid fa-pen"></i>
									</button>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xl={4}>
								<div className="mb-3">
									<div className="text-muted">School Name</div>
									<div className="fs-16 fw-medium text-base">{school.name}</div>
								</div>
							</Col>
							<Col xl={4}>
								<div className="mb-3">
									<div className="text-muted">Address</div>
									<div className="fs-16 fw-medium text-base">
										{[school.line1, school.line2, school.city]
											.filter(Boolean)
											.join(", ")}
										<br />
										{[school.state, school.country, school.zip]
											.filter(Boolean)
											.join(", ")}
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xl={12}>
								<hr />
							</Col>
						</Row>
						<Row>
							<Col xl={12}>
								<div className="d-flex justify-content-between">
									<h5 className="card-title mb-3 mt-1 header-title">
										DSO Info
									</h5>
									<button className="linkbtn mt-1" onClick={handleOpenAddDso}>
										<i className="fa-solid fa-plus"></i>
									</button>
								</div>
							</Col>
						</Row>
						{dsos.map((dso) => (
							<ViewDso dso={dso} schoolId={schoolId} />
						))}
						<Row>
							<Col xl={12}>
								<hr />
							</Col>
						</Row>
						<Row>
							<Col xl={12}>
								<h5 className="card-title mb-3 mt-1 header-title">
									Employee Info (Total: {employees.length})
								</h5>
							</Col>
						</Row>
						<Row>
							<Col xl={12}>
								<div className="d-flex flex-wrap">
									{employees.map((employee) => (
										<Link
											key={employee.uid}
											to={`/console/employees/${employee.uid}`}
											className="mx-1 mb-2"
										>
											<img
												class="bg-info rounded-circle"
												style={{
													width: "56px",
												}}
												src={employee.imageURL}
												alt={employee.uid + "image"}
											/>
										</Link>
									))}
								</div>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Container>
		</>
	);
}
