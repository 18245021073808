import React from 'react'
import { GoFileSymlinkFile } from 'react-icons/go'
import { Button,Typography, LinearProgress } from "@material-ui/core"
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import AppModalHeader from '../../../../../shared/components/Modals/AppModalHeader';
import { useDialog } from '../../../../../hooks/useDialog';
import Modal from 'react-bootstrap/Modal';
import BootStrapButtom from 'react-bootstrap/Button';

const useStyles = makeStyles({
  root: {
    background: '#2C9273',
    borderRadius:3,
    border: 0,
    color: 'white',
    height: 30,
    padding: '0 10px',
  },
});

function Presentation(props) {
  const { state, handleDocumentSubmit, setState } = props
  const classes = useStyles();
  const { open, handleOpen, handleClose } = useDialog(); 
  const [dialogIndex, setDialogIndex] = React.useState(null);

  const handleDeleteDocument = (index) => {
    const updatedAttachmentDetails = state.timesheetInfo.attachmentDetails.filter(
      (_, i) => i !== index
    );
    setState({
      timesheetInfo: {
        ...state.timesheetInfo,
        attachmentDetails: updatedAttachmentDetails,
      },
    });
    handleClose(); 
    setDialogIndex(null);
    
  };

  const handleViewDocument = () => {
    window.open(state.timesheetInfo.attachmentDetails[dialogIndex].publicURL, '_blank');
    handleClose(); 
    setDialogIndex(null);
  }


  if (!state.isSettingsLoaded)
    return (
      <div>
        <Typography component="div" variant={"h3"}>
          <Skeleton />
        </Typography>
        <Typography component="div" variant={"body1"}>
          <Skeleton />
        </Typography>
        <Typography component="div" variant={"caption"}>
          <Skeleton />
        </Typography>
      </div>
    )
  return (
    <div>
      <div className="mt-2 mb-2">
        <br />
        <input
          className="d-none"
          id="contained-button-file-ehfile"
          multiple={true}
          type="file"
          onChange={handleDocumentSubmit}
          disabled={!state.selectedRange.length || state.isAlreadySubmitted}
        />
        {state.timesheetSettings.attachMandatory || (state.type === "edit" ? state.timesheetInfo.attachmentDetails.publicURL.length : false) ? (
          <div>
            <label htmlFor="contained-button-file-ehfile">
              <Button
                disabled={
                  !state.selectedRange.length || state.isAlreadySubmitted || state.type === "view"
                }
                fullWidth
                variant="contained"
                component="span"
                classes={{
                  root: classes.root,
                }}
              >
                Upload Document
              </Button>
            </label>
            <p>
              {state.timesheetInfo.attachmentDetails.publicURL ? (
                 <span>
                 <a
                   target="_blank"
                   rel="noopener noreferrer"
                   href={state.timesheetInfo.attachmentDetails.publicURL}
                 >
                    <GoFileSymlinkFile size={22} />
                 </a>              
               </span>
              ) :  state.timesheetInfo.attachmentDetails.length > 0  ? 
                  state.timesheetInfo.attachmentDetails.map((item, index) => {
                    return  <span key={index}>
                      <GoFileSymlinkFile size={22} onClick={() => { setDialogIndex(index); handleOpen(); }} />    
                  </span>
              })
               : (
                <p>No file choosen</p>
              )}{" "}
            </p>
            {state.isDocumentUploading ? "Uploading..." : ""}
          </div>
        ) : null}
      </div>
      <Modal show={open} onHide={handleClose} centered size="md">
      <AppModalHeader title=" Do you want to view or delete the document?" />
      <Modal.Body>
          <Typography variant="body1">
            {state?.timesheetInfo?.attachmentDetails[dialogIndex]?.sourcePath}
          </Typography>
        </Modal.Body>
      <Modal.Footer>
			  <BootStrapButtom className="btn btn-success rounded-pill px-4 btn-lg" onClick={handleViewDocument}>
				    View
			  </BootStrapButtom>
			  <BootStrapButtom className="btn btn-secondary rounded-pill px-4 btn-lg" onClick={() => { handleDeleteDocument(dialogIndex);  }}>
				    Delete
			  </BootStrapButtom>
		  </Modal.Footer>
  
      </Modal>
    </div>
  )
}

export default Presentation
