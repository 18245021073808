import { defaultInstance } from "../../../instances/default.instance";

/**
 * @param {Record} params
 * @param {String?} params.employeeId
 * @param {AbortSignal?} signal
 * @returns {Promise<Record[]>}
 */
export const getAllSoa = (params, signal) => {
	const { employeeID } = params;
	const query = new URLSearchParams();
	if (employeeID) {
		query.append("employeeID", employeeID);
	}
	return defaultInstance({
	  method: 'GET',
	  url: `/payrolls/v2/soa?${query.toString()}`,
	  signal,
	});
	// return [
	// 	{
	// 		status: "Working",
	// 		month: "Jan",
	// 		year: 2023,
	// 		placementID: "PC - 4",
	// 		totalRevenue: 0,
	// 		earnedPay: 0,
	// 		profit: 0,
	// 		pay: 1000,
	// 		deductions: 0,
	// 		insurance: 0,
	// 		bonus: -1000,
	// 	},
	// 	{
	// 		status: "Training",
	// 		month: "Feb",
	// 		year: 2023,
	// 		placementID: "PC - 1",
	// 		totalRevenue: 11760,
	// 		earnedPay: 3500,
	// 		profit: 8260,
	// 		pay: 3500,
	// 		deductions: 0,
	// 		insurance: 250,
	// 		bonus: -250,
	// 	},
	// 	{
	// 		status: "Training",
	// 		month: "Mar",
	// 		year: 2023,
	// 		placementID: "PC - 1",
	// 		totalRevenue: 11760,
	// 		earnedPay: 3500,
	// 		profit: 8260,
	// 		pay: 3500,
	// 		deductions: 0,
	// 		insurance: 250,
	// 		bonus: -250,
	// 	},
	// 	{
	// 		status: "Working",
	// 		month: "Apr",
	// 		year: 2023,
	// 		placementID: "PC - 1",
	// 		totalRevenue: 11760,
	// 		earnedPay: 3500,
	// 		profit: 8260,
	// 		pay: 3500,
	// 		deductions: 0,
	// 		insurance: 250,
	// 		bonus: -250,
	// 	},
	// 	{
	// 		status: "Prospect",
	// 		month: "May",
	// 		year: 2023,
	// 		placementID: "PC - 1",
	// 		totalRevenue: 11760,
	// 		earnedPay: 3500,
	// 		profit: 8260,
	// 		pay: 3500,
	// 		deductions: 0,
	// 		insurance: 250,
	// 		bonus: -250,
	// 	},
	// 	{
	// 		status: "Bench",
	// 		month: "Jun",
	// 		year: 2023,
	// 		placementID: "PC - 1",
	// 		totalRevenue: 11760,
	// 		earnedPay: 8820,
	// 		profit: 2940,
	// 		pay: 7500,
	// 		deductions: 1000,
	// 		insurance: 250,
	// 		bonus: 70,
	// 	},
	// 	{
	// 		status: "Prospect",
	// 		month: "May",
	// 		year: 2023,
	// 		placementID: "PC - 2",
	// 		totalRevenue: 12600,
	// 		earnedPay: 9450,
	// 		profit: 3150,
	// 		bonus: 9450,
	// 	},
	// 	{
	// 		status: "Bench",
	// 		month: "Jun",
	// 		year: 2023,
	// 		placementID: "PC - 2",
	// 		totalRevenue: 12600,
	// 		earnedPay: 9450,
	// 		profit: 3150,
	// 		bonus: 9450,
	// 	},
	// ];
};
