import React from "react"
import {
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { AttachFile as AttachFileIcon } from "@material-ui/icons"
import validate from "../../../../../../../shared/validation"
import { GoFileSymlinkFile } from "react-icons/go"

const useStyles = makeStyles(() => ({
  input: {
    display: "none",
  },
}))

const documentTypes = ["Incorporation", "PO", "MSA", "COI", "W9", "MSA &PO", "Others"]
const statusList = ["Active", "Inactive"]

function Presentation(props) {
  const {
    documentType,
    isUploading,
    work_doc,
    effectiveDate,
    effectiveUntilDate,
    handleChange,
    handleDateChange,
    handleSubmit,
    handleFile,
    clearState,
  } = props
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    clearState()
  }
  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add documents
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <div style={{ minWidth: "100%" }}>
                  <TextField
                    select
                    label="Document Type"
                    size="small"
                    value={documentType}
                    fullWidth
                    required
                    name="documentType"
                    id="placements-documents-type"
                    onChange={handleChange}
                  >
                    {documentTypes.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  label="Status"
                  size="small"
                  value={new Date(effectiveUntilDate) < new Date() ? "Inactive" : "Active"}
                  fullWidth
                  required
                  name="status"
                  id="placements-documents-status"
                  onChange={handleChange}
                >
                  {statusList.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={6}>
                      <KeyboardDatePicker
                        required
                        size="small"
                        style={{ marginTop: "3px", marginRight: "10px" }}
                        id="placements-documents-effectiveDate"
                        label="Effective date"
                        format="MM/dd/yyyy"
                        value={effectiveDate ? effectiveDate : null}
                        onChange={(date) => handleDateChange("effectiveDate", date)}
                        name="effectiveDate"
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <KeyboardDatePicker
                        required
                        minDate={effectiveDate}
                        //maxDate={state.project.enddate}
                        id="placements-documents-effectiveUntilDate"
                        label="Effective until date"
                        format="MM/dd/yyyy"
                        value={effectiveUntilDate ? effectiveUntilDate : null}
                        onChange={(date) => handleDateChange("effectiveUntilDate", date)}
                        name="effectiveUntilDate"
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        fullWidth
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              {documentType && validate.checkDateDiff(effectiveDate, effectiveUntilDate) ? (
                <Grid item xs={6}>
                  <div style={{ marginTop: "6px" }}>
                    <input
                      className={classes.input}
                      id="placements-documents-file"
                      type="file"
                      onChange={handleFile}
                    />
                    <label htmlFor="placements-documents-file">
                      <Button variant="contained" color="primary" component="span">
                        <span>
                          <AttachFileIcon fontSize="small" color="inherit" /> Attachment
                        </span>
                      </Button>
                    </label>
                    <div className="d-flex justify-content-between">
                      <p>
                        {Object.keys(work_doc).length ? (
                          <a target="_blank" rel="noopener noreferrer" href={work_doc.url}>
                            <GoFileSymlinkFile size={22} />{" "}
                            {work_doc.name.substr(0, work_doc.name.lastIndexOf("_"))}
                          </a>
                        ) : (
                          <p>No file choosen</p>
                        )}
                      </p>
                    </div>
                  </div>
                  {isUploading ? <p>Uploading please wait...</p> : ""}
                </Grid>
              ) : null}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose()
              handleSubmit()
            }}
            disabled={
              !validate.checkDateDiff(effectiveDate, effectiveUntilDate) ||
              !documentType ||
              !work_doc.url
            }
            color="primary"
            variant="contained"
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Presentation
