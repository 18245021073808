import React from "react"
import { connect } from "react-redux"
import { firestoreConnect } from "react-redux-firebase"
import { compose } from "redux"
import Presentation from "./Presentation"
import {
  addNewCategory,
  deleteCategory,
  updateCategory,
} from "../../middleware"

function Container(props) {
  const { onRowAdd, onRowDelete, onRowUpdate } = props
  const { categories } = props
  return (
    <div>
      <Presentation
        categories={categories}
        onRowAdd={onRowAdd}
        onRowDelete={onRowDelete}
        onRowUpdate={onRowUpdate}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const firestore = state.firestore.ordered
  return {
    categories: firestore.categories,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onRowAdd: (payload, resolve, reject) => {
      dispatch(addNewCategory(payload, resolve, reject))
    },
    onRowUpdate: (payload, categoryId, resolve, reject) => {
      dispatch(updateCategory(payload, categoryId, resolve, reject))
    },
    onRowDelete: (categoryId, resolve, reject) => {
      dispatch(deleteCategory(categoryId, resolve, reject))
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collectionGroup: "CATEGORIES_DOCS",
        where: [["isExist", "==", true]],
        storeAs: "categories",
      },
    ]
  })
)(Container)
