import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "suneditor/dist/css/suneditor.min.css";
import "semantic-ui-css/semantic.min.css";
import App from "./App";
import { Provider, useSelector } from "react-redux";
import firebase from "./config/fbConfig";
import { ReactReduxFirebaseProvider, isLoaded } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import Backdrop from "./shared/backDrop";
import store from "./store/Index";
import axios from "axios";
import { configuration } from "./config/companyConfig";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { InterceptorService } from "./shared/InterceptorService";

const base_url = configuration.REST_api;
const profileSpecificProps = {
  userProfile: "EMPLOYEES",
  useFirestoreForProfile: true,
  enableRedirectHandling: false,
  resetBeforeLogin: false
};

const rffProps = {
  firebase,
  config: { ...profileSpecificProps },
  dispatch: store.dispatch,
  createFirestoreInstance
};

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      user
        .getIdToken()
        .then((idToken) => {
          axios.defaults.baseURL = base_url;
          return;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  });
  if (!isLoaded(auth)) return <Backdrop openBackdrop={true} />;
  return children;
}

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Application Error</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // stop refetching data when window is not focused
      retry: false, // disable retries on queries
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: 1000 * 60 * 60 // 1 hour
    }
  }
});
InterceptorService.instance.addBearerToken();

function Root() {
  return (
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<ErrorBoundary
					FallbackComponent={ErrorFallback}
					onReset={() => {
						// reset the state of your app so the error doesn't happen again
					}}
				>
					<ReactReduxFirebaseProvider {...rffProps}>
						<AuthIsLoaded>
							<App />
						</AuthIsLoaded>
					</ReactReduxFirebaseProvider>
				</ErrorBoundary>
			</Provider>
      <ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
}

ReactDOM.render(
  <Root />,
  document.getElementById("root")
);
