import React from "react"
import { connect } from "react-redux"
import { isLoaded } from "react-redux-firebase"
import Presentation from "./Presentation"
import { firestoreConnect } from "react-redux-firebase"
import { compose } from "redux"
function Container(props) {
  console.log("props",props)
  
  if (isLoaded(props.names, props.expenseApproval))
  if (props.placements.length > 0) {
  const placement = props.placements[0];
  const isExist = placement.isExist;
  return (
    <div>
      {isExist === false ? (
        <>
          <p>Placement Closed</p>
          <div>
          <Presentation {...props} />
          </div>
        </>
      ) : (
        <Presentation {...props} />
      )}
    </div>
  );
}
  return <p>Loading....</p>
}
const mapStateToProps = (state, ownProps) => {
  const states = state.firestore.ordered.expenseApproval
  return {
    names: state.firestore.ordered.names,
    expenseApproval: states && states.length !==0
      ? state.firestore.ordered.expenseApproval[0].approvalBy
      : state.firestore.ordered.expenseApproval,
  }
}
export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
   
    const employeeID = props.placements[0]?.employeeID
    if (!props.expenseApproval && employeeID)
      return [
        {
          collection: "EMPLOYEES",
          doc: employeeID,
          subcollections: [
            {
              collection: "PLACEMENTS",
              doc: props.placementID,
              subcollections: [
                {
                  collection: "SETTINGS",
                  doc: "expense_details",
                  storeAs: "expenseApproval",
                },
              ],
            },
          ],
          storeAs: "expenseApproval",
        },
      ]

    return []
  })
)(Container)
