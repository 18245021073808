import React from 'react'

function Presentation() {
  return (
    <div>
      Authenticating please wait...
    </div>
  )
}

export default Presentation
