import { defaultInstance } from "../../instances/default.instance";

/**
 * @param {Number?} status
 * @param {AbortSignal?} signal
 * @returns {Promise<Array<Record>>}
 */
export const getEmployees = (status, signal) => {
  const params = new URLSearchParams();
  switch (status) {
    case 3:
      params.append("status", "all");
      break;
    case 0:
      params.append("status", "active");
      break;
    case 1:
      params.append("status", "inactive");
      break;
    case 2:
      params.append("status", "suspended");
      break;
    default:
      break;
  }
  
	return defaultInstance({
		method: "GET",
		url: "/employee/all",
		params,
		signal,
	});
}

/**
 * @param {string} employeeId
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const getEmployee = (employeeId, signal) => {
  return defaultInstance({
    method: 'GET',
    url: `/employee/${employeeId}/emp_details/dashboard`,
    signal,
  });
};

/**
 * @param {string} employeeId
 * @param {Record} data
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const updateEmployee = (employeeId, data, signal) => {
  return defaultInstance({
    method: 'PUT',
    url: `/employee/${employeeId}/updateprofile`,
    data,
    signal,
  });
};

/**
 * @param {string} schoolId
 * @returns {Promise<Array<Record>>}
 */
export const getEmployeesBySchoolId = (schoolId) => {
  return defaultInstance({
    method: 'GET',
    url: `/employee/getBySchoolId?schoolId=${schoolId}`,
  });
};



