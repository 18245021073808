import React, { useState } from 'react'
import { Dialog, DialogContent, Button, Tooltip, DialogActions } from "@material-ui/core"
import QueueIcon from '@material-ui/icons/Queue';
import {generateID} from "../middleware/employeeList"
import IconButton from '@material-ui/core/IconButton';
import { useQueryClient } from '@tanstack/react-query';

function GenerateID(props) {
    const {email,employeelist,employeeid} = props
    const [open, setOpen] = useState(false);
    const tab = new URLSearchParams(window.location.search).get("tab");
    const queryClient = useQueryClient();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleGenerate = () => {
        generateID(email).then(() => {
            if (!isNaN(tab)) {
                queryClient.invalidateQueries({ queryKey: ["getEmployees", Number(tab)] });
            }
        })
        handleClose()
    }

    if(!employeeid)
        return (
            <div>
                <Tooltip title="Generate ID">
                    <IconButton style={{ color: "red" }} onClick={handleClickOpen}><QueueIcon size={20} /></IconButton>
                </Tooltip>

                <Dialog
                    onClose={handleClose}
                    fullWidth
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <DialogContent dividers>
                        <div style={{fontWeight: "500",fontSize: "medium"}}>
                        You are about to generate an ID for <span style={{fontWeight: "bolder"}}>{email}</span> ?
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            color="secondary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleGenerate}
                            variant="contained"
                            color="primary"
                        >
                            Generate
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    return(<></>)
}

export default GenerateID