import React from 'react';
import { Card } from 'react-bootstrap';
import Validations from '../../../../shared/validation';

// types



const Orders = (props) => {
    const {paidDetails} = props
    return (
        <Card>
            <Card.Body className="pb-1">
                <h5 className="card-title mt-0 mb-0 header-title text-center"><i className='bi bi-check-circle-fill text-success pe-2'></i>LAST 5 Paid INVOICES</h5>

                <div className="table-responsive mt-1">
                    <table className="table table-nowrap mb-0">
                        <thead>
                            <tr>
                                <th scope="col">Invoice Number</th>
                                <th scope="col" className='text-center'>Invoiced Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(paidDetails || [])?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <span className="badge badge-soft-success rounded-pill">{item.invoiceNumber}</span>
                                        </td>
                                        <td className='text-center'>{Validations.compactCurrencyFormatter(item.invoiceAmount)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </Card.Body>
        </Card>
    );
};

export default Orders;
