import React from 'react'
import ResourceManagementGroup from '../Services/EmployeeSkill/ResourceManagementGroupList'
import { ResourceManagementGroupContextProvider } from '../Services/EmployeeSkill/context'

export default function EmployeeSkillPage() {
  return (
    <ResourceManagementGroupContextProvider>
    <ResourceManagementGroup />
    </ResourceManagementGroupContextProvider>
  )
}
