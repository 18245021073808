import React, { useEffect, useContext, useState } from 'react'
import Presentation from "./Presentation"
import { Context } from "../component_state/context"
import { connect } from "react-redux"
import { uploadToStorage } from "../../../../../shared/fileUploader"

function Container(props) {
  const [state, handle] = useContext(Context);
  const [updatedState, setUpdatedState] = useState(state)
  const [uploadedSize, setuploadedSize] = useState(0)
  const { type, loggedInEmployee } = props
  const setState = (obj) => {
    handle({
      type: "SET_STATE",
      data: obj
    })
  }

  useEffect(() => {
    setUpdatedState(state)
  }, [state.selectedRange.join(", "), state.timesheetInfo.attachmentDetails])


  const handleDocumentSubmit = (e) => {
    if (e.target.files.length > 0) {
      const totalSize = Array.from(e.target.files).reduce(
        (acc, file) => acc + file.size,
        0
      );
      const newUploadedSize = uploadedSize + totalSize;
      if (newUploadedSize > 4000000) {
        alert("Total file size should be less than 4MB");
        return;
      }
      setuploadedSize(newUploadedSize);
    const { attachmentDetails } = state.timesheetInfo
    let urlData = []
    const files  = [...e.target.files]
    const requests =files.map(async (file) => {
      if (file && state.type !== "view") {
        const fileName = [loggedInEmployee, '_TS_', state.placementID, "_", new Date().toISOString()].join('')
        const filePath = `EMPLOYEES/${loggedInEmployee}/TIMESHEETS/${fileName}`
        setState({ isDocumentUploading: true })
        return await  uploadToStorage(file, filePath, fileName, 'file')
          .then(url => {
            urlData.push({
              publicURL: url,
              sourcePath: filePath + "." + file.name.split('.')[1]
            })
          })
          .catch(err => {
            setState({ isDocumentUploading: false })
            console.log(err)
          })
      }
     })
     return Promise.all(requests).then(() => {
       if (attachmentDetails.publicURL) {
         setState({
           timesheetInfo: {
             ...updatedState.timesheetInfo,
             attachmentDetails: [
               {
                 publicURL: attachmentDetails.publicURL,
                 sourcePath: attachmentDetails.sourcePath,
               },
               ...urlData,
             ],
           },
           isDocumentUploading: false,
         });
       } else {
         setState({
           timesheetInfo: {
             ...updatedState.timesheetInfo,
             attachmentDetails: [
               ...updatedState.timesheetInfo.attachmentDetails,
               ...urlData,
             ],
           },
           isDocumentUploading: false,
         });
       }
    });
    }
  }
  return (
    <div>
      <Presentation
        setState={setState}
        state={state}
        type={type}
        handleDocumentSubmit={handleDocumentSubmit}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loggedInEmployee: state.firebase.auth.uid,
  }
}


export default connect(mapStateToProps)(Container)
