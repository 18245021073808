import React, { useEffect, useState } from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import AppModalHeader from "../shared/components/Modals/AppModalHeader";

function useCustomDialog() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const renderContent = (props) => {
    const { dialogContent, title, buttonTitle, hideButton, maxWidth = "md",hideDefaultClose = true,disableEscapeKeyDown = false, btnSize = "small",dialogActions,disableBackdropClick  = false } = props;
    return (
      <div>
        <OverlayTrigger
          placement="top-end"
				  overlay={<Tooltip id="tooltip-top">{buttonTitle}</Tooltip>}
			  >
          <Button variant="primary" onClick={handleClickOpen} size={btnSize} hidden={hideButton}>
            <span>
              {buttonTitle}
            </span>
          </Button>
			  </OverlayTrigger>
        <Modal
          show={open}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onEscapeKeyDown={disableEscapeKeyDown}
          onBackdropClick={disableBackdropClick}
          backdrop="static"
        >
					<AppModalHeader title={title || ''} hideDefaultClose = {hideDefaultClose}/>
          <Modal.Body>
            {dialogContent}
          </Modal.Body>
          {
            dialogActions ?
              <Modal.Footer>
                {dialogActions}
              </Modal.Footer>
            :
              null
          }
        </Modal>
      </div>
    );
  };

  return {
    isDialogOpen: open,
    renderContent,
    closeDialog: handleClose,
    openDialog: handleClickOpen
  };

}

export default useCustomDialog;