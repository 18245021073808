import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Button,
  FormControlLabel,
  FormGroup,
  Tabs,
  Tab,
  Badge,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IOSSwitch from "../../../../../../../shared/iosSwitch";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MetaInfo from "../../../../../../../shared/getMetaInfo";
import NewProject from "../../../../../../TaskManagement/components/NewProject";
import TabPanel from "../../../../../../../shared/tabPanel";
import History from "../History";
function Presentation(props) {
  const {
    timesheetCycle,
    startDay,
    approvalBy,
    attachMandatory,
    enableTask,
    makeMandatory,
    linkToProject,
    projectsList,
    approvals,
    implementationDate,
    handleChange,
    handleChecked,
    handleDateChange,
    handleSubmit,
    fillableSections,
    handleKeyValuePair,
    history,
    handleUpdate,
    isCreation
  } = props;

  const metaInfo = new MetaInfo();


  const [selectedProject, setSelectedProject] = useState({});

  useEffect(() => {
    setSelectedProject(
      projectsList.filter((item) => item.id === linkToProject)[0]
    );
  }, [linkToProject, projectsList]);

  const handleTimeSheetAttachment = (event) => {
    if (!event.target.checked) {
      var r = window.confirm(
        "Are you sure?\nYou want to disable time sheet attachment"
      );
      if (r === true) {
        handleChecked(event);
      }
      return r;
    }
    handleChecked(event);
  };
  const [value, setValue] = useState(0);
  const handleTabChange = (_, value) => setValue(value);

  return (
    <div>
      <div className="text-right mb-4">
        <NewProject projectsList={projectsList} />
      </div>
      <form onSubmit={handleSubmit}>
        <Tabs value={value} onChange={handleTabChange} variant="scrollable">
          <Tab label="Info" />
          <Tab
            disabled={isCreation}
            label={
              <Badge color="primary" children="Cycles" max={99} badgeContent={history?.length || 0} />
            }
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Grid container spacing={1}>
            {
              isCreation ?
                <>
                  <Grid item xs={4}>
                    <TextField
                      select
                      label="Timesheet Cycle"
                      size="small"
                      value={timesheetCycle}
                      fullWidth
                      required
                      id="placements-timesheets-timesheetCycle"
                      onChange={handleChange}
                      name="timesheetCycle"
                    >
                      {tsCycle.map((option) => (
                        <MenuItem
                          key={option}
                          value={tsCycle.indexOf(option).toString()}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {parseInt(timesheetCycle) !== 3 && parseInt(timesheetCycle) !== 4 ? (
                    <Grid item xs={4}>
                      <TextField
                        select
                        label="Week start day"
                        size="small"
                        value={startDay}
                        fullWidth
                        required
                        id="placements-timesheets-startDay"
                        name="startDay"
                        onChange={handleChange}
                      >
                        {weekStartDays.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  ) : null}
                </> : null
            }

            <Grid item xs={4}>
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch />}
                  checked={attachMandatory}
                  onChange={handleTimeSheetAttachment}
                  name="attachMandatory"
                  label="Timesheet Attachment"
                  id="placements-timesheets-attachMandatory"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                options={approvals}
                onChange={(_, value) => {
                  if (value) {
                    handleKeyValuePair("approvalBy", value);
                  }
                }}
                value={approvalBy}
                autoHighlight
                getOptionLabel={(option) => metaInfo.emailToName(option)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Approval by *"
                    size="small"
                    fullWidth
                    id="placements-timesheets-approvalBy"
                  />
                )}
              />
            </Grid>
            {
              isCreation ?
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      required
                      fullWidth
                      id="placements-timesheets-implementationDate"
                      size="small"
                      label="Implementation date"
                      format="MM/dd/yyyy"
                      value={implementationDate ? implementationDate : null}
                      onChange={(date) =>
                        handleDateChange("implementationDate", date)
                      }
                      KeyboardButtonProps={{
                        "aria-lablel": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                :
                null
            }

            <Grid item xs={4}>
              <Autocomplete
                fullWidth
                options={projectsList}
                onChange={(_, value) => {
                  if (value) {
                    handleKeyValuePair("linkToProject", value.id);
                  }
                }}
                value={selectedProject}
                autoHighlight
                getOptionLabel={(option) => option.title}
                renderOption={(option) => (
                  <React.Fragment>{option.title}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Project *"
                    size="small"
                    fullWidth
                    id="placements-timesheets-project"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch />}
                  checked={enableTask}
                  name="enableTask"
                  onChange={handleChecked}
                  label="Enable Task"
                  id="placements-timesheets-enableTask"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={4}>
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch />}
                  checked={makeMandatory}
                  onChange={handleChecked}
                  name="makeMandatory"
                  id="placements-timesheets-makeMandatory"
                  label="Make Manditory"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <br />
          <Button
            type="submit"
            variant="contained"
            color={
              fillableSections.includes("timesheets") ? "primary" : "secondary"
            }
            disabled={
              !timesheetCycle ||
              ((timesheetCycle==="Monthly" || timesheetCycle === "Semi-Monthly") && !startDay) ||
              !approvalBy[0] ||
              !implementationDate ||
              !linkToProject
            }
          >
            {fillableSections.includes("timesheets") ? "Done" : "Update"}
          </Button>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <History
            history={history}
            handleTabChange={handleTabChange}
            handleUpdate={handleUpdate}
            employeeID={props.employeeID}
            placementID={props.id}
          />
        </TabPanel>
      </form>
    </div>
  );
}

export default Presentation;

export const tsCycle = ["Daily", "Weekly", "Biweekly", "Semi-Monthly", "Monthly"];

export const weekStartDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
