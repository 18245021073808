import { ACTION } from "./actionTypes";

export const getReviewDocumentRequest = () => ({
  type: ACTION.GET_REVIEW_DOCUMENT_REQUEST,
});

export const getReviewDocumentSuccess = (payload) => ({
  type: ACTION.GET_REVIEW_DOCUMENT_SUCCESS,
  payload,
});

export const getReviewDocumentFailure = (err) => ({
  type: ACTION.GET_REVIEW_DOCUMENT_FAILURE,
  err,
});

export const reviewWorkQueueDocumentRequest = () => ({
  type: ACTION.REVIEW_WORKQUEUE_DOCUMENT_REQUEST,
});

export const reviewWorkQueueDocumentSuccess = (payload) => ({
  type: ACTION.REVIEW_WORKQUEUE_DOCUMENT_SUCCESS,
  payload,
});

export const reviewWorkQueueDocumentFailure = (err) => ({
  type: ACTION.REVIEW_WORKQUEUE_DOCUMENT_FAILURE,
  err,
});

export const updateWorkQueueDocumentRequest = () => ({
  type: ACTION.UPDATE_WORKQUEUE_DOCUMENT_REQUEST,
});

export const updateWorkQueueDocumentSuccess = (payload) => ({
  type: ACTION.UPDATE_WORKQUEUE_DOCUMENT_SUCCESS,
  payload,
});

export const updateWorkQueueDocumentFailure = (err) => ({
  type: ACTION.UPDATE_WORKQUEUE_DOCUMENT_FAILURE,
  err,
});

export const setStateAction = (payload) => ({
  type: ACTION.SET_STATE,
  payload,
});
export function expiredlistRequest() {
  return {
    type: ACTION.EXPIREDLIST_REQUEST,
  };
}

export function expiredlistSuccess(payload) {
  return {
    type: ACTION.EXPIREDLIST_SUCCESS,
    payload,
  };
}

export function expiredlistError(err) {
  return {
    type: ACTION.EXPIREDLIST_ERROR,
    err,
  };
}

export function ReviewSuspendedRequest() {
  return {
    type: ACTION.SUSPENDED_LIST_REQUEST,
  };
}

export function ReviewSuspendedSuccess(payload) {
  return {
    type: ACTION.SUSPENDED_LIST_SUCCESS,
    payload,
  };
}

export function ReviewSuspendedFailure(err) {
  return {
    type: ACTION.SUSPENDED_LIST_ERROR,
    err,
  };
}

export const holdAccountRequest = () => {
  return {
    type: ACTION.HOLD_ACCOUNT_REQUEST,
  };
};
export const holdAccountSuccess = (payload) => {
  return {
    type: ACTION.HOLD_ACCOUNT_SUCCESS,
    payload,
  };
};
export const holdAccountFailure = (err) => {
  return {
    type: ACTION.HOLD_ACCOUNT_FAILURE,
    err,
  };
};
export const enableAccountRequest = () => {
  return {
    type: ACTION.ENABLE_ACCOUNT_REQUEST,
  };
};
export const enableAccountSuccess = (payload) => {
  return {
    type: ACTION.ENABLE_ACCOUNT_SUCCESS,
    payload,
  };
};
export const enableAccountFailure = (err) => {
  return {
    type: ACTION.ENABLE_ACCOUNT_FAILURE,
    err,
  };
};
export const onIgnoreDocumentRequest = () => {
  return {
    type: ACTION.ONIGNORE_DOCUMENT_REQUEST,
  };
};
export const onIgnoreDocumentSuccess = (payload) => {
  return {
    type: ACTION.ONIGNORE_DOCUMENT_SUCCESS,
    payload,
  };
};
export const onIgnoreDocumentFailure = (err) => {
  return {
    type: ACTION.ONIGNORE_DOCUMENT_FAILURE,
    err,
  };
};
