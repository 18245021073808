import React from 'react'
import ProfileBuilder from "../Services/Console/components/ProfileBuilder/Index"
function ProfileBuilderPage() {
  return (
    <div>
      <ProfileBuilder />
    </div>
  )
}

export default ProfileBuilderPage
