import React from "react";
import { Row, Col, Card, Badge } from "react-bootstrap";
import Chart from "react-apexcharts";
import Validations from '../../../../shared/validation';

const HLExpenses = (props) => {
  const { ExpensesRemainingData } = props;
  console.log("ExpensesRemainingData", ExpensesRemainingData);
  const iconMap = {
    Fuel: "fa-gas-pump",
    Parking: "fa-square-parking",
    Hotel: "fa-hotel",
    Travel: "fa-car",
    Meals: "fa-utensils",
    other: "fa-question-circle"
    };
    const colorMap = {
      Fuel: "#2C9273",
      Parking: "#FFE380",
      Hotel: "#6B778C",
      Travel: "#95D7AE",
      Meals: "#027D8D",
      other: "#FF0000" 
    };
    const highestExpenseType = ExpensesRemainingData?.highestExpense?.type;
    const leastExpenseType = ExpensesRemainingData?.leastExpense?.type;
    const highestExpenseColor = colorMap[highestExpenseType] || colorMap.other;
    const leastExpenseColor = colorMap[leastExpenseType] || colorMap.other;
    const highestExpenseIcon = {
      className: iconMap[highestExpenseType] || iconMap.other,
      color: highestExpenseColor
    };
    
    const leastExpenseIcon = {
      className: iconMap[leastExpenseType] || iconMap.other,
      color: leastExpenseColor
    };
  return(



<Col lg={6}>
<Card>
    <Card.Body>
    <h5 className="card-title mb-3 header-title">HIGHEST EXPENSE</h5>
    <div className="d-flex justify-content-between">
<div className="keyicon">
<i className={`fa-solid ${highestExpenseIcon.className}`} style={{color: highestExpenseIcon.color}}></i>
</div>
<h6 className="card-title mt-2 header-title">{highestExpenseType}</h6>
<div></div>
<div></div>
<div className="align-self-center flex-shrink-0">
    <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.527319 11.5907C0.734609 12.0049 1.231 12.3498 1.74833 12.3335L5.95164 12.2005L6.30291 23.3089C6.3238 23.9694 7.08455 24.4863 7.79588 24.4638L15.8792 24.2082C16.5905 24.1857 17.3171 23.6217 17.2962 22.9612L16.9449 11.8529L21.1482 11.72C21.6655 11.7036 22.1392 11.328 22.3199 10.9016C22.4996 10.4451 22.3855 9.90775 22.0194 9.58875L12.028 0.678571C11.7618 0.446569 11.4346 0.336703 11.079 0.34795C10.7233 0.359197 10.4038 0.489513 10.1527 0.737874L0.744209 10.2615C0.398988 10.603 0.319078 11.1465 0.527319 11.5907ZM11.1739 3.3502L18.0006 9.41531L15.414 9.49711C14.7026 9.5196 14.2956 9.95321 14.3165 10.6137L14.6716 21.8421L8.85159 22.0262L8.49652 10.7977C8.47563 10.1373 8.04201 9.73023 7.33068 9.75272L4.74403 9.83452L11.1739 3.3502Z" fill="#2C9273"/>
    </svg>
    <h4 className="mb-0 mt-2 align-self- fw-bold">{Validations.currencyFormatterUs(ExpensesRemainingData?.highestExpense?.amount)}</h4>
</div>
<div></div>
</div>
    </Card.Body>
</Card>
<Col lg={12}>
<Card>
    <Card.Body>
    <h5 className="card-title mb-3 header-title">LEAST EXPENSE</h5>
<div className="d-flex justify-content-between">
<div className="keyicon">
<i className={`fa-solid ${leastExpenseIcon.className}`} style={{color: leastExpenseIcon.color}}></i>
</div>

<h6 className="card-title mt-2 header-title">{leastExpenseType}</h6>

<div></div>
<div></div>
<div className="align-self-center flex-shrink-0">
    <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.9017 13.0964C21.7076 12.6758 21.2224 12.3154 20.7048 12.3154H16.4994V1.2015C16.4994 0.540676 15.7553 0 15.0437 0H6.95634C6.24465 0 5.50062 0.540676 5.50062 1.2015V12.3154H1.29521C0.777616 12.3154 0.292377 12.6758 0.0982806 13.0964C-0.0958153 13.5469 0.00123272 14.0876 0.357075 14.418L10.0619 23.6395C10.3207 23.8798 10.6442 24 11 24C11.3558 24 11.6793 23.8798 11.9381 23.6395L21.6429 14.418C21.9988 14.0876 22.0958 13.5469 21.9017 13.0964ZM11 20.9962L4.36839 14.7184H6.95634C7.66802 14.7184 8.08856 14.2979 8.08856 13.637V2.403H13.9114V13.637C13.9114 14.2979 14.332 14.7184 15.0437 14.7184H17.6316L11 20.9962Z" fill="#FF7452" fill-opacity="0.6"/>
    </svg>
    <div></div>

    <h4 className="mb-0 mt-2 align-self- fw-bold">{Validations.currencyFormatterUs(ExpensesRemainingData?.leastExpense?.amount)}</h4>
</div>
<div></div>
</div>
    </Card.Body>
</Card>
</Col>
</Col>
  )
}

export default HLExpenses;