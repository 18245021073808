export default {
  allEmployees: {
    data: [],
    isLoading: true,
    error: "",
    listener: () => { },
    noOfLoadings: 0,
  },
  activeEmployees: {
    data: [],
    isLoading: true,
    error: "",
    listener: () => { },
    noOfLoadings: 0,
  },
  inActiveEmployees: {
    data: [],
    isLoading: true,
    error: "",
    listener: () => { },
    noOfLoadings: 0,
  },
  suspendedEmployees: {
    data: [],
    isLoading: true,
    error: "",
    listener: () => { },
    noOfLoadings: 0,
  },
  selectedEmployees: {
    data: [],
    isLoading: true,
    error: "",
    listener: () => { },
    noOfLoadings: 0,
  },
  currViewingTab: "allEmployees",
  metaInfoofEmployees:{
    isLoading: true,
    data:{},
    error:""
  }
}
