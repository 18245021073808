import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    background: "linear-gradient(45deg, #078962 10%, #2C9273 90%)",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  layout: {
    padding: theme.spacing(5),
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

export default useStyles
