import React from 'react'
import ViewSchool from '../Services/Console/components/Schools/ViewSchool'

export default function ViewSchoolPage(props) {
  const { id } = props.match.params

  return (
    <ViewSchool schoolId={id} />
  )
}
