import React from "react"
// import { Spinner } from "reactstrap"
import RegistrationForm from "../RegistartionForm"
function Presentation(props) {
  const {
    // levelOneAuth,
    // found,
    // handleSubmit,
    // load,
    // handleUser,
    // email,
    // AuthOneSuccess,
    // htmlContent,
    // incorrectPass,
    // token,
    state,
  } = props
  let email = state["invitationTokenEmail"].data
 
  let valid = state["invitationTokenEmail"].error
  console.log(valid.message, email.length ? true : false, email)
  return (
    <div>
      {email.length ? (
        <RegistrationForm {...props} email={email} token={props.token} />
      ) : (
        <p className="text-center">
          {/* {load ? (
            <Spinner color="dark" />
          ) : ( */}
          <p className="text-center p-3 bg-danger mt-5 w-75 rounded shadow ml-auto mr-auto text-white">
            {valid.message}
          </p>
          {/* )} */}
        </p>
      )}
    </div>
  )
}

export default Presentation
