import React from "react";
import { useDialog } from "../../../../../hooks/useDialog";
import { Button, Modal } from "react-bootstrap";
import AppModalHeader from "../../../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../../../shared/components/Modals/AppModalFooter";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { clearEmployee } from "../../../../../api/services/default/payrolls/reports";

export default function AccountsClearModal({
	isAccountsClear,
	employeeId,
}) {
	const { open, handleOpen, handleClose } = useDialog();

	const queryClient = useQueryClient();

	const useClearEmployee = useMutation({
		mutationKey: "clearEmployee",
		mutationFn: ({ id, data }) => clearEmployee(id, data),
		onSuccess() {
			handleClose();
			queryClient.invalidateQueries(["getExitReport"]);
		},
	});

	const handleClearAccounts = (e) => {
		e.preventDefault();
		useClearEmployee.mutate({ id: employeeId });
	};

	return (
		<>
			<Button variant="danger" className="text-capitalize" onClick={handleOpen}>
				{JSON.stringify(isAccountsClear)}
			</Button>
			<Modal show={open} onHide={handleClose} centered>
				<form onSubmit={handleClearAccounts}>
					<AppModalHeader
						title={`${!isAccountsClear ? "Clear" : "Unclear"} Accounts`}
					/>
					<Modal.Body>
						<p className="text-muted">
							Are you sure you want to {!isAccountsClear ? "clear" : "unclear"}{" "}
							the accounts for this employee?
						</p>
					</Modal.Body>
					<AppModalFooter
						saveButtonText="Yes"
						handleClose={handleClose}
						cancelButtonText="No"
					/>
				</form>
			</Modal>
		</>
	);
}
