import React, { useState,useEffect } from "react";
import {
  Dialog,
  Typography,
  DialogContent,
  Button,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import styles from "../../../../styles/dialogStyles";
import { withStyles,makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { useNewInvoiceContext } from "./context";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function UpdatePaymentRates(props) {
  const classes = useStyles();
  const { ChangedPaymentRateOnDates,isPaymentRateChanged } = props;
  const [open, setOpen] = useState(isPaymentRateChanged);
  const { state,stateSetters,services } = useNewInvoiceContext();
  const timesheetsArr = Object.values(state.selectedTimesheets)
  const totalTimeInHrs = services.getTotalTimeInHours(timesheetsArr.map(sh => sh.OTtime))

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdatePaymentRates = () => {
    let newSelectedTimesheets = state.selectedTimesheets;
    ChangedPaymentRateOnDates.forEach(item => {
      newSelectedTimesheets = {
        ...newSelectedTimesheets,
        [item.date]: {
          ...newSelectedTimesheets[item.date],
          billingRate: item.newPaymentBillingRate,
          OTbillingRate: item.newPaymentOTBillingRate ? item.newPaymentOTBillingRate : state.selectedTimesheets[item.date].OTbillingRate,
        }
      }
    });
    stateSetters.setSelectedTimesheets(newSelectedTimesheets)
    handleClose();
  };

  useEffect(() => {
    setOpen(isPaymentRateChanged)
  }, [isPaymentRateChanged])

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
        disableEscapeKeyDown
        disableBackdropClick
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          You are about to update Payment Rates?
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer component={Paper} style={{overflow:"hidden"}}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Existing Billing Rate</TableCell>
                <TableCell align="center">Changed Billing Rate</TableCell>
                {
                  totalTimeInHrs ?
                    <>
                      <TableCell align="center">Existing OT Billing Rate</TableCell>
                      <TableCell align="center">Changed OT Billing Rate</TableCell>
                    </>
                  :
                    null
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {ChangedPaymentRateOnDates.map((item) => (
                <TableRow key={item.date}>
                  <TableCell component="th" scope="row" align="center">
                    {item.date}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {item.oldPaymentBillingRate}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {item.newPaymentBillingRate}
                  </TableCell>
                  {
                    totalTimeInHrs ?
                      <>
                        <TableCell component="th" scope="row" align="center">
                          {item.oldPaymentOTBillingRate}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {item.newPaymentOTBillingRate}
                        </TableCell>
                      </>
                    :
                      null
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleUpdatePaymentRates}
            variant="contained"
            color="primary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UpdatePaymentRates;
