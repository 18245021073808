import React from "react"
import { connect } from "react-redux"
import { isLoaded } from "react-redux-firebase"
import Presentation from "./Presentation"

function Container(props) {
	const { inviteeInfo = [] } = props
	let data =[]
	data = props.excel_invite.concat(inviteeInfo)
	console.log("objectData",data)
	return (
		<div>
			<Presentation {...props} data={data}/>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => {
	const { data } = state.firestore
	return {
		excel_invite: state.employee.employeeInvite.excel_invite,
		activeEmployees: Object.values(data.names),
		inviteeInfo: state.firestore.ordered.inviteeInfo,
	}
}

export default connect(mapStateToProps)(Container)
