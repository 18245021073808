import React from "react"
import {
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  Button,
  IconButton,
  Tabs,
  Tab,
  Badge,
} from "@material-ui/core"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  AttachFile as AttachFileIcon,
} from "@material-ui/icons"
import validate from "../../../../../../shared/validation"
import TabPanel from "../../../../../../shared/tabPanel"
import MaterialTable, { MTableToolbar } from "material-table"
import { GoFileSymlinkFile } from "react-icons/go"

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
}))

const determineDocStatus = (data = [], index = 0) => {
  const { effectiveUntilDate, documentType } = data[index]
  if (new Date(effectiveUntilDate) < new Date()) return "Inactive"

  const req_docs = data
    .filter((doc) => doc.documentType === documentType)
    .sort((a, b) => new Date(b.effectiveUntilDate) - new Date(a.effectiveUntilDate))
  if (req_docs[0].index !== index) return "Inactive"
  return "Active"
}

function Presentation(props) {
  const {
    documentType,
    status,
    isUploading,
    work_doc,
    effectiveDate,
    effectiveUntilDate,
    documents,
    handleChange,
    handleDateChange,
    handleSubmit,
    handleFile,
    handleUpdate,
    handleDelete,
    update,
    clearState,
  } = props
  const [value, setValue] = React.useState(0)
  const handleTab = (_, newValue) => {
    setValue(newValue)
  }
  const classes = useStyles()
  const columns = [
    { title: "Document type", field: "documentType" },
    { title: "Status", field: "status" },
    { title: "Effective Date", field: "effectiveDate" },
    { title: "Effective Until", field: "effectiveUntilDate" },
    {
      title: "Document",
      field: "work_doc",
      render: (row) => {
        const { name, url } = row.work_doc
        return (
          <a target="_blank" rel="noopener noreferrer" href={url}>
            {name.substr(0, name.lastIndexOf("_"))}
          </a>
        )
      },
    },
    {
      title: "Actions",
      field: "",
      render: (rowData) => {
        return (
          <div className="d-flex">
            <IconButton
              onClick={(e) => {
                handleUpdate(e, rowData)
                handleTab(null, 0)
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton onClick={(e) => handleDelete(e, rowData)}>
              <DeleteIcon />
            </IconButton>
          </div>
        )
      },
    },
  ]
  let data = []
  documents &&
    documents.forEach((doc) => {
      data.push({
        documentType: doc.documentType,
        status: determineDocStatus(documents, doc.index),
        work_doc: doc.work_doc,
        effectiveDate: validate.dateFormatter(doc.effectiveDate),
        effectiveUntilDate: validate.dateFormatter(doc.effectiveUntilDate),
        index: doc.index,
      })
    })
  const documentTypes = ["Incorporation", "PO", "MSA", "COI", "W9", "MSA &PO", "Others"]

  const statusList = ["Active", "Inactive"]
  return (
    <div>
      <Tabs value={value} onChange={handleTab} variant="scrollable" scrollButtons="auto">
        <Tab label="Form" />
        <Tab
          label={
            <Badge color="primary" children="Documents" max={99} badgeContent={documents.length} />
          }
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <div style={{ minWidth: "100%" }}>
                <TextField
                  select
                  label="Document Type"
                  size="small"
                  value={documentType}
                  fullWidth
                  required
                  name="documentType"
                  id="placements-documents-type"
                  onChange={handleChange}
                >
                  {documentTypes.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                label="Status"
                size="small"
                value={new Date(effectiveUntilDate) < new Date() ? "Inactive" : "Active"}
                fullWidth
                required
                name="status"
                id="placements-documents-status"
                onChange={handleChange}
              >
                {statusList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      required
                      size="small"
                      style={{ marginTop: "3px", marginRight: "10px" }}
                      id="placements-documents-effectiveDate"
                      label="Effective date"
                      format="MM/dd/yyyy"
                      value={effectiveDate ? effectiveDate : null}
                      onChange={(date) => handleDateChange("effectiveDate", date)}
                      name="effectiveDate"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      required
                      minDate={effectiveDate}
                      //maxDate={state.project.enddate}
                      id="placements-documents-effectiveUntilDate"
                      label="Effective until date"
                      format="MM/dd/yyyy"
                      value={effectiveUntilDate ? effectiveUntilDate : null}
                      onChange={(date) => handleDateChange("effectiveUntilDate", date)}
                      name="effectiveUntilDate"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      fullWidth
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            {documentType && validate.checkDateDiff(effectiveDate, effectiveUntilDate) ? (
              <Grid item xs={6}>
                <div style={{ marginTop: "6px" }}>
                  <input
                    className={classes.input}
                    id="placements-documents-file"
                    type="file"
                    onChange={handleFile}
                  />
                  <label htmlFor="placements-documents-file">
                    <Button variant="contained" color="primary" component="span">
                      <span>
                        <AttachFileIcon fontSize="small" color="inherit" /> Attachment
                      </span>
                    </Button>
                  </label>
                  <div className="d-flex justify-content-between">
                    <p>
                      {Object.keys(work_doc).length ? (
                        <a target="_blank" rel="noopener noreferrer" href={work_doc.url}>
                          <GoFileSymlinkFile size={22} />{" "}
                          {work_doc.name.substr(0, work_doc.name.lastIndexOf("_"))}
                        </a>
                      ) : (
                        <p>No file choosen</p>
                      )}
                    </p>
                  </div>
                </div>
                {isUploading ? <p>Uploading please wait...</p> : ""}
              </Grid>
            ) : null}
          </Grid>
          <br />
          {update ? (
            <span>
              <Button
                type="submit"
                disabled={
                  !validate.checkDateDiff(effectiveDate, effectiveUntilDate) ||
                  !documentType ||
                  !work_doc.url
                }
                color="secondary"
                variant="contained"
              >
                Update
              </Button>{" "}
              <Button onClick={clearState} color="inherit" variant="contained">
                Cancel
              </Button>
            </span>
          ) : (
            <Button
              type="submit"
              disabled={
                !validate.checkDateDiff(effectiveDate, effectiveUntilDate) ||
                !documentType ||
                !work_doc.url
              }
              color="primary"
              variant="contained"
            >
              Done
            </Button>
          )}
        </form>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MaterialTable
          title=""
          data={data}
          columns={columns}
          options={{
            search: false,
            showTitle: false,
          }}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <div
                  style={{
                    marginTop: "-58px",
                    marginLeft: "8px",
                    display: "flex",
                  }}
                ></div>
              </div>
            ),
          }}
        />
      </TabPanel>
    </div>
  )
}

export default Presentation
