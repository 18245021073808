import React, { useContext, useEffect } from "react"
import Presentation from "./Presentation"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import { compose } from "redux"
import { connect } from "react-redux"
import validation from "../../../../../shared/validation"
import { useHistory } from "react-router-dom"
import { loadAllProjects } from "../../../middleware/projectsList"
function Container(props) {
  const { modules } = props
  const tab = Number(validation.getQueryParameter("tab"))
  const [tabValue, setTabValue] = React.useState(tab)
  const { state, _load_all_projects, projectsCount } = props
  const history = useHistory();
  const handleTabChange = (event, newValue) => {
    const pathName = window.location.pathname
    history.push(`${pathName}?tab=${newValue}`)
    setTabValue(newValue);
};
  useEffect(() => {
    setTabValue(tab)
    _load_all_projects()
  }, [tab])

  return (
    <div>
      <Presentation
        {...props}
        modules={modules}
        tabValue={tabValue}
        handleTabChange={handleTabChange}
        projectsCount={
          projectsCount
            ? projectsCount
            : {
                Open: 0,
                Closed: 0,
                totalCount: 0,
                OverDue: 0,
              }
        }
      />
    </div>
  )
}

// const mapStateToProps = (state) => {
//     return {

//     }
// }
const mapStateToProps = (state) => {
  const data = state.firestore.data.projectsCount
    ? state.firestore.data.projectsCount.projects_tracker
    : state.firestore.data.projectsCount
  return {
    modules: state.employee.employeeModules.accessModules,
    loggedInEmployee: state.firebase.auth.uid,
    state: state.projects.projectsList,
    projectsCount: data,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    _load_all_projects: () => dispatch(loadAllProjects()),
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), firestoreConnect((props) => {
  if ((props.modules.includes("task-management") || props.modules.includes("console-customization")))
    return [
      {
        collection: "ID_TRACKER",
        doc: "task_management",
        storeAs: "projectsCount",
      },
    ]
  return []
}))(Container)