import React from "react"
import MaterialTable from "material-table"

export default function Presentation(props) {
  const columns = [
    { title: "Label", field: "label" },
    {
      title: "Color",
      field: "color",
      render: (rowData) => (
        <span
          className="p-2 rounded"
          style={{ backgroundColor: `${rowData.color}` }}
        >
          {rowData.color}
        </span>
      ),
    },
  ]

  let labels = props.project[0].labels
  let data = []
  labels &&
    Object.values(labels)
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      .forEach((e) => {
        if (e.isExist) {
          data.push({
            label: e.name,
            color: e.colorCode.includes("#")
              ? e.colorCode.length > 7
                ? e.colorCode.substr(0, 7)
                : e.colorCode
              : e.colorCode,
            id: e.id,
          })
        }
      })
  const { updateLabel, createNewLabel, deleteLabel } = props
  return (
    <div>
      <MaterialTable
        title="Labels"
        columns={columns}
        data={data}
        editable={{
          onRowAdd: (newData) => {
            return new Promise((resolve, reject) => {
              createNewLabel(newData, resolve, reject)
            })
          },

          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              updateLabel(newData, oldData, resolve, reject)
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              deleteLabel(oldData, resolve, reject)
            }),
        }}
      />
    </div>
  )
}
