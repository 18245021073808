import React from "react";
import profilePic from "../../../../../assets/images/users/avatar-1.jpg";
import { Button } from "react-bootstrap";

const Recentrequest = (props) => {
  const { recents } = props;
  return (
    <div className="card-border mb-24 mb-xl-0">
      <div className="card-border-title">Recent Letter Requests</div>
      <div className="card-lists pt-2">
        <div className="d-sm-flex justify-content-between mt-2">
          <div>
            <div className="d-flex">
              <div className="p-0">
                <img
                  src={profilePic}
                  className="avatar-sm rounded-circle"
                  alt=""
                />
              </div>
              <div className="ms-2">
                <h5 className="mb-1 mt-0 align-self-center fw-bold fs-15">
                  Teja Velpucharla
                </h5>
                <h6 className="text-muted fw-normal mt-0 mb-1">
                  Requested on 2/27/23
                </h6>
                <h6 className="mt-0 mb-1">
                  <span className="fw-400 text-muted">Description :</span>{" "}
                  <span className="fw-600">Test Mail</span>
                </h6>
                <h6 className="mt-0 mb-0">
                  <span className="fw-400 text-muted">Subject :</span>{" "}
                  <span className="fw-600">Experience Letter</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="badge rounded-pill badge-soft-warning align-self-end px-2 py-1 fs-12 mt-2 mt-sm-0">
            • Pending
          </div>
        </div>
      </div>
      <div className="card-lists pt-2">
        <div className="d-sm-flex justify-content-between mt-2">
          <div>
            <div className="d-flex">
              <div className="p-0">
                <img
                  src={profilePic}
                  className="avatar-sm rounded-circle"
                  alt=""
                />
              </div>
              <div className="ms-2">
                <h5 className="mb-1 mt-0 align-self-center fw-bold fs-15">
                  Teja Velpucharla
                </h5>
                <h6 className="text-muted fw-normal mt-0 mb-1">
                  Requested on 2/27/23
                </h6>
                <h6 className="mt-0 mb-1">
                  <span className="fw-400 text-muted">Description :</span>{" "}
                  <span className="fw-600">Test Mail</span>
                </h6>
                <h6 className="mt-0 mb-0">
                  <span className="fw-400 text-muted">Subject :</span>{" "}
                  <span className="fw-600">Experience Letter</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="badge rounded-pill badge-soft-success align-self-end px-2 py-1 fs-12 mt-2 mt-sm-0">
            • Approved
          </div>
        </div>
      </div>
      <div className="card-lists pt-2">
        <div className="d-sm-flex justify-content-between mt-2">
          <div>
            <div className="d-flex">
              <div className="p-0">
                <img
                  src={profilePic}
                  className="avatar-sm rounded-circle"
                  alt=""
                />
              </div>
              <div className="ms-2">
                <h5 className="mb-1 mt-0 align-self-center fw-bold fs-15">
                  Teja Velpucharla
                </h5>
                <h6 className="text-muted fw-normal mt-0 mb-1">
                  Requested on 2/27/23
                </h6>
                <h6 className="mt-0 mb-1">
                  <span className="fw-400 text-muted">Description :</span>{" "}
                  <span className="fw-600">Test Mail</span>
                </h6>
                <h6 className="mt-0 mb-0">
                  <span className="fw-400 text-muted">Subject :</span>{" "}
                  <span className="fw-600">Experience Letter</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="badge rounded-pill badge-soft-warning align-self-end px-2 py-1 fs-12 mt-2 mt-sm-0">
            • Pending
          </div>
        </div>
      </div>
      <div className="card-lists pt-2">
        <div className="d-sm-flex justify-content-between mt-2">
          <div>
            <div className="d-flex">
              <div className="p-0">
                <img
                  src={profilePic}
                  className="avatar-sm rounded-circle"
                  alt=""
                />
              </div>
              <div className="ms-2">
                <h5 className="mb-1 mt-0 align-self-center fw-bold fs-15">
                  Teja Velpucharla
                </h5>
                <h6 className="text-muted fw-normal mt-0 mb-1">
                  Requested on 2/27/23
                </h6>
                <h6 className="mt-0 mb-1">
                  <span className="fw-400 text-muted">Description :</span>{" "}
                  <span className="fw-600">Test Mail</span>
                </h6>
                <h6 className="mt-0 mb-0">
                  <span className="fw-400 text-muted">Subject :</span>{" "}
                  <span className="fw-600">Experience Letter</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="badge rounded-pill badge-soft-danger align-self-end px-2 py-1 fs-12 mt-2 mt-sm-0">
            • Rejected
          </div>
        </div>
      </div>
      <div className="card-lists smorebtn py-2">
        <div className="d-grid gap-2">
          <Button variant="success">See More</Button>
        </div>
      </div>
    </div>
  );
};

export default Recentrequest;
