import React from "react"
import { connect } from "react-redux"
import Login from "../Services/Authentication/components/Login"

function LoginPage(props) {
	const { auth } = props
	return (
		<div>
			<Login uid={auth.uid} />
		</div>
	)
}

const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.firebase.auth,
	}
}

export default connect(mapStateToProps)(LoginPage)
