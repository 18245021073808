import React from "react"
import { connect } from "react-redux"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import { compose } from "redux"
import CircularSpinner from "../../../../../../shared/circularSpinner"
import Presentation from "./Presentation"
import { useQuery } from "@tanstack/react-query"
import { getAllClients, getClientAccounts, getClientLocations } from "../../../../../../api/services/default/clients"

function Container(props) {
const {client} = props
  const { data: clientsAccountsData,isFetched :isLoading } = useQuery({
		queryKey: ["getClientAccounts",client.id],
		queryFn: () => getClientAccounts(client.id),
	});

  const { data: clientsLocationsData,isFetched :isLoadingLoactions } = useQuery({
		queryKey: ["getClientLocations",client.id],
		queryFn: () => getClientLocations(client.id),
	});
  const { data: clientsData,isFetched :isClientsDataLoading } = useQuery({
		queryKey: ["getAllClientsData"],
		queryFn: () => getAllClients(),

	});
  if (isLoading && isLoadingLoactions && isClientsDataLoading){
    let clientId = client.id
    const clientData  = clientsData.length && (Object.values(clientsData)).find((client) => client.clientId === clientId)
    return (
      <div>
        <Presentation {...props} clientData={clientData} accounts={Object.values(clientsAccountsData)} clients_locations={Object.values(clientsLocationsData)}/>
      </div>
    )
  }
  return (
    <CircularSpinner />
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    placements_list: state.firestore.ordered.placements_list,
    names: state.employee.employeeList.metaInfoofEmployees.data,
    placements_settings_list: state.firestore.ordered.placements_settings_list,

  }
}

export default compose(
  connect(mapStateToProps)
)(Container)
