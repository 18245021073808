import React from "react";
import * as yup from "yup";
import { RegexExp } from "../../../../shared/regex";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
	createDso,
	updateDso,
} from "../../../../api/services/flair-shared/schools";
import { Col, Modal, Row } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import AppModalHeader from "../../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../../shared/components/Modals/AppModalFooter";
import TextFormField from "../../../../shared/components/BootStrapFormFields/TextFormField";

const schema = yup.object().shape({
	name: yup.string().required("DSO name is required"),
	email: yup
		.string()
		.required("DSO email is required")
		.email("Please enter a valid email address"),
	phone: yup
		.string()
		// .required("DSO phone number is required")
		.matches(RegexExp.phonenumber, "Please enter a valid phone number"),
});

export default function AddDsoModal({
	open,
	handleClose,
	schoolId,
	size = "xl",
	isEdit = false,
	dso = {},
	onCreate = () => { },
}) {
	const initialValues = {
		name: "",
		phone: "",
		email: "",
		...dso,
	};

	const queryClient = useQueryClient();

	const useAddNewDso = useMutation({
		mutationFn: (values) => createDso(values),
		onSuccess: () => {
			queryClient.invalidateQueries(["getSchool", schoolId]);
		},
	});

	const useUpdateDso = useMutation({
		mutationFn: ({ id, data }) => updateDso(id, data),
		onSuccess: () => {
			queryClient.invalidateQueries(["getDso", dso.id]);
		},
	});

  const handleSubmit = async (values, actions) => {
		actions.setSubmitting(true);
		if (isEdit) {
			await useUpdateDso.mutateAsync({ id: dso.id, data: values });
		} else {
			await useAddNewDso.mutateAsync({
        ...values,
        schoolId: schoolId
      });
		}
		actions.setSubmitting(false);
		handleClose();
		onCreate();
	};

	return (
		<>
			<Modal show={open} onHide={handleClose} size={size} centered>
				<Formik
					enableReinitialize
					validationSchema={schema}
					initialValues={initialValues}
					onSubmit={handleSubmit}
				>
					{({ isSubmitting, isValid, dirty }) => {
						return (
							<Form>
								<AppModalHeader title={isEdit ? "Edit Dso" : "Add New Dso"} />
								<Modal.Body>
									<Row>
										<Col xl={4}>
											<Field
												name="name"
												label="DSO Name"
												component={TextFormField}
												required
											/>
										</Col>
										<Col xl={4}>
											<Field
												type="email"
												name="email"
												label="DSO Email"
												component={TextFormField}
												required
											/>
										</Col>
										<Col xl={4}>
											<Field
												name="phone"
												label="DSO Phone"
												component={TextFormField}
												required
											/>
										</Col>
									</Row>
								</Modal.Body>
								<AppModalFooter
									saveButtonDisabled={!isValid || !dirty || isSubmitting}
									saveButtonText={isEdit ? "Update" : "Add Dso"}
								/>
							</Form>
						);
					}}
				</Formik>
			</Modal>
		</>
	);
}
