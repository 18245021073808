import React, { useEffect } from "react";
import { connect } from "react-redux";
import { addMember } from "../../../middleware";
import Presentation from "./Presentation";

function Container(props) {
  const [state, setState] = React.useState({
    employees: [],
    isAdded: false,
  });
  const [projectAssignee, setAssignee] = React.useState([]);

  useEffect(() => {
    if (props.viewingProject.Users)
      setAssignee(Object.values(props.viewingProject.Users));
  }, [JSON.stringify(props.viewingProject)]);
  const handleEmployees = (value) => {
    setState({
      ...state,
      employees: value,
    });
  };
  const onAddMembers = () => {
    const employees = state.employees.map((e) => e.uid);
    const { addMember } = props;
    let data = {
      employees,
      projectId: props.projectId,
    };
    addMember(data);
    setState({
      ...state,
      employees: [],
      isAdded: !state.isAdded,
    });
    // $("#add-employee-into-project").attr('value',[])
    // dispatch({
    //     type : ADD_MEMBER,
    //     payload : {
    //         data :{
    //             employees : employees,
    //             projectId : state.project.id
    //         },
    //         dispatch : dispatch,
    //         isAddedConfirmed : this.isAddedConfirmed
    //     }
    // })
  };
  const projectMembers = projectAssignee.filter(
    (id) => id.uid !== props.auth && id.uid !== undefined && id.uid !== null
  );
  console.log("projectMembers",projectMembers)
  return (
    <div>
      {
        <Presentation
          {...props}
          projectMembers={projectMembers}
          isAdded={state.isAdded}
          employees={state.employees}
          handleEmployees={handleEmployees}
          onAddMembers={onAddMembers}
          projectAssignee={projectAssignee}
        />
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    viewingProject: state.projects.project.projectData.data,
    auth: state.firebase.auth.uid,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addMember: (payload) => {
      dispatch(addMember(payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Container);
