import React from "react"
import PlacementView from "../Services/Placements/components/NewPlacement/Index"

function PlacementViewPage(props) {
  return (
    <div>
      <PlacementView {...props.match.params} isEdit={false} docId={null} />
    </div>
  )
}

export default PlacementViewPage
