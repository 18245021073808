import {
  addDays,
  differenceInCalendarDays,
  getDate,
  getDay,
  getDaysInMonth,
  getMonth,
} from "date-fns";
import eachDayOfInterval from "date-fns/eachDayOfInterval";

const getIndexByDay = (day) => {
  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const index = weekDays.indexOf(day);
  return index;
};

const getNextDate = (currCycle) => {
  if (
    currCycle.startDay &&
    getIndexByDay(currCycle.startDay) !== getDay(currCycle.date)
  ) {
    const startDay = getIndexByDay(currCycle.startDay);
    const currDay = getDay(currCycle.date);
    const diff =
      startDay > currDay ? startDay - currDay : currDay - startDay - 1;
    return addDays(currCycle.date, diff - 1);
  }
  if (currCycle.range === 0) {
    return new Date(currCycle.date);
  } else if (currCycle.range === 1) {
    return addDays(new Date(currCycle.date), 6);
  } else if (currCycle.range === 2) {
    return addDays(new Date(currCycle.date), 13);
  } else if (currCycle.range === 3) {
    const day = getDate(currCycle.date);
    if (day >= 1 && day <= 15) {
      return addDays(new Date(currCycle.date), 15 - day);
    } else {
      const daysInMonth = getDaysInMonth(currCycle.date);
      return addDays(new Date(currCycle.date), daysInMonth - day);
    }
  } else if (currCycle.range === 4) {
    const day = getDate(currCycle.date);
    const daysInMonth = getDaysInMonth(currCycle.date);
    return addDays(new Date(currCycle.date), daysInMonth - day);
  }

  return currCycle.date;
};

const formDateRangesBetweenTwoCycles = (currCycle, nextCycle) => {
  /* 
    {
      date: Date,
      range: 0 | 1 | 2 | 3 | 4
      startDay: Sunday | Monday | Tuesday | Wednesday | Thursday | Friday | Saturday
    }
  */
  const condition = differenceInCalendarDays(
    new Date(nextCycle.date),
    new Date(currCycle.date)
  );
  if (condition <= 0) return [];
  else {
    const nextDate = getNextDate(currCycle);
    const nextCondition = differenceInCalendarDays(
      new Date(nextCycle.date),
      nextDate
    );
    let formedRange;
    if (nextCondition <= 0) {
      const diffInNextCycleDateAndCurrentRangeDate = differenceInCalendarDays(
        new Date(nextCycle.date),
        currCycle.date
      );
      formedRange = {
        startDate: currCycle.date,
        endDate: addDays(
          currCycle.date,
          diffInNextCycleDateAndCurrentRangeDate - 1
        ),
      };
    } else {
      formedRange = {
        startDate: new Date(currCycle.date),
        endDate: nextDate,
      };
    }

    currCycle = {
      date: addDays(nextDate, 1),
      range: currCycle.range,
      startDay: undefined,
    };
    let formedRanges = formDateRangesBetweenTwoCycles(currCycle, nextCycle);
    formedRanges = [...formedRanges, formedRange];
    return formedRanges;
  }
};

export const generateTimesheetSkeleton = (cycles, placementEndDate) => {
  // here cycles is an array of object, each object is in the following structure
  /* 
    {
      date: Date,
      range: 0 | 1 | 2 | 3 | 4
      startDay: Sunday | Monday | Tuesday | Wednesday | Thursday | Friday | Saturday
    }

    range = 0 i.e Daily
    range = 1 i.e Weekly
    range = 2 i.e Bi-Weekly
    range = 3 i.e Semi-Monthly
    range = 4 i.e Monthly


  */
  let dateRanges = [],
    i = 0;
  for (i = 0; i < cycles.length - 1; i++) {
    const ranges = formDateRangesBetweenTwoCycles(cycles[i], cycles[i + 1]);
    dateRanges = [...dateRanges, ...ranges];
  }
  const finalCycle = {
    date: placementEndDate,
    range: cycles[i].range,
    startDay: cycles[i].startDay,
  };
  const finalFormingRanges = formDateRangesBetweenTwoCycles(
    cycles[i],
    finalCycle
  );
  dateRanges = [...dateRanges, ...finalFormingRanges];

  return dateRanges.sort((a, b) =>
    differenceInCalendarDays(a.startDate, b.startDate)
  );
};

export const checkIfDateLiesBetweenTwoDates = (date, leftDate, rightDate) => {
  const condition1 = differenceInCalendarDays(leftDate, date);
  const condition2 = differenceInCalendarDays(rightDate, date);
  return condition1 <= 0 && condition2 >= 0;
};

export const getDateRangeFromRanges = (selectedDate, ranges) => {
  for (const i in ranges) {
    const dateRange = ranges[i];
    if (
      checkIfDateLiesBetweenTwoDates(
        selectedDate,
        new Date(dateRange.startDate),
        new Date(dateRange.endDate)
      )
    ) {
      return dateRange;
    }
  }
  alert("Date range not found for the selected date");
  return {
    startDate: undefined,
    endDate: undefined,
  };
};

export const getAllDatesFromSubmission = (submissions) => {
  return submissions.reduce((init, string) => {
    const [id, startDate, endDate] = string.split("_");
    const start = new Date(startDate);
    const end = new Date(endDate);
    init = [
      ...init,
      ...eachDayOfInterval({
        start: start,
        end: end,
      }),
    ];
    return init;
  }, []);
};

export const checkIfTimesheetContainsConnectingMonths = (
  standardTime,
  OTtime
) => {
  let timesheet1 = { standardTime: [], OTtime: [] },
    timesheet2 = { standardTime: [], OTtime: [] },
    initialMonth = getMonth(new Date(standardTime[0].date));

  standardTime.forEach((time, index) => {
    const month = getMonth(new Date(time.date));
    if (month === initialMonth) {
      timesheet1.standardTime.push(time);
      timesheet1.OTtime.push(OTtime[index]);
    } else {
      timesheet2.standardTime.push(time);
      timesheet2.OTtime.push(OTtime[index]);
    }
  });

  return {
    isConnects: timesheet2.standardTime.length > 0,
    timesheet1: {
      workdetails: timesheet1,
      startDate: timesheet1.standardTime[0].date,
      endDate: timesheet1.standardTime[timesheet1.standardTime.length - 1].date,
    },
    timesheet2: {
      workdetails: timesheet2,
      startDate: timesheet2.standardTime.length > 0 ? timesheet2.standardTime[0].date : null,
      endDate: timesheet2.standardTime.length > 0 ? timesheet2.standardTime[timesheet2.standardTime.length - 1].date : null,
    },
  };
};

export const formWorkingHours = (selectedRange) => {
  const newArr = [];
  const OTArr = [];
  selectedRange.forEach(date => {
    OTArr.push({
      date: date,
      value: "00:00"
    });
    const day = getDay(new Date(date))
    newArr.push({
      date: date,
      value: day === 0 || day === 6 ? "00:00" : "08:00"
    });
  })

  return {
    standardTime: newArr,
    OTtime: OTArr
  }
}