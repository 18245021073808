import React, { useState } from "react"
import AllTemplates from "../AllTemplates"
import CreateTemplate from "../CreateTemplate"
import TabPanel from "../../../../../shared/tabPanel"
import { Tabs, Tab } from "@material-ui/core"
import useStyles from "../../../styles/letterTemplateTabsStyle"

export default function Presentation(props) {
  const { state } = props
  const [value, setValue] = useState(0)
  const handleChange = (_, v) => {
    setValue(v)
  }
  const classes = useStyles()
  const isUpdating = state.updating
  return (
    <div className={classes.root}>
      {isUpdating ? null : (
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          className={classes.tabs}
        >
          <Tab id="template_panel_0" label="Templates" />
          <Tab id="template_panel_1" label="Create Template" />
        </Tabs>
      )}
      <TabPanel value={value} index={0}>
        <AllTemplates />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CreateTemplate />
      </TabPanel>
    </div>
  )
}
