import { makeStyles } from "@material-ui/core/styles"
export const useStyles = makeStyles({
  table: {
    width: "100%",
    backgroundColor: "#fafafa",
    overflowX: "inherit",
  },
})
export const customStyles = {
  employeeExist: {
    color: "#4183c4",
    textTransform: "capitalize",
  },
  employeeNonExist: {
    color: "#626466",
    textTransform: "capitalize",
  },
}
