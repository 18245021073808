import React from "react"
import Template from "../Services/EmployeeManagment/components/InvitationTemplate/PostForm"
function EmployeeRegisterPage(props) {
  console.log(props)
  return (
    <div>
      <Template
        {...props}
        // email={props.match.params.email}
        token={props.match.params.token}
      />
    </div>
  )
}

export default EmployeeRegisterPage
