import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from "@date-io/date-fns";
import { FiEdit3 } from "react-icons/fi";
import Fab from "@material-ui/core/Fab";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { DataContext } from "../../../../../contexts/data"
import validate from "../../../../../../shared/validation";
import { Tooltip } from "@material-ui/core";
import SunEditor from "suneditor-react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import useStyles from "../../../../Styles/EdittaskStyles";
import MetaInfo from "../../../../../../shared/getMetaInfo";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Presentation(props) {
  const classes = useStyles();
  // const [state] = useContext(DataContext)
  const {
    handleChange,
    handleUpdate,
    handleDateChange,
    handleDropdown,
    // projectAssignee,
    // errCount,
    // taskId,
    // reminderDate,
    // setReminder,
    // status,
    labels,
    names,
    state,
    project,
    handleDescription
  } = props;
  let task = state.task;
  // task = state.taskList.filter((item) => item.id === taskId)[0]
  const [open, setOpen] = React.useState(false);
  let [selectedStartDate, setSelectedStartDate] = React.useState(
    new Date(task.startdate).toISOString()
  );
  let [selectedEndDate, setSelectedEndDate] = React.useState(
    new Date(task.enddate).toISOString()
  );
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    handleDateChange("startdate", date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    handleDateChange("enddate", date);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkValidTaskRange = (startdate, enddate) => {
    const initialDateDiff = new Date(startdate) - new Date(project.startdate);
    const finalDateDiff = new Date(project.enddate) - new Date(enddate);
    return initialDateDiff >= 0 && finalDateDiff >= 0;
  };

  let projectLabels = Object.values(project.labels).filter((e) => e.isExist);
  //let taskLabels = []
  let projectAssignee = Object.values(project.Users)
    .filter((e) => names[e.uid].status === "active")
    .map((user) => user.uid);
  const metaInfo = new MetaInfo();
  if (Object.keys(task).length)
    return (
      <div>
        <Tooltip title="Edit task">
          <Fab
            variant="contained"
            className="flairbtn"
            onClick={handleClickOpen}
          >
            <FiEdit3 size={22} />
          </Fab>
        </Tooltip>
        <Dialog
          fullWidth
          maxWidth="md"
          disableEscapeKeyDown
          disableBackdropClick
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <div className="d-flex justify-content-between p-2">
              <div>
                <IconButton
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div>
                <Typography component="h1" variant="h4" align="center">
                  Edit task
                </Typography>
              </div>
              <div></div>
            </div>
          </AppBar>
          <div className={classes.layout}>
            <Paper className={classes.paper}>
              <form onSubmit={(e) => handleUpdate(e, handleClose)}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      required="required"
                      id="tm-edit-task-title"
                      name="title"
                      label="Name"
                      defaultValue={state.title}
                      onChange={(e) => handleChange("title", e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="grouped-select">
                        Task type
                      </InputLabel>
                      <Select
                        defaultValue={state.type}
                        onChange={(e, v) =>
                          handleDropdown("type", v.props.value)
                        }
                        input={<Input id="tm-edit-task-select" />}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="Task">Task</MenuItem>
                        <MenuItem value="Bug">Bug</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="grouped-select">Status</InputLabel>
                      <Select
                        value={state.status}
                        onChange={(e, v) =>
                          handleDropdown("status", v.props.value)
                        }
                        input={<Input id="tm-edit-task-select" />}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="Open">Open</MenuItem>
                        <MenuItem value="InProgress">InProgress</MenuItem>
                        <MenuItem value="Review">Review</MenuItem>
                        <MenuItem value="Closed">Closed</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <KeyboardDatePicker
                            required="required"
                            style={{ marginTop: "-2px" }}
                            fullWidth
                            id="tm-edit-task-startdate"
                            label="Start date"
                            format="MM/dd/yyyy"
                            minDate={project.startdate}
                            maxDate={project.enddate}
                            value={selectedStartDate ? selectedStartDate : null}
                            onChange={handleStartDateChange}
                            name="startdate"
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <KeyboardDatePicker
                            style={{ marginTop: "-2px" }}
                            fullWidth
                            id="tm-edit-task-enddate"
                            label="End date"
                            format="MM/dd/yyyy"
                            maxDate={project.enddate}
                            minDate={selectedStartDate}
                            value={selectedEndDate ? selectedEndDate : null}
                            onChange={handleEndDateChange}
                            name="enddate"
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </Grid>
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="grouped-select">Priority</InputLabel>
                      <Select
                        defaultValue={state.priority}
                        onChange={(e, v) =>
                          handleDropdown("priority", v.props.value)
                        }
                        input={<Input id="tm-edit-task-select" />}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="Urgent">Urgent</MenuItem>
                        <MenuItem value="High">High</MenuItem>
                        <MenuItem value="Medium">Medium</MenuItem>
                        <MenuItem value="Low">Low</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      multiple
                      // defaultValue={state.assignee.map((e) =>
                      //   metaInfo.emailToName(e)
                      // )}
                      required="required"
                      id="tm-edit-task-add-employees"
                      options={projectAssignee}
                      getOptionLabel={(option) => metaInfo.emailToName(option)}
                      value={state.assignee}
                      filterSelectedOptions
                      onChange={(e, v) => handleDropdown("assignee", v)}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          label="Add employees *"
                        />
                      )}
                    />
                  </Grid>
                  {project.useLabels ? (
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        required="required"
                        id="tm-edit-task-labels"
                        options={projectLabels}
                        defaultValue={state.labels}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span
                              className={classes.color}
                              style={{ backgroundColor: option.colorCode }}
                            />
                            <div className={classes.text}>{option.name}</div>
                          </React.Fragment>
                        )}
                        filterSelectedOptions
                        onChange={(e, v) => handleDropdown("labels", v)}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} label="Labels" />
                        )}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.setReminder}
                          id="tm-edit-task-set-reminder"
                          onChange={(e) =>
                            handleChange("setReminder", e.target.checked)
                          }
                        />
                      }
                      label="Set reminder"
                    />
                  </Grid>
                  {state.setReminder ? (
                    <Grid item xs={12} sm={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          required
                          style={{ marginTop: "-2px" }}
                          fullWidth
                          id="tm-edit-task-reminder-date"
                          label="Reminder date"
                          format="MM/dd/yyyy"
                          minDate={new Date().setDate(new Date().getDate() + 1)}
                          maxDate={project.enddate}
                          helperText={
                            isNaN(Date.parse(state.reminderDate))
                              ? "Select reminder date"
                              : ""
                          }
                          value={state.reminderDate ? state.reminderDate : null}
                          onChange={(date) =>
                            handleChange("reminderDate", date)
                          }
                          name="reminderDate"
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <SunEditor
                      placeholder="Description"
                      setContents={state.description}
                      onChange={(data) =>
                        handleDescription("description", data)
                      }
                      setOptions={{
                        buttonList: [
                          [
                            "undo",
                            "redo",
                            "formatBlock",
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                            "fontColor",
                            "hiliteColor",
                            "removeFormat",
                            "outdent",
                            "indent",
                            "align",
                            "horizontalRule",
                            "list",
                            "lineHeight",
                            "table",
                            "link"
                          ]
                        ],
                        mode: "Balloon-always"
                      }}
                    />
                  </Grid>
                </Grid>
                <br />
                <div className="text-center">
                  {/* {
                    // errCount === 0 &&
                    validate.checkDateDiff(
                      selectedStartDate,
                      selectedEndDate
                    ) &&
                    checkValidTaskRange(selectedStartDate, selectedEndDate) ? (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleClose}
                      >
                        Update
                      </Button>
                    ) :*/}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={
                      state.setReminder
                        ? state.title &&
                          state.type &&
                          state.status &&
                          state.priority &&
                          validate.checkDateDiff(
                            selectedStartDate,
                            selectedEndDate
                          ) &&
                          checkValidTaskRange(
                            selectedStartDate,
                            selectedEndDate
                          ) &&
                          state.reminderDate &&
                          state.assignee.length
                          ? false
                          : true
                        : state.title &&
                          state.type &&
                          state.status &&
                          state.priority &&
                          validate.checkDateDiff(
                            selectedStartDate,
                            selectedEndDate
                          ) &&
                          checkValidTaskRange(
                            selectedStartDate,
                            selectedEndDate
                          ) &&
                          state.assignee.length
                        ? false
                        : true
                    }
                  >
                    Update
                  </Button>
                </div>
              </form>
            </Paper>
          </div>
        </Dialog>
      </div>
    );
  return <div></div>;
}
