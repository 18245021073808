import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress, Grid, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import SelectionCriteria from "./SelectionCriteria";
import InvoiceAddress from "./InvoiceAddress";
import DatesSelection from "./DatesSelection";
import Discount from "./Discount";
import MoreInformation from "./MoreInformation";
import GenerateButton from "./GenerateButton";
import UpdateButton from "./UpdateButton"
import ExpenseTable from "./ExpenseTable";
import SheetsSelector from "./SheetsSelectors/index"
import Wrapper from "../../../../shared/wrapper";
import { NewInvoiceContextProvider, useNewInvoiceContext } from "./context";
import Notifiers from "./Notifiers";
import StandardTimeTable from "./StandardTimeTable";
import OTtimeTable from "./OTtimeTable";
import { addDays, differenceInCalendarDays, isWithinInterval } from "date-fns";
import validation from "../../../../shared/validation"
import { useParams } from "react-router-dom";
import { CalendarFormatter } from "../../../../shared/CalendarFormatter";
import UpdateNotifiers from "./UpdateNotifiers";
import AddAdditionalItems from "./AddAdditionalItems";
import AdditionalItemsTable from "./AdditionalItemsTable";
import UpdatePaymentRates from "./UpdatePaymentRates";


function NewInvoiceComponent(props) {
  const { state, services, stateSetters } = useNewInvoiceContext();
  const { type } = useParams();
  const [isNotifiersChanged, setIsNotifiersChanged] = useState(false)
  const [isPaymentRateChanged, setIsPaymentRateChanged] = useState(false)
  const [ChangedPaymentRateOnDates, setChangedPaymentRateOnDates] = useState([])
  const timesheetsArr = Object.values(state.selectedTimesheets)
  const totalOTTimeInHrs = services.getTotalTimeInHours(timesheetsArr.map(sh => sh.OTtime))

  // load selection data here
  useEffect(() => {
    if (type === "edit") {
      const invoiceID = decodeURIComponent(
        validation.getQueryParameter("invoiceID")
      );
      stateSetters.setIsInvoiceEdit(true)
      services.getInvoiceDetails(invoiceID, "edit")
      stateSetters.setInvoiceID(invoiceID)
    } else {
      services.getCompanyDetails()
      services.getActiveEmployees()
    }
  }, [])

  useEffect(() => {
    if ((state.invoiceBy === "client" || state.invoiceBy === "external") && type !== "edit") {
      services.getClients()
    }
    if ((state.invoiceBy === "employee" || state.invoiceBy === "client") && type !== "edit") {
      stateSetters.setInvoiceName("Invoice to Vendor")
    }
  }, [state.invoiceBy])

  useEffect(() => {
    if (state.invoiceBy === "external" && state.clientID && state.toClient && type !== "edit") {
      services.getClientContacts(state.clientID)
      const selectedClient = services.getClientById(state.clientID)
        services.getClientInvoiceLocationById(state.clientID, selectedClient?.invoiceLocationId)
    }
  }, [state.invoiceBy, state.clientID, state.toClient])

  useEffect(() => {
    if (state.invoiceBy === "client")
      services.getPlacementsOfTheClient(state.clientID)
      const selectedClient = services.getClientById(state.clientID)
        services.getClientInvoiceLocationById(state.clientID, selectedClient?.invoiceLocationId)
  }, [state.clientID])

  useEffect(() => {
    if (state.invoiceBy === "employee" || (state.invoiceBy === "external" && !state.toClient))
      services.getPlacementsOfTheEmployee(state.employeeID)
  }, [state.employeeID])

  // for getting single client
  useEffect(() => {
    if (state.invoiceBy === "employee" && state.clientID && type !== "edit") {
      services.getClients(state.clientID)
    }
  }, [state.clientID])

 
  useEffect(() => {
    if (type !== "edit") {
      const selectedClient = services.getClientById(state.clientID)
      stateSetters.setClientDetails({
        name: selectedClient?.businessDisplayName,
        address: [
          state?.invoiceLocation?.locationsline1,
          state?.invoiceLocation?.locationsline2,
          state?.invoiceLocation?.locationscity,
          state?.invoiceLocation?.locationsstate_name,
          state?.invoiceLocation?.locationszip,
        ]
          .filter((item) => item !== "")
          .join(", ")
      })
    }
  }, [state.clientID, state.clients, state.invoiceLocation])

  useEffect(() => {
    if (state.employeeID && state.placementID && type !== "edit")
      services.getInvoicePreRequisiteData()
  }, [state.employeeID, state.placementID])

  // calculate sub total
  useEffect(() => {
    services.calculateSubTotal()
  }, [JSON.stringify(state.selectedTimesheets), JSON.stringify(state.selectedExpenses), state.externalAmount])

  // calculate grand total
  useEffect(() => {
    services.calculateGrandTotal()
  }, [state.subTotal, JSON.stringify(state.discount), JSON.stringify(state.additionalItems)])


  useEffect(() => {
    if ((state.invoiceSettings?.POnumber ? state.poNumber !== "" : true) &&
      (Number(state.netTerms) > 0) &&
      (state.notifiers.to?.length > 0) && (
        state.invoiceBy !== "external" ? true :
          (state.clientDetails?.name?.length > 0) && (state.clientDetails?.address?.length > 0)))
      stateSetters.setIsDisableButton(false)
    else stateSetters.setIsDisableButton(true)
  }, [Number(state.grandTotal), state.poNumber, Number(state.netTerms), Number(state.discount), state.notifiers?.to?.length, state.clientDetails?.name?.length, state.clientDetails?.address?.length])

  useEffect(() => {
    if (type === "edit" && state.placementNotifiers.to.length && !state.isPaymentDone && !state.isVoid) {
      const isEqual = (a, b) => (JSON.stringify(a.sort()) === JSON.stringify(b.sort()))
      if (!isEqual(state.notifiers.to, state.placementNotifiers.to) || !isEqual(state.notifiers.cc, state.placementNotifiers.cc)) {
        setIsNotifiersChanged(true)
      } else {
        setIsNotifiersChanged(false)
      }
    }
  }, [JSON.stringify(state.notifiers), JSON.stringify(state.placementNotifiers), type])

  useEffect(() => {
    let dummyArrForChangedPaymentRateOnDates = []
    Object.values(state?.selectedTimesheets)?.forEach((eachTimesheet) => {
      state.paymentSettings?.forEach((eachPaymentSetting) => {
        const isWithinRange = isWithinInterval(new Date(eachTimesheet.date), { start: new Date(eachPaymentSetting.effectiveDate), end: new Date(eachPaymentSetting.effectiveUntil) });
        if(isWithinRange){
          if((Number(eachTimesheet?.billingRate) !== Number(eachPaymentSetting?.billingRate)) || (Number(eachTimesheet?.OTbillingRate) !== Number(eachPaymentSetting?.OTbillingRate))){
            if(Number(totalOTTimeInHrs) !== 0){
              dummyArrForChangedPaymentRateOnDates.push({
                "date":eachTimesheet.date,
                "newPaymentBillingRate":Number(eachPaymentSetting.billingRate),
                "oldPaymentBillingRate":Number(eachTimesheet.billingRate),
                "newPaymentOTBillingRate":Number(eachPaymentSetting.OTbillingRate),
                "oldPaymentOTBillingRate":Number(eachTimesheet.OTbillingRate),
              })
            }else if(Number(eachPaymentSetting.billingRate) !== Number(eachTimesheet.billingRate)){
              dummyArrForChangedPaymentRateOnDates.push({
                "date":eachTimesheet.date,
                "newPaymentBillingRate":Number(eachPaymentSetting.billingRate),
                "oldPaymentBillingRate":Number(eachTimesheet.billingRate),
              })
            }
          }
        }
      })
    })
    if(dummyArrForChangedPaymentRateOnDates.length){
      let sortDummyArrBasedOnDates = dummyArrForChangedPaymentRateOnDates.sort((a,b) => new Date(a.date) - new Date(b.date))
      setIsPaymentRateChanged(true)
      setChangedPaymentRateOnDates(sortDummyArrBasedOnDates)
    }
  },[JSON.stringify(state.selectedTimesheets),JSON.stringify(state.paymentSettings)])

  //Setting Invoice due date when change in Placemrnt
  useEffect(() => {
    if (!isNaN(parseInt(state.netTerms)) && (parseInt(state.netTerms) < 367)) {
      stateSetters.setInvoiceDueDate(CalendarFormatter.standardDateFormat(addDays(new Date(state.invoiceDate), state.netTerms)));
    }
  }, [state.clientID, state.placementID])

  return (
    <div>
      <Backdrop style={{ color: "#fff", zIndex: 4000 }} open={state.showLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="text-right w-100">
        {type === "edit" ? (
          <h2 style={{ fontWeight: 500 }}>{state.invoiceID} </h2>
        ) : (
          ""
        )}
      </div>
      <UpdateNotifiers isNotifiersChanged={isNotifiersChanged} />
      <UpdatePaymentRates isPaymentRateChanged={isPaymentRateChanged} ChangedPaymentRateOnDates={ChangedPaymentRateOnDates}/>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={9}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <SelectionCriteria />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Paper className="p-2">
                <InvoiceAddress />
                <br />
                <DatesSelection />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12}>
              <StandardTimeTable />
              {
                typeof state.invoiceSettings === "object" && state.invoiceSettings?.OT ? <OTtimeTable /> : <></>
              }
              <ExpenseTable />
            </Grid>
            <Grid item xs={12} sm={12} style={{textAlign:"end"}}>
              <AddAdditionalItems type = "new"/>
            </Grid>
            <Grid item xs={12} sm={12}>
              <AdditionalItemsTable />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Discount />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Notifiers />
            </Grid>
            <Grid item xs={12} sm={12}>
              <MoreInformation />
            </Grid>
            <Grid item xs={12} sm={12}>
              {type === "edit" ? <UpdateButton /> : <GenerateButton />}
            </Grid>
          </Grid>
        </Grid>
        {/* {!state.paymentSettings.isLoading&&
        state.paymentSettings.error === "" ? ( */}
        <Grid item xs={12} sm={3}>
          <SheetsSelector />
        </Grid>
        {/* ) : null} */}
      </Grid>
    </div>
  );
}

const NewInvoice = (props) => <NewInvoiceContextProvider><NewInvoiceComponent {...props} /> </NewInvoiceContextProvider>
export default NewInvoice