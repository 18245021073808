import React from "react"
import MoreArticles from "../Services/Wiki/components/MoreArticles/Index"
import { Breadcrumbs } from "@material-ui/core"
import { Link } from "react-router-dom"

function MoreArticlesPage(props) {
  return (
    <div>
      <div>
        <MoreArticles />
      </div>
    </div>
  )
}

export default MoreArticlesPage
