import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { Link, useParams } from "react-router-dom";
import MetaInfo from "../../../../../../shared/getMetaInfo";
import parser from "html-react-parser";
import validate from "../../../../../../shared/validation";
import { useProfileDashboardContext } from "../../../MenuBar/context";
import { connect } from "react-redux";
import Popover from "@material-ui/core/Popover";

function PopoverContent({ popoverHtml }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <span
        onClick={handleClick}
        style={{
          cursor: "pointer",
          textDecoration: "underline",
          color: "#2c9273",
        }}
      >
        See detailed info...
      </span>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {parser(popoverHtml ?? "")}
      </Popover>
    </>
  );
}

const SectionEmployeeAudit = ({ modules }) => {
  const tableRef = React.createRef();
  const { services, state } = useProfileDashboardContext();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { id } = useParams();

  const refreshData = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  useEffect(() => {
    if (state.tabValue === "employeeaduit") {
      refreshData();
    }
  }, [state.tabValue, id]);

  const metaInfo = new MetaInfo();
  const columns = [
    {
      title: "Action by",
      field: "actionbyName",
      render: (rowData) => {
        if (modules.includes("console-customization")) {
          return (
            <Link to={"/console/employees/" + rowData.actionby}>
              {rowData.actionbyName}
            </Link>
          );
        } else {
          return <Link to={"/console/profile"}>{rowData.actionbyName}</Link>;
        }
      },
    },
    { title: "Timestamp", field: "timestamp" },
    {
      title: "History",
      field: "history",
      render: (rowData) => formatSubject(rowData),
    },
  ];

  function formatSubject(rowData) {
    const { type, historyObj } = rowData;
    switch (type) {
      case "updateProfile":
        if ("actionDesc" in historyObj) {
          return (
            <span>
              {parser(historyObj.actionDesc?.action_desc_msg ?? "")}
              {historyObj.actionDesc?.popoverHtml?.length > 1 ? (
                <PopoverContent
                  popoverHtml={
                    historyObj.actionDesc?.popoverHtml ?? ""
                  }
                />
              ) : (
                ""
              )}
            </span>
          );
        } else {
          return <span>Updated Profile.</span>;
        }
      default:
        if ("actionDesc" in historyObj) {
          return (
            <span>{parser(historyObj.actionDesc?.action_desc_msg ?? "")}</span>
          );
        } else {
          return <span>{type}</span>;
        }
    }
  }

  const getData = async (query) => {
    let { pageSize = 10, page = 0 } = query;
    let isPageZero = false,
      isNextPage = false,
      isPrevPage = false;
    if (page === 0) {
      isPageZero = true;
      setRowsPerPage(pageSize);
    } else {
      if (page > state?.page) {
        isNextPage = true;
      } else {
        isPrevPage = true;
      }
    }

    const historyData = await services.getEmployeeAudit(
      isPageZero,
      isNextPage,
      isPrevPage,
      page,
      pageSize,
      id
    );

    let data =
      historyData &&
      historyData?.map((history) => {
        return {
          actionby: history.actionBy,
          actionbyName: metaInfo.emailToName(history.actionBy),
          timestamp:
            history.createdAt === ""
              ? history.createdAt
              : validate.dateAndTimeFormatter(history.createdAt),
          type: history.type,
          subject: Object.values(history.subject),
          historyObj: history,
        };
      });

    return { data, page, totalCount: Number.MAX_SAFE_INTEGER };
  };

  const localization = {
    pagination: {
      labelDisplayedRows: `Page: ${state?.page}`,
    },
  };

  return (
    <MaterialTable
      title=""
      columns={columns}
      data={(query) => getData(query)}
      localization={localization}
      isLoading={state?.isLoading}
      options={{
        filtering: false,
        columnsButton: false,
        showFirstLastPageButtons: false,
        pageSize: rowsPerPage,
        paginationPosition: "both",
        paginationType: "normal",
        pageSizeOptions: [10, 20, 50, 100, 250],
        padding: "dense",
        rowStyle: (_, index) =>
          index % 2 === 0 ? { background: "#f5f5f5" } : null,
        emptyRowsWhenPaging: false,
        search: false,
      }}
      actions={[
        {
          icon: "refresh",
          tooltip: "Refresh Data",
          isFreeAction: true,
          onClick: refreshData,
        },
      ]}
      tableRef={tableRef}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    modules: state.employee.employeeModules.accessModules,
  };
};

export default connect(mapStateToProps, null)(SectionEmployeeAudit);
