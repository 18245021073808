import React from 'react'
import {
  Table,
  Paper,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core"
import { withStyles, createStyles } from "@material-ui/core/styles"
import { useNewInvoiceContext } from '../NewInvoice/context'
import validate from '../../../../shared/validation'


const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell)

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow)

function ByExternalTable() {
  const { state } = useNewInvoiceContext()
  if (state.invoiceBy !== "external") return <span></span>

  return (
    <div style={{marginTop:"1rem"}}>
    <>
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell>S.No</StyledTableCell>
                        <StyledTableCell>Reason</StyledTableCell>
                        <StyledTableCell align='center'>Amount</StyledTableCell>
                    </StyledTableRow>
                    </TableHead>
                <TableBody>
                    <StyledTableRow>
                        <StyledTableCell>{1}</StyledTableCell>
                        <StyledTableCell>{state.invoiceName}</StyledTableCell>                    
                        <StyledTableCell align="right">{validate.currencyFormatterUs(state?.externalAmount)}</StyledTableCell>
                    </StyledTableRow>
                    <TableRow>
                        <TableCell colSpan={1} />
                        <TableCell align="right"><h4>Subtotal</h4></TableCell>
                        <TableCell align="right"><h4>{validate.currencyFormatterUs(state?.externalAmount)}</h4></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </>
    </div>
)
}

export default ByExternalTable