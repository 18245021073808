import axios from "axios"
import {
  loadClientProjectsRequest,
  loadClientProjectsFailure,
  loadClientProjectsSuccess,
} from "../actions/actionCreators"
import {
  waitingMsg,
  stopWaitMsg,
  errorMsg,
  successMsg,
} from "../../../shared/SnackBars/index"
import { JSutils } from "../../../shared/JSutils"
import make_API_call from "../../../providers/REST_API"
import firebase from "../../../config/fbConfig"

// export const setState = (obj) => (dispatch) => {
//   return dispatch(setStateAction(obj))
// }

export const loadClientProjects = (payload) => (
  dispatch,
  getState,
  { getFirebase }
) => {
  const { placementIDs } = payload
  console.log(payload)
  const clientplacementID = [...new Set(placementIDs)]
  const chunks = JSutils._array_to_chunk(clientplacementID, 10)
  console.log(chunks)
  dispatch(loadClientProjectsRequest())

  async function fetch() {
    const promises = []
    chunks.forEach((chunk) => {
      const promise = firebase
        .firestore()
        .collectionGroup("SETTINGS")
        .where("placementID", "in", chunk)
        .where("id", "==", "timesheets")
        .get()
      promises.push(promise)
    })
    const chunkSnap = await Promise.all(promises)
    console.log(chunkSnap)
    const data = chunkSnap.reduce(
      (init, snap) => [...snap.docs.map((doc) => doc.data()), ...init],
      []
    )
    console.log(data)
    const formatted = data.reduce((init, item) => {
      return {
        ...init,
        [item.placementID]: item,
      }
    }, {})
    dispatch(loadClientProjectsSuccess(formatted))
  }
  if (placementIDs) fetch()
  else {
    let err = "Failed to Loade them"
    dispatch(loadClientProjectsFailure(err))
  }
  // const subscribe =

  //   getFirebase()
  //       .firestore()
  //       .collectionGroup("EXPENSES")
  //       .where("isApproved", "==", false)
  //       .where("isRejected", "==", false)
  //       .onSnapshot(
  //         (snap) => {
  //           const data = JSutils._array_to_object(
  //             snap.docs.map((doc) => doc.data()),
  //             "id"
  //           )
  //           return dispatch(loadSubmittedExpensesSuccess(data))
  //         },
  //         (err) => {
  //           console.error(err)
  //           const msg = "Failed to load submiteed Expenses"
  //           errorMsg(msg)
  //           return dispatch(loadSubmittedExpensesFailure(msg))
  //         }
  //       )

  // dispatch(setListener(subscribe, "submittedExpenses"))
}
