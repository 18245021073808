import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import SimpleBar from 'simplebar-react';
import $ from 'jquery';
import modules from '../modules';

class Sidebar extends Component {
    componentDidMount() {
        $(".button-menu-mobile").on('click', function (event) {
            event.preventDefault();
            $("body").removeClass("side-condensed");
            $(this).addClass("d-none");
            $(".button-menu").removeClass("d-none");
            $(".button-menu").addClass("d-block");
            if ($(window).width() <= 992) {
                $(".left-side-menu").show();
            }
        });
        $(".button-menu").on('click', function (event) {
            event.preventDefault();
            $("body").addClass("side-condensed");
            $(this).addClass("d-none");
            $(".button-menu-mobile").addClass("d-block");
            $(".button-menu-mobile").removeClass("d-none");
            if ($(window).width() <= 992) {
                $(".left-side-menu").hide();
            }
        });
    }

    // handleDrawerClose = () => {
    //     $(".button-menu").on('click', function (event) {
    //         event.preventDefault();
    //         // $("body").removeClass("side-condensed");
    //         $(this).removeClass("d-none");
    //     });
    // }

    render(props) {
        const {accessModules , profile} = this.props  
       
        return (
            <div className="left-side-menu">
                <SimpleBar className="bar-height">
                    <div id="sidebar-menu">
                    <ul className="side-menu" id="side-menu">
                    {modules
                        .filter(
                            (ele) =>
                                (accessModules.includes(ele.moduleName) ||
                                accessModules.includes("console-customization") ||
                                ele.moduleName === "common-module") &&
                                profile.status === "active"
                            )
                        .map((item) => {
                            return (
                                <li className="side-nav-item">
                                    <Link className="side-nav-link-ref" data-menu-key="apps-notification" to={item.link} key={item.text}>
                                        {item.icon}
                                        <span>{item.text}</span>
                                    </Link>
                                </li>
                            )
                        })
                    }
                    </ul>
                        {/* {
                             modules
                             .filter(
                               (ele) =>
                                 (accessModules.includes(ele.moduleName) ||
                                   accessModules.includes("console-customization") ||
                                   ele.moduleName === "common-module") &&
                                 profile.status === "active"
                             )
                             .map((item) => {
                                return (
                                    <ul key={item.text} className="side-menu" id="side-menu">
                                <li className="side-nav-item">
                                    <Link className="side-nav-link-ref" data-menu-key="apps-notification" to={item.link} key={item.text}>
                                        {item.icon}
                                        <span> {item.text} </span>
                                    </Link>
                                </li>
                              
                            </ul>
                                )
                        }
                             )} */}
                       
                    </div>
                </SimpleBar>
                <div className="clearfix"></div>
            </div>
        )

    }
}

export default Sidebar;
