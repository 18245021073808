import React from 'react'
import List from '@material-ui/core/List'
import FilterControl from './FilterControl'

function FiltersContainer(props) {
  const { columns, onApply } = props
  console.log(columns)
  return (
    <List component='nav'>
      {columns.filter((col) => col.dataType !== 'component' && col.dataType !== '').map((column) => (
        <FilterControl column={column} key={column.field} onApply={onApply} />
      ))}
    </List>
  )
}

export default FiltersContainer
