import React, { useEffect, useState } from 'react'
import { useInvoiceListContext } from "../context";
import WarningIcon from "@material-ui/icons/Warning";
import { Tooltip } from '@material-ui/core';
import parser from 'html-react-parser'
import { useDialog } from '../../../../../hooks/useDialog';
import { Formik, Form, Field } from "formik";
import {Row,Col,Modal,Button} from "react-bootstrap"
import * as yup from "yup";
import AppModalHeader from '../../../../../shared/components/Modals/AppModalHeader';
import AppModalFooter from '../../../../../shared/components/Modals/AppModalFooter';
import { createTag } from '../../../../../api/services/default/invoices';
import { useMutation, useQueryClient} from "@tanstack/react-query";
import SelectFormField from '../../../../../shared/components/BootStrapFormFields/SelectFormField';
import RichTextEditor from '../../../../../shared/components/BootStrapFormFields/RichTextEditor';
import validation from '../../../../../shared/validation';

function EditFollowup(props) {
  const { invoiceID, followupComment :theFollowupComment,tagType, showButton = false ,employeeID,clientID=""} = props
  const [followupComment, setFollowupComment] = useState('')
  const {open,handleOpen,handleClose}=useDialog()
  const queryClient = useQueryClient();
  const tab = validation.getQueryParameter("tab") || "0";
  const [tabValue, setTabValue] = useState(tab)

  const tabValueToTypeMap = {
    "1": "due",
    "2": "generated",
    "3": "sent",
    "4": "void",
    "5": "paid",
    "6": "all"
  };
  
  let type = tabValueToTypeMap[tabValue];

  useEffect(() => {
    setFollowupComment(theFollowupComment)
  }, [invoiceID, theFollowupComment])

  const initialState={
    followupComment:followupComment,
    tagType:tagType,
    encodedID:""
  }
 
  const schema= yup.object().shape({
    tagType:yup.string().required("Tag Type is required"),
    followupComment:yup.string().required("Follow Up Comment is required")
  })

  let tagList = ["Check Issued", "PTP","Cash Not Applied","ACH","Collections","Internal","Sub Contract","Paid When Paid"];
  const optionMapper = (i) => ({ value: i, label: i });

  const tagTypes = useMutation({
		mutationFn: ({ encodedID,isUpdatingFollowup,followupComment,tagType}) => createTag( encodedID,isUpdatingFollowup,followupComment,tagType), 
   async onSuccess() {
      await queryClient.invalidateQueries(["getInvoices",type,{clientID, employeeID}]);
			handleClose();
		}, 
	});

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      const encodedID = encodeURIComponent(invoiceID)
      await tagTypes.mutateAsync({
        encodedID:encodedID,
        isUpdatingFollowup:1,
        followupComment:values.followupComment,
        tagType: values.tagType ||"",
      });
      actions.setSubmitting(false);
      handleClose()
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div>
      {
        showButton ?
        <Button
        onClick={handleOpen}
        className='btn-success btn-md px-6 text-high'
        style={{width:100,marginBottom:10}}
      >
          Follow up
      </Button>
          :
          <Tooltip
            placement="top"
            title={<div style={{ fontSize: "13px" }}>{parser(theFollowupComment)}</div>}
          >
            <WarningIcon onClick={handleOpen} className='chip-warning c-pointer' style={{ fontSize: '19px', marginTop: '3px', background: 'transparent' }} />
          </Tooltip>
      }

       <Modal show={open} onHide={handleClose} centered size="md">
				<Formik
					initialValues={initialState}
					validationSchema={schema}
					onSubmit={handleSubmit}
				>
					{(formik) => (
						<Form>
							<AppModalHeader title="Edit Follow Up"/>
              <Modal.Body>
                <Row>
                <Col md={12}>
                  <Field
                    component={RichTextEditor}
                    label="Follow up notes"
                    name="followupComment"
                    options={{
                      mode: "Balloon-always",
                    }}
                    height={180}
                  />
                </Col>
                <Col md={12}>
                  <Field
                    name="tagType"
                    label="Tag Type *"
                    options={tagList?.map(optionMapper)}
                    component={SelectFormField}
                    type='select'
                  />
                </Col>
              </Row>
							</Modal.Body>
							<AppModalFooter
                saveButtonText='Proceed'
								saveButtonDisabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
								onClickCancel={handleClose}
							/>
						</Form>
					)}
				</Formik>
			</Modal>
    </div>
  )
}

export default EditFollowup
