import React, { useState } from 'react'
import { IconButton, CircularProgress, Tooltip } from "@material-ui/core"
import { HiOutlineBan } from "react-icons/hi"
import { useInvoiceListContext } from "../context";
import SunEditor from "suneditor-react"
import { Row,Col,Button ,Modal } from 'react-bootstrap';
import {useDialog} from "../../../../../hooks/useDialog"
import { Formik, Form } from "formik";
import AppModalHeader from '../../../../../shared/components/Modals/AppModalHeader';
import AppModalFooter from '../../../../../shared/components/Modals/AppModalFooter';
import validation from "../../../../../shared/validation"
import { useQueryClient} from "@tanstack/react-query";

function VoidInvoice(props) {
  const { invoiceID, isVoid, isPaymentDone, isPreviewVoid, clientID="",employeeID } = props
  const { services } = useInvoiceListContext()
  const [showSpinner, setShowSpinner] = useState(false);
  const [voidReason, setVoidReason] = useState('')
  const {open,handleOpen,handleClose}=useDialog()

  const queryClient = useQueryClient();
  const tab = validation.getQueryParameter("tab") || "0";
  const [tabValue, setTabValue] = useState(tab)
  const tabValueToTypeMap = {
    "1": "due",
    "2": "generated",
    "3": "sent",
    "4": "void",
    "5": "paid",
    "6": "all"
  };
  
  let type = tabValueToTypeMap[tabValue];
 
  const handleYes = async () => {
    try {
      setShowSpinner(true)
      handleClose()
      await services.makeInvoiceVoid(invoiceID, voidReason)
      setShowSpinner(false)
      await queryClient.invalidateQueries(["getInvoices",type,{clientID, employeeID}]);
      await queryClient.invalidateQueries(["getInvoices","void",{clientID, employeeID}]);
    } catch (error) {
      setShowSpinner(false)
    }
  }

  return (
    <div>
      {
        showSpinner ? (
          <CircularProgress
            style={{ marginTop: "12px", marginLeft: "11px" }}
            size={18}
          />
        ) :
          !isPreviewVoid ? (
            <Tooltip title="VOID">
              <IconButton style={{ color: "red", padding: " 0px 5px" }} hidden={isVoid || isPaymentDone} onClick={handleOpen}>
                <HiOutlineBan size={20} /></IconButton>
            </Tooltip>  
          ) : (
            <Button
              onClick={handleOpen}
              hidden={isVoid || isPaymentDone}
              className='btn-info btn-md px-6 text-high'
            >
              <span>
                <HiOutlineBan style={{ color: "white" }} /> Void
              </span>
            </Button>
          )}
      <Modal show={open} onHide={handleClose} centered size="md"  style={{ border: '2px solid gray' }} className='followup-invoice-modal'>
				<Formik
					 initialValues={voidReason}
					 onSubmit={handleYes}
				>
					{(formik) => (
						<Form>
							<AppModalHeader title="Void Invoice"/>
              <Modal.Body>
                <Row>
                <Col md={12}>
                <SunEditor
              placeholder="Remarks*"
              onChange={(content) => setVoidReason(content)}
              setContents={voidReason}
              height={200}
              setOptions={{
                buttonList: [
                  [
                    "undo",
                    "redo",
                    "blockquote",
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                    "outdent",
                    "indent",
                    "list",
                    "table",
                    "link",
                    "image",
                    "fullScreen",
                    "showBlocks",
                    "print",
                    "save",
                  ],
                ],
              }}
          />
                </Col>
              </Row>
							</Modal.Body>
							<AppModalFooter
                saveButtonText='Proceed'
								onClickCancel={handleClose}
							/>
						</Form>
					)}
				</Formik>
			</Modal>  
    </div>
  )
}

export default VoidInvoice
