import React from "react"
import Presentation from "./Presentation"
import CircularSpinner from "../../../../../../../shared/circularSpinner"
import { getClientAccounts } from "../../../../../../../api/services/default/clients";
import { useQuery } from "@tanstack/react-query";

function Container(props) {
  const {client} = props
  const { data: clientsAccountsData,isFetched :isLoading } = useQuery({
		queryKey: ["getClientAccounts",client.id],
		queryFn: () => getClientAccounts(client.id),
	});
  if (isLoading) {
    return (
      <div>
        <Presentation {...props} accounts={clientsAccountsData} />
      </div>
    )
  }
  return (
    <CircularSpinner />
  )
}


export default Container
