import { ACTION } from "../actions/actionTypes"

const initstate = {
  employees: {

  }
}

export default function EmployeeHoverCardReducer(state = initstate, action) {
  switch (action.type) {

    case ACTION.LOAD_EMPLOYEE_DETAILS_REQ:
      return {
        ...state,
        employees: {
          ...state.employees,
          [action.payload.employeeID]: {
            isLoading: true,
            data: {},
            error: ""
          }
        }
      }

    case ACTION.LOAD_EMPLOYEE_DETAILS_SUCCESS:
      return {
        ...state,
        employees: {
          ...state.employees,
          [action.payload.employeeID]: {
            isLoading: false,
            data: action.payload.data,
            error: ""
          }
        }
      }

    case ACTION.LOAD_EMPLOYEE_DETAILS_FAILURE:
      return {
        ...state,
        employees: {
          ...state.employees,
          [action.payload.employeeID]: {
            ...state.employees[action.payload.employeeID],
            isLoading: false,
            error: action.payload.error
          }
        }
      }

    default:
      return state
  }
}
