import React from "react"
import { FaRegFileExcel } from "react-icons/fa"

function Presentation(props) {
	const { handleFileChange } = props
	return (
		<div>
			<div>
				<label
					className='rounded border p-2 c-pointer'
					htmlFor='contained-button-file-excel-invite'
				>
					<FaRegFileExcel size={22} /> <b>Invite multiple</b>
				</label>
				<input
					type='file'
					variant='outlined'
					className='d-none'
					id='contained-button-file-excel-invite'
					accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
					onChange={handleFileChange}
					size='small'
				/>
			</div>
		</div>
	)
}

export default Presentation
