import React from "react"
import DeductionList from "../Services/Deductions/DeductionsList"

function DeductionListPage(props) {
  return (
    <div>
      <DeductionList listAll={true} id={null} />
    </div>
  )
}

export default DeductionListPage
