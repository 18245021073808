import { makeStyles } from '@material-ui/core/styles'



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      display: 'flex',
      justifyContent: 'space-between',
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    btn: {
      backgroundColor: '#17a2b8',
      color: 'white',
      margin: '4px',
    },
    flex: {
      display: 'flex',
    },
    projectsarea: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
  }))

  export default useStyles