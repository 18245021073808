import React from "react"
import InviteEmployee from "../Services/EmployeeManagment/components/InviteEmployee/Index"

function InviteEmployeePage(props) {
	return (
		<div>
			<InviteEmployee />
		</div>
	)
}

export default InviteEmployeePage
