import React, { useEffect, useState } from "react"
import {
  Grid,
  TextField,
  Button,
  TextareaAutosize,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import validate from "../../../../../../shared/validation"
import { DoubleDigitFormatter } from "../../../../../../shared/customNumberFormats"
import { Autocomplete } from "@material-ui/lab"
import IOSSwitch from "../../../../../../shared/iosSwitch"
import { useMutation, useQuery } from "@tanstack/react-query"
import { getAllClientDomains,createClientDomains } from "../../../../../../api/services/flair-shared/clients"
import CreatableSelect from "react-select/creatable";

function Presentation(props) {
  const {
    comments,
    billableClient,
    jobTitle,
    clientDomain,
    startDate,
    endDate,
    projectEndDate,
    netTerms,
    paidWhenPaid,
    handleClientDomainChange,
    handleChange,
    handleKeyValuePair,
    handleSubmit,
    handleCheck,
    id,
    clients,
    disableBtn,
    setState
  } = props
  const [isLoading, setIsLoading] = useState(false);
  const [reqClient, setReqClient] = useState({})
  useEffect(() => {
    setReqClient(clients.filter((client) => client.id === billableClient)[0])
  }, [billableClient])

    const {data, refetch } = useQuery({
    queryKey: ["getAllClientDomains"],
    queryFn: () => getAllClientDomains(),
   })

   const useCreateClientDomain = useMutation({
		mutationFn: ({ data }) => createClientDomains(data),
		onSuccess: () => {
			refetch()
		},
	});

   const handleCreateOption = async (inputValue) => {
    setIsLoading(true);
    await useCreateClientDomain.mutateAsync({ data : {
      name : inputValue
    }})
    setIsLoading(false);
  };
   
  const clientDomainValue = data?.clientDomains?.find((res) => res === clientDomain) || null;

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            {!id ? (
              <Autocomplete
                fullWidth
                options={clients}
                onChange={(_, value) => {
                  if (value) {
                    handleKeyValuePair("billableClient", value.id)
                  }
                }}
                value={reqClient}
                autoHighlight
                getOptionLabel={(option) => option.businessDisplayName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Billable Client *"
                    size="small"
                    fullWidth
                    id="placemnts-billableclient"
                  />
                )}
              />
            ) : (
              <TextField
                label="Billable Client *"
                size="small"
                fullWidth
                value={reqClient ? reqClient.businessDisplayName : ""}
                id="placemnts-billableclient"
                InputProps={{
                  readOnly: true,
                }}
              />
            )}
          </Grid>
          <Grid item xs={6}>
          <CreatableSelect
				    isClearable
				    isSearchable
            isDisabled={isLoading}
				    key={`placements-clientDomain-${clientDomainValue}`}
            value={{ value: clientDomainValue, label: clientDomainValue}}
            options={data?.clientDomains?.map((item) => ({
              value: item,
              label: item,
            })) || []}   
            onChange={(option) => {
              const value = option ? option.value : "";
              setState((prevState) => ({
                ...prevState,
                clientDomain: value,
              }));
            }}
            onCreateOption={handleCreateOption}
            placeholder="Client Domain"
            name="clientDomain"
            closeMenuOnSelect={true}
            escapeClearsValue={true}
			/>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  fullWidth
                  label="Net Terms"
                  name="netTerms"
                  id="placements-netTerms"
                  value={netTerms}
                  hidden={paidWhenPaid}
                  onChange={handleChange}
                  InputProps={{
                    inputComponent: DoubleDigitFormatter,
                    disabled: paidWhenPaid,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
            <TextField
              required
              label="Job Title"
              size="small"
              fullWidth
              variant="outlined"
              helperText={
                jobTitle.length ? (validate.checkName(jobTitle) ? "" : "Enter valid job title") : ""
              }
              value={jobTitle}
              name="jobTitle"
              id="placements-jobTitle"
              onChange={handleChange}
            />
              </Grid>         
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <KeyboardDatePicker
                    required
                    fullWidth
                    size="small"
                    id="placements-startdate"
                    label="Start date"
                    format="MM/dd/yyyy"
                    value={startDate ? startDate : null}
                    onChange={(dateValue) => {
                      handleKeyValuePair("startDate", dateValue)
                      // sets_date(dateValue)
                    }}
                    name="startDate"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <KeyboardDatePicker
                    required
                    size="small"
                    fullWidth
                    minDate={startDate}
                    id="placements-endDate"
                    label="End date"
                    format="MM/dd/yyyy"
                    value={endDate ? endDate : null}
                    onChange={(dateValue) => {
                      handleKeyValuePair("endDate", dateValue)
                      // sete_date(dateValue)
                    }}
                    name="endDate"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <KeyboardDatePicker
                    size="small"
                    required
                    fullWidth
                    minDate={startDate}
                    value={projectEndDate ? projectEndDate : null}
                    id="placements-projectenddate"
                    label="Project End date"
                    format="MM/dd/yyyy"
                    onChange={(date) => {
                      handleKeyValuePair("projectEndDate", date)
                           //  setpe_date(date)
                    }}
                    name={"projectEndDate"}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <TextareaAutosize
              placeholder="Comment"
              marginTop={3}
              rowsMin={5}
              style={{ marginTop: "10px", width: "100%" }}
              value={comments}
              name="comments"
              id="placements-comments"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={8}>
                <FormGroup>
                  <FormControlLabel
                    id="placements-paidWhenPaid"
                    control={<IOSSwitch />}
                    checked={paidWhenPaid}
                    name="paidWhenPaid"
                    onChange={handleCheck}
                    label="Paid When Paid"
                  />
                </FormGroup>
              </Grid>
        </Grid>

        <br />
        <Button
          type="submit"
          variant="contained"
          color={id ? "secondary" : "primary"}
          disabled={
            disableBtn ||
            !validate.checkDateDiff(startDate, projectEndDate) ||
            !validate.checkDateDiff(startDate, endDate) ||
            !billableClient ||
            !validate.checkName(jobTitle) ||
            !netTerms
          }
        >
          {id ? "Update" : "Done"}
        </Button>
      </form>
    </div>
  )
}

export default Presentation
