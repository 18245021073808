import React from "react"
import Announcements from "../Services/Announcements/AllAnnouncements"

function AllAnnouncementPage() {
  return (
    <div>
      <Announcements />
    </div>
  )
}

export default AllAnnouncementPage
