import React from 'react'
import make_API_call from '../../providers/REST_API'


const ResourceManagementGroupContext = React.createContext()
const useResourceManagementGroupContext = () => React.useContext(ResourceManagementGroupContext)

function ResourceManagementGroupContextProvider(props) {
    const [isLoading, setisLoading] = React.useState(false)
    const [resourceManagementGroup, setResourceManagementGroup] = React.useState([])
    const  state = {
        isLoading,
        resourceManagementGroup
    }
    const stateSetters = {
        setisLoading,
        setResourceManagementGroup
    }

    const fetchResourceManagementGroup = async () => {
        try {
            setisLoading(true)
            const response = await make_API_call("get",'/employee/getEmployeesResourceManagementGroup')
            setResourceManagementGroup(response)
            setisLoading(false)
        } catch (error) {
            console.log(error)
            setisLoading(false)
        }
    }

    const services = {
        fetchResourceManagementGroup
    }
    
  return (
   <ResourceManagementGroupContext.Provider value={{state, stateSetters, services}}>
    {props.children}
    </ResourceManagementGroupContext.Provider>
  )
}   
export {ResourceManagementGroupContextProvider, useResourceManagementGroupContext}
