import React, { useState } from 'react'
import {
  Typography,
  AccordionSummary,
  Accordion,
  MenuItem,
  AccordionDetails,
  Button,
  TextField,
} from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { CustomField } from "../../../../../shared/components/formFields"
import { useNewInvoiceContext } from '../context'

function OtherInfo() {
  const { state, services } = useNewInvoiceContext()
  const [projectEndDate, setProjectEndDate] = useState(null)
  const invoiceFrequencies = ["Weekly", "Biweekly", "Semi-Monthly", "Monthly"]
  const placement = services.getPlacementById(state.placementID)
  const client = services.getClientById(state.clientID)
  const employee=services.getEmployeeById(state.employeeID)
  const category=employee?.personal?.category
  console.log("catttt",category)

  const onUpdate = () => {
    services.updatePlacement({ projectEndDate: projectEndDate }, "general", state.employeeID, state.placementID)
  }
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Other Information</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          {state.placementID !== "" ? (
            <>
              <div>
                <CustomField
                  type="date"
                  required
                  fullWidth
                  label="Project End Date"
                  value={projectEndDate ? projectEndDate : placement?.projectEndDate}
                  handleChange={({ target }) => {
                    console.log(target.value, !isNaN(Date.parse(target.value)))
                    if (!isNaN(Date.parse(target.value)))
                      setProjectEndDate(target.value)
                  }}
                />
              </div>
              <br />
              <div>
                <TextField
                  select
                  label="Invoice Frequnecy"
                  size="small"
                  required
                  value={(state.invoiceSettings?.frequency - 1).toString()}
                  style={{ marginTop: "3px" }}
                  name="frequency"
                  fullWidth
                  id="invoices-placements-invoices-frequency"
                  InputProps={{
                    readOnly: true,
                    disabled: false,
                  }}
                >
                  {invoiceFrequencies.map((option) => (
                    <MenuItem key={option} value={invoiceFrequencies.indexOf(option).toString()}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <br />
              <div>
                <span>
                  {typeof state.invoiceSettings === "object" && state.invoiceSettings?.OT ? (
                    <CheckIcon className="text-success" />
                  ) : (
                    <CloseIcon className="text-danger" />
                  )}
                  &nbsp;&nbsp; Invoice OT{" "}
                </span>
              </div>
              <br />
              <div>
                <span>
                  {client?.isInternal ? (
                    <CheckIcon className="text-success" />
                  ) : (
                    <CloseIcon className="text-danger" />
                  )}
                  &nbsp;&nbsp; Internal{" "}
                </span>
              </div>
              <br />
              <div>
                <span>
                  {category==="C2C"? (
                    <CheckIcon className="text-success" />
                  ) : (
                    <CloseIcon className="text-danger" />
                  )}
                  &nbsp;&nbsp; Sub Contract{" "}
                </span>
              </div>
              <br />
              <div>
                <span>
                  {placement?.paidWhenPaid ? (
                    <CheckIcon className="text-success" />
                  ) : (
                    <CloseIcon className="text-danger" />
                  )}
                  &nbsp;&nbsp; Paid When Paid{" "}
                </span>
              </div>
              <div className="text-center">
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={onUpdate}
                >
                  Update
                </Button>
              </div>
            </>
          ) : 
          <div>
            <div>
                <span>
                  {client?.isInternal ? (
                    <CheckIcon className="text-success" />
                  ) : (
                    <CloseIcon className="text-danger" />
                  )}
                  &nbsp;&nbsp; Internal{" "}
                </span>
              </div>
              <br />
              <div>
                <span>
                  {category==="C2C"? (
                    <CheckIcon className="text-success" />
                  ) : (
                    <CloseIcon className="text-danger" />
                  )}
                  &nbsp;&nbsp; Sub Contract{" "}
                </span>
              </div>
              <br />
              <div>
                <span>
                  {placement?.paidWhenPaid ? (
                    <CheckIcon className="text-success" />
                  ) : (
                    <CloseIcon className="text-danger" />
                  )}
                  &nbsp;&nbsp; Paid When Paid{" "}
                </span>
              </div>
            </div>
          }
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default OtherInfo
