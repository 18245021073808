import React from "react"
import EmployeeSort from "../Services/EmployeeManagment/components/EmployeeSort/Index/index"

function EmployeeListPage(props) {
	return (
		<div>
			<EmployeeSort />
		</div>
	)
}

export default EmployeeListPage
