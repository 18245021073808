import { CgNotes } from "react-icons/cg";
import useCustomDialog from "../../hooks/useCustomDialog";
import { IconButton } from "@material-ui/core";
import React,{ useState } from "react";
import SunEditor from "suneditor-react";
import Button from 'react-bootstrap/Button';

export default function Notes({ notes, notesName, handleExcuteNotesFunctions }) {
  const { renderContent, closeDialog, openDialog } = useCustomDialog();
  const [notesState, setNotesState] = useState("");

  const handleDialogOpen = () => {
    openDialog();
    setNotesState(notes)
  }

  const handleDialogClose = () => {
    closeDialog();
    setNotesState("");
  }

  const handleNotes = () => {
    handleExcuteNotesFunctions(notesState)
    handleDialogClose();
  }

  const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html ?? "<p></p>";
    return tmp.textContent || tmp.innerText || "";
  }

  const notesInText = stripHtml(notes)

  const dialogTitle = () => {
    if(notesInText?.length > 1 )
      return <>You are about to Update Notes for {notesName ?? ""}</>
    return <>You are about to Add Notes for {notesName ?? ""}</>
  }

  const dialogContent = () => {
    return (
      <SunEditor
        placeholder="Please Enter Notes here.*"
        onChange={(content) => setNotesState(content)}
        setContents={notesState}
        height={200}
        setOptions={{
            buttonList: [
                [
                    "undo",
                    "redo",
                    "blockquote",
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                    "outdent",
                    "indent",
                    "list",
                    "table",
                    "link",
                    "image",
                    "fullScreen",
                    "showBlocks",
                    "print",
                    "save",
                ],
            ],
        }}
      />
    );
  };

  const dialogActions = () => {
    return (
        <div>
            <div style={{textAlign:"end"}}>
              <Button onClick={handleDialogClose} className="btn btn-cancel rounded-pill px-4 btn-lg" style={{marginRight:"10px"}}>Cancel</Button>
              <Button onClick={handleNotes} className="btn btn-success rounded-pill px-4 btn-lg" disabled={stripHtml(notesState)?.length < 1}>
                {notesInText?.length > 1 ? (
                    <span>Update Notes</span>
                ) : (
                    <span>Add Notes</span>
                )}
              </Button>
            </div>
        </div>
    );
  };

  return(
    <div>
      {notesInText.length > 1 ? (
        <IconButton onClick={handleDialogOpen}>
          <CgNotes style={{ color: "red" }} />
        </IconButton>
      ) : (
        <IconButton onClick={handleDialogOpen}>
          <CgNotes />
        </IconButton>
      )}
      {renderContent({
        title: dialogTitle(),
        dialogContent: dialogContent(),
        hideButton: true,
        hideDefaultClose: true,
        maxWidth : "sm",
        disableEscapeKeyDown : true,
        disableBackdropClick : true,
        dialogActions:dialogActions()
      })}
    </div>
  )
}