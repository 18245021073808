import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Popover, IconButton } from "@material-ui/core"
import { AiOutlineMessage } from "react-icons/ai";
import parser from "html-react-parser"
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    width: "400px"
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));


function StatusReportHoverCard({ statusReport }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if(statusReport)
    return (
      <div align="center">
        <Link style={{ color: "grey" }} 
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        ><AiOutlineMessage size={20} /></Link>
        {/* <IconButton 
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          // onClick={handlePopoverOpen}
          >
          <AiOutlineMessage />
        </IconButton> */}
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorPosition={{ top: 200, left: 780 }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>
            {parser("<h4>Status Report :</h4>" + (statusReport ? statusReport : ""))}
          </Typography>
        </Popover>
      </div>
    )
  else
    return ("")
}

export default StatusReportHoverCard
