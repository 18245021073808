export default {
  data: {
    users: [],
    projectsCount: {
      totalCount: 0,
      Open: 0,
      OverDue: 0,
      Closed: 0,
    },
  },
  isLoading: true,
  error: null,
}
