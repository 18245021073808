import React, { useEffect, useState } from 'react'
import { BulkPaymentsContextContextProvider, useBulkPaymentsContext } from './context'
import TabPanel from "../../../../shared/tabPanel"
import { Paper, Tabs, Tab, Backdrop, CircularProgress } from "@material-ui/core"
import BulkPayment from './BulkPayment'
import SearchByReference from './SearchByReference'
import Validations from '../../../../shared/validation'
import Accounts from '../../../../utils/Accounts'


function TabsComponent(props) {
  const { services, state, stateSetters } = useBulkPaymentsContext()
  const [tabValue, setTabValue] = useState(0)
  const bulkPaymentId = Validations.getQueryParameter("bulkPaymentId")
  const editMode = !!bulkPaymentId

  useEffect(() => {
    if (editMode)
      setTabValue(2)
  }, [editMode])


  useEffect(() => {
    return () => {
      services.clearState()
    }
  }, [tabValue])

  useEffect(() => {
    if (editMode && tabValue === 2)
      services.editBulkPayment(bulkPaymentId)
    else if (state.selectedClientId && tabValue === 0) {
      services.getInvoicesByClient()
      services.checkUnlinkedAmount()
    }
  }, [state.selectedClientId, editMode, tabValue])

  useEffect(() => {
    const amount = Object.values(state.payDivisions).reduce((init, value) => init + Number(value), 0)
    const finalAmount = Accounts.roundOffAmount(amount)
    stateSetters.setAmountLinked(Number(finalAmount))
  }, [JSON.stringify(state.payDivisions)])

  useEffect(() => {
    const am = Number(state.paymentAmount) - Number(state.amountLinked)
    const finalAmount = Accounts.roundOffAmount(am)
    stateSetters.setUnlinkedAmount(Number(finalAmount))
  }, [state.paymentAmount, state.amountLinked])


  return (
    <div>
      <Backdrop style={{ color: "#fff", zIndex: 4000 }} open={state.showLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper square>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, v) => setTabValue(v)}
        >
          <Tab label="Bulk Payment" />
          <Tab label="Search by reference" />
          <Tab disabled={!editMode} label="Update Bulk Payment" />
        </Tabs>
      </Paper>
      <TabPanel value={tabValue} index={0}>
        <BulkPayment tabValue={tabValue} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <SearchByReference />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <BulkPayment tabValue={tabValue} bulkPaymentId={bulkPaymentId} editMode={editMode} />
      </TabPanel>
    </div>
  )
}

const BulkPaymentTabs = (props) => <BulkPaymentsContextContextProvider><TabsComponent {...props} /> </BulkPaymentsContextContextProvider>
export default BulkPaymentTabs