import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const TbEndsWithIcon = () => {
    return (
        <SvgIcon viewBox="0,0,16,16">
            <rect x="4.78" y="1.59" width="11.22" height="11.63" />
            <path d="M4.4,11H3.56v-.82h0A1.72,1.72,0,0,1,2,11.07a1.73,1.73,0,0,1-1.22-.41A1.45,1.45,0,0,1,.29,9.57C.29,8.59.86,8,2,7.87l1.55-.22c0-.88-.35-1.32-1.07-1.32A2.53,2.53,0,0,0,.8,7V6.12a3.18,3.18,0,0,1,1.77-.49c1.22,0,1.83.64,1.83,1.94ZM3.56,8.32l-1.25.17a1.93,1.93,0,0,0-.87.29.81.81,0,0,0-.3.72.78.78,0,0,0,.28.63,1,1,0,0,0,.72.24,1.34,1.34,0,0,0,1-.44,1.54,1.54,0,0,0,.4-1.1Z" />
            <path
                fill="white"
                d="M6.82,10.2h0V11H6V3.25H6.8V6.66h0a2,2,0,0,1,1.79-1,1.9,1.9,0,0,1,1.57.7,2.84,2.84,0,0,1,.56,1.87,3.21,3.21,0,0,1-.63,2.08,2.09,2.09,0,0,1-1.73.79A1.71,1.71,0,0,1,6.82,10.2Zm0-2.1v.72a1.56,1.56,0,0,0,.42,1.1,1.37,1.37,0,0,0,1.06.45,1.4,1.4,0,0,0,1.18-.58,2.63,2.63,0,0,0,.43-1.61,2.08,2.08,0,0,0-.4-1.36,1.32,1.32,0,0,0-1.08-.49,1.48,1.48,0,0,0-1.17.5A1.84,1.84,0,0,0,6.8,8.1Z"
            />
            <path
                fill="white"
                d="M15.62,10.71a2.74,2.74,0,0,1-1.43.36,2.33,2.33,0,0,1-1.79-.73,2.62,2.62,0,0,1-.68-1.87,2.87,2.87,0,0,1,.73-2.06,2.58,2.58,0,0,1,2-.78,2.73,2.73,0,0,1,1.21.25v.85a2.13,2.13,0,0,0-1.24-.4,1.68,1.68,0,0,0-1.31.57,2.16,2.16,0,0,0-.51,1.5,2.05,2.05,0,0,0,.48,1.44,1.67,1.67,0,0,0,1.29.53,2.1,2.1,0,0,0,1.28-.45Z"
            />
        </SvgIcon>
    );
};
