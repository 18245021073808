export const ACTION = {
  SET_STATE: "SET_STATE",

  EXPIREDLIST_REQUEST: "EXPIREDLIST_REQUEST",
  EXPIREDLIST_SUCCESS: "EXPIREDLIST_SUCCESS",
  EXPIREDLIST_ERROR: "EXPIREDLIST_ERROR",

  SUSPENDED_LIST_REQUEST: "SUSPENDED_LIST_REQUEST",
  SUSPENDED_LIST_SUCCESS: "SUSPENDED_LIST_SUCCESS",
  SUSPENDED_LIST_ERROR: "SUSPENDED_LIST_ERROR",

  HOLD_ACCOUNT_REQUEST: "HOLD_ACCOUNT_REQUEST",
  HOLD_ACCOUNT_SUCCESS: "HOLD_ACCOUNT_SUCCESS",
  HOLD_ACCOUNT_FAILURE: "HOLD_ACCOUNT_FAILURE",

  ENABLE_ACCOUNT_REQUEST: "ENABLE_ACCOUNT_REQUEST",
  ENABLE_ACCOUNT_SUCCESS: "ENABLE_ACCOUNT_SUCCESS",
  ENABLE_ACCOUNT_FAILURE: "ENABLE_ACCOUNT_FAILURE",

  ONIGNORE_DOCUMENT_REQUEST: "ONIGNORE_DOCUMENT_REQUEST",
  ONIGNORE_DOCUMENT_SUCCESS: "ONIGNORE_DOCUMENT_SUCCESS",
  ONIGNORE_DOCUMENT_FAILURE: "ONIGNORE_DOCUMENT_FAILURE",

  UPDATE_WORKQUEUE_DOCUMENT_REQUEST: "UPDATE_WORKQUEUE_DOCUMENT_REQUEST",
  UPDATE_WORKQUEUE_DOCUMENT_SUCCESS: "UPDATE_WORKQUEUE_DOCUMENT_SUCCESS",
  UPDATE_WORKQUEUE_DOCUMENT_FAILURE: "UPDATE_WORKQUEUE_DOCUMENT_FAILURE",

  REVIEW_WORKQUEUE_DOCUMENT_REQUEST: "REVIEW_WORKQUEUE_DOCUMENT_REQUEST",
  REVIEW_WORKQUEUE_DOCUMENT_SUCCESS: "REVIEW_WORKQUEUE_DOCUMENT_SUCCESS",
  REVIEW_WORKQUEUE_DOCUMENT_FAILURE: "REVIEW_WORKQUEUE_DOCUMENT_FAILURE",

  GET_REVIEW_DOCUMENT_REQUEST: "GET_REVIEW_DOCUMENT_REQUEST",
  GET_REVIEW_DOCUMENT_SUCCESS: "GET_REVIEW_DOCUMENT_SUCCESS",
  GET_REVIEW_DOCUMENT_FAILURE: "GET_REVIEW_DOCUMENT_FAILURE",
};
