import React, { useEffect, useRef, useState } from 'react'
import MaterialTable from 'material-table'
import { useInvoiceListContext } from '../context'
import RenderAmounts from './renderAmounts'
import { Constants } from '../../../../../shared/constants'
import Cards from './Cards'
import { CsvBuilder } from 'filefy'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

export default function General() {
	const { state, services } = useInvoiceListContext()

	const EXPORT_FILE_NAME = 'A/R Aging General Report'

	const tableRef = useRef(MaterialTable)
	const [filteredDataRows, setFilteredDataRows] = useState([])

	useEffect(() => {
		services.getArAgingList()
	}, [])

	useEffect(() => {
		setFilteredDataRows(tableRef.current.state.data)
	}, [tableRef.current.state && tableRef.current.state.data])

	useEffect(() => {}, [filteredDataRows])

	const handleSearchChange = (_) => {
		setFilteredDataRows(tableRef.current.state.data)
	}

	const columns = [
		{ title: 'Client Name', field: 'clientName' },
		{
			title: Constants.AR_AGING_RANGES[0],
			field: 'current',
			render: ({
				current: { openBalance = 0, invoicedAmount = 0 },
				clientId,
			}) => {
				return (
					<RenderAmounts
						openBalance={openBalance}
						invoicedAmount={invoicedAmount}
						clientId={clientId}
						range={0}
					/>
				)
			},
			customExport: ({ current: { openBalance = 0, invoicedAmount = 0 } }) => {
				return `Open balance: ${openBalance} \nInvoiced amount: ${invoicedAmount}`
			},
		},
		{
			title: Constants.AR_AGING_RANGES[1],
			field: 'dueBy30',
			render: ({
				dueBy30: { openBalance = 0, invoicedAmount = 0 },
				clientId,
			}) => {
				return (
					<RenderAmounts
						openBalance={openBalance}
						invoicedAmount={invoicedAmount}
						clientId={clientId}
						range={1}
					/>
				)
			},
			customExport: ({ dueBy30: { openBalance = 0, invoicedAmount = 0 } }) => {
				return `Open balance: ${openBalance} \nInvoiced amount: ${invoicedAmount}`
			},
		},
		{
			title: Constants.AR_AGING_RANGES[2],
			field: 'dueBy60',
			render: ({
				dueBy60: { openBalance = 0, invoicedAmount = 0 },
				clientId,
			}) => {
				return (
					<RenderAmounts
						openBalance={openBalance}
						invoicedAmount={invoicedAmount}
						clientId={clientId}
						range={2}
					/>
				)
			},
			customExport: ({ dueBy60: { openBalance = 0, invoicedAmount = 0 } }) => {
				return `Open balance: ${openBalance} \nInvoiced amount: ${invoicedAmount}`
			},
		},
		{
			title: Constants.AR_AGING_RANGES[3],
			field: 'dueBy90',
			render: ({
				dueBy90: { openBalance = 0, invoicedAmount = 0 },
				clientId,
			}) => {
				return (
					<RenderAmounts
						openBalance={openBalance}
						invoicedAmount={invoicedAmount}
						clientId={clientId}
						range={3}
					/>
				)
			},
			customExport: ({ dueBy90: { openBalance = 0, invoicedAmount = 0 } }) => {
				return `Open balance: ${openBalance} \nInvoiced amount: ${invoicedAmount}`
			},
		},
		{
			title: Constants.AR_AGING_RANGES[4],
			field: 'dueByAbove90',
			render: ({
				dueByAbove90: { openBalance = 0, invoicedAmount = 0 },
				clientId,
			}) => {
				return (
					<RenderAmounts
						openBalance={openBalance}
						invoicedAmount={invoicedAmount}
						clientId={clientId}
						range={4}
					/>
				)
			},
			customExport: ({
				dueByAbove90: { openBalance = 0, invoicedAmount = 0 },
			}) => {
				return `Open balance: ${openBalance} \nInvoiced amount: ${invoicedAmount}`
			},
		},
		{ title: 'Remittance Amount', field: 'remittanceAmount' },
		{
			title: 'Total Balance',
			field: 'totalBalance',
			render: ({
				totalBalance: { openBalance = 0, invoicedAmount = 0 },
				clientId,
			}) => {
				return (
					<RenderAmounts
						openBalance={openBalance}
						invoicedAmount={invoicedAmount}
						clientId={clientId}
						range={4}
					/>
				)
			},
			customExport: ({
				totalBalance: { openBalance = 0, invoicedAmount = 0 },
			}) => {
				return `Open balance: ${openBalance} \nInvoiced amount: ${invoicedAmount}`
			},
		},
	]
	const rows = Object.entries(state.arAgingList)?.map(([clientId, invoice]) => {
		return {
			...invoice,
			clientId: clientId,
		}
	})

	const invoices = filteredDataRows.map((invoice) => {
		return {
			openBalance: invoice.totalBalance.openBalance,
			amount: invoice.totalBalance.invoicedAmount,
		}
	})

	return (
		<div>
			<Cards invoices={invoices} />
			<MaterialTable
				title=''
				data={rows}
				columns={columns}
				tableRef={tableRef}
				isLoading={state.showLoader}
				onSearchChange={handleSearchChange}
				options={{
					pageSize: 10,
					filtering: false,
					paginationPosition: 'both',
					paginationType: 'normal',
					pageSizeOptions: [10, 20, 50, 100, 250],
					emptyRowsWhenPaging: false,
					exportButton: true,
					exportPdf: (columns, data) => {
						const doc = new jsPDF('l')
						const columnTitles = columns.map((columnDef) => columnDef.title)

						const pdfData = data.map((rowData) => {
							return columns.map((columnDef) => {
								if (typeof columnDef.customExport === 'function') {
									return columnDef.customExport(rowData)
								}
								return rowData[columnDef.field]
							})
						})

						autoTable(doc, {
							head: [columnTitles],
							body: pdfData,
						})

						doc.save(`${EXPORT_FILE_NAME}.pdf`)
					},
					exportCsv: (columns, data) => {
						const columnTitles = columns.map((columnDef) => columnDef.title)

						const csvData = data.map((rowData) => {
							return columns.map((columnDef) => {
								if (typeof columnDef.customExport === 'function') {
									return columnDef.customExport(rowData)
								}
								return rowData[columnDef.field]
							})
						})

						const builder = new CsvBuilder(`${EXPORT_FILE_NAME}.csv`)
							.setColumns(columnTitles)
							.addRows(csvData)
							.exportFile()

						return builder
					},
				}}
			/>
		</div>
	)
}
