import React from "react"
import TimesheetsList from "../Services/Timesheets/components/TimesheetsList/Index"

function TimesheetsListPage(props) {
  return (
    <div>
      <TimesheetsList listAll={true}  />
    </div>
  )
}

export default TimesheetsListPage
