import React from "react"
import { SwipeableDrawer, makeStyles, Tooltip, Fab } from "@material-ui/core"
import { Button } from "react-bootstrap"
import clsx from "clsx"
import Settings from "../../ProjectHandleComponents/Settings"
import { Settings as SettingsIcon } from "@material-ui/icons"
import { Link } from "react-router-dom"

const useSwipableStyles = makeStyles({
  list: {
    width: "auto",
  },
  fullList: {
    width: "auto",
  },
})

function Presentation(props) {
  const { project, id, handleComponentUpdate, isDashboard } = props
  const swipableClasses = useSwipableStyles()
  const [swipableState, setSwipableState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })
  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return
    }

    setSwipableState({ ...swipableState, [anchor]: open })
  }
  const list = (anchor) => (
    <div
      className={clsx(swipableClasses.list, {
        [swipableClasses.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <Settings project={project} projectId={id} handleComponentUpdate={handleComponentUpdate} />
    </div>
  )
  return ["left"].map((anchor) => (
    <React.Fragment key={anchor}>
      <Tooltip title="Project Settings">
        <Link onClick={toggleDrawer(anchor, true)} style={{ color: "white" }}>
          {isDashboard ? (
            <Button className="btn btn-success px-6 btn-md text-high ">
              Settings
            </Button>
          ) : (
            <Fab size="medium" className="ml-2" color="secondary">
              <SettingsIcon />
            </Fab>
          )}
        </Link>
      </Tooltip>

      <SwipeableDrawer
        anchor={anchor}
        open={swipableState[anchor]}
        onClose={toggleDrawer(anchor, false)}
        onOpen={toggleDrawer(anchor, true)}
      >
        {list(anchor)}
      </SwipeableDrawer>
    </React.Fragment>
  ))
}

export default Presentation
