import {
  loadProfileTemplateReq,
  loadProfileTemplateSuccess,
  loadProfileTemplateFailure,
  loadEmployeeProfileReq,
  loadEmployeeProfileSuccess,
  loadEmployeeProfileFailure,
  updateProfileRequest,
  updateProfileSuccess,
  updateProfileFailure,
  saveWorkAuthActiveRequest,
  saveWorkAuthActiveSuccess,
  saveWorkAuthActiveFailure
} from "../actions/dynamicProfileRenderActionCreators"
import { setStateAction } from "../actions/actionCreators"
import {
  errorMsg,
  successMsg,
} from "../../../shared/SnackBars/index"
import make_API_call from "../../../providers/REST_API"
import firebase from '../../../config/fbConfig'

export const _set_state = (obj) => (dispatch) => {
  dispatch(setStateAction(obj))
}

export const loadProfileTemplate = () => (dispatch, getState, { getFirebase }) => {
  dispatch(loadProfileTemplateReq())
  return getFirebase().firestore()
    .collection(`WEB_BUILDER`)
    .doc("employee-profile")
    .get()
    .then(async doc => {
      let data = doc.data()
      const personalFields = data.sections.personal.fields
      const empProfile = getState().employee?.dynamicProfileRender?.employeeProfile?.data
      const employeeID = empProfile?.uid
      const res = await loadSuspendedData(employeeID)
        if (res?.latestRelievingDate) {
        const suspensionField = {
          customize: false,
          editAccess: null,
          employeeID:employeeID,
          fieldPriority: personalFields.length + 1,
          isExist: false,
          label: 'Relieving date',
          latestRelievingDate:res.latestRelievingDate,
          required: false,
          type: 'date',
          name: 'dateOfSuspension',
          reasons : res?.reasons
        }

        personalFields.push(suspensionField)
        data.sections.personal.fields = personalFields
      }
      return dispatch(loadProfileTemplateSuccess(data))
    }).catch(err => {
      console.error(err);
      return dispatch(loadProfileTemplateFailure("Failed to load profile template"))
    })
}

const loadSuspendedData = (employeeID) => {
  if(!employeeID){
    return null
  }
  return firebase.firestore()
  .collection(`EMPLOYEES`)
  .doc(employeeID)
  .collection(`WORK_QUEUE`)
  .doc(`suspended_list_data`)
  .get()
  .then((doc)=> {
    if (!doc.exists || !doc.data()?.isExist) {
      throw new Error("No Doc Exists")
    } else {
      return doc.data()
    }
  })
  .catch((err)=> {
    console.log(err)
  })
};

export const loadEmployeeProfile = (employeeID) => (dispatch, getState, { getFirebase }) => {
  dispatch(loadEmployeeProfileReq())
  return getFirebase().firestore()
    .collection(`EMPLOYEES`)
    .doc(employeeID)
    .onSnapshot(
      (doc) => {
        return dispatch(loadEmployeeProfileSuccess(doc.data()))
      },
      (err) => {
        console.error(err);
        return dispatch(loadEmployeeProfileFailure("Failed to load employee profile"))
      }
    )
}


export const updateProfile = (payload, employeeID, modifiedProfile, callback = () => {}) => (dispatch, getState) => {
  dispatch(updateProfileRequest());
  return make_API_call("put", `/employee/${employeeID}/updateprofile`, payload)
    .then((res) => {
      successMsg(res.message)
      callback()
      return dispatch(updateProfileSuccess(modifiedProfile));
    })
    .catch((err) => {
      console.log(err);
      errorMsg(err.message)
      dispatch(updateProfileFailure("Failed to update profile"));
    });
};

export const saveWorkAuthActive = (payload, employeeID, callback = () => {}) => (dispatch, getState) => {
  dispatch(saveWorkAuthActiveRequest());
  return make_API_call("put", `/employee/${employeeID}/saveWorkAuthActiveStatus`, payload)
    .then((res) => {
      callback()
      return dispatch(saveWorkAuthActiveSuccess(res.data));
    })
    .catch((err) => {
      console.log(err);
      dispatch(saveWorkAuthActiveFailure("Failed to send the status"));
    });
};