import { flairSharedInstance } from "../../instances/flair-shared.instance";

/**
 * @param {Record} data
 * @param {AbortSignal?} signal
 */
export const createClientDomains = (data, signal) => {
  return flairSharedInstance({
    method: 'POST',
    url: '/clients',
    data,
    signal,
  });
}

/**
 * @returns {Promise<Array<Record>>}
 */

export const getAllClientDomains = (signal) => {
  return flairSharedInstance({
    method: 'GET',
    url: '/clients',
    signal
  });
}
