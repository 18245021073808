import { defaultInstance } from "../../instances/default.instance";

/**
 * @returns {Promise<Array<Record>>}
 */
export const getEmployeesSkillData = () => {
	return defaultInstance({
		method: "GET",
		url: `/employee/getEmployeesResourceManagementGroup`,
	});
};

/**
 * @returns {Promise<Array<Record>>}
 */
export const getAllSkills = () => {
	return defaultInstance({
		method: "GET",
		url: `/resourcemanagement/skills`,
	});
};

/**
 * @returns {Promise<Array<Record>>}
 */
export const postSkills = (data) => {
	return defaultInstance({
		method: "POST",
		url: `/resourcemanagement/skills`,
    data
	});
};

/**
 * @returns {Promise<Array<Record>>}
 */
export const getAllCertifications = () => {
	return defaultInstance({
		method: "GET",
		url: `/resourcemanagement/certifications`,
	});
};

/**
 * @returns {Promise<Array<Record>>}
 */
export const postCertifications = (data) => {
	return defaultInstance({
		method: "POST",
		url: `/resourcemanagement/certifications`,
    data
	});
};

