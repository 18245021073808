import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import CircularProgress from "@material-ui/core/CircularProgress";
import validation from "../../../../../../shared/validation";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Chip from "@material-ui/core/Chip";
import SunEditor from "suneditor-react";
import {
  useStyles,
  useStylesFacebook,
} from "../../../../Styles/commentsStyles";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import MetaInfo from "../../../../../../shared/getMetaInfo";

export default function Presentation(props) {
  const classes = useStyles();
  const {
    comments,
    handleChange,
    handleSubmit,
    state,
    handleUpdate,
    handleDelete,
    handleEdit,
    onEdits,
    updateId,
    handleCancel,
    loggedIn,
  } = props

  dayjs.extend(relativeTime);
console.log(loggedIn)
  function FacebookProgress(props) {
    const classes = useStylesFacebook();

    return (
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          value={100}
          className={classes.top}
          size={24}
          thickness={4}
          {...props}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.bottom}
          size={24}
          thickness={4}
          {...props}
        />
      </div>
    );
  }
  const onEdit = (id) => {
    const comment = comments.filter((item) => item.id === id)[0];
    handleEdit("onEdit", comment);
    // stateSet("isEditing", true)
    // stateSet("commentText", comment.text)
    // stateSet("updateId", comment.id)
  };
  const cancelUpdate = () => {
    handleCancel();
  };
  const validate = new validation();
  const metaInfo = new MetaInfo();
  const names = useSelector((state) => state.firestore.ordered.names[0]);
  console.log("Coment", comments)
  return (
    <div>
      <List className={classes.root}>
        {comments.map((comment) => {
          let userData =
            names &&
            Object.values(names).filter(
              (name) => name.uid === comment.createdBy
            )[0]
          console.log(userData)
          return (
            <div>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    alt={comment.createdBy}
                    src={metaInfo.getImage(comment.createdBy)}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <span className="d-flex">
                      <span>
                        <Link to={"/console/employees/" + comment.createdBy}>
                          {metaInfo.emailToName(comment.createdBy)}
                        </Link>{" "}
                        {metaInfo.checkSupervisor(comment.createdBy) ? (
                          <Chip
                            style={{
                              backgroundColor: "#f78a14",
                              fontWeight: "bold",
                              color: "#fff",
                            }}
                            size="small"
                            label="SUPERVISOR"
                          />
                        ) : userData.jobtitle ? (
                          <Chip size="small" label={userData.jobtitle} />
                        ) : null}
                      </span>
                      <Typography
                        style={{ marginLeft: "15px", color: "grey" }}
                        variant="body2"
                      >
                        {dayjs(comment.createdAt).fromNow()}
                      </Typography>{" "}
                      {loggedIn.uid === comment.createdBy  || loggedIn.role=="admin"? (
                        <span>
                          <span>
                            <IconButton
                              onClick={() => onEdit(comment.id)}
                              style={{ marginLeft: "10px", marginTop: "-15px" }}
                            >
                              <EditIcon />
                            </IconButton>
                          </span>{" "}
                          <span>
                            <IconButton
                              onClick={() => handleDelete(comment.id)}
                              style={{ marginLeft: "10px", marginTop: "-15px" }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </span>
                        </span>
                      ) : null}
                    </span>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {parse(comment.text)}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </div>
          )
        })}
      </List>

      <h2>Leave your comment</h2>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <form onSubmit={!onEdits ? handleSubmit : handleUpdate}>
            <SunEditor
              placeholder="Type your comment here"
              setContents={state.commentText}
              onChange={(data) => handleChange(data)}
              setOptions={{
                buttonList: [
                  [
                    "undo",
                    "redo",
                    "font",
                    "fontSize",
                    "formatBlock",
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                    "fontColor",
                    "hiliteColor",
                    "removeFormat",
                    "outdent",
                    "indent",
                    "align",
                    "horizontalRule",
                    "list",
                    "lineHeight",
                    "table",
                    "link",
                    "image",
                    "video",
                    "showBlocks",
                    "codeView",
                  ],
                ],
                mode: "Balloon-always",
              }}
            />
            <br />
            <br />
            {state.commentText && state.commentText.trim().length > 0 ? (
              state.commenting ? (
                <FacebookProgress />
              ) : !onEdits ? (
                <Button variant="contained" color="primary" type="submit">
                  Comment
                </Button>
              ) : (
                <span>
                  <Button
                    variant="contained"
                    className="flairbtn"
                    type="submit"
                  >
                    Update comment
                  </Button>{" "}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={cancelUpdate}
                  >
                    Cancel
                  </Button>
                </span>
              )
            ) : (
              <Button variant="contained" type="button" disabled>
                Comment
              </Button>
            )}
          </form>
        </Grid>
        <Grid item xs={12} sm={12}></Grid>
      </Grid>
    </div>
  );
}
