import React from "react"
// import CreateInvoice from "../Services/Invoices/components/CreateInvoice/Index"
import NewInvoice from "../Services/Invoices/components/NewInvoice/index"

function CreateInvoicePage(props) {
  return (
    <div>
      <NewInvoice {...props} />
    </div>
  )
}

export default CreateInvoicePage
