import React from "react"
import { connect } from "react-redux"
import { firestoreConnect,isLoaded } from "react-redux-firebase"
import { compose } from "redux"
import { contactActions } from "../../../../../middleware"
import Presentation from "./Presentation"
import { getAllClients, getClientContacts } from "../../../../../../../api/services/default/clients"
import { useQuery } from "@tanstack/react-query"

function Container(props) {
  const {clients_list_locations,client} = props

  const { data: clientsContactsData,isFetched :isLoading } = useQuery({
		queryKey: ["getClientContacts",client.id],
		queryFn: () => getClientContacts(client.id),
	});
  const { data: clientsData,isFetched :isLoadingClients } = useQuery({
		queryKey: ["getAllClients"],
		queryFn: () => getAllClients(),
	}); 

  if(isLoadingClients && clients_list_locations && isLoading )
    return (
      <div>
        <Presentation {...props} contacts = {clientsContactsData} clients= {clientsData} />
      </div>
    )
  return( 
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    clients_locations:state.firestore.ordered.clients_locations,
    clients_list_locations:state.firestore.ordered.clients_list_locations
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteContact: (payload) => {
      dispatch(contactActions(payload, "delete"))
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    
    return [
      {
        collectionGroup: "CLIENTS_LOCATIONS",
        storeAs: "clients_list_locations",
      },
    ]

  })
)(Container)
