import React from 'react';
import { Card } from 'react-bootstrap';



const Orders = (props) => {
    const {dueDetails} = props
    return (
        <Card>
            <Card.Body className="pb-1">
                <h5 className="card-title mt-0 mb-0 header-title text-center"><i className='bi bi-info-circle-fill text-danger pe-2'></i>TOP 5 DUE INVOICES</h5>

                <div className="table-responsive mt-1">
                    <table className="table table-nowrap mb-0">
                        <thead>
                            <tr>
                                <th scope="col">Invoice Number</th>
                                <th scope="col" className='text-center'>Due Days</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(dueDetails || [])?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <span className="badge badge-soft-danger rounded-pill">{item.invoiceNumber}</span>
                                        </td>
                                        <td className='text-center'>{item.dueDays} days</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </Card.Body>
        </Card>
    );
};

export default Orders;
