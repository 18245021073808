import ACTION from "../actions/actionTypes";
import initState from "./documentsState";
import documentsState from "./documentsState";

export default function documentsReducer(
  state = documentsState,
  { type, payload }
) {
  switch (type) {
    case ACTION.LOAD_EMPLOYEE_DOCS_REQ:
      return {
        ...state,
        isLoaded: false,
        data: initState.data,
        error: null,
      };

    case ACTION.LOAD_EMPLOYEE_DOCS_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        data: payload,
        error: null,
      };

    case ACTION.LOAD_EMPLOYEE_DOCS_FAILURE:
      return {
        ...state,
        isLoaded: true,
        data: initState.data,
        error: payload,
      };

    default:
      return state;
  }
}
