import React from 'react'
import { TextField } from '@material-ui/core'
import { handleFilterChange, onKeyDown } from './utlis'

export function StringFilter(props) {
  const { column, onApply } = props
  return (
    <div>
      <TextField
        label={''}
				onChange={handleFilterChange(column)}
				defaultValue={column.filterText}
        onKeyDown={onKeyDown(onApply)}
      />
    </div>
  )
}
