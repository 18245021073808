import React from 'react';
import { Card } from 'react-bootstrap';

const Payroll = () => {

    return (
        <Card>
            <Card.Body>
                <h5 className="card-title mb-3 header-title">Payroll</h5>
                <div className="d-flex mb-3">
                    <div className="keyicon">
                        <i className="fa-solid fa-chart-simple"></i>
                    </div>
                    <div className="ms-2">
                        <h6 className="text-muted fw-normal mt-0 mb-0">Total Profit</h6>
                        <h4 className="mb-0 mt-1 align-self-center fw-bold">$ 32,582.00</h4>
                    </div>
                </div>
                <div className="d-flex mb-3">
                    <div className="keyicon">
                        <i className="fa-solid fa-credit-card"></i>
                    </div>
                    <div className="ms-2">
                        <h6 className="text-muted fw-normal mt-0 mb-0">Total Pay</h6>
                        <h4 className="mb-0 mt-1 align-self-center fw-bold">$ 32,582.00</h4>
                    </div>
                </div>
                <div className="d-flex mb-3">
                    <div className="keyicon">
                        <i className="fa-solid fa-money-bill-trend-up"></i>
                    </div>
                    <div className="ms-2">
                        <h6 className="text-muted fw-normal mt-0 mb-0">Over Head</h6>
                        <h4 className="mb-0 mt-1 align-self-center fw-bold">$ 32,582.00</h4>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="keyicon">
                        <i className="fa-solid fa-chart-pie"></i>
                    </div>
                    <div className="ms-2">
                        <h6 className="text-muted fw-normal mt-0 mb-0">Anticipated Profit</h6>
                        <h4 className="mb-0 mt-1 align-self-center fw-bold">$ 32,582.00</h4>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};
export default Payroll;