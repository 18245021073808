import React from 'react'
import TimesheetsSelector from "./TimesheetsSelector"
import ExpenseSelector from "./ExpenseSelector"
import OtherInfo from "./OtherInfo"

function SheetsSelectorsIndex() {
  return (
    <>
      <TimesheetsSelector />
      <ExpenseSelector />
      <OtherInfo />
    </>
  )
}

export default SheetsSelectorsIndex
