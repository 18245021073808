import React from "react";
import { Col } from "react-bootstrap";

const reviewers = (props) => {
  const { Reviewers } = props;
  return (
    <div className="smleft pb-2">
      <h5 className="card-title mt-1 mb-3 header-title text-white position-relative">
        Timesheet Reviewers
      </h5>
      <div className="scrollspy-example" style={{ height: 235 }}>
        {Reviewers?.map((item) => (
          <div className="onstyle">
            <div className="card-lists py-2 px-2">
              <div className="d-flex">
                <img
                  src={item.empImage}
                  className="avatar-sm rounded-circle"
                  alt=""
                />
                <span className="align-self-center ps-2 fs-5">
                  {item.empName}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default reviewers;
