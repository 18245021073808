import React from 'react'
import ClientReject from "../Services/Invoices/components/ClientsInterest/Reject"
function ClientRejectPage() {
  return (
    <div>
      <ClientReject />
    </div>
  )
}

export default ClientRejectPage
