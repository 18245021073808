import React, { useState } from 'react'
import { NumberFilter } from './NumberFilter'
import { StringFilter } from './StringFilter'
import { DateFilter } from './DateFilter'
import { Grid, Button, Menu, MenuItem } from '@material-ui/core'
import { getOperators, getOperatorIcon, getOperatorText } from './utlis'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const getFilterControl = (column, onApply) => {
  switch (column.dataType) {
    case 'Numeric':
      return <NumberFilter column={column} onApply={onApply} />
    case 'String':
      return <StringFilter column={column} onApply={onApply} />
    case 'Date':
      return <DateFilter column={column} onApply={onApply} />
    default:
      return null
  }
}

function StandardFilterEditor(props) {
  const { column, onApply } = props
  const [currentOperator, setCurrentOperator] = useState(column.filterOperator)
  const [anchorEl, setAnchorEl] = useState(null)

  const options = getOperators(column).map((row) => ({
    key: row.value,
    icon: getOperatorIcon(row.value),
    text: getOperatorText(row.value, row.title),
  }))

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleOperatorClick = (operator) => {
    setCurrentOperator(operator)
    column['filterOperator'] = operator
    handleClose()
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Button
            variant='outlined'
            color='primary'
            size='small'
            // aria-controls={open ? 'split-button-menu' : undefined}
            // aria-expanded={open ? 'true' : undefined}
            aria-label='select merge strategy'
            aria-haspopup='menu'
            onClick={handleMenuClick}
          >
            {getOperatorIcon(currentOperator)}
            <ArrowDropDownIcon />
          </Button>
        </Grid>
        <Grid item xs={9}>
          {getFilterControl(column, onApply)}
        </Grid>
        <Menu
					id="split-button-menu"
					open={Boolean(anchorEl)}
					onClose={handleClose}
					anchorEl={anchorEl}
				>
          {options.map((option) => (
            <MenuItem
              key={option.text}
              selected={currentOperator === option.key}
              onClick={() => handleOperatorClick(option.key)}
            >
              {getOperatorIcon(option.key)}
              <span style={{ marginLeft: 10 }}>{option.text}</span>
            </MenuItem>
          ))}
        </Menu>
      </Grid>
    </>
  )
}

export default StandardFilterEditor
