import React, { useState } from "react"
import {
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from "@material-ui/core"
import { FiExternalLink } from "react-icons/fi"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import InviteThroughExcel from "../InviteThroughExcel"
import { CustomField } from "../../../../../shared/components/formFields"
import validate from "../../../../../shared/validation"
import { Button } from "react-bootstrap"

function Presentation(props) {
  const {
    heading,
    text,
    btnText,
    firstname,
    lastname,
    branch,
    dob,
    email,
    phonenumber,
    handleChange,
    handleDateChange,
    handleClose,
    handleClickOpen,
    onInvite,
    open,
    branchList,
    hideBtn = false
  } = props

  return (
    <div>
      {
        !hideBtn ?
          <Button
            className="btn btn-success px-6 btn-md text-high"
            onClick={handleClickOpen}
            id="em-invite-dialog-button"
          >
            {btnText}
          </Button>
          :
          null
      }

      <Dialog
        scroll="paper"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <span>{heading}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div>
              <span>{text}</span>
            </div>
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <CustomField
                label="First name"
                type="name"
                name="firstname"
                value={firstname}
                required
                variant="standard"
                fullWidth
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomField
                label="Last name"
                type="name"
                name="lastname"
                required
                value={lastname}
                variant="standard"
                fullWidth
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomField
                label="Phone"
                type="phone"
                name="phonenumber"
                required
                value={phonenumber}
                variant="standard"
                fullWidth
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{ marginTop: "4px" }}
                  fullWidth
                  label="Date of birth"
                  format="MM/dd/yyyy"
                  name="dob"
                  required
                  invalidDateMessage=""
                  disableFuture={true}
                  onChange={(date) => handleDateChange("dob", date)}
                  value={dob ? dob : null}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  id="em-invite-dialog-dob"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomField
                label="Email Address"
                value={email}
                required
                name="email"
                type="email"
                variant="standard"
                fullWidth
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomField
                label="Branch"
                name="branch"
                type="select"
                value={branch}
                required
                menuItems={branchList}
                variant="standard"
                fullWidth
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <h5>
                You can invite multiple number of employees by following the sample excel sheet we
                have provided:
              </h5>
              <a
                style={{ textDecoration: "underline" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://firebasestorage.googleapis.com/v0/b/flair-technologies.appspot.com/o/files%2FMultiple%20Invites%20(8).xlsx?alt=media&token=513f722d-1fc2-4d5b-82e4-377bdb1178ad"
              >
                Download sample format
                <FiExternalLink size={14} />{" "}
              </a>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <span className="mr-auto ml-2 d-flex">
            <InviteThroughExcel handleClose={handleClose} />
          </span>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onInvite} disabled={!validate.checkName(firstname) || !validate.checkName(lastname) || !validate.checkNumber(phonenumber) || !validate.checkEmail(email) || !branch || !dob} color="primary">
            Invite
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Presentation
