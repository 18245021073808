import axios from "axios"
import {
  loadApprovedExpensesFailure,
  loadApprovedExpensesRequest,
  loadApprovedExpensesSuccess,
  loadMyExpensesFailure,
  loadMyExpensesRequest,
  loadMyExpensesSuccess,
  loadRejectedExpensesFailure,
  loadRejectedExpensesRequest,
  loadRejectedExpensesSuccess,
  loadSubmittedExpensesFailure,
  loadSubmittedExpensesRequest,
  loadSubmittedExpensesSuccess,
  setListener,
  setStateAction,
  unSubscribeListenerAction,
} from "../actions/actionCreators"
import {
  waitingMsg,
  stopWaitMsg,
  errorMsg,
  successMsg,
} from "../../../shared/SnackBars/index"
import { JSutils } from "../../../shared/JSutils"
import make_API_call from "../../../providers/REST_API"

export const setState = (obj) => (dispatch) => {
  return dispatch(setStateAction(obj))
}

export const unSubscribeListener = (type) => (dispatch, getState) => {
  const expensesState = getState().expenses.expensesList
  const unsubscribe = expensesState[type].listener
  unsubscribe()
  return dispatch(unSubscribeListenerAction({ type }))
}
export const loadSubmittedExpenses = (payload) => (
  dispatch,
  getState,
  { getFirebase }
) => {
  const { individual, employeeId, clientID, isClientView } = payload
  console.log(payload)
  dispatch(loadSubmittedExpensesRequest())
  const subscribe = individual
    ? getFirebase()
      .firestore()
      .collection("EMPLOYEES")
      .doc(employeeId)
      .collection("EXPENSES")
      .where("isApproved", "==", false)
      .where("isRejected", "==", false)
      .orderBy("createdAt", "desc")
      .onSnapshot(
        (snap) => {
          const data = JSutils._array_to_object(
            snap.docs.map((doc) => doc.data()),
            "id"
          )
          // .filter(
          //   (placement) => new Date(placement.projectEndDate) > new Date()
          // )
          return dispatch(loadSubmittedExpensesSuccess(data))
        },
        (err) => {
          console.error(err)
          const msg = "Failed to load submiteed Expenses"
          errorMsg(msg)
          return dispatch(loadSubmittedExpensesFailure(msg))
        }
      )
    : isClientView
      ? getFirebase()
        .firestore()
        .collectionGroup("EXPENSES")
        .where("isApproved", "==", false)
        .where("isRejected", "==", false)
        .where("clientId", "==", clientID)
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadSubmittedExpensesSuccess(data))
          },
          (err) => {
            console.error(err)
            const msg = "Failed to load submiteed Expenses"
            errorMsg(msg)
            return dispatch(loadSubmittedExpensesFailure(msg))
          }
        )
      : getFirebase()
        .firestore()
        .collectionGroup("EXPENSES")
        .where("isApproved", "==", false)
        .where("isRejected", "==", false)
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadSubmittedExpensesSuccess(data))
          },
          (err) => {
            console.error(err)
            const msg = "Failed to load submiteed Expenses"
            errorMsg(msg)
            return dispatch(loadSubmittedExpensesFailure(msg))
          }
        )

  dispatch(setListener(subscribe, "submittedExpenses"))
}

export const loadApprovedExpenses = (payload) => (
  dispatch,
  getState,
  { getFirebase }
) => {
  const { individual, employeeId, clientID, isClientView } = payload
  dispatch(loadApprovedExpensesRequest())
  const subscribe = individual
    ? getFirebase()
      .firestore()
      .collection("EMPLOYEES")
      .doc(employeeId)
      .collection("EXPENSES")
      .where("isApproved", "==", true)
      .orderBy("createdAt", "desc")
      .onSnapshot(
        (snap) => {
          const data = JSutils._array_to_object(
            snap.docs.map((doc) => doc.data()),
            "id"
          )
          return dispatch(loadApprovedExpensesSuccess(data))
        },
        (err) => {
          console.error(err)
          const msg = "Failed to load Approved Expenses"
          errorMsg(msg)
          return dispatch(loadApprovedExpensesFailure(msg))
        }
      )
    : isClientView
      ? getFirebase()
        .firestore()
        .collectionGroup("EXPENSES")
        .where("clientId", "==", clientID)
        .where("isApproved", "==", true)
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadApprovedExpensesSuccess(data))
          },
          (err) => {
            console.error(err)
            const msg = "Failed to load Approved Expenses"
            errorMsg(msg)
            return dispatch(loadApprovedExpensesFailure(msg))
          }
        )
      : getFirebase()
        .firestore()
        .collectionGroup("EXPENSES")
        .where("isApproved", "==", true)
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadApprovedExpensesSuccess(data))
          },
          (err) => {
            console.error(err)
            const msg = "Failed to load Approved Expenses"
            errorMsg(msg)
            return dispatch(loadApprovedExpensesFailure(msg))
          }
        )

  dispatch(setListener(subscribe, "approvedExpenses"))
}

export const loadRejectedExpenses = (payload) => (
  dispatch,
  getState,
  { getFirebase }
) => {
  const { individual, employeeId, clientID, isClientView } = payload
  dispatch(loadRejectedExpensesRequest())
  const subscribe = individual
    ? getFirebase()
      .firestore()
      .collection("EMPLOYEES")
      .doc(employeeId)
      .collection("EXPENSES")
      .where("isRejected", "==", true)
      .orderBy("createdAt", "desc")
      .onSnapshot(
        (snap) => {
          const data = JSutils._array_to_object(
            snap.docs.map((doc) => doc.data()),
            "id"
          )
          return dispatch(loadRejectedExpensesSuccess(data))
        },
        (err) => {
          console.error(err)
          const msg = "Failed to load Approved Expenses"
          errorMsg(msg)
          return dispatch(loadRejectedExpensesFailure(msg))
        }
      )
    : isClientView
      ? getFirebase()
        .firestore()
        .collectionGroup("EXPENSES")
        .where("clientId", "==", clientID)
        .where("isRejected", "==", true)
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadRejectedExpensesSuccess(data))
          },
          (err) => {
            console.error(err)
            const msg = "Failed to load Rejected Expenses"
            errorMsg(msg)
            return dispatch(loadRejectedExpensesFailure(msg))
          }
        )
      : getFirebase()
        .firestore()
        .collectionGroup("EXPENSES") 
        .where("isRejected", "==", true)
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadRejectedExpensesSuccess(data))
          },
          (err) => {
            console.error(err)
            const msg = "Failed to load Rejected Expenses"
            errorMsg(msg)
            return dispatch(loadRejectedExpensesFailure(msg))
          }
        )

  dispatch(setListener(subscribe, "rejectedExpenses"))
}

export const loadMyExpenses = (payload) => (
  dispatch,
  getState,
  { getFirebase }
) => {
  const { individual, employeeId } = payload
  dispatch(loadMyExpensesRequest())
  const subscribe = getFirebase()
    .firestore()
    .collection("EMPLOYEES")
    .doc(employeeId)
    .collection("EXPENSES")
    .orderBy("createdAt", "desc")
    .onSnapshot(
      (snap) => {
        const data = JSutils._array_to_object(
          snap.docs.map((doc) => doc.data()),
          "id"
        )
        return dispatch(loadMyExpensesSuccess(data))
      },
      (err) => {
        console.error(err)
        const msg = "Failed to load My Expenses"
        errorMsg(msg)
        return dispatch(loadMyExpensesFailure(msg))
      }
    )

  dispatch(setListener(subscribe, "myExpenses"))
}

export const _get_expenses_dashboard_Remaining_count = async () => {
  try{
    let data = await make_API_call("get",
      `/employees/expensesDashboardCount`)
    console.log("Satya",data)
    return{
      data: data   ,
         }
    
  }catch (error){
    console.error(error)
    let errorInfo = {
      stringifiedError: error?.message,
      service: 'Expenses - Expense Dashboard',
      functionName: '_get_expenses_dashboard_Remaining_count'
    }
    make_API_call('post', '/errors/report', errorInfo)
  }
}

export const _get_expenses_dashboard_count = async () => {
  try{
    let data = await make_API_call("get",
      `/employees/expensesDashboard`)
    console.log("Satya",data)
    return{
      data: data   ,
         }
    
  }catch (error){
    console.error(error)
    let errorInfo = {
      stringifiedError: error?.message,
      service: 'Expenses - Expense Dashboard',
      functionName: '_get_expenses_dashboard_count'
    }
    make_API_call('post', '/errors/report', errorInfo)
  }
}

export const _get_expenses_graph_dashboard = async (fromDate,toDate) => {
  try{
    let data = await make_API_call("get",
      `/employees/expensesGraphdashboard?fromDate=${fromDate}&toDate=${toDate}`)
    console.log("Satya",data)
    return{
      data: data   ,
         }
    
  }catch (error){
    console.error(error)
    let errorInfo = {
      stringifiedError: error?.message,
      service: 'Expenses - Expense Dashboard',
      functionName: '_get_expenses_graph_dashboard'
    }
    make_API_call('post', '/errors/report', errorInfo)
  }
}

