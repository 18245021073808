import React from "react";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { configuration } from "../../../../../../config/companyConfig";
import { Link } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import Validation from "../../../../../../shared/validation";
import TaskActions from "../TaskActions";
import parser from "html-react-parser";
import Labels from "../Labels";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import validate from "../../../../../../shared/validation"
import { useStyles, customStyles } from "../../../../Styles/TaskViewerStyles";
import MetaInfo from "../../../../../../shared/getMetaInfo";

export default function Presentation(props) {
  const classes = useStyles();
  const { project, task } = props;
  let req_task = task[0];
  let useLabels = project[0].useLabels;
  let labels = project[0].labels;
  const formatter = (date) => {
    let final = ""
    try {
      final = Intl.DateTimeFormat(
        configuration["date-code"],
        configuration.dateformat
      ).format(new Date(date))
    } catch (error) {
      console.log(error)
      final = date
    }
    return final
  }
  const validate = new Validation();
  const metaInfo = new MetaInfo();
  // if (
  //   state.hasOwnProperty("taskList") &&
  //   task !== undefined &&
  //   Object.keys(task).length
  // )

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item md={12} sm={12} xs={12}>
          <div className="d-flex justify-content-between">
            <div>
              <h2>{req_task.title}</h2>
              <span>
                <Chip
                  size="small"
                  label={project[0].cid + " - " + req_task.cid}
                />{" "}
                &nbsp;
                {req_task.type === "Task" ? (
                  <Chip
                    size="small"
                    style={{ backgroundColor: "#17a2b8", color: "white" }}
                    label="Task"
                  />
                ) : (
                  <Chip
                    size="small"
                    style={{ backgroundColor: "#db2828", color: "white" }}
                    label="Bug"
                  />
                )}
              </span>
            </div>
            <div>
              <TaskActions taskId={props.taskId} projectId={props.projectId} />
            </div>
          </div>
          <hr />
        </Grid>
        <Grid item md={9} sm={9} xs={9}>
          <TableContainer className={classes.table} component={Paper}>
            <span className="d-flex">
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell width="20%">
                      <b>Start date:</b>
                    </TableCell>
                    <TableCell>{formatter(req_task.startdate)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>End date:</b>
                    </TableCell>
                    <TableCell>{formatter(req_task.enddate)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Status:</b>
                    </TableCell>
                    <TableCell>{req_task.status}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Priority:</b>
                    </TableCell>
                    <TableCell>{req_task.priority}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Assignee:</b>
                      <br />
                    </TableCell>
                    <TableCell>
                      {req_task.assignee &&
                        req_task.assignee.map((employee) => (
                          <span>
                            <Link to={"/console/employees/" + employee}>
                              <Button
                                type="a"
                                style={
                                  Object.values(project[0].Users)
                                    .map((user) => user.uid)
                                    .includes(employee)
                                    ? customStyles.employeeExist
                                    : customStyles.employeeNonExist
                                }
                                size="small"
                              >
                                {metaInfo.emailToName(employee)}
                              </Button>
                            </Link>
                            <br />
                          </span>
                        ))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Created By :</b>
                    </TableCell>
                    <TableCell>
                      {metaInfo.emailToName(req_task.createdBy)}
                    </TableCell>
                  </TableRow>
                  <TableRow></TableRow>
                  <TableRow rowSpan={2}>
                    <TableCell>
                      <b>Description:</b>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          wordWrap: "break-word",
                          width: "fit-content",
                        }}
                      >
                        {parser(req_task.description)}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </span>
          </TableContainer>
        </Grid>
        {/* <Grid item md={2} sm={12} xs={12}>
            <Card className={classes.card}>
              <CardContent>
                <b>Assignee:</b><br/>
                {task.assignee.map(employee => <span><Link to={"/console/employees/" + employee} ><Button type="a" style={state.projectList.filter(project => project.id === state.project.id)[0].Users.map(user => user.uid).includes(employee) ? customStyles.employeeExist : customStyles.employeeNonExist} size="small">{metaInfo.emailToName(employee)}</Button></Link><br /></span>)}
              </CardContent>
            </Card>
          </Grid> */}
        <Grid item md={3} sm={3} xs={3}>
          <Card className={classes.card}>
            <CardContent>
              {useLabels ? (
                <Labels labels={labels} task={task} project={project[0]} />
              ) : null}
            </CardContent>
          </Card>
          <br />
          <Card className={classes.card}>
            <CardContent>
              <h3>Project :</h3>
              <h3 style={{ fontWeight: "200" }}>{project[0].title} </h3>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );

  // return <p>Loading viewer</p>
}
