import React, { useContext } from "react";
import { Context } from "../../SubmitTimesheet/component_state/context";
import { Grid, Button, Paper, Icon } from "@material-ui/core";
import SelectPlacement from "../../SubmitTimesheet/SelectPlacement";
import Document from "../../SubmitTimesheet/Document";
import EntryTable from "../../SubmitTimesheet/EntryTable";
import SubmitButton from "../../SubmitTimesheet/ActionButtons/SubmitButton";
import UpdateButton from "../../SubmitTimesheet/ActionButtons/UpdateButton";

import { Link } from "react-router-dom";
import TimesheetCalendar from "../../SubmitTimesheet/TimesheetCalendar";
import StatusReport from '../../SubmitTimesheet/StatusReport'
import parser from "html-react-parser"

function Presentation(props) {
  const [state, handle] = useContext(Context);
  const { isInvoicePage, loggedInEmployee,modules } = props;
  return (
    <div>
      <Paper className="p-2">
        <Grid container spacing={2}>
          {!isInvoicePage ? (
            <>
              <Grid item xs={12} sm={4}>
                <SelectPlacement state={state} {...props} />
                <Document state={state} type={'defaulter'} />
              </Grid>
              <Grid item xs={12} sm={7}>
                <TimesheetCalendar />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Link
                  to={`/console/timesheets/edit?employeeID=${props.employeeID}&placementID=${props.placementID}&timesheetID=${props.timesheetID}`}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    id="edit-timesheet-button"
                    hidden={state.type !== "view"}
                    endIcon={<Icon style={{ size: "12px" }}>edit</Icon>}
                  >
                    Edit
                  </Button>
                </Link>
              </Grid>
            </>
          ) : null}
          {
             loggedInEmployee === state.employeeID ?
              <Grid item xs={12} sm={12}>
                <StatusReport />
              </Grid>
            :
            <></>
          }
          {
             (loggedInEmployee !== state.employeeID) ?
              <Grid item xs={12} sm={12}>
                <p>{parser(state?.statusReport ? ("<h4>Status Report :</h4>" + state?.statusReport) : "")}</p>
              </Grid>
            :
            <></>
          }
          <Grid item xs={12} sm={12}>
            <EntryTable />
          </Grid>
          
          <Grid item xs={12} sm={12}>
            {isInvoicePage && loggedInEmployee === state.employeeID ? (
              <div className="h6">
                Note: One cannot edit his/her own timesheet after approved
              </div>
            ) : (
              <div>
                <SubmitButton />
                <UpdateButton isInvoicePage={isInvoicePage} />
              </div>
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default Presentation;
