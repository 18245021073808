import React, { useEffect, useState } from 'react'
import Presentation from "./Presentation"
import { connect } from "react-redux"
import { _set_state } from "../../../middleware/dynamicProfileRender"
import { Prompt } from 'react-router-dom'
import fire from "../../../../../config/fbConfig"
import { storage } from "../../../../../config/fbConfig"
import { render } from "react-dom"
import {  updateReason } from '../../../middleware'
import { successMsg,errorMsg } from '../../../../../shared/SnackBars/index'
function Container(props) {
  const {update_reason,suspendedReasons, employeeID,relievingDate,employeeProfileData } = props

  const [states, setStates] = useState({
    profile: props.profile,
    image: null,
    progress: 0,
    url: "",
    imageURL: "",
    email: "",
  })

useEffect(()=>{
  setStates({
    ...states,
    imageURL: employeeProfileData.imageURL,
  });
},[employeeProfileData.imageURL])

  const handleChange = (file, email) => {
    console.log(file, email)
    if (file) {
      const image = file
      setStates(() => ({
        ...states,
        image,
      }))
      ImgUpload(file, email)
    } else {
    }
  }
  const ImgUpload = (image, email) => {
    var size = 5000000
    console.log(email)

    console.log(image.type)
    if (
      image.type === "image/png" ||
      image.type === "image/jpeg" ||
      image.type === "image/jpg"
    ) {
      if (image.size > size) {
        render(
          <Prompt
            open={true}
            content="Image size should be less than 5MB"
            severity="error"
          />,
          document.getElementById("notifications-box")
        )
        setStates({
          ...states,
          image: null,
        })
      } else {
        const uploadTask = storage.ref(`images/${image.name}`).put(image)
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // progrss function ....
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 150
            )
            console.log(progress)
            setStates({
              ...states,
              progress: 150,
            })
          },
          (error) => {
            // error function ....
            console.log(error)
          },
          () => {
            // complete function ....
            storage
              .ref("images")
              .child(image.name)
              .getDownloadURL()
              .then((url) => {
                console.log(url)
                setStates({
                  ...states,
                  url,
                })

                fire
                  .firestore()
                  .collection("EMPLOYEES")
                  .doc(employeeID)
                  .update({
                    imageURL: url,
                  })
                  .then(() => {
                    setStates({
                      ...states,
                      imageURL: url,
                      image: null,
                      progress: 0,
                    });
                    successMsg("Profile pic Updated successfully");
                  })
                  .catch((err) => {
                    console.error(err);
                    errorMsg("Failed to update profile pic");
                  });
              })
          }
        )
      }
    } else {
      render(
        <Prompt
          open={true}
          content="You Are Allowed To Choose Images with jpeg,png,jpg Format"
          severity="error"
        />,
        document.getElementById("notifications-box")
      )
      setStates({
        ...states,
        image: null,
      })
    }
  }
 

  const onUpdate = async (payload) => {
    
    update_reason(payload)
  }


  return (
    <div>
      <Presentation
      {...states}
      handleChange={handleChange}
      onUpdate={onUpdate}
      employeeID={employeeID}
      relievingDate={relievingDate}
      progress={states.progress}
      employeeProfileData={employeeProfileData}
      suspendedReasons={suspendedReasons}
      />
    </div>
  )
}



const mapDispatchToProps = dispatch => ({
  setState: (obj) => dispatch(_set_state(obj)),
  update_reason:(payload) => {
    dispatch(updateReason(payload))
  },
})

export default connect(null, mapDispatchToProps)(Container)
