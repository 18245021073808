import React from "react"
import RequestLetter from "../Services/LetterRequests/components/MyRequests/EmployeeStepper/index"

function RequestLetters(props) {
  return (
    <div>
      <RequestLetter myReq={false} />
    </div>
  )
}

export default RequestLetters
