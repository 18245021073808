import {
	documentsFailure,
	documentsReq,
	documentsSuccess,
} from '../actions/actionCreators'
import { errorMsg } from '../../../shared/SnackBars/index'
import firebase from '../../../config/fbConfig'
import make_API_call from '../../../providers/REST_API'

async function getPlacementDocs(employeeID) {
	try {
		const placementsSnapshot = await firebase
			.firestore()
			.collectionGroup('PLACEMENTS')
			.where('employeeID', '==', employeeID)
			.where('isExist', '==', true)
			.get()
		const placementIds = placementsSnapshot.docs.map((doc) => doc.id)
		const documentPromises = []
		placementIds.forEach((id) => {
			const promise = firebase
				.firestore()
				.collection(`EMPLOYEES/${employeeID}/PLACEMENTS/${id}/SETTINGS`)
				.where('id', '==', 'documents')
				.get()
			documentPromises.push(promise)
		})
		const promiseResult = await Promise.allSettled(documentPromises)
		const placementDocsSnapshots = promiseResult
			.filter((promise) => promise.status === 'fulfilled')
			.map((promise) => promise.value)
		const documents = []
		placementDocsSnapshots.forEach((snapshot) => {
			snapshot.docs.forEach((doc) => {
				const data = doc.data()
				if (doc.exists && data) {
					documents.push(data.documents)
				}
			})
		})
    return documents
	} catch (error) {
		console.error(error)
    const errorInfo = {
			stringifiedError: error?.message || error,
			service: 'Documents',
			functionName: 'getPlacementDocs',
		}
		make_API_call('post', '/errors/report', errorInfo)
		throw error
	}
}

async function getDeductions(employeeID) {
	try {
		const deductionsSnapshot = await firebase
			.firestore()
			.collection(`EMPLOYEES/${employeeID}/DEDUCTIONS`)
			.where('isExist', '==', true)
			.get()

		return deductionsSnapshot.docs
			.map((doc) => doc.data())
			.filter((data) => data['attachment'])
			.map((data) => data['attachment'])
	} catch (error) {
		console.error(error)
    const errorInfo = {
			stringifiedError: error?.message || error,
			service: 'Documents',
			functionName: 'getDeductions',
		}
		make_API_call('post', '/errors/report', errorInfo)
		throw error
	}
}

async function getProfileDocs(employeeID) {
	try {
		const profileQuerySnapshot = await firebase
			.firestore()
			.collection(`EMPLOYEES`)
			.where('uid', '==', employeeID)
			.where('isExist', '==', true)
			.get()
		if (profileQuerySnapshot.size === 0) throw new Error('not-exists')
		const sectionsDocSnapshot = await firebase
			.firestore()
			.doc('WEB_BUILDER/employee-profile')
			.get()
		if (!sectionsDocSnapshot.exists) throw new Error('no-sections')

		const profile = profileQuerySnapshot.docs[0]?.data() || {}
		const sections = sectionsDocSnapshot.data()?.sections || {}
		const fileplaces = {}
		const docs = []

		// fetching all the filePlaces that will be dynamically added in employee profile
		Object.entries(sections).forEach(([key, value]) => {
			const { fields, access_key, entryType, sectionName } = value
			fileplaces[access_key] = fields
				.filter((field) => field.type === 'file')
				.map((item) => ({
					name: item.name,
					type: entryType,
					label: item.label,
					sectionName,
				}))
		})

		// filtering documents from the empProfile based on the filePlaces
		Object.entries(fileplaces).forEach(([key, value]) => {
			if (key in profile) {
				value.forEach((field) => {
					if (field.type === 'array') {
						profile[key].forEach((ele) => {
							docs.push({
								url: ele[field.name],
								fileName: field.label,
								category: field.sectionName,
							})
						})
					} else if (field.type === 'object') {
						docs.push({
							url: profile[key][field.name],
							fileName: field.label,
							category: field.sectionName,
						})
					}
				})
			}
		})
		return docs
	} catch (error) {
		console.error(error)
    const errorInfo = {
			stringifiedError: error?.message || error,
			service: 'Documents',
			functionName: 'getProfileDocs',
		}
		make_API_call('post', '/errors/report', errorInfo)
		throw error
	}
}

export const _load_employe_docs = (employeeID) => async (dispatch) => {
	try {
		dispatch(documentsReq())
		const placements = await getPlacementDocs(employeeID)
		const deductions = await getDeductions(employeeID)
		const profile = await getProfileDocs(employeeID)
		return dispatch(documentsSuccess({ placements, profile, deductions }))
	} catch (error) {
		console.error(error)
		errorMsg('Failed to load documents')
    const errorInfo = {
			stringifiedError: error?.message || error,
			service: 'Documents',
			functionName: '_load_employe_docs',
		}
		make_API_call('post', '/errors/report', errorInfo)
		return dispatch(documentsFailure(error))
	}
}
