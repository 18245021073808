import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  vendorsData,
  project_meta_info,
  unsubscribeListener,
} from "../../../middleware/projectDashboard"
import Presentation from "./Presentation"
import Loader from "../../../../../shared/circularSpinner"
import { isLoaded } from "react-redux-firebase"

function Container(props) {
  const [isLoading, setIsLoading] = useState(true)

  const {
    project,
    projectId,
    dashboardData,
    _get_vendors_data,
    _get_meta_info,
    _remove_listener,
  } = props

  useEffect(() => {
    const isLoaded = dashboardData.isLoaded && dashboardData.meta_info.isLoaded
    setIsLoading(!isLoaded)
  }, [JSON.stringify(dashboardData)])

  useEffect(() => {
    _get_vendors_data()
    _get_meta_info()
    return () => _remove_listener()
  }, [projectId])

  return isLoaded(project) && !isLoading ? (
    <div>
      <Presentation project={project} dashboardData={dashboardData} />
    </div>
  ) : (
    <Loader />
  )
}

const mapStateToProps = (state, ownProps) => {
  const { data } = state.firestore
  return {
    project: data.project,
    dashboardData: state.projects.projectDashboard,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    _get_vendors_data: () => {
      dispatch(vendorsData(ownProps.projectId))
    },
    _get_meta_info: () => {
      dispatch(project_meta_info(ownProps.projectId))
    },
    _remove_listener: () => {
      dispatch(unsubscribeListener())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
