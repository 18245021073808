import { defaultInstance } from "../../instances/default.instance";

/**
 * @param {Record} data
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const updateCompanyDetails = (data, signal) => {
  return defaultInstance({
    method: 'PUT',
    url: `/console/company-details`,
    data,
    signal,
  });
};

/**
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const getCompanyDetails = (signal) => {
  return defaultInstance({
    method: 'GET',
    url: `/console/company-details`,
    signal,
  });
};