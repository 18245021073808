import React, { useEffect } from 'react'
import Presentation from "./Presentation"
import { connect } from "react-redux"
import { loginByToken } from "../../middleware"
import Validations from '../../../../shared/validation'
import { useHistory } from "react-router-dom"

function Container(props) {
  const { login_by_token } = props
  const history = useHistory()

  useEffect(() => {
    const token = Validations.getQueryParameter("token")
    login_by_token(token, history)
  }, [])

  return (
    <div>
      <Presentation />
    </div>
  )
}


const mapStateToProps = (state, ownProps) => {
  console.log(state)
  return {
    auth: state.firebase.auth,
    companyLogo: state.console.companyDetails["images"],
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    login_by_token: (creds, history) => {
      dispatch(loginByToken(creds, history))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
