import React from "react"
import HomePage from "../Services/Wiki/components/Index"
import { Breadcrumbs } from "@material-ui/core"
import { Link } from "react-router-dom"

function WikiHomePage(props) {
	return (
    <div>
      <div>
        <HomePage {...props} />
      </div>
    </div>
  )
}

export default WikiHomePage
