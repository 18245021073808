import React from "react"
import LetterTemplates from "../Services/Console/components/LetterTemplates/Index"

function LetterTemplatesPage(props) {
  return (
    <div>
      <LetterTemplates />
    </div>
  )
}

export default LetterTemplatesPage
