import React, { useEffect } from 'react'
import Presentation from "./Presentation"
import { clientApprove } from "../../../middleware/invoiceList"
import validation from "../../../../../shared/validation"
import { connect } from "react-redux"

function Container(props) {
  const { _client_approve } = props
  const invoiceID = decodeURIComponent(validation.getQueryParameter("invoiceID"))
  useEffect(() => {
    const approvedBy = validation.getQueryParameter("approvedBy")
    if (window.confirm("Are you sure ?")) {
      _client_approve(approvedBy, invoiceID)
    }
  }, [])
  return (
    <div>
      <Presentation invoiceID={invoiceID} />
    </div>
  )
}


const mapStateToProps = (state) => ({
  state: state.invoice.createInvoice
})

const mapDispatchToProps = (dispatch) => ({
  _client_approve: (email, invoiceID) => dispatch(clientApprove(email, invoiceID))
})


export default connect(mapStateToProps, mapDispatchToProps)(Container)
