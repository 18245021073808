import React, { useContext, useEffect, useState } from "react";
import Presentation from "./Presentation";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import validation from "../../../../../../shared/validation";
import { useHistory } from "react-router-dom";
function Container(props) {
  const { modules, trackProjectTasksCount, id } = props;
  const tab = Number(validation.getQueryParameter("tab"));
  const [tabValue, setTabValue] = React.useState(tab);
  const history = useHistory();
  const handleTabChange = (event, newValue) => {
    const pathName = window.location.pathname;
    history.push(`${pathName}?tab=${newValue}`);
    setTabValue(newValue);
    // setTabValue(newValue)
  };

  const [badges, setBadges] = useState({
    InProgress: {
      count: 0,
    },
    Open: {
      count: 0,
    },
    totalCount: 0,
    Review: {
      count: 0,
    },
    Overdue: {
      count: 0,
    },
    Closed: {
      count: 0,
    },
    SubTasks: {
      count: 0,
    },
  });

  useEffect(() => {
    if (
      typeof trackProjectTasksCount === "object" &&
      trackProjectTasksCount.hasOwnProperty("tasks_tracker")
    ) {
      if (
        typeof trackProjectTasksCount.tasks_tracker === "object" &&
        trackProjectTasksCount.tasks_tracker.hasOwnProperty(id)
      ) {
        setBadges((prevState) => ({
          ...prevState,
          ...trackProjectTasksCount.tasks_tracker[id],
        }));
      }
      if (
        typeof trackProjectTasksCount.sub_tasks_tracker === "object" &&
        trackProjectTasksCount.sub_tasks_tracker.hasOwnProperty(id)
      ) {
        setBadges((prevState) => ({
          ...prevState,
          SubTasks: {
            count: trackProjectTasksCount.sub_tasks_tracker[id].subTaskCount,
          },
        }));
      }
    }
  }, [id, trackProjectTasksCount]);

  useEffect(() => {
    setTabValue(tab);
  }, [tab]);

  return (
    <div>
      <Presentation
        {...props}
        modules={modules}
        tabValue={tabValue}
        handleTabChange={handleTabChange}
        trackProjectTasksCount={badges}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { data } = state.firestore;
  console.log(data);
  return {
    modules: state.employee.employeeModules.accessModules,
    loggedInEmployee: state.firebase.auth.uid,
    trackProjectTasksCount: data.trackProjectTasksCount,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "ID_TRACKER",
        doc: "task_management",
        storeAs: "trackProjectTasksCount",
      },
    ];
  })
)(Container);
