import React from "react"
import SunEditor from "suneditor-react"
import { TextField, MenuItem, Typography, Tooltip, Grid } from "@material-ui/core"
import { Delete as DeleteIcon } from "@material-ui/icons"
import { Button } from "react-bootstrap"

export default function Presentation(props) {
  const {
    handleContent,
    handleChange,
    handleCreate,
    cancelUpdate,
    handleUpdate,
    name,
    type,
    handleDelete,
    helperText,
    content,
    state,
    draggableLabels,
    letterTypes,
  } = props
  const isUpdating = state.updating
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <form onSubmit={isUpdating ? handleUpdate : handleCreate}>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
              <TextField
                label="Enter template name"
                onChange={handleChange}
                size="small"
                value={name}
                variant="outlined"
                name="name"
                required
              />
              <TextField
                select
                label="Choose Type"
                style={{ minWidth: 250 }}
                value={type}
                name="type"
                required
                onChange={handleChange}
              >
                {letterTypes.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            {helperText.length ? (
              <Typography color="error">Check these labels with reference labels</Typography>
            ) : null}
            <div>
              {helperText.map((item) => (
                <div>
                  <Typography color="error">
                    <li>{item}</li>
                  </Typography>
                </div>
              ))}
            </div>
            <SunEditor
              onChange={handleContent}
              setContents={content}
              setOptions={{
                buttonList: [
                  [
                    "undo",
                    "redo",
                    "font",
                    "fontSize",
                    "formatBlock",
                    "paragraphStyle",
                    "blockquote",
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                    "fontColor",
                    "hiliteColor",
                    "textStyle",
                    "removeFormat",
                    "outdent",
                    "indent",
                    "align",
                    "horizontalRule",
                    "list",
                    "lineHeight",
                    "table",
                    "link",
                    "image",
                    "video",
                    "fullScreen",
                    "showBlocks",
                    "codeView",
                    "preview",
                    "print",
                    "save",
                    "template",
                  ],
                ],
                minHeight: 500,
              }}
            />
            <div className="text-center mt-4">
              {isUpdating ? (
                <span>
                  {helperText.length ? (
                    <Button className="btn btn-success px-6 btn-md text-high " type="button" disabled>
                      Update letter
                    </Button>
                  ) : (
                    <Button className="btn btn-success px-6 btn-md text-high " type="submit">
                      Update letter
                    </Button>
                  )}{" "}
                  <Button className="btn btn-success px-6 btn-md text-high " onClick={cancelUpdate}>
                    Cancel
                  </Button>
                </span>
              ) : helperText.length ? (
                <Button className="btn btn-success px-6 btn-md text-high "  type="button" disabled>
                  Create new template
                </Button>
              ) : (
                <Button className="btn btn-success px-6 btn-md text-high " type="submit" onClick={handleCreate}>
                  Create new template
                </Button>
              )}
            </div>
            {isUpdating ? (
              <div className="text-right">
                <Tooltip title="Delete this template">
                  <Button
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={handleDelete}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </div>
            ) : null}
          </form>
          <Typography className="mt-2">
            <h4>
              <u>Note:</u>
            </h4>{" "}
            Use the listed labels with '~Label~ ' format. (Use space after the Label)
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <div className="mr-4"></div>
          <div>
            <Typography>
              <h4>
                <u>Labels:</u>
              </h4>
            </Typography>
            <Grid container>
              {draggableLabels.map((item) => (
                <Grid item xs={12}>
                  <Typography>{item}</Typography>
                </Grid>
              ))}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
