import React, { useEffect, useState } from 'react'
import { Formik, Form } from "formik";
import { Row,Col,Button ,Modal } from 'react-bootstrap';
import { useInvoiceListContext } from "../context";
import SunEditor from "suneditor-react"
import WarningIcon from "@material-ui/icons/Warning";
import { Tooltip } from '@material-ui/core';
import parser from 'html-react-parser'
import { useDialog } from '../../../../../hooks/useDialog';
import AppModalHeader from '../../../../../shared/components/Modals/AppModalHeader';
import AppModalFooter from '../../../../../shared/components/Modals/AppModalFooter';
import validation from "../../../../../shared/validation"
import { useQueryClient} from "@tanstack/react-query";


function EditVoidInvoice(props) {
  const { invoiceID, voidReason: theVoidReason,clientID="", employeeID } = props
  const { services } = useInvoiceListContext()
  const [showSpinner, setShowSpinner] = useState(false);
  const [voidReason, setVoidReason] = useState('')
  const {open,handleOpen,handleClose}=useDialog()

  const queryClient = useQueryClient();
  const tab = validation.getQueryParameter("tab") || "0";
  const [tabValue, setTabValue] = useState(tab)
  const tabValueToTypeMap = {
    "1": "due",
    "2": "generated",
    "3": "sent",
    "4": "void",
    "5": "paid",
    "6": "all"
  };
  let type = tabValueToTypeMap[tabValue];

  useEffect(() => {
    setVoidReason(theVoidReason)
  }, [invoiceID, theVoidReason])

  const handleYes = async () => {
    try {
      setShowSpinner(true)
      handleClose()
      const isUpdatingVoidReason = 1
      await services.makeInvoiceVoid(invoiceID, voidReason, isUpdatingVoidReason)
      setShowSpinner(false)
      await queryClient.invalidateQueries(["getInvoices",type,{clientID, employeeID}]);
    } catch (error) {
      setShowSpinner(false)
    }
  }

  return (
    <div>
      
      <Tooltip
        placement="top"
        title={<div style={{ fontSize: "13px" }}>{parser(theVoidReason)}</div>}
      >
        <a onClick={handleOpen} style={{color:'black'}}>
            <span style={{ fontSize: '12px' }} >{parser(theVoidReason)}</span>
        </a>
       
      </Tooltip>

      <Modal show={open} onHide={handleClose} centered size="md"  style={{ border: '2px solid gray' }} className='followup-invoice-modal'>
				<Formik
					 initialValues={voidReason}
					 onSubmit={handleYes}
				>
					{(formik) => (
						<Form>
							<AppModalHeader title="Edit Void Invoice"/>
              <Modal.Body>
                <Row>
                <Col md={12}>
                  <SunEditor
                      placeholder="Remarks*"
                      onChange={(content) => setVoidReason(content)}
                      setContents={voidReason}
                      height={200}
                      setOptions={{
                        buttonList: [
                          [
                            "undo",
                            "redo",
                            "blockquote",
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                            "outdent",
                            "indent",
                            "list",
                            "table",
                            "link",
                            "image",
                            "fullScreen",
                            "showBlocks",
                            "print",
                            "save",
                          ],
                        ],
                      }}
                  />
                </Col>
              </Row>
							</Modal.Body>
							<AppModalFooter
                saveButtonText='Proceed'
                saveButtonDisabled={voidReason.length < 1}
								onClickCancel={handleClose}
							/>
						</Form>
					)}
				</Formik>
			</Modal> 
    </div>
  )
}

export default EditVoidInvoice