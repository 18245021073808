import axios from "axios"
import {
  loadInvitationEmailRequest,
  loadInvitationEmailSuccess,
  loadInvitationEmailFailure,
  setListener,
  setStateAction,
  loadInvitatedEmpInfoRequest,
  loadInvitatedEmpInfoFailure,
  loadInvitatedEmpInfoSuccess,
} from "../actions/actionCreators"
import {
  waitingMsg,
  stopWaitMsg,
  errorMsg,
  successMsg,
} from "../../../shared/SnackBars/index"
import { JSutils } from "../../../shared/JSutils"
import make_API_call from "../../../providers/REST_API"
import { configuration } from "../../../config/companyConfig"
export const setState = (obj) => (dispatch) => {
  return dispatch(setStateAction(obj))
}

export const loadInvitationEmail = (payload) => (
  dispatch,
  getState,
  { getFirebase }
) => {
  dispatch(loadInvitationEmailRequest())
  let subscribe = ""
  make_API_call(
    "post",
    `${configuration.REST_api}/auth/validateinvitationtoken`,
    payload
  )
    .then((data) => {
      console.log(data, "token")
      subscribe = data
      dispatch(loadInvitationEmailSuccess(data))
    })
    .catch((err) => {
      console.log(err, "token")
      subscribe = err
      dispatch(loadInvitationEmailFailure(err))
    })

  dispatch(setListener(subscribe, "invitationTokenEmail"))
}
export const loadInvitedEmployeeInfo = (payload) => (
  dispatch,
  getState,
  { getFirebase }
) => {
  dispatch(loadInvitatedEmpInfoRequest())
  const { email } = payload
  console.log(email)
  getFirebase()
    .firestore()
    .collection("EMPLOYEES")
    .where("email","==",email)
    .where("isExist","==",true)
    .get()
    .then((snap) => {
      const data = snap.docs.map((d)=>d.data())?.[0] || {}
      return dispatch(loadInvitatedEmpInfoSuccess(data))
    })
    .catch((err)=>{
      console.error(err)
      const msg = "Failed to load Employee"
      errorMsg(msg)
      return dispatch(loadInvitatedEmpInfoFailure(msg))
    })

  // dispatch(setListener(subscribe, "invitationTokenEmpInfo"))
}
