import ACTION from "../actions/actionTypes";
import initState from "./newClientState";

export function newClientReducer(state = initState, action) {
  switch (action.type) {
    case ACTION.SET_NEW_CLIENT_BUSINESS_INFORMATION:
      return {
        ...state,
        businessInformation: action.payload,
      };

    case ACTION.SET_NEW_CLIENT_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
      };

    case ACTION.SET_NEW_CLIENT_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
      };

    case ACTION.SET_NEW_CLIENT_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };

    case ACTION.SET_TO_INITIAL_STATE:
      return {
        businessInformation: {
          businessName: "",
          contactNumber: "",
          website: "",
          jobTerminationNotice: "",
          category: "",
          businessDisplayName: "",
          email: "",
          federalId: "",
          netTerms: "",
          fax: "",
          logo: "",
          permanentPlacement: {
            netTerms: "",
            commissionType: "",
            commission: "",
          },
          invoiceLocation: {
            line1: "",
            line2: "",
            city: "",
            state: "",
            country: "",
            zipCode: "",
          },
          status: false,
          countries: [],
          states: [],
          helperText: "",
          isChecking: false,
          invoiceLocationId: 0,
          isPrimeVendor:false
        },
        contacts: {
          contactsList: [
            {
              key: 0,
              id: 0,
              representativeName: "",
              jobTitle: "",
              contactType: "",
              gender: "",
              email: "",
              mobile: "",
              workPhone: "",
              homePhone: "",
              line1: "",
              line2: "",
              country: "",
              state_name: "",
              city: "",
              zip: "",
              workPhoneExtension: "",
              isDraft: true,
            },
          ],
          status: false,
        },
        accounts: {
          jobTitle: "",
          email: "",
          homePhone: "",
          services: "",
          firstName: "",
          middleName: "",
          lastName: "",
          phoneNumber: "",
          mobileNumber: "",
          to: [],
          cc: [],
          bcc: [],
          helperText: {
            to: "",
            cc: "",
            bcc: "",
          },
          status: "",
          discountDetails: [
            {
              name: "",
              value: 0,
              type: "",
            },
          ],
          discountStatus: false,
        },
        locations: {
          locationsList: [
            {
              key: 0,
              id: 0,
              locationsline1: "",
              locationsline2: "",
              locationscountry: "",
              locationsstate_name: "",
              locationscity: "",
              locationszip: "",
              isDraft: true,
            },
          ],
          status: false,
          invoiceLocationId: null,
        },
      };

    default:
      return state;
  }
}
