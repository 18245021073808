import React,{useState,useEffect} from "react";
import MaterialTable from "material-table";
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import MetaInfo from "../../../../shared/getMetaInfo";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import SunEditor from "suneditor-react";
import useCustomDialog from "../../../../hooks/useCustomDialog";
function HomePage(props) {
  const metaInfo = new MetaInfo();

  const { onHoldAccount, ExpiredListData, onEnableAccount, employeeId,onReview } = props;
  const [open, setOpen] = useState(false);
  const [empId, setEmpId] = useState()
  const { renderContent, closeDialog, openDialog, isDialogOpen } =
    useCustomDialog();

  const initState = {
    SuspensionReview: '',
    ReviewDate:''
  };

  const handleClickOpen = (id) => {
    setEmpId(id)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [state, setState] = useState(initState);

  const handleChange = (obj) => {
        setState((prevState) => ({
            ...prevState,
            ...obj,
        }));
    };
  
  const ReviewEmployee = () => {
    setOpen(false);
    onReview(state.SuspensionReview,empId,state.ReviewDate)
  }

  const columns = [
    {
      title: "Employee Name",
      field: "name",
      dataType: "String",
      render: (rowData) => {
        return (
          <Link
            style={{ color: rowData.isSuspended ? "red" : "blue"  }}
            to={`/console/employees/${rowData.Id}`}
          >
            {rowData.name}
          </Link>
        )
      },
    },
    {
      title: "Expired Items",
      field: "expireddocs",
      dataType: "String",
      render: (rowData) => {
        if (!rowData.isSuspended) {
          return (
            <Link style={{ color: "blue" }} to={"/expiredList/" + rowData.Id}>
              {rowData.expireddocs} Expired Document(s)
            </Link>
          );
        }
        return null;
      },
    },
    {
      title: "Under Review Items",
      field: "underReviewCount",
      render: (rowData) => {
        if (!rowData.isSuspended) {
          return (
            <Link style={{ color: "blue" }} to={"/expiredList/" + rowData.Id}>
              {rowData.underReviewCount} Under Review Document(s)
            </Link>
          );
        }
        return null;
      },
    },
    {
      title: "More",
      field: "",
      dataType: "",
      render: (rowData) => {
        return (
          <span className="d-flex">
            <span
              key={rowData.email}
              className="d-flex"
              style={{ marginTop: "8px" }}
            >
              {rowData.isSuspended ? (
                <Button variant="outlined" color="secondary" onClick={() => {handleClickOpen(rowData.Id)}}>
                  Review Account
                </Button>
              ) : rowData.status === "onHold" ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to enable the account of " +
                          rowData.name +
                          "  ?"
                      )
                    )
                      onEnableAccount(rowData);
                  }}
                >
                  Enable Account
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to Hold the account of " +
                          rowData.name +
                          "  ?"
                      )
                    )
                      onHoldAccount(rowData);
                  }}
                >
                  Hold Account
                </Button>
              )}
            </span>
          </span>
        );
      },
    },
  ];

  
  //Review Dialog Box
  const dialogContent = () => {
    return (
      <div>
          <Typography style={{fontSize:15,marginBottom:8}}>
              There are exit employees, review them now
             </Typography>
        {
          tableData.map((res) => {
            if(res.isSuspended)
            return (
              <div>    
             <Typography style={{color:'red',fontWeight:'bold'}}>
              {res.name}
             </Typography>
             </div>
            )
          })
        }
        <DialogActions>
          <Button onClick={closeDialog} color="primary" >
            OK
          </Button>
        </DialogActions>
      </div>
    )
  }

  const tableData = [];
  ExpiredListData.forEach((data) => {
    tableData.push({
      Id: data.id,
      name: metaInfo.emailToName(data.id),
      isSuspended: data.isFromSuspended,
      expireddocs: data.isFromSuspended
        ? null
        : data.count,
      underReviewCount: !data.isFromSuspended
        ? data.underReviewCount
        : null,
      status: metaInfo.getStatus(data.id),
    });
  });

  useEffect(() => {
    if(ExpiredListData) {
      const suspendedEmployeeExist = ExpiredListData.some((res) => res.isFromSuspended)
      if(suspendedEmployeeExist){
        openDialog()
      }
    }
  
  }, [ExpiredListData])
  
  return (
    <div>
       {renderContent({
        title: "Review Exit Employee",
        dialogContent: dialogContent(),
        hideButton: true,
        maxWidth:"sm"
      })}
      <MaterialTable
        title=""
        columns={columns}
        data={tableData}
        style={{ width: "100%" }}
        options={{
          rowStyle: {
            height: "1em",
          },
          pageSize: 10,
          paginationPosition: "top",
          filtering: true,
          columnsButton: true,
          selection: true,
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="close-placement-dialog-title"
        style={{ overflow: "hidden" }}
      >
        <DialogTitle id="close-placement-dialog-title">
          Review for employee 
        </DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        name="review date"
                        size="small"
                        required
                        fullWidth
                        value={state.ReviewDate ? state.ReviewDate : null}
                        label="Review Date"
                        format="MM/dd/yyyy"
                        onChange={(date) => {
                          handleChange({ReviewDate : date});
                        }}
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                        style={{ marginBottom: "10px" }}
                    />
                </MuiPickersUtilsProvider>
          <SunEditor
            placeholder="Review Note"
            setContents={state.SuspensionReview}
            onChange={(data) => handleChange({ SuspensionReview: data })}
            height={200}
            setOptions={{
              buttonList: [
                [
                  "undo",
                  "redo",
                  "font",
                  "fontSize",
                  "formatBlock",
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                  "fontColor",
                  "hiliteColor",
                  "removeFormat",
                  "outdent",
                  "indent",
                  "align",
                  "horizontalRule",
                  "list",
                  "lineHeight",
                  "table",
                  "link",
                  "image",
                  "video",
                  "showBlocks",
                  "codeView",
                ],
              ],
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button
            onClick={ReviewEmployee}
            variant="contained"
            color="secondary"
            disabled={!state.SuspensionReview.length}
          >
            Review
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default HomePage;
