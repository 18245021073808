import React,{useState} from 'react'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, Typography, DialogContent, Button, DialogActions, IconButton, CircularProgress, FormHelperText } from "@material-ui/core"
import { withStyles } from '@material-ui/core/styles';
import { useNewInvoiceContext } from "./context";
import { useHistory } from "react-router-dom"
import styles from "../../styles/dialogStyles"

function UpdateButton() {
  const { state, services} = useNewInvoiceContext()
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onUpdate = () => {
    handleClose();
    services.updateInvoice(history)
  }


  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  return (
    <div className="text-center" >
      <Button 
        disabled={state.isDisableButton || state.isNewInvoiceUpdating} 
        onClick={handleClickOpen} 
        size="large" 
        color="secondary" 
        variant="contained" >
        {state.isNewInvoiceUpdating ? "Updating" : "Update"}
      </Button>
      <Dialog 
        onClose={handleClose} 
        open={open}
        aria-labelledby="customized-dialog-title">
        <DialogTitle 
          onClose={handleClose}
          id="customized-dialog-title">
          Are you sure?
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            You want to update the invoice 
            <span className="custom-chip chip-danger" >
              {`${state.invoiceID}`}
            </span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={onUpdate} 
            disabled={state.isDisableButton} 
            variant="contained" 
            color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default UpdateButton