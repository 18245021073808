import { Backdrop, Button, CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react'
import useCustomDialog from "../../hooks/useCustomDialog"
import { AnnouncementsContextProvider, useAnnouncementContext } from "./context"
import parser from "html-react-parser";

function CheckAnnouncementComponent() {
    const { state,services } = useAnnouncementContext();
    const { renderContent, closeDialog, openDialog } = useCustomDialog();


    useEffect(() => {
        services.getAnnouncement()
    }, [])

    useEffect(() => {
        if(Object.keys(state.announcement).length > 0){
            openDialog()
        }
    },[JSON.stringify(state.announcement)])

    const handleAcknowledge = () => {
        services.acknowledgeAnnouncement(closeDialog,state?.announcement?.id)
    }

    const handleDecline = () => {
        services.declineAnnouncement(closeDialog,state?.announcement?.id)
    }


    const dialogContent = () => {
        return (
            <div>
                <p>{state?.announcement?.body ? parser(state?.announcement?.body) : ""}</p>
            </div>
        );
    };
    
    const dialogActions = () => {
        return (
            <div>
                <div style={{textAlign:"end"}}>
                    <Button onClick={handleDecline} variant={"contained"} color="secondary" style={{marginRight:"10px"}}>Decline</Button>
                    <Button onClick={handleAcknowledge} variant={"contained"} color="primary">Acknowledge</Button>
                </div>
            </div>
        );
    };

  return (
    <div>
        <Backdrop style={{ color: "#fff", zIndex: 4000 }} open={state.showLoader}>
            <CircularProgress color="inherit" />
        </Backdrop>
        {renderContent({
        title: state?.announcement?.subject ? parser(state?.announcement?.subject) : "You have a new announcement!",
        dialogContent: dialogContent(),
        hideButton: true,
        hideDefaultClose: false,
        maxWidth : "sm",
        disableEscapeKeyDown : false,
        disableBackdropClick : false,
        dialogActions:dialogActions()
        })}
    </div>
  )
}
const CheckAnnouncement = (props) => <AnnouncementsContextProvider><CheckAnnouncementComponent {...props} /> </AnnouncementsContextProvider>
export default CheckAnnouncement