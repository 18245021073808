import ACTION from "../actions/actionTypes"
import clientDocumentsState from "./clientDocumentsState"

export default function clientDocumentsReducer(state = clientDocumentsState, { type, payload }) {
  switch (type) {
    case ACTION.GET_CLIENT_DOCUMENTS_REQ:
      return {
        ...clientDocumentsState,
      }

    case ACTION.GET_CLIENT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        error: null,
        data: payload,
      }

    case ACTION.GET_CLIENT_DOCUMENTS_FAILURE:
      return {
        ...state,
        isLoaded: true,
        error: payload,
        data: [],
      }

    default:
      return state
  }
}
