import React, { useEffect, useState } from "react"
import { isLoaded } from "react-redux-firebase"
import Presentation from "./Presentation"
import { connect } from "react-redux"
import { editTasks } from "../../../../middleware"

function Container(props) {
  console.log(props.taskLabels)
  const { task, taskLabels } = props
  const [state, setState] = React.useState({
    task: task,
    title: task.title,
    type: task.type,
    status: task.status,
    startdate: task.startdate,
    enddate: task.enddate,
    priority: task.priority,
    labels: taskLabels,
    assignee: task.assignee,
    description: task.description,
    projectAssignee: [],
    setReminder: task.setReminder,
    reminderDate: task.reminderDate,
  })
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      labels: taskLabels,
    }))
  }, [task])
  const [description, setDescription] = useState("")
  const handleChange = (key, value) => {
    if (key === "setReminder" && value === true) {
      setState({
        ...state,
        status: "Closed",
        setReminder: true,
      })
    } else if (key === "setReminder" && value === false) {
      setState({
        ...state,
        status: "",
        setReminder: false,
      })
    } else if (key === "reminderDate" && !isNaN(Date.parse(value))) {
      setState({
        ...state,
        reminderDate: new Date(value).toISOString(),
      })
    } else if (key === "reminderDate" && isNaN(Date.parse(value))) {
      setState({
        ...state,
        reminderDate: "",
      })
    } else if (key !== "reminderDate")
      setState({
        ...state,
        [key]: value,
      })
  }
  const handleDescription = (key, value) => {
    setDescription({
      [key]: value,
    })
  }
  const handleDateChange = (key, value) => {
    if (!isNaN(Date.parse(value)))
      setState({
        ...state,
        [key]: value.toISOString(),
      })
  }

  const handleUpdate = (e, handleClose) => {
    console.log(state)
    e.preventDefault()
    const {
      title,
      type,
      status,
      startdate,
      enddate,
      priority,
      labels,
      assignee,
      setReminder,
      reminderDate,
    } = state
    console.log("2", labels)
    const { editTasks } = props
    let data = {
      title,
      type,
      status,
      startdate: new Date(startdate).setHours(0, 0, 0, 0),
      enddate: new Date(enddate).setHours(0, 0, 0, 0),
      priority,
      labels: labels.map((e) => e.id),
      assignee,
      description: description ? Object.values(description)[0] : "",
      setReminder,
      reminderDate,
      taskId: props.task.id,
      projectId: props.project.id,
    }
    console.log("dataw2", data)
    editTasks(data, handleClose)
  }
  // const clearState = (e) => {
  //   setState({
  //     ...state,
  //     title: "",
  //     type: "",
  //     status: "",
  //     startdate: "",
  //     enddate: "",
  //     priority: "",
  //     label: "",
  //     assignee: [],
  //     description: "",
  //     projectAssignee: [],
  //     isCreated: false,
  //     setReminder: false,
  //     reminderDate: "",
  //   })
  // }

  const handleDropdown = (key, value) => {
    console.log(value)
    setState({
      ...state,
      [key]: value,
    })
  }
  console.log(state)

  if (isLoaded(props.task, props.project, props.labels, props.taskLabels))
    console.log(state.labels, "cont", props.taskLabels)
  return (
    <div>
      <Presentation
        {...props}
        // errCount={errCount}
        state={state}
        handleUpdate={handleUpdate}
        handleDateChange={handleDateChange}
        handleChange={handleChange}
        handleDropdown={handleDropdown}
        handleDescription={handleDescription}
      />
    </div>
  )
  return <p>Loading....</p>
}
const mapStateToProps = (state, ownProps) => {
  const task = ownProps.task
  const project = ownProps.project
  let assignees =
    project && Object.values(project.Users).map((user) => user.uid)
  let labels = Object.values(project.labels).filter((e) => e.isExist)

  let taskLabels = task.labels
    ? labels.filter((item) => task.labels.includes(item.id))
    : []
  console.log(taskLabels)
  return {
    task: task,
    project: project,
    assignees: assignees,
    labels: labels,
    taskLabels: taskLabels,
    names: state.employee.employeeList.metaInfoofEmployees.data
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    editTasks: (payload, handleClose) => {
      dispatch(editTasks(payload, handleClose))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Container)
