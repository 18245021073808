import React from "react";
import { Row, Col, Card, Badge } from "react-bootstrap";
import Chart from "react-apexcharts";

const Graphs = (props) => {
const { ExpensesRemainingData } = props;
const mealsAmount =ExpensesRemainingData?.mealsAmount ;
const hotelAmount =ExpensesRemainingData?.hotelAmount ;
const travelAmount =ExpensesRemainingData?.travelAmount;
const fuelAmount = ExpensesRemainingData?.fuelAmount;
const parkingAmount =ExpensesRemainingData?.parkingAmount;
  console.log("ExpensesRemainingData", ExpensesRemainingData);
const chartOptions2= {
    labels: ["Meals", "Hotel", "Travel", "Fuel", "Parking"],
    colors: [ 
            "#027D8D",
            "#6B778C",
            '#95D7AE', 
            '#2C9273',
            "#FFE380",
    ],
    Border: [
         '3px solid' ,'#FFFFFF'
    ]
  }
 const series4= [
  mealsAmount,
  hotelAmount,
  travelAmount,
  fuelAmount,
  parkingAmount
 ]


return(
<Col lg={6}>
<Card>
    <Card.Body>

        <h5 className="card-title mb-0 header-title"><center>Expenses List</center></h5>
        <div className="stateborder mb-4"></div>
        <Chart options={chartOptions2} series={series4} type='pie'/>
        
    </Card.Body>
</Card>
</Col>
)
}

export default Graphs;
