import React, { useEffect } from "react";
import Notes from "../../../../shared/components/Notes";
import { useBulkPaymentsContext } from "./context";
import MetaInfo from "../../../../shared/getMetaInfo";

function PaymentNotes() {
  const { state, stateSetters } = useBulkPaymentsContext();
  const metaInfo = new MetaInfo();

  const handleExcuteNotesFunctions = async (paymentNotes) => {
    stateSetters.setPaymentNotes(paymentNotes);
  };

  return (
    <Notes notesName = {metaInfo.clientIdToName(state?.selectedClientId)} notes = {state?.paymentNotes} handleExcuteNotesFunctions = {handleExcuteNotesFunctions}/>
  );
}

export default PaymentNotes