import React, { useEffect } from "react";
import {
  // makeStyles,
  TextField,
  // Grid,
  MenuItem,
  Button,
  Chip,
  CircularProgress,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import SunEditor from "suneditor-react";
import MetaInfo from "../../../../../shared/getMetaInfo";
import validate from "../../../../../shared/validation";
import NumberFormat from "react-number-format";
import { Autocomplete } from "@material-ui/lab";
// import { DataContext } from "../../../../../contexts/data"
// import { FcCheckmark } from "react-icons/fc"
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PlacementDetails from "../PlacementDetails";
import { GoFileSymlinkFile } from "react-icons/go";
import { CustomField } from "../../../../../shared/components/formFields";

// const useStyles = makeStyles((theme) => ({
//   select: {
//     minWidth: "100%",
//   },
// }))

function CustomCurrencyFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

function Presentation(props) {
  const {
    handleChange,
    expenseActionsCallback,
    handleAdd,
    actionType,
    handleUpdate,
    state,
    addDescription,
    handleAddDescription,
    employeeId,
    employeePlacements,
    handlePlacement,
    placementID,
    expenseSettings,
    ExpenseDoc,
  } = props;
  console.log(placementID);
  // const [state] = useContext(DataContext)
  // const validate = new Validations()
  // const placementList = []
  // const employee = employeeId
  const obj = new MetaInfo();

  const expenseTypes = ["Travel", "Parking", "Hotel", "Meals", "Fuel"];
  const placements = employeePlacements;
  console.log("Placements",placements);
  let reqFields = [];
  if (
    state.expenseType === "" ||
    ["Travel", "Parking", "Hotel"].includes(state.expenseType)
  ) {
    reqFields = [
      "spentDate",
      "expenseType",
      "amount",
      "receipt",
      "description",
      "expenseDoc",
      "vendor",
      "departureDate",
      "returnDate",
      // "ExpenseDoc.expenseDoc",
    ];
  } else {
    reqFields = [
      "spentDate",
      "expenseType",
      "amount",
      "receipt",
      "description",
      "expenseDoc",
      "vendor",
      // "ExpenseDoc.expenseDoc",
    ];
  }

  const areReqFilled = reqFields.every((item) => {
    if (
      item === "spentDate" ||
      item === "departureDate" ||
      item === "returnDate"
    )
      return !isNaN(Date.parse(props.state[item]));

    return props.state[item] !== "" && placementID;
  });
  // const minDate = (placementID !== ""
  //   ? new Date(
  //     placements.filter(
  //       (item) => item.placementID === placementID
  //     )[0].startDate
  //   )
  //   : new Date()) &&
  //   (!["Meals", "Fuel"].includes(state.expenseType)
  //     ? state.departureDate
  //       ? state.departureDate
  //       : null
  //     : null)

  // const maxDate = (placementID !== ""
  //   ? new Date(
  //     placements.filter(
  //       (item) => item.placementID === placementID
  //     )[0].endDate
  //   )
  //   : new Date()) &&
  //   (!["Meals", "Fuel"].includes(state.expenseType)
  //     ? state.returnDate
  //       ? state.returnDate
  //       : null
  //     : null)

  console.log(ExpenseDoc.isUploading, !areReqFilled);
  console.log({placements,placementID});
  return (
    <div className="custom-card">
      <div>
        {actionType === "edit" ? (
          <TextField
            select
            label={"Select Placement"}
            id="Expenses-SelectPlacement-state-placementID"
            required
            fullWidth
            value={placementID}
            size="small"
          >
            <MenuItem value={placementID}>
              {obj.emailToName(employeeId) +
                "_" +
                obj.clientIdToName(state.clientId) +
                "_" +
                placementID}
            </MenuItem>
          </TextField>
        ) : (
          <Autocomplete
            options={placements}
            renderOption={(option) => {
              return (
                <span>
                  {obj.emailToName(option.employeeID) +
                    "_" +
                    obj.clientIdToName(option.clientId) +
                    "_" +
                    option.id}
                </span>
              )
            }}
            getOptionLabel={(option) => {
              return (
                obj.emailToName(option.employeeID) +
                "_" +
                obj.clientIdToName(option.clientId) +
                "_" +
                option.placementID
              )
            }}
            style={{ width: 500 }}
            onChange={(e, v) => {
              if (v) {
                handlePlacement("placementID", v)
              }
            }}
            renderInput={(params) => (
              <TextField
                required
                size="small"
                {...params}
                label="Select Placement"
              />
            )}
          />
        )}
      </div>
      <div className="mt-2">
        {placementID ? (
          <PlacementDetails
            placementID={placementID}
            placements={placements.filter((e) => e.placementID === placementID )}
            expenseSettings={expenseSettings}
            employeeId={employeeId}
            clientId={placements
              .filter((e) => e.placementID === placementID)
              .map((e) => e.clientId)}
          />
        ) : null}
      </div>
      <hr />
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="row">
          <div className="col-sm-2">
            <TextField
              select
              variant="outlined"
              required={reqFields.includes("expenseType")}
              label="Expense Type"
              id="Expense-Type"
              size="small"
              value={state.expenseType}
              fullWidth
              disabled={placementID === ""}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              name="expenseType"
            >
              {expenseTypes.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>

          {state.expenseType ? (
            <>
              <div className="col-sm-2">
                <CustomField
                  label="Description"
                  type="text"
                  required={reqFields.includes("description")}
                  size="small"
                  value={state.description}
                  fullWidth
                  id="state-description"
                  disabled={placementID === ""}
                  variant="outlined"
                  handleChange={(e) =>
                    handleChange(e.target.name, e.target.value)
                  }
                  name="description"
                />
              </div>
              <div className="col-sm-2">
                <CustomField
                  label="Amount"
                  size="small"
                  type="number"
                  value={state.amount}
                  required={reqFields.includes("amount")}
                  fullWidth
                  id="state-amount"
                  disabled={placementID === ""}
                  variant="outlined"
                  handleChange={(e) =>
                    handleChange(e.target.name, e.target.value)
                  }
                  name="amount"
                  InputProps={{
                    inputComponent: CustomCurrencyFormat,
                  }}
                />
              </div>
              <div className="col-sm-2">
                <input
                  className="d-none"
                  id="contained-button-file-ehfile"
                  multiple
                  type="file"
                  onChange={(e) =>
                    handleChange(e.target.name, e.target.files[0])
                  }
                  name="expenseDoc"
                  disabled={state.expenseType === "" || placementID === ""}
                />

                <div>
                  <label htmlFor="contained-button-file-ehfile">
                    <Button
                      // disabled={state.expenseType === "" || placementID === ""}
                      fullWidth
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload Document
                    </Button>
                  </label>
                  {ExpenseDoc.attachmentDetails.publicURL !== "" ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={ExpenseDoc.attachmentDetails.publicURL}
                    >
                      {obj.emailToName(employeeId) +
                        "_" +
                        obj.clientIdToName(state.clientId) +
                        "_" +
                        placementID +
                        "_" +
                        state.expenseType}
                    </a>
                  ) : ExpenseDoc.isUploading === false ? (
                    <p>No file choosen</p>
                  ) : (
                    <CircularProgress />
                  )}{" "}
                </div>
              </div>
            </>
          ) : null}
        </div>

        <div className="row">
          {state.expenseType ? (
            <>
              <div className="col-sm-2">
                <CustomField
                  label="Vendor"
                  size="small"
                  type="name"
                  value={state.vendor}
                  required={reqFields.includes("vendor")}
                  fullWidth
                  id="state-amount"
                  disabled={placementID === ""}
                  variant="outlined"
                  handleChange={(e) =>
                    handleChange(e.target.name, e.target.value)
                  }
                  name="vendor"
                />
              </div>
              <div className="col-sm-2 mt-2">
                <CustomField
                  label="Receipt"
                  size="small"
                  type="text"
                  required={reqFields.includes("receipt")}
                  value={state.receipt}
                  fullWidth
                  id="state-receipt"
                  disabled={placementID === ""}
                  variant="outlined"
                  handleChange={(e) =>
                    handleChange(e.target.name, e.target.value)
                  }
                  name="receipt"
                />
              </div>
              {!["Meals", "Fuel"].includes(state.expenseType) ? (
                <>
                  <div className="col-sm-2">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className="d-flex">
                        <KeyboardDatePicker
                          required={reqFields.includes("departureDate")}
                          disabled={placementID === ""}
                          size="small"
                          style={{ marginTop: "4px", marginRight: "10px" }}
                          label="Departure Date"
                          format="MM/dd/yyyy"
                          minDate={
                            placementID
                              ? placements.filter(
                                  (e) => e.placementID === placementID
                                )[0]?.startDate
                              : new Date()
                          }
                          maxDate={
                            placementID
                              ? placements.filter(
                                  (e) => e.placementID === placementID
                                )[0]?.endDate
                              : new Date()
                          }
                          value={
                            state.departureDate ? state.departureDate : null
                          }
                          onChange={(date) =>
                            handleChange("departureDate", date)
                          }
                          name="departureDate"
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </div>
                    </MuiPickersUtilsProvider>
                  </div>

                  <div className="col-sm-2">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className="d-flex">
                        <KeyboardDatePicker
                          required={reqFields.includes("returnDate")}
                          disabled={placementID === ""}
                          minDate={state.departureDate}
                          maxDate={
                            placementID
                              ? placements.filter(
                                  (e) => e.placementID === placementID
                                )[0]?.endDate
                              : new Date()
                          }
                          label="Return date"
                          format="MM/dd/yyyy"
                          value={state.returnDate ? state.returnDate : null}
                          onChange={(date) => handleChange("returnDate", date)}
                          name="returnDate"
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </div>
                    </MuiPickersUtilsProvider>
                  </div>
                </>
              ) : null}
              <div className="col-sm-2">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="d-flex">
                    <KeyboardDatePicker
                      required={reqFields.includes("spentDate")}
                      size="small"
                      disabled={placementID === ""}
                      label=" Transaction Date"
                      disableFuture
                      // minDate={minDate}
                      // (placementID !== ""
                      //   ? new Date(
                      //     placements.filter(
                      //       (item) => item.placementID === placementID
                      //     )[0].startDate
                      //   )
                      //   : new Date()) &&
                      // (!["Meals", "Fuel"].includes(state.expenseType)
                      //   ? state.departureDate
                      //     ? state.departureDate
                      //     : null
                      //   : null)

                      // maxDate={maxDate}
                      // (placementID !== ""
                      //   ? new Date(
                      //     placements.filter(
                      //       (item) => item.placementID === placementID
                      //     )[0].endDate
                      //   )
                      //   : new Date()) &&
                      // (!["Meals", "Fuel"].includes(state.expenseType)
                      //   ? state.returnDate
                      //     ? state.returnDate
                      //     : null
                      //   : null)

                      format="MM/dd/yyyy"
                      value={state.spentDate ? state.spentDate : null}
                      onChange={(date) => handleChange("spentDate", date)}
                      name="spentDate"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </div>
            </>
          ) : null}
        </div>
        <br />
        <div className="w-100">
          {state.expenseType ? (
            <div>
              <SunEditor
                placeholder="Additional Details"
                setContents={addDescription}
                onChange={(data) =>
                  handleAddDescription("additionalDetails", data)
                }
                setOptions={{
                  buttonList: [
                    [
                      "undo",
                      "redo",
                      "font",
                      "fontSize",
                      "formatBlock",
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "subscript",
                      "superscript",
                      "fontColor",
                      "hiliteColor",
                      "removeFormat",
                      "outdent",
                      "indent",
                      "align",
                      "horizontalRule",
                      "list",
                      "lineHeight",
                      "table",
                      "link",
                      "image",
                      "video",
                      "showBlocks",
                      "codeView",
                    ],
                  ],
                }}
              />
            </div>
          ) : null}
        </div>
      </form>
      <br />
      <div className="text-center">
        <Button
          disabled={!ExpenseDoc.expenseDoc || !areReqFilled}
          onClick={actionType === "new" ? handleAdd : handleUpdate}
          type="submit"
          color={actionType === "new" ? "primary" : "secondary"}
          variant="contained"
        >
          {actionType === "new" ? "Add Entry" : "Update"}
        </Button>{" "}
        <Button
          onClick={expenseActionsCallback}
          color="default"
          variant="contained"
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default Presentation;
