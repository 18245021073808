import React from "react"
import {
  Table,
  Paper,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core"
import { withStyles, createStyles } from "@material-ui/core/styles"
import validate from "../../../../../shared/validation"
import { useNewInvoiceContext } from "../../NewInvoice/context"

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell)

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow)

function DiscountTable(props) {
  const { state, services } = useNewInvoiceContext()
  if (state?.discount?.length < 1 || (state?.discount[0]?.name == "" && state?.discount[0]?.value == 0)) return <span></span>

  return (
    <div>
      <h2>
        <u>Discount</u>
      </h2>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Discount For</StyledTableCell>
              <StyledTableCell align="right">Discount Amount</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.discount?.map((item, index) => {
              return (
                <>
                  <StyledTableRow>
                    <StyledTableCell>
                      {item?.name}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      $ {services.calculateDiscount([item])}
                    </StyledTableCell>
                  </StyledTableRow>
                </>)
            })
            }
            <TableRow>
              <TableCell></TableCell>
              <TableCell style={{display:"flex",justifyContent:"space-around",alignItems:"baseline"}}>
                <h4>
                  Subtotal
                </h4>
                <h4>
                  {validate.currencyFormatterUs(services.calculateDiscount(state.discount))}
                </h4>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default DiscountTable