import React from "react";
import { connect } from "react-redux";
import { deleteEmployee, inviteEmployee, movingEmployee } from "../../middleware";
import Presentation from "./Presentation";
import { compose } from "redux";
import { useQueryClient } from "@tanstack/react-query";

function Container(props) {
  const { inviteEmployee, _delete_employee } = props;

  const onInviteAgain = (listOfMails) => {
    listOfMails.forEach((list) => {
      inviteEmployee({
        firstname: list.firstname,
        lastname: list.lastname,
        phonenumber: list.phone,
        email: list.email,
        dob: "",
        branch: list.branch,
      });
    });
  };

  const tab = new URLSearchParams(window.location.search).get("tab");
  const queryClient = useQueryClient();
 
  const onMovingEmployee = async (uid,payload) => {
    await movingEmployee(uid,payload)
    console.log({tab})
    if (!isNaN(tab)) {
      queryClient.invalidateQueries({ queryKey: ["getEmployees", Number(tab)] });
    }
  }

  const onDelete = (uid) => {
    _delete_employee(uid, async () => {
      if (!isNaN(tab)) {
        await queryClient.invalidateQueries({ queryKey: ["getEmployees", Number(tab)] });
      }
    });
  };

  return (
    <div>
      <Presentation {...props} onInviteAgain={onInviteAgain} onMovingEmployee={onMovingEmployee} onDelete={onDelete} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    branchList: state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.personal?.fields?.filter((field) => field.name === "branch")?.[0]?.values || [],
    clients: state.firestore.ordered.clients_list,
    placements_settings_list: state.firestore.ordered.placements_settings_list,
    employee_suspended_list_data: state.firestore.ordered.employee_suspended_list_data,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    inviteEmployee: (payload) => {
      dispatch(inviteEmployee(payload));
    },
    _delete_employee: (uid, cb) => {
      dispatch(deleteEmployee(uid, cb));
    }
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Container)
