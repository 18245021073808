import React,{useState} from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import AppModalHeader from "../../../../../shared/components/Modals/AppModalHeader"
import { Field, Form, Formik } from "formik";
import TextFormField from "../../../../../shared/components/BootStrapFormFields/TextFormField";
import AppModalFooter from "../../../../../shared/components/Modals/AppModalFooter";
import { useDialog } from "../../../../../hooks/useDialog"
import { useMutation } from "@tanstack/react-query";
import * as yup from "yup";
import { addReportToCollections } from "../../../../../api/services/default/reportcollectios";
import { RegexExp } from "../../../../../shared/regex";

const schema = yup.object().shape({
	collectionsAgency: yup.string().required("Required"),
	pointOfContactName: yup.string().required("Required"),
	phoneNumber: yup
	.string()
	.required()
	.matches(RegexExp.phonenumber, "phone number should be valid"),
  email: yup
	.string()
	.required()
	.matches(RegexExp.email, "emailId should be valid"),
});

export default function ReportCollections({clientId}) {
	const { open, handleOpen, handleClose } = useDialog();
	const [collectionsDetails, setCollectionsDetails] = useState({});

	const useAddCollections = useMutation({
		mutationFn: ({ data }) => addReportToCollections(clientId,data),
		onSuccess() {
			handleClose();
		},
	});

	const handleSubmit = async (values, actions) => {
    setCollectionsDetails(values);
		actions.setSubmitting(true);
		await useAddCollections.mutateAsync({ data: values });
		actions.setSubmitting(false);
	};

	return (
		<>
			<Button variant="primary" onClick={handleOpen}>
				Report to Collections
			</Button>
			<Modal show={open} onHide={handleClose} centered size="md">
				<Formik
					validationSchema={schema}
          initialValues={collectionsDetails}
					enableReinitialize
					onSubmit={handleSubmit}
				>
					{({ isValid, isSubmitting, dirty }) => (
						<Form>
							<AppModalHeader title="Report To Collections" />
							<Modal.Body>
								<Row>
									<Col xl={12}>
										<Field
											component={TextFormField}
											label="Collections Agency"
											name="collectionsAgency"
											placeholder="Collections Agency"
											type="text"
										/>
									</Col>
                  <Col xl={12}>
										<Field
											component={TextFormField}
											label="Point of Contact Name"
											name="pointOfContactName"
											placeholder="Point of Contact Name"
											type="text"
										/>
									</Col>
									<Col xl={12}>
										<Field
											component={TextFormField}
											label="Collections Phone Number"
											name="phoneNumber"
											placeholder="Collections Phone Number"
											type="text"
										/>
									</Col>
									<Col xl={12}>
										<Field
											component={TextFormField}
											label="Collections Email"
											name="email"
											placeholder="Collections Email"
											type="text"
										/>
									</Col>
								</Row>
							</Modal.Body>
							<AppModalFooter
								saveButtonDisabled={!isValid || isSubmitting || !dirty}
                saveButtonText={"Submit"}
								onClickCancel={handleClose}
							/>
						</Form>
					)}
				</Formik>
			</Modal>
		</>
	);
}
