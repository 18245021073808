import React, { useContext } from "react"
import MaterialTable, { MTableToolbar } from "material-table"
import {
  AppBar,
  Tabs,
  Tab,
  Badge,
  Typography,
  Box,
  Grid,
} from "@material-ui/core"
import PropTypes from "prop-types"
import { configuration } from "../../../../../config/companyConfig"
import Chip from "@material-ui/core/Chip"
import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"
import Validation from "../../../../../shared/getMetaInfo"
// import EmployeeStepper from "../EmployeeStepper"
import ManagerStepper from "../ManagerStepper"
import RejectLetter from "../RejectLetter"
import parser from "html-react-parser"
 import PreviewLetter from "../PreviewLetter"
import { AiOutlineFileProtect } from "react-icons/ai"
// import { DataContext } from "../../../../contexts/data"
import FlairTable from "../../../../../shared/FlairTable"

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  }
}

export default function Presentation(props) {
  const [value, setValue] = React.useState(0)

  // const handleChange = (event, newValue) => {
  //   setValue(newValue)
  // }
  const validate = new Validation()
  const {
    state,
    condition,
    tabPair,
    loggedInEmployee,
    reqLettersList,
    authorizedSignatures,
  } = props
  const { myReq } = props
  // const [state] = useContext(DataContext)
  const formatter = (date) => {
    let final = ""
    try {
      final = Intl.DateTimeFormat(
        configuration["date-code"],
        configuration.dateformat
      ).format(new Date(date))
    } catch (error) {
      console.log(error)
      final = date
    }
    return final
  }
  const data = Object.values(state[tabPair[condition]].data)
  let requests = []
  let letters = []
  let signatures = []
  try {
    requests = myReq ? data : data
    letters = reqLettersList
    signatures = authorizedSignatures
  } catch (error) {
    requests = []
    letters = []
    signatures = []
  }

  let columns = []
  if (myReq === true)
    columns = [
      {
        title: "Request ID",
        field: "requestid",
        defaultSort: "desc",
        dataType: "String",
        render: (row) => {
          if (row.status === "Approved")
            return (
              <span className="custom-chip chip-success">{`${row.requestid}`}</span>
            )
          else if (row.status === "Rejected")
            return (
              <span className="custom-chip chip-danger">{`${row.requestid}`}</span>
            )
          return (
            <span className="custom-chip chip-warning">{`${row.requestid}`}</span>
          )
        },
      },
      { title: "Subject", field: "subject", dataType: "String" },
      {
        title: "Description",
        field: "description",
        dataType: "String",
        render: (row) => parser(row.description),
      },
      { title: "Timestamp", field: "timestamp", dataType: "Date" },
      { title: "Issued by/Rejected by", field: "issuedBy", dataType: "String" },
      {
        title: "Comment",
        field: "comment",
        dataType: "String",
        render: (row) => {
          return parser(row.comment)
        },
      },
      {
        title: "Status",
        field: "status",
        dataType: "String",
        render: (row) => (
          <span>
            {row.status === "Approved" ? (
              <Chip
                style={{
                  width: "75%",
                  backgroundColor: "#21ba45",
                  color: "white",
                }}
                label="Approved"
              />
            ) : row.status === "Rejected" ? (
              <Chip
                style={{
                  width: "75%",
                  backgroundColor: "#db2828",
                  color: "white",
                }}
                label="Rejected"
              />
            ) : (
              <Chip
                style={{
                  width: "75%",
                  backgroundColor: "orange",
                  color: "white",
                }}
                label="Pending"
              />
            )}
          </span>
        ),
      },
      {
        title: "Action",
        field: "action",
        dataType: "",
        render: (row) => (
          <span>
            {row.status === "Approved" ? (
              <PreviewLetter
                headerAndFooter={true}
                htmlContent={row.docContent}
                btnContent={<AiOutlineFileProtect size={18} />}
                iconBtn={true}
                isString={true}
                reqData={row}
                signatures={signatures}
                letters={letters}
              />
            ) : row.status === "Rejected" ? (
              <p>Re-request</p>
            ) : null}
          </span>
        ),
      },
    ]
  else if (myReq === false)
    columns = [
      {
        title: "Request ID",
        field: "requestid",
        defaultSort: "desc",
        dataType: "String",
        render: (row) => {
          if (row.status === "Approved")
            return (
              <span className="custom-chip chip-success">{`${row.requestid}`}</span>
            )
          else if (row.status === "Rejected")
            return (
              <span className="custom-chip chip-danger">{`${row.requestid}`}</span>
            )
          return (
            <span className="custom-chip chip-warning">{`${row.requestid}`}</span>
          )
        },
      },
      {
        title: "Employee",
        field: "employeename",
        dataType: "String",
        render: (row) => (
          <span>
            <Link
              to={"/console/employees/" + row.uid}
              style={{ textTransform: "capitalize", color: "#4183c4" }}
            >
              {row.employeename}
            </Link>
            <br />
          </span>
        ),
      },
      { title: "Subject", field: "subject", dataType: "String" },
      {
        title: "Description",
        field: "description",
        dataType: "String",
        render: (row) => parser(row.description),
      },
      { title: "Timestamp", field: "timestamp" },
      { title: "Issued by/Rejected by", field: "issuedBy" },
      {
        title: "Comment",
        field: "comment",
        dataType: "String",
        render: (row) => {
          return parser(row.comment)
        },
      },
      {
        title: "Status",
        field: "status",
        dataType: "String",
        render: (row) => (
          <span>
            {row.status === "Approved" ? (
              <Chip
                style={{
                  width: "75%",
                  backgroundColor: "#21ba45",
                  color: "white",
                }}
                label="Approved"
              />
            ) : row.status === "Rejected" ? (
              <Chip
                style={{
                  width: "75%",
                  backgroundColor: "#db2828",
                  color: "white",
                }}
                label="Rejected"
              />
            ) : (
              <Chip
                style={{
                  width: "75%",
                  backgroundColor: "orange",
                  color: "white",
                }}
                label="Pending"
              />
            )}
          </span>
        ),
      },
      {
        title: "Action",
        field: "status",
        dataType: "",
        render: (row) => (
          <span>
            {row.status === "Approved" ? (
              <PreviewLetter
                headerAndFooter={true}
                htmlContent={row.docContent}
                btnContent={<AiOutlineFileProtect size={18} />}
                iconBtn={true}
                isString={true}
                reqData={row}
                signatures={signatures}
                letters={letters}
              />
            ) : row.status === "Rejected" ? (
              <p>Reopen</p>
            ) : (
              <span className="d-flex">
                {/* <p>Manager</p>
                <p>Reject</p> */}
                <ManagerStepper
                  reqData={row}
                  signatures={signatures}
                  letters={letters}
                />
                <RejectLetter reqId={row.requestid} id={row.uid} />
              </span>
            )}
          </span>
        ),
      },
    ]

  const reqContent = requests.map((request) => {
    return {
      requestid: request.id,
      subject: configuration.letterTypes.filter(
        (letter) =>
          letter.replace(/ /g, "").toLowerCase() === request.letterType
      )[0],
      description: request.description,
      timestamp: formatter(request.createdAt),
      status: request.isApproved
        ? "Approved"
        : request.isRejected
        ? "Rejected"
        : "Pending",
      id: request.id,
      employeename: validate.emailToName(request.employeeID),
      uid: request.employeeID,
      approvedDetails: request.approvedDetails ? request.approvedDetails : {},
      additionalDetails: request.additionalInformation,
      issuedBy: request.approvedDetails
        ? validate.emailToName(request.approvedDetails.approvedBy)
        : request.hasOwnProperty("rejectedDetails")
        ? validate.emailToName(request.rejectedDetails.rejectedBy)
        : "",
      comment: request.hasOwnProperty("approvedDetails")
        ? request.approvedDetails.description
        : request.hasOwnProperty("rejectedDetails")
        ? request.rejectedDetails.description
        : "",
      req_doc: request.attachmentDetails.publicURL,
    }
  })
  const [newColumns, setNewColumns] = React.useState([])
  const [filterBy, setFilterBy] = React.useState({
    data: [],
    isFiltered: false,
  })
  const handleChange = (
    filteredData = [],
    isFiltered = false,
    newCol = columns
  ) => {
    console.log(filteredData, isFiltered, newCol)
    setFilterBy({
      data: filteredData,
      isFiltered: isFiltered,
    })

    setNewColumns(newCol)
  }

  const RequestLetterAction = {
    icon:()=> <Link to="/console/requestLetter"><Button className="btn btn-success px-4 btn-md text-high">Request Letter</Button></Link>,
              tooltip: "Request Letter",
              isFreeAction: true,
              position: "toolbar",
    }

  return (
    <div>
      <FlairTable
        title="Letter Request Report"
        data={!filterBy.isFiltered ? reqContent : filterBy.data}
        columns={columns}
        actions= {myReq?RequestLetterAction: {}}
      />
    </div>
  )
}
