import { makeStyles, Popover } from "@material-ui/core";
import React from "react";
import { JSutils } from "../../../shared/JSutils";

const useStyles = makeStyles((theme) => ({
	popover: {
		pointerEvents: "none",
	},
	paper: {
		padding: theme.spacing(1),
		width: "450px",
	},
	large: {
		width: theme.spacing(7),
		height: theme.spacing(7),
	},
	row: {
		paddingTop: "5px",
	},
}));

export function TimesheetHover({
	data,
	columnNameToDisplay = "submissionDate",
}) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const uniqData = JSutils.getUniqueItemsByProperties(data, [
		"duration",
		"submissionDate",
		"approvedAt",
	]);

	if (data.length === 0) return "--";
	return (
		<div>
			<span
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
			>
				{uniqData[0]?.[columnNameToDisplay]}
			</span>
			<Popover
				id="mouse-over-popover"
				className={classes.popover}
				classes={{
					paper: classes.paper,
				}}
				open={open}
				anchorEl={anchorEl}
				anchorPosition={{ top: 200, left: 780 }}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<table className="table table-striped table-bordered">
					<thead>
						<tr>
							<th>Duration</th>
							<th>Submitted On</th>
							<th>Approved On</th>
						</tr>
					</thead>
					<tbody>
						{uniqData.map((item) => {
							return (
								<tr key={item.id}>
									<td>{item.duration}</td>
									<td>{item.submissionDate}</td>
									<td>{item.approvedAt}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</Popover>
		</div>
	);
}
