import React from "react";
import { Button } from "react-bootstrap";
import { collectionsExport } from "../../../../../api/services/default/reportcollectios";
import { useMutation } from "@tanstack/react-query";

export default function CollectionsExport({clientId}) {
  const useAddCollections = useMutation({
		mutationFn: () => collectionsExport(clientId)
	});

	const handleExport = async () => {
		await useAddCollections.mutateAsync();
	};

  return (
    <>
      <Button variant="secondary" onClick={handleExport}>
        Collections Export
      </Button>
    </>
  );
}
