import React, { useState } from "react"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import Presentation from "./Presentation"
import { compose } from "redux"
import { connect } from "react-redux"
import CircularSpinner from "../../../../shared/circularSpinner"

function Container(props) {
  const [state, setState] = useState({
    personal: {},
    profile: {},
  })
  if (isLoaded(props.employeeProfile))
    return (
      <div>
        <Presentation {...props} />
      </div>
    )

  return (
    <CircularSpinner />
  )
}

const mapStateToProps = (state, ownProps) => {
  console.log(ownProps)
  const employeeProfile = state.firestore.ordered.employeeProfile
    ? state.firestore.ordered.employeeProfile[0]
    : state.firestore.ordered.employeeProfile
  return {
    id: ownProps.id,
    employeeProfile: employeeProfile,
    personal: employeeProfile ? employeeProfile.personal : employeeProfile,
    profile: employeeProfile,
  }
}
export default compose(
  firestoreConnect((props) => {
    if (!props.employeeProfile)
      return [
        {
          collection: "EMPLOYEES",
          doc: props.id,
          storeAs: "employeeProfile",
        },
      ]
    return []
  }),
  connect(mapStateToProps)
)(Container)
