import { combineReducers } from "redux";
import { expiredListReducer } from "./expiredListReducer";
import { dynamicDocRender } from "./dynamicDocRender";
import { holdAccountReducer } from "./holdAccountReducer";
import { suspendedListReducer } from "./suspendedListReducer";
const rootReducer = combineReducers({
  expiredList: expiredListReducer,
  suspendedList : suspendedListReducer,
  dynamciDocRender: dynamicDocRender,
  holdAccountReducer: holdAccountReducer,
});

export default rootReducer;
