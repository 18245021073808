import { Skeleton } from "@material-ui/lab";
import React, { useContext, useEffect } from "react";
import useTimesheetCalendar from "../../../../../hooks/useTimesheetCalendar";
import { formWorkingHours } from "../../../../../utils/TimesheetsUtils";
import { Context } from "../component_state/context";
import { handleTableChange } from "../component_state/utils";

function TimesheetCalendar() {
  const [state, handle] = useContext(Context);
  const setState = (obj) => {
    handle({
      type: "SET_STATE",
      data: obj,
    });
  };
  const { initializeCalendar, renderCalendar, initializeState } =
    useTimesheetCalendar(selectionCallback);

  useEffect(() => {
    if (state.isSettingsLoaded) {
      initializeState()
      const placementInfo = state.placements[state.placementID];
      const cycles = state.timesheetSettings.cycle;
      initializeCalendar(
        placementInfo,
        cycles,
        state.type,
        state.timesheetInfo
      );
    }
  }, [state.isSettingsLoaded, state.placementID]);

  function selectionCallback(selectedRange) {
    if (selectedRange.length >= 1) {
      const placementInfo = state.placements[state.placementID];
      const { standardTime, OTtime } = formWorkingHours(selectedRange)
      setState({
        selectedRange: selectedRange,
        standardTime: standardTime,
        OTtime: OTtime,
        timesheetInfo: {
          ...state.timesheetInfo,
          startDate: selectedRange[0],
          endDate: selectedRange[selectedRange.length - 1],
        },
      });
    } else {
      setState({
        selectedRange: [],
        standardTime: [],
        OTtime: [],
        timesheetInfo: {
          ...state.timesheetInfo,
          startDate: "",
          endDate: "",
        },
        isAlreadySubmitted: false,
      });
    }
  }
  return <div>{renderCalendar()}</div>;
}

export default TimesheetCalendar;
