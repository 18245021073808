import React from "react"
import ExpensesList from "../Services/Expenses/components/ExpensesList/Index/index"

function CreateExpensesPage(props) {
  console.log("list", props)
  return (
    <div>
      <ExpensesList {...props} listAll={true} />
    </div>
  )
}

export default CreateExpensesPage
