import {
  expiredlistError,
  expiredlistRequest,
  expiredlistSuccess,
  ReviewSuspendedRequest,
  ReviewSuspendedSuccess,
  ReviewSuspendedFailure,
  setStateAction,
  holdAccountRequest,
  holdAccountSuccess,
  holdAccountFailure,
  enableAccountFailure,
  enableAccountRequest,
  enableAccountSuccess,
  onIgnoreDocumentFailure,
  onIgnoreDocumentRequest,
  onIgnoreDocumentSuccess,
  updateWorkQueueDocumentRequest,
  updateWorkQueueDocumentSuccess,
  updateWorkQueueDocumentFailure,
  reviewWorkQueueDocumentRequest,
  reviewWorkQueueDocumentSuccess,
  reviewWorkQueueDocumentFailure,
  getReviewDocumentRequest,
  getReviewDocumentSuccess,
  getReviewDocumentFailure,
} from "../actions/actionCreators";
import {
  waitingMsg,
  stopWaitMsg,
  errorMsg,
  successMsg,
} from "../../../shared/SnackBars/index";
import make_API_call from "../../../providers/REST_API";
import firebase from "../../../config/fbConfig"
export const _set_state = (obj) => (dispatch) => {
  dispatch(setStateAction(obj));
};

export const getExpiredList = () => async(dispatch, getState) => {
  // alert()
  try {
    const uid = getState().firebase.auth.uid;
    const empModules = getState().employee.employeeModules.accessModules;
    const employeeDepartment =
      getState().firebase.profile?.personal?.department;
    dispatch(expiredlistRequest());
    const isAdmin =
          empModules.includes("employees-manager") ||
          empModules.includes("console-customization");
    
    let suspendedList = [];
    let expiredList = [];
    return firebase
      .firestore()
      .collectionGroup("WORK_QUEUE")
      .where("id", "==", "suspended_list_data")
      .where("isExist", "==", true)
      .where("reviewDepartments", "array-contains", employeeDepartment)
      .onSnapshot(
        (snap) => {
          suspendedList = snap.docs.map((d) => d.data());
          return firebase
            .firestore()
            .collectionGroup("WORK_QUEUE")
            .where("id", "==", "expired_list_data")
            .onSnapshot((querySnap) => {
              expiredList = querySnap.docs
                .map((d) => d.data())
                .filter(
                  (doc) =>
                    !suspendedList
                      .map((item) => item.employeeID)
                      .includes(doc.EmpId)
                );
              let data = suspendedList.map((doc) => {
                return {
                  id: doc.employeeID,
                  docs: [],
                  count: 0,
                  underReviewCount: 0,
                  isFromSuspended: true,
                };
              });
              data = [
                ...data,
                ...expiredList.map((doc) => {
                  const totalCount = doc.docs?.length ?? 0;
                  const underReviewCount = doc.docs?.filter((d) => d?.status === "submitted").length ?? 0;
                  const expiredDocsCount = totalCount - underReviewCount;

                  return {
                    id: doc.EmpId,
                    docs: doc.docs,
                    isIgnored: doc.isIgnored,
                    count: expiredDocsCount,
                    underReviewCount: underReviewCount,
                    isFromSuspended: false,
                  };
                }),
              ];
              if (isAdmin) {
                return dispatch(expiredlistSuccess(data));
              } else {
                let singleUserData = expiredList.filter(
                  (emp) => emp.EmpId === uid
                );
                return dispatch(expiredlistSuccess(singleUserData));
              }
            });
        },
        (err) => {
          console.error(err);
          const msg = `Failed to Expired Document List `;
          dispatch(expiredlistError(msg));
        }
      );
  } catch (err) {
    console.error(err);
    const msg = `Failed to Expired Document List `;
    dispatch(expiredlistError(msg));
  }
};

  export function onReviewSuspended(payload,uid,reviewDate) {
    return (dispatch, getState) => {
      dispatch(ReviewSuspendedRequest());
      waitingMsg("Reviewing the employee...");
      const dept = getState().employee.employeeProfile.profile?.personal?.department;
      console.log("Reviewd Person Dept: -", dept)
      make_API_call("put", `/employee/${uid}/suspend/reason`,{reviewOfSuspension : payload ,dept,reviewDate})
        .then((data) => {
          stopWaitMsg();
          dispatch(ReviewSuspendedSuccess());
          successMsg(data.message);
        })
        .catch((err) => {
          stopWaitMsg();
          dispatch(ReviewSuspendedFailure());
          errorMsg(err.message);
        });
    };
  }

export function onHold(uid) {
  return (dispatch) => {
    dispatch(holdAccountRequest());
    waitingMsg("Holding the employee...");
    make_API_call("put", `/employee/${uid}/holdaccount`)
      .then((data) => {
        stopWaitMsg();
        dispatch(holdAccountSuccess());
        successMsg(data.message);
      })
      .catch((err) => {
        stopWaitMsg();
        dispatch(holdAccountFailure());
        errorMsg(err.message);
      });
  };
}
export function onEnable(uid) {
  return (dispatch) => {
    dispatch(enableAccountRequest());
    waitingMsg("Enabling the employee...");
    make_API_call("put", `/employee/${uid}/enable_account`)
      .then((data) => {
        stopWaitMsg();
        successMsg("Successfully Enabled");
        dispatch(enableAccountSuccess());
        successMsg(data.message);
      })
      .catch((err) => {
        stopWaitMsg();
        dispatch(enableAccountFailure());
        errorMsg(err.message);
      });
  };
}
export function onIgnore(data) {
  const { EmpId, isIgnored } = data;
  return (dispatch) => {
    dispatch(onIgnoreDocumentRequest());
    waitingMsg("Processing  the Request...");
    make_API_call("put", `/employee/documentignore`, { isIgnored, EmpId })
      .then((data) => {
        stopWaitMsg();
        successMsg("Successfully Processed the request");
        dispatch(onIgnoreDocumentSuccess());
        successMsg(data.message);
        window.location.reload();
      })
      .catch((err) => {
        stopWaitMsg();
        dispatch(onIgnoreDocumentFailure());
        errorMsg(err.message);
      });
  };
}

export const updateWorkQueueDocument = (payload, callback) => async (dispatch) => {
  try {
    dispatch(updateWorkQueueDocumentRequest());
    const res = await make_API_call("put", `/employee/updateWorkQueueDocument`, payload);
    dispatch(updateWorkQueueDocumentSuccess(res));
    successMsg(res.message);
    callback();
  } catch (err) {
    console.error(err);
    const msg = err?.message || `Failed to update the document`;
    errorMsg(msg);
    dispatch(updateWorkQueueDocumentFailure(err));
  }
}

export const reviewWorkQueueDocument = (payload, callback) => async (dispatch) => {
  try {
    dispatch(reviewWorkQueueDocumentRequest());
    const res = await make_API_call("put", `/employee/reviewWorkQueueDocument`, payload);
    dispatch(reviewWorkQueueDocumentSuccess(res));
    successMsg(res.message);
    callback();
  } catch (err) {
    console.error(err);
    const msg = err?.message || `Failed to review the document`;
    errorMsg(msg);
    dispatch(reviewWorkQueueDocumentFailure(err));
  }
}

export const getReviewDocument = (employeeId, reviewId, callback) => async (dispatch) => {
  try {
		dispatch(getReviewDocumentRequest())
		const res = await firebase
			.firestore()
			.collection(`EMPLOYEES/${employeeId}/REVIEW`)
      .where('id', '==', reviewId)
			.get()

		if (res.size === 0) {
      dispatch(getReviewDocumentFailure('No review found'))
      return
    }

    const review = res.docs[0].data()
    dispatch(getReviewDocumentSuccess(review))
    callback()
	} catch (err) {
		console.error(err)
		dispatch(getReviewDocumentFailure(err))
	}
}