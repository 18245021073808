import React from "react"
import PlacementView from "../Services/Placements/components/NewPlacement/Index"

function PlacementEditPage(props) {
  return (
    <div>
      <PlacementView {...props.match.params} isEdit={true} />
    </div>
  )
}

export default PlacementEditPage
