import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paper: {
      
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    layout: {
      width: 800,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    root: {
     
      '& > *': {
        margin: theme.spacing(1),
        
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 'bold',
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    
   
  }));

  export default useStyles