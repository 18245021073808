import React from "react"
import DiscountDetails from "../DiscountDetails"
import Edit from '../EditMoreDetails'
import EditNotifiers from '../EditNotifiers'
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

function Presentation(props) {
  const accounts = props.accounts[0]
  console.log(accounts)

  let rows = []
  let max = [
    accounts.to.length,
    accounts.cc.length,
    accounts.bcc.length,
  ].reduce((a, b) => Math.max(a, b))

  for (let index = 0; index < max; index++) {
    rows.push({
      to: accounts.to[index] ? accounts.to[index] : "",
      cc: accounts.cc[index] ? accounts.cc[index] : "",
      bcc: accounts.bcc[index] ? accounts.bcc[index] : "",
    })
  }

  const detailsList = [
    {
      name: "Services",
      value: accounts.services,
    },
    {
      name: "Name",
      value:
        accounts.firstName + "" + accounts.middleName + "" + accounts.lastName,
    },
    {
      name: "Mobile Number",
      value: accounts.mobileNumber,
    },
    {
      name: "Email",
      value: accounts.email,
    },
  ]

  // const extraList = [
  // 	{
  // 		name: 'Job Title',
  // 		value: accounts.jobTitle,
  // 	},
  // 	{
  // 		name: 'Phone Number',
  // 		value: accounts.phoneNumber,
  // 	},
  // 	{
  // 		name: 'Home Phone',
  // 		value: accounts.homePhone,
  // 	},
  // ]

  return (
    <div>
      <div className="row">
        <div className="custom-card p-4 col-sm-6 border">
          <div className="row">
            <div className="col-11">
              <h2>Notifiers</h2>
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>To</TableCell>
                      <TableCell>CC</TableCell>
                      <TableCell>BCC</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{row.to}</TableCell>
                          <TableCell>{row.cc}</TableCell>
                          <TableCell>{row.bcc}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="col-1">
              <EditNotifiers accounts={accounts} clientID={props.client.id} />
            </div>
          </div>
        </div>
        <div className="col-sm-6 p-4 custom-card border">
          <div className="row">
            <div className="col-11">
              <h2>More Details</h2>
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Field</TableCell>
                      <TableCell align="right">Entry</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {detailsList.map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell component="h2" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.value}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className='col-1'>
							<Edit accounts={accounts} clientID={props.client.id} />
						</div>
          </div>
        </div>
        <div className="col-sm-6 p-4 custom-card border">
          <DiscountDetails accounts={accounts} client={props.client} />
        </div>
      </div>
    </div>
  )
}

export default Presentation
