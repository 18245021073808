import React, { useEffect } from "react"
import { connect } from "react-redux"
import { _load_employe_docs } from "../../middleware";
import Presentation from "./Presentation";

function Container(props) {
  const { employeeID, getDocuments, state, profDoc } = props;

  useEffect(() => {
    getDocuments(employeeID);
  }, [employeeID]);

  return (
    <div>
      <Presentation
        state={state}
        getDocuments={getDocuments}
        employeeID={employeeID}
        profDoc={profDoc}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    state: state.documents.documents_list,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getDocuments: (employeeID) => {
      dispatch(_load_employe_docs(employeeID));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container)
