import {
  setStateAction,
  setListener,
  unSubscribeListenerAction,
  loadOpenInvoicesReq,
  loadOpenInvoicesSuccess,
  loadOpenInvoicesFailure,
  loadDueInvoicesReq,
  loadDueInvoicesSuccess,
  loadDueInvoicesFailure,
  loadVoidInvoicesReq,
  loadVoidInvoicesSuccess,
  loadVoidInvoicesFailure,
  loadPaidInvoicesReq,
  loadPaidInvoicesSuccess,
  loadPaidInvoicesFailure,
  loadAllInvoicesReq,
  loadAllInvoicesSuccess,
  loadAllInvoicesFailure,
  makeInvoiceVoidReq,
  makeInvoiceVoidSuccess,
  makeInvoiceVoidFailure,
  sendInvoiceToClientReq,
  sendInvoiceToClientSuccess,
  sendInvoiceToClientFailure,
  loadInvoiceAttachmentsReq,
  loadInvoiceAttachmentsSuccess,
  loadInvoiceAttachmentsFailure,
  downloadInvoiceSuccess,
  downloadInvoiceFailure,
  downloadInvoiceReq,
  getPreviewInvoiceFailure,
  getPreviewInvoiceReq,
  getPreviewInvoiceSuccess,
  clientApproveReq,
  clientApproveSuccess,
  clientApproveFailure,
  clientRejectReq,
  clientRejectSuccess,
  clientRejectFailure,
  loadGeneratedInvoicesReq,
  loadGeneratedInvoicesSuccess,
  loadGeneratedInvoicesFailure,
  sendInvoiceReminderReq,
  sendInvoiceReminderSuccess,
  sendInvoiceReminderFailure,
} from "../actions/actionCreators"
import { JSutils } from "../../../shared/JSutils"
import {
  errorMsg,
  waitingMsg,
  stopWaitMsg,
  successMsg,
} from "../../../shared/SnackBars/index"
import make_API_call from "../../../providers/REST_API"
import { configuration } from "../../../config/companyConfig"
import firebase from "../../../config/fbConfig"
import { differenceInDays } from "date-fns"

export function newPayment(payload, clearState, handleClose) {
  return function () {
    waitingMsg("Paying the invoice...")
    make_API_call("post", "/payments/new", payload)
      .then((data) => {
        console.log(data)
        stopWaitMsg()
        successMsg(data.message)
        clearState()
        handleClose()
      })
      .catch((err) => {
        stopWaitMsg()
        errorMsg(err.message)
      })
  }
}

export function getInvoiceHTML(invoiceID) {
  return (dispatch) => {
    dispatch(getPreviewInvoiceReq())
    console.log(invoiceID)
    make_API_call("put", `invoices/preview`, { id: invoiceID })
      .then((data) => {
        console.log(data)
        return dispatch(getPreviewInvoiceSuccess(data.html))
      })
      .catch((error) => {
        dispatch(getPreviewInvoiceFailure(error))
      })
  }
}

export const downloadInvoice = (generatedInvoiceFilePath) => (
  dispatch,
  getState,
  { getFirebase }
) => {
  dispatch(downloadInvoiceReq())
  const storage = getFirebase().storage()
  storage
    .refFromURL(generatedInvoiceFilePath)
    .getDownloadURL()
    .then((url) => {
      window.open(url)
      return dispatch(downloadInvoiceSuccess(url))
      // return storage.refFromURL(generatedInvoiceFilePath)
    })
    .catch((err) => {
      console.error(err)
      stopWaitMsg()
      //const msg = "Failed to download"
      const msg= ""
      //errorMsg(msg)
      return dispatch(downloadInvoiceFailure(err))
    })
}

export const setState = (obj) => (dispatch) => {
  return dispatch(setStateAction(obj))
}

export const loadDueInvoices = (payload) => (
  dispatch,
  getState,
  { getFirebase }
) => {
  const { listAll, employeeID, clientID, isClientView } = payload
  dispatch(loadDueInvoicesReq())
  const subscribe = isClientView
    ? getFirebase()
        .firestore()
        .collection("INVOICES")
        .where("invoiceDueDate", "<", Date.parse(new Date()))
        .where("isPaymentDone", "==", false)
        .where("isMailedToClient", "==", true)
        .where("isVoid", "==", false)
        .where("isExist", "==", true)
        .where("clientID", "==", clientID)
        .orderBy("invoiceDueDate", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadDueInvoicesSuccess(data))
          },
          (err) => {
            console.error(err)
            //const msg = "Failed to load due invoices"
            const msg=""
            //errorMsg(msg)
            return dispatch(loadDueInvoicesFailure(err))
          }
        )
    : listAll
    ? getFirebase()
        .firestore()
        .collection("INVOICES")
        .where("invoiceDueDate", "<", Date.parse(new Date()))
        .where("isPaymentDone", "==", false)
        .where("isMailedToClient", "==", true)
        .where("isVoid", "==", false)
        .where("isExist", "==", true)
        .orderBy("invoiceDueDate", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadDueInvoicesSuccess(data))
          },
          (err) => {
            console.error(err)
            //const msg = "Failed to load due invoices"
            const msg=""
            //errorMsg(msg)
            return dispatch(loadDueInvoicesFailure(err))
          }
        )
    : getFirebase()
        .firestore()
        .collection("INVOICES")
        .where("invoiceDueDate", "<", Date.parse(new Date()))
        .where("isPaymentDone", "==", false)
        .where("isVoid", "==", false)
        .where("isMailedToClient", "==", true)
        .where("isExist", "==", true)
        .where("employeeID", "==", employeeID)
        .orderBy("invoiceDueDate", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadDueInvoicesSuccess(data))
          },
          (err) => {
            console.error(err)
            //const msg = "Failed to load due invoices"
            const msg=""
            //errorMsg(msg)
            return dispatch(loadDueInvoicesFailure(err))
          }
        )

  dispatch(setListener(subscribe, "dueInvoices"))
}

export const loadGeneratedInvoices = (payload) => (
  dispatch,
  getState,
  { getFirebase }
) => {
  dispatch(loadGeneratedInvoicesReq())
  const { listAll, employeeID, clientID, isClientView } = payload
  let dbConnect
  if (isClientView) {
    dbConnect = getFirebase()
      .firestore()
      .collection("INVOICES")
      .where("isPaymentDone", "==", false)
      .where("isMailedToClient", "==", false)
      .where("isVoid", "==", false)
      .where("clientID", "==", clientID)
      .where("isExist", "==", true)
      .orderBy("createdAt", "desc")
  } else if (listAll) {
    dbConnect = getFirebase()
      .firestore()
      .collection("INVOICES")
      .where("isPaymentDone", "==", false)
      .where("isMailedToClient", "==", false)
      .where("isVoid", "==", false)
      .where("isExist", "==", true)
      .orderBy("createdAt", "desc")
  } else {
    dbConnect = getFirebase()
      .firestore()
      .collection("INVOICES")
      .where("isPaymentDone", "==", false)
      .where("isMailedToClient", "==", false)
      .where("isVoid", "==", false)
      .where("employeeID", "==", employeeID)
      .where("isExist", "==", true)
      .orderBy("createdAt", "desc")
  }

  const subscribe = dbConnect.onSnapshot(
    (snap) => {
      const data = JSutils._array_to_object(
        snap.docs.map((doc) => doc.data()),
        "id"
      )
      console.log(data)
      return dispatch(loadGeneratedInvoicesSuccess(data))
    },
    (err) => {
      console.error(err)
      const msg = "Failed to load generated invoices"
      errorMsg(msg)
      return dispatch(loadGeneratedInvoicesFailure(msg))
    }
  )

  dispatch(setListener(subscribe, "generatedInvoices"))
}

// sent invoices
export const loadOpenInvoices = (payload) => (
  dispatch,
  getState,
  { getFirebase }
) => {
  const { listAll, employeeID, clientID, isClientView } = payload

  dispatch(loadOpenInvoicesReq())
  const subscribe = isClientView
    ? getFirebase()
        .firestore()
        .collection("INVOICES")
        .where("isPaymentDone", "==", false)
        .where("isMailedToClient", "==", true)
        .where("isVoid", "==", false)
        .where("isExist", "==", true)
        .where("clientID", "==", clientID)
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadOpenInvoicesSuccess(data))
          },
          (err) => {
            console.error(err)
            //const msg = "Failed to load due invoices"
            const msg=""
            errorMsg(msg)
            return dispatch(loadOpenInvoicesFailure(err))
          }
        )
    : listAll
    ? getFirebase()
        .firestore()
        .collection("INVOICES")
        .where("isPaymentDone", "==", false)
        .where("isMailedToClient", "==", true)
        .where("isVoid", "==", false)
        .where("isExist", "==", true)
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadOpenInvoicesSuccess(data))
          },
          (err) => {
            console.error(err)
            //const msg = "Failed to load due invoices"
            const msg=""
            errorMsg(msg)
            return dispatch(loadOpenInvoicesFailure(err))
          }
        )
    : getFirebase()
        .firestore()
        .collection("INVOICES")
        .where("isPaymentDone", "==", false)
        .where("isMailedToClient", "==", true)
        .where("isVoid", "==", false)
        .where("isExist", "==", true)
        .where("employeeID", "==", employeeID)
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadOpenInvoicesSuccess(data))
          },
          (err) => {
            console.error(err)
            //const msg = "Failed to load due invoices"
            const msg=""
            errorMsg(msg)
            return dispatch(loadOpenInvoicesFailure(err))
          }
        )

  dispatch(setListener(subscribe, "openInvoices"))
}

export const loadVoidInvoices = (payload) => (
  dispatch,
  getState,
  { getFirebase }
) => {
  const { listAll, employeeID, clientID, isClientView } = payload

  dispatch(loadVoidInvoicesReq())
  const subscribe = isClientView
    ? getFirebase()
        .firestore()
        .collection("INVOICES")
        .where("isVoid", "==", true)
        .where("isExist", "==", true)
        .where("clientID", "==", clientID)
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadVoidInvoicesSuccess(data))
          },
          (err) => {
            console.error(err)
            const msg = "Failed to load void invoices"
            //const msg=""
            errorMsg(msg)
            return dispatch(loadVoidInvoicesFailure(msg))
          }
        )
    : listAll
    ? getFirebase()
        .firestore()
        .collection("INVOICES")
        .where("isVoid", "==", true)
        .where("isExist", "==", true)
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadVoidInvoicesSuccess(data))
          },
          (err) => {
            console.error(err)
            const msg = "Failed to load void invoices"
            errorMsg(msg)
            return dispatch(loadVoidInvoicesFailure(msg))
          }
        )
    : getFirebase()
        .firestore()
        .collection("INVOICES")
        .where("isVoid", "==", true)
        .where("isExist", "==", true)
        .where("employeeID", "==", employeeID)
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadVoidInvoicesSuccess(data))
          },
          (err) => {
            console.error(err)
            //const msg = "Failed to load void invoices"
            const msg=""
            errorMsg(msg)
            return dispatch(loadVoidInvoicesFailure(err))
          }
        )

  dispatch(setListener(subscribe, "voidInvoices"))
}

export const loadPaidInvoices = (payload) => (
  dispatch,
  getState,
  { getFirebase }
) => {
  const { listAll, employeeID, clientID, isClientView } = payload

  dispatch(loadPaidInvoicesReq())
  const subscribe = isClientView
    ? getFirebase()
        .firestore()
        .collection("INVOICES")
        .where("isPaymentDone", "==", true)
        .where("isExist", "==", true)
        .where("clientID", "==", clientID)
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadPaidInvoicesSuccess(data))
          },
          (err) => {
            console.error(err)
            const msg = "Failed to load paid invoices"
            errorMsg(msg)
            return dispatch(loadPaidInvoicesFailure(msg))
          }
        )
    : listAll
    ? getFirebase()
        .firestore()
        .collection("INVOICES")
        .where("isPaymentDone", "==", true)
        .where("isExist", "==", true)
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadPaidInvoicesSuccess(data))
          },
          (err) => {
            console.error(err)
            const msg = "Failed to load paid invoices"
            errorMsg(msg)
            return dispatch(loadPaidInvoicesFailure(msg))
          }
        )
    : getFirebase()
        .firestore()
        .collection("INVOICES")
        .where("isPaymentDone", "==", true)
        .where("isExist", "==", true)
        .where("employeeID", "==", employeeID)
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadPaidInvoicesSuccess(data))
          },
          (err) => {
            console.error(err)
            const msg = "Failed to load paid invoices"
            errorMsg(msg)
            return dispatch(loadPaidInvoicesFailure(msg))
          }
        )

  dispatch(setListener(subscribe, "paidInvoices"))
}

export const loadAllInvoices = (payload) => (
  dispatch,
  getState,
  { getFirebase }
) => {
  const { listAll, employeeID, clientID, isClientView } = payload
  dispatch(loadAllInvoicesReq())
  const subscribe = isClientView
    ? getFirebase()
        .firestore()
        .collection("INVOICES")
        .where("isExist", "==", true)
        .where("clientID", "==", clientID)
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadAllInvoicesSuccess(data))
          },
          (err) => {
            console.error(err)
            const msg = "Failed to load all invoices"
            errorMsg(msg)
            return dispatch(loadAllInvoicesFailure(msg))
          }
        )
    : listAll
    ? getFirebase()
        .firestore()
        .collection("INVOICES")
        .where("isExist", "==", true)
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadAllInvoicesSuccess(data))
          },
          (err) => {
            console.error(err)
            const msg = "Failed to load all invoices"
            errorMsg(msg)
            return dispatch(loadAllInvoicesFailure(msg))
          }
        )
    : getFirebase()
        .firestore()
        .collection("INVOICES")
        .where("isExist", "==", true)
        .where("employeeID", "==", employeeID)
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (snap) => {
            const data = JSutils._array_to_object(
              snap.docs.map((doc) => doc.data()),
              "id"
            )
            return dispatch(loadAllInvoicesSuccess(data))
          },
          (err) => {
            console.error(err)
            const msg = "Failed to load all invoices"
            errorMsg(msg)
            return dispatch(loadAllInvoicesFailure(msg))
          }
        )
  dispatch(setListener(subscribe, "allInvoices"))
}

export const unSubscribeListener = (type) => (dispatch, getState) => {
  const invoiceState = getState().invoice.invoiceList
  const unsubscribe = invoiceState[type].listener
  unsubscribe()
  return dispatch(unSubscribeListenerAction({ type }))
}

export const makeInvoiceVoid = (invoiceID) => (dispatch) => {
  dispatch(makeInvoiceVoidReq(invoiceID))
  const encodedID = encodeURIComponent(invoiceID)
  return make_API_call("put", `/invoices/makevoid?invoiceID=${encodedID}`)
    .then((res) => {
      stopWaitMsg()
      successMsg(res.message)
      return dispatch(makeInvoiceVoidSuccess(invoiceID))
    })
    .catch((err) => {
      stopWaitMsg()
      errorMsg(err.message)
      return dispatch(makeInvoiceVoidFailure(invoiceID))
    })
}

export const sendInvoiceToClient = (inputs, invoiceID) => (dispatch) => {
  dispatch(sendInvoiceToClientReq(invoiceID))
  const encodedID = encodeURIComponent(invoiceID)
  return make_API_call(
    "post",
    `/invoices/sendinvoicetoclient?invoiceID=${encodedID}`,
    inputs
  )
    .then((res) => {
      stopWaitMsg()
      successMsg(res.message)
      return dispatch(sendInvoiceToClientSuccess(invoiceID))
    })
    .catch((err) => {
      stopWaitMsg()
      errorMsg(err.message)
      return dispatch(sendInvoiceToClientFailure(invoiceID))
    })
}

export const loadInvoiceAttachments =
  (invoice, invoiceID) =>
  (dispatch, getState, { getFirebase }) => {
    console.log(invoice);
    dispatch(loadInvoiceAttachmentsReq(invoiceID));
    // const invoiceListState = getState().invoice.invoiceList
    let attachments = [];
    // generated
    const storage = getFirebase().storage();
    const { invoiceDetails = {} } = invoice;
    const generatedInvoiceFilePath = invoiceDetails.generatedInvoiceFilePath;

    // attached
    if (invoice.invoiceBy !== "external") {
      
      // FIXME : This needs to be verified

      // const timesheetAttachmentInInvoice = {
      //   name: invoice.timesheetAttachment.sourcePath.split("/").reverse()[0],
      //   url: invoice.timesheetAttachment.publicURL,
      //   type: "timesheet",
      //   submissionType: "attached",
      // }

      // attachments.push(timesheetAttachmentInInvoice)

      // const expenseAttachmentInInvoice = {
      //   name: invoice.expenseAttachment.sourcePath.split("/").reverse()[0],
      //   url: invoice.expenseAttachment.publicURL,
      //   type: "expense",
      //   submissionType: "attached",
      // };
      // attachments.push(expenseAttachmentInInvoice);

      const submittedTimesheetsURLs = invoice.timesheets.map((item) => {
        return {
          name: item.attachmentDetails.sourcePath.split("/").reverse()[0],
          url: item.attachmentDetails.publicURL,
          type: "timesheet",
          submissionType: "attached",
        };
      });

      attachments = [...attachments, ...submittedTimesheetsURLs];

      const submittedExpensesURLs = invoice.expenses.map((item) => {
        return {
          name: item.attachmentDetails.sourcePath.split("/").reverse()[0],
          url: item.attachmentDetails.publicURL,
          type: "expense",
          submissionType: "attached",
        };
      });

      attachments = [...attachments, ...submittedExpensesURLs];
    }

    function fetchAsyncData() {
      return (
        storage
          .refFromURL(generatedInvoiceFilePath)
          .getDownloadURL()
          .then((url) => {
            attachments.push({
              name: invoiceID,
              url: url,
              type: "invoice",
              submissionType: "generated",
            });
          })
          .then(() => {
            let promises = [];
            if (invoice.invoiceBy !== "external")
              promises = invoice.timesheets.map(async (item) => {
                const path = `EMPLOYEES/${invoice.employeeID}/TIMESHEETS/GENERATED/${item.id}.pdf`;
                const firebaseStorage = firebase.storage();
                var pathReference = firebaseStorage.ref(path);
                return pathReference.getDownloadURL();
              });
            return Promise.all(promises);
            // return
          })
          // .then((generatedTimesheetUrls) => {
          //   const generatedTimesheets = generatedTimesheetUrls.map((url, index) => {
          //     return {
          //       name: invoice.timesheets[index].id + ".pdf",
          //       url: url,
          //       type: "timesheet",
          //       submissionType: "generated",
          //     }
          //   })
          //   return (attachments = [...attachments, ...generatedTimesheets])
          // })
          .catch((err) => {
            throw err;
          })
      );
    }

    fetchAsyncData()
      .then(() => {
        return dispatch(
          loadInvoiceAttachmentsSuccess({
            invoiceID: invoiceID,
            data: attachments,
          })
        );
      })
      .catch((err) => {
        console.error(err);
        stopWaitMsg();
        const msg = "Failed to load invoice attachments for " + invoiceID;
        errorMsg(msg);
        return dispatch(
          loadInvoiceAttachmentsFailure({ invoiceID: invoiceID, error: msg })
        );
      });
  };

export const clientApprove = (email, invoiceID) => (dispatch) => {
  dispatch(clientApproveReq());
  return make_API_call(
    "post",
    `${
      configuration.REST_api
    }/invoices/clientapprove?invoiceID=${encodeURIComponent(
      invoiceID
    )}&approvedBy=${email}`
  )
    .then((res) => {
      dispatch(clientApproveSuccess());
      return successMsg(res.message);
    })
    .catch((err) => {
      console.error(err);
      dispatch(clientApproveFailure());
      return errorMsg(err.message);
    });
};

export const clientReject = (email, invoiceID, reason) => (dispatch) => {
  dispatch(clientRejectReq());
  return make_API_call(
    "post",
    `${
      configuration.REST_api
    }/invoices/clientreject?invoiceID=${encodeURIComponent(
      invoiceID
    )}&rejectedBy=${email}`,
    { reason }
  )
    .then((res) => {
      dispatch(clientRejectSuccess());
      return successMsg(res.message);
    })
    .catch((err) => {
      console.error(err);
      dispatch(clientRejectFailure());
      return errorMsg(err.message);
    });
};

export const sendReminder = (invoiceIds) => (dispatch) => {
  dispatch(sendInvoiceReminderReq());
  const promises = invoiceIds.map((id) => {
    const encodedID = encodeURIComponent(id);
    return make_API_call(
      "post",
      `/invoices/remindertoclient?invoiceID=${encodedID}`
    );
  });
  return Promise.all(promises)
    .then((res) => {
      dispatch(sendInvoiceReminderSuccess());
      return successMsg(res.message);
    })
    .catch((err) => {
      console.error(err);
      dispatch(sendInvoiceReminderFailure());
      return errorMsg(err.message);
    });
};

// export const sendBulkMail = (selectedInvoices) => (dispatch, getState) => {
//   const attachments = state.invoice.invoiceList.sendInvoiceToClient.attachments
//   const invoiceIds = Object.keys(attachments)

//   const promises = selectedInvoices.map(row => {
//     const encodedID = encodeURIComponent(row.id)
//     const { to = [], cc = [], bcc = [] } = row.mailReceivers
//     const subject = row.isMailedToClient ? "Re: " + row.id : row.id
//     const attachmentsInState = attachments[row.id]
//     const timesheetAttachments = attachmentsInState.data.filter(item => item.type === "timesheet")
//     const expenseAttachments = attachmentsInState.data.filter(item => item.type === "expense")
//     const invoiceAttachments = attachmentsInState.data.filter(item => item.type === "invoice")
//     // let content = `
//     //     Hello ${row.clientName},
//     //     <br>
//     //     An invoice has been generated for you by ${companyDetails.companyName}.
//     //     <br>
//     //     Find the details below in brief:
//     //     <br>
//     //     <span class='d-flex' ><span>Invoice Number:</span> <b>${row.id}</b></span>\n
//     //     <span class='d-flex' ><span>Invoice Amount:</span> <b>$ ${row.openBalance}</b></span>

//     //     <br>
//     //     Please note that the payment is due by ${row.invoiceDueDate}.
//     //     <br>
//     //     If you have any questions related to this invoice, please contact us as soon as possible.
//     //     <br>
//     //     Please click ${`<a target='_blank' href='${invoiceAttachments.length ? invoiceAttachments[0].url : ""}' >View Invoice</a>`} for Approved Timesheets.
//     //     <div>
//     //     <h3><u>Timesheets</u></h3>
//     //       ${timesheetAttachments.map(item => {
//     //   return `
//     //             <div>
//     //               <a target='_blank' href='${item.url}' >${item.name}</a>
//     //             </div>
//     //             <br>
//     //           `
//     // })
//     //   }
//     //     </div>

//     //     <hr><br>
//     //     <div>
//     //     <h3><u>Expenses</u></h3>
//     //       ${expenseAttachments.map(item => {
//     //     return `
//     //             <div>
//     //               <a target='_blank' href='${item.url}' >${item.name}</a>
//     //             </div>
//     //             <br>
//     //           `
//     //   })
//     //   }
//     //     </div>
//     //   <hr><br>

//     //     Thanks & Regards,
//     //       `
//     const mail = {
//       to,
//       cc,
//       bcc,
//       subject,
//       body: content
//     }
//     return make_API_call(
//       "post",
//       `/invoices/sendinvoicetoclient?invoiceID=${encodedID}`,
//       mail
//     )
//       .then((res) => {
//         stopWaitMsg()
//         successMsg(res.message)
//         return dispatch(sendInvoiceToClientSuccess(invoiceID))
//       })
//       .catch((err) => {
//         errorMsg(err.message)
//         return dispatch(sendInvoiceToClientFailure(invoiceID))
//       })
//   })

//   return Promise.all(promises)
//     .then(() => {
//       // successMsg(res.message)
//     })
//   // return
// }

export const sendBulkEmail =
  (selectedInvoices, isReminder) =>
  (dispatch, getState, { getFirebase }) => {
    const companyDetails = getState().console.companyDetails;
    const promises = selectedInvoices.map((invoice) => {
      const invoiceID = invoice.id;
      let attachments = [];
      // generated
      const storage = getFirebase().storage();
      const { invoiceDetails = {} } = invoice;
      const generatedInvoiceFilePath = invoiceDetails.generatedInvoiceFilePath;

      // attached
      if (invoice.invoiceBy !== "external") {
        // const timesheetAttachmentInInvoice = {
        //   name: invoice.timesheetAttachment.sourcePath.split("/").reverse()[0],
        //   url: invoice.timesheetAttachment.publicURL,
        //   type: "timesheet",
        //   submissionType: "attached",
        // }

        // attachments.push(timesheetAttachmentInInvoice)

        // const expenseAttachmentInInvoice = {
        //   name: invoice.expenseAttachment.sourcePath.split("/").reverse()[0],
        //   url: invoice.expenseAttachment.publicURL,
        //   type: "expense",
        //   submissionType: "attached",
        // }
        // attachments.push(expenseAttachmentInInvoice)

        const submittedTimesheetsURLs = invoice.timesheets.map((item) => {
          return {
            name: item.attachmentDetails.sourcePath.split("/").reverse()[0],
            url: item.attachmentDetails.publicURL,
            type: "timesheet",
            submissionType: "attached",
          };
        });

        attachments = [...attachments, ...submittedTimesheetsURLs];

        const submittedExpensesURLs = invoice.expenses.map((item) => {
          return {
            name: item.attachmentDetails.sourcePath.split("/").reverse()[0],
            url: item.attachmentDetails.publicURL,
            type: "expense",
            submissionType: "attached",
          };
        });

        attachments = [...attachments, ...submittedExpensesURLs];
      }

      function fetchAsyncData() {
        return (
          storage
            .refFromURL(generatedInvoiceFilePath)
            .getDownloadURL()
            .then((url) => {
              attachments.push({
                name: invoiceID,
                url: url,
                type: "invoice",
                submissionType: "generated",
              });
            })
            .then(() => {
              let nestPromises = [];
              if (invoice.invoiceBy !== "external")
                nestPromises = invoice.timesheets.map(async (item) => {
                  const path = `EMPLOYEES/${invoice.employeeID}/TIMESHEETS/GENERATED/${item.id}.pdf`;
                  const firebaseStorage = firebase.storage();
                  var pathReference = firebaseStorage.ref(path);
                  return pathReference.getDownloadURL();
                });
              return Promise.all(nestPromises);
              // return
            })
            // .then((generatedTimesheetUrls) => {
            //   const generatedTimesheets = generatedTimesheetUrls.map(
            //     (url, index) => {
            //       return {
            //         name: invoice.timesheets[index].id + ".pdf",
            //         url: url,
            //         type: "timesheet",
            //         submissionType: "generated",
            //       }
            //     }
            //   )
            //   return (attachments = [...attachments, ...generatedTimesheets])
            // })
            .then(() => {
              const timesheetAttachments = attachments.filter(
                (item) => item.type === "timesheet"
              );
              const expenseAttachments = attachments.filter(
                (item) => item.type === "expense"
              );
              const invoiceAttachments = attachments.filter(
                (item) => item.type === "invoice"
              );
              let content = `
          <p>Hello ${invoice.clientName},</p>
          <br/>
          <p>An invoice for <b>$ ${invoice.openBalance}</b> is due by <b>${
                invoice.invoiceDueDate
              }</b>.</p>
          <p>Visit the link below to view your invoice which has been generated by ${
            companyDetails.companyName
          }.</p>
          <p>${`<a target='_blank' href='${
            invoiceAttachments.length ? invoiceAttachments[0].url : ""
          }' >View Invoice</a>`}</p>
          <br/>
          <div>
            <h3><u>Timesheets</u></h3>
              ${timesheetAttachments.map((item) => {
                return `
                    <a target='_blank' href='${item.url}' >${item.name}</a><br/>
                  `;
              })}
            </div>
            <br/>
            <div>
              <h3><u>Expenses</u></h3>
                ${expenseAttachments.map((item) => {
                  return `
                    <a target='_blank' href='${item.url}' >${item.name}</a><br/>
                  `;
                })}
          </div>
          <br/>
          <hr/>
          <p>Any queries related to this Invoice, please contact us asap.</p>
          <p>As they are bulk generated Invoices.</p>
          `;
              const encodedID = encodeURIComponent(invoice.id);
              const { to = [], cc = [], bcc = [] } = invoice.mailReceivers;
              let dateDiff = 0,
                reminderSubject = "";
              if (isReminder) {
                dateDiff = differenceInDays(
                  new Date(),
                  new Date(invoice.invoiceDueDateTimestamp)
                );
                reminderSubject = `REMAINDER #${
                  dateDiff > 0 ? dateDiff : "0"
                }: Due Date has been passed for an Invoice ${invoice.id}`;
              }
              const subject = isReminder
                ? reminderSubject
                : invoice.isMailedToClient
                ? "Re: " + invoice.id
                : invoice.id;
              const mail = {
                to,
                cc,
                bcc,
                subject,
                body: content,
              };
              return make_API_call(
                "post",
                `/invoices/sendinvoicetoclient?invoiceID=${encodedID}`,
                mail
              );
            })
            .then(() => {
              return successMsg(`Mail sent successfully to ${invoiceID}`);
              // return {
              //   isFailed: false,
              //   invoiceID
              // }
            })
            .catch((err) => {
              console.error(err);
              return errorMsg(`Failed to send email to ${invoiceID}`);
              // return {
              //   isFailed: true,
              //   invoiceID
              // }
            })
        );
      }

      if (generatedInvoiceFilePath) return fetchAsyncData();
      return {
        isFailed: true,
        invoiceID,
      };
      // errorMsg(`Failed to send email for ${invoiceID}`)
      // return Promise.resolve()
    });

    return Promise.all(promises);
    // .then((details) => {
    //   details.forEach(item => {
    //     if (item.isFailed)
    //       errorMsg(`Failed to send email to ${item.invoiceID}`)
    //     else
    //       successMsg(`Mail sent successfully to ${item.invoiceID}`)

    //   })
    // }).catch(err => {
    //   console.error(err);
    //   // return errorMsg(`Failed to send mails`)
    // })
  };
