import React from "react";
import Chart from "react-apexcharts";

const Branches = () => {
  const apexBarChartOpts = {
    chart: {
      foreColor: "#979797",
      height: 267,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 3,
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#32A675", "#FFC400"],
    legend: {
      show: true,
    },
    xaxis: {
      categories: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      axisBorder: {
        show: true,
        color: "rgba(0,0,0,0.05)",
      },
      axisTicks: {
        show: true,
        color: "rgba(0,0,0,0.05)",
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
        color: "rgba(0,0,0,0.05)",
      },
      axisTicks: {
        show: true,
        color: "rgba(0,0,0,0.05)",
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: ["transparent", "transparent"],
        opacity: 0.2,
      },
      borderColor: "rgba(0, 0, 0, .1)",
    },
    fill: {
      opacity: 1,
    },
  };

  const apexBarChartData = [
    {
      name: "Offer Letter",
      data: [40, 25, 30, 20, 25, 35, 30, 20, 10, 15, 15, 20],
    },
    {
      name: "Relieving Letter",
      data: [30, 20, 25, 15, 10, 20, 35, 25, 20, 30, 35, 40],
    },
  ];

  return (
    <div className="card-border mt-24">
      <div className="card-border-title mt-1 pb-0">
        Offer Letter & Relieving Letter Statistics
      </div>
      <div className="card-body pt-0">
        <Chart
          options={apexBarChartOpts}
          series={apexBarChartData}
          type="line"
          className="apex-charts mt-1"
          height={267}
        />
      </div>
    </div>
  );
};

export default Branches;
