import ACTION from "./actionTypes";

export const setToInitial = () => ({
  type: ACTION.SET_TO_INITIAL_STATE,
});

export const documentsReq = () => ({
  type: ACTION.GET_CLIENT_DOCUMENTS_REQ,
});

export const documentsSuccess = (payload) => ({
  type: ACTION.GET_CLIENT_DOCUMENTS_SUCCESS,
  payload,
});

export const documentsError = (payload) => ({
  type: ACTION.GET_CLIENT_DOCUMENTS_FAILURE,
  payload,
});

export function countriesRequest() {
  return {
    type: ACTION.COUNTRIES_REQUEST,
  };
}

export function countriesError(err) {
  return {
    type: ACTION.COUNTRIES_FAILURE,
    err,
  };
}

export function countriesSuccess(payload) {
  return {
    type: ACTION.COUNTRIES_SUCCESS,
    payload,
  };
}

export function setBusinessInformation(payload) {
  return {
    type: ACTION.SET_NEW_CLIENT_BUSINESS_INFORMATION,
    payload,
  };
}

export function setContactsInformation(payload) {
  return {
    type: ACTION.SET_NEW_CLIENT_CONTACTS,
    payload,
  };
}

export function setAccountsInformation(payload) {
  return {
    type: ACTION.SET_NEW_CLIENT_ACCOUNTS,
    payload,
  };
}

export function setLocationsInformation(payload) {
  return {
    type: ACTION.SET_NEW_CLIENT_LOCATIONS,
    payload,
  };
}

export function loadClientProjectsRequest() {
  return {
    type: ACTION.LOAD_CLIENT_PROJECTS_REQUEST,
  };
}

export function loadClientProjectsFailure(err) {
  return {
    type: ACTION.LOAD_CLIENT_PROJECTS_FAILURE,
    err,
  };
}

export function loadClientProjectsSuccess(payload) {
  return {
    type: ACTION.LOAD_CLIENT_PROJECTS_SUCCESS,
    payload,
  };
}

export const clientsLocationReq = () => {
  return {
    type: ACTION.GET_CLIENTS_LOCATIONS_REQ,
  };
};

export const clientsLocationSucc = (payload) => {
  return {
    type: ACTION.GET_CLIENTS_LOCATIONS_SUCCESS,
    payload,
  };
};
export const clientsLocationFailure = (payload) => {
  return {
    type: ACTION.GET_CLIENTS_LOCATIONS_FAILURE,
    payload,
  };
};
export const invoiceLocationReq = () => {
  return {
    type: ACTION.GET_INVOICE_LOCATION_REQ,
  };
};

export const invoiceLocationSucc = (payload) => {
  return {
    type: ACTION.GET_INVOICE_LOCATION_SUCCESS,
    payload,
  };
};
export const invoiceLocationFailure = (payload) => {
  return {
    type: ACTION.GET_INVOICE_LOCATION_FAILURE,
    payload,
  };
};

export const getMetaInfoClientsRequest = () => {
  return {
    type: ACTION.GET_META_INFO_CLIENTS_REQUEST,
  };
};

export const getMetaInfoClientsSuccess = (payload) => {
  return {
    type: ACTION.GET_META_INFO_CLIENTS_SUCCESS,
    payload
  };
};

export const getMetaInfoClientsFailure = (err) => {
  return {
    type: ACTION.GET_META_INFO_CLIENTS_FAILURE,
    err,
  };
}