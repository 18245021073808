import React from "react";
import { Formik, Form, Field } from "formik";
import { Row, Col, Modal } from "react-bootstrap";
import AppModalHeader from "../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../shared/components/Modals/AppModalFooter";
import SelectFormField from "../../shared/components/BootStrapFormFields/SelectFormField";
import TextFormField from "../../shared/components/BootStrapFormFields/TextFormField";
import DatePickerField from "../../shared/components/BootStrapFormFields/DatePickerField";
import FileUploadForm from "../../shared/components/BootStrapFormFields/FileUploadForm";
import * as yup from "yup";
import { useDialog } from "../../hooks/useDialog";
import { FaPen } from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	createDeduction,
	getDeduction,
	updateDeduction,
} from "../../api/services/default/deductions";
import RichTextEditor from "../../shared/components/BootStrapFormFields/RichTextEditor";

const schema = yup.object().shape({
	advanceType: yup.string().required("Advance Type is required"),
	chequeNumber: yup.string().required("Cheque Number is required"),
	amountTobeDeducted: yup
		.number()
		.required("Amount to be deducted is required"),
	deductPer: yup.string().required("Deduct per is required"),
	givenDate: yup.date().required("Given date is required"),
	effectiveFrom: yup
		.date()
		.required("Effective from is required")
		.test(
			"is-greater",
			"Effective from must be greater than given date",
			function (value) {
				return new Date(value) > new Date(this.parent.givenDate);
			}
		),
	amount: yup.number().required("Total amount is required"),
	attachment: yup.string().required("Cheque is required"),
	notes: yup.string(),
});

export default function CreateDeductionModal({ id, employeeId }) {
	const { open, handleClose, handleOpen } = useDialog();

	const { data: deduction, refetch } = useQuery({
		queryKey: ["getDeduction", id],
		queryFn: () => getDeduction(id),
		enabled: !!id,
	});

	const queryClient = useQueryClient();

	const useCreateDeduction = useMutation({
		mutationFn: (data) => createDeduction(data),
	});

	const useUpdateDeduction = useMutation({
		mutationFn: ({ id, data }) => updateDeduction(id, data),
		onSuccess() {
			refetch();
		},
	});

	const getTitleText = () => {
		if (id) {
			return "Edit Deduction";
		} else {
			return "Create Deduction";
		}
	};

	let advanceList = ["Advance", "Premium", "Miscellaneous"];
	let deductList = ["Per Paycheque", "Per Bi-Paycheque"];

	const optionMapper = (i) => ({ value: i, label: i });

	const handleSubmit = async (values, actions) => {
		const data = {
			advanceType: values.advanceType,
			chequeNumber: values.chequeNumber,
			amount: values.amount,
			amountTobeDeducted: values.amountTobeDeducted,
			deductPer: values.deductPer,
			givenDate: values.givenDate,
			effectiveFrom: values.effectiveFrom,
			attachment: values.attachment,
			employeeId: employeeId,
			notes: values.notes,
		};

		if (id) {
			await useUpdateDeduction.mutateAsync({ id, data });
		} else {
			await useCreateDeduction.mutateAsync(data);
		}
		await queryClient.invalidateQueries(["getAllDeductions", employeeId]);
		handleClose();
	};

	return (
		<>
			<div>
				{id ? (
					<button className="btn mt-1" onClick={handleOpen}>
						<FaPen style={{ fontSize: "18px" }} className="text-success" />
					</button>
				) : (
					<button
						className="btn btn-success px-4 btn-md text-high"
						onClick={handleOpen}
					>
						Create Deduction
					</button>
				)}
			</div>
			<Modal show={open} onHide={handleClose} size="lg" centered>
				<Formik
					validationSchema={schema}
					initialValues={{
						...(deduction || {}),
						deductPer: deductList[0],
					}}
					onSubmit={handleSubmit}
					enableReinitialize
				>
					{(formik) => {
						return (
							<Form>
								<AppModalHeader title={getTitleText()} />
								<Modal.Body>
									<Row>
										<Col xl={6}>
											<Field
												name="advanceType"
												label="Deduction Type *"
												options={advanceList?.map(optionMapper)}
												type="select"
												component={SelectFormField}
											/>
										</Col>
										<Col xl={6}>
											<Field
												name="chequeNumber"
												label="Cheque Number *"
												component={TextFormField}
											/>
										</Col>
										<Col xl={6}>
											<Field
												name="amount"
												label="Total amount *"
												type="number"
												component={TextFormField}
											/>
										</Col>
										<Col xl={6}>
											<Row>
												<Col xl={8}>
													<Field
														name="deductPer"
														label="Deduct per *"
														type="text"
														component={TextFormField}
														value={formik.values.deductPer}
														readOnly
													/>
												</Col>
												<Col xl={4}>
													<Field
														name="amountTobeDeducted"
														label="$ *"
														type="number"
														component={TextFormField}
													/>
												</Col>
											</Row>
										</Col>
										<Col xl={6}>
											<Field
												name="givenDate"
												label="Given date *"
												type="date"
												component={DatePickerField}
												onValueChange={() => formik.setFieldTouched("effectiveFrom", true, true)}
											/>
										</Col>
										<Col xl={6}>
											<Field
												name="effectiveFrom"
												label="Deduct Effective from *"
												type="date"
												component={DatePickerField}
												minDate={formik.values.givenDate}
											/>
										</Col>
										<Col xl={6}>
											<Field
												name="attachment"
												label={"Upload Cheque *"}
												type="file"
												component={FileUploadForm}
												storageDir={`DEDUCTIONS/${employeeId}`}
											/>
										</Col>
										<Col xl={6}>
											<Field
												component={RichTextEditor}
												label="Payable To"
												name="notes"
												placeholder="Please type here..."
												options={{
													mode: "Balloon-always",
												}}
												height="auto"
											/>
										</Col>
									</Row>
								</Modal.Body>
								<AppModalFooter
									saveButtonDisabled={
										!formik.isValid || formik.isSubmitting || !formik.dirty
									}
								/>
							</Form>
						);
					}}
				</Formik>
			</Modal>
		</>
	);
}
