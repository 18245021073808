import React, { useMemo } from "react";
import FlairTable, {
	convertMaterialToAgGridColumns,
} from "../../../shared/FlairTable";
import { useQuery } from "@tanstack/react-query";
import { getExitReport } from "../../../api/services/default/payrolls/reports";
import { getDaysInMonth, getMonth, getYear, isWithinInterval } from "date-fns";
import validate from "../../../shared/validation";
import MetaInfo from "../../../shared/getMetaInfo";
import { CalendarFormatter } from "../../../shared/CalendarFormatter";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import DatePickerField from "../../../shared/components/BootStrapFormFields/DatePickerField";
import { TimesheetHover } from "./TimesheetHover";
import GlobalSettings from "./GlobalSettings";
import ExitActions from "./Actions/Exit/Actions";
import { Link } from "react-router-dom";
import SelectFormField from "../../../shared/components/BootStrapFormFields/SelectFormField";

export default function ExitReport() {
	const [reportingDate, setReportingDate] = React.useState();
	const [employeeIds, setEmployeeIds] = React.useState([]);
	const [dates, setDates] = React.useState([]);
	const tableRef = React.useRef();
	const metaInfo = new MetaInfo();

	const discountMapper = (discountDetails) => {
		if (Object.keys(discountDetails).length > 0) {
			const { type, value } = discountDetails;
			if (type === "byPercentage") {
				return `${value}%`;
			}
			return validate.currencyFormatterUs(value);
		}
		return "";
	};

	const { isFetching: isLoading, refetch, data } = useQuery({
		queryKey: ["getExitReport", reportingDate, employeeIds],
		queryFn: () =>
			getExitReport(reportingDate.getMonth(), reportingDate.getFullYear(), employeeIds),
		enabled: false,
	});

	const rows = useMemo(() => {
		return data?.payrollDetails?.map((item) => {
			const timesheetsDates = dates
				.map((date, index) => {
					const isDateInPlacementRange =
						item.startDate && item.endDate
							? isWithinInterval(new Date(date), {
									start: new Date(item.startDate),
									end: new Date(item.endDate),
								})
							: false;
					return {
						[`DateOfMonth${index}`]:
							item?.timesheetsDataset[date]?.totalHours ??
							(isDateInPlacementRange ? "0" : "--"),
					};
				})
				.reduce(function (acc, x) {
					for (const key in x) acc[key] = x[key];
					return acc;
				}, {});
			return {
				isAllAccountsClear: item.isAllAccountsClear,
				employeeName: item.employeeName,
				employeeID: item.employeeID,
				payrollId: item.payrollId,
				eVerifyDate: item.eVerifyDate,
				i9Date: item.i9Date,
				dateOfJoining: item.dateOfJoining,
				dateOfSuspension:
					data?.suspendedDetails?.[item.employeeID]?.latestRelievingDate ??
					"",
				companyName: item.companyName,
				clientName: item.clientName,
				branch: item.branch,
				placementID: item.placementID,
				workAuthorizationType: item.workAuthorizationType
					.map((i) => i?.work_type ?? "")
					.join(", "),
				workAuthData: item.workAuthorizationType,
				startDate: item.startDate,
				endDate: item.endDate,
				employeeCategory: item.employeeCategory,
				billingRate: item.billingRate
					.map(validate.currencyFormatterUs)
					.join(", "),
				payRate: item.payRate.map(validate.currencyFormatterUs).join(", "),
				payPercentage: item.payPercentage.map((i) => `${i}%`).join(", "),
				effectiveUntil: item.effectiveUntil.join(", "),
				invoiceFrequency: item.invoiceFrequency,
				hrs:
					Object.keys(item.timesheetsDataset).length === dates.length
						? "Full"
						: "Partial",
				submittedHours: item.submittedHours,
				approvedHours: item.approvedHours,
				totalHours: item.totalEntireHours,
				totalPay: item.totalEarnedPay
					? validate.currencyFormatterUs(item.totalEarnedPay)
					: "--",
				timesheetsDataset: item.timesheetsDataset,
				lastSubmission: item.submissionData[0]?.submissionDate ?? "",
				submissionData: item.submissionData,
				discount: item.discountDetails.map(discountMapper).join(", "),
				...timesheetsDates,
			};
		});
	}, [data, dates]);

	const cellStyle = (params) => {
		const row = params.data;
		const date = params.colDef.headerName;
		const isDateInPlacementRange =
			row.startDate && row.endDate
				? isWithinInterval(new Date(date), {
						start: new Date(row.startDate),
						end: new Date(row.endDate),
				  })
				: false;
		return {
			backgroundColor: !isDateInPlacementRange
				? "#dbdbd9"
				: row.timesheetsDataset[date]?.status === "approved"
				? "green"
				: row.timesheetsDataset[date]?.status === "rejected"
				? "red"
				: row.timesheetsDataset[date]?.status === "pending"
				? "orange"
				: "white",
		};
	};

	const columns = [
		{
			title: "Is All Accounts Clear",
			field: "isAllAccountsClear",
			render: (row) => <ExitActions row={row} />,
			// style: { width: '200px' },
		},
		{
			title: "Employee ID",
			field: "employeeID",
			render: (row) => (
				<Link to={`/console/employees/${row.employeeID}`}>
					{row.employeeID}
				</Link>
			),
		},
		{ title: "Payroll ID", field: "payrollId" },
		{
			title: "Employee Name",
			field: "employeeName",
			style: { width: "250px" },
		},
		{
			title: "E-Verify Date",
			field: "eVerifyDate",
			render: (row) => {
				const eVerifyData = row.eVerifyDate?.split(",");
				return eVerifyData?.map((item) => {
					return <div key={item}>{item}</div>;
				});
			},
		},
		{
			title: "I-9 Date",
			field: "i9Date",
			render: (row) => {
				const i9Data = row.i9Date?.split(",");
				return i9Data?.map((item) => {
					return <div key={item}>{item}</div>;
				});
			},
		},
		{ title: "Date of joining", field: "dateOfJoining", type: "date" },
		{ title: "Date of exit", field: "dateOfSuspension", type: "date" },
		{ title: "Company", field: "companyName" },
		{ title: "Client", field: "clientName" },
		{ title: "Branch", field: "branch" },
		{ title: "Job Code", field: "placementID" },
		{
			title: "Work Authorization",
			field: "workAuthorizationType",
			render: (row) => {
				const { workAuthData } = row;
				return workAuthData?.map((item) => {
					return (
						<div key={workAuthData?.id}>
							{item.work_type} - {item.activated_date}
						</div>
					);
				});
			},
		},
		{ title: "Job Start Date", field: "startDate", type: "date" },
		{ title: "Job End Date", field: "endDate", type: "date" },
		{ title: "Employee Category", field: "employeeCategory" },
		{ title: "Bill Rate", field: "billingRate", type: "currency" },
		{ title: "Pay Rate", field: "payRate", type: "currency" },
		{ title: "Pay Percentage", field: "payPercentage" },
		{ title: "Discount", field: "discount" },
		{ title: "Effective Until", field: "effectiveUntil" },
		{ title: "Invoice Frequency", field: "invoiceFrequency" },
		{ title: "Hours", field: "hrs" },
		{
			title: "Last submission date",
			field: "lastSubmission",
			render: (row) => {
				return <TimesheetHover data={row.submissionData} />;
			},
		},
		{ title: "Submitted Hours", field: "submittedHours" },
		{ title: "Approved Hours", field: "approvedHours" },
		{ title: "Total", field: "totalHours" },
		{ title: "Total Pay", field: "totalPay" },
	];

	const getRowHeight = React.useCallback((params) => {
		const { data } = params;
		let maxHeight = 35;
		const { workAuthData, employeeStatusData } = data;
		const workAuthDataLength = workAuthData?.length ?? 0;
		const employeeStatusDataLength = employeeStatusData?.length ?? 0;

		if (workAuthDataLength > 1) {
			const height = 35 * workAuthDataLength;
			maxHeight = height > maxHeight ? height : maxHeight;
		}

		if (employeeStatusDataLength > 1) {
			const height = 35 * employeeStatusDataLength;
			maxHeight = height > maxHeight ? height : maxHeight;
		}

		return maxHeight;
	}, []);

	const handleGenerateReport = async (values, actions) => {
		actions.setSubmitting(true);
		const { date } = values;
		const rDate = new Date(date);
		setEmployeeIds(values.employeeIds);
		setReportingDate(rDate);
		if (!date || isNaN(Date.parse(new Date(rDate)))) return;
		const month = getMonth(rDate);
		const year = getYear(rDate);
		const days = getDaysInMonth(new Date(year, month));
		const _dates = [];

		for (let i = 1; i <= days; i++) {
			const formDateFormat = CalendarFormatter.standardDateFormat(
				new Date(year, month, i)
			);
			_dates.push(formDateFormat);
		}

		setDates(_dates);
		const prevColumns = tableRef.current?.getColumnDefs();
		const updatedColumns = prevColumns.filter(
			(item) => !item.field.startsWith("DateOfMonth")
		);
		const newColumns = [
			...updatedColumns,
			...convertMaterialToAgGridColumns(
				_dates.map((date, index) => {
					return {
						title: date,
						field: `DateOfMonth${index}`,
						headerClass:
							new Date(date).getDay() === 0 || new Date(date).getDay() === 6
								? "bg-blue"
								: undefined,
						cellStyle: cellStyle,
					};
				})
			),
		];
		tableRef.current?.setColumnDefs(newColumns);
		await refetch();
		actions.setSubmitting(false);
	};

	return (
		<Container fluid className="p-0 m-0 mt-3 mb-3">
			<Row>
				<Col xl={6}>
					<GlobalSettings />
				</Col>
				<Col xl={6}>
					<Formik
						initialValues={{
							date: reportingDate,
							employeeIds: employeeIds,
						}}
						onSubmit={handleGenerateReport}
					>
						{({ values, isSubmitting }) => (
							<Form className="d-flex gap-3 align-items-center justify-content-end">
								<Field
									name="employeeIds"
									label="Employee"
									component={SelectFormField}
									isMulti
									options={Object.values(metaInfo.names)
										.filter((item) => item.status === "suspended")
										.map((item) => ({
											value: item.uid,
											label: item.name,
										}))}
									placeholder="Select at most 30 employees"
									styles={{
										control: (styles) => ({
											...styles,
											width: "250px",
										}),
									}}
								/>
								<Field
									name="date"
									label="Reporting Date"
									component={DatePickerField}
									dateFormat="MMMM yyyy"
									showMonthYearPicker
								/>
								<Button type="submit" disabled={isSubmitting || !values.date}>
									Generate Report
								</Button>
							</Form>
						)}
					</Formik>
				</Col>
			</Row>
			<FlairTable
				columns={columns}
				data={rows}
				tableRef={tableRef}
				isLoading={reportingDate ? isLoading : false}
				getRowHeight={getRowHeight}
			/>
		</Container>
	);
}
