import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';


const State = ({ setTabValue, ...props }) => {
    const {Invoices,paidDetails} = props
    const history = useHistory()
    return (
        <div className="onleft">
            <h5 className="card-title mt-1 mb-4 header-title text-white">INVOICES OVERVIEW</h5>
            <Row className='mb-4'>
                <Col className='border-end'>
                    <div className='d-flex align-items-center'>
                        <div className="stateicon">
                            <i className='uil uil-file-alt'></i>
                        </div>
                        <div className="idesc">
                            <p>All</p>
                            <h3 className='my-0'>{Invoices?.allInvoices?.count}</h3>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className="stateicon">
                            <i className='uil uil-check-circle'></i>
                        </div>
                        <div className="idesc">
                            <p>Paid</p>
                            <h3 className='my-0'>{Invoices?.paidInvoice?.count}</h3>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col className='border-end'>
                    <div className='d-flex align-items-center'>
                        <div className="stateicon">
                            <i className='uil uil-location-arrow'></i>
                        </div>
                        <div className="idesc">
                            <p>Sent</p>
                            <h3 className='my-0'>{Invoices?.sentInvoice?.count}</h3>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className="stateicon">
                            <i className='uil uil-info-circle'></i>
                        </div>
                        <div className="idesc">
                            <p>Due</p>
                            <h3 className='my-0'>{Invoices?.dueInvoice?.count}</h3>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col className='border-end'>
                    <div className='d-flex align-items-center'>
                        <div className="stateicon">
                            <i className='uil uil-plus-circle'></i>
                        </div>
                        <div className="idesc">
                            <p>Draft</p>
                            <h3 className='my-0'>{Invoices?.generatedInvoice?.count}</h3>
                        </div>
                    </div>
                </Col>
                <Col className='align-self-center'>
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className="stateicon">
                            <i className='uil uil-question-circle'></i>
                        </div>
                        <div className="idesc">
                            <p>Void</p>
                            <h3 className='my-0'>{Invoices?.voidInvoice?.count}</h3>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className="stateborder mb-4"></div>
            <h5 className="card-title mt-0 mb-4 header-title text-white">RECENT PAID INVOICES</h5>
            {
                paidDetails?.slice(0,3)?.map((res) => {
                    return (
                        <div className="oscard mb-3">
                        <h3 className='mt-0'>{res?.clientName}</h3>
                        <p className='mb-0'>Invoiced Date: {res?.invoiceDate}</p>
                    </div>
                    )
                })
            }
            <button className='btn btn-custom mb-1' onClick={() => {
                // history.push("/console/invoiceslist?tab=5")
                setTabValue('5')
            }}>See More</button>
        </div>
    );
};

export default State;
