import React, { useEffect } from "react"
import { connect } from "react-redux"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import { compose } from "redux"
import { deleteSignature } from "../../../middleware/authorizedSignatures"
import Presentation from "./Presentation"
import CircularSpinner from "../../../../../shared/circularSpinner"

function Container(props) {
  const { authorizedSignatures, _delete_signature, names, managers } = props
  useEffect(() => {}, [authorizedSignatures])

  const handleDelete = (id, employeeID) => {
    _delete_signature(id, employeeID)
  }

  return isLoaded(managers) ? (
    <div>
      <Presentation
        authorizedSignatures={authorizedSignatures}
        names={names}
        handleDelete={handleDelete}
      />
    </div>
  ) : (
    <CircularSpinner />
  )
}

const mapStateToProps = (state, ownProps) => {
  const { ordered, data } = state.firestore
  return {
    authorizedSignatures: ordered.authorizedSignatures,
    names: data.names,
    managers: ordered.managers,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    _delete_signature: (id, employeeID) => {
      dispatch(deleteSignature(id))
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(() => {
    return [
      {
        collectionGroup: "SIGNATURES",
        where: [["isExist", "==", true]],
        storeAs: "authorizedSignatures",
      },
      {
        collectionGroup: "MODULE_LEVEL_ACCESS",
        where: [["accessibles", "array-contains", "employee-self-services-manager"]],
        storeAs: "managers",
      },
    ]
  })
)(Container)
/*import React, { Component } from 'react';
import Presentation from './Presentation';
import firebase from '../../../../firebase_config'
import { DataContext } from '../../../../contexts/data'
import { SET_AUTHORIZED_SIGNATURES, DELETE_AUTHORISED_SIGNATURE } from '../../../../contexts/types'

class Container extends Component {

    static contextType = DataContext

    handleDelete = (signatureId, signatureUID) => {
        if(!window.confirm("Are you sure you want to delete the signature?"))
            return null
        const dispatch = this.context[1]
        dispatch({
            type : DELETE_AUTHORISED_SIGNATURE,
            payload : {
                signatureId,
                signatureUID
            }
        })
    }

    componentDidMount = () => {
        const dispatch = this.context[1]
        firebase.firestore().collection('authorisedSignatures').where("isActive","==",true)
        .onSnapshot(snap => {
            const signatures = snap.docs.map(doc => doc.data())
            dispatch({
                type: SET_AUTHORIZED_SIGNATURES,
                payload: signatures
            })
        })
    }

    render() {
        const [state] = this.context
        if(state.hasOwnProperty('auth_signatures'))
            return (
                <Presentation
                    handleDelete={this.handleDelete}
                />
            );
        return <p>Loading...</p>
    }
}

export default Container;*/
