import React from "react";
import AccountsClearModal from "./AccountsClearModal";

export default function ExitActions({ row }) {
	return (
		<div className="d-flex">
			<AccountsClearModal
				employeeId={row.employeeID}
				isAccountsClear={row.isAllAccountsClear}
			/>
		</div>
	);
}
