import { getIn } from "formik";
import { format, parse } from "date-fns";
import React from "react";
import { Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DatePickerField({
	field,
	form,
	required,
	minDate,
	onValueChange = () => {},
	dateFormat = "MM/dd/yyyy",
	...props
}) {
	const errorText =
		 getIn(form.errors, field.name);

	const handleDate = (date) => {
		if (date) {
			const convertedValue = format(date, dateFormat);
			onValueChange(convertedValue);
			return form.setFieldValue(field.name, convertedValue, true);
		} else {
			return form.setFieldValue(field.name, null, true);
		}
	};

	const selectedTimeStamp = field.value
		? parse(field.value, dateFormat, new Date())
		: null;

	return (
		<Form.Group className="mb-3">
			{props.label && <Form.Label>{props.label}</Form.Label>}
			<ReactDatePicker
				className={errorText ? "form-control is-invalid" : "form-control"}
				selected={selectedTimeStamp}
				onBlur={() => form.setFieldTouched(field.name, true, true)}
				onChange={handleDate}
				dateFormat={dateFormat}
				minDate={minDate ? parse(minDate, dateFormat, new Date()) : null}
				placeholderText={dateFormat}
				tabIndex={1}
				peekNextMonth
				showMonthDropdown
				showYearDropdown
				dropdownMode="select"
				{...props}
			/>
			{errorText && <Form.Text className="text-danger">{errorText}</Form.Text>}
		</Form.Group>
	);
}
