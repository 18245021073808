import React from 'react'
import {
  Table,
  Paper,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core"
import { withStyles, createStyles } from "@material-ui/core/styles"
import validate from "../../../../shared/validation"
import { useNewInvoiceContext } from './context'


const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell)

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow)

function ExpenseTable() {
  const { state, services } = useNewInvoiceContext()
  if (state.selectedExpenses?.length < 1) return <span></span>
  const employeeName = services.getNameOfTheEmployeeById(state.employeeID)
  const isExternalInvoice = state.invoiceBy == "external"


  return (
    <div>
      <>
        <h2>
          <u>Expenses</u>
        </h2>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>S.No</StyledTableCell>
                <StyledTableCell>Service Summary</StyledTableCell>
                <StyledTableCell>Activity</StyledTableCell>
                <StyledTableCell>Attachment</StyledTableCell>
                {
                  !isExternalInvoice ?
                    <StyledTableCell>Amount</StyledTableCell>
                    :
                    null
                }
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {state.selectedExpenses?.map((expense, index) => {
                return (
                  <StyledTableRow>
                    <StyledTableCell>
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell>
                      {employeeName +
                        "-" +
                        state.employeeID}
                    </StyledTableCell>
                    <StyledTableCell>
                      {`${expense.expenseType}(${validate.dateFormatter(
                        expense.spentDate
                      )})`}
                    </StyledTableCell>
                    <StyledTableCell>
                      {expense.attachmentDetails?.publicURL !== "" ? (
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={expense.attachmentDetails?.publicURL}
                        >
                          {" "}
                          {expense.attachmentDetails?.sourcePath?.slice(
                            expense.attachmentDetails?.sourcePath?.lastIndexOf(
                              "/"
                            ) + 1
                          )}{" "}
                        </a>
                      ) : (
                        "---"
                      )}
                    </StyledTableCell>
                    {
                      !isExternalInvoice ?
                        <StyledTableCell align="right">
                          {validate.currencyFormatterUs(expense.amount)}
                        </StyledTableCell>
                        :
                        null
                    }

                  </StyledTableRow>
                )
              })}

              {
                !isExternalInvoice ?
                  <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <h4>Subtotal</h4>
                    </TableCell>
                    <TableCell align="right">
                      <h4>
                        {validate.currencyFormatterUs(state.selectedExpenses?.reduce(
                          (total, expense) => total + Number(expense.amount),
                          0
                        ))}                        
                      </h4>
                    </TableCell>
                  </TableRow>
                  : <span></span>
              }

            </TableBody>
          </Table>
        </TableContainer>
      </>
    </div>
  )
}

export default ExpenseTable