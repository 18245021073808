import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import Navbar from "./Services/Dashboard/Navbar";
import { connect } from "react-redux";
import EmployeeRegisterPage from "./pages/EmployeeRegisterPage";
import { loadCompanyDetails } from "./Services/Console/middleware";
import LoginByToken from "./Services/Authentication/components/LoginByToken";
import ClientApprovePage from "./pages/ClientApprovePage";
import ClientRejectInvoicePage from "./pages/ClientRejectInvoicePage";
import InvoicePreviewPage from "./pages/InvoicePreviewPage";
import { configuration } from "./config/companyConfig";
import axios from "axios";

// Theme.scss
import './assets/scss/Theme.scss';

const base_url = configuration.REST_api;
axios.defaults.baseURL = base_url;

function App(props) {
  const { auth, _load_company_details } = props;

  useEffect(() => {
    _load_company_details();
  }, []);

  return auth.uid ? (
    <BrowserRouter>
        <Navbar />
        <Route exact path="/invoices" component={InvoicePreviewPage} />
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      <Switch>
        <Route exact path="/invoices" component={InvoicePreviewPage} />
        <Route
          exact
          path="/invitations/:token"
          component={EmployeeRegisterPage}
        />
        <Route path="/clientapprove" component={ClientApprovePage} />
        <Route path="/clientreject" component={ClientRejectInvoicePage} />
        <Route path="/loginrequest" component={LoginByToken} />
        <Route path="/" component={LoginPage} />
        <Route path="/login" component={LoginPage} />
      </Switch>
    </BrowserRouter>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = (dispatch) => ({
  _load_company_details: (data) => dispatch(loadCompanyDetails(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
