import React, { useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import TextFormField from "../../../../../../shared/components/BootStrapFormFields/TextFormField";
import { RegexExp } from "../../../../../../shared/regex";
import { useDispatch } from "react-redux";
import { updateProfile } from "../../../../middleware/dynamicProfileRender";
import AppModalHeader from "../../../../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../../../../shared/components/Modals/AppModalFooter";
import { Constants } from "../../../../../../shared/constants";
import SelectFormField from "../../../../../../shared/components/BootStrapFormFields/SelectFormField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateEmployee } from "../../../../../../api/services/default/employee";

const schema = yup.object().shape({
  name: yup.string().required("Name is required").matches(RegexExp.name,"Name should be valid"),
  phone: yup
    .string()
    .required("PhoneNumber is required")
    .matches(RegexExp.phonenumber,'Phone number should be valid'),
  emailid: yup.string().matches(RegexExp.email, "emailId should be valid"),
  relation: yup
    .string()
    .required("Relation should be valid text")
    .matches(RegexExp.name),
});

export default function EmargencyContactEditModal(props) {
  const { data, employeeID, idx, onHide } = props;
  const loadInitialValues = () => {
    if (!isNaN(idx)) {
      return data[idx];
    } else {
      return {};
    }
  };

  const getTitleText = () => {
    if (!isNaN(idx)) {
      return "Edit Emergency Contact";
    } else {
      return "Create Emergency Contact";
    }
  };

  const getSaveButtonText = () => {
    if (!isNaN(idx)) {
      return "Save Changes";
    } else {
      return "Create";
    }
  };

  const queryClient = useQueryClient();
  const useUpdateProfile = useMutation({
		queryKey: ["updateEmployee", employeeID],
		mutationFn: ({ employeeID, data }) => updateEmployee(employeeID, data),
		onSuccess: () => {
			queryClient.invalidateQueries(["getEmployee", employeeID]);
			onHide();
		},
	});

  const handleSubmit = async(values, actions) => {
    actions.setSubmitting(true);
    const formData = { ...values };
    let emergencycontact = data?.map((item) => item) || [];

    if (!isNaN(idx)) {
      emergencycontact[idx] = formData;
    } else {
      emergencycontact.push(formData);
    }

    const payload = {
      emergencycontact,
    };
    await useUpdateProfile.mutateAsync({ employeeID, data: payload });
		actions.setSubmitting(false);
  };

 
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        validationSchema={schema}
        validateOnMount={true}
        initialValues={{ ...loadInitialValues() }}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <AppModalHeader title={getTitleText()} />
              <Modal.Body>
                <Row>
                  <Col xl={4}>
                    <Field
                      name="name"
                      type="text"
                      label={"Name *"}
                      component={TextFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="phone"
                      type="text"
                      label={"Phone *"}
                      component={TextFormField}
                    />
                  </Col>
                  {/* <Col xl={4}>
                <StateField {...data}/>
                </Col>
                <Col xl={4}>
                <CountryField {...data}/>
                </Col> */}
                  <Col xl={4}>
                    <Field
                      name="emailid"
                      type="text"
                      label={"Email "}
                      component={TextFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="relation"
                      type="text"
                      label={"Relation *"}
                      component={TextFormField}
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <AppModalFooter
                saveButtonText={getSaveButtonText()}
                saveButtonDisabled={
                  !formik.isValid || formik.isSubmitting || !formik.dirty
                }
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
