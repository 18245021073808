import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getReviewDocument,
	reviewWorkQueueDocument,
} from "../../../MiddleWare";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DocumentsEditModal from "../../../../EmployeeManagment/components/NewProfileUI/general/Documents/documents";
import PassportEditModal from "../../../../EmployeeManagment/components/NewProfileUI/general/Passport/passport";
import WorkAuthorizationEditModal from "../../../../EmployeeManagment/components/NewProfileUI/general/WorkAuthorization/workauth";
import MailingAddressEditModal from "../../../../EmployeeManagment/components/NewProfileUI/general/MailingAddress/mailingaddress";
import EVerifyEditModal from "../../../../EmployeeManagment/components/NewProfileUI/general/Everification/everify";
import { useHistory } from "react-router-dom";
import SimpleBackdrop from "../../../../../shared/backDrop";
import { AlertService } from "../../../../../shared/alerts";

export default function ReviewDocument({
	employeeId,
	reviewId,
	sectionName = "default",
	workQueueId,
}) {
	const dispatch = useDispatch();
	const history = useHistory();
	const _alertService = new AlertService();

	const empModules = useSelector(
		(state) => state.employee.employeeModules.accessModules
	);
	const loggedInEmployeeId = useSelector((state) => state.firebase.auth.uid);

	const [open, setOpen] = React.useState(false);
	const [reviewData, setReviewData] = React.useState({});
	const [isManager, setIsManager] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);

	const getReviewDocumentState = useSelector(
		(state) => state.ExpiredDocs.expiredList.getReviewDocument
	);

	useEffect(() => {
		if (
			empModules.includes("employees-manager") ||
			empModules.includes("console-customization")
		) {
			setIsManager(true);
		}
	}, [empModules]);

	useEffect(() => {
		if (getReviewDocumentState.data && !getReviewDocumentState.isLoading) {
			setReviewData(getReviewDocumentState.data.data);
		}
	}, [getReviewDocumentState.data, getReviewDocumentState.isLoading]);

	const handleClickOpen = () => {
		if (isManager && loggedInEmployeeId !== employeeId) {
			setIsLoading(true);
			dispatch(
				getReviewDocument(employeeId, reviewId, () => {
					setIsLoading(false);
					setOpen(true);
				})
			);
		} else {
			_alertService.infoMessage("Your document is being reviewed by your manager")
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const callback = () => {
		handleClose();
		history.push("/home");
	};

	const handleApprove = (e) => {
		e.preventDefault();
		const payload = {
			uid: employeeId,
			reviewId: reviewId,
			workQueueDocId: workQueueId,
			status: "approved",
		};
		dispatch(reviewWorkQueueDocument(payload, callback));
	};

	const handleReject = async () => {
		const result = await _alertService.rejectWithReason(
			"Are you sure you want to reject this document?"
		);
		if (result.isConfirmed) {
			const payload = {
				uid: employeeId,
				reviewId: reviewId,
				workQueueDocId: workQueueId,
				status: "rejected",
				reason: result.value,
			};
			dispatch(reviewWorkQueueDocument(payload, callback));
		}
	};

	const footer = (formik) => {
		return (
			<Modal.Footer>
				<Button
					className="btn btn-danger rounded-pill px-4 btn-lg"
					onClick={handleReject}
				>
					Reject
				</Button>
				<Button
					className="btn btn-success rounded-pill px-4 btn-lg"
					onClick={handleApprove}
				>
					Approve
				</Button>
			</Modal.Footer>
		);
	};

	const components = {
		workauth: (
			<WorkAuthorizationEditModal
				employeeId={employeeId}
				show={open}
				onHide={handleClose}
				isFromWorkQueue={true}
				onUpdate={handleApprove}
				actions={footer}
				data={reviewData}
			/>
		),
		passport: (
			<PassportEditModal
				employeeId={employeeId}
				show={open}
				onHide={handleClose}
				isFromWorkQueue={true}
				onUpdate={handleApprove}
				actions={footer}
				data={reviewData}
			/>
		),
		documents: (
			<DocumentsEditModal
				employeeId={employeeId}
				show={open}
				onHide={handleClose}
				isFromWorkQueue={true}
				onUpdate={handleApprove}
				actions={footer}
				data={reviewData}
			/>
		),
		mailingaddress: (
			<MailingAddressEditModal
				employeeId={employeeId}
				show={open}
				onHide={handleClose}
				isFromWorkQueue={true}
				onUpdate={handleApprove}
				actions={footer}
				data={reviewData}
			/>
		),
		"e-verification": (
			<EVerifyEditModal
				employeeId={employeeId}
				show={open}
				onHide={handleClose}
				isFromWorkQueue={true}
				onUpdate={handleApprove}
				actions={footer}
				data={reviewData}
			/>
		),
		default: null,
	};

	return (
		<React.Fragment>
			<SimpleBackdrop openBackdrop={isLoading} />
			<Button className="btn btn-info btn-md" onClick={handleClickOpen}>
				Under Review
			</Button>
			{components[sectionName]}
		</React.Fragment>
	);
}
