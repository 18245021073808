import React from "react"
import MaterialTable, { MTableToolbar } from "material-table"
import {
  IconButton,
  Tooltip,
  ButtonGroup,
  // Button,
  Chip,
} from "@material-ui/core"
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons"
import { isLoaded } from "react-redux-firebase"
import EditContact from "../EditContact"

function Presentation(props) {
  const { contacts, deleteContact,clients_locations } = props
  const columns = [
    { title: "Name", field: "representativeName" },
    {
      title: "Job title",
      field: "jobTitle",
      render: (row) => <Chip color="secondary" label={row.jobTitle.toUpperCase()} />,
    },
    { title: "Gender", field: "gender" },
    { title: "Mobile no", field: "mobile" },
    { title: "Email", field: "email" },
    { title: "Address", field: "locationsline1", 
    render: (row) => <span>
      {
        row.locationsline1+"  "+row.locationsline2+","+row.locationscountry+","+row.locationsstate_name+","+row.locationszip
      }
    </span>},

    // { title: "Country", field: "locationscountry" },
    // { title: "State", field: "locationsstate_name" },
    // { title: "City", field: "locationscity" },
    // { title: "Zip", field: "locationszip" },
    { title: "Phone no", field: "workPhone" },
    {
      title: "Actions",
      field: "",
      render: (row) => (
        <div className="d-flex">
          {/* <ContactCard
            key={row.id}
            client={props.client}
            contact={row}
            edit={true}
          /> */}
          {/*  */}
          <EditContact
            btnContent={<EditIcon />}
            clientId={props.client.id}
            contact={row}
            edit={true}
            isPlacement={false}
          />
          <IconButton
            onClick={(e) => deleteContact({ clientId: props.client.id, contactId: row.id })}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ]

  let data = []
  contacts &&
    contacts.forEach((contact) => {
      const contactLocation = clients_locations?.filter((loc) => loc.id === contact?.location)[0]
      data.push({
        ...contact,
        locationsline1:contactLocation?.locationsline1 || "",
        locationsline2:contactLocation?.locationsline2 || "",
        locationsstate_name:contactLocation?.locationsstate_name || "",
        locationscountry:contactLocation?.locationscountry || "",
        locationszip:contactLocation?.locationszip || "",
        locationscity:contactLocation?.locationscity || "",
      })
    })

  return (
    <div>
      <MaterialTable
        title=""
        isLoading={!isLoaded(contacts)}
        data={data}
        columns={columns}
        options={{
          paginationPosition: "top",
          pageSize: 10,
          paginationType: "normal",
          filtering: true,
          selection: true,
          columnsButton: true,
        }}
        components={{
          Toolbar: (tableProps) => (
            <div>
              <MTableToolbar {...tableProps} />
              <div style={{ padding: "0 10px" }}>
                <Tooltip title="Add contact">
                  <ButtonGroup
                    variant="contained"
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <EditContact
                      btnContent={<AddCircleOutlineIcon />}
                      clientId={props.client.id}
                      edit={false}
                      contact={{}}
                    />
                  </ButtonGroup>
                </Tooltip>
              </div>
            </div>
          ),
        }}
      />
    </div>
  )
}

export default Presentation
