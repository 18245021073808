import React from "react";
import TabPanel from "../../../../../shared/tabPanel";
import TableFormatter from "../ExcelTable";
import { AppBar, Tabs, Tab, Badge } from "@material-ui/core";
import validations from "../../../../../shared/validation"
function Presentation(props) {
  const { inviteeInfo, excel_invite, activeEmployees,data} = props;
  console.log("excel_invite",excel_invite)
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

 
  
  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            label={
              <Badge
                color="primary"
                children="All"
                badgeContent={[...excel_invite, ...inviteeInfo].length}
              />
            }
          />
          <Tab
            label={
              <Badge
                color="primary"
                children="To be invited"
                badgeContent={
                  inviteeInfo.filter(
                    (item) =>
                      !activeEmployees
                        .map((user) => user.useremail)
                        .includes(item.email) &&
                      !validations.checkDate(new Date(item.dob))
                  ).length
                }
              />
            }
          />
          <Tab
            label={
              <Badge
                color="primary"
                children="Already registered"
                badgeContent={
                  excel_invite.filter(
                    (item) =>
                      item.validEmail === true && item.shalliInvite === false
                  ).length
                }
              />
            }
          />
          <Tab
            label={
              <Badge
                color="error"
                children="Rejected"
                badgeContent={
                  data.filter(
                    (item) =>
                      item.validEmail === false ||
                      validations.checkDate(new Date(item.dob))
                  ).length
                }
              />
            }
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TableFormatter
          value={value}
          tableData={[...excel_invite, ...inviteeInfo]}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TableFormatter
          value={value}
          tableData={inviteeInfo.filter(
            (item) =>
              item.shalliInvite === true &&
              !validations.checkDate(new Date(item.dob))
          )}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TableFormatter
          value={value}
          tableData={excel_invite.filter(
            (item) => item.validEmail === true && item.shalliInvite === false
          )}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TableFormatter
          value={value}
          tableData={data.filter(
            (item) =>
              item.validEmail === false ||
              validations.checkDate(new Date(item.dob))
          )}
        />
      </TabPanel>
    </div>
  );
}

export default Presentation