import React from 'react'
import {Modal} from 'react-bootstrap';

export default function DeleteModal(props) {
    return (
        <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Body>
                <div className="text-center pb-3">
                    <div className="delmodalicon"><i className="fa-regular fa-trash-can fs-24"></i></div>
                    <div className="fw-medium my-3">Are you sure want to delete?</div>
                    <button className="btn btn-outline-success rounded-pill px-4 " onClick={props.onHide}>Close</button>
                    <button className="btn btn-danger rounded-pill px-4 ms-2" onClick={props.handleDelete}>Delete</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

