import React, { useContext, useCallback } from "react";
import Presentation, { dataMapper } from "./Presentation";
import { connect } from "react-redux";
import { Context } from "../component_state/context";
import { getAllTimesheets } from "../../../../../api/services/default/timesheets";
import { useQuery } from "@tanstack/react-query";

function Container(props) {
	const {isManager, loggedInEmployee, condition, modules, branchList, employees } = props;
	const [state, handle] = useContext(Context);

	// const isManager = state.listAll
	// 	? modules.includes("timesheets-manager") ||
	// 	  modules.includes("console-customization")
	// 	: false;
	const employeeID = isManager ? undefined : state.employeeID;

	const setState = (obj) => {
		handle({
			type: "SET_STATE",
			data: obj,
		});
	};

	const { data, isLoading } = useQuery({
		queryKey: ["getAllTimesheets", condition, isManager, employeeID],
		queryFn: () => getAllTimesheets(condition, isManager, employeeID),
		enabled: condition !== "allTimesheets" || condition !== "approvedTimesheets",
	})

	const onGridReady = useCallback(async (params) => {
		const datasource = {
			getRows: async (params) => {
				try {
					const { startRow, endRow } = params.request;
					const filterModel = params.request.filterModel;
					const employeeIds = [
						...new Set([
							...(filterModel?.employeeID?.values || []),
							...(filterModel?.empName?.values || []),
						]),
					];
					const clientIds = filterModel?.clientName?.values || [];
          const skip = startRow;
          const take = endRow - startRow;
					const data = await getAllTimesheets(
						condition,
						isManager,
						isManager ? employeeIds : employeeID,
						clientIds,
            skip,
            take
					);
					const placementIds = data.timesheets.map((item) => item.placementID);
					handle({
						type: "LOAD_SETTINGS",
						placementIDs: placementIds,
						setState,
					});
					const timesheets = dataMapper(
						data.timesheets,
						state,
						branchList,
						employees
					);
					params.successCallback(timesheets, data.total);
				} catch (error) {
					console.error(`[getRows] ${condition}`, error);
					params.failCallback();
				}
			},
		};
		params.api.setServerSideDatasource(datasource);
	}, [condition, isManager, employeeID]);

	return (
		<div>
			<Presentation
				state={state}
				loggedInEmployee={loggedInEmployee}
				condition={condition}
				modules={modules}
				branchList={branchList}
				employees={employees}
				onGridReady={onGridReady}
        isManager={isManager}
        queryEmployeeID={employeeID}
				timesheets={data?.timesheets || []}
				isLoading={isLoading}
			/>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		modules: state.employee.employeeModules.accessModules,
		loggedInEmployee: state.firebase.auth.uid,
		timesheets: state.firestore.ordered.timesheets,
		settings_info: state.timesheets.info.settings_info,
		timesheets_data: state.timesheets.list,
		branchList:
			state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.personal?.fields?.filter(
				(field) => field.name === "branch"
			)?.[0]?.values || [],
		employees: state.employee.employeeList.metaInfoofEmployees.data,
	};
};

export default connect(mapStateToProps)(Container);
