import React, { useContext } from "react"
import OrgChart from "../Services/EmployeeManagment/components/OrgChart"
const OrgChartPage = (props) => {
  console.log(props)
  return (
    <div>
      {/* {state.hasOwnProperty("employees_collection") ? ( */}
      <OrgChart id={props.match.params.id} />
      {/* ) : ( */}
      {/* <p className="text-center">Loading OrgChart ...</p> */}
      {/* )} */}
      hii
    </div>
  )
}

export default OrgChartPage
