import {
  loadTimesheetsFailure,
  loadTimesheetsListReq,
  loadTimesheetsListSuccess,
  loadColumnDetailsReq,
  loadColumnDetailsSuccess,
  loadColumnDetailsFailure
} from "../actions/actionCreators"
import firebase from "../../../config/fbConfig"
import make_API_call from "../../../providers/REST_API"

const field = "createdAt"

export const timeSheetsList = (condition, employeeID, isManager, pageSize = 10) => async (dispatch, getState) => {
  dispatch(loadTimesheetsListReq())
  let timesheetsRef = firebase
    .firestore()
    .collection(`EMPLOYEES/${employeeID}/TIMESHEETS`)
    .where("isExist", "==", true)
    .orderBy(field, "desc")
  if (isManager) {
    timesheetsRef = firebase
      .firestore()
      .collectionGroup(`TIMESHEETS`)
      .orderBy("createdAt", "desc")
      .where("isExist", "==", true)
  }
  if (condition === "submittedTimesheets")
    timesheetsRef = timesheetsRef
      .where("isApproved", "==", false)
      .where("isRejected", "==", false)
  else if (condition === "approvedTimesheets")
    timesheetsRef = timesheetsRef
      .where("isApproved", "==", true)
      .where("isRejected", "==", false)
  else if (condition === "rejectedTimesheets")
    timesheetsRef = timesheetsRef
      .where("isApproved", "==", false)
      .where("isRejected", "==", true)
  return timesheetsRef
    // .limit(pageSize)
    .onSnapshot(snap => {
      const timesheetsList = snap.docs.map((doc) => doc.data())
      return dispatch(
        loadTimesheetsListSuccess({
          data: timesheetsList,
          firstDoc: timesheetsList[0],
          lastDoc: timesheetsList[timesheetsList.length - 1],
          page: 0,
          rowsPerPage: pageSize,
        })
      )
    },
      (err) => {
        console.error(err)
        return dispatch(loadTimesheetsFailure(err))
      })
}

export const nextPage =
  (condition, employeeID, isManager, pageSize = 10) =>
    async (dispatch, getState) => {
      dispatch(loadTimesheetsListReq())
      let timesheetsRef = firebase
        .firestore()
        .collection(`EMPLOYEES/${employeeID}/TIMESHEETS`)
        .where("isExist", "==", true)
        .orderBy(field, "desc")
      const lastDoc = getState().timesheets.list.lastDoc
      const page = getState().timesheets.list.page
      if (isManager) {
        timesheetsRef = firebase
          .firestore()
          .collectionGroup(`TIMESHEETS`)
          .orderBy("createdAt", "desc")
          .where("isExist", "==", true)
      }
      if (condition === "submittedTimesheets")
        timesheetsRef = timesheetsRef
          .where("isApproved", "==", false)
          .where("isRejected", "==", false)
          .where("isDefaulter", "==", false)
      else if (condition === "approvedTimesheets")
        timesheetsRef = timesheetsRef
          .where("isApproved", "==", true)
          .where("isRejected", "==", false)
          .where("isDefaulter", "==", false)
      else if (condition === "rejectedTimesheets")
        timesheetsRef = timesheetsRef
          .where("isApproved", "==", false)
          .where("isRejected", "==", true)
          .where("isDefaulter", "==", false)
      else if (condition === "defaulterTimesheets")
        timesheetsRef = timesheetsRef
          .where("isApproved", "==", false)
          .where("isRejected", "==", false)
          .where("isDefaulter", "==", true)
      return timesheetsRef
        .startAfter(lastDoc[field])
        .limit(pageSize)
        .onSnapshot(
          (snap) => {
            const timesheetsList = snap.docs.map((doc) => doc.data())
            return dispatch(
              loadTimesheetsListSuccess({
                data: timesheetsList,
                firstDoc: timesheetsList[0],
                lastDoc: timesheetsList[timesheetsList.length - 1],
                page: page + 1,
                rowsPerPage: pageSize,
              })
            )
          },
          (err) => {
            console.error(err)
            return dispatch(loadTimesheetsFailure(err))
          }
        )
    }

export const prevPage =
  (condition, employeeID, isManager, pageSize = 10) =>
    async (dispatch, getState) => {
      dispatch(loadTimesheetsListReq())
      let timesheetsRef = firebase
        .firestore()
        .collection(`EMPLOYEES/${employeeID}/TIMESHEETS`)
        .where("isExist", "==", true)
        .orderBy(field, "desc")
      const firstDoc = getState().timesheets.list.firstDoc
      const page = getState().timesheets.list.page
      if (isManager) {
        timesheetsRef = firebase
          .firestore()
          .collectionGroup(`TIMESHEETS`)
          .orderBy("createdAt", "desc")
          .where("isExist", "==", true)
      }
      if (condition === "submittedTimesheets")
        timesheetsRef = timesheetsRef
          .where("isApproved", "==", false)
          .where("isRejected", "==", false)
          .where("isDefaulter", "==", false)
      else if (condition === "approvedTimesheets")
        timesheetsRef = timesheetsRef
          .where("isApproved", "==", true)
          .where("isRejected", "==", false)
          .where("isDefaulter", "==", false)
      else if (condition === "rejectedTimesheets")
        timesheetsRef = timesheetsRef
          .where("isApproved", "==", false)
          .where("isRejected", "==", true)
          .where("isDefaulter", "==", false)
      else if (condition === "defaulterTimesheets")
        timesheetsRef = timesheetsRef
          .where("isApproved", "==", false)
          .where("isRejected", "==", false)
          .where("isDefaulter", "==", true)
      return timesheetsRef
        .endBefore(firstDoc[field])
        .limitToLast(pageSize)
        .onSnapshot(
          (snap) => {
            const timesheetsList = snap.docs.map((doc) => doc.data())
            return dispatch(
              loadTimesheetsListSuccess({
                data: timesheetsList,
                firstDoc: timesheetsList[0],
                lastDoc: timesheetsList[timesheetsList.length - 1],
                page: page - 1,
                rowsPerPage: pageSize
              })
            )
          },
          (err) => {
            console.error(err)
            return dispatch(loadTimesheetsFailure(err))
          }
        )
    }

export const loadColumnDetails = (employeeID, columnType) => (dispatch, getState) => {
  const timesheetListState = getState().timesheets.list
  dispatch(loadColumnDetailsReq())
  if (columnType === "employeeProfile") {
    return firebase.firestore()
      .collection(`EMPLOYEES`)
      .doc(employeeID)
      .get()
      .then(doc => {
        const data = doc.data()
        const newDetails = {
          ...timesheetListState.employees,
          [employeeID]: data
        }
        dispatch(loadColumnDetailsSuccess({ employees: newDetails }))
      }).catch(err => {
        console.error(err);
        return dispatch(loadColumnDetailsFailure({}))
      })
  }
}

export const loadDefaulters = async (employeeID) => {
  const activePlacementsSnap = await firebase.firestore().collection(`EMPLOYEES/${employeeID}/PLACEMENTS`)
    .where("draft", "==", false)
    .where("isExist", "==", true)
    .get()

  const activePlacements = activePlacementsSnap.docs.map(doc => doc.data())
  let defaulters = [];
  const promises = activePlacements.map(placement => {
    return firebase.firestore().collection(`META_INFO/timesheets/TRACK_SUBMISSIONS`)
      .doc(placement.placementID)
      .get()
  });

  (await Promise.all(promises)).forEach((doc, index) => {
    const placement = activePlacements[index]
    const data = doc.data()
    if (data?.defaulters) {
      const mappedData = data.defaulters.map(d => {
        return {
          ...d,
          placementID: placement.placementID,
          clientId: placement.clientId,
          employeeID: placement.employeeID,
        }
      })
      defaulters = [...defaulters, ...mappedData]
    }
  })
  return defaulters
}


export const loadAllDefaulters = async (employeeID) => {
  let activePlacementsSnap = []
  if (employeeID)
    activePlacementsSnap = await firebase.firestore().collectionGroup(`PLACEMENTS`)
      .where("employeeID", "==", employeeID)
      .where("draft", "==", false)
      .where("isExist", "==", true)
      .get()
  else
    activePlacementsSnap = await firebase.firestore().collectionGroup(`PLACEMENTS`)
      .where("draft", "==", false)
      .where("isExist", "==", true)
      .get()

  const activePlacements = activePlacementsSnap.docs.map(doc => doc.data())
  let defaulters = [];
  const promises = activePlacements.map(placement => {
    return firebase.firestore().collection(`META_INFO/timesheets/TRACK_SUBMISSIONS`)
      .doc(placement.placementID)
      .get()
  });

  (await Promise.all(promises)).forEach((doc, index) => {
    const placement = activePlacements[index]
    const data = doc.data()
    if (data?.defaulters) {
      const defaultersRelatedToOnePlacement = {
        placementID: placement.placementID,
        clientId: placement.clientId,
        employeeID: placement.employeeID,
        ranges: data.defaulters
      }
      defaulters = [...defaulters, defaultersRelatedToOnePlacement]
    }
  })
  return defaulters
}

export const getTimesheetsHistory = async (isPageZero = false,isNextPage = false,isPrevPage = false,page,pageSize = 10,firstDocID,lastDocID) => {
  try{
    let payload = {isPageZero,isNextPage,isPrevPage,pageSize,firstDocID,lastDocID}
    let data = await make_API_call("post","/employees/timesheet-history",payload)
    return{
      data: data.timesheetHistory,
      firstDocID: data.firstDocument,
      lastDocID: data.lastDocument,
      page: page,
      rowsPerPage: pageSize
    }
  }catch (error){
    console.error(error)
    let errorInfo = {
      stringifiedError: error?.message,
      service: 'Timesheets - Timsheet History',
      functionName: 'getTimesheetsHistory'
    }
    make_API_call('post', '/errors/report', errorInfo)
    return{
      data: [],
      firstDocID: firstDocID,
      lastDocID: lastDocID,
      page: page,
      rowsPerPage: pageSize
    }
  }
}

export const loadDashboardTimesheets = async (fromDate,toDate) => {
  try{
    let data = await make_API_call("get",
      `/employees/dashboardTimesheets?fromDate=${fromDate}&toDate=${toDate}`)
    return{
      data: data   
    }
    
  }catch (error){
    console.error(error)
    let errorInfo = {
      stringifiedError: error?.message,
      service: 'Timesheets - Timesheet Dashboard',
      functionName: 'loadDashboardTimesheets'
    }
    make_API_call('post', '/errors/report', errorInfo)
  }
}

export const getTimesheetReviewers = async () => {
  try{
    let data = await make_API_call("get",
      `/employees/dashboard/timesheet-reviewers`)
    return{
      data: data
    }
    
  }catch (error){
    console.error(error)
    let errorInfo = {
      stringifiedError: error?.message,
      service: 'Timesheets - Timesheet Dashboard',
      functionName: 'getTimesheetReviewers'
    }
    make_API_call('post', '/errors/report', errorInfo)
  }
}

export const getTimesheetdefaulters = async () => {
  try{
    let data = await make_API_call("get",
      `/employees/dashboard/defaulters`)
    return{
      data: data
    }
    
  }catch (error){
    console.error(error)
    let errorInfo = {
      stringifiedError: error?.message,
      service: 'Timesheets - Timesheet Dashboard',
      functionName: 'getTimesheetdefaulters'
    }
    make_API_call('post', '/errors/report', errorInfo)
  }
}

