import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';

const Contacts = (props) => {
    const {data} = props
  
    return (
        <Card className="bgcontacts">
            <Card.Body>
                <h5 className="card-title mb-2 header-title">Emergency Contact</h5>
                <ListGroup as="ul">
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start border-0 px-0">
                        <div className="me-auto fw-medium">{data?.[0]?.name}</div>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start border-0 px-0 py-1">
                        <div className="me-auto fw-medium">{data?.[0]?.phone}</div>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start border-0 px-0 pb-0">
                        <div className="me-auto fw-medium">{data?.[0]?.emailid}</div>
                    </ListGroup.Item>
                </ListGroup>
            </Card.Body>
        </Card>
    );
};
export default Contacts;
