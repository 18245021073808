import React, { useEffect } from "react";
import Presentation from "./Presentation";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { loadProjectTimeLine } from "../../../middleware/projectsList";

function Container(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadProjectTimeLine({ projectID: props.projectId }));
  }, []);
  if (props.timelines)
    return (
      <div>
        <Presentation {...props} />
      </div>
    );
  return <p>Loading......</p>;
}
const mapStateToProps = (state, ownProps) => {
  return {
    id: ownProps.projectId,
    timelines: state.projects.projectsList.projectTimeline,
  };
};
export default compose(connect(mapStateToProps))(Container);
