import React from "react"
import PlacementsList from "../Services/Placements/components/PlacementsList/Index"

function PlacementListPage(props) {
  return (
    <div>
      <PlacementsList
        listAll={true}
        employeeId={null}
        clientView={false}
        clientId={null}
      />
    </div>
  )
}

export default PlacementListPage
