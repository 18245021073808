import React from "react"
import NewClient from "../Services/Clients/components/NewClient/Index"

function NewClientPage(props) {
  return (
    <div>
      <NewClient />
    </div>
  )
}

export default NewClientPage
