import React from "react"
import EmployeeAnnouncements from "../Services/Announcements/EmployeeAnnouncements"

function EmployeeAnnouncementPage() {
  return (
    <div>
      <EmployeeAnnouncements />
    </div>
  )
}

export default EmployeeAnnouncementPage
