import React from 'react'
import { onKeyDown } from './utlis'
import { TextField, Grid } from '@material-ui/core'

export function NumberFilter(props) {
	const { onApply, column } = props
  const handleFilterChange = (isSecondInput) => (event) => {
    const newValue = event.target.value
    if (isSecondInput) {
      column.filterArgument = []
      column.filterArgument[0] = newValue
    } else {
      column.filterText = newValue
    }
  }

  const isBetween = column.filterOperator === 'Between'

  return (
    <Grid container={true} direction='column'>
      <Grid item={true}>
        <TextField
          label={''}
          type='number'
          placeholder={isBetween ? 'From' : 'Type a number'}
          onChange={handleFilterChange()}
          defaultValue={column.filterText}
          onKeyDown={onKeyDown(onApply)}
        />
      </Grid>
      {isBetween && (
        <Grid item={true}>
          <TextField
            label={''}
            type='number'
            placeholder='To'
            onChange={handleFilterChange(true)}
            defaultValue={column.filterArgument ? column.filterArgument[0] : ''}
            onKeyDown={onKeyDown(onApply)}
          />
        </Grid>
      )}
    </Grid>
  )
}
