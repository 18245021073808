const initState = {
	timesheets: {
		data: {},
		approvals: [],
		projectsList: [],
		isLoading: true,
		error: null,
		listener: () => {},
	},
}

export default initState
