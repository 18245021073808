import Axios from "axios";
import { defaultInstance } from "../../instances/default.instance";
import * as fileSaver from 'file-saver';

/**
 * @param {String} id
 * @param {Record} data
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const addReportToCollections = (clientId, data, signal) => {
  return defaultInstance({
    method: "PUT",
    url: `/clients/reportcollections/reporttocollections/${clientId}`,
    data,
    signal,
  });
}

/**
 * @param {String} id
 * @param {Record} data
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */

export const collectionsExport = async (clientId, data, signal) => {
  try {
    const response = await Axios.get(`/clients/reportcollections/collectionsexport/${clientId}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
      },
      signal,
    });
    
    if (response.status === 200) {
      const blob = response.data;
      if (blob.size > 0) {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${clientId}_collections.pdf`;
        link.click();
      } else {
        console.error('Empty or incomplete PDF received.');
      }
    } else {
      console.error('PDF generation failed. Status:', response.status);
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
}