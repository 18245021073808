import React from "react";
import { connect } from "react-redux";
import { updateProfile } from "../middleware";
import Notes from "../../../shared/components/Notes";
import { useQueryClient } from "@tanstack/react-query";

function EmployeeNotes(props) {
  const { rowData,updateProfile,fetchActiveEmployees } = props;
  const queryClient = useQueryClient();
  const tab = new URLSearchParams(window.location.search).get("tab");

  const handleExcuteNotesFunctions = async (notesState) => {
    let payload = {
        employeeID : rowData.id,
        data : {employeenotes:notesState ?? "<p></p>"},
        key : "personal"
    }
    updateProfile(payload, async () => {
      if (!isNaN(tab)) {
        await queryClient.invalidateQueries({ queryKey: ["getEmployees", Number(tab)] });
      }
      fetchActiveEmployees();
    })
  };

  return (
    <Notes notes = {rowData?.employeeNotes} notesName={rowData?.name} handleExcuteNotesFunctions = {handleExcuteNotesFunctions}/>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateProfile: (payload, cb) => {
            dispatch(updateProfile(payload, cb))
        }
    }
}

export default connect(null, mapDispatchToProps)(EmployeeNotes)