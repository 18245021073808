export default {
  payments_history: [],
  isLoaded: false,
  isEmpty: false,
  error: null,
  invoiceHistory: {
    data: [],
    error: "",
    isLoading: false
  }
}
