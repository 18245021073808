import React from 'react';
import { Card } from 'react-bootstrap';
import Chart from 'react-apexcharts';


const InvoicesChart = (props) => {
    const {Invoices} = props
    const allKeys = Array.from(
      new Set([
        ...Object.keys(Invoices?.dueInvoicesMonthly ?? {}),
        ...Object.keys(Invoices?.paidInvoicesMonthly ?? {}),
        ...Object.keys(Invoices?.sentInvoicesMonthly ?? {}),
      ])
    ).sort((a, b) => {
        const [startMonth, startYear] = a.split("/")
        const [endMonth, endYear] = b.split("/")

        return new Date(startYear, startMonth, 1) - new Date(endYear, endMonth, 1)
    });
    console.log({ due: Invoices?.dueInvoicesMonthly, paid: Invoices?.paidInvoicesMonthly, sent: Invoices?.sentInvoicesMonthly})
    let dueInv = allKeys.map(key => Invoices?.dueInvoicesMonthly[key] || 0)
    let paidInv = allKeys.map(key => Invoices?.paidInvoicesMonthly[key] || 0)
    let sentInv = allKeys.map(key => Invoices?.sentInvoicesMonthly[key] || 0)
    const apexBarChartOpts = {
        chart: {
            foreColor: '#979797',
            height: 300,
            type: 'bar',
            stacked: true,
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '60%',
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 7,
            colors: ['transparent']
        },
        colors: ['#FFAB00', '#A9CB8E', '#FF8F73'],
        legend: {
            show: true,
        },
        xaxis: {
            categories: allKeys,
            axisBorder: {
                show: true,
                color: 'rgba(0,0,0,0.05)'
            },
            axisTicks: {
                show: true,
                color: 'rgba(0,0,0,0.05)'
            }
        },
        yaxis: {
            axisBorder: {
                show: true,
                color: 'rgba(0,0,0,0.05)'
            },
            axisTicks: {
                show: true,
                color: 'rgba(0,0,0,0.05)'
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
            row: {
                colors: ['transparent', 'transparent'],
                opacity: .2
            },
            borderColor: 'rgba(0, 0, 0, .1)'
        },
        fill: {
            opacity: 1,
        },
    };

    const apexBarChartData = [
        {
            name: 'Sent Invoices',
            data: sentInv,
        },{
            name: 'Paid Invoices',
            data: paidInv,
        },{
            name: 'Due Invoices',
            data: dueInv,
        },
    ];

    if(allKeys?.length > 12 || allKeys.length === 0) return <></>

    return (
        <Card>
            <Card.Body>
                <h5 className="card-title mb-0 header-title">INVOICES</h5>
                <Chart
                    options={apexBarChartOpts}
                    series={apexBarChartData}
                    type="bar"
                    className="apex-charts mt-1"
                    height={300}
                    dir="ltr"
                />
            </Card.Body>
        </Card>
    );
};

export default InvoicesChart;
