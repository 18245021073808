import React from 'react'

function Presentation({ invoiceID }) {
  return (
    <div>
      <h3>Invoice {invoiceID} approval</h3>
    </div>
  )
}

export default Presentation
