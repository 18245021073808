import React, { useEffect } from "react"
import ClientDetails from "../ClientDetails"
import Contacts from "../Contacts/Index"
import Accounts from "../Accounts/Index"
import Locations from "../Locations/Index"
import PlacementsList from "../../../../../Placements/components/PlacementsList/Index"
import Documents from "../Documents/Index"
import Expenses from "../Expenses"
import {Tab,Card} from "react-bootstrap"
import InvoicesList from "../../../../../Invoices/components/ListingInvoices/TabsView"
import ProjectsList from "../Projects"
import Navtab from "../Navtab"
function Presentation(props) {
  const { client } = props
  
  return (
    <>
      <Tab.Container  defaultActiveKey="Details">
        <Card>
          <Card.Body>
          <Navtab/>
          </Card.Body>
        </Card>
        <Tab.Content className="text-muted pt-0" >
        <Tab.Pane eventKey="Details" id="1" key="1" >
        <ClientDetails client={client} />
      </Tab.Pane>
      <Tab.Pane eventKey="Contacts" id="2" key="2">
        <Contacts client={client} />
      </Tab.Pane>
      <Tab.Pane eventKey="Accounts" id="3" key="3">
        <Accounts client={client} />
      </Tab.Pane>
      <Tab.Pane eventKey="Locations" id="4"  key="4">
        <Locations client={client} />
      </Tab.Pane>
      <Tab.Pane eventKey="Placements" id="5"  key="5">
        <PlacementsList
          listAll={false}
          employeeId={null}
          clientView={true}
          clientId={client.clientId}
        />
      </Tab.Pane>
      <Tab.Pane eventKey="Documents" id="6" key="6">
        <Documents clientId={client.clientId} />
      </Tab.Pane>
      <Tab.Pane eventKey="Expenses" id="7" key="7">
        <Expenses
          listAll={false}
          clientView={true}
          clientId={client.clientId}
        />
      </Tab.Pane>
      <Tab.Pane eventKey="Invoices" id="8" key="8">
        <InvoicesList
          clientID = {client.clientId} 
          isClientInvoices = {true}
        />
      </Tab.Pane>
      <Tab.Pane eventKey="Projects" id='9'  key="9">
        <ProjectsList
          listAll={false}
          clientView={true}
          clientId={client.clientId}
        />
      </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  )
}

export default Presentation
