import { ACTION } from "../actions/actionTypes";
import { initState } from "./suspendedListState";

export const suspendedListReducer = (state = initState, action) => {
  switch (action.type) {
    case ACTION.SUSPENDED_LIST_REQUEST:
      return {
        ...state,
        isReviewed: true,
      };

    case ACTION.SUSPENDED_LIST_SUCCESS:
      return {
        ...state, 
        isReviewed: false,
      };

    case ACTION.SUSPENDED_LIST_ERROR:
      return {
        ...state,
        isReviewed: false,
      };

    default:
      return state;
  }
};
