import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { getFirebase } from "react-redux-firebase";
import { configuration } from "../../config/companyConfig";

const store = createStore(
  rootReducer,
  configuration.isDevelopment
    ? composeWithDevTools(
        applyMiddleware(thunk.withExtraArgument({ getFirebase }))
      )
    : applyMiddleware(thunk.withExtraArgument({ getFirebase }))
);

export default store;
