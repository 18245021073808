import React from "react";
import EmployeeSettings from "../Actions/General/EmployeeSettings";
import SoaTable from "./SoaTable";

export default function Soa({ employeeID }) {
	return (
		<>
			<div className="text-end">
				<EmployeeSettings
					id={employeeID}
					btnContent={"Edit employee settings"}
				/>
			</div>
			<SoaTable id={employeeID} />
		</>
	);
}
