import React from "react"
import {
  Grid,
  TextField,
  Divider,
  Typography,
  MenuItem,
  Button,
  CircularProgress,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import {
  FederalIdPattern,
  DoubleDigitFormatter,
  MobileNumberFormatter,
} from "../../../../../../shared/customNumberFormats";
import validate from "../../../../../../shared/validation";
import { GoFileSymlinkFile } from "react-icons/go";
import { AttachFile as AttachFileIcon } from "@material-ui/icons";
import CountryField from "../../../../../../shared/components/Geo/CountryField";
import StateField from "../../../../../../shared/components/Geo/StateField";
import IOSSwitch from "../../../../../../shared/iosSwitch"

function Presentation(props) {
  const {
    businessInformation,
    handleChange,
    handleKeyValuePair,
    isUploading,
    handleFile,
    handleChangePrimeVendor
  } = props;
  const jobTerminationNoticeList = ["1-week", "2-weeks", "3-weeks", "4-weeks"];
  const categoryList = ["End Client", "Prime-Contractor", "Sub-Contractor"];
  return (
    <div className="custom-card">
      <form>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Business Name"
              name="businessName"
              id="new-client-businessName"
              // helperText={
              //   businessInformation.businessName.length &&
              //   !validate.checkName(businessInformation.businessName)
              //     ? "Enter valid Business Name"
              //     : businessInformation.businessName.length
              //     ? businessInformation.helperText
              //     : ""
              // }
              value={businessInformation.businessName}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <React.Fragment>
                    {businessInformation.isChecking ? (
                      <CircularProgress color="inherit" size={15} />
                    ) : null}
                  </React.Fragment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Business Display Name"
              name="businessDisplayName"
              id="new-client-businessDisplayName"
              value={businessInformation.businessDisplayName}
              // helperText={
              //   businessInformation.businessDisplayName.length &&
              //   !validate.checkName(businessInformation.businessDisplayName)
              //     ? "Enter valid BusinessDisplayName"
              //     : ""
              // }
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Email"
              name="email"
              value={businessInformation.email}
              id="new-client-email"
              helperText={
                businessInformation.email.length
                  ? validate.checkEmail(businessInformation.email)
                    ? ""
                    : "Enter valid email id"
                  : ""
              }
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Contact Number"
              name="contactNumber"
              id="new-client-contactNumber"
              helperText={
                businessInformation.contactNumber.length
                  ? validate.checkNumber(businessInformation.contactNumber)
                    ? ""
                    : "Enter valid contact number"
                  : ""
              }
              value={businessInformation.contactNumber}
              onChange={handleChange}
              InputProps={{
                inputComponent: MobileNumberFormatter,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Federal ID"
              placeholder="##-######"
              name="federalId"
              value={businessInformation.federalId}
              id="new-client-federalId"
              helperText={
                businessInformation.federalId.length
                  ? businessInformation.federalId.trim().length === 9
                    ? ""
                    : "Enter valid federalId"
                  : ""
              }
              onChange={handleChange}
              InputProps={{
                inputComponent: FederalIdPattern,
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Website"
              name="website"
              value={businessInformation.website}
              id="new-client-website"
              helperText={
                businessInformation.website.length
                  ? validate.checkWebsite(businessInformation.website)
                    ? ""
                    : "Enter valid website"
                  : ""
              }
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Net Terms"
              name="netTerms"
              id="new-client-netTerms"
              value={businessInformation.netTerms}
              onChange={handleChange}
              InputProps={{
                inputComponent: DoubleDigitFormatter,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              select
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Job Termination Notice"
              name="jobTerminationNotice"
              id="new-client-jobTerminationNotice"
              value={businessInformation.jobTerminationNotice}
              onChange={handleChange}
            >
              <MenuItem value={""}>None</MenuItem>
              {jobTerminationNoticeList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              select
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Client Category"
              name="category"
              value={businessInformation.category}
              onChange={handleChange}
              id="new-client-category"
            >
              <MenuItem value="">None</MenuItem>
              {categoryList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Fax"
              name="fax"
              value={businessInformation.fax}
              id="new-client-fax"
              helperText={
                businessInformation.fax.length
                  ? validate.checkNumber(businessInformation.fax)
                    ? ""
                    : "Enter valid fax number"
                  : ""
              }
              onChange={handleChange}
              InputProps={{
                inputComponent: MobileNumberFormatter,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ marginTop: "6px" }}>
              <input
                style={{ display: "none" }}
                id="clients-logo"
                type="file"
                accept="image/*"
                onChange={handleFile}
              />
              <label htmlFor="clients-logo">
                <Button variant="contained" color="primary" component="span">
                  <span>
                    <AttachFileIcon fontSize="small" color="inherit" /> Client
                    logo
                  </span>
                </Button>
              </label>
              <div className="d-flex justify-content-between">
                <p>
                  {businessInformation.logo !== "" ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={businessInformation.logo}
                    >
                      <GoFileSymlinkFile size={22} /> Logo
                    </a>
                  ) : (
                    <p>No file choosen</p>
                  )}
                </p>
              </div>
            </div>
            {isUploading ? <p>Uploading please wait...</p> : ""}
          </Grid>
          <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={<IOSSwitch />}
              checked={businessInformation.isPrimeVendor}
              onChange={handleChangePrimeVendor}
              name="primeVendor"
              label="Prime Vendor"
              id="prime-vendor"
            />
          </FormGroup>
        </Grid>
        <br />
        </Grid>
        <br />
      </form>
    </div>
  );
}

export default Presentation
