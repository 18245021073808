import React from 'react';
import { Button } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import useStyles from '../../Styles/NewprojectStyles'
import ActiveMembers from '../ActiveMembers'
import validate from "../../../../shared/validation"
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Presentation(props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [selectedStartDate, setSelectedStartDate] = React.useState("")
  const [selectedEndDate, setSelectedEndDate] = React.useState("")
  const {
    handleCreate,
    handleChange,
    handleDateChange,
    errCount,
    newProject,
    clearValues,
    handleCheck,
    handleEmployees,
    projectsList,
    projectAssignee,
    employees,
    projectId
  } = props

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date)
    handleDateChange("startdate", date)
  }

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date)
    handleDateChange("enddate", date)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedStartDate("")
    setSelectedEndDate("")
    clearValues()
  }
  //   function  AvoidSpace(event) {
  //     var k = event ? event.which : window.event.keyCode;
  //     if (k === 32) return false;
  // }
  const projectExist = projectsList.filter(
    (project) =>
      project.title.trim().toLowerCase() ===
      newProject.name.trim().toLowerCase()
  ).length
  const projectIdExist = projectsList.filter(
    (project) =>
      project.cid.trim().toLowerCase() ===
      newProject.projectId.trim().toLowerCase()
  ).length

  const ProjectIdValidations = {
    checkName: (name) => {
      const exp = /^[a-zA-Z \-  0-9\b]{1,}$/
      return exp.test(name.trim())
    },
  }

  return (
    <div>
      <Tooltip title="New project" aria-label="excel_tooltip">
        <Button className="btn btn-success px-4 btn-md text-high " onClick={handleClickOpen}>
          add a new project
        </Button>
      </Tooltip>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              New project
            </Typography>
            <React.Fragment>
              <Typography variant="h6" gutterBottom></Typography>
              <form
                onSubmit={(e) => {
                  handleCreate(e)
                  setOpen(false)
                  setSelectedStartDate("")
                  setSelectedEndDate("")
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="tm-new-project-projectname"
                      name="name"
                      label="Name"
                      onChange={handleChange}
                      fullWidth
                      // onkeypress ={AvoidSpace(event)}
                    />
                    {projectExist ? (
                      <p className="text-danger">
                        This title already exists! choose another title
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="tm-new-project-projectid"
                      name="projectId"
                      label="Project ID"
                      value={projectId}
                      fullWidth
                      disabled
                    />
                    {projectIdExist ? (
                      <p className="text-danger">
                        This project ID already exists! choose another ID
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <KeyboardDatePicker
                            fullWidth
                            required
                            margin="normal"
                            id="tm-new-project-startdate"
                            label="Start date"
                            format="MM/dd/yyyy"
                            value={selectedStartDate ? selectedStartDate : null}
                            onChange={handleStartDateChange}
                            name="startdate"
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <KeyboardDatePicker
                            fullWidth
                            margin="normal"
                            required
                            id="tm-new-project-enddate"
                            label="End date"
                            format="MM/dd/yyyy"
                            minDate={selectedStartDate}
                            value={selectedEndDate ? selectedEndDate : null}
                            onChange={handleEndDateChange}
                            name="enddate"
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel required htmlFor="grouped-select">
                        Status
                      </InputLabel>
                      <Select
                        name="status"
                        onChange={handleChange}
                        input={<Input id="tm-new-project-status" />}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="Open">Open</MenuItem>
                        <MenuItem value="Closed">Closed</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newProject.useTimeline}
                          onChange={(event) => handleCheck(event)}
                          name="useTimeline"
                        />
                      }
                      label="Timeline"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newProject.useLabels}
                          onChange={(event) => handleCheck(event)}
                          name="useLabels"
                          id="tm-new-project-uselabels"
                        />
                      }
                      label="Labels"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ActiveMembers
                      inProject={false}
                      handleEmployeesDefault={handleEmployees}
                    />
                  </Grid>

                  <br />
                  {errCount === 0 &&
                  !projectExist &&
                  !projectIdExist &&
                  validate.checkDateDiff(selectedStartDate, selectedEndDate) &&
                  ProjectIdValidations.checkName(newProject.projectId) ? (
                    <Button
                    type='submit'
                    className="btn btn-success px-4 btn-md text-high"
                    >
                      Create
                    </Button>
                  ) : (
                    <Button
                    className="btn btn-success px-4 btn-md"
                    disabled
                    type='button'
                    >
                      Create
                    </Button>
                  )}
                </Grid>
              </form>
            </React.Fragment>
          </Paper>
        </main>
      </Dialog>
    </div>
  )
}
