export default {
  listAll: false,
  listAllCriteriaID: "",
  generatedInvoices: {
    data: {},
    isLoading: true,
    error: "",
    listener: () => {},
    noOfLoadings: 0,
  },
  openInvoices: {
    data: {},
    isLoading: true,
    error: "",
    listener: () => {},
    noOfLoadings: 0,
  },
  dueInvoices: {
    data: {},
    isLoading: true,
    error: "",
    listener: () => {},
    noOfLoadings: 0,
  },
  paidInvoices: {
    data: {},
    isLoading: true,
    error: "",
    listener: () => {},
    noOfLoadings: 0,
  },
  voidInvoices: {
    data: {},
    isLoading: true,
    error: "",
    listener: () => {},
    noOfLoadings: 0,
  },
  allInvoices: {
    data: {},
    isLoading: true,
    error: "",
    listener: () => {},
    noOfLoadings: 0,
  },
  currViewingTab: "openInvoices",
  actions: {
    voidingInvoices: [],
    mailingInvoices: [],
    errorWhileVoiding: [],
    errorWhileMailing: [],
    invoicePreview: {
      isLoaded: false,
      error: null,
      html: "",
      url: "",
    },
  },
  sendInvoiceToClient: {
    attachments: {},
    receivers: {
      to: [],
      cc: [],
      bcc: [],
    },
  },
  clientInterest: {
    approve: {
      isApproving: false,
      error: "",
    },
    reject: {
      isRejecting: false,
      error: "",
      reason: "",
    },
  },
  selectedRows: {},
  openReminderDialog: false,
  openBulkMailDialog: false,
  sendInvoiceReminder: {
    isProcessing: false,
  },
}