import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { connect } from "react-redux";
import { closePlacement } from "../../middleware";
import SunEditor from "suneditor-react";
import { CalendarFormatter } from "../../../../shared/CalendarFormatter";

function ClosePlacement(props) {
  const { placement, _close_placement, employeeID } = props;
  const [open, setOpen] = useState(false);

  const initState = {
    reason: "",
    projectEndDate: placement.projectEndDate,
    employeeID: employeeID,
  };

  const [state, setState] = useState(initState);

  const handleChange = (obj) => {
    setState((prevState) => ({
      ...prevState,
      ...obj,
    }));
  };

  const handleKeyValuePair = (value) => {
    if (!isNaN(Date.parse(value))) {
      const date = new Date(value);
      date.setHours(0, 0, 0, 0);
      setState((prevState) => ({
        ...prevState,
        projectEndDate: date.toISOString(),
      }));
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClosingPlacement = () => {
    const payload = {
      projectEndDate: CalendarFormatter.standardDateFormat(new Date(state.projectEndDate)),
      reason: state.reason,
    };
    _close_placement(payload, state.employeeID, placement.id);
    handleClose();
    setState({
      reason: "",
      projectEndDate: "",
      employeeID: "",
    });
  };

  return (
    <div>
      <Button
        variant="contained"
        style={{
          backgroundColor: "#ffcccb",
          marginLeft: "10px",
        }}
        onClick={handleClickOpen}
      >
        Close Placement
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="close-placement-dialog-title"
        style={{ overflow: "hidden" }}
      >
        <DialogTitle id="close-placement-dialog-title">
          You are about to Close Placement {placement.id} ?
        </DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              name="projectEndDate"
              size="small"
              required
              fullWidth
              minDate={placement.startDate}
              maxDate={new Date()}
              value={state.projectEndDate ? state.projectEndDate : null}
              id="placements-projectenddate"
              label="Project End date"
              format="MM/dd/yyyy"
              onChange={(date) => {
                handleKeyValuePair(date);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              style={{marginBottom:"10px"}}
            />
          </MuiPickersUtilsProvider>
          <SunEditor
            placeholder="Reason for Closing Placement"
            setContents={state.reason}
            onChange={(data) => handleChange({ reason: data })}
            height={200}
            setOptions={{
              buttonList: [
                [
                  "undo",
                  "redo",
                  "font",
                  "fontSize",
                  "formatBlock",
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                  "fontColor",
                  "hiliteColor",
                  "removeFormat",
                  "outdent",
                  "indent",
                  "align",
                  "horizontalRule",
                  "list",
                  "lineHeight",
                  "table",
                  "link",
                  "image",
                  "video",
                  "showBlocks",
                  "codeView",
                ],
              ],
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button
            onClick={onClosingPlacement}
            variant="contained"
            color="secondary"
            disabled={!state.reason.length}
          >
            Close Placement
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    _close_placement: (payload, uid, placementID) => {
      dispatch(closePlacement(payload, uid, placementID));
    },
  };
};

export default connect(null, mapDispatchToProps)(ClosePlacement);