export const ACTION = {
  REQUEST_COUNTRIES: "REQUEST_COUNTRIES",
  RECEIVE_COUNTRIES: "RECEIVE_COUNTRIES",
  ERROR_COUNTRIES: "ERROR_COUNTRIES",

  REQUEST_STATES: "REQUEST_STATES",
  RECEIVE_STATES: "RECEIVE_STATES",
  ERROR_STATES: "ERROR_STATES",

  LOAD_EMPLOYEE_DETAILS_REQ: "LOAD_EMPLOYEE_DETAILS_REQ",
  LOAD_EMPLOYEE_DETAILS_SUCCESS: "LOAD_EMPLOYEE_DETAILS_SUCCESS",
  LOAD_EMPLOYEE_DETAILS_FAILURE: "LOAD_EMPLOYEE_DETAILS_FAILURE",


}
