import React, { useEffect, useState } from "react"
import {
  IconButton,
  AppBar,
  Dialog,
  Typography,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit";
import { Close as CloseIcon } from "@material-ui/icons"
import useStyles from "../../../../shared/styles/dialogStyles"
import { CalendarFormatter } from "../../../../shared/CalendarFormatter"
import { useInvoiceListContext } from "../ListingInvoices/context";
import MakeOrUpdatePayment from "../ListingInvoices/Actions/MakeOrUpdatePayment";
import Accounts from "../../../../utils/Accounts";

function UpdateIndividualPayment(props) {
  const { invoiceID,PaymentData,handleLoad } = props
  const { state, services } = useInvoiceListContext()
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const initialState = {
    clientID: "",
    paymentMethod: "",
    paymentDate: CalendarFormatter.standardDateFormat(new Date()),
    referenceNumber: "",
    attachment: {
      sourcePath: "",
      publicURL: "",
    },
    isUploading: false,
    paymentAmount: "",
    discountDoneDetails: 0,
    discountDetails: [
      {
        name: "",
        value: 0,
        type: "",
      },
    ],
    receivedAmount: 0,
    noReference: false,
    paymentDoneAmount: 0,
    paymentID: "",
    paymentNotes: ""
  }
  const [paymentState, setPaymentState] = useState(initialState)
  const [componentKey, setComponentKey] = useState(Date.now())

  const handleClickOpen = () => {
    // componentDidMount()
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    // clearState()
  }

  useEffect(async () => {
    if (open) {
      await services.getInvoiceDetails(invoiceID)
      setPaymentState({
        ...paymentState,
        clientID: PaymentData?.clientID,
        paymentMethod: PaymentData?.otherInfo?.paymentType,
        paymentDate: PaymentData?.paymentDate,
        referenceNumber: PaymentData?.otherInfo?.referenceNumber,
        attachment: PaymentData?.otherInfo?.attachmentDetails,
        isUploading: false,
        paymentAmount: PaymentData?.paymentAmount,
        discountDoneDetails: 0,
        discountDetails: PaymentData?.discountDetails?.length ? PaymentData?.discountDetails : paymentState?.discountDetails,
        noReference: PaymentData?.noReference,
        paymentDoneAmount: 0,
        paymentID: PaymentData?.id,
        paymentNotes: PaymentData?.paymentNotes,
      })
      setComponentKey(Date.now())
    }
  }, [open])

  const clearState = () => {
    setPaymentState(initialState)
  }

  return (
    <div key={componentKey}>
      <IconButton
        onClick={(e) => {
          e.preventDefault()
          handleClickOpen()
        }}
      >
        <EditIcon />
      </IconButton>
      <Dialog disableEscapeKeyDown disableBackdropClick open={open} fullWidth maxWidth="lg" style={{margin:"20px"}}>
        <AppBar className={classes.appBar}>
          <div className="d-flex justify-content-between p-2">
            <div>
              <IconButton color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </div>
            <div>
              <Typography component="h1" variant="h4" align="center" style={{ color: "whitesmoke" }}>
                Payment
              </Typography>
            </div>
            <div></div>
          </div>
        </AppBar>
        <div style={{padding:"15px"}}>
          <MakeOrUpdatePayment
            isUpdatePaymentFromBulkPayment = {true}
            paymentState = {paymentState}
            isUpdatePayment = {true}
            setPaymentState = {setPaymentState} 
            invoicedAmount = {state?.invoiceDetails?.grandTotal} 
            handleClose = {handleClose} 
            handleLoad = {handleLoad}
            clearState = {clearState} 
            invoiceID = {invoiceID}
            receivedAmount = {state?.invoiceDetails?.receivedAmount}
            receivedAmountTillNow = {state?.invoiceDetails?.receivedAmount}
            discountReceivedTillNow = {state?.invoiceDetails?.paymentDiscountAmount}
            openBalance = {Accounts.roundOffAmount(state?.invoiceDetails?.grandTotal - (state?.invoiceDetails?.receivedAmount || 0) - (state?.invoiceDetails?.paymentDiscountAmount || 0))}
            isEdit = {true}
          />
        </div>
      </Dialog>
    </div>
  )
}

export default UpdateIndividualPayment
