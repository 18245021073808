import React, { useEffect } from "react"
import {
  SwipeableDrawer,
  makeStyles,
  Tooltip,
  Grid,
  Tabs,
  Tab,
  Box,
  Typography,
  AppBar,
  IconButton,
} from "@material-ui/core"
import { FaFilter } from "react-icons/fa"
import clsx from "clsx"
import Filters from "./Filters"
import FilterListIcon from "@material-ui/icons/FilterList"
import PropTypes from "prop-types"
import TabPanel from "../../tabPanel"
import Button from "react-bootstrap/Button"


const useSwipableStyles = makeStyles(() => ({
  list: {
    width: "auto",
  },
  fullList: {
    width: "auto",
  },
}))

const useStyles = makeStyles(() => ({
  tabPanel: {
    // It seems the appbar for tabs will always be 72px
    height: "calc(100% - 72px)",
    overflow: "auto",
  },
  mainWrapper: {
    height: "100%",
    width: 400,
    padding: 20,
    position: "relative",
    overflow: "hidden",
  },
  featureContainer: {
    overflow: "hidden",
  },
  actionsArea: {
    paddingTop: 20,
  },
}))

function FilterByComponent(props) {
  let tempColumns = JSON.stringify(props.columns)
  tempColumns = JSON.parse(tempColumns)
  const swipableClasses = useSwipableStyles()
  const classes = useStyles()
  const [swipableState, setSwipableState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return
    }

    setSwipableState({ ...swipableState, [anchor]: open })
  }

  const [value, setValue] = React.useState(0)

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  const dateFiltersFunction = (filterOperator, sourceNumber, testingNumber, testingArgument) => {
    if ([filterOperator, sourceNumber, testingNumber, testingArgument].includes(undefined))
      return false
    console.log(filterOperator, Date.parse(sourceNumber), Date.parse(testingNumber))
    switch (filterOperator) {
      case "Equals":
        return Date.parse(sourceNumber) === Date.parse(testingNumber)
      case "Gt":
        return Date.parse(sourceNumber) > Date.parse(testingNumber)
      case "Gte":
        return Date.parse(sourceNumber) >= Date.parse(testingNumber)
      case "Lt":
        return Date.parse(sourceNumber) < Date.parse(testingNumber)
      case "Lte":
        return Date.parse(sourceNumber) <= Date.parse(testingNumber)
      case "Between":
        if (!testingArgument) return false
        return (
          Date.parse(testingNumber) < Date.parse(sourceNumber) &&
          Date.parse(sourceNumber) < Date.parse(testingArgument)
        )
      default:
        return false
    }
  }

  const numericFilterFunction = (filterOperator, sourceNumber, testingNumber, testingArgument) => {
    if ([filterOperator, sourceNumber, testingNumber, testingArgument].includes(undefined))
      return false
    switch (filterOperator) {
      case "Equals":
        return parseFloat(sourceNumber) === parseFloat(testingNumber)
      case "Gt":
        return parseFloat(sourceNumber) > parseFloat(testingNumber)
      case "Gte":
        return parseFloat(sourceNumber) >= parseFloat(testingNumber)
      case "Lt":
        return parseFloat(sourceNumber) < parseFloat(testingNumber)
      case "Lte":
        return parseFloat(sourceNumber) <= parseFloat(testingNumber)
      case "Between":
        if (!testingArgument) return false
        return (
          parseFloat(testingNumber) < parseFloat(sourceNumber) &&
          parseFloat(sourceNumber) < parseFloat(testingArgument)
        )
      default:
        return false
    }
  }

  const stringFilterFunction = (filterOperator, sourceString, testingTesting, temp = "") => {
    if ([filterOperator, sourceString, testingTesting].includes(undefined)) return false
    switch (filterOperator) {
      case "NotContains":
        return !sourceString.toLowerCase().includes(testingTesting.toLowerCase())
      case "Contains":
        return sourceString.toLowerCase().includes(testingTesting.toLowerCase())
      case "StartsWith":
        return sourceString.toLowerCase().startsWith(testingTesting.toLowerCase())
      case "NotStartsWith":
        return !sourceString.toLowerCase().startsWith(testingTesting.toLowerCase())
      case "EndsWith":
        return sourceString.toLowerCase().endsWith(testingTesting.toLowerCase())
      case "NotEndsWith":
        return !sourceString.toLowerCase().endsWith(testingTesting.toLowerCase())
      case "Equals":
        return sourceString.toLowerCase() === testingTesting.toLowerCase()
      case "NotEquals":
        return sourceString.toLowerCase() !== testingTesting.toLowerCase()
      default:
        return false
    }
  }

  const columnHasFilter = (column) => {
    if (column.dataType === "component" || column.dataType === "") return false
    return true
  }

  const applyFilters = (columns) => {
    columns.forEach((fColumn) => {
      const column = columns.find((c) => c.field === fColumn.field)

      if (columnHasFilter(fColumn)) {
        column.filterText = fColumn.filterText
        column.filterOperator = fColumn.filterOperator
        column.filterArgument = fColumn.filterArgument

        if (
          column.filterOperator === "Between" &&
          (!column.filterArgument || !column.filterArgument[0])
        ) {
          column.filterOperator = "Gte"
          column.filterArgument = null
        }
      } else {
        column.filterText = null
        column.filterOperator = "None"
        column.filterArgument = null
      }
    })

    return columns
  }

  const onApplyClick = () => {
    const newColumns = applyFilters(tempColumns)
    const dataSource = props.data
    const userSelectedColumns = newColumns.filter(
      (col) => col["filterText"] && col["filterOperator"] && col["filterOperator"] !== "None"
    )
    const options = userSelectedColumns.map((row) => {
      return {
        fieldName: row.field,
        filterOperator: row.filterOperator,
        filterText: row.filterText,
        dataType: row.dataType,
        filterArgument: row.filterOperator === "Between" ? row.filterArgument[0] : null,
      }
    })
    const pairs = {
      String: stringFilterFunction,
      Date: dateFiltersFunction,
      Numeric: numericFilterFunction,
    }
    const filteredData = dataSource.filter((item) => {
      for (let index = 0; index < options.length; index++) {
        const filterObj = options[index]
        if (
          !pairs[filterObj.dataType](
            filterObj.filterOperator,
            item[filterObj.fieldName],
            filterObj.filterText,
            filterObj.filterArgument
          )
        )
          return false
      }
      return true
    })
    props.handleChange(filteredData, true, newColumns)
  }

  const list = (anchor) => (
    <div
      className={clsx(swipableClasses.list, {
        [swipableClasses.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <Grid container spacing={3} className={classes.mainWrapper}>
        <Grid item className={classes.featureContainer} xs={12}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab icon={<FilterListIcon />} label="Filters" />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Filters columns={tempColumns} onApply={onApplyClick} />
          </TabPanel>
        </Grid>
        <Grid item xs={12}>
        <Button className="btn btn-success px-6 btn-md text-high" onClick={toggleDrawer(anchor, true)}>
           Apply
        </Button>
          <Button
            variant="outline-success"
            className="text-high"
            onClick={(e) => {
              e.preventDefault()
              toggleDrawer(anchor, false)
              props.handleChange([], false, [])
            }}
            style={{ marginLeft: 10 }}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </div>
  )
  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
           <Button className="btn btn-success px-4 btn-md text-high" onClick={toggleDrawer(anchor, true)}>
           Advanced Filters
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={swipableState[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  )
}

FilterByComponent.propTypes = {
  columns: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
}

export default FilterByComponent
