import React, { useEffect,useState } from 'react'
import MaterialTable from 'material-table'
import { Link } from 'react-router-dom'
import MetaInfo from '../../../../shared/getMetaInfo'
import parser from 'html-react-parser'
import { useInvoiceListContext } from './context'
import validate from '../../../../shared/validation'

export default function InvoiceHistory() {
	const { state, services } = useInvoiceListContext()
	const [rowsPerPage, setRowsPerPage] = useState(10)

    useEffect(() => {
        services.getModules()
    }, [])

	const metaInfo = new MetaInfo()
	const columns = [
		{
			title: 'Action by',
			field: 'actionbyName',
			render: (rowData) => {
				if (state.modules.includes('console-customization')) {
					return (
						<Link to={'/console/employees/' + rowData.actionby}>
							{rowData.actionbyName}
						</Link>
					)
				} else {
					return <Link to={'/console/profile'}>{rowData.actionbyName}</Link>
				}
			},
		},
		{ title: 'Timestamp', field: 'timestamp' },
		{
			title: 'History',
			field: 'history',
			render: (rowData) => formatSubject(rowData),
		},
	]

	function formatSubject(rowData) {
		const { type, historyObj } = rowData
		switch (type) {
            case 'invoiceUpdated':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return (
						<span>Updated invoice {historyObj.subject.invoiceID}</span>
					)
				}
			case 'invoiceCreated':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return (
						<span>Created invoice {historyObj.subject.invoiceID}</span>
					)
				}
			case 'invoiceToVoid':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return (
						<span>
							<span>Invoice Void {historyObj.subject.invoiceID}</span>{' '}
							<span>{parser(
								'Void remarks' +
									(historyObj?.eventDetails?.after?.voidReason
										? ' - ' + historyObj?.eventDetails?.after.voidReason
										: '')
							)}</span>
						</span>
					)
				}
			case 'invoiceToVoidReasonUpdated':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return (
						<span>
							<span>Invoice follow up {historyObj.subject.invoiceID}</span>{' '}
							<span>{parser(
								'Void remarks updated' +
									(historyObj?.eventDetails?.after?.voidReason
										? ' - ' + historyObj?.eventDetails?.after.voidReason
										: '')
							)}</span>
						</span>
					)
				}
            case 'sendInvoiceToClient':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return(
						<span>
							Invoice sent to client {historyObj.subject.invoiceID}
						</span>
					)
				}
			case 'invoiceFollowup':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return (
						<span>
							<span>Invoice follow up {historyObj.subject.invoiceID}</span>{' '}
							<span>{parser(
								'Follow up' +
									(historyObj?.eventDetails?.after?.followupComment
										? ' - ' + historyObj?.eventDetails?.after.followupComment
										: '')
							)}</span>
						</span>
					)
				}
			case 'invoiceFollowupUpdated':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return (
						<span>
							<span>Invoice follow up {historyObj.subject.invoiceID}</span>{' '}
							<span>{parser(
								'Follow up updated' +
									(historyObj?.eventDetails?.after?.followupComment
										? ' - ' + historyObj?.eventDetails?.after.followupComment
										: '')
							)}</span>
						</span>
					)
				}
            case 'reminderToClient':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return(
						<span>
							Sent invoice remainder to client {historyObj.subject.invoiceID}
						</span>
					)
				}
            case 'clientApproveInvoice':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return(
						<span>
							Client Approved invoice {historyObj.subject.invoiceID}
						</span>
					)
				}
            case 'clientRejectInvoice':
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return(
						<span>
							Client Rejected invoice {historyObj.subject.invoiceID}
						</span>
					)
				}
			default:
				if('actionDesc' in historyObj){
					return <span>{parser(historyObj.actionDesc)}</span>
				}else{
					return (
						<span>{type} - {historyObj.subject.invoiceID}</span>
					)
				}
		}
	}

	const getData = async (query) => {
		let { pageSize, page } = query
		let isPageZero = false,isNextPage = false,isPrevPage = false
		if (page === 0) {
			isPageZero = true
			setRowsPerPage(pageSize)
		} else {
			if (page > state?.page) {
				isNextPage = true
			} else {
				isPrevPage = true
			}
		}
		const historyData = await services.getInvoicesHistory(isPageZero,isNextPage,isPrevPage,page,pageSize)

		let data = historyData && historyData?.map((history) => {
            return {
                actionby: history.actionBy,
                actionbyName: metaInfo.emailToName(history.actionBy),
                timestamp:
                    history.createdAt === ''
                        ? history.createdAt
                        : validate.dateAndTimeFormatter(history.createdAt),
                type: history.type,
                subject: Object.values(history.subject),
                historyObj: history,
            }
		}).filter(function (item) {
			return item.historyObj.actionDesc !== "";
		});

		return { data, page, totalCount: Number.MAX_SAFE_INTEGER }
	}

		const localization = {
			pagination: {
				labelDisplayedRows: `Page: ${state.page}`,
			},
		}

	return (
		<MaterialTable
			title=''
			columns={columns}
			data={(query) => getData(query)}
			localization={localization}
            isLoading = {state.isLoading}
			options={{
				filtering: false,
				columnsButton: false,
				showFirstLastPageButtons: false,
				pageSize: rowsPerPage,
				paginationPosition: 'both',
				paginationType: 'normal',
				pageSizeOptions: [10, 20, 50, 100, 250],
				padding: 'dense',
				rowStyle: (_, index) =>
					index % 2 === 0 ? { background: '#f5f5f5' } : null,
				emptyRowsWhenPaging: false,
				search: false,
			}}
		/>
	)
}