import { combineReducers } from "redux"
import { firestoreReducer } from "redux-firestore"
import { firebaseReducer } from "react-redux-firebase"
import ACTION from "../../Services/Authentication/actions/actionTypes"
import authReducer from "../../Services/Authentication/reducers"
import employeeReducer from "../../Services/EmployeeManagment/reducers"
import placementReducer from "../../Services/Placements/reducers"
import clientReducer from "../../Services/Clients/reducers"
import invoiceReducer from "../../Services/Invoices/reducers"
import timesheetReducer from "../../Services/Timesheets/reducers"
import consoleReducer from "../../Services/Console/reducers"
import sharedReducer from "../../shared/reducers"
import deductionReducer from "../../Services/Deductions/reducers"
import wikiReducer from "../../Services/Wiki/reducers"
import paymentsReducer from "../../Services/Payments/reducers"
import documentsReducer from "../../Services/Documents/reducers"
import expensesReducer from "../../Services/Expenses/reducers"
import payrollReducer from "../../Services/Payrolls/reducers"
import taskManagementReducer from "../../Services/TaskManagement/reducers"
import letterRequestReducer from "../../Services/LetterRequests/reducers"
import expiredListReducer from "../../Services/Dashboard/reducers/index";
const appReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  auth: authReducer,
  employee: employeeReducer,
  placement: placementReducer,
  client: clientReducer,
  invoice: invoiceReducer,
  timesheets: timesheetReducer,
  console: consoleReducer,
  shared: sharedReducer,
  deductions: deductionReducer,
  wiki: wikiReducer,
  payments: paymentsReducer,
  documents: documentsReducer,
  expenses: expensesReducer,
  ExpiredDocs: expiredListReducer,
  payrolls: payrollReducer,
  projects: taskManagementReducer,
  letterRequests: letterRequestReducer,
});

const rootReducer = (state, action) => {
  if (action.type === ACTION.SIGNOUT_SUCCESS) {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
