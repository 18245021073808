import React, { useEffect, useContext, useState } from "react";
import Presentation from "./Presentation";
import { connect } from "react-redux";
import { Context } from "../component_state/context";
import { addDays, differenceInBusinessDays, eachDayOfInterval } from "date-fns";
import { utils, handleTableChange } from "../component_state/utils";
import { useSelector } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";

function Container(props) {
  const { settings, placement, submittedTimesheets } = props;
  const [state, handle] = useContext(Context);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const setState = (obj) => {
    handle({
      type: "SET_STATE",
      data: obj
    });
  };

  const handleDateSelection = (date, modifiers = {}) => {
    if (modifiers.disabled || state.type !== "new") return;
    setSelectedDate(date);
  };

  const handleDateChange = () => {
    const placementInfo = state.placements[state.placementID];
    const existOrNot = state.dateRanges.some((range) => {
      const selectionStart = new Date(selectedDate).setHours(0, 0, 0, 0);
      if (
        new Date(range.startDate).setHours(0, 0, 0, 0) <=
          new Date(selectionStart) &&
        new Date(range.endDate).setHours(0, 0, 0, 0) >= new Date(selectionStart)
      ) {
        const rangeDates = eachDayOfInterval({
          start: range.startDate,
          end: range.endDate
        });

        const info = handleTableChange(
          new Date(range.startDate).setHours(0, 0, 0, 0),
          new Date(range.endDate).setHours(0, 0, 0, 0),
          placementInfo.startDate,
          placementInfo.endDate
        );
        let isAlreadySubmitted = false;
        const submitted = submittedTimesheets.map(
          (item) => item.split("_")[1] + "_" + item.split("_")[2]
        );
        const submittedStartDate = submittedTimesheets.map(
          (item) => item.split("_")[1]
        );
        console.log(submittedTimesheets);
        console.log(submitted);
        console.log(
          submitted,
          `${info.startDate}_${info.endDate}`,
          submittedStartDate,
          info.startDate
        );
        if (
          submitted.includes(`${info.startDate}_${info.endDate}`) ||
          submittedStartDate.includes(`${info.startDate}`)
        ) {
          isAlreadySubmitted = true;
        }
        setState({
          selectedRange: rangeDates,
          standardTime: info.standardTime,
          OTtime: info.OTArr,
          timesheetInfo: {
            ...state.timesheetInfo,
            startDate: info.startDate,
            endDate: info.endDate
          },
          isAlreadySubmitted: isAlreadySubmitted
        });
        return true;
      }
      return false;
    });
    console.log(existOrNot);
    if (!existOrNot) {
      setState({
        selectedRange: [selectedDate],
        standardTime: [],
        OTtime: [],
        timesheetInfo: {
          ...state.timesheetInfo,
          startDate: "",
          endDate: ""
        },
        isAlreadySubmitted: false
      });
      handleTableChange("", "");
    }
  };

  const rangeFormatter = (cycleStartDate, cycleEndDate, range, startDay) => {
    // let { date, range, startDay } = cycles[i]
    let i = 0,
      cycleVisitedFlag = false,
      cycleTimesheetDates = [];
    // let start = new Date(placementStartDate).setHours(0, 0, 0, 0)
    const limit = new Date(cycleEndDate).setHours(0, 0, 0, 0);
    while (limit >= cycleStartDate) {
      console.log("in while");
      let end;
      if (!cycleVisitedFlag) {
        end = addDays(
          cycleStartDate,
          utils().getDateRangeByDay(cycleStartDate, startDay, range)
        ).setHours(0, 0, 0, 0);
        cycleVisitedFlag = true;
      } else {
        let diff = differenceInBusinessDays(
          new Date(cycleEndDate),
          new Date(cycleStartDate)
        );
        if (diff < utils().getRangeByIndex(range))
          end = addDays(new Date(cycleStartDate), diff - 1).setHours(
            0,
            0,
            0,
            0
          );
        else
          end = utils()
            .getRangeEndDate(new Date(cycleStartDate), range)
            .setHours(0, 0, 0, 0);
      }
      if (end < new Date().setHours(0, 0, 0, 0)) {
        cycleTimesheetDates.push({
          startDate: cycleStartDate,
          endDate: end
        });
      }
      const nextCycleDate = addDays(new Date(end), 1).setHours(0, 0, 0, 0);
      if (cycleStartDate === nextCycleDate) break;
      cycleStartDate = nextCycleDate;
    }

    return cycleTimesheetDates;
  };

  const formDateRanges = () => {
    const placementInfo = state.placements[state.placementID];
    const cycles = state.timesheetSettings.cycle;
    const placementEndDate = new Date(placementInfo.endDate);
    let allDates = [],
      j;
    for (j = 0; j < cycles.length - 1; j++) {
      console.log("in for");
      const { date, range, startDay } = cycles[j];
      const cycleStartDate = new Date(date).setHours(0, 0, 0, 0);
      const cycleEndDate = new Date(cycles[j + 1].date).setHours(0, 0, 0, 0);
      allDates = [
        ...allDates,
        ...rangeFormatter(cycleStartDate, cycleEndDate, range, startDay)
      ];
    }
    const { date, range, startDay } = cycles[j];
    const finalCycleStartDate = new Date(date).setHours(0, 0, 0, 0);
    const finalCycleEndDate =
      new Date() > new Date(placementEndDate)
        ? new Date(placementEndDate)
        : new Date();
    allDates = [
      ...allDates,
      ...rangeFormatter(finalCycleStartDate, finalCycleEndDate, range, startDay)
    ];
    setState({
      dateRanges: allDates
    });
    console.log(
      allDates.map((item) => {
        return {
          start: new Date(item.startDate),
          end: new Date(item.endDate)
        };
      })
    );
  };

  useEffect(() => {
    if (state.isSettingsLoaded) {
      formDateRanges();
    }
  }, [state.isSettingsLoaded]);

  useEffect(() => {
    handleDateChange();
  }, [selectedDate]);

  console.log(state);
  return (
    <div>
      <Presentation
        state={state}
        handleDateSelection={handleDateSelection}
        placement={state.placements[state.placementID]}
      />
    </div>
  );
}

const mapStateToProps = (reduxState, ownProps) => {
  const trackTimesheetSubmissions =
    reduxState.firestore.data.submittedTimesheets;
  console.log(trackTimesheetSubmissions, ownProps);
  const placementID = ownProps.state.placementID;
  return {
    loggedInEmployee: reduxState.firebase.auth.uid,
    submittedTimesheets: trackTimesheetSubmissions
      ? placementID in trackTimesheetSubmissions
        ? Array.isArray(trackTimesheetSubmissions[placementID].submittedRanges)
          ? trackTimesheetSubmissions[placementID].submittedRanges
          : []
        : []
      : []
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "META_INFO",
        doc: "timesheets",
        subcollections: [
          {
            collection: "TRACK_SUBMISSIONS",
            where: [["id", "==", props.placementID]],
            storeAs: "trackTimesheetSubmissions"
          }
        ],
        storeAs: "trackTimesheetSubmissions"
      }
    ];
  })
)(Container);
