import { AppBar, Dialog, Grid, Typography, IconButton, TableContainer, Table, Paper, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import MaterialTable from 'material-table'
import React from 'react'
import { useBulkPaymentsContext } from './context'
import { Alert } from "@material-ui/lab";
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import MetaInfo from '../../../../shared/getMetaInfo';
import Validations from '../../../../shared/validation';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import styles from "../../styles/dialogStyles"
import { GoFileSymlinkFile } from "react-icons/go"
import { Edit as EditIcon } from "@material-ui/icons"
import Accounts from '../../../../utils/Accounts';

function ViewUnlinkedAmounts() {
    const { state } = useBulkPaymentsContext()
    const [open, setOpen] = useState(false)
    const metaData = new MetaInfo()
    const history = useHistory()

    const columns = [
        {
            title: "Reference number",
            field: "referenceNum",
        },
        {
            title: "Payment type",
            field: "paymentType"
        },
        {
            title: "Payment Date",
            field: "paymentDate",
            dataType: "Date",
        },
        {
            title: "Client",
            field: "clientName",
            dataType: "String",
            render: (row) => {
                if (row.clientID)
                    return <Link to={"/console/clientslist/" + row.clientID}>{row.clientName}</Link>
                return "--"
            },
        },
        {
            title: "Reference doc",
            field: "attachment",
            dataType: "String",
            render: (row) => {
                if (row.attachment)
                    return <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={row.attachment}
                    >
                        <GoFileSymlinkFile size={22} />
                    </a>
                return "--"
            },
        },
        {
            title: "Payment Amount",
            field: "paymentAmount",
            dataType: "Numeric",
            type: "currency"
        },
        {
            title: "Unlinked Amount",
            field: "unlinkedAmount",
            dataType: "Numeric",
            type: "currency"
        },
        {
            title: "Actions",
            field: "",
            render: (rowData) => {
                // console.log(rowData)
                return (
                    <span>
                        <IconButton
                            onClick={(e) => {
                                if (rowData.bulkPaymentId) {
                                    history.push('?tab=7&bulkPaymentId=' + rowData.bulkPaymentId)
                                    return window.location.reload()
                                }
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    </span>
                )
            },
        }
    ]


    const rows = state.totalBulkPayments.map((bulkpayment) => {
        const linkedPayments = state.totalPaymentsMadeList.filter((p) => p.bulkPaymentId === bulkpayment.id)
        const linkedAmount = linkedPayments.reduce((init, item) => init + (item.paymentAmount ?? 0), 0)
        return {
            bulkPaymentId: bulkpayment?.id || '',
            clientName: metaData.clientIdToName(bulkpayment.clientID),
            referenceNum: bulkpayment?.referenceNumber ?? '',
            paymentDate: bulkpayment.paymentDate,
            paymentType: bulkpayment?.paymentType ?? '',
            paymentAmount: bulkpayment.paymentAmount,
            clientID: bulkpayment.clientID,
            attachment: bulkpayment?.attachmentDetails?.publicURL ?? '',
            linkedPayments: linkedPayments,
            linkedAmount: linkedAmount,
            unlinkedAmount: Accounts.roundOffAmount(bulkpayment.paymentAmount - linkedAmount)
        }
    }).filter((r) => r.unlinkedAmount !== 0)

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const sumOf = (arr) => arr.reduce((init, val) => init + val, 0)
    const totalPaymentAmount = sumOf(rows.map((r) => r.paymentAmount))
    const totalLinkedAmount = sumOf(rows.map((r) => sumOf(r.linkedPayments.map(it => it.paymentAmount))))
    const totalUnlinkedAmount = totalPaymentAmount - totalLinkedAmount

    if (totalUnlinkedAmount > 0) {
        return (
            <Grid item xs={12} sm={12}>
                <Alert className='c-pointer' onClick={() => setOpen(true)} variant="filled" severity="warning">
                    Found unlinked amount from previous payments - <span style={{ fontSize: '18px' }} >{Validations.currencyFormatterUs(totalUnlinkedAmount)}</span>
                </Alert>
                <Dialog
                    onClose={() => setOpen(false)}
                    open={open}
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                        Unlinked Amount : {Validations.currencyFormatterUs(totalUnlinkedAmount)}
                    </DialogTitle>
                    <main>
                        <div>
                            <MaterialTable
                                title={`Total Amount: ${Validations.currencyFormatterUs(totalPaymentAmount)}`}
                                data={rows}
                                columns={columns}
                                isLoading={state.showLoader}
                                // onSelectionChange={(rows) => setSelectedRows(rows)}
                                options={{
                                    pageSize: 5,
                                    filtering: true,
                                    columnsButton: true,
                                    paginationPosition: "top",
                                    paginationType: "normal",
                                }}
                                detailPanel={rowData => {
                                    return (
                                        <TableContainer style={{ width: '300px' }} >
                                            <Table size={'small'} >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Invoice no</TableCell>
                                                        <TableCell align="right">Payment amount</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rowData.linkedPayments.map((row) => (
                                                        <TableRow
                                                            key={row.id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {row.invoiceID}
                                                            </TableCell>
                                                            <TableCell align="right">{Validations.currencyFormatterUs(row.paymentAmount)}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )
                                }}
                            />
                        </div>
                    </main>
                </Dialog>
            </Grid>
        )
    }
    return null
}

export default ViewUnlinkedAmounts