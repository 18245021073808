import React from "react"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import Presentation from "./Presentation"
import { connect } from "react-redux"
import { compose } from "redux"
import CircularSpinner from "../../../../../../shared/circularSpinner"

function Container(props) {
  const { taskId, projectId, isLoadingTask, taskData, projectData } = props
  console.log(props)
  if (!isLoadingTask) {
    const task = Object.values(taskData["taskData"])
    const project = Object.values(projectData["projectData"])
    if (!project[1])
      return (
        <div>
          <Presentation {...props} task={task} project={project} />
        </div>
      )
    return <p>Loading.</p>
  }
	return (
    <CircularSpinner />
  )
}

const mapStateToProps = (state, ownProps) => {
  const taskId = ownProps.taskId
  const projectId = ownProps.projectId
  return {
    taskData: state.projects.task,
    projectData: state.projects.project,
    taskId: taskId,
    projectId: projectId,
    isLoadingTask: state.projects.task.taskData.isLoading,
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    if (!props.task)
      return [
        {
          collection: "PROJECTS",
          doc: props.projectId,
          storeAs: "project",
        },
        {
          collection: "PROJECTS",
          doc: props.projectId,
          subcollections: [
            {
              collection: "TASKS",
              doc: props.taskId,
              storeAs: "task",
            },
          ],
          storeAs: "task",
        },
      ]
    return []
  })
)(Container)
