import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getCompanyDetails } from "../../../../api/services/default/company-details";
import { Card, Col, Row } from "react-bootstrap";
import HTMLParser from "html-react-parser";
import EditCompanyDetails from "./EditCompanyDetails";

function DisplayDetails({ labels, values }) {
	return (
		<table className="table table-borderless">
			<tbody>
				{labels.map((label, index) => (
					<tr key={label}>
						<td className="w-25">{label}</td>
						<td className="fw-bold">{values[index]}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}

export default function ViewCompanyDetails() {
	const { data: companyDetails } = useQuery({
		queryKey: ["getCompanyDetails"],
		queryFn: () => getCompanyDetails(),
	});

	return (
		<Row>
			<Col xs={12} md={4}>
				<Card>
					<Card.Body>
						<img
							src={companyDetails?.images?.companyLogo}
							alt="Company Logo"
							className="img-fluid"
						/>
						<h6 className="text-center">Company Logo</h6>
						<img
							src={companyDetails?.images?.waterMark}
							alt="Watermark"
							className="img-fluid mt-3"
						/>
						<h6 className="text-center">Company Water Mark</h6>
					</Card.Body>
				</Card>
			</Col>
			<Col xs={12} md={8}>
				<Card>
					<Card.Body>
						<div className="d-flex justify-content-between">
							<h3 className="text-underline">Company Details: </h3>
							<EditCompanyDetails />
						</div>

						<DisplayDetails
							labels={[
								"Company Name",
								"Web-URL",
								"E-Verify Number",
								"EIN-Number",
							]}
							values={[
								companyDetails?.companyName,
								<a href={companyDetails?.web_url}>{companyDetails?.web_url}</a>,
								companyDetails?.e_VerifyNumber,
								companyDetails?.ein_Number,
							]}
						/>
						<h3 className="text-underline">Contact Details:</h3>
						<DisplayDetails
							labels={[
								"Address",
								"Phone",
								"Mail-Id",
								"Accounts Mail-Id",
								"HR Mail-Id",
								"Immigration Mail Id",
							]}
							values={[
								companyDetails?.contactDetails?.address,
								companyDetails?.contactDetails?.phoneno,
								<a href={`mailto:${companyDetails?.contactDetails?.mailId}`}>
									{companyDetails?.contactDetails?.mailId}
								</a>,
								<a
									href={`mailto:${companyDetails?.contactDetails?.accountsMailId}`}
								>
									{companyDetails?.contactDetails?.accountsMailId}
								</a>,
								<a href={`mailto:${companyDetails?.contactDetails?.hrMailId}`}>
									{companyDetails?.contactDetails?.hrMailId}
								</a>,
								<a
									href={`mailto:${companyDetails?.contactDetails?.immigrationMailId}`}
								>
									{companyDetails?.contactDetails?.immigrationMailId}
								</a>,
							]}
						/>
						<h3 className="text-underline">Invoice Details:</h3>
						<DisplayDetails
							labels={[
								"Invoice Prefix",
								"Seperator",
								"Begin From",
								"Auto-fill Payable To",
								"Payable To",
								"Invoice Auto Send",
								"Invoice Auto Generate",
							]}
							values={[
								companyDetails?.invoiceDetails?.invoiceNumberFormat
									?.invoicePrefix,
								companyDetails?.invoiceDetails?.invoiceNumberFormat?.seperator,
								companyDetails?.invoiceDetails?.invoiceNumberFormat?.beginFrom,
								companyDetails?.invoiceDetails?.autoFillPayableTo
									? "Yes"
									: "No",
								HTMLParser(companyDetails?.invoiceDetails?.payableTo || ""),
								companyDetails?.invoiceDetails?.invoiceAutoSend ? "Yes" : "No",
								companyDetails?.invoiceDetails?.invoiceAutoGenerate
									? "Yes"
									: "No",
							]}
						/>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
}
