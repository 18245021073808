import React from "react";
import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";

const TimesheetsByMonths = (props) => {
  const { TimesheetsByMonths } = props;

  const allKeys = Array.from(
    new Set([
      ...Object.keys(TimesheetsByMonths?.submittedTimesheetsMonthly ?? {}),
      ...Object.keys(TimesheetsByMonths?.approvedTimesheetsMonthly ?? {}),
      ...Object.keys(TimesheetsByMonths?.rejectedTimesheetsMonthly ?? {}),
    ])
  ).sort((a, b) => {
    const [startMonth, startYear] = a.split("/");
    const [endMonth, endYear] = b.split("/");

    return new Date(startYear, startMonth, 1) - new Date(endYear, endMonth, 1);
  });
  console.log({
    submitted: TimesheetsByMonths?.submittedTimesheetsMonthly,
    approved: TimesheetsByMonths?.approvedTimesheetsMonthly,
    rejected: TimesheetsByMonths?.rejectedTimesheetsMonthly,
  });
  let submitted = allKeys.map(
    (key) => TimesheetsByMonths?.submittedTimesheetsMonthly[key] || 0
  );
  let approved = allKeys.map(
    (key) => TimesheetsByMonths?.approvedTimesheetsMonthly[key] || 0
  );
  let rejected = allKeys.map(
    (key) => TimesheetsByMonths?.rejectedTimesheetsMonthly[key] || 0
  );
  const apexBarChartOpts = {
    chart: {
      foreColor: "#979797",
      height: 300,
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "60%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 7,
      colors: ["transparent"],
    },
    colors: ["#FFAB00", "#A9CB8E", "#FF8F73"],
    legend: {
      show: true,
    },
    xaxis: {
      categories: allKeys,
      axisBorder: {
        show: true,
        color: "rgba(0,0,0,0.05)",
      },
      axisTicks: {
        show: true,
        color: "rgba(0,0,0,0.05)",
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
        color: "rgba(0,0,0,0.05)",
      },
      axisTicks: {
        show: true,
        color: "rgba(0,0,0,0.05)",
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: ["transparent", "transparent"],
        opacity: 0.2,
      },
      borderColor: "rgba(0, 0, 0, .1)",
    },
    fill: {
      opacity: 1,
    },
  };

  const apexBarChartData = [
    {
      name: "Submitted",
      data: submitted,
    },
    {
      name: "Approved",
      data: approved,
    },
    {
      name: "Rejected",
      data: rejected,
    },
  ];

  if (allKeys?.length > 12 || allKeys.length === 0) return <></>;

  return (
    <Card>
      <Card.Body>
        <h5 className="card-title mb-0 header-title">Timesheets</h5>
        <Chart
          options={apexBarChartOpts}
          series={apexBarChartData}
          type="bar"
          className="apex-charts mt-1"
          height={300}
          dir="ltr"
        />
      </Card.Body>
    </Card>
  );
};

export default TimesheetsByMonths;
