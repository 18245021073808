import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import FlairTable from "../../../../../shared/FlairTable";
import MetaInfo from "../../../../../shared/getMetaInfo";
import { useQuery } from "@tanstack/react-query";
import { getTagsTable } from "../../../../../api/services/default/invoices";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

function TagsTable() {
  const branchList = useSelector(
    (state) =>
      state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.personal?.fields?.filter(
        (field) => field.name === "branch"
      )?.[0]?.values || []
  );
  const metaInfo = new MetaInfo();
  const [selectedBranch, setSelectedBranch] = useState("");
  const handleBranchSelect = (branch) => {
    setSelectedBranch(branch);
  };

    const { data: getTagsTableData, isLoading } = useQuery({
    queryKey: ["getTagsTable", selectedBranch],
    queryFn: () => getTagsTable(selectedBranch),
  });

  const tagAction = {
    icon: () => (
      <DropdownButton
        id="branch-dropdown"
        title={`${selectedBranch || "Select Branch"}`}
      >
        {branchList.map((branch, index) => (
          <Dropdown.Item key={index} onClick={() => handleBranchSelect(branch)}>
            {branch}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    ),
    tooltip: "Select Branch",
    isFreeAction: true,
    position: "toolbar",
  };

  const tags = getTagsTableData ? getTagsTableData : {};
  const columns = [
    { title: "Client Name", field: "clientName" },
    { title: "Target", field: "target" },
    { title: "Check Issued", field: "checkissued" },
    { title: "PTP", field: "ptp" },
    { title: "ACH", field: "ach" },
    { title: "Cash Not Applied" },
    { title: "Internal", field: "internal" },
    { title: "Sub Contract", field: "subcontract" },
    { title: "Paid When Paid", field: "paidwhenpaid" },
    { title: "Collections", field: "collections" },
    { title: "Remittance", field: "remittance" },
    { title: "ShortPay", field: "shortpay" },
    { title: "Loss Of Pay", field: "lossofpay" },
  ];

  const rows = Object.keys(tags).map((clientKey) => {
    const clientData = tags[clientKey];
    console.log("table", clientData);
    return {
      clientName: metaInfo.clientIdToName(clientData.clientId),
      checkissued: clientData["Check Issued"] || "",
      target: clientData.Target.toFixed(2) || "",
      ptp: clientData?.PTP || "",
      ach: clientData.ACH || "",
      cashnotapplied: clientData["Cash Not Applied"] || "",
      internal: clientData.Internal || "",
      subcontract: clientData["Sub Contract"] || "",
      paidwhenpaid: clientData["Paid When Paid"] || "",
      collections: clientData.Collections || "",
      remittance: clientData.Remittance || "",
      shortpay: clientData["Short Pay"] || "",
      lossofpay: clientData["Loss Of Pay"] || "",
    };
  });

  return (
    <FlairTable
      data={rows}
      columns={columns}
      actions={tagAction}
      isLoading={isLoading}
    />
  );
}

export default TagsTable;
