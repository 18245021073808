import React from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { AttachFile as AttachFileIcon } from "@material-ui/icons"
import { GoFileSymlinkFile } from "react-icons/go"
import  Button  from "react-bootstrap/Button"

function Presentation(props) {
  const { names, handleNewSignature, handleChange, handleFile, state } = props
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div>
      <Button  className="btn btn-success px-6 btn-md text-high " onClick={handleClickOpen} >
        Add New Signature
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            To add signature please select your name and upload your signature
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                options={names}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => {
                  if (v) {
                    handleChange("employeeID", v.uid)
                  } else {
                    handleChange("employeeID", "")
                  }
                }}
                renderInput={(params) => <TextField {...params} size="small" label="Name" />}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <input
                    style={{ display: "none" }}
                    id="contained-button-file-wafile"
                    type="file"
                    onChange={handleFile}
                  />
                  <label htmlFor="contained-button-file-wafile">
                    <Button
                      variant="contained"
                      disabled={state.employeeID === ""}
                      color="primary"
                      component="span"
                    >
                      <span>
                        <AttachFileIcon fontSize="small" color="inherit" /> Attachment
                      </span>
                    </Button>
                  </label>
                  <div className="d-flex justify-content-between">
                    <p>
                      {typeof state.attachmentDetails === "object" &&
                      Object.keys(state.attachmentDetails).length ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={state.attachmentDetails.publicURL}
                        >
                          <GoFileSymlinkFile size={22} /> {state.attachmentDetails.name}
                        </a>
                      ) : (
                        <p>No file choosen</p>
                      )}{" "}
                    </p>
                  </div>
                  {state.isUploading ? <p>Uploading please wait...</p> : ""}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="btn btn-danger px-6 btn-md text-high " onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="btn btn-success px-6 btn-md text-high "
            disabled={!Object.keys(state.attachmentDetails).length}
            onClick={handleNewSignature}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Presentation
