import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
} from '@material-ui/core'
import StandardFilterEditor from './StandardFilterEditor'
import BooleanFilterEditor from './BooleanFilterEditor'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  expandIcon: {
    color: theme.palette.primary.contrastText,
  },
}))

function FilterControl(props) {
  const { column, onApply } = props
  //const classes = useStyles()
  const FilterEditor =
    column.dataType === 'Boolean'
      ? BooleanFilterEditor
      : StandardFilterEditor
  return (
    <Accordion>
      <AccordionSummary
        //classes={classes}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>{column.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FilterEditor column={column} onApply={onApply}/>
      </AccordionDetails>
    </Accordion>
  )
}

export default FilterControl
