import React, { useState } from "react"
import Grid from "@material-ui/core/Grid"
import TasksSort from "../TaskSort/Index/index"
// import Validation from "../../../../../shared/validation"
import useStyles from "../../../Styles/TaskManagerStyles"

function Presentation(props) {
  const [filterBy, setFilter] = useState("")
  const classes = useStyles()
  const { project, tasksList, employee } = props
  let projectMembers = Object.values(project.Users).map((user) => user.uid)
  let allAssignees = []
  let temp = []
  if (tasksList) {
    tasksList.forEach((task) => {
      temp.push(...task.assignee)
    })
    temp = [...new Set(temp)]
    if (employee.role === "user") projectMembers = temp
    projectMembers.forEach((member) => {
      let count = 0
      tasksList.forEach((task) => {
        if (task.assignee.includes(member)) {
          count++
        }
      })
      allAssignees.push({
        uid: member,
        count: count,
      })
    })
  }
  return (
    <div>
      <main className={classes.layout}>
        <Grid alignItems="stretch" container spacing={3}>
          <Grid item xs={12}>
            <TasksSort
              filterBy={filterBy}
              taskslist={props.taskslist}
              id={props.id}
            />
          </Grid>
        </Grid>
      </main>
    </div>
  )
}

export default Presentation
