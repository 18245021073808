import axios from "axios"
import make_API_call from "../../../providers/REST_API"
import {
  newProjectRequest,
  newProjectSuccess,
  newProjectFailure,
  projectUpdationFailure,
  projectUpdationRequest,
  projectUpdationSuccess,
  removeMemberFailure,
  removeMemberRequest,
  removeMemberSuccess,
  createLabelsRequest,
  createLabelsSuccess,
  createLabelsFailure,
  updateLabelsRequest,
  updateLabelsSuccess,
  updateLabelsFailure,
  deleteLabelsRequest,
  deleteLabelsSuccess,
  deleteLabelsFailure,
  updatePermissionsRequest,
  updatePermissionsSuccess,
  updatePermissionsFailure,
  createTasksRequest,
  createTasksFailure,
  createTasksSuccess,
  editTasksRequest,
  editTasksFailure,
  editTasksSuccess,
  linkTasksRequest,
  linkTasksSuccess,
  linkTasksFailure,
  unLinkTasksRequest,
  unLinkTasksSuccess,
  unLinkTasksFailure,
  createSubTasksRequest,
  createSubTasksSuccess,
  createSubTasksFailure,
  addCommentsRequest,
  addCommentsSuccess,
  addCommentsFailure,
  updateCommentsRequest,
  updateCommentsSuccess,
  updateCommentsFailure,
  deleteCommentsRequest,
  deleteCommentsSuccess,
  deleteCommentsFailure,
  addMembersRequest,
  addMembersSuccess,
  addMembersFailure,
} from "../actions/actionCreators"
import { waitingMsg, stopWaitMsg, errorMsg, successMsg } from "../../../shared/SnackBars/index"
// import { error } from "jquery"
//import validate from "../../../shared/validation"

export function projectCreation(payload) {
  return (dispatch) => {
    dispatch(newProjectRequest())
    //
    const { title, status, startdate, enddate, useLabels, useTimeline, cid, Users } = payload
    waitingMsg("Project is being Created...")
    make_API_call("post", `/projects/newproject`, {
      title,
      status,
      startdate,
      enddate,
      useLabels,
      useTimeline,
      cid,
      Users,
    })
      .then((res) => {
        stopWaitMsg()
        successMsg(res.message)
        dispatch(newProjectSuccess())
      })
      .catch((err) => {
        stopWaitMsg()
        errorMsg(err.message)
        dispatch(newProjectFailure())
      })
  }
}
export function projectUpdation(payload) {
  return (dispatch) => {
    dispatch(projectUpdationRequest())
    //
    const { title, status, startdate, enddate, useLabels, useTimeline, cid, id } = payload
    waitingMsg("Project is being Updated...")
    make_API_call("put", `/projects/${id}/updateproject`, {
      title,
      status,
      startdate,
      enddate,
      cid,
      useLabels,
      useTimeline,
    })
      .then((res) => {
        stopWaitMsg()
        successMsg(res.message)
        dispatch(projectUpdationSuccess())
      })
      .catch((err) => {
        stopWaitMsg()
        errorMsg(err.message)
        dispatch(projectUpdationFailure())
      })
  }
}

export function removeMember(payload) {
  return (dispatch) => {
    dispatch(removeMemberRequest())
    //

    const { projectId, employees } = payload
    waitingMsg("Removing Member from project...")
    make_API_call("put", `/projects/${projectId}/removeemployee`, {
      employees,
    })
      .then((res) => {
        stopWaitMsg()
        successMsg(res.message)
        dispatch(removeMemberSuccess())
      })
      .catch((err) => {
        stopWaitMsg()
        errorMsg(err.message)
        dispatch(removeMemberFailure())
      })
  }
}

export function createLabels(payload, resolve, reject) {
  return (dispatch) => {
    dispatch(createLabelsRequest())
    //
    const { projectId, labelName, labelColorCode } = payload
    waitingMsg("Creating the label...")
    make_API_call("post", `/projects/${projectId}/labels/newlabel`, {
      labelName,
      labelColorCode,
    })
      .then((res) => {
        stopWaitMsg()
        successMsg(res.message)
        dispatch(createLabelsSuccess())
        resolve()
      })
      .catch((err) => {
        stopWaitMsg()
        errorMsg(err.message)
        dispatch(createLabelsFailure())
        reject()
      })
  }
}
export function updateLabels(payload, resolve, reject) {
  return (dispatch) => {
    dispatch(updateLabelsRequest())
    //
    const { projectId, labelName, labelColorCode, labelId } = payload
    waitingMsg("Updating the label...")
    make_API_call("put", `/projects/${projectId}/labels/${labelId}/update`, {
      // .put("/projects/" + projectId + "/labels/" + labelId + "/update", {
      labelName,
      labelColorCode,
    })
      .then((res) => {
        stopWaitMsg()
        successMsg(res.message)
        dispatch(updateLabelsSuccess())
        resolve()
      })
      .catch((err) => {
        stopWaitMsg()
        errorMsg(err.message)
        dispatch(updateLabelsFailure())
        reject()
      })
  }
}
export function deleteLabels(payload, resolve, reject) {
  return (dispatch) => {
    dispatch(deleteLabelsRequest())
    //
    const { projectId, id } = payload
    waitingMsg("Deleting the label...")
    make_API_call("delete", `/projects/${projectId}/labels/${id}/delete`, {})
      //.delete("/projects/" + projectId + "/labels/" + id + "/delete", {})
      .then((res) => {
        stopWaitMsg()
        successMsg(res.message)
        dispatch(deleteLabelsSuccess())
        resolve()
      })
      .catch((err) => {
        stopWaitMsg()
        errorMsg(err.message)
        dispatch(deleteLabelsFailure())
        reject()
      })
  }
}
export function updatePermissions(payload) {
  return (dispatch) => {
    dispatch(updatePermissionsRequest())
    //
    const { uid, projectId, create, update, read, del } = payload
    waitingMsg("Updaating the Permissions...")
    make_API_call("put",`/projects/${projectId}/updatepermissions`,{
     // .put("/projects/" + projectId + "/updatepermissions", {
        permissionLevels: {
          uid,
          create,
          update,
          read,
          delete: del,
        },
      })
      .then((res) => {
        stopWaitMsg()
        successMsg(res.message)
        dispatch(updatePermissionsSuccess())
      })
      .catch((err) => {
        stopWaitMsg()
        errorMsg(err.message)
        dispatch(updatePermissionsFailure())
      })
  }
}
export function createTasks(payload) {
  return (dispatch) => {
    dispatch(createTasksRequest())
    //
    const {
      title,
      type,
      status,
      startdate,
      enddate,
      priority,
      assignee,
      description,
      setReminder,
      reminderDate,
      category,
      labels,
      id,
    } = payload
    console.log(
      title,
      type,
      status,
      startdate,
      enddate,
      priority,
      assignee,
      description,
      setReminder,
      reminderDate,
      category,
      labels,
      id
    )
    waitingMsg("creating the task...")
    make_API_call("post",`/projects/${id}/tasks/new`,{
      //.post("/projects/" + id + "/tasks/new", {
        title,
        type,
        status,
        startdate,
        enddate,
        priority,
        assignee,
        description,
        setReminder,
        reminderDate,
        category,
        labels,
      })
      .then((res) => {
        stopWaitMsg()
        successMsg(res.message)
        dispatch(createTasksSuccess())
      })
      .catch((err) => {
        stopWaitMsg()
        errorMsg(err.message)
        dispatch(createTasksFailure())
      })
  }
}
export function createsubTasks(payload,handleClose) {
  return (dispatch) => {
    dispatch(createSubTasksRequest())
    //
    const {
      title,
      type,
      status,
      startdate,
      enddate,
      priority,
      assignee,
      description,
      setReminder,
      reminderDate,
      category,
      labels,
      id,
      taskId,
    } = payload
    waitingMsg("Creating the Subtask...")
    make_API_call("post",`/projects/${id}/tasks/new`,{
     // .post("/projects/" + id + "/tasks/new", {
        title,
        type,
        status,
        startdate,
        enddate,
        priority,
        assignee,
        description,
        setReminder,
        reminderDate,
        category,
        labels,
        taskId,
      })
      .then((res) => {
        stopWaitMsg()
        successMsg(res.message)
        dispatch(createSubTasksSuccess())
        handleClose()
      })
      .catch((err) => {
        stopWaitMsg()
        errorMsg(err.message)
        dispatch(createSubTasksFailure())
      })
  }
}
export function editTasks(payload,handleClose) {
  console.log("line-342================",handleClose,"=============================")
  return (dispatch) => {
    dispatch(editTasksRequest())
    //
    const {
      title,
      type,
      status,
      startdate,
      enddate,
      priority,
      labels,
      assignee,
      description,
      setReminder,
      reminderDate,
      taskId,
      projectId,
    } = payload
    console.log(
      title,
      type,
      status,
      startdate,
      enddate,
      priority,
      labels,
      assignee,
      description,
      setReminder,
      reminderDate,
      taskId,
      projectId
    )
    waitingMsg("Updating the task...")
    make_API_call("put",`/projects/${projectId}/tasks/${taskId}/update`,{
      //.put("/projects/" + projectId + "/tasks/" + taskId + "/update", {
        title,
        type,
        status,
        startdate,
        enddate,
        priority,
        labels,
        assignee,
        description,
        setReminder,
        reminderDate,
      })
      .then((res) => {
        stopWaitMsg()
        successMsg(res.message)
        dispatch(editTasksSuccess())
        handleClose()
      })
      .catch((err) => {
        stopWaitMsg()
        errorMsg(err.message)
        dispatch(editTasksFailure())
      })
  }
}
export function linkTasks(payload,handleClose) {
  console.log("line-405==================",handleClose,"=============================")
  return (dispatch) => {
    dispatch(linkTasksRequest())
    //
    const { projectID, data } = payload
    waitingMsg("Linking the tasks...")
    make_API_call("put", `/projects/${projectID}/tasks/link`, data)
      .then((res) => {
        stopWaitMsg()
        successMsg(res.message)
        dispatch(linkTasksSuccess())
        handleClose();
      })
      .catch((err) => {
        console.log(err)
        stopWaitMsg()
        errorMsg(err.error)
        dispatch(linkTasksFailure())
      })
  }
}
export function unLinkTasks(payload,handleClose) {
  return (dispatch) => {
    dispatch(unLinkTasksRequest())
    ////
    const { projectID, data } = payload
    waitingMsg("UnLinking the tasks...")
    make_API_call("put", `/projects/${projectID}/tasks/unlink`, data)
      .then((res) => {
        stopWaitMsg()
        successMsg(res.message)
        dispatch(unLinkTasksSuccess())
        handleClose()
      })
      .catch((err) => {
        stopWaitMsg()
        errorMsg(err.message)
        dispatch(unLinkTasksFailure())
      })
  }
}
export function addComment(payload) {
  return (dispatch) => {
    dispatch(addCommentsRequest())
    ////
    const { projectId, taskId, text } = payload
    waitingMsg("Commenting on tasks...")
    make_API_call("post", `/projects/${projectId}/tasks/${taskId}/comments/new`, { text })
      .then((res) => {
        stopWaitMsg()
        successMsg(res.message)
        dispatch(addCommentsSuccess())
      })
      .catch((err) => {
        stopWaitMsg()
        errorMsg(err.message)
        dispatch(addCommentsFailure())
      })
  }
}

export function updateComment(payload) {
  return (dispatch) => {
    dispatch(updateCommentsRequest())
    ////
    const { projectId, taskId, text, id } = payload
    waitingMsg("Updating comments on tasks...")
    make_API_call("put",`/projects/${projectId}/tasks/${taskId}/comments/${id}/update`,{text})
      // .put("/projects/" + projectId + "/tasks/" + taskId + "/comments/" + id + "/update", {
      //   text,
      // })
      .then((res) => {
        stopWaitMsg()
        successMsg(res.message)
        dispatch(updateCommentsSuccess())
      })
      .catch((err) => {
        stopWaitMsg()
        errorMsg(err.message)
        dispatch(updateCommentsFailure())
      })
  }
}

export function deleteComment(payload) {
  return (dispatch) => {
    dispatch(deleteCommentsRequest())
    ////
    const { projectId, taskId, id } = payload
    waitingMsg("Deleting comments on tasks...")
    make_API_call("delete",`/projects/${projectId}/tasks/${taskId}/comments/${id}/delete`,{})
      // .delete("/projects/" + projectId + "/tasks/" + taskId + "/comments/" + id + "/delete", {})
      .then((res) => {
        stopWaitMsg()
        successMsg(res.message)
        dispatch(deleteCommentsSuccess())
      })
      .catch((err) => {
        stopWaitMsg()
        errorMsg(err.message)
        dispatch(deleteCommentsFailure())
      })
  }
}

export function addMember(payload) {
  return (dispatch) => {
    dispatch(addMembersRequest())
    ////
    const { projectId, employees } = payload
    waitingMsg("Adding Members to project...")
    make_API_call("post",`/projects/${projectId}/addemployee`,{employees,})
      .then((res) => {
        stopWaitMsg()
        successMsg(res.message)
        dispatch(addMembersSuccess())
      })
      .catch((err) => {
        console.log(err.message)
        stopWaitMsg()
        errorMsg(err.message)
        dispatch(addMembersFailure())
      })
  }
}
