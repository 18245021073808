import JSZip from "jszip"
import JSZipUtils from "jszip-utils"
import saveAs from "save-as"

export function downloadasZip(e, data, filename) {
  const urls = data.map((documents) => documents.work_doc)
  const zip = new JSZip()
  let count = 0
  const zipFilename = filename
  urls.forEach(async function (item) {
    const urlArr = item.url
    const filename = `${item.name}${urlArr.substring(
      urlArr.lastIndexOf("."),
      urlArr.lastIndexOf("?")
    )}`
    try {
      const file = await JSZipUtils.getBinaryContent(urlArr)
      zip.file(filename, file, { binary: true })
      count++
      if (count === urls.length) {
        zip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, zipFilename)
        })
      }
    } catch (err) {
      console.log(err)
    }
  })
}
/*
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import saveAs from "save-as";

async function downloadItem(item, filename, length, zip) {
  let count = 0;
  const zipFilename = filename;
  const urlArr = item.url;
  const file = await JSZipUtils.getBinaryContent(urlArr);
  zip.file(item.name${urlArr.substring(
      urlArr.lastIndexOf("."),
      urlArr.lastIndexOf("?")
    )}, file, { binary: true });
  count++;
  if (count === length) {
    return zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, zipFilename);
    });
  }
}

export async function downloadasZip(e, data, filename) {
  const zip = new JSZip();
  const urls = data.map((documents) => documents.work_doc);
  const length = urls.length;
  const promises = [];
  urls.forEach((item) =>
    promises.push(downloadItem(item, filename, length, zip))
  );
  return Promise.allSettled(promises);
}
*/