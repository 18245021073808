import initialState from "../state/clientInvoiceLocation";
import ACTION from "../actions/actionTypes";

export function getInvoiceLocationClients (
  state = initialState,
  action
){
  switch (action.type) {
    case ACTION.CLIENT_ADDRESS_FOR_INVOICE_EMPLOYEE_REQ:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION.CLIENT_ADDRESS_FOR_INVOICE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        invoiceLocation: {
          ...state.invoiceLocation,
          ...action.payload,
        },
      };
    case ACTION.CLIENT_ADDRESS_FOR_INVOICE_EMPLOYEE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error:action.payload
      };
      default:
        return state
  }
};
