import { defaultInstance } from "../../instances/default.instance";

/**
 * @param {Record} data
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const createDeduction = (data, signal) => {
	return defaultInstance({
		method: "POST",
		url: "/deductions",
		data,
		signal,
	});
};

/**
 * @param {Object} query
 * @param {String?} query.employeeId
 * @param {AbortSignal?} signal
 * @returns {Promise<Array<Record>>}
 */
export const getAllDeductions = (query, signal) => {
	return defaultInstance({
		method: "GET",
		url: "/deductions",
		params: JSON.parse(JSON.stringify(query)),
		signal,
	});
};

/**
 * @param {String} id
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const getDeduction = (id, signal) => {
	return defaultInstance({
		method: "GET",
		url: `/deductions/${id}`,
		signal,
	});
};

/**
 * @param {Record} data
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const saveDraftDeduction = (data, signal) => {
	return defaultInstance({
		method: "PUT",
		url: "/deductions/draft",
		data,
		signal,
	});
};

/**
 * @param {String} id
 * @param {Record} data
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const updateDeduction = (id, data, signal) => {
	return defaultInstance({
		method: "PUT",
		url: `/deductions/${id}`,
		data,
		signal,
	});
};

/**
 * @param {String} id
 * @param {AbortSignal?} signal
 * @returns {Promise<Array<Record>>}
 */
export const getAllDeductionTransactions = (id, signal) => {
	return defaultInstance({
		method: "GET",
		url: `/deductions/${id}/transactions`,
		signal,
	});
};
