import React from "react"
import {
  Accordion as ExpansionPanel,
  AccordionDetails as ExpansionPanelDetails,
  AccordionSummary as ExpansionPanelSummary,
  Typography,
} from "@material-ui/core"
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons"
import { FaRegHandPointRight } from "react-icons/fa"
import { List } from "semantic-ui-react"
import useStyles from "../../../styles/allTemplatesStyle"

export default function Presentation(props) {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)
  const { letters, onSelect } = props
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const letterTypes = props.letterTypes.map((letter, index) => {
    return {
      name: letter,
      id: "panel" + index,
      type: letter,
    }
  })

  return (
    <div className={classes.root}>
      {letterTypes.map((paper) => {
        return (
          <ExpansionPanel expanded={expanded === paper.id} onChange={handleChange(paper.id)}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>{paper.name} </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className={classes.list}>
                {letters
                  .filter((letter) => letter.type === paper.type)
                  .map((item) => {
                    return (
                      <List selection onClick={() => onSelect(item.id)} verticalAlign="middle">
                        <List.Item className="d-flex">
                          <FaRegHandPointRight size={22} />
                          <List.Content>
                            <List.Header className="ml-4 mt-1">{item.name}</List.Header>
                          </List.Content>
                        </List.Item>
                      </List>
                    )
                  })}
                <Typography style={{ opacity: 0 }}>
                  Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat. Aliquam eget
                  maximus est, id dignissim quam.
                </Typography>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      })}
    </div>
  )
}
