import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import Presentation from "./Presentation"
import { compose } from "redux"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import validate from "../../../../../../../shared/validation"
import { clientDocuments } from "../../../../../middleware"

function Container(props) {
  const { clientId, placements_documents, placements_list, client_documents, refreshDocs } = props

  const [isLoading, setIsLoading] = useState(true)

  const [data, setData] = useState([])

  useEffect(() => {
    refreshDocs()
  }, [clientId])

  useEffect(() => {
    if (isLoaded(placements_documents)) {
      console.log(placements_documents)
      const placementIDs = placements_list
        .filter((item) => item.clientId === clientId)
        .map((item) => item.placementID)
      console.log(placementIDs)
      const documents = placements_documents.filter((doc) => placementIDs.includes(doc.placementID))
      console.log(documents)
      const data = []
      const documentTypes = ["Incorporation", "PO", "MSA", "COI", "W9", "MSA &PO", "Others"]
      documents.forEach((item) => {
        item.documents.forEach((doc) => {
          data.push({
            documentType: documentTypes.indexOf(doc.documentType),
            status: new Date(doc.effectiveUntilDate) < new Date() ? 1 : 0,
            work_doc: doc.work_doc,
            effectiveDate: validate.dateFormatter(doc.effectiveDate),
            effectiveUntilDate: validate.dateFormatter(doc.effectiveUntilDate),
          })
        })
      })
      console.log(data)
      setData(data)
      setIsLoading(false)
    }
  }, [placements_documents, placements_list, clientId])

  return (
    <div>
      <Presentation
        data={data.concat(client_documents.data)}
        isLoading={isLoading}
        clientId={clientId}
        client_documents={client_documents}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { placements_documents, placements_list } = state.firestore.ordered
  return {
    placements_documents,
    placements_list,
    client_documents: state.client.clientsDocuments,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { clientId } = ownProps
  return {
    refreshDocs: () => {
      dispatch(clientDocuments(clientId, false))
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collectionGroup: "SETTINGS",
        where: [["id", "==", "documents"]],
        storeAs: "placements_documents",
      },
    ]
  })
)(Container)
