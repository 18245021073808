import React from "react"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Skeleton } from "@material-ui/lab"

import Validations from "../../../../../shared/validation"
import MetaInfo from "../../../../../shared/getMetaInfo"


function Presentation(props) {
  const {
    placementID,
    employeeId,
    clientId,
    expenseApproval,
    placements,
  } = props
  console.log(props)
  let employeesCollection = Object.values(props.names[0]).filter(
    (employee) => employee.uid !== undefined
  )
  const employee = employeesCollection.filter(
    (item) => item.uid === employeeId
  )[0]
  const placement = placements[0]
  // const placement = state.placements_list.filter(
  //   (item) => item.placementID === placementID
  // )[0]
  // const client = state.client_list.filter(
  //   (item) => item.clientId === placement.clientId
  // )[0]
  const expenseApprover = employeesCollection.filter((item) =>
    expenseApproval.includes(item.companyID)
  )
  console.log(employee, expenseApprover)
  const metaInfo = new MetaInfo()
  const displayDetails = [
    {
      key: "Client Name",
      value: metaInfo.clientIdToName(clientId),
    },
    {
      key: "Client ID",
      value: clientId,
    },
    {
      key: "Placement ID",
      value: placementID,
    },
    {
      key: "Employee Name",
      value: employee?.name,
    },
    {
      key: "Employee ID",
      value: employee?.companyID,
    },
    {
      key: "Expense Approver",
      value: expenseApprover.map((exp) => exp?.name + "  "),
    },
  ]
  return (
    <div className="col-sm-6">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          {placementID !== "" ? (
            <Typography>
              <h2>{placement?.jobTitle} </h2>
            </Typography>
          ) : (
            <Skeleton animation="wave" />
          )}
        </AccordionSummary>
        <AccordionDetails className="bg-light">
          {placementID !== "" ? (
            <div>
              <table>
                <tbody>
                  {displayDetails.map((item) => {
                    return (
                      <tr>
                        <td>
                          <h4 style={{ fontWeight: 500 }}>{item.key} </h4>{" "}
                        </td>
                        <td> : {item.value}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div>
              <Skeleton />
              <Skeleton animation={false} />
              <Skeleton animation="wave" />
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default Presentation
