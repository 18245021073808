import ACTION from "../actions/actionTypes"
import projectListDashboardState from "./projectListDashboardState"

const projectListDashboardReducer = (state = projectListDashboardState, { type, payload }) => {
  switch (type) {
    case ACTION.PROJECT_LIST_COUNT_REQ:
      return {
        ...state,
        isLoading: true,
      }

    case ACTION.PROJECT_LIST_COUNT_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        error: null,
      }

    case ACTION.PROJECT_LIST_COUNT_FAILURE:
      return {
        ...state,
        data: projectListDashboardState.data,
        isLoading: false,
        error: payload,
      }

    default:
      return state
  }
}

export default projectListDashboardReducer
