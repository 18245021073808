import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import TextFormField from "../../../../../../shared/components/BootStrapFormFields/TextFormField";
import { useDispatch } from "react-redux";
import { updateProfile } from "../../../../middleware/dynamicProfileRender";
import FileUploadForm from "../../../../../../shared/components/BootStrapFormFields/FileUploadForm";
import AppModalHeader from "../../../../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../../../../shared/components/Modals/AppModalFooter";
import { RegexExp } from "../../../../../../shared/regex";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateEmployee } from "../../../../../../api/services/default/employee";

const schema = yup.object().shape({
  ssn: yup.string().required().matches(RegexExp.number),
  ssndocument:yup.string().required()
});

export default function SSNEditModal(props) {
  const { data, employeeID, idx, onHide } = props;
  const optionMapper = (i) => ({ value: i, label: i });
  
  const loadInitialValues = () => {
    if (!isNaN(idx)) {
      return data[idx];
    } else {
      return {};
    }
  };

  const getTitleText = () => {
    if (!isNaN(idx)) {
      return "Edit SSN FORM";
    } else {
      return "Create SSN FORM";
    }
  };

  const getSaveButtonText = () => {
    if (!isNaN(idx)) {
      return "Save Changes";
    } else {
      return "Create";
    }
  };

  const queryClient = useQueryClient();
  const useUpdateProfile = useMutation({
		queryKey: ["updateEmployee", employeeID],
		mutationFn: ({ employeeID, data }) => updateEmployee(employeeID, data),
		onSuccess: () => {
			queryClient.invalidateQueries(["getEmployee", employeeID]);
			onHide();
		},
	});

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);

    const formData = { ...values };

  let ssn = data?.map((item) => item) || [];

    if (!isNaN(idx)) {
      ssn[idx] = formData;
    } else {
      ssn.push(formData);
    }

    const payload = {
      ssn,
    };

    await useUpdateProfile.mutateAsync({ employeeID, data: payload });
		actions.setSubmitting(false);
  };

  const dispatch = useDispatch();
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        validationSchema={schema}
        initialValues={{ ...loadInitialValues() }}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <AppModalHeader title={getTitleText()} />
              <Modal.Body>
                <Row>
                  <Col xl={4}>
                    <Field
                      name="ssn"
                      type="text"
                      label={"SSN *"}
                      component={TextFormField}
                    />
                  </Col>
                  <Col xl={4}>
                     <Field
											name="ssndocument"
											component={FileUploadForm}
											label="SSN Document *"
											type="text"
											employeeId={employeeID}
                      sectionKey="ssn"
											/>
                  </Col>
                </Row>
              </Modal.Body>
              <AppModalFooter
                saveButtonText={getSaveButtonText()}
                saveButtonDisabled={
                  !formik.isValid ||
                  formik.isSubmitting ||
                  !formik.dirty
                }
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
