import React, { useEffect } from 'react';
import { Card, Row, Col, Modal, Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MetaInfo from '../../../../../../shared/getMetaInfo';
import validation from '../../../../../../shared/validation';
import PersonalinfoEditModal from './personalinfo';
import AssignRole from "../../../AssignRole/index"
import { useQuery } from '@tanstack/react-query';
import { getEmployee } from '../../../../../../api/services/default/employee';
import { Skeleton } from '@material-ui/lab';


const Personalinfo = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const { employeeID, isPersonal} = props
    const metaInfo = new MetaInfo()
    const { data : employeeData = {} } = useQuery({
        queryKey: ["getEmployee", employeeID],
        queryFn: () => getEmployee(employeeID),
        enabled: !!employeeID,
      })
    useEffect(() => {}, [employeeData]);
    return (
        <>
         {Object.values(employeeData).length ? (
        <>
            <PersonalinfoEditModal
                show={modalShow}
                names={props.names}
                access_modules={props.access_modules}
                employeeID={employeeID}
                data={{
                    ...employeeData.personal,
                    emailid: employeeData?.email,
                }}
                onHide={() => setModalShow(false)}
            />

            <Card>
                <Card.Body>
                    <div className="d-flex justify-content-between">
                        <h5 className="card-title mb-3 mt-1 header-title">Personal Information</h5>
                        <div className="d-flex">
                        {
                            isPersonal ? <></> 
                            :  
                            <>
                             <Link to="#" className="linkbtn mt-1" onClick={() => setModalShow(true)}><i className="fa-solid fa-pen"></i></Link>
                            <AssignRole id={employeeID} profile={employeeData}/>
                            </>
                        }
                        </div>
                    </div>
                    <Row>
                        <Col xl={3}>
                            <div className="mb-3">
                                <div className="text-muted">First Name</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.personal?.firstname}</div>
                            </div>
                        </Col>
                        <Col xl={3}>
                            <div className="mb-3">
                                <div className="text-muted">Middle Name</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.personal?.middlename}</div>
                            </div>
                        </Col>
                        <Col xl={3}>
                            <div className="mb-3">
                                <div className="text-muted">Last Name</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.personal?.lastname}</div>
                            </div>
                        </Col>
                        <Col xl={3}>
                            <div className="mb-3">
                                <div className="text-muted">Gender</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.personal?.gender}</div>
                            </div>
                        </Col>
                        <Col xl={3}>
                            <div className="mb-3">
                                <div className="text-muted">Marital status</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.personal?.maritalstatus}</div>
                            </div>
                        </Col>
                        <Col xl={3}>
                            <div className="mb-3">
                                <div className="text-muted">Date of birth</div>
                                <div className="fs-16 fw-medium text-base">{validation.dateFormatter(employeeData?.personal?.dob)}</div>
                            </div>
                        </Col>
                        <Col xl={3}>
                            <div className="mb-3">
                                <div className="text-muted">Email</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.email}</div>
                            </div>
                        </Col>
                        <Col xl={3}>
                            <div className="mb-3">
                                <div className="text-muted">Phone</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.personal?.phonenumber}</div>
                            </div>
                        </Col>
                        <Col xl={3}>
                            <div className="mb-3">
                                <div className="text-muted">Department</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.personal?.department}</div>
                            </div>
                        </Col>
                        <Col xl={3}>
                            <div className="mb-3">
                                <div className="text-muted">Job Title</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.personal?.jobtitle}</div>
                            </div>
                        </Col>
                        <Col xl={3}>
                            <div className="mb-3">
                                <div className="text-muted">Date of Joining</div>
                                <div className="fs-16 fw-medium text-base">{validation.dateFormatter(employeeData?.personal?.dateofjoining)}</div>
                            </div>
                        </Col>
                        <Col xl={3}>
                            <div className="mb-3">
                                <div className="text-muted">Employee Status</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.employeeStatus}</div>
                            </div>
                        </Col>
                        <Col xl={3}>
                            <div className="mb-3">
                                <div className="text-muted">Category</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.personal?.category}</div>
                            </div>
                        </Col>
                        <Col xl={3}>
                            <div className="mb-3">
                                <div className="text-muted">Supervisor</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.personal?.supervisor ? 'Yes' : 'No'}</div>
                            </div>
                        </Col>
                        <Col xl={3}>
                            <div className="mb-3">
                                <div className="text-muted">Reporting Manager</div>
                                <div className="fs-16 fw-medium text-base">{metaInfo.getEmployeeKey(employeeData?.personal?.reportingmanager,'name')}</div>
                            </div>
                        </Col>
                        <Col xl={3}>
                            <div className="mb-3">
                                <div className="text-muted">Alternative Email</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.personal?.emailid}</div>
                            </div>
                        </Col>
                        <Col xl={3}>
                            <div className="mb-3 mb-lg-0">
                                <div className="text-muted">Payroll Id</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.personal?.payrollid}</div>
                            </div>
                        </Col>
                        <Col xl={3}>
                            <div className="mb-3 mb-lg-0">
                                <div className="text-muted">Branch</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.personal?.branch}</div>
                            </div>
                        </Col>
                        <Col xl={3}>
                            <div className="mb-3 mb-lg-0">
                                <div className="text-muted">Salary</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.personal?.salary}</div>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
        ) : (
            <Skeleton variant="rounded" height={260} />
        )}
        </>
       
    );
};

const mapStateToProps = state => ({
    access_modules: state.employee.employeeModules.accessModules,
    names: state.employee.employeeList.metaInfoofEmployees.data
})



export default connect(mapStateToProps, null)(Personalinfo)
