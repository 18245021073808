import React from 'react'
import {
  Grid,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core"
import { FcHome } from "react-icons/fc"
import { RiBuildingLine } from "react-icons/ri"
import { useNewInvoiceContext } from './context'

function InvoiceAddress() {
  const { state } = useNewInvoiceContext()
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Card
          style={{
            background:
              "#e2edc1",
            width: "100%",
            height:"100%"
          }}
        >
          <CardContent>
            <div className="d-flex">
              <div>
                <Typography color="textSecondary" gutterBottom>
                  <FcHome size={80} />
                </Typography>
              </div>
              <div>
                <Typography color="textSecondary" gutterBottom>
                  From address
                </Typography>
                <Typography variant="h4" >
                  {state.isInvoiceEdit ? state.companyDetails?.name : state.companyDetails?.companyName}
                </Typography>
                <Typography>
                  {state.isInvoiceEdit ? state.companyDetails?.address : state.companyDetails?.contactDetails?.address}
                </Typography>
              </div>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card
          style={{
            background:
              "#dff3fc",
            width: "100%",
            height:"100%"
          }}
        >
          <CardContent>
            <div className="d-flex">
              <div>
                <RiBuildingLine size={80} />
              </div>
              <div className="ml-2">
                <Typography color="textSecondary" gutterBottom>
                  Billing address
                </Typography>
                <Typography variant="h4">
                  {state.clientDetails?.name}
                </Typography>
                <Typography>
                {state.clientDetails?.address}
                </Typography>
              </div>

            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default InvoiceAddress