import { Backdrop, Badge, CircularProgress, Grid, Paper } from '@material-ui/core';
import React,{useEffect,useState} from 'react'
import { AnnouncementsContextProvider,useAnnouncementContext } from "./context";
import validate from "../../shared/validation"
import parser from "html-react-parser"
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TiTick } from 'react-icons/ti';
import { ImCross } from 'react-icons/im';
import EmployeeHoverCard from "../../shared/components/EmployeeHoverCard"
import "./TimeStyles.css"

const useStyles = makeStyles({
    table: {
      maxWidth:580,
    },
  });

function ViewAnnouncementComponent(props) {
  const { state,services } = useAnnouncementContext();
  const classes = useStyles();
  const { announcementId } = props
  const [announcedToArr, setAnnouncedToArr] = useState([])

  useEffect(() => {
    services.viewAnnouncementbyId({announcementId : announcementId})
  }, [announcementId])

  useEffect(() => {
    setAnnouncedToArr([...new Set([...(state?.viewAnnouncement?.announceTo) || [],...(state?.viewAnnouncement?.acknowledged) || [],...(state?.viewAnnouncement?.declined) || []])])
  },[JSON.stringify(state)])

  const d = new Date(state.viewAnnouncement.createdAt);
  const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  let month = months[d.getMonth()];
  let day =weekday[d.getDay()];
  let date = d.getDate()

  return (
    <div>
      <Backdrop style={{ color: "#fff", zIndex: 4000 }} open={state.showLoader}>
          <CircularProgress color="inherit" />
      </Backdrop>
        <Grid container>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
                <Paper style={{padding:"15px"}}>
                    <div style={{textAlign:"-webkit-right"}}>
                      <time dateTime={validate.dateAndTimeFormatter(state.viewAnnouncement.createdAt)} className="icon">
                        <em>{day}</em>
                        <strong>{month}</strong>
                        <span>{date}</span>
                      </time>
                    </div>
                    <div style={{fontWeight:"bold"}}>{state.viewAnnouncement.subject}</div>
                    <br/>
                    <div>{parser(state?.viewAnnouncement?.body ? state?.viewAnnouncement?.body : "")}</div>
                    <br/>
                    <TableContainer className={classes.table} style={{margin:"auto"}}>
                        <Table className={classes.table} aria-label="spanning table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" colSpan={1}>Announced To{announcedToArr ? <Badge badgeContent={announcedToArr.length}  color="error"></Badge> : "" }</TableCell>
                                <TableCell align="center" colSpan={1}>Acknowledged By{state?.viewAnnouncement?.acknowledged ? <Badge badgeContent={state?.viewAnnouncement?.acknowledged.length}  color="error"></Badge> : "" }</TableCell>
                                <TableCell align="center" colSpan={1}>Declined By{state?.viewAnnouncement?.declined ? <Badge badgeContent={state?.viewAnnouncement?.declined.length}  color="error"></Badge> : "" }</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {announcedToArr.map((id,idx) => (
                                <TableRow key={idx}>
                                    <TableCell align="center" colSpan={1}><EmployeeHoverCard employeeID={id} /></TableCell>
                                    <TableCell align="center" colSpan={1}>{state?.viewAnnouncement?.acknowledged.includes(id) ? <TiTick style={{color:"green"}} size={20} /> : "--" }</TableCell>
                                    <TableCell align="center" colSpan={1}>{state?.viewAnnouncement?.declined.includes(id) ? <ImCross style={{color:"red"}} /> : "--" }</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
            <Grid item xs={2}></Grid>
        </Grid>
    </div>
  )
}

const ViewAnnouncement = (props) => <AnnouncementsContextProvider><ViewAnnouncementComponent {...props} /> </AnnouncementsContextProvider>
export default ViewAnnouncement