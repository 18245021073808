import { CardContent, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { FaBalanceScale, FaFileInvoiceDollar } from 'react-icons/fa'
import Validations from '../../../../../shared/validation';


const paperStyle = {

    padding: "1px",
    height: "85%",
    borderColor: "#DF6589FF",

};
const useStyles = makeStyles({
    Card: {
        "&:hover": {
            color: "grey"
        },
        backgroundColor: "White",
        borderRadius: "20px solid",
    },

});
const Cards = ({ invoices }) => {

    const [openBalance, setOpenBalance] = useState(0)
	const [invoicedAmount, setInvoicedAmount] = useState(0)

    useEffect(() => {
		let openBalance = 0
		let invoicedAmount = 0
		invoices.forEach((value) => {
			openBalance += value.openBalance;
			invoicedAmount += value.amount
		})
		setOpenBalance(openBalance)
		setInvoicedAmount(invoicedAmount)
	}, [JSON.stringify(invoices)])
    
    const classes = useStyles();
    return (
        <Grid item container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <Paper elevation={2} style={paperStyle} className={classes.Card} type="button" >
                    <CardContent style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Typography className="h2 font-weight-bold mb-0" style={{ textAlign: "left" }}>
                                <h2 style={{ fontWeight: "normal", color: "#229106" }}>{Validations.currencyFormatterUs(invoicedAmount)}</h2>
                            </Typography>
                            <div>
                                <Typography
                                    className="text-uppercase text-muted mb-0"
                                    gutterBottom={true}
                                    style={{ textAlign: "left", paddingTop: "10px" }}
                                >
                                  Total Invoiced Amount
                                </Typography>
                            </div>
                        </div>
                        <div >
                            <FaFileInvoiceDollar size={50} style={{ color: "#229106", opacity: "0.7" }} />
                        </div>
                    </CardContent>
                </Paper>
            </Grid> 
            <Grid item xs={12} sm={6} md={3}>
                <Paper elevation={2} style={paperStyle} className={classes.Card} type="button" >
                    <CardContent style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Typography className="h2 font-weight-bold mb-0" style={{ textAlign: "left" }}>
                                <h2 style={{
                                    fontWeight: "normal", color: "#cc8706"
                                }}>{Validations.currencyFormatterUs(openBalance)}</h2>
                            </Typography>
                            <div>
                                <Typography
                                    className="text-uppercase text-muted mb-0"
                                    gutterBottom={true}
                                    style={{ textAlign: "left", paddingTop: "10px" }}
                                >
                                   Total Open Balance
                                </Typography>
                            </div>
                        </div>
                        <div >
                            <FaBalanceScale size={50} style={{
                                color: "#cc8706", opacity: "0.7"
                            }} />
                        </div>
                    </CardContent>
                </Paper>
            </Grid>
          
          
        </Grid >
    )
}

export default Cards
