import React from "react"
import CreateExpenses from "../Services/Expenses/components/CreateExpenses/Index"

function CreateExpensesPage(props) {
  return (
    <div>
      <CreateExpenses {...props.match.params} />
    </div>
  )
}

export default CreateExpensesPage
