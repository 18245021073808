import React from "react"
import { Typography } from "@material-ui/core"
import { Link } from "react-router-dom"
import { Breadcrumbs } from "@material-ui/core"
import MetaInfo from "../shared/getMetaInfo"
// import { getAnnouncementNameByID } from "./middleware"
function BreadCrumbs(props) {
  const { currentPath, companyPrefix, search } = props
  let prefix = companyPrefix.hasOwnProperty("prefix")
    ? companyPrefix["prefix"]
    : ""
  return breadcrumb(currentPath, prefix,search)
}

export default BreadCrumbs
const name = (route, prefix, index, currentPath,search) => {
  const metaInfo = new MetaInfo()
  if (prefix === route.slice(0, 3)) {
    return metaInfo.emailToName(route)
  } else if ("CLN" === route.slice(0, 3)) {
    return metaInfo.clientIdToName(route)
  } else if (currentPath[index - 1] === "projects") {
    return "Project"
  } else if (currentPath[index - 2] === "projects") {
    return "Task"
  } else if (currentPath[index - 1] === "expenseslist") {
    return "Expenses"
  }else if (currentPath[index] === "timesheets"){
    return "Timesheets"
  }else if (currentPath[index] === "invoiceslist"){
    return "Invoices"
  }else if (currentPath[index] === "letterrequests"){
    return "LetterRequests"
  }else if (currentPath[index - 1] === "wiki") {
    return "Article"
  } else if (currentPath[index - 2] === "wiki") {
    return "RevertPage"
  } else if (currentPath[index - 1] === "Announcements"){
    return search[1]
  } else if (route === "projects") {
    return "ProjectsList"
  } else {
    return route
  }
}
const link = (route, index, prefix, currentPath) => {
  // `/console/${currentPath.slice(0, index + 1).join("/")}`
  console.log(
    route,
    currentPath[index + 1],
    currentPath.slice(0, index + 1).join("/")
  )
  const metaInfo = new MetaInfo()
  if (prefix === currentPath[index].slice(0, 3)) {
    return `/console/employees/${currentPath[index]}`
  } else if ("expenseslist" === currentPath[index - 1]) {
    return `/console/expenseslist`
  } else {
    return `/console/${currentPath.slice(0, index + 1).join("/")}`
  }
}
const breadcrumb = (currentPath,prefix,search) => {
  {
    if (currentPath.length > 0) {
      currentPath.shift()
      return (
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/home">Home</Link>
          {( 
            currentPath.map((route, index) => {
              return index !== currentPath.length - 1 ? (
                <Link to={`${link(route, index, prefix, currentPath,search)}`}>
                  {name(route, prefix, index, currentPath,search)}
                </Link>
              ) : (
                <Typography key={index} color="textPrimary">
                  {name(route, prefix, index, currentPath,search)}
                </Typography>
              );
            })
          )}
        </Breadcrumbs>
      );
    } else return <></>
  }
}