import React from "react"
import TaskHandler from "../Services/TaskManagement/components/Tasks/TaskHandler"

function ProjectHandlerPage(props) {
  return (
    <div>
      <TaskHandler {...props} />
    </div>
  )
}

export default ProjectHandlerPage
