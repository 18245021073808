import React from "react"
import { Typography } from "@material-ui/core"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Edit from "../EditDiscount"

function Presentation(props) {
  const { status, discountDetails } = props.accounts
  return (
    <div className="row">
      <div className="col-11">
        <Typography color="textSecondary" gutterBottom>
          Discount Details({status})
        </Typography>
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell align="right">Discount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {discountDetails.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell style={{ textTransform: "capitalize" }}>
                    {row.type}
                  </TableCell>
                  <TableCell align="right">{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="col-1">
        <Edit accounts={props.accounts} clientID={props.clientID} />
      </div>
    </div>
  )
}

export default Presentation
