import { combineReducers } from "redux"
import { employeeInviteReducer } from "./employeeInviteReducer"
import employeeListReducer from "./employeeListReducer"
import { employeeModulesReducer } from "./employeeModulesReducer"
import employeeProfile from "./employeeProfile"
import employeeInvitationTokenReducer from "./employeeInvitationTokenReducer"
import dynamicProfileRender from "./dynamicProfileRender"

const rootReducer = combineReducers({
  employeeList: employeeListReducer,
  employeeModules: employeeModulesReducer,
  employeeInvite: employeeInviteReducer,
  employeeProfile: employeeProfile,
  invitationToken: employeeInvitationTokenReducer,
  dynamicProfileRender: dynamicProfileRender
})

export default rootReducer
