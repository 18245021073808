import React,{useState} from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import AppModalHeader from "../../../../shared/components/Modals/AppModalHeader"
import { Field, Form, Formik } from "formik";
import TextFormField from "../../../../shared/components/BootStrapFormFields/TextFormField";
import AppModalFooter from "../../../../shared/components/Modals/AppModalFooter";
import { useDialog } from "../../../../hooks/useDialog"
import * as yup from "yup";
import FileUploadForm from "../../../../shared/components/BootStrapFormFields/FileUploadForm";
import { useNewInvoiceContext }  from "./context";
import { FaPen } from "react-icons/fa";
import { IconButton } from "@material-ui/core";

const schema = yup.object().shape({
	serviceSummary: yup.string().required("Required"),
	amount: yup.number().required("Required"),
  attachment: yup.string(),
});

export default function AddAdditionalItems({type,item = {}}) {
	const { open, handleOpen, handleClose } = useDialog();
  const initialState = item;
	const [additionalItem, setAdditionalItem] = useState(initialState);
  const { state, stateSetters } = useNewInvoiceContext();


	const handleSubmit = (values, actions) => {
    if(type === "new"){
      setAdditionalItem(values);
      actions.setSubmitting(true);
      stateSetters.setAdditionalItems([...state.additionalItems,values]);
      actions.setSubmitting(false);
      handleClose();
    }else{
      setAdditionalItem(values);
      let dummy = [...state.additionalItems];
      let index = dummy.findIndex(obj => obj?.serviceSummary === item?.serviceSummary);
      if (index !== -1) {
        dummy[index] = values;
      }
      actions.setSubmitting(true);
      stateSetters.setAdditionalItems([...dummy]);
      actions.setSubmitting(false);
      handleClose();
    }
	};

	return (
		<div>
      {
        type === "edit" && <IconButton onClick={handleOpen} size="small"><FaPen /></IconButton>
      }
      {
        type === "new" && <Button variant="primary" onClick={handleOpen} disabled={!state?.invoiceBy}>Add Line Item</Button>
      }
			<Modal show={open} onHide={handleClose} centered size="md">
				<Formik
					validationSchema={schema}
          initialValues={initialState}
					enableReinitialize
					onSubmit={handleSubmit}
				>
					{({ isValid, isSubmitting, dirty }) => (
						<Form>
							<AppModalHeader title="Add New Line Item" />
							<Modal.Body>
								<Row>
									<Col xl={12}>
										<Field
											component={TextFormField}
											label="Service Summary *"
											name="serviceSummary"
											placeholder="Service Summary"
											type="text"
										/>
									</Col>
                  <Col xl={6}>
                    <Field
                      name="attachment"
                      label={"Upload Attachment (optional)"}
                      type="file"
                      component={FileUploadForm}
                      storageDir={`InvoicesAdditionalItemsAttachments`}
                    />
                  </Col>
									<Col xl={12}>
										<Field
											component={TextFormField}
											label="Amount *"
											name="amount"
											placeholder="Amount"
											type="text"
										/>
									</Col>
								</Row>
							</Modal.Body>
							<AppModalFooter
								saveButtonDisabled={!isValid || isSubmitting || !dirty}
                saveButtonText={type === "new" ? "Add" : "Update"}
								onClickCancel={handleClose}
							/>
						</Form>
					)}
				</Formik>
			</Modal>
		</div >
	);
}
