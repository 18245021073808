import React from "react"
import { Divider, Grid, List, ListItem, ListItemText } from "@material-ui/core"
import ProjectList from "../../../ProjectList/Index"
import { Pie } from "react-chartjs-2"
import MetaInfo from "../../../../../../shared/getMetaInfo"
import ProjectDashBoardTeamactivity from "../ProjectDashBoardTeamactivity"
//import NewProject from "../../../NewProject"
import ProjectDashNavBoard from "../ProjectlistDashboardNavbar"
function Presentation(props) {
  const { role, dashboardData, state,projectstate } = props
  const closed = projectstate.closedProjects.data
  const Inprogress = projectstate.inProgressProjects.data
  const due = projectstate.overDueProjects.data
  const closedcount =  Object.values(closed).length
  const Inprogresscount = Object.values(Inprogress).length
  const duecount = Object.values(due).length
  const { totalCount, Open, OverDue, Closed } = dashboardData.projectsCount
  console.log(Inprogresscount,"...............")
  const projectsData ={ 
   labels: ["InProgress", "Closed", "OverDue"],
    datasets: [
      {
        lablel: "Projects",
        data: [Inprogresscount, closedcount, duecount],
        backgroundColor: ["#e8eb34", "#eb345c", "#3489eb"],
      },
    ],
  }

  const validate = new MetaInfo()

  if (role)
    return (
      <div>
        <ProjectDashNavBoard />
        <Grid container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={6}>
                <div className="card">
                  <div className="card-body">
                    <Pie data={projectsData} />
                  </div>
                  <h3 style={{ textAlign: "center", marginBottom: "10px" }}>
                    Total Projects: {totalCount}
                  </h3>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  className="card"
                  style={{ height: "100%", overflowY: "auto" }}
                >
                  <div className="card-body">
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <div>
                          <List>
                            <ListItem>
                              <h4 style={{ textAlign: "center" }}>
                                Supervisors
                              </h4>
                            </ListItem>
                            <hr style={{ backgroundColor: "black" }} />
                            <ListItem>
                              <h1 style={{ textAlign: "center" }}>
                                {dashboardData.users.length}
                              </h1>
                            </ListItem>
                          </List>
                        </div>
                        <Divider orientation="vertical" flexItem />
                      </Grid>
                      <div style={{ borderLeft: "1px solid black" }}></div>
                      <Grid item xs={6}>
                        <div>
                          <List>
                            {dashboardData.users.map((user) => {
                              return (
                                <>
                                  <ListItem>
                                    <ListItemText
                                      primary={validate.emailToName(user)}
                                    />
                                  </ListItem>
                                  <hr style={{ backgroundColor: "black" }} />
                                </>
                              )
                            })}
                          </List>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <ProjectList />
              </Grid>
            </Grid>
          </Grid>
          {/* temporarily removed  */}
          {/* <Grid item xs={3}>
            <ProjectDashBoardTeamactivity />
          </Grid> */}
        </Grid>
      </div>
    )
  return (
    <div>
      <ProjectList />
    </div>
  )
}

export default Presentation
