import { initialState } from "./clientLocationState";
import ACTION from "../actions/actionTypes";

export const clientsLocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.GET_CLIENTS_LOCATIONS_REQ:
      return {
        ...state,
        isLoaded: false,
      };

    case ACTION.GET_CLIENTS_LOCATIONS_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        locations: action.payload,
      };

    case ACTION.GET_CLIENTS_LOCATIONS_FAILURE:
      return {
        ...state,
        isLoaded: false,
      };

    default:
      return state;
  }
};
