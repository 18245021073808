import React from "react"
import Skeleton from "@material-ui/lab/Skeleton"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
	root: {
		width: 300,
	},
})

export function Animations() {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<Skeleton />
			<Skeleton animation={false} />
			<Skeleton animation='wave' />
		</div>
	)
}

export function Variants() {
	return (
		<div>
			<Skeleton variant='text' />
			<Skeleton variant='circle' width={40} height={40} />
			<Skeleton variant='rect' width={210} height={118} />
		</div>
	)
}
