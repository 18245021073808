import React from "react"
import { Tab, Tabs, Grid, IconButton } from "@material-ui/core"
import { Add, Close } from "@material-ui/icons"
import TabPanel from "../../../../../../../shared/tabPanel"
import Form from "../Form"

function Presentation(props) {
  const {
    handleTabChange,
    tabList,
    deleteTab,
    addTab,
    value,
    handleChange,
    billableClient,
    clients,
    fillableSections,
    id,
    employeeID,
    closeTab,
    handleSubmit,
    employeeCategory,
    tabValue
  } = props

  return (
    <div style={{ width: "100%", flex: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          {tabValue?.clientType !== "Sub-Contractor" && employeeCategory === "C2C" ? <p style={{ color: "red", fontSize: "small",fontWeight:"bold" }}>Note:Sub-Contractor is mandatory as employee is in C2C Category.</p> : ""}
          <Tabs value={value} onChange={handleTabChange} variant="scrollable">
            {tabList.map((tab) => (
              <Tab
                key={tab.key.toString()}
                value={tab.id}
                label={
                  <div>
                    {tab.clientId ? clients[tab.clientId].businessDisplayName : "Layer " + tab.id}
                    {tab?.key !== 0 ? (
                      <IconButton id={tab.id} onClick={(e) => deleteTab(e,tab.id)} color="inherit">
                        <Close fontSize="small" />
                      </IconButton>
                    ) : null}
                  </div>
                }
              />
            ))}
          </Tabs>
          {tabList.map((item, index) => (
            <TabPanel value={value} index={item.id}>
              {console.log(item, "---kkkk")}
              <Form
                key={item.key.toString()}
                item={item}
                index={index}
                tabList={tabList}
                handleChange={handleChange}
                closeTab={closeTab}
                fillableSections={fillableSections}
                billableClient={item.key === 0 ? billableClient : ""}
                uid={employeeID}
                id={id}
                submitClientDetails={handleSubmit}
                employeeCategory={employeeCategory}
              />
            </TabPanel>
          ))}
        </Grid>
        <Grid item xs={1}>
          <IconButton color="inherit" onClick={addTab}>
            <Add fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  )
}

export default Presentation
