export default {
  profileTemplate: {
    isLoading: true,
    data: {},
    error: "",
    isUpdating: false,
    errorWhileUpdating: ""
  },
  addingLabel: {
    customize: false,
    editAccess: "",
    fieldPriority: 0,
    isExist: true,
    label: "",
    name: "",
    required: false,
    type: "name"
  },
  newField: {
    customize: false,
    editAccess: "employee",
    fieldPriority: 0,
    isExist: true,
    label: "",
    name: "",
    required: false,
    type: "",
    values: [],
    minDate: "",
    country: ""
  },
  addSection: {
    isEnabled: false,
    access_key: "",
    sectionName: "",
    sortPriority: null,
    fields: []
  }
}