import React,{useEffect} from "react";
import { AnnouncementsContextProvider,useAnnouncementContext } from "./context";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
import validate from "../../shared/validation"
import FlairTable from "../../shared/FlairTable"
import { useSelector } from "react-redux";
import AnnouncementDialog from "./AnnouncementDialog";

function EmployeeAnnouncementsComponent(props) {
  const { state,services } = useAnnouncementContext();
  const auth = useSelector((state) => state.firebase.auth);

  useEffect(() => {
    services.getEmployeeAnnouncements(auth.uid)
  }, [])

  const columns = [
    {
      title: "Announcement Subject",
      field: "subject",
      dataType: "",
      filtering:false,
      width:500,
      render: (rowData) => {
        return (
          <div>
            <div style={{display:"flex",flexDirection:"column"}}>
              {rowData.subject}
              <Typography className="d-flex" variant="caption" color="textSecondary">
              CreatedOn:{" "}
              <em className="mr-1">
                {validate.dateAndTimeFormatter(rowData.createdAt)}
              </em></Typography>
            </div>
          </div>
        )
      },
    },
    {
      title: "Status",
      field: "",
      dataType: "String",
      align:"center",
      render: (rowData) => {
        return rowData.acknowledged? (
          <span className="custom-chip chip-success">
            Acknowledged
          </span>
        ) : (
          <span className="custom-chip chip-danger">
            Declined
          </span>
        )
      },
    },
    {
      title: "View",
      field: "id",
      dataType: "",
      filtering:false,
      align:"center",
      render: (rowData) => {
        return (
          <div>
            <AnnouncementDialog announcement={rowData}/>
          </div>
        )
      },
    }
  ]

  const rows = state?.employeeAnnouncements?.map((announcement) => {
    return {
      id:announcement.id,
      subject:announcement.subject,
      createdAt:announcement.createdAt,
      acknowledged:announcement.acknowledged.includes(auth.uid),
      declined:announcement.declined.includes(auth.uid),
      body:announcement.body
    }
  })

  return (
    <>
      <Backdrop style={{ color: "#fff", zIndex: 4000 }} open={state.showLoader}>
          <CircularProgress color="inherit" />
      </Backdrop>
      <FlairTable
        title=""
        data={rows}
        columns={columns}
      />
    </>
  );
}

const EmployeeAnnouncements = (props) => <AnnouncementsContextProvider><EmployeeAnnouncementsComponent {...props} /> </AnnouncementsContextProvider>
export default EmployeeAnnouncements