import { ACTION } from "../actions/actionTypes";
import { initState } from "./expiredListState";

export const expiredListReducer = (state = initState, action) => {
  switch (action.type) {
    case ACTION.EXPIREDLIST_REQUEST:
      return {
        ...state,
        isLoaded: false,
        isEmpty: false,
      };

    case ACTION.EXPIREDLIST_SUCCESS:
      return {
        ...state,
        expiredItems: action.payload,
        isLoaded: true,
        isEmpty: false,
        error: null,
      };

    case ACTION.EXPIREDLIST_ERROR:
      return {
        ...state,
        expiredItems: [],
        isLoaded: true,
        isEmpty: false,
        error: action.err,
      };
    
    case ACTION.UPDATE_WORKQUEUE_DOCUMENT_REQUEST:
      return {
        ...state,
        updateWorkQueueDocument: {
          isLoading: true,
          err: null,
        }
      };
    
    case ACTION.UPDATE_WORKQUEUE_DOCUMENT_SUCCESS:
      return {
        ...state,
        updateWorkQueueDocument: {
          isLoading: false,
          payload: action.payload,
        }
      };
    
    case ACTION.UPDATE_WORKQUEUE_DOCUMENT_FAILURE:
      return {
        ...state,
        updateWorkQueueDocument: {
          isLoading: false,
          err: action.err,
        }
      };

    case ACTION.REVIEW_WORKQUEUE_DOCUMENT_REQUEST:
      return {
        ...state,
        reviewWorkQueueDocument: {
          isLoading: true,
          err: null,
        }
      };

    case ACTION.REVIEW_WORKQUEUE_DOCUMENT_SUCCESS:
      return {
        ...state,
        reviewWorkQueueDocument: {
          isLoading: false,
          payload: action.payload,
        }
      };

    case ACTION.REVIEW_WORKQUEUE_DOCUMENT_FAILURE:
      return {
        ...state,
        reviewWorkQueueDocument: {
          isLoading: false,
          err: action.err,
        }
      };
    
    case ACTION.GET_REVIEW_DOCUMENT_REQUEST:
      return {
        ...state,
        getReviewDocument: {
          isLoading: true,
          err: null,
        }
      };

    case ACTION.GET_REVIEW_DOCUMENT_SUCCESS:
      return {
        ...state,
        getReviewDocument: {
          isLoading: false,
          data: action.payload,
        }
      };

    case ACTION.GET_REVIEW_DOCUMENT_FAILURE:
      return {
        ...state,
        getReviewDocument: {
          isLoading: false,
          err: action.err,
        }
      };
  
    default:
      return state;
  }
};
