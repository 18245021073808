import React from "react"
import { useState } from "react"
import MetaInfo from "../../../../../../../shared/getMetaInfo"
import Presentation from "./Presentation"
import { storage } from "../../../../../../../config/fbConfig"
import { clientDocuments } from "../../../../../middleware"
import { connect } from "react-redux"

function Container(props) {
  const { clientId, refreshDocuments } = props
  const initState = {
    documentType: "",
    status: "",
    work_doc: {},
    effectiveDate: "",
    effectiveUntilDate: "",
    isUploading: false,
    progress: 0,
  }

  const [state, setState] = useState(initState)

  const handleChange = (event) => {
    const { name, value } = event.target
    if (name === "documentType") {
      setState((prevState) => ({ ...prevState, work_doc: {} }));
    }
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleFile = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      setState(() => ({ ...state, file }))
      fileUpload(file, state.documentType)
    }
  }

  const uploadToStorage = (file, filePath, fileName) => {
    return new Promise((resolve, reject) => {
      if (file.size > 10000000) {
        reject("invalid size")
        alert("File size should be less than 10MB")
      } else {
        const storageRef = storage.ref(
          `Clients/Documents/${clientId}/${fileName}.${file.name.split(".")[1]}`
        )
        const metadata = {
          customMetadata: {
            documentType: state.documentType,
            work_doc_name: `${fileName}`,
            effectiveDate: state.effectiveDate,
            effectiveUntilDate: state.effectiveUntilDate,
          },
        }
        storageRef.put(file, metadata).on(
          "state_changed",
          (snapshot) => {
            const progress1 = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            console.log(progress1)
          },
          (error) => {
            console.log("upload failed")
            reject(error)
          },
          () => {
            storageRef.getDownloadURL().then((url) => {
              console.log("uploaded")
              resolve(url)
            })
          }
        )
      }
    })
  }

  const fileUpload = async (file, type) => {
    const clientName = new MetaInfo().clientIdToName(clientId)
    const otherFileName = [clientName, "_", type, "_", new Date().toISOString()].join("")
    const fileName = otherFileName
    const filePath = `Clients/Documents/${clientId}/${fileName}`
    setState((prevState) => ({ ...prevState, isUploading: true }))
    uploadToStorage(file, filePath, fileName)
      .then((url) => {
        console.log(url)
        return setState((prevState) => ({
          ...prevState,
          work_doc: {
            ...prevState.work_doc,
            name: fileName,
            url: url,
          },
          isUploading: false,
        }))
      })
      .catch((err) => {
        console.error(err)
        setState((prevState) => ({ ...prevState, isUploading: false }))
      })
  }

  const handleDateChange = (key, value) => {
    if (!isNaN(Date.parse(value))) {
      const date = new Date(value)
      date.setHours(0, 0, 0, 0)
      setState((prevState) => ({
        ...prevState,
        [key]: date.toISOString(),
      }))
    }
  }

  const clearState = () => {
    setState(() => ({ ...initState }))
  }

  const handleSubmit = () => {
    refreshDocuments()
  }

  return (
    <div>
      <Presentation
        {...state}
        handleChange={handleChange}
        handleFile={handleFile}
        handleDateChange={handleDateChange}
        handleSubmit={handleSubmit}
        clearState={clearState}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    prop: state.prop,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { clientId } = ownProps
  return {
    refreshDocuments: () => {
      dispatch(clientDocuments(clientId, true))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
