import React, { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import LetterRequestTable from "../LetterRequestTable";
import LetterrequestsDashboard from "../LetterRequestDashboard/index";

export default function TabsView(props) {
  const { myReq } = props;
  const [tabValue, setTabValue] = useState("0");
  return (
    <Tab.Container activeKey={tabValue} onSelect={setTabValue}>
      <Nav as="ul" justify variant="pills" className="">
        <Nav.Item as="li" key="0">
          <Nav.Link className="cursor-pointer" eventKey={"0"}>
            <span className="d-sm-block">Dashboard</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" key="1">
          <Nav.Link className="cursor-pointer" eventKey={"1"}>
            <span className="d-sm-block">All</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" key="2">
          <Nav.Link className="cursor-pointer" eventKey={"2"}>
            <span className="d-sm-block">Pending</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" key="3">
          <Nav.Link className="cursor-pointer" eventKey={"3"}>
            <span className="d-sm-block">Rejected</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" key="4">
          <Nav.Link className="cursor-pointer" eventKey={"4"}>
            <span className="d-sm-block">Issued</span>
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content className="text-muted">
        <Tab.Pane eventKey={"0"}>
          <LetterrequestsDashboard setTabValue={setTabValue} {...props} />
        </Tab.Pane>
        <Tab.Pane eventKey={"1"}>
          <LetterRequestTable condition={0} myReq={myReq} {...props} />
        </Tab.Pane>
        <Tab.Pane eventKey={"2"}>
          <LetterRequestTable condition={1} myReq={myReq} {...props} />
        </Tab.Pane>
        <Tab.Pane eventKey={"3"}>
          <LetterRequestTable condition={2} myReq={myReq} {...props} />
        </Tab.Pane>
        <Tab.Pane eventKey={"4"}>
          <LetterRequestTable condition={3} myReq={myReq} {...props} />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
}
