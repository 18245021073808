import React, { useState } from "react"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import Presentation from "./Presentation"
import { compose } from "redux"
import { connect } from "react-redux"
import { enableEmployee, suspendEmployee } from "../../middleware"
import { loadProfileTemplate } from "../../middleware/dynamicProfileRender"

function Container(props) {
  const [state, setState] = useState({
    processing: false,
  })
  console.log(props)
  const { access_modules, loginProfile, loggedInUser, _load_profile_temploate } = props
  const onResponse = () => {
    setState({
      ...state,
      processing: false,
    })
  }

  const onPromote = () => {
    // const dispatch = context[1]
    setState({
      ...state,
      processing: true,
    })
    // dispatch({
    //   type: PROMOTE_EMPLOYEE,
    //   payload: props.profile.useremail,
    //   onResponse: onResponse,
    // })
  }

  const onDemote = () => {
    // const dispatch = context[1]
    setState({
      ...state,
      processing: true,
    })
    // dispatch({
    //   type: DEMOTE_EMPLOYEE,
    //   payload: props.profile.useremail,
    //   onResponse: onResponse,
    // })
  }

  const onSuspend = async (payload) => {
    // const dispatch = context[1]
    const { suspendEmployee, profile } = props
    setState({
      ...state,
      processing: true,
    })
    await suspendEmployee(payload)
    _load_profile_temploate()
    onResponse()
    // dispatch({
    //   type: SUSPEND_EMPLOYEE,
    //   payload: props.profile.useremail,
    //   
    // })
  }

  const onEnable = (reason, dateOfEnable) => {
    // const dispatch = context[1]
    const { enableEmployee, profile } = props
    setState({
      ...state,
      processing: true,
    })
    let payload = {
      employeeID: profile.uid,
      reason:reason,
      enabledDate : dateOfEnable
    }
    enableEmployee(payload)
    onResponse()

    // dispatch({
    //   type: ENABLE_EMPLOYEE,
    //   payload: props.profile.useremail,
    //   onResponse: onResponse,
    // })
  }

  return (
    <div>
      <Presentation
        access_modules={access_modules}
        loginProfile={loginProfile}
        loggedInUser={loggedInUser}
        {...state}
        id={props.id}
        onPromote={onPromote}
        onDemote={onDemote}
        onSuspend={onSuspend}
        onEnable={onEnable}
        profile={props.profile}
      />
    </div>
  )
}
const mapStateToProps = (state, ownProps) => {
  return {
    access_modules: state.employee.employeeModules.accessModules,
    loginProfile: state.firebase.profile,
    loggedInUser: state.firebase.auth.uid,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    enableEmployee: (payload) => {
      dispatch(enableEmployee(payload))
    },
    suspendEmployee: (payload) => {
      dispatch(suspendEmployee(payload))
    },
    _load_profile_temploate: () => dispatch(loadProfileTemplate())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
