import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Popover, Avatar, Grid } from "@material-ui/core";
import MetaInfo from "../getMetaInfo";
import { Link } from "react-router-dom";
import { FaAddressCard, FaDollarSign, FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
    width: "450px",
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  row: {
    paddingTop: "5px",
  },
}));

function EmployeeHoverCard({ employeeID }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const metaObj = new MetaInfo();
  const employees = metaObj.names

  const employeeDetails = employees[employeeID] ?? {};
  const isLoading =
    employeeID in employees ? employees[employeeID].isLoading : true;
  return (
    <div>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Link style={{ align: "left" }} to={"/console/employees/" + employeeID}>
          <table style={{ width: 250 }}>
            <tr>
              <td>
                <Avatar
                  style={{ width: 24, height: 24, backgroundColor: "red" }}
                  src={employeeDetails.photoURL}
                />
              </td>
              <td>&nbsp;</td>
              <td>
                {employeeDetails?.name}
              </td>
            </tr>
          </table>
        </Link>
      </Typography>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorPosition={{ top: 200, left: 780 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>
          <Grid container>
            <Grid item xs={3}>
              <div>
                <Avatar
                  alt="Remy Sharp"
                  src={employeeDetails.photoURL}
                  className={classes.large}
                />
              </div>
            </Grid>
            <Grid item xs={9}>
              <div>
                <h4>
                {employeeDetails?.name}
                </h4>
                <div className={classes.row}>
                  <span style={{ color: "#3f51b5" }}>
                    <FaAddressCard size={24} />
                    &nbsp;&nbsp;{employeeID}
                  </span>
                </div>
                <div className={classes.row}>
                  {employeeDetails?.payrollid ? (
                    <span>
                      <FaDollarSign style={{ color: "#3f51b5" }} size={18} />
                      &nbsp;&nbsp;&nbsp;{employeeDetails?.payrollid}
                    </span>
                  ) : (
                    <span
                      style={{
                        background: "red",
                        padding: "5px",
                        color: "white",
                      }}
                    >
                      <FaDollarSign size={18} />
                      &nbsp;&nbsp;<b>Please update Payroll ID</b>
                    </span>
                  )}
                </div>

                <div className={classes.row}>
                  <span>
                    <FaPhoneAlt style={{ color: "#3f51b5" }} size={24} />
                    &nbsp;&nbsp;{employeeDetails?.phonenumber}
                  </span>
                </div>
                <div className={classes.row}>
                  <span>
                    <MdEmail style={{ color: "#3f51b5" }} size={24} />
                    &nbsp;&nbsp;{employeeDetails?.email}
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Typography>
      </Popover>
    </div>
  );
}

export default EmployeeHoverCard;
