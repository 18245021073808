import { initialState } from "./invoiceLocationState";
import ACTION from "../actions/actionTypes";

export const invoiceLocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.GET_INVOICE_LOCATION_REQ:
      return {
        ...state,
        isLoading: true,
      };

    case ACTION.GET_INVOICE_LOCATION_SUCCESS:
      return {
        ...state,
        locations: action.payload,
        isLoading: false,
      };

    case ACTION.GET_INVOICE_LOCATION_FAILURE:
      return {
        ...state,
        isLoading: true,
      };

    default:
      return state;
  }
};
