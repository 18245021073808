import React from "react"
import { connect } from "react-redux"
import Presentation from "./Presentation"
import {
	inviteEmployee,
	deleteExcelContent,
	updateExcelContent,
	inviteBulk
} from "../../../middleware"

function Container(props) {
	const {
		inviteeInfo,
		inviteEmployee,
		deleteExcelContent,
		updateExcelContent,
	} = props

	const updateTableContent = (newData, id, oldData) => {
		updateExcelContent({
			newData,
			id,
			oldData,
		})
	}

	const deleteTableContent = (oldData) => {
		deleteExcelContent(oldData)
	}

	const startInvite = () => {
		// inviteeInfo.forEach((invitee) => {
		// 	const payload = {
		// 		firstname: invitee.firstname,
		// 		lastname: invitee.lastname,
		// 		phonenumber: invitee.phonenumber,
		// 		email: invitee.email,
		// 		dob: invitee.dob,
		// 		branch: invitee.branch,
		// 	}
		// 	inviteEmployee(payload)
		// })
		inviteBulk()
	}

	return (
		<div>
			<Presentation
				{...props}
				updateTableContent={updateTableContent}
				deleteTableContent={deleteTableContent}
				startInvite={startInvite}
			/>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => {
	return {
		excel_invite: state.employee.employeeInvite.excel_invite,
		inviteeInfo: state.firestore.ordered.inviteeInfo,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		inviteEmployee: (payload) => {
			dispatch(inviteEmployee(payload))
		},
		deleteExcelContent: (payload) => {
			dispatch(deleteExcelContent(payload))
		},
		updateExcelContent: (payload) => {
			dispatch(updateExcelContent(payload))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
