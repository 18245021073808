import React from "react"
import ViewArticle from "../Services/Wiki/components/ViewArticle"
import { Breadcrumbs } from "@material-ui/core"
import { Link } from "react-router-dom"

function RevertChangesPage(props) {
  const { articleId, historyId } = props.match.params
  return (
    <div>
      <div>
        <ViewArticle {...props.match.params} isHistory={true} />
      </div>
    </div>
  )
}

export default RevertChangesPage
