import React from "react"
import { Grid, TextField, Button } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"

function Presentation(props) {
  const {
    handleEmployees,
    approvalBy,
    approvalManagers,
    handleSubmit,
    fillableSections,
  } = props
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{ minWidth: 240, maxWidth: "100%" }}>
              <Autocomplete
                multiple
                options={approvalManagers}
                getOptionLabel={(option) => option.name}
                value={approvalManagers.filter((employee) => {
                  return approvalBy.includes(employee.companyID)
                })}
                autoHighlight
                filterSelectedOptions
                onChange={(_, v) =>
                  handleEmployees(v.map((item) => item.companyID))
                }
                renderInput={(params) => (
                  <TextField fullWidth {...params} label="Add employees *" />
                )}
              />
            </div>
          </Grid>
        </Grid>
        <br />
        <Button
          color={
            fillableSections.includes("expense_details")
              ? "primary"
              : "secondary"
          }
          variant="contained"
          type="submit"
          disabled={approvalBy.length ? false : true}
        >
          {fillableSections.includes("expense_details") ? "Done" : "Update"}
        </Button>
      </form>
    </div>
  )
}

export default Presentation
