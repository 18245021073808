import React from 'react'

function Presentation({ invoiceID }) {
  return (
    <div>
      <h3>Invoice {invoiceID} rejection</h3>
    </div>
  )
}

export default Presentation
