import React from "react";
import FlairTable, {
  convertMaterialToAgGridColumns,
} from "../../../../shared/FlairTable";
import { useQuery } from "@tanstack/react-query";
import { getTimesheetsReport } from "../../../../api/services/default/timesheets";
import { getDaysInMonth, getMonth, getYear, isWithinInterval } from "date-fns";
import validate from "../../../../shared/validation";
import { CalendarFormatter } from "../../../../shared/CalendarFormatter";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import DatePickerField from "../../../../shared/components/BootStrapFormFields/DatePickerField";
import { TimesheetHover } from "../../../Payrolls/components/TimesheetHover";
import { Link } from "react-router-dom";
import "./styles.css";

export default function TimesheetOverview() {
  const [reportingDate, setReportingDate] = React.useState();
  const [dates, setDates] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const tableRef = React.useRef();

  const { isFetching: isLoading, refetch } = useQuery({
    queryKey: ["getTimesheetsReport", reportingDate],
    queryFn: () =>
	getTimesheetsReport(reportingDate?.getMonth(), reportingDate?.getFullYear()),
    enabled: !isNaN(Date.parse(reportingDate)),
    onSuccess(data) {
      const _rows = data?.map((item) => {
        const timesheetsDates = dates
          .map((date, index) => {
            const isDateInPlacementRange =
              item.startDate && item.endDate
                ? isWithinInterval(new Date(date), {
                    start: new Date(item.startDate),
                    end: new Date(item.endDate),
                  })
                : false;
            return {
              [`DateOfMonth${index}`]:
                item.timesheetsDataset[date]?.totalHours ??
                (isDateInPlacementRange ? "0" : "--"),
            };
          })
          .reduce(function (acc, x) {
            for (const key in x) acc[key] = x[key];
            return acc;
          }, {});

        return {
          employeeName: item.employeeName,
          employeeID: item.employeeID,
          dateOfJoining: item.dateOfJoining,
          dateOfSuspension: item.dateOfSuspension,
          clientName: item.clientName,
          branch: item.branch,
          placementID: item.placementID,
          startDate: item.startDate,
          endDate: item.endDate,
          employeeCategory: item.employeeCategory,
          hrs: (() => {
            if (item.totalMissingDays > 0) {
              return `Partial`;
            }
            return "Full";
          })(),
          missingDays: item.totalMissingDays,
          submittedHours: item.submittedHours.toFixed(2),
          approvedHours: item.approvedHours.toFixed(2),
          totalHours: item.totalEntireHours.toFixed(2),
          timesheetsDataset: item.timesheetsDataset,
          lastSubmission: item.submissionData[0]?.submissionDate ?? "",
          lastApproval: item.submissionData[0]?.approvalDate ?? "",
          submissionData: item.submissionData ?? [],
          defaultPlacementId: item.defaultPlacementId,
          ...timesheetsDates,
          reportingDate: reportingDate,
          totalDeductions: item.totalDeductions
            ? validate.currencyFormatterUs(item.totalDeductions)
            : "--",
          deductionsData: item.deductionsData ?? [],
          billingRate: item.billingRate
						.map(validate.currencyFormatterUs)
						.join(", "),
        };
      });
      setRows(_rows);
    },
  });

  const excelStyles = React.useMemo(() => {
		return [
			{
				id: "approvedinvoicedmailed",
				interior: {
					color: "#006400",
					pattern: "Solid",
				},
			},
      {
				id: "approvedinvoicednotmailed",
				interior: {
					color: "#CBC3E3",
					pattern: "Solid",
				},
			},
      {
				id: "approvednotinvoiced",
				interior: {
					color: "#ebe654",
					pattern: "Solid",
				},
			},
			{
				id: "rejected",
				interior: {
					color: "#ff0000",
					pattern: "Solid",
				},
			},
			{
				id: "pending",
				interior: {
					color: "#ffa500",
					pattern: "Solid",
				},
			},
			{
				id: "no-placement",
				interior: {
					color: "#dbdbd9",
					pattern: "Solid",
				},
			}
		]
	}, []);

  const datesCellClassRules = React.useMemo(() => {
		const getTimesheetsStatus = (params) => {
			const row = params.data;
			const date = params.colDef.headerName;
			const isDateInPlacementRange =
				row.startDate && row.endDate
					? isWithinInterval(new Date(date), {
							start: new Date(row.startDate),
							end: new Date(row.endDate),
						})
					: false;
			return {
        isDateInPlacementRange,
        status: row.timesheetsDataset[date]?.status,
        isInvoiced: row.timesheetsDataset[date]?.isInvoiced,
        isMailedToClient: row.timesheetsDataset[date]?.isMailedToClient,
      };
		};

		return {
			"approvedinvoicedmailed": (params) => {
				const { isDateInPlacementRange, status, isInvoiced, isMailedToClient } = getTimesheetsStatus(params);
				console.log(isDateInPlacementRange, status);
				return isDateInPlacementRange && status === "approved" && isInvoiced && isMailedToClient;
			},
      "approvedinvoicednotmailed": (params) => {
				const { isDateInPlacementRange, status, isInvoiced, isMailedToClient } = getTimesheetsStatus(params);
				console.log(isDateInPlacementRange, status);
				return isDateInPlacementRange && status === "approved" && isInvoiced && !isMailedToClient;
			},
      "approvednotinvoiced": (params) => {
				const { isDateInPlacementRange, status, isInvoiced } = getTimesheetsStatus(params);
				console.log(isDateInPlacementRange, status);
				return isDateInPlacementRange && status === "approved" && !isInvoiced;
			},
			"rejected": (params) => {
				const { isDateInPlacementRange, status} = getTimesheetsStatus(params);
				return isDateInPlacementRange && status === "rejected";
			},
			"pending": (params) => {
				const { isDateInPlacementRange, status } = getTimesheetsStatus(params);
				return isDateInPlacementRange && status === "pending";
			},
			"no-placement": (params) => {
				const { isDateInPlacementRange } = getTimesheetsStatus(params);
				return !isDateInPlacementRange
			},
		}
	}, []);

  const headingColors = [
    { label: "Approved & Invoiced & Mailed", color: "#006400" },
    { label: "Approved & Invoiced & Not Mailed", color: "#CBC3E3" },
    { label: "Approved & Not Invoiced", color: "#ebe654" },
    { label: "Rejected", color: "#ff0000" },
    { label: "Pending", color: "#ffa500" },
    { label: "Range exceeded", color: "#dbdbd9"}
  ];

  const columns = [
    {
      title: "Employee ID",
      field: "employeeID",
      render: (row) => (
        <Link to={`/console/employees/${row.employeeID}`}>
          {row.employeeID}
        </Link>
      ),
    },
    { title: "Employee Name", field: "employeeName" },
    { title: "Date of joining", field: "dateOfJoining" },
    { title: "Client", field: "clientName" },
    { title: "Branch", field: "branch" },
    {
      title: "Job Code",
      field: "placementID",
      render: (row) => (
        <Link to={`/console/placements/${row.employeeID}/${row.placementID}`}>
          {row.placementID}
        </Link>
      ),
    },
    { title: "Job Start Date", field: "startDate" },
    { title: "Job End Date", field: "endDate" },
    { title: "Employee Category", field: "employeeCategory" },
    { title: "Bill Rate", field:"billingRate"},
    { title: "Hours", field: "hrs" },
    { title: "Missing Days", field: "missingDays" },
    {
      title: "Last submission date",
      field: "lastSubmission",
      cellClass: "custom-chip c-bg-sky text-dark",
      render: (row) => {
        return (
          <TimesheetHover
            data={row.submissionData}
            columnNameToDisplay="submissionDate"
          />
        );
      },
    },
    {
      title: "Last approval date",
      field: "lastApproval",
      cellClass: "custom-chip c-bg-sky text-dark",
      render: (row) => {
        return (
          <TimesheetHover
            data={row.submissionData}
            columnNameToDisplay="approvedAt"
          />
        );
      },
    },
    { title: "Submitted Hours", field: "submittedHours" },
    { title: "Approved Hours", field: "approvedHours" },
    { title: "Total Hours", field: "totalHours" },
  ];

  const getRowHeight = React.useCallback((params) => {
    const { data } = params;
    let maxHeight = 35;
    const { workAuthData, employeeStatusData } = data;
    const workAuthDataLength = workAuthData?.length ?? 0;
    const employeeStatusDataLength = employeeStatusData?.length ?? 0;

    if (workAuthDataLength > 1) {
      const height = 35 * workAuthDataLength;
      maxHeight = height > maxHeight ? height : maxHeight;
    }

    if (employeeStatusDataLength > 1) {
      const height = 35 * employeeStatusDataLength;
      maxHeight = height > maxHeight ? height : maxHeight;
    }

    return maxHeight;
  }, []);

  const handleGenerateReport = async (values, actions) => {
    actions.setSubmitting(true);
    const { date } = values;
    const rDate = new Date(date);
    if (!date || isNaN(Date.parse(new Date(rDate)))) return;
    const month = getMonth(rDate);
    const year = getYear(rDate);
    const days = getDaysInMonth(new Date(year, month));
    const _dates = [];

    for (let i = 1; i <= days; i++) {
      const formDateFormat = CalendarFormatter.standardDateFormat(
        new Date(year, month, i)
      );
      _dates.push(formDateFormat);
    }

    setDates(_dates);
    const prevColumns = tableRef.current?.getColumnDefs();
    const updatedColumns = prevColumns.filter(
      (item) => !item.field.startsWith("DateOfMonth")
    );
    const newColumns = [
      ...updatedColumns,
      ...convertMaterialToAgGridColumns(
        _dates.map((date, index) => {
          return {
            title: date,
            field: `DateOfMonth${index}`,
            headerClass:
              new Date(date).getDay() === 0 || new Date(date).getDay() === 6
                ? "bg-blue"
                : undefined,
            cellClassRules:datesCellClassRules
          };
        })
      ),
    ];
    tableRef.current?.setColumnDefs(newColumns);
    setReportingDate(rDate);
    await refetch();
    actions.setSubmitting(false);
  };

  return (
    <Container fluid className="p-0 m-0 mb-3">
      <Row>
        <Col>
          <Formik
            initialValues={{
              date: reportingDate,
            }}
            onSubmit={handleGenerateReport}
          >
            {({ values, isSubmitting }) => (
              <Form className="d-flex gap-3 align-items-center justify-content-end">
                <Field
                  name="date"
                  label="Reporting Date"
                  component={DatePickerField}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                />
                <Button type="submit" disabled={isSubmitting || !values.date}>
                  Generate Report
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>

      <Row className="mt-2 mb-2">
        {headingColors.map(({ label, color }) => (
          <Col key={label} xs={6} md={4} lg={2}>
            <div className="d-flex align-items-center">
              <div
                style={{
                  backgroundColor: color,
                  width: "15px",
                  height: "15px",
                  borderRadius: "4px",
                  marginRight: "8px",
                }}
              />
              <span style={{ fontWeight: "bold", color: "#000000" }}>{label}</span>
            </div>
          </Col>
        ))}
      </Row>
    
      <FlairTable
        columns={columns}
        data={rows}
        tableRef={tableRef}
        isLoading={reportingDate ? isLoading : false}
        getRowHeight={getRowHeight}
        excelStyles={excelStyles}
      />
    </Container>
  );
}
