import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom"
import { IconButton, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";

function EditInvoice({ invoiceID }) {
  const history = useHistory();

  const goToEditPage = () => {
    return history.push(`/console/invoiceslist/edit?invoiceID=${encodeURIComponent(invoiceID)}`)
  }
  
  return (
    <div>
      <Tooltip title="Edit">
        <Link style={{ color: "red", paddingLeft: "5px", paddingRight: "5px"}} onClick={goToEditPage}>
          <EditIcon size={20} /></Link>
      </Tooltip>
    </div>
  );
}

export default EditInvoice;
