export default {
  invitationTokenEmail: {
    data: {},
    isLoading: true,
    error: "",
    listener: () => {},
    noOfLoadings: 0,
  },
  invitationTokenEmpInfo: {
    data: {},
    isLoading: true,
    error: "",
    listener: () => {},
    noOfLoadings: 0,
  },
}
