import React, { useContext } from "react";
import { Context } from "../component_state/context";
import { Autocomplete } from "@material-ui/lab";
import { Grid, Button, Paper, Icon } from "@material-ui/core";
import SelectPlacement from "../SelectPlacement";
import Document from "../Document";
import RangeSelector from "../RangeSelector";
import EntryTable from "../EntryTable";
import SubmitButton from "../ActionButtons/SubmitButton";
import UpdateButton from "../ActionButtons/UpdateButton";
import ApproveTimesheet from "../ActionButtons/Approve";
import RejectTimesheet from "../ActionButtons/Reject";
import TimesheetMetaData from "../TimesheetMetaData";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import TimesheetCalendar from "../TimesheetCalendar";
import StatusReport from '../StatusReport'
import parser from "html-react-parser"

function Presentation(props) {
  const [state, handle] = useContext(Context);
  const { isInvoicePage, loggedInEmployee,modules } = props;
  return (
    <div>
      <Paper className="p-2">
        <Grid container spacing={2}>
          {!isInvoicePage ? (
            <>
              <Grid item xs={12} sm={4}>
                <SelectPlacement state={state} {...props} />
                <Document state={state} type={props.type} />
                {state.type !== "new" && state.type !== "defaulter" ? <TimesheetMetaData /> : null}
              </Grid>
              <Grid item xs={12} sm={7}>
                <TimesheetCalendar />
              </Grid>
              {/* <Grid item xs={12} sm={7}>
                <RangeSelector state={state} {...props} />
              </Grid> */}
              <Grid item xs={12} sm={1}>
                <Link
                  to={`/console/timesheets/edit?employeeID=${props.employeeID}&placementID=${props.placementID}&timesheetID=${props.timesheetID}`}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    id="edit-timesheet-button"
                    hidden={state.type !== "view"}
                    endIcon={<Icon style={{ size: "12px" }}>edit</Icon>}
                  >
                    Edit
                  </Button>
                </Link>
              </Grid>
            </>
          ) : null}
          {
            state.type !== "view" && loggedInEmployee === state.employeeID ?
              <Grid item xs={12} sm={12}>
                <StatusReport />
              </Grid>
            :
            <></>
          }
          {
            state.type === "view" || (state.type === 'edit' && loggedInEmployee !== state.employeeID) ?
              <Grid item xs={12} sm={12}>
                <p>{parser(state?.statusReport ? ("<h4>Status Report :</h4>" + state?.statusReport) : "")}</p>
              </Grid>
            :
            <></>
          }
          <Grid item xs={12} sm={12}>
            <EntryTable />
          </Grid>
          
          <Grid item xs={12} sm={12}>
            {state.type === "view" ? (
              <div className="text-center w-100 d-flex justify-content-between">
                <div />
                <div className="d-flex">
                  <ApproveTimesheet />
                  &nbsp;&nbsp;
                  <RejectTimesheet />
                </div>
                <div />
              </div>
            ) : isInvoicePage && loggedInEmployee === state.employeeID ? (
              <div className="h6">
                Note: One cannot edit his/her own timesheet after approved
              </div>
            ) : (
              <div>
                <SubmitButton />
                <UpdateButton isInvoicePage={isInvoicePage} />
              </div>
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default Presentation;
