import React from 'react'
import {
  Table,
  Paper,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
} from "@material-ui/core"
import { withStyles, createStyles } from "@material-ui/core/styles"
import validate from "../../../../shared/validation"
import { useNewInvoiceContext } from './context'
import { Add } from '@material-ui/icons'
import AddAdditionalItems from './AddAdditionalItems'
import { RiDeleteBin2Fill } from 'react-icons/ri'


const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell)

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow)

function AdditionalItemsTable(props) {
  const { state, stateSetters } = useNewInvoiceContext()
  const { hideActions = false } = props
  const handleDelete = (item) => {
    let newArray = state.additionalItems.filter(obj => obj?.serviceSummary !== item?.serviceSummary);
    stateSetters.setAdditionalItems(newArray)
  }

  if (state?.additionalItems?.length < 1) return <span></span>
    return (
      <div style={{marginTop: "1rem"}}>
        <>
          {/* <h2>
            <u>Additional Items</u>
          </h2> */}
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Service Summary</StyledTableCell>
                  <StyledTableCell>Attachment</StyledTableCell>
                  <StyledTableCell align="right">Amount</StyledTableCell>
                  { !hideActions && <StyledTableCell align='right'>Actions</StyledTableCell> }
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {state.additionalItems?.map((item) => {
                  return (
                    <StyledTableRow>
                      <StyledTableCell>
                        {item.serviceSummary}
                      </StyledTableCell>
                      <StyledTableCell>
                        {item?.attachment !== "" && Object.keys(item).includes("attachment") ? (
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={item?.attachment}
                          >
                            {"Link"}
                          </a>
                        ) : (
                          "---"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {validate.currencyFormatterUs(item.amount)}
                      </StyledTableCell>
                      { !hideActions &&
                      <StyledTableCell style={{display:"flex"}} align='right'>
                        <AddAdditionalItems type = "edit" item={item}/>
                        <IconButton size='small' onClick={() => handleDelete(item)}><RiDeleteBin2Fill /></IconButton>
                      </StyledTableCell>}
                    </StyledTableRow>
                  )
                })}
                {
                  <TableRow>
                    <TableCell colSpan={!hideActions ? 2 : 1} />
                    <TableCell align="right">
                      <h4>
                        {"Subtotal"}
                      </h4>
                    </TableCell>
                    <TableCell align="center">
                      <h4>
                      {validate.currencyFormatterUs(state.additionalItems?.reduce(
                          (total, item) => total + Number(item.amount),
                          0
                        ))}  
                      </h4>
                    </TableCell>
                  </TableRow>
                }
                
              </TableBody>
            </Table>
          </TableContainer>
        </>
      </div>
    )
}

export default AdditionalItemsTable