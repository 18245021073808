import React, { useState, useEffect } from "react"
import Presentation from "./Presentation"
import { connect } from "react-redux"
import { projectCreation } from "../../middleware"
import { compose } from "redux"
import { firestoreConnect, isLoaded } from "react-redux-firebase"

function Container(props) {
  const { projects_id_tracker } = props
  const [state, setState] = useState({
    name: "",
    status: "",
    startdate: "",
    enddate: "",
    projectId: "",
    employees: [],
    isCreated: false,
    useLabels: false,
    useTimeline: false,
  })
  const [projectAssignee, setAssignee] = React.useState([]);

  useEffect(() => {
    let currID = projects_id_tracker ? 
                        projects_id_tracker[0]?.projectID ? 
                            projects_id_tracker[0]?.projectID : "1"
                        : "1"
    if(isLoaded(projects_id_tracker)){
      setState({
        ...state,
        projectId:"PROJ" + "-" + currID.toString(),
      })
    }
  }, [projects_id_tracker])
  
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const handleCheck = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
  }

  const handleDateChange = (key, value) => {
    if (value === null)
      setState({
        ...state,
        [key]: "",
      })
    else if (!isNaN(Date.parse(value)))
      setState({
        ...state,
        [key]: value.toISOString(),
      })
  }
  const clearValues = () => {
    setState({
      ...state,
      name: "",
      status: "",
      startdate: "",
      enddate: "",
      projectId: "",
      useLabels: false,
      useTimeline: false,
      employees: [],
      isCreated: false,
    })
  }
  const handleEmployees = (value) => {
    setState({
      ...state,
      employees: value,
    })
  }
  const handleCreate = (e) => {
    e.preventDefault()
    const { projectCreation } = props
    const {
      name,
      status,
      startdate,
      enddate,
      employees,
      projectId,
      useLabels,
      useTimeline,
    } = state
    let projectInfo = {
      title: name,
      status,
      startdate: new Date(startdate).setHours(0, 0, 0, 0),
      enddate: new Date(enddate).setHours(0, 0, 0, 0),
      cid: projectId,
      useLabels,
      useTimeline,
      Users: employees ? employees.map((e) => e.uid) : [],
    }
    projectCreation(projectInfo)
    clearValues()
  }
  const newProject = {
    name: state.name,
    status: state.status,
    startdate: state.startdate,
    enddate: state.enddate,
    useLabels: state.useLabels,
    useTimeline: state.useTimeline,
    projectId: state.projectId,
  }
  let errCount = 0
  Object.entries(newProject).forEach(([key, value]) => {
    if (
      (key === "startdate" && isNaN(Date.parse(value))) ||
      (key === "enddate" && isNaN(Date.parse(value)))
    )
      errCount++
    else if (
      (key === "projectId" && value.length < 3) ||
      (key === "projectId" && value.length > 10)
    )
      errCount++
    else if (typeof value === "string" && value.trim() === "") errCount++
  })

  return (
    <div>
      <Presentation
        {...props}
        newProject={newProject}
        errCount={errCount}
        handleEmployees={handleEmployees}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleDateChange={handleDateChange}
        clearValues={clearValues}
        handleCreate={handleCreate}
        projectAssignee={projectAssignee}
        employees={state.employees}
        projectId={state.projectId}
      />
    </div>
  )
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    projectCreation: (payload) => {
      dispatch(projectCreation(payload))
    },
  }
}

const mapStateToProps = (state) => {
  return {
    projects_id_tracker: state.firestore.ordered.projects_id_tracker,
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "ID_TRACKER",
        doc: "projects",
        storeAs: "projects_id_tracker",
      },
    ];
  })
)(Container);