import React, { useState } from 'react'
import TabPanel from '../../../../../shared/tabPanel'
import { Paper, Tabs, Tab } from '@material-ui/core'
import General from './general'
import InDetail from './inDetail'
import TagsTable from './tags'

function ArAgingTabs() {
	const [tabValue, setTabValue] = useState(0)

	const setTabChangeValue = (event, newValue) => {
		setTabValue(newValue)
	}

	return (
		<React.Fragment>
			<Paper square>
				<Tabs
					value={tabValue}
					indicatorColor='primary'
					textColor='primary'
					onChange={setTabChangeValue}
					aria-label='disabled tabs example'
				>
					<Tab label='General View' />
					<Tab label='InDetail View' />
					<Tab label='Tags'/>
				</Tabs>
			</Paper>
			<TabPanel value={tabValue} index={0}>
				<General />
			</TabPanel>
			<TabPanel value={tabValue} index={1}>
				<InDetail />
			</TabPanel>
			<TabPanel value={tabValue} index={2}>
				<TagsTable/>
			</TabPanel>
		</React.Fragment>
	)
}

export default ArAgingTabs
