import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import Presentation from "./Presentation"
import { contactActions } from "../../../../../middleware"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import { compose } from "redux"

function Container(props) {
  const {
    contact,
    btnContent,
    edit,
    clientId,
    contactActions,
    isPlacement,
    hidden = false,
    clients_locations,
    clients,
    isWorkLocation,
    clientList,
    clients_list_locations
  } = props
  const [workLocationClientId,setWorkLocationClientId]= useState('')
  const [state, setState] = useState({
    representativeName: "",
    jobTitle: "",
    contactType: "",
    gender: "",
    email: "",
    mobile: "",
    workPhone: "",
    homePhone: "",
    locationsline1: "",
    locationsline2: "",
    locationscountry: "",
    locationsstate_name: "",
    locationscity: "",
    locationszip: "",
    workPhoneExtension: "",
    location:"",
    contactType:""
  })

  const [address, setAddress] = useState({
    locationsline1:"",
    locationsline2:"",
    locationscity:"",
    locationsstate_name:"",
    locationszip:"",
    locationscountry:""
  });

  useEffect(() => {
    if(isLoaded(clients_locations)){
      const tempState = {}
      const contactLocation = clients_locations.filter((loc) => loc.id === contact?.location)[0] || {}
      Object.entries(contact).forEach(([key, value]) => {
        if (state.hasOwnProperty(key)) {
          tempState[key] = value
        }
        setState((prevState) => ({
          ...prevState,
          ...tempState,
        }))
      })
      Object.entries(contactLocation).forEach(([key, value]) => {
        if (state.hasOwnProperty(key)) {
          tempState[key] = value
        }
        setState((prevState) => ({
          ...prevState,
          ...tempState,
        }))
        setAddress((prevState) => ({
          ...prevState,
          locationsline1:contactLocation?.locationsline1 || "",
          locationsline2:contactLocation?.locationsline2 || "",
          locationscity:contactLocation?.locationscity || "",
          locationsstate_name:contactLocation?.locationsstate_name || "",
          locationszip:contactLocation?.locationszip || "",
          locationscountry:contactLocation?.locationscountry || ""
        }))
      })
    }
  }, [contact])


  const clearState = () => {
    setState({
      representativeName: "",
      jobTitle: "",
      contactType: "",
      gender: "",
      email: "",
      mobile: "",
      workPhone: "",
      homePhone: "",
      locationsline1:"",
      locationsline2:"",
      locationscity:"",
      locationsstate_name:"",
      locationszip:"",
      locationscountry:"",
      workPhoneExtension: "",
    });
    setAddress({
      locationsline1:"",
    locationsline2:"",
    locationscity:"",
    locationsstate_name:"",
    locationszip:"",
    locationscountry:""
    })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {};
    const contact = {
      representativeName: state.representativeName,
      jobTitle: state.jobTitle,
      contactType: state.contactType,
      gender: state.gender,
      email: state.email,
      mobile: state.mobile,
      workPhone: state.workPhone,
      homePhone: state.homePhone,
      location:state.location,
      contactType:state.contactType
    }
    payload["contact"] = contact;
    payload["contactId"] = edit ? props.contact.id : null;
    payload["clientId"] = workLocationClientId ? workLocationClientId :clientId ;
    contactActions(payload);
    clearState();
  };


  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const handleChangeAddress = (value) => {
    setAddress(value)
    setState((prevState) => {
      return {
        ...prevState,
        locationsline1: value.locationsline1,
        locationsline2: value.locationsline2,
        locationscity: value.locationscity,
        locationsstate_name: value.locationsstate_name,
        locationszip: value.locationszip,
        locationscountry: value.locationscountry,
        location:value.id
      }
    })
  }
if(clients && clients_list_locations)
  return (
    <div>
      <Presentation
        btnContent={btnContent}
        clients_list_locations={clients_list_locations}
        edit={edit}
        setWorkLocationClientId={setWorkLocationClientId}
        workLocationClientId={workLocationClientId}
        isWorkLocation={isWorkLocation}
        clientList={clientList}
        hidden={hidden}
        clients={clients}
        isPlacement={isPlacement}
        contact={state}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        clients_locations={clients_locations}
        address={address}
        clearState={clearState}
        handleChangeAddress={handleChangeAddress}
      />
    </div>
  )
return (
  ""
)
}

const mapStateToProps = (state, ownProps) => {
  return {
    clients_locations : state.firestore.ordered.clients_locations,
    clients:state.firestore.ordered.clients,
    clients_list_locations:state.firestore.ordered.clients_list_locations
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    contactActions: (payload) => {
      dispatch(contactActions(payload, ownProps.edit ? "update" : "new"))
    },
  }
}

export default compose(
  connect(mapStateToProps,mapDispatchToProps),
  firestoreConnect((props) => {
    console.log(props)
    if(props.clients === undefined || props.clients_list_locations === undefined)
    {
      if(props.clientId !== ''){
    return [
      {
        collection: "CLIENTS",
        doc: props.clientId,
        subcollections: [
          {
            collection: "CLIENTS_LOCATIONS",
            where: [["isExist", "==", true]],
          },
        ],
        storeAs: "clients_locations",
      },
      {
        collectionGroup: "CLIENTS_LOCATIONS",
        storeAs: "clients_list_locations",
      },
      {
        collection: "CLIENTS",
        storeAs: "clients",
      }
    ]
  }
  return [
    {
    collectionGroup: "CLIENTS_LOCATIONS",
    storeAs: "clients_list_locations",
  },
  {
    collection: "CLIENTS",
    storeAs: "clients",
  }
]
  }
    return []
  })
)(Container)