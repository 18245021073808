import React, { useEffect } from "react";
import { Row, Col, Tab, Card } from "react-bootstrap";
import Usertop from "./ProfileCard/index";
import Navtab from "./navtab";
import Keymatrix from "./dashboard/keymatrix";
import Basicdetails from "./dashboard/basicdetails";
import Payroll from "./dashboard/payroll";
import Invoice from "./dashboard/invoice";
import Deductions from "./dashboard/deductions";
import Contacts from "./dashboard/contacts";
import Documents from "./dashboard/documents";
import Timesheets from "./dashboard/timesheets";
import Personalinfo from "./general/PersonalInfo/index";
import MailingAddress from "./general/MailingAddress/index";
import Sidetabs from "./general/tabs";
import EmergencyContact from "./general/EmergencyContact/index";
import EmploymentHistory from "./general/EmployementHistory/index";
import WorkAuth from "./general/WorkAuthorization/index";
import Skills from "./general/skills/index";
import { useProfileDashboardContext } from "../MenuBar/context";
import DeductionsList from "../../../Deductions/DeductionsList";
import Document from "../../../Documents/components/DocumentsTable";
import TimesheetsList from "../../../Timesheets/components/TimesheetsList/Index";
import InvoicesList from "../../../Invoices/components/ListingInvoices/TabsView";
import ExpensesList from "../../../Expenses/components/ExpensesList/Index";
import Placements from "../../../Placements/components/PlacementsList/EmployeePlacementList";
import { Skeleton } from "@material-ui/lab";
import EducationHistory from "./general/EducationHistory";
import Certifications from "./general/Cerifications";
import Passport from "./general/Passport";
import DashboardDocuments from "./general/Documents";
import EVerification from "./general/Everification";
import BankDetails from "./general/BankDetails";
import I9Form from "./general/I9form";
import Ssn from "./general/ssn";
import W4 from "./general/w4";
import Section401k from "./general/401k";
import SectionEmployeeAudit from "./general/EmployeeAudit";
import { useQuery } from "@tanstack/react-query";
import { getEmployee } from "../../../../api/services/default/employee";
import Soa from "../../../Payrolls/components/Soa";

export function ProfileDashboard(props) {
  const { Id, isPersonal } = props;
  const { services, state } = useProfileDashboardContext();

  const { data: employeeData = {} } = useQuery({
    queryKey: ["getEmployee", Id],
    queryFn: () => getEmployee(Id),
    enabled: !!Id,
  })

  useEffect(() => {
    // services.getEmployeeData(Id);
    services.getInvoiceData(Id);
    services.getDeductionData(Id);
    services.getSuspendedData(Id);
    services.getTimesheetsData(Id);
  }, []);

  useEffect(() => {}, [employeeData]);

  return (
    <>
      <Row>
        <Col>
          <div className="page-title-box">
            <h4 className="page-title">Profile</h4>
            {/* <div className="page-title-right">
              <nav aria-label="breadcrumb">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Profile</li>
                                </ol>
                            </nav>
            </div> */}
          </div>
        </Col>
      </Row>
      {isPersonal ? (
        <>
          <Card>
            <Card.Body>
              {Object.values(employeeData).length ? (
                <Usertop
                  employeeID={Id}
                  employeeProfileData={employeeData}
                  suspendedReasons={state?.suspendedData?.reasons}
                  relievingDate={state?.suspendedData?.latestRelievingDate}
                />
              ) : (
                <Skeleton
                  style={{ padding: 4, marginBottom: 6 }}
                  variant="rounded"
                  height={118}
                />
              )}
            </Card.Body>
          </Card>
          <Tab.Container defaultActiveKey="personalinfo">
            <div className="gen">
              <div className="sidetabs mb-3">
                <Sidetabs />
              </div>
              <Tab.Content className="text-muted pt-0">
                <Tab.Pane eventKey="personalinfo" id="20" key="20"> 
                    <Personalinfo
                      isPersonal={isPersonal}                 
                      employeeID={Id}
                    />
                </Tab.Pane>
                <Tab.Pane eventKey="mailingaddress" id="21" key="21">
                    <MailingAddress
                      employeeID={Id}
                      isPersonal={isPersonal}
                    />
                </Tab.Pane>
                <Tab.Pane eventKey="emergency" id="22" key="22">
                    <EmergencyContact
                      isPersonal={isPersonal}
                      employeeID={Id}
                     
                    />
                </Tab.Pane>
                <Tab.Pane eventKey="employment" id="23" key="23">   
                    <EmploymentHistory
                      employeeID={Id}
                      isPersonal={isPersonal}               
                    />
                 
                </Tab.Pane>
                <Tab.Pane eventKey="workauth" id="24" key="24">               
                    <WorkAuth
                      employeeID={Id}
                      isPersonal={isPersonal}                 
                    />

                </Tab.Pane>
                <Tab.Pane eventKey="education" id="25" key="25">
                  {Object.values(employeeData).length ? (
                    <EducationHistory
                      employeeID={Id}
                      isPersonal={isPersonal}
                      data={employeeData?.educationhistory}
                    />
                  ) : (
                    <Skeleton variant="rounded" height={260} />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="skills" id="26" key="26">
                 
                    <Skills
                      employeeID={Id}
                      isPersonal={isPersonal}
                      data={employeeData?.skills}
                    />
                 
                </Tab.Pane>
                <Tab.Pane eventKey="certification" id="27" key="27">
                 
                    <Certifications
                      employeeID={Id}
                      isPersonal={isPersonal}
                      data={employeeData?.certifications}
                    />
               
                </Tab.Pane>
                <Tab.Pane eventKey="passport" id="28" key="28">
                 
                    <Passport
                      employeeID={Id}
                      isPersonal={isPersonal}
                      data={employeeData?.passport}
                    />
              
                </Tab.Pane>
                <Tab.Pane eventKey="documents" id="29" key="29">
                 
                    <DashboardDocuments
                      employeeID={Id}
                      isPersonal={isPersonal}
                      data={employeeData?.documents}
                    />
                
                </Tab.Pane>
                <Tab.Pane eventKey="i9-form" id="30" key="30">
                 
                    <I9Form
                      employeeID={Id}
                      isPersonal={isPersonal}
                      data={employeeData?.["i-9form"]}
                    />
                 
                </Tab.Pane>
                <Tab.Pane eventKey="e-verification" id="31" key="31">
                  
                    <EVerification
                      employeeID={Id}
                      isPersonal={isPersonal}
                      data={employeeData?.["e-verification"]}
                    />
                
                </Tab.Pane>
                <Tab.Pane eventKey="bankdetails" id="32" key="32">
                  
                    <BankDetails
                      employeeID={Id}
                      isPersonal={isPersonal}
                      data={employeeData?.bankdetails}
                    />
                 
                </Tab.Pane>
                <Tab.Pane eventKey="ssn" id="33" key="33">
                 
                    <Ssn
                      employeeID={Id}
                      isPersonal={isPersonal}
                      data={employeeData?.ssn}
                    />
                
                </Tab.Pane>
                <Tab.Pane eventKey="w4" id="34" key="34">
                 
                    <W4
                      data={employeeData?.w4}
                      isPersonal={isPersonal}
                      employeeID={Id}
                    />
                
                </Tab.Pane>
                <Tab.Pane eventKey="401k" id="35" key="35">
                 
                    <Section401k
                      data={employeeData?.["401k"]}
                      isPersonal={isPersonal}
                      employeeID={Id}
                    />
                  
                </Tab.Pane>
                <Tab.Pane eventKey="employeeaduit" id="36" key="36">
                  <SectionEmployeeAudit />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </>
      ) : (
        <Tab.Container defaultActiveKey="Dashboard">
          <Card>
            <Card.Body>
              {Object.values(employeeData).length ? (
                <Usertop
                  employeeID={Id}
                  employeeProfileData={employeeData}
                  suspendedReasons={state?.suspendedData?.reasons}
                  relievingDate={state?.suspendedData?.latestRelievingDate}
                />
              ) : (
                <Skeleton
                  style={{ padding: 4, marginBottom: 6 }}
                  variant="rounded"
                  height={118}
                />
              )}
              <Navtab />
            </Card.Body>
          </Card>
          <Tab.Content className="text-muted pt-0">
            <Tab.Pane eventKey="Dashboard" id="10" key="10">
              <Row>
                <Col xl={12}>
                  {Object.values(employeeData).length &&
                  Object.keys(state?.timesheetsData) ? (
                    <Keymatrix
                      data={employeeData}
                      essRequests={state?.timesheetsData?.essRequests}
                      placements={state?.timesheetsData?.placements}
                    />
                  ) : (
                    <Skeleton
                      style={{ marginBottom: 8 }}
                      variant="rounded"
                      height={160}
                    />
                  )}
                </Col>
                <Col xl={8}>
                  {Object.values(employeeData).length ? (
                    <Basicdetails data={employeeData} />
                  ) : (
                    <Skeleton variant="rounded" height={260} />
                  )}

                  <Row>
                    <Col xl={4}>
                      <Payroll />
                    </Col>
                    <Col xl={4}>
                      {state?.invoiceData ? (
                        <Invoice data={state?.invoiceData} />
                      ) : (
                        <Skeleton variant="rounded" height={260} />
                      )}
                    </Col>
                    <Col xl={4}>
                      {state?.deductionData ? (
                        <Deductions data={state?.deductionData} />
                      ) : (
                        <Skeleton variant="rounded" height={260} />
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col xl={4}>
                  {Object.values(employeeData).length ? (
                    <Contacts data={employeeData?.emergencycontact} />
                  ) : (
                    <Skeleton variant="rounded" height={160} />
                  )}
                  {Object.values(employeeData).length ? (
                    <Documents data={employeeData} />
                  ) : (
                    <Skeleton variant="rounded" height={160} />
                  )}
                  {Object.keys(state?.timesheetsData) ? (
                    <Timesheets timesheets={state?.timesheetsData} />
                  ) : (
                    <Skeleton variant="rounded" height={160} />
                  )}
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="General" id="11" key="11">
              <Tab.Container defaultActiveKey="personalinfo">
                <div className="gen">
                  <div className="sidetabs mb-3">
                    <Sidetabs />
                  </div>
                  <Tab.Content className="text-muted pt-0">
                    <Tab.Pane eventKey="personalinfo" id="20" key="20">
                      {Object.values(employeeData).length ? (
                        <Personalinfo
                          data={employeeData}
                          isPersonal={isPersonal}
                          employeeID={Id}
                        />
                      ) : (
                        <Skeleton variant="rounded" height={260} />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="mailingaddress" id="21" key="21">
                      {Object.values(employeeData).length ? (
                        <MailingAddress
                          employeeID={Id}
                          isPersonal={isPersonal}
                          data={employeeData?.mailingaddress}
                        />
                      ) : (
                        <Skeleton variant="rounded" height={260} />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="emergency" id="22" key="22">
                      {Object.values(employeeData).length ? (
                        <EmergencyContact
                          employeeID={Id}
                          isPersonal={isPersonal}
                          data={employeeData?.emergencycontact}
                        />
                      ) : (
                        <Skeleton variant="rounded" height={260} />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="employment" id="23" key="23">
                      {Object.values(employeeData).length ? (
                        <EmploymentHistory
                          employeeID={Id}
                          isPersonal={isPersonal}
                          data={employeeData?.employmenthistory}
                        />
                      ) : (
                        <Skeleton variant="rounded" height={260} />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="workauth" id="24" key="24">
                      {Object.values(employeeData).length ? (
                        <WorkAuth
                          employeeID={Id}
                          isPersonal={isPersonal}
                          data={employeeData?.workauth}
                        />
                      ) : (
                        <Skeleton variant="rounded" height={260} />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="education" id="25" key="25">
                      {Object.values(employeeData).length ? (
                        <EducationHistory
                          employeeID={Id}
                          isPersonal={isPersonal}
                          data={employeeData?.educationhistory}
                        />
                      ) : (
                        <Skeleton variant="rounded" height={260} />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="skills" id="26" key="26">
                      {Object.values(employeeData).length ? (
                        <Skills
                          employeeID={Id}
                          isPersonal={isPersonal}
                          data={employeeData?.skills}
                        />
                      ) : (
                        <Skeleton variant="rounded" height={260} />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="certification" id="27" key="27">
                      {Object.values(employeeData).length ? (
                        <Certifications
                          employeeID={Id}
                          isPersonal={isPersonal}
                          data={employeeData?.certifications}
                        />
                      ) : (
                        <Skeleton variant="rounded" height={260} />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="passport" id="28" key="28">
                      {Object.values(employeeData).length ? (
                        <Passport
                          employeeID={Id}
                          isPersonal={isPersonal}
                          data={employeeData?.passport}
                        />
                      ) : (
                        <Skeleton variant="rounded" height={260} />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="documents" id="29" key="29">
                      {Object.values(employeeData).length ? (
                        <DashboardDocuments
                          employeeID={Id}
                          isPersonal={isPersonal}
                          data={employeeData?.documents}
                        />
                      ) : (
                        <Skeleton variant="rounded" height={260} />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="i9-form" id="30" key="30">
                      {Object.values(employeeData).length ? (
                        <I9Form
                          employeeID={Id}
                          isPersonal={isPersonal}
                          data={employeeData?.["i-9form"]}
                        />
                      ) : (
                        <Skeleton variant="rounded" height={260} />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="e-verification" id="31" key="31">
                      {Object.values(employeeData).length ? (
                        <EVerification
                          employeeID={Id}
                          isPersonal={isPersonal}
                          data={employeeData?.["e-verification"]}
                        />
                      ) : (
                        <Skeleton variant="rounded" height={260} />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="bankdetails" id="32" key="32">
                      {Object.values(employeeData).length ? (
                        <BankDetails
                          employeeID={Id}
                          isPersonal={isPersonal}
                          data={employeeData?.bankdetails}
                        />
                      ) : (
                        <Skeleton variant="rounded" height={260} />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="ssn" id="33" key="33">
                      {Object.values(employeeData).length ? (
                        <Ssn
                          employeeID={Id}
                          isPersonal={isPersonal}
                          data={employeeData?.ssn}
                        />
                      ) : (
                        <Skeleton variant="rounded" height={260} />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="w4" id="34" key="34">
                      {Object.values(employeeData).length ? (
                        <W4
                          data={employeeData?.w4}
                          isPersonal={isPersonal}
                          employeeID={Id}
                        />
                      ) : (
                        <Skeleton variant="rounded" height={260} />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="401k" id="35" key="35">
                      {Object.values(employeeData).length ? (
                        <Section401k
                          data={employeeData?.["401k"]}
                          isPersonal={isPersonal}
                          employeeID={Id}
                        />
                      ) : (
                        <Skeleton variant="rounded" height={260} />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="employeeaduit" id="36" key="36">
                      <SectionEmployeeAudit />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </Tab.Pane>
            <Tab.Pane eventKey="Placements" id="12" key="12">
              <Placements listAll={false} id={Id} />
            </Tab.Pane>
            <Tab.Pane eventKey="Timesheets" id="13" key="13">
              <TimesheetsList listAll={false} employeeID={Id} />
            </Tab.Pane>
            <Tab.Pane eventKey="Expenses" id="14" key="14">
              <ExpensesList listAll={false} employeeID={Id} individual={true} />
            </Tab.Pane>
            <Tab.Pane eventKey="Invoices" id="15" key="15">
              <InvoicesList employeeID={Id} isEmployeeInvoices={true} />
            </Tab.Pane>
            <Tab.Pane eventKey="Documents" id="16" key="16">
              <Document employeeID={Id} />
            </Tab.Pane>
            <Tab.Pane eventKey="Deductions" id="17" key="17">
              <DeductionsList listAll={false} employeeId={Id} />
            </Tab.Pane>
            <Tab.Pane eventKey="SOA" id="18" key="18">
              <Soa employeeID={Id} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      )}
    </>
  );
}

export default ProfileDashboard;
