import React, { useEffect, useState } from "react"
import {
  Grid,
  TextField,
  MenuItem,
  Button,
  FormControlLabel,
  FormGroup,
  TextareaAutosize,
  Tabs,
  Tab,
  Tooltip,
  ButtonGroup,
} from "@material-ui/core"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import TabPanel from "../../../../../../shared/tabPanel"
import validate from "../../../../../../shared/validation"
import IOSSwitch from "../../../../../../shared/iosSwitch"
import ChipInput from "material-ui-chip-input"
import { Autocomplete } from "@material-ui/lab"
import { AddCircleOutline as AddCircleOutlineIcon } from "@material-ui/icons"
import EditContact from "../../../../../Clients/components/ViewClient/clientComponents/Contacts/EditContact"

function Presentation(props) {
  const {
    value,
    frequency,
    OT,
    POnumber,
    //pointOfContactMailId,
    attachFlairExpense,
    attachFlairTimesheets,
    bcc,
    billingAddress,
    cc,
    frequencyStartDate,
    //pointOfContactName,
    //pointOfContactPhoneNo,
    to,
    handleDelete,
    handleAdd,
    fillableSections,
    handleTab,
    handleChange,
    handleCheck,
    handleDateChange,
    helperText,
    index,
    handleSubmit,
    contacts = [],
    handleKeyValuePair,
    clientId,
    clientList
  } = props
  const handleOT = (event) => {
    if (!event.target.checked) {
      var r = window.confirm(
        "Are you sure?\nOT Payrate will be disabled and OT billing will not be included in the invoice"
      )
      if (r === true) {
        handleCheck(event)
      }
      return r
    }
    handleCheck(event)
  }

  const total = ["Weekly", "Biweekly", "Semi-Monthly", "Monthly"]
  let invoiceFrequencies = []
  if (index === 0 || index === 1) {
    invoiceFrequencies = total
  } else {
    invoiceFrequencies = total.slice(index - 1)
  }

  const [requiredTo, setRequiredTo] = useState([])
  const [requiredCC, setRequiredCC] = useState([])
  const filteredContact = contacts.filter((e) => e.isExist === true);
  useEffect(() => {
    const requiredTo = filteredContact.filter(
      (contact) => to.includes(contact.id) 
    );
    setRequiredTo(requiredTo);
    const requiredCC = filteredContact.filter(
      (contact) =>  cc.includes(contact.id)
    );
    setRequiredCC(requiredCC);
  }, [to, cc, contacts]);
  console.log(filteredContact);
  return (
    <div>
      <Tabs value={value} onChange={handleTab} variant="scrollable">
        <Tab value={0} label="Invoice Details" />
        <Tab
          value={1}
          wrapped={true}
          label="Billing and Notifications Contact"
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              select
              label="Frequnecy"
              size="small"
              required
              value={frequency}
              style={{ marginTop: "3px" }}
              name="frequency"
              onChange={handleChange}
              fullWidth
              id="placements-invoices-frequency"
            >
              {invoiceFrequencies.map((option) => (
                <MenuItem key={option} value={total.indexOf(option).toString()}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="placements-invoices-frequencyStartDate"
                required="required"
                label="Frequency Start date"
                format="MM/dd/yyyy"
                value={frequencyStartDate ? frequencyStartDate : null}
                onChange={(date) =>
                  handleDateChange("frequencyStartDate", date)
                }
                name="frequencyStartDate"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={8}>
            <FormGroup>
              <FormControlLabel
                id="placements-invoices-attachFlairTimesheets"
                control={<IOSSwitch />}
                checked={attachFlairTimesheets}
                name="attachFlairTimesheets"
                onChange={handleCheck}
                label="Flair Timesheet Attachment"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <FormGroup>
              <FormControlLabel
                id="placements-invoices-POnumber"
                control={<IOSSwitch />}
                checked={POnumber}
                name="POnumber"
                onChange={handleCheck}
                label="PO Number"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={8}>
            <FormGroup>
              <FormControlLabel
                id="placements-invoices-attachFlairExpense"
                control={<IOSSwitch />}
                onChange={handleCheck}
                name="attachFlairExpense"
                checked={attachFlairExpense}
                label="Flair Expense Attachment"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            <FormGroup>
              <FormControlLabel
                control={<IOSSwitch />}
                checked={OT}
                name="OT"
                onChange={(event) => handleOT(event)}
                label="Invoice OT"
                id="placements-invoices-OT"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={1}>
          {/* <Grid item xs={4}>
              <TextField
                size="small"
                required
                variant="outlined"
                label="Contact Name"
                id="placements-invoices-pointOfContactName"
                value={pointOfContactName}
                helperText={
                  pointOfContactName.length
                    ? validate.checkName(pointOfContactName)
                      ? ""
                      : "Enter valid name"
                    : ""
                }
                name="pointOfContactName"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                required
                variant="outlined"
                id="placements-invoices-pointOfContactName"
                label="Contact Phone Number"
                value={pointOfContactPhoneNo}
                name="pointOfContactPhoneNo"
                onChange={handleChange}
                helperText={
                  pointOfContactPhoneNo.length
                    ? validate.checkNumber(pointOfContactPhoneNo)
                      ? ""
                      : "Enter valid phone number"
                    : ""
                }
                fullWidth
                InputProps={{
                  inputComponent: MobileNumberFormatter,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                id="placements-invoices-pointOfContactMailId"
                required
                variant="outlined"
                label="Contact mail ID"
                value={pointOfContactMailId}
                helperText={
                  pointOfContactMailId.length
                    ? validate.checkEmail(pointOfContactMailId)
                      ? ""
                      : "Enter valid email"
                    : ""
                }
                name="pointOfContactMailId"
                onChange={handleChange}
                fullWidth
              />
            </Grid> */}
          <Grid item xs={12}>
            <div className="text-right">
              <Tooltip title="Add contact">
                <EditContact
                  btnContent={<AddCircleOutlineIcon />}
                  clientId={""}
                  clientList={clientList}
                  isWorkLocation={true}
                  edit={false}
                  contact={{}}
                  isPlacement={true}
                />
              </Tooltip>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="placements-invoices-to"
              options={filteredContact}
              value={requiredTo}
              getOptionLabel={(option) =>
                option.representativeName + " - " + option?.email || ""
              }
              onChange={(e, v) => {
                if (v) {
                  handleKeyValuePair(
                    "to",
                    v.map((item) => item.id)
                  );
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="To *" variant="standard" />
              )}
            />
            {/* <ChipInput
                value={to}
                onAdd={(chip) => handleAdd("to", chip)}
                onDelete={(_, index) => handleDelete("to", index)}
                helperText={helperText.to}
                allowDuplicates={false}
                label="To *"
                fullWidth
                id="placements-invoices-to"
                newChipKeyCodes={[13, 9, 32]}
              /> */}
          </Grid>
          <Grid item xs={12}>
            {/* <ChipInput
                value={cc}
                onAdd={(chip) => handleAdd("cc", chip)}
                onDelete={(_, index) => handleDelete("cc", index)}
                helperText={helperText.cc}
                allowDuplicates={false}
                label="CC"
                fullWidth
                id="placements-invoices-cc"
                newChipKeyCodes={[13, 9, 32]}
              /> */}
            <Autocomplete
              multiple
              id="placements-invoices-cc"
              options={filteredContact}
              value={requiredCC}
              getOptionLabel={(option) =>
                option.representativeName + " - " + option?.email || ""
              }
              onChange={(e, v) => {
                if (v) {
                  handleKeyValuePair(
                    "cc",

                    v.map((item) => item.id)
                  );
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="CC" variant="standard" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <ChipInput
              value={bcc}
              onAdd={(chip) => handleAdd("bcc", chip)}
              onDelete={(_, index) => handleDelete("bcc", index)}
              helperText={helperText.bcc}
              allowDuplicates={false}
              label="BCC"
              fullWidth
              id="placements-invoices-bcc"
              newChipKeyCodes={[13, 9, 32]}
            />
          </Grid>
          <Grid item xs={12}>
            <TextareaAutosize
              required
              placeholder="Billing Address"
              value={billingAddress}
              name="billingAddress"
              onChange={handleChange}
              id="placements-invoices-billingAddress"
              rowsMin={5}
              style={{ marginTop: "10px", width: "100%" }}
            />
          </Grid>
        </Grid>
      </TabPanel>
      <br />
      <Button
        onClick={handleSubmit}
        variant="contained"
        color={fillableSections.includes("invoices") ? "primary" : "secondary"}
        disabled={
          !frequency ||
          !billingAddress.trim().length ||
          !to.length ||
          !frequencyStartDate
            ? true
            : false
        }
      >
        {fillableSections.includes("invoices") ? "Done" : "Update"}
      </Button>
    </div>
  );
}

export default Presentation
