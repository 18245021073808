import React from "react"
// import InvoicesSort from "../Services/Invoices/components/InvoicesList/Index"
import TabsView from "../Services/Invoices/components/ListingInvoices/TabsView"

function InvoicesListPage(props) {
  return (
    <div>
      {/* <InvoicesSort {...props} listAll={true} listAllCriteriaID={""} /> */}
      <TabsView />
    </div>
  )
}

export default InvoicesListPage
