import React, { useState, useReducer, useContext } from 'react';
import firebase from "../../../../config/fbConfig"
import make_API_call from "../../../../providers/REST_API"
import { CalendarFormatter } from '../../../../shared/CalendarFormatter';
import { successMsg, errorMsg } from "../../../../shared/SnackBars/index"
import validation from "../../../../shared/validation"
import { useHistory } from "react-router-dom"
import { JSutils, TimesheetsUTILS } from '../../../../shared/JSutils';
import { differenceInDays } from 'date-fns';
import { useQueryClient } from '@tanstack/react-query';

const ListingInvoiceContext = React.createContext();
const useInvoiceListContext = () => useContext(ListingInvoiceContext);
const tab = Number(validation.getQueryParameter("tab"))

const invoiceListInitialState = {
  tabValue: tab,
  invoicesList: [],
  paymentHistory: [],
  showLoader: false,
  isMailing: false,
  isAddingPayment: false,
  arAgingList: {},
  arAgingInDetail: [],
  invoicesHistory:[],
  modules: [],
  isLoading: true,
  page: 0,
  dashboardDataload : false,
  dashboardData:{},
  dashboardMonthCount: {},
  invoiceDetails: {},
}


function InvoiceListContextProvider(props) {
  const history = useHistory();
  const pathName = window.location.pathname
  const queryClient = useQueryClient();

  const [tabValue, setTabValue] = useState(invoiceListInitialState.tabValue);
  const [invoicesList, setInvoicesList] = useState(invoiceListInitialState.invoicesList);
  const [paymentHistory, setpaymentHistory] = useState(invoiceListInitialState.paymentHistory);
  const [dashboardDataLoaded, setDashboardDataLoaded] = useState(invoiceListInitialState.dashboardDataload);
  const [showLoader, setShowLoader] = useState(invoiceListInitialState.showLoader);
  const [isMailing, setIsMailing] = useState(invoiceListInitialState.isMailing);
  const [isAddingPayment, setIsAddingPayment] = useState(invoiceListInitialState.isMailing);
  const [arAgingList, setArAgingList] = useState(invoiceListInitialState.arAgingList);
  const [arAgingDataInDetail, setArAgingDataInDetail] = useState(invoiceListInitialState.arAgingInDetail);
  const [dashboardData , setdashboardData] = useState(invoiceListInitialState.dashboardData)
  const [invoicesHistory, setInvoicesHistory] = useState(invoiceListInitialState.invoicesHistory);
	const [modules, setModules] = useState(invoiceListInitialState.modules)
  const [isLoading, setIsLoading] = useState(invoiceListInitialState.isLoading);
	const [page, setPage] = useState(invoiceListInitialState.page)
	const [firstDocID, setFirstDocID] = useState(null)
	const [lastDocID, setLastDocID] = useState(null)
  const [lastfiveDashboardInvoices, setLastfiveDashboardInvoices] = useState({})
  const [lastfiveDashboardInvoiceLoader, setLastfiveDashboardInvoiceLoader] = useState(false)
  const [dashboardMonthCountLoader, setdashboardMonthCountLoader] = useState(false)
  const [dashboardMonthCount, setdashboardMonthCount] = useState(invoiceListInitialState.dashboardMonthCount)
  const [invoiceDetails, setInvoiceDetails] = useState(invoiceListInitialState.invoiceDetails)

  const state = {
    tabValue,
    invoicesList,
    showLoader,
    isMailing,
    isAddingPayment,
    paymentHistory,
    arAgingList,
    arAgingDataInDetail,
    dashboardData,
    invoicesHistory,
    modules,
    isLoading,
    dashboardDataLoaded,
    dashboardMonthCount,
    dashboardMonthCountLoader,
    lastfiveDashboardInvoices,
    lastfiveDashboardInvoiceLoader,
    page,
    invoiceDetails
  }

  const stateSetters = {
    setTabValue,
    setInvoicesList,
    setShowLoader,
    setIsMailing,
    setIsAddingPayment,
    setpaymentHistory,
    setArAgingList,
    setArAgingDataInDetail,
    setdashboardData,
    setInvoicesHistory,
    setModules,
    setIsLoading,
    setdashboardMonthCount,
    setdashboardMonthCountLoader,
    setDashboardDataLoaded,
    setLastfiveDashboardInvoices,
    setLastfiveDashboardInvoiceLoader,
    setPage,
    setInvoiceDetails
  }

  const getArAgingList = async () => {
    try {
      setShowLoader(true)
			const response = await make_API_call('get', `/invoices/arAgingData`)
      setArAgingList(response)
      setShowLoader(false)
    } catch (error) {
      errorMsg('Failed to A/R Aging data')
			setShowLoader(false)
			const errorInfo = {
				stringifiedError: error?.message,
				service: 'Invoices - A/R Aging',
				functionName: 'getArAgingList',
			}
			make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const getInvoicesByDueDays = async (clientID, range) => {
    try {
			setShowLoader(true)
			const response = await make_API_call(
				'get',
				`/invoices/getInvoiceByDueDays?clientId=${clientID}&range=${range}`
			)
			setInvoicesList(response)
			setShowLoader(false)
		} catch (error) {
			errorMsg('Failed to data')
			setShowLoader(false)
			const errorInfo = {
				stringifiedError: error?.message,
				service: 'Invoices - getInvoicesByDueDays',
				functionName: 'getInvoicesByDueDays',
			}
			make_API_call('post', '/errors/report', errorInfo)
		}
  }

  const getInvoicesDashboardData = async (fromDate, toDate) => {
    try {
			setDashboardDataLoaded(true)
			const response = await make_API_call(
				'get',
				`/invoices/getDashboardDataByMonth?fromDate=${fromDate}&toDate=${toDate}`
			)
			setdashboardData(response)
			setDashboardDataLoaded(false)
		} catch (error) {
			errorMsg('Failed to data')
			setDashboardDataLoaded(false)
			const errorInfo = {
				stringifiedError: error?.message,
				service: 'Invoices - getDashboardDataByMonth',
				functionName: 'getDashboardDataByMonth',
			}
			make_API_call('post', '/errors/report', errorInfo)
		}
  }

  const getDashboardMonthCount = async (fromDate, toDate) => {
    try {
			setdashboardMonthCountLoader(true)
			const response = await make_API_call(
				'get',
				`/invoices/getDashboardMonthCount?fromDate=${fromDate}&toDate=${toDate}`
			)
			setdashboardMonthCount(response)
			setdashboardMonthCountLoader(false)
		} catch (error) {
			errorMsg('Failed to data')
			setdashboardMonthCountLoader(false)
			const errorInfo = {
				stringifiedError: error?.message,
				service: 'Invoices - getDashboardMonthCount',
				functionName: 'getDashboardMonthCount',
			}
			make_API_call('post', '/errors/report', errorInfo)
		}
  }

  const getLastFiveDashboardInvoices = async () => {
    try {
			setLastfiveDashboardInvoiceLoader(true)
			const response = await make_API_call(
				'get',
				`/invoices/getLastFiveDashboardInvoices`
			)
			setLastfiveDashboardInvoices(response)
			setLastfiveDashboardInvoiceLoader(false)
		} catch (error) {
			errorMsg('Failed to data')
			setLastfiveDashboardInvoiceLoader(false)
			const errorInfo = {
				stringifiedError: error?.message,
				service: 'Invoices - getLastFiveDashboardInvoices',
				functionName: 'getLastFiveDashboardInvoices',
			}
			make_API_call('post', '/errors/report', errorInfo)
		}
  }

  const getArAgingDataInDetail = async (range, setLoader, setData) => {
    try {
			setLoader(true)
			const response = await make_API_call(
				'get',
				`/invoices/getArAgingDataInDetail?range=${range}`
			)
      const data = response.map((value) => {
        return {
          ...value,
          dueDays: differenceInDays(new Date(), new Date(value.dueDate))
        }
      })
			setData(data)
			setLoader(false)
		} catch (error) {
			errorMsg('Failed to data')
			setLoader(false)
			const errorInfo = {
				stringifiedError: error?.message,
				service: 'Invoices - getArAgingDataInDetail',
				functionName: 'getArAgingDataInDetail',
			}
			make_API_call('post', '/errors/report', errorInfo)
		}
  }

  const setTabChangeValue = (key) => {
    history.push(`${pathName}?tab=${key}`)
    setTabValue(key.toString());
  }

  const getDueInvoices = async (isEmployeeInvoices, employeeID, isClientInvoices, clientID, setList) => {
    try {
      setShowLoader(true);
      isEmployeeInvoices ?
        await firebase
          .firestore()
          .collection("INVOICES")
          .where("employeeID", "==", employeeID)
          .where("isPaymentDone", "==", false)
          .where("isVoid", "==", false)
          .where("isExist", "==", true)
          .onSnapshot(snap => {
            const data = snap.docs.map(d => d.data()).filter(d => Date.parse(d.dueDate) < Date.parse(new Date())).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            setInvoicesList(data)
            setList(data)
          })
        : isClientInvoices ?
          await firebase
            .firestore()
            .collection("INVOICES")
            .where("clientID", "==", clientID)
            .where("isPaymentDone", "==", false)
            .where("isVoid", "==", false)
            .where("isExist", "==", true)
            .onSnapshot(snap => {
              const data = snap.docs.map(d => d.data()).filter(d => Date.parse(d.dueDate) < Date.parse(new Date())).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
              setInvoicesList(data)
              setList(data)
            })
          :
          await firebase
            .firestore()
            .collection("INVOICES")
            .where("isPaymentDone", "==", false)
            .where("isVoid", "==", false)
            .where("isExist", "==", true)
            .onSnapshot(snap => {
              const data = snap.docs.map(d => d.data()).filter(d => Date.parse(d.dueDate) < Date.parse(new Date())).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
              setInvoicesList(data)
              setList(data)
            })
      setShowLoader(false);
    } catch (error) {
      errorMsg('Failed to load Due Invoices')
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Listing Invoices',
        functionName: 'getDueInvoices'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const getGeneratedInvoices = async (isEmployeeInvoices, employeeID, isClientInvoices, clientID, setList) => {
    try {
      setShowLoader(true);
      isEmployeeInvoices ?
        await firebase
          .firestore()
          .collection("INVOICES")
          .where("employeeID", "==", employeeID)
          .where("isPaymentDone", "==", false)
          .where("isMailedToClient", "==", false)
          .where("isVoid", "==", false)
          .where("isExist", "==", true)
          .orderBy("createdAt", "desc")
          .onSnapshot(snap => {
            const data = snap.docs.map(d => d.data())
            setInvoicesList(data)
            setList(data)
          })
        : isClientInvoices ?
          await firebase
            .firestore()
            .collection("INVOICES")
            .where("clientID", "==", clientID)
            .where("isPaymentDone", "==", false)
            .where("isMailedToClient", "==", false)
            .where("isVoid", "==", false)
            .where("isExist", "==", true)
            .orderBy("createdAt", "desc")
            .onSnapshot(snap => {
              const data = snap.docs.map(d => d.data())
              setInvoicesList(data)
              setList(data)
            })
          :
          await firebase
            .firestore()
            .collection("INVOICES")
            .where("isPaymentDone", "==", false)
            .where("isMailedToClient", "==", false)
            .where("isVoid", "==", false)
            .where("isExist", "==", true)
            .orderBy("createdAt", "desc")
            .onSnapshot(snap => {
              const data = snap.docs.map(d => d.data())
              setInvoicesList(data)
              setList(data)
            })
      setShowLoader(false);
    } catch (error) {
      errorMsg('Failed to load Generated Invoices')
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Listing Invoices',
        functionName: 'getGeneratedInvoices'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const getSentInvoices = async (isEmployeeInvoices, employeeID, isClientInvoices, clientID, setList) => {
    try {
      setShowLoader(true);
      isEmployeeInvoices ?
        await firebase
          .firestore()
          .collection("INVOICES")
          .where("employeeID", "==", employeeID)
          .where("isPaymentDone", "==", false)
          .where("isMailedToClient", "==", true)
          .where("isVoid", "==", false)
          .where("isExist", "==", true)
          .orderBy("createdAt", "desc")
          .onSnapshot(snap => {
            const data = snap.docs.map(d => d.data())
            setInvoicesList(data)
            setList(data)
          })
        : isClientInvoices ?
          await firebase
            .firestore()
            .collection("INVOICES")
            .where("clientID", "==", clientID)
            .where("isPaymentDone", "==", false)
            .where("isMailedToClient", "==", true)
            .where("isVoid", "==", false)
            .where("isExist", "==", true)
            .orderBy("createdAt", "desc")
            .onSnapshot(snap => {
              const data = snap.docs.map(d => d.data())
              setInvoicesList(data)
              setList(data)
            })
          :
          await firebase
            .firestore()
            .collection("INVOICES")
            .where("isPaymentDone", "==", false)
            .where("isMailedToClient", "==", true)
            .where("isVoid", "==", false)
            .where("isExist", "==", true)
            .orderBy("createdAt", "desc")
            .onSnapshot(snap => {
              const data = snap.docs.map(d => d.data())
              setInvoicesList(data)
              setList(data)
            })
      setShowLoader(false);
    } catch (error) {
      errorMsg('Failed to load Sent Invoices')
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Listing Invoices',
        functionName: 'getSentInvoices'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const getVoidInvoices = async (isEmployeeInvoices, employeeID, isClientInvoices, clientID, setList) => {
    try {
      setShowLoader(true);
      isEmployeeInvoices ?
        await firebase
          .firestore()
          .collection("INVOICES")
          .where("employeeID", "==", employeeID)
          .where("isVoid", "==", true)
          .where("isExist", "==", true)
          .orderBy("createdAt", "desc")
          .onSnapshot(snap => {
            const data = snap.docs.map(d => d.data())
            setInvoicesList(data)
            setList(data)
          })
        : isClientInvoices ?
          await firebase
            .firestore()
            .collection("INVOICES")
            .where("clientID", "==", clientID)
            .where("isVoid", "==", true)
            .where("isExist", "==", true)
            .orderBy("createdAt", "desc")
            .onSnapshot(snap => {
              const data = snap.docs.map(d => d.data())
              setInvoicesList(data)
              setList(data)
            })
          :
          await firebase
            .firestore()
            .collection("INVOICES")
            .where("isVoid", "==", true)
            .where("isExist", "==", true)
            .orderBy("createdAt", "desc")
            .onSnapshot(snap => {
              const data = snap.docs.map(d => d.data())
              setInvoicesList(data)
              setList(data)
            })
      setShowLoader(false);
    } catch (error) {
      errorMsg('Failed to load Void Invoices')
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Listing Invoices',
        functionName: 'getVoidInvoices'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const getPaidInvoices = async (isEmployeeInvoices, employeeID, isClientInvoices, clientID, setList) => {
    try {
      setShowLoader(true);
      isEmployeeInvoices ?
        await firebase
          .firestore()
          .collection("INVOICES")
          .where("employeeID", "==", employeeID)
          .where("isPaymentDone", "==", true)
          .where("isExist", "==", true)
          .orderBy("createdAt", "desc")
          .onSnapshot(snap => {
            const data = snap.docs.map(d => d.data())
            setInvoicesList(data)
            setList(data)
          })
        : isClientInvoices ?
          await firebase
            .firestore()
            .collection("INVOICES")
            .where("clientID", "==", clientID)
            .where("isPaymentDone", "==", true)
            .where("isExist", "==", true)
            .orderBy("createdAt", "desc")
            .onSnapshot(snap => {
              const data = snap.docs.map(d => d.data())
              setInvoicesList(data)
              setList(data)
            })
          :
          await firebase
            .firestore()
            .collection("INVOICES")
            .where("isPaymentDone", "==", true)
            .where("isExist", "==", true)
            .orderBy("createdAt", "desc")
            .onSnapshot(snap => {
              const data = snap.docs.map(d => d.data())
              setInvoicesList(data)
              setList(data)
            })
      setShowLoader(false);
    } catch (error) {
      errorMsg('Failed to load Paid Invoices')
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Listing Invoices',
        functionName: 'getPaidInvoices'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const getAllInvoices = async (isEmployeeInvoices, employeeID, isClientInvoices, clientID, setList) => {
    console.log(isEmployeeInvoices, employeeID, isClientInvoices, clientID, "true")
    try {
      setShowLoader(true);
      isEmployeeInvoices ?
        await firebase
          .firestore()
          .collection("INVOICES")
          .where("employeeID", "==", employeeID)
          .where("isExist", "==", true)
          .orderBy("createdAt", "desc")
          .onSnapshot(snap => {
            const data = snap.docs.map(d => d.data())
            setInvoicesList(data)
            setList(data)
          })
        : isClientInvoices ?
          await firebase
            .firestore()
            .collection("INVOICES")
            .where("clientID", "==", clientID)
            .where("isExist", "==", true)
            .orderBy("createdAt", "desc")
            .onSnapshot(snap => {
              const data = snap.docs.map(d => d.data())
              setInvoicesList(data)
              setList(data)
            })
          :
          await firebase
            .firestore()
            .collection("INVOICES")
            .where("isExist", "==", true)
            .orderBy("createdAt", "desc")
            .onSnapshot(snap => {
              const data = snap.docs.map(d => d.data())
              setInvoicesList(data)
              setList(data)
            })
      setShowLoader(false);
    } catch (error) {
      errorMsg('Failed to load All Invoices')
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Listing Invoices',
        functionName: 'getAllInvoices'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const getInvoiceTimeline = (invoiceID) => {
    return []
  }

  const sendInoviceToClient = async (inputs, invoiceID, type) => {
    try {
      const encodedID = encodeURIComponent(invoiceID)
      await make_API_call("post", `/invoices/sendinvoicetoclient?invoiceID=${encodedID}`, inputs)
      successMsg(`${invoiceID} mailed successfully`)
      await queryClient.invalidateQueries(["getInvoices",type,{clientID : '', employeeID : ''}]);
      await queryClient.invalidateQueries(["getInvoices",'sent',{clientID : '', employeeID : ''}])
    } catch (error) {
      errorMsg(`Failed to Sent Invoice to Client ${invoiceID}`)
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Listing Invoices',
        functionName: 'sendInoviceToClient'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const makeInvoiceVoid = async (invoiceID, voidReason, isUpdatingVoidReason = 0) => {
    // here isUpdatingVoidReason will be 1 or 0 i.e 1 is yes and 0 is false
    try {
      const encodedID = encodeURIComponent(invoiceID)
      const info = await make_API_call("put", `/invoices/makevoid?invoiceID=${encodedID}&isUpdatingVoidReason=${isUpdatingVoidReason}`, { voidReason })
      successMsg(info.message)
      history.push(`/console/invoiceslist?tab=${3}`)
    } catch (error) {
      errorMsg(`Failed to Void Invoice ${invoiceID}`)
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Listing Invoices',
        functionName: 'makeInvoiceVoid'
      }
      make_API_call('post', '/errors/report', errorInfo)
      throw error
    }
  }

  const newPayment = async (payload, clearState, handleClose,handleLoad) => {
    try {
      setIsAddingPayment(true)
      await make_API_call("post", `/invoices/new-payment`, payload)
      successMsg(`${payload.invoiceID} payment made successfully`)
      clearState()
      handleLoad()
      handleClose()
      setIsAddingPayment(false)
    } catch (error) {
      errorMsg(error?.message || `Failed to add Payment`)
      setIsAddingPayment(false)
      const errorInfo = {
        stringifiedError: error?.message || '',
        service: 'Invoices - Listing Invoices',
        functionName: 'newPayment'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }
  const updatePayment = async (payload, clearState, handleClose,handleLoad) => {
    try {
      setIsAddingPayment(true)
      await make_API_call("post", `/invoices/update-payment`, payload)
      successMsg(`${payload.invoiceID} payment updated successfully`)
      clearState()
      handleLoad()
      handleClose()
      setIsAddingPayment(false)
    } catch (error) {
      errorMsg(error?.message || `Failed to update Payment`)
      setIsAddingPayment(false)
      const errorInfo = {
        stringifiedError: error?.message || '',
        service: 'Invoices - Listing Invoices',
        functionName: 'updatePayment'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const calculateDiscount = (subTotal, discountArr) => {
    return Number(discountArr.reduce((initial, item) => {
      if (item.type === "byValue") {
        return initial - Number(item.value);
      } else if (item.type === "byPercentage" && Number(item.value) > 0) {
        return initial - Number((subTotal * Number(item.value)) / 100);
      } else {
        return initial - 0;
      }
    }, 0).toFixed(2))
  }

  const calculateGrandTotal = (subTotal, discount) => {
    // calculation between subtotal and discount
    const discountAmount = calculateDiscount(subTotal, discount)
    return subTotal - discountAmount
  }
  const getPaymentHistory = async (invoiceID) => {
    try {
      setShowLoader(true);
      await firebase
        .firestore()
        .collection("INVOICES")
        .doc(invoiceID)
        .collection("PAYMENTS_HISTORY")
        .orderBy("createdAt", "desc")
        .onSnapshot(snap => {
          const data = snap.docs.map(d => d.data())
          setpaymentHistory(data)
        })

      setShowLoader(false);
    } catch (error) {
      errorMsg('Failed to load Sent Invoices')
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - payment History of Invoices',
        functionName: 'getPaymentHistory'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }

  const followUpInvoice = async (invoiceID, followupComment, isUpdatingFollowup = 0) => {
    // here isUpdatingFollowup will be 1 or 0 i.e 1 is yes and 0 is false
    try {
      const encodedID = encodeURIComponent(invoiceID)
      const info = await make_API_call("put", `/invoices/followup?invoiceID=${encodedID}&isUpdatingFollowup=${isUpdatingFollowup}`, { followupComment })
      successMsg(info.message)
    } catch (error) {
      errorMsg(`Failed to Void Invoice ${invoiceID}`)
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Listing Invoices',
        functionName: 'followUpInvoice'
      }
      make_API_call('post', '/errors/report', errorInfo)
      throw error
    }
  }

  const getTotalTime = (arr) => {
    const hours = [],
      minutes = [];
    arr.forEach((item) => {
      const [h, m] = item.split(":");
      hours.push(parseInt(h));
      minutes.push(parseInt(m));
    });
    let totalHours = hours.reduce((a, b) => a + b, 0);
    let totalMinutes = minutes.reduce((a, b) => a + b, 0);
    totalHours = parseInt(totalHours + totalMinutes / 60);
    let remainingMinutes = totalMinutes % 60;
    return (
      totalHours +
      ":" +
      "0".repeat(2 - remainingMinutes.toString().length) +
      remainingMinutes.toString()
    );
  }

  const getBillingRatesInvolvedInTheTimesheets = (timesheets) => {
    return [...new Set(timesheets.map(it => it.billingRate))]
  }

  const getOTBillingRatesInvolvedInTheTimesheets = (timesheets) => {
    return [...new Set(timesheets.map(it => it.OTbillingRate))]
  }

  const getInvoicesHistory = async (isPageZero = false,isNextPage = false,isPrevPage = false,page,pageSize = 10) => {
    try{
      let payload = {isPageZero,isNextPage,isPrevPage,pageSize,firstDocID,lastDocID}
      setIsLoading(true)
      let data = await make_API_call("post","/invoices/invoice-history",payload)
      setPage(page)
      setInvoicesHistory(data.invoiceHistory)
      setFirstDocID(data.firstDocument)
      setLastDocID(data.lastDocument)
      setIsLoading(false)
      return data.invoiceHistory
    }catch (error){
      console.error(error)
      errorMsg(`Failed to load invoices history`)
      let errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Listing Invoices',
        functionName: 'getInvoicesHistory'
      }
      make_API_call('post', '/errors/report', errorInfo)
      setIsLoading(false)
      throw error
    }
  }

  const getModules = async () => {
		try {
      setIsLoading(true)
			const data = await make_API_call("get",'/employee/modules')
			setModules(data)
      setIsLoading(false)
		} catch (error) {
			console.error(error)
			const errorInfo = {
				stringifiedError: error?.message,
				service: 'History - getModules',
				functionName: 'getModules',
			}
			make_API_call('post', '/errors/report', errorInfo)
      setIsLoading(false)
		}
	}

  const getInvoiceDetails = async (invoiceID) => {
    try {
      setShowLoader(true);
      await firebase
        .firestore()
        .collection("INVOICES")
        .doc(invoiceID)
        .onSnapshot(snap => {
          const data = snap.data()
          setInvoiceDetails(data)
        })
      setShowLoader(false);
    } catch (error) {
      errorMsg('Failed to load Invoice Details')
      setShowLoader(false);
      const errorInfo = {
        stringifiedError: error?.message,
        service: 'Invoices - Invoice Details',
        functionName: 'getInvoiceDetails'
      }
      make_API_call('post', '/errors/report', errorInfo)
    }
  }


  const services = {
    setTabChangeValue,
    getDueInvoices,
    getGeneratedInvoices,
    getSentInvoices,
    getVoidInvoices,
    getPaidInvoices,
    getAllInvoices,
    sendInoviceToClient,
    makeInvoiceVoid,
    newPayment,
    calculateGrandTotal,
    calculateDiscount,
    getPaymentHistory,
    updatePayment,
    getBillingRatesInvolvedInTheTimesheets,
    getOTBillingRatesInvolvedInTheTimesheets,
    getTotalTime,
    followUpInvoice,
    getArAgingList,
    getInvoicesByDueDays,
    getArAgingDataInDetail,
    getInvoicesDashboardData,
    getInvoicesHistory,
    getModules,
    getInvoicesDashboardData,
    getLastFiveDashboardInvoices,
    getDashboardMonthCount,
    getInvoiceDetails
  }

  return (
    <ListingInvoiceContext.Provider value={{ state, stateSetters, services }} >
      {props.children}
    </ListingInvoiceContext.Provider>
  )
}
export { InvoiceListContextProvider, useInvoiceListContext }
