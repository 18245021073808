import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  avatar: {
    width: 30,
    height: 30,
  },
  name: {
    fontFamily: "fira sans",
    fontWeight: "bold",
    fontSize: 12,
    whiteSpace: "nowrap",
  },
  position: {
    verticalAlign: "super",
  },
}))
export default useStyles
