import React, { useEffect } from "react"
import Presentation from './Presentation'
import { load_Dash_Projects } from "../../../../middleware/projectsList"
import { compose } from "redux"
import { connect } from "react-redux"

function Container(props) {
  const { list_of_projects, state } = props
  console.log(state)
  useEffect(() => {
    list_of_projects()
  }, [])
  return (
    <div>
      <Presentation state={state}  />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    state: state.projects.projectsList,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    list_of_projects: () => {
      dispatch(load_Dash_Projects())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Container)
