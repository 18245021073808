import React from "react"
import ClientCard from "../clientComponents/ClientCard"
import ClientTabs from "../clientComponents/ClientTabs"
import {Card} from "react-bootstrap"

function Presentation(props) {
  const { client } = props
  console.log(client)
  if (Object.keys(client).length)
    return (
      <div>
        <Card>
        <Card.Body>
        <ClientCard client={client} />
        </Card.Body>
        </Card>
        <ClientTabs client={client} />
      </div>
    )
  return <h1 className="text-center">No client found</h1>
}

export default Presentation
