import React from "react";
import { Chip, Tooltip } from "@material-ui/core";

function RedChip(props) {
    const {
      leftIcon,
      message,
      rightIcon,
      tooltipContent,
      tooltipPosition,
      style = {},
      } = props;
  
    // Check if tooltipContent is provided
    const hasTooltip = Boolean(tooltipContent);


  return (

        <div>
            {hasTooltip ? (
                <Tooltip placement="top" title={tooltipContent}>
                    <div
                        style={{
                            backgroundColor: "red",
                            color: "#FFFFFF",
                            display: "inline-block",
                            alignItems: "center",
                            padding: "0 12px",
                            borderRadius: "25px",
                            height: "24px", // Set the same height as Material-UI Chip
                            fontSize: "0.875rem", // Set the same font size as Material-UI Chip
                            lineHeight: "24px", // Adjust the line height if needed
                            ...style,
                        }}
                    >
                        <span style={{ marginRight: "4px" }}>{leftIcon}</span>
                        {message}
                        <span style={{ marginLeft: "4px", fontWeight: "bold" }}>{rightIcon}</span>
                    </div>
                </Tooltip>
            ) : 
                <div
                  style={{
                      backgroundColor: "red",
                      color: "#FFFFFF",
                      display: "inline-block",
                      alignItems: "center",
                      padding: "0 12px",
                      borderRadius: "25px",
                      height: "24px", // Set the same height as Material-UI Chip
                      fontSize: "0.875rem", // Set the same font size as Material-UI Chip
                      lineHeight: "24px", // Adjust the line height if needed
                      ...style,
                  }}
                >
                  <span style={{ marginRight: "4px" }}>{leftIcon}</span>
                  {message}
                  <span style={{ marginLeft: "4px", fontWeight: "bold" }}>{rightIcon}</span>
                </div>
            }
        </div>


    // <Tooltip placement="top" title={tooltipContent}>
    //   <Chip style={{
    //       backgroundColor: "red",
    //       color: "#FFFFFF",
    //     }}
    //     icon={LeftIcon}
    //     label={message}
    //     deleteIcon={RightIcon}
    //     // onDelete={handleDelete}
    //     size="small" />
    //   </Tooltip>
  );
}

export default RedChip;
