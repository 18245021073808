import React, { useState, useEffect } from "react";
import { Stepper, Step, StepLabel, Button } from "@material-ui/core";
import useStyles from "../../../styles/stepperStyles";
import BusinessInformation from "../newClientComponents/BusinessInformation";
import Contacts from "../newClientComponents/Contacts/Index";
import Accounts from "../newClientComponents/Accounts";
import Locations from "../newClientComponents/Locations/Index";
import validate from "../../../../../shared/validation";

function getSteps(isPrimeVendor) {
  if(isPrimeVendor)
    return ["Business Information"]
  else
    return ["Business Information", "Locations", "Accounts", "Contacts"]
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <BusinessInformation />
    case 1:
      return <Locations />;
    case 2:
      return <Accounts />;
    case 3:
      return <Contacts />;
    default:
      return;
  }
}
//Locations
function Presentation(props) {
  const { activeStep, handleNext, handleBack, handleFinish, newClient } = props;
  const { businessInformation, contacts, locations, accounts } = newClient;
  const classes = useStyles();
  const steps = getSteps(businessInformation.isPrimeVendor);
  
  const emptyLocation = locations.locationsList.filter(
    (location) => location.isDraft === true
  );
  const emptyContacts = contacts.contactsList.filter(
    (contact) => contact.isDraft === true
  );

  const contactsStatus = emptyContacts.length > 0 ? false : true;
  const locationsstatus = emptyLocation.length > 0 ? false : true;
  console.log(contactsStatus, "contactsStatus");
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <div>
          <div className={classes.instructions}>
            <div className="custom-card">{getStepContent(activeStep)}</div>
          </div>
          <div>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.backButton}
            >
              Back
            </Button>
            {businessInformation.isPrimeVendor || activeStep === 3 ? (
              <Button
                variant="contained"
                color="primary"
                disabled={
                  // !validate.checkName(
                  // businessInformation.businessDisplayName
                  // ) ||
                  // !validate.checkName(businessInformation.businessName) ||
                  !validate.checkNumber(businessInformation.contactNumber) ||
                  !validate.checkWebsite(businessInformation.website) ||
                  !businessInformation.jobTerminationNotice ||
                  !businessInformation.category ||
                  businessInformation.businessDisplayName.trim().length <= 0 ||
                  businessInformation.businessName.trim().length <= 0 ||
                  businessInformation.contactNumber.trim().length <= 0 ||
                  !validate.checkEmail(businessInformation.email) ||
                  !businessInformation.netTerms.trim() ||
                  businessInformation.federalId.trim().length !== 9 ||
                  businessInformation.helperText.trim().length > 0 ||
                  businessInformation.isChecking}
                onClick={handleFinish}
              >
                Finish
              </Button>
              ) : activeStep === 0 ? (
                <Button
                  variant="contained"
                  disabled={
                    // !validate.checkName(
                    //   businessInformation.businessDisplayName
                    // ) ||
                    // !validate.checkName(businessInformation.businessName) ||
                    !validate.checkNumber(businessInformation.contactNumber) ||
                    !validate.checkWebsite(businessInformation.website) ||
                    !businessInformation.jobTerminationNotice ||
                    !businessInformation.category ||
                    businessInformation.businessDisplayName.trim().length <= 0 ||
                    businessInformation.businessName.trim().length <= 0 ||
                    businessInformation.contactNumber.trim().length <= 0 ||
                    !validate.checkEmail(businessInformation.email) ||
                    !businessInformation.netTerms.trim() ||
                    businessInformation.federalId.trim().length !== 9 ||
                    // !businessInformation.invoiceLocation.line1.trim() ||
                    // !businessInformation.invoiceLocation.city.trim() ||
                    // !validate.checkZip(
                    //   businessInformation.invoiceLocation.zipCode
                    // ) ||
                    // !businessInformation.invoiceLocation.state ||
                    // !businessInformation.invoiceLocation.country ||
                    businessInformation.helperText.trim().length > 0 ||
                    businessInformation.isChecking
                  }
                  color="primary"
                  onClick={handleNext}
                >
                  Next
                </Button>
              ) : activeStep === 1 ? (
                <Button
                variant="contained"
                color="primary"
                disabled={!locationsstatus}
                onClick={handleNext}
              >
                Next
              </Button>
              ) : activeStep === 2 ? (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    accounts.discountDetails.filter(
                      (discount) =>
                        discount.name.trim() === "" &&
                        discount.value !== 0 &&
                        discount.type !== ""
                    ).length > 0
                  }
                  onClick={handleNext}
                >
                  Next
                </Button>
              ) 
              : 
              null
            }
            {/* <Button
              variant="contained"
              color="primary"
              onClick={
                activeStep === steps.length - 1 ? handleFinish : handleNext
              }
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Presentation;
