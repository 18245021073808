import React, { useEffect } from "react"
import { connect } from "react-redux"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import { compose } from "redux"
import CircularSpinner from "../../../../../../shared/circularSpinner"
import { loadClientProjects } from "../../../../middleware/projectIds"
import Presentation from "./Presentation"

function Container(props) {
  const {
    clientPlacements,
    loadClientProjects,
    placementSettings,
    isloadedClient,
    isEmptyClient,
  } = props
  let isLoading = true
  if (isLoaded(clientPlacements)) {
    isLoading = false
  }

  useEffect(() => {
    const placementIDs = clientPlacements ? clientPlacements : []
    let paylaod = {
      placementIDs,
    }
    loadClientProjects(paylaod)
  }, [!isLoading])

  if (isLoaded(clientPlacements)) {
    if (isloadedClient) {
      return (
        <div>
          <Presentation {...props} />
        </div>
      )
    } else
      return (
        <CircularSpinner />
      )
  }
  return (
    <CircularSpinner />
  )
}
const mapStateToProps = (state, ownProps) => {
  const { clientplacements_list } = state.firestore.ordered
  const { placementSettings, isLoaded, isEmpty } = state.client.clientsProjects
  const { clientId } = ownProps
  return {
    clientPlacements: clientplacements_list
      ? clientplacements_list
          .filter((item) => item.clientId === clientId)
          .map((e) => e.placementID)
      : clientplacements_list,
    placementSettings: placementSettings,
    isloadedClient: isLoaded,
    isEmptyClient: isEmpty,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadClientProjects: (payload) => {
      dispatch(loadClientProjects(payload))
    },
  }
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    const totalCollections = [
      {
        collectionGroup: "PLACEMENTS",
        orderBy: [["id", "asc"]],
        where: [["isExist", "==", true]],
        storeAs: "clientplacements_list",
      },
    ]

    return totalCollections
  })
)(Container)
