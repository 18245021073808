import {
  setStateAction,
  loadProfileTemplateReq,
  loadProfileTemplateSuccess,
  loadProfileTemplateFailure,
  updateProfileTemplateReq,
  updateProfileTemplateSuccess,
  updateProfileTemplateFailure
} from "../actions/ProfileBuilderActions/actionCreators"
import make_API_call from "../../../providers/REST_API"
import { errorMsg, successMsg } from "../../../shared/SnackBars/index"


export const _set_state = (obj) => (dispatch) => {
  dispatch(setStateAction(obj))
}

export const loadProfileTemplate = () => (dispatch, getState, { getFirebase }) => {
  dispatch(loadProfileTemplateReq())
  return getFirebase().firestore()
    .collection(`WEB_BUILDER`)
    .doc("employee-profile")
    .get()
    .then(doc => {
      return dispatch(loadProfileTemplateSuccess(doc.data()))
    }).catch(err => {
      console.error(err);
      return dispatch(loadProfileTemplateFailure("Failed to load profile template"))
    })
}

export const updateProfileTemplate = (data) => (dispatch) => {
  dispatch(updateProfileTemplateReq())
  return make_API_call("put", "/webbuilder/employeeprofile", data)
    .then(res => {
      successMsg(res.message)
      return dispatch(updateProfileTemplateSuccess())
    }).catch(err => {
      console.error(err);
      const msg = "Failed to update profile template"
      errorMsg(msg)
      return dispatch(updateProfileTemplateFailure(msg))
    })
}