import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import { Timeline, TimelineEvent } from 'react-event-timeline'
import { useBulkPaymentsContext } from './context';
import { useHistory } from 'react-router';
import MetaInfo from '../../../../shared/getMetaInfo'
import Validations from '../../../../shared/validation'
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import parser from 'html-react-parser'

function BulkPaymentsHistory(props) {
  const [anchor, setAnchor] = useState(false);

  const { bulkPaymentId } = props
  const { state, services } = useBulkPaymentsContext();
  const history = useHistory();
  const metaobj = new MetaInfo();

  useEffect(() => {
    if (bulkPaymentId && anchor)
      services.loadBulkPaymentHistory(bulkPaymentId)
  }, [anchor])

  const data = state.bulkPaymentHistory.map(item => {
    return {
      invoiceID: item.invoiceID,
      discountDetails: item.discountDetails,
      attachedFile: item.otherInfo?.attachmentDetails ?? {},
      actionBy: metaobj.emailToName(item.createdBy),
      paymentDate: item.paymentDate,
      createdAt: Validations.dateAndTimeFormatter(item.createdAt),
      description: item.actionDesc,
      paymentAmount: item.paymentAmount,
      discountAmount: item.discountAmount,
      id: item.id
    }
  }).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

  const groupedData = data.reduce(function (r, a) {
    r[a.createdAt] = r[a.createdAt] || [];
    r[a.createdAt].push(a);
    return r;
  }, Object.create(null));

  const toggleDrawer = (val) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setAnchor(val);
  };

  console.log(groupedData);
  return (
    <div>
      <React.Fragment key={anchor}>
        <Button
          color="primary"
          variant="contained"
          onClick={toggleDrawer(true)}
        >
          History
        </Button>
        <Drawer anchor={'right'} open={anchor} onClose={toggleDrawer(false)}>
          <div style={{ fontSize: '18px' }} >
            <Timeline>
              {
                Object.values(groupedData).map((eachGroup) => {
                  return (
                    <>
                      <TimelineEvent
                        title={eachGroup?.[0]?.actionBy}
                        createdAt={eachGroup?.[0]?.createdAt}
                        icon={<i />}
                        buttons={<i />}
                        iconColor="#6fba1c"
                      >
                        <TableContainer >
                          <Table size={'small'} >
                            <TableBody>
                              {eachGroup.map((eachRecord) => (
                                <TableRow
                                  key={eachRecord.id}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    {eachRecord.invoiceID}
                                  </TableCell>
                                  <TableCell align="right">{parser(eachRecord.description)}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TimelineEvent>
                    </>
                  )
                })
              }
            </Timeline>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}

export default BulkPaymentsHistory;