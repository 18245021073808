export default {
  taskData: {
    data: {},
    isLoading: true,
    error: "",
    listener: () => {},
    noOfLoadings: 0,
  },
  taskCommentsData: {
    data: {},
    isLoading: true,
    error: "",
    listener: () => {},
    noOfLoadings: 0,
  },
}
