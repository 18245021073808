import React from "react";
import { MdClose } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";
import FacebookProgress from "../../../../../shared/facebookProgress";
import validate from "../../../../../shared/validation";
import { Button } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";

function Presentation(props) {
  const {
    tableData,
    value,
    startInvite,
    updateTableContent,
    deleteTableContent,
    excel_invite,
  } = props;
  console.log(tableData);

  const columns = [
    { title: "First name", field: "firstname" },
    { title: "Last name", field: "lastname" },
    { title: "Email id", field: "email" },
    { title: "Phone no", field: "phonenumber" },
    {
      title: "DOB MM/DD/YYYY",
      field: "dob",
      render: (row) => validate.dateFormatter(row.dob),
    },
    { title: "Branch", field: "branch" },
    {
      title: "Status",
      field: "status",
      render: (item) => (
        <span className="d-flex ">
          &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          <span>
            {" "}
            {item.isInviting && item.dob !== "Invalid date" ? (
              <FacebookProgress />
            ) : item.isInvited ? (
              <IoMdCheckmark className="text-success" size={18} />
            ) : (
              <MdClose className="text-danger" size={18} />
            )}
          </span>
        </span>
      ),
    },
    { title: "Comment", field: "comment" },
  ];

  let data = [];
  tableData &&
    tableData.forEach((item) => {
      data.push({
        ...item,
      });
    });
  console.log(data);
  return (
    <MaterialTable
      title=""
      columns={columns}
      data={data}
      components={{
        Toolbar: (props) => (
          <div>
            <MTableToolbar {...props} />
            <div
              style={{ marginTop: "-58px", marginLeft: "8px", display: "flex" }}
            >
              {value === 1 ? (
                <div className="text-center">
                  {/* <img src={ArrowRight} height='40' width='50' /> */}
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={
                      data.length === 0 ||
                      excel_invite.some(
                        (item) =>
                          item.isInviting === false && item.isInvited === true
                      )
                    }
                    onClick={startInvite}
                  >
                    Invite below list
                  </Button>
                  {/* <img
										src={ArrowRight}
										className={classes.reverseImage}
										height='40'
										width='50'
									/> */}
                </div>
              ) : null}
            </div>
          </div>
        ),
      }}
      options={{
        exportButton: true,
        paginationType: "stepped",
        pageSize: 10,
      }}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              {
                const index = excel_invite.indexOf(oldData);
                updateTableContent(newData, index, oldData);
              }
              resolve();
            }, 1000);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              deleteTableContent(oldData);

              resolve();
            }, 1000);
          }),
      }}
    />
  );
}

export default Presentation;
