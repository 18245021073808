import React from "react"
import { connect } from "react-redux"
import ProfileDashboard from "../Services/EmployeeManagment/components/NewProfileUI/index"
// import PersonalProfile from "../Services/EmployeeManagment/components/NewProfileUI/PersonalProfile"
// import RenderProfile from "../Services/EmployeeManagment/components/DynamicProfile/RenderProfile/Index"
import {ProfileDashboardContextProvider} from "../Services/EmployeeManagment/components/MenuBar/context"

function EmployeeProfilePage(props) {
  const { auth } = props
  return (
    <div>
      <ProfileDashboardContextProvider>
      <ProfileDashboard Id={auth.uid} isPersonal={true}/>
      </ProfileDashboardContextProvider>
     
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps)(EmployeeProfilePage)
