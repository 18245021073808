import React from "react"
import {
  Grid,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableRow,
  withStyles,
  Typography,
  TableHead,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { Doughnut, Bar } from "react-chartjs-2"
import validate from "../../../../../shared/validation"
import ProjectLog from "../../ProjectHandleComponents/Timeline"
import MetaInfo from "../../../../../shared/getMetaInfo"
import format from "date-fns/format"
import differenceInDays from "date-fns/differenceInDays"
import { GiCheckeredFlag } from "react-icons/gi"
import { Link } from "react-router-dom"
import Settings from "../../ProjectHandler/Settings"
import { Button } from "react-bootstrap"

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell)

const CustomKeyValuePairs = ({ data = [] }) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell variant="head">
                <Typography style={{ fontWeight: "bold" }}>{item.name}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>{item.value}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function Presentation(props) {
  const { project, dashboardData } = props
  const {
    totalTickets,
    ticketsMetaInfo,
    projectMembersData,
    overDueData,
    totalOverDue,
  } = dashboardData.meta_info.data
  const ticketsData = {
    labels: ["Open", "InProgress", "Review", "Closed"],
    datasets: [
      {
        label: "Project Members",
        data: [
          ticketsMetaInfo.open,
          ticketsMetaInfo.inProgress,
          ticketsMetaInfo.review,
          ticketsMetaInfo.closed,
        ],
        backgroundColor: ["#34eb8f", "#e8eb34", "#3489eb", "#eb345c"],
      },
    ],
  }

  const projectMembers = Object.keys(project.Users).length
  const activeMembers = Object.keys(projectMembersData).length
  const availableMebers = projectMembers - activeMembers
  const busyMembers = projectMembers - availableMebers
  const projectData = {
    labels: ["Available", "Busy"],
    datasets: [
      {
        label: "Project Members",
        data: [availableMebers, busyMembers],
        backgroundColor: ["#3489eb", "#eb345c"],
      },
    ],
  }

  const overDueDataChart = {
    labels: ["Urgent", "High", "Medium", "Low"],
    datasets: [
      {
        label: "Project Members",
        data: [overDueData.urgent, overDueData.high, overDueData.medium, overDueData.low],
        backgroundColor: ["#eb345c", "#3489eb", "#e8eb34", "#42f5ef"],
      },
    ],
  }

  const projectHealth =
    totalOverDue / (ticketsMetaInfo.open + ticketsMetaInfo.inProgress + ticketsMetaInfo.review)

  const ticketsSummary = Object.entries(project.Users).map(([user]) => {
    return {
      name: new MetaInfo().emailToName(user),
      value: projectMembersData.hasOwnProperty(user)
        ? projectMembersData[user].expectTobeClosed
        : 0,
    }
  })

  const supervisors = Object.keys(project.Users)
    .filter((uid) => new MetaInfo().checkSupervisor(uid))
    .map((item) => new MetaInfo().emailToName(item))

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12}>
        <div className="d-flex justify-content-between">
          <div>
            <Link to={`/console/projects`}>
              <Button variant="outline-success" className="text-high">
                Back
              </Button>
            </Link>
          </div>
          <div className="card">
            <div className="card-body">
              <h3 style={{ textAlign: "center" }}>{project.title}</h3>
            </div>
          </div>
          <div>
            <div className="d-flex">
              <div style={{marginRight:"8px"}}>
                <Link to={`/console/projects/${project.id}/tasks?tab=0`}>
                  <Button className="btn btn-success px-6 btn-md text-high ">
                    Tasks
                  </Button>
                </Link>
              </div>
              <div>
                <Settings project={[project]} id={project.id} isDashboard={true} />
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <div className="card">
              <div className="card-body">
                <Doughnut data={ticketsData} />
              </div>
              <h3 className="text-center mb-4">Tickets: ({totalTickets})</h3>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="card">
              <div className="card-body">
                <Doughnut data={overDueDataChart} />
              </div>
              <h3 className="text-center mb-4">Over due: ({totalOverDue})</h3>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="card">
              <div className="card-body">
                <Bar data={projectData} />
              </div>
              <h3 className="text-center mb-4">Total Members: ({projectMembers})</h3>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="card scroll" style={{ height: "400px" }}>
              <div className="card-body">
                <h3 className="text-center">Vendors Summary</h3>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableCell>Vendor</TableCell>
                      <TableCell align="right">Members</TableCell>
                    </TableHead>
                    <TableBody>
                      {dashboardData.data.vendors_data.map((vendor, index) => {
                        return (
                          <TableRow>
                            <MuiTableCell>
                              <Typography>{vendor.name}</Typography>
                            </MuiTableCell>
                            <MuiTableCell align="right">
                              <Typography>{vendor.employees.length}</Typography>
                            </MuiTableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="card scroll" style={{ height: "400px" }}>
              <div className="card-body">
                <h3 className="text-center">Tickets Summary</h3>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableCell>Members</TableCell>
                      <TableCell align="right">Tickets expect closed</TableCell>
                    </TableHead>
                    <TableBody>
                      {ticketsSummary.map((item, index) => (
                        <TableRow key={index}>
                          <MuiTableCell>
                            <Typography>{item.name}</Typography>
                          </MuiTableCell>
                          <MuiTableCell align="right">
                            <Typography>{item.value}</Typography>
                          </MuiTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="scroll" style={{ height: "400px" }}>
              <div className="card">
                <div className="card-body">
                  <h3 className="text-center">Project Log</h3>
                  <ProjectLog projectId={project.id} />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className="card">
              <div className="card-body">
                <div className="d-flex" style={{ justifyContent: "space-evenly" }}>
                  <div>
                    <GiCheckeredFlag size={100} />
                  </div>
                  <div>
                    <h4 className="text-center">Project launched date</h4>
                    <Typography className="text-center" variant="h6">
                      {format(new Date(project.startdate), "MMMM dd, yyyy")}
                    </Typography>
                    <h3 className="text-center">
                      {differenceInDays(new Date(), project.startdate)} Days
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="card">
              <div className="card-body">
                <h4 className="text-center">Total Tickets</h4>
                <h1 className="text-center">{totalTickets}</h1>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="card">
              <div className="card-body">
                <h4 className="text-center">Labels</h4>
                <h1 className="text-center">
                  {Object.entries(project.labels).filter(([key, value]) => value.isExist).length}
                </h1>
              </div>
            </div>
          </Grid>
          {/* <Grid item xs={4}>
            <Grid container spacing={1}></Grid>
          </Grid> */}
          <Grid item xs={12}>
            <div className="card">
              <div className="card-body">
                <h4 className="text-center">Project Health</h4>
                {projectHealth >= 0.25 ? (
                  <Alert severity="error">
                    <h3>Critical</h3>
                  </Alert>
                ) : (
                  <Alert severity="info">
                    <h3>Normal</h3>
                  </Alert>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="card">
              <div className="card-body">
                <h3 className="text-center">Project Summary</h3>

                <CustomKeyValuePairs
                  data={[
                    { name: "Start Date", value: validate.dateFormatter(project.startdate) },
                    { name: "End Date", value: validate.dateFormatter(project.enddate) },
                    { name: "Project Leader", value: supervisors.length ? supervisors[0] : "--" },
                  ]}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Presentation
