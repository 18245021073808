import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firebase-firestore"
import "firebase/storage"
import frontendKeyConfigurations from "./frontend-key-configurations.json"

const app = firebase.initializeApp(frontendKeyConfigurations.firebaseConfig)

const storage = firebase.storage()

export { storage, app as default }
