import React, { useEffect } from 'react';
import { Card, Row, Col, Modal, Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MailingAddressEditModal from './mailingaddress';
import { getEmployee } from '../../../../../../api/services/default/employee';
import { useQuery } from '@tanstack/react-query';
import { Skeleton } from '@material-ui/lab';



const MailingAdress = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const {employeeID ,isPersonal} = props 
    const { data: employeeData = {} } = useQuery({
        queryKey: ["getEmployee", employeeID],
        queryFn: () => getEmployee(employeeID),
        enabled: !!employeeID,
      })
      useEffect(() => {}, [employeeData]);
    return (
        <>
        {Object.values(employeeData).length ? (
        <>
            <MailingAddressEditModal
                show={modalShow}
                country={employeeData?.mailingaddress?.country}
                employeeID={employeeID}
                data={employeeData?.mailingaddress}
                onHide={() => setModalShow(false)}
            />
            <Card>
                <Card.Body>
                    <div className="d-flex justify-content-between">
                        <h5 className="card-title mb-3 header-title">Mailing Address</h5>
                        {
                            isPersonal ? 
                            <></> 
                            :
                            <Link to="#" className="linkbtn" onClick={() => setModalShow(true)}><i className="fa-solid fa-pen"></i></Link>
                        }
                    </div>
                    <Row>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">Line 1</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.mailingaddress?.line1}</div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">Line 2</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.mailingaddress?.line2}</div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">State</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.mailingaddress?.state}</div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">Country</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.mailingaddress?.country}</div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">City</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.mailingaddress?.city}</div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">Zip Code</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.mailingaddress?.zip}</div>
                            </div>
                        </Col>
                       
                    </Row>
                </Card.Body>
            </Card>
        </>
          ) : (
            <Skeleton variant="rounded" height={260} />
        )}
        </>
    );
};
export default MailingAdress;