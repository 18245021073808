import React from "react";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
// import { DataContext } from "../../../../../contexts/data"
import { Link } from "react-router-dom";
import Validation from "../../../../../../shared/validation";
import { configuration } from "../../../../../../config/companyConfig";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import MaterialTable from "material-table";
import { Tooltip, Typography, withStyles } from "@material-ui/core";
import { differenceInDays } from "date-fns";
import { useStyles, customStyles } from "../../../../Styles/SubTaskTableStyles";
import MetaInfo from "../../../../../../shared/getMetaInfo";

export default function DenseTable(props) {
  const classes = useStyles();
  let rows = [];
  const { task, tasksList, project } = props;
  // const labels = props.labels
  // const [state] = useContext(DataContext)
  const taskId =props.taskId;
  // const projectId = state.projectId
  let Uselabels = project[0].useLabels;
  const projectLabels = Object.values(project[0].labels).filter(
    (e) => e.isExist
  );
  tasksList.forEach((task) => {
    if (task.taskId === taskId) rows.push(task);
  });
  let taskList = tasksList;
  dayjs.extend(relativeTime);
  const diff = dayjs();
  const getDueBy = (enddate, status) => {
    if (status === "Completed" || status === "Closed") return false;
    return new Date() - new Date(enddate) >= 0 ? true : false;
  };
  const formatter = (date) => {
    let final = "";
    try {
      final = Intl.DateTimeFormat(
        configuration["date-code"],
        configuration.dateformat
      ).format(new Date(date));
    } catch (error) {
      final = date;
    }
    return final;
  };
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);
  // const validate = {
  //   emailToName: (uid) => {
  //     try {
  //       return state.names.filter((item) => item.uid === uid)[0].name
  //     } catch (error) {
  //       console.log(error)
  //       return uid
  //     }
  //   },
  // }
  const validate = new Validation();
  const metaInfo = new MetaInfo();
  dayjs.extend(relativeTime);

  const formatDueBy = (enddate, status) => {
    if (getDueBy(enddate, status)) {
      const days = differenceInDays(
        new Date().setHours(0, 0, 0, 0),
        new Date(enddate).setHours(0, 0, 0, 0)
      );
      if (days === 1) return days.toString() + " day";
      return days.toString() + " days";
    }
    return "---";
  };
  let data = [];
  // const projectLabels = state.projectList.filter(item => item.id === state.project.id)[0].labels
  rows &&
    rows.forEach((task) => {
      data.push({
        title: task.title,
        id: task.id,
        cid: task.cid,
        startdate: task.startdate,
        enddate: task.enddate,
        status: task.status,

        assignee: task.assignee && task.assignee.map((e) => e),
        createdByName: metaInfo.emailToName(task.createdBy),
        type: task.type,
        labels: projectLabels.filter(
          (item) => task.labels && task.labels.includes(item.id)
        ),
        createdBy: task.createdBy,
        projectTaskId: project[0].cid + " - " + task.cid,
        category: task.category,
        priority: task.priority,
        taskId: task.category === "subtask" ? task.taskId : "",
        dueby: formatDueBy(task.enddate, task.status),
        projectId: project[0].id,
      });
    });
  const columns = [
    {
      title: "TaskId",
      field: "TaskId",
      render: (rowData) => {
        return (
          <Chip size="small" label={project[0].cid + " - " + rowData.cid} />
        );
      },
    },
    {
      title: "Labels",
      field: "Labels",
      render: (rowData) => {
        return rowData.labels.length === 0
          ? ""
          : rowData.labels.map((element) => (
              <span>
                <Chip
                  size="small"
                  style={{
                    backgroundColor: `${element.colorCode}`,
                    color: "white",
                    marginBottom: "2px",
                  }}
                  label={element.name}
                />
                <br />
              </span>
            ));
      },
    },
    {
      title: "Title",
      field: "title",
      render: (rowData) => {
        let row = rowData;
        return (
          <div style={{ display: "flex" }}>
            <span>
              {row.category === "task" ? (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography className="text-center" color="inherit">
                        {row.title}
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <Link
                    to={"/console/projects/" + row.projectId + "/" + row.id}
                    style={{ marginLeft: "4px" }}
                  >
                    {row.title.trim().length > 35 ? (
                      <Typography variant="body1">
                        {row.title.trim().substring(0, 35) + "..."}
                        <br />
                      </Typography>
                    ) : (
                      <Typography variant="body1">
                        {row.title.trim()}
                        <br />
                      </Typography>
                    )}
                  </Link>
                </HtmlTooltip>
              ) : (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography className="text-center" color="inherit">
                        {row.title}
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <Link
                    to={"/console/projects/" + row.projectId + "/" + row.id}
                    style={{ marginLeft: "4px" }}
                  >
                    {row.title.trim().length > 35 ? (
                      <Typography variant="body1">
                        {row.title.trim().substring(0, 35) + "..."}
                        <br />
                      </Typography>
                    ) : (
                      <Typography variant="body1">
                        {row.title.trim()}
                        <br />
                      </Typography>
                    )}
                  </Link>
                </HtmlTooltip>
              )}
              <p style={{ fontSize: "14px" }}>
                {row.type === "Task" ? (
                  <Chip
                    size="small"
                    style={{ backgroundColor: "#17a2b8", color: "white" }}
                    label="Task"
                  />
                ) : (
                  <Chip
                    size="small"
                    style={{ backgroundColor: "#db2828", color: "white" }}
                    label="Bug"
                  />
                )}
                &nbsp;&nbsp;&nbsp;
                {row.status === "Open" ? (
                  <Chip
                    variant="outlined"
                    size="small"
                    style={{
                      borderColor: "#21ba45",
                      fontWeight: "bold",
                      color: "#21ba45",
                    }}
                    label="Open"
                  />
                ) : row.status === "InProgress" ? (
                  <Chip
                    variant="outlined"
                    size="small"
                    style={{
                      borderColor: "#f78a14",
                      fontWeight: "bold",
                      color: "#f78a14",
                    }}
                    label="InProgress"
                  />
                ) : (
                  <Chip variant="outlined" size="small" label="Closed" />
                )}
                &nbsp;&nbsp;&nbsp;
                {formatter(row.startdate)}
                &nbsp;&nbsp;&nbsp;
                {formatter(row.enddate)}
              </p>
            </span>
          </div>
        );
      },
      width: "auto",
    },
    {
      title: "CreatedBy",
      field: "createdByName",
    },
    {
      title: "Due Passed By ",
      field: "dueby",
      defaultSort: "asc",
    },
    { title: "Priority", field: "priority" },
    {
      title: "Assignee",
      field: "Assignee",
      render: (rowData) => {
        let row = rowData;
        return (
          row.assignee &&
          row.assignee.map((employee) => (
            <span>
              <Link to={"/console/employees/" + employee}>
                <Button
                  type="a"
                  style={
                    Object.values(project[0].Users)
                      .map((user) => user.uid)
                      .includes(employee)
                      ? customStyles.employeeExist
                      : customStyles.employeeNonExist
                  }
                  size="small"
                >
                  {metaInfo.emailToName(employee)}
                </Button>
              </Link>
              <br />
            </span>
          ))
        );
      },
    },
  ];
  let updatedColumns = columns.filter((column) => column.title !== "Labels");
  if (rows.length > 0)
    return (
      <div>
        <MaterialTable
          title=""
          columns={Uselabels ? columns : updatedColumns}
          data={data}
          parentChildData={(row, rows) =>
            (taskList.find((a) => a.id === taskList.taskId))
          }
          style={{ width: "100%" }}
        />
        {/* <Tasktable
      taskList={rows}
      type='subtask'
      /> */}
      </div>
    );
 

  return <div></div>;
}
