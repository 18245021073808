import React from 'react'
import {
  Grid,
  Paper
} from '@material-ui/core'
import MetaInfo from "../../../../../shared/getMetaInfo";
import parser from "html-react-parser"

function Presentation({ state }) {
  const metaInfo = new MetaInfo();
  const { isApproved, isRejected, approvedDetails = {}, rejectedDetails = {},reportingManager} = state.viewingTimesheet
  console.log(reportingManager)
  const statusFormat = (status) => {
    const classPair = {
      approved: "chip-success",
      pending: "chip-warning",
      rejected: "chip-danger",
      "--": "",
    }
    return (
      <span className={`custom-chip ${classPair[status]}`}>
        {status}
      </span>
    )
  }
  return (
    <div className="mt-4" >
      <Grid container spacing={2}>
      {
          isApproved ?
            <Grid item xs={12}>
              <span className="h6" >
                Reporting Manager:
          </span>
              <span>{reportingManager ?  metaInfo.emailToName(reportingManager) : "---"}</span>
            </Grid>
            :
            null
        }
        {
          isRejected ?
            <>
              
              {
                <Grid item xs={12}>
                <span className="h6" >
                  Reporting Manager:  
            </span>
            <span>{reportingManager ?  metaInfo.emailToName(reportingManager) : "---"}</span>

              </Grid>
             
              }
              <Grid item xs={12}>
                <span className="h6" >
                  Reason:
                </span>
                <span>{parser(rejectedDetails.reason ? rejectedDetails.reason : "")}</span>
              </Grid>
            </>

            :
            null
        }
        <Grid item xs={12}>
          <span className="h6" >
            Status:
          </span>
          <span>{statusFormat(isApproved ? "approved" : isRejected ? "rejected" : "pending")}</span>
        </Grid>
        

        

      </Grid>
    </div>
  )
}

export default Presentation
