export default {
  SET_STATE: "SET_STATE",

  LOAD_PROFILE_TEMPLATE_REQ: "LOAD_PROFILE_TEMPLATE_REQ",
  LOAD_PROFILE_TEMPLATE_SUCCESS: "LOAD_PROFILE_TEMPLATE_SUCCESS",
  LOAD_PROFILE_TEMPLATE_FAILURE: "LOAD_PROFILE_TEMPLATE_FAILURE",

  UPDATE_PROFILE_TEMPLATE_REQ: "UPDATE_PROFILE_TEMPLATE_REQ",
  UPDATE_PROFILE_TEMPLATE_SUCCESS: "UPDATE_PROFILE_TEMPLATE_SUCCESS",
  UPDATE_PROFILE_TEMPLATE_FAILURE: "UPDATE_PROFILE_TEMPLATE_FAILURE",

}