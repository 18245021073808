import React, { useState, useContext } from "react";
import make_API_call from "../../../../providers/REST_API";
import firebase from '../../../../config/fbConfig'

const ProfileDashboardContext = React.createContext();
const useProfileDashboardContext = () => useContext(ProfileDashboardContext);

const InitialState = {
  isLoading: false,
  invoiceData: [],
  timesheetsData: [],
  profileData: [],
  deductionData: [],
  suspendedData: [],
  employeeAuditData: [],
  page: 0,
  tabValue: "personalinfo",
};

function ProfileDashboardContextProvider(props) {
  // any new state add here, should be added in the state object and stateSetters object
  const [isLoading, setIsLoading] = useState(InitialState.isLoading);
  const [invoiceData, setinvoiceData] = useState(InitialState.invoiceData);
  const [deductionData, setdeductionData] = useState(
    InitialState.deductionData
  );
  const [timesheetsData, settimesheetsData] = useState(
    InitialState.timesheetsData
  );
  const [profileData, setprofileData] = useState(
    InitialState.profileData
  );
  const [suspendedData, setsuspendedData] = useState(
    InitialState.suspendedData
  );
  const [employeeAuditData, setEmployeeAuditData] = useState(
    InitialState.employeeAuditData
  );
  const [page, setPage] = useState(InitialState.page)
	const [firstDocID, setFirstDocID] = useState(null)
	const [lastDocID, setLastDocID] = useState(null)
  const [tabValue, setTabValue] = useState(InitialState.tabValue);

  const getInvoiceData = async (employeeID) => {
    return make_API_call("get", `/employee/${employeeID}/invoices/dashboard`)
      .then((data) => {
        return setinvoiceData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEmployeeData = async (employeeID) => {
    
    return make_API_call("get", `/employee/${employeeID}/emp_details/dashboard`)
      .then((data) => {
         return setprofileData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDeductionData = async (employeeID) => {
    return make_API_call("get", `/employee/${employeeID}/deductions/dashboard`)
      .then((data) => {
        return setdeductionData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTimesheetsData = async (employeeID) => {
    return make_API_call("get", `/employee/${employeeID}/timesheets/dashboard`)
      .then((data) => {
        return settimesheetsData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSuspendedData = (employeeID) => {
    if (!employeeID) {
      return null;
    }
    return firebase
      .firestore()
      .collection(`EMPLOYEES`)
      .doc(employeeID)
      .collection(`WORK_QUEUE`)
      .doc(`suspended_list_data`)
      .get()
      .then((doc) => {
          return setsuspendedData(doc.data());
        
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEmployeeAudit = async (
    isPageZero = false,
    isNextPage = false,
    isPrevPage = false,
    page,
    pageSize = 10,
    employeeID
  ) => {
    try {
      let payload = {
        isPageZero,
        isNextPage,
        isPrevPage,
        pageSize,
        firstDocID,
        lastDocID,
      };
      setIsLoading(true);
      let data = await make_API_call(
        "post",
        `/employee/${employeeID}/employeeAudit`,
        payload
      );
      setPage(page);
      setEmployeeAuditData(data.employeeAudit);
      setFirstDocID(data.firstDocument);
      setLastDocID(data.lastDocument);
      setIsLoading(false);
      return data.employeeAudit;
    } catch (error) {
      console.error(error);
      // errorMsg(`Failed to load employee audit`)
      let errorInfo = {
        stringifiedError: error?.message,
        service: "Employee Management - Employee Audit",
        functionName: "getEmployeeAudit",
      };
      make_API_call("post", "/errors/report", errorInfo);
      setIsLoading(false);
      throw error;
    }
  };

  const state = {
    isLoading,
    invoiceData,
    deductionData,
	  suspendedData,
    profileData,
    timesheetsData,
    employeeAuditData,
    firstDocID,
    lastDocID,
    page,
    tabValue,
  };

  const stateSetters = {
    setIsLoading,
    setprofileData,
    setinvoiceData,
    setdeductionData,
    settimesheetsData,
	  setsuspendedData,
    setEmployeeAuditData,
    setFirstDocID,
    setLastDocID,
    setPage,
    setTabValue,
  };

  const services = {
    getInvoiceData,
    getDeductionData,
    getEmployeeData,
    getTimesheetsData,
    getSuspendedData,
    getEmployeeAudit
  };

  return (
    <ProfileDashboardContext.Provider value={{ state, stateSetters, services }}>
      {props.children}
    </ProfileDashboardContext.Provider>
  );
}

export { ProfileDashboardContextProvider, useProfileDashboardContext };
