import { createContext, useContext, useState } from 'react'
import axios from 'axios'
import make_API_call from '../../../providers/REST_API'
import firebase from '../../../config/fbConfig'

const HistoryContext = createContext()
const useHistoryContext = () => useContext(HistoryContext)

const initialState = {
	historyList: [],
	modules: [],
	page: 0,
}

function HistoryContextProvider(props) {
	const [isLoading, setIsLoading] = useState(true)
	const [historyList, setHistoryList] = useState(initialState.historyList)
	const [modules, setModules] = useState(initialState.modules)
	const [page, setPage] = useState(initialState.page)

	const [firstDoc, setFirstDoc] = useState(null)
	const [lastDoc, setLastDoc] = useState(null)

	const state = {
		historyList,
		isLoading,
		modules,
		page,
	}

	const stateSetters = {
		setIsLoading,
		setHistoryList,
		setModules,
		setPage,
	}

	const getModules = async () => {
		try {
			setIsLoading(true)
			const response = await axios.get('/employee/modules')
			setModules(response.data)
			setIsLoading(false)
		} catch (error) {
			console.error(error)
			setIsLoading(false)
			const errorInfo = {
				stringifiedError: error?.message,
				service: 'History - getModules',
				functionName: 'getModules',
			}
			make_API_call('post', '/errors/report', errorInfo)
		}
	}

	const getCurrentPage = async (pageSize = 10) => {
		try {
			let ref
			const uId = firebase.auth().currentUser?.uid
			const isAdmin = modules.includes('console-customization')
			if (isAdmin) {
				ref = firebase.firestore().collectionGroup('HISTORY')
			} else {
				ref = firebase.firestore().collection(`EMPLOYEES/${uId}/HISTORY`)
			}

			ref = ref.orderBy('createdAt', 'desc').limit(pageSize)

			const historyQuerySnap = await ref.get()
			if (historyQuerySnap.size > 0) {
				setFirstDoc(historyQuerySnap.docs[0])
				setLastDoc(historyQuerySnap.docs[historyQuerySnap.docs.length - 1])
			}
			setPage(0)
			const data = historyQuerySnap.docs.map((doc) => doc.data())
			setHistoryList(data)
			return data

		} catch (error) {
			console.error(error)
			const errorInfo = {
				stringifiedError: error?.message,
				service: 'History - getModules',
				functionName: 'getModules',
			}
			make_API_call('post', '/errors/report', errorInfo)
		}
	}

	const getNextPage = async (page, pageSize = 10) => {
		try {
			let ref
			const uId = firebase.auth().currentUser?.uid
			const isAdmin = modules.includes('console-customization')
			if (isAdmin) {
				ref = firebase.firestore().collectionGroup('HISTORY')
			} else {
				ref = firebase.firestore().collection(`EMPLOYEES/${uId}/HISTORY`)
			}

			const historyQuerySnap = await ref
				.orderBy('createdAt', 'desc')
				.startAfter(lastDoc)
				.limit(pageSize)
				.get()
			if (historyQuerySnap.size > 0) {
				setFirstDoc(historyQuerySnap.docs[0])
				setLastDoc(historyQuerySnap.docs[historyQuerySnap.docs.length - 1])
			}
			const data = historyQuerySnap.docs.map((doc) => doc.data())
			setHistoryList(data)
			setPage(page)
			return data
		} catch (error) {
			console.error(error)
			const errorInfo = {
				stringifiedError: error?.message,
				service: 'History - getNextPage',
				functionName: 'getNextPage',
			}
			make_API_call('post', '/errors/report', errorInfo)
		}
	}

	const getPrevPage = async (page, pageSize = 10) => {
		try {
			let ref
			const uId = firebase.auth().currentUser?.uid
			const isAdmin = modules.includes('console-customization')
			if (isAdmin) {
				ref = firebase.firestore().collectionGroup('HISTORY')
			} else {
				ref = firebase.firestore().collection(`EMPLOYEES/${uId}/HISTORY`)
			}

			const historyQuerySnap = await ref
				.orderBy('createdAt', 'desc')
				.endBefore(firstDoc)
				.limitToLast(pageSize)
				.get()
			if (historyQuerySnap.size > 0) {
				setFirstDoc(historyQuerySnap.docs[0])
				setLastDoc(historyQuerySnap.docs[historyQuerySnap.docs.length - 1])
			}
			const data = historyQuerySnap.docs.map((doc) => doc.data())
			setHistoryList(data)
			setPage(page)
			return data
		} catch (error) {
			console.error(error)
			const errorInfo = {
				stringifiedError: error?.message,
				service: 'History - getPrevPage',
				functionName: 'getPrevPage',
			}
			make_API_call('post', '/errors/report', errorInfo)
		}
	}

	const services = {
		getModules,
		getCurrentPage,
		getNextPage,
		getPrevPage,
	}

	return (
		<HistoryContext.Provider value={{ state, stateSetters, services }}>
			{props.children}
		</HistoryContext.Provider>
	)
}

export { useHistoryContext, HistoryContextProvider }
