import axios from "axios"
import {
  setListener,
  setStateAction,
  unSubscribeListenerAction,
  loadAllProjectsFailure,
  loadAllProjectsRequest,
  loadAllProjectsSuccess,
  loadClosedProjectsFailure,
  loadClosedProjectsRequest,
  loadClosedProjectsSuccess,
  loadInProgressProjectsFailure,
  loadInProgressProjectsRequest,
  loadInProgressProjectsSuccess,
  loadOverDueProjectsFailure,
  loadOverDueProjectsRequest,
  loadOverDueProjectsSuccess,
  loadProjectRequest,
  loadProjectSuccess,
  loadProjectFailure,
  loadAllDashProjectsFailure,
  loadAllDashProjectsRequest,
  loadAllDashProjectsSuccess,
  loadProjectTimelineRequest,
  loadProjectTimelineSuccess,
  loadProjectTimelineFailure,
} from "../actions/actionCreators";
import {
  waitingMsg,
  stopWaitMsg,
  errorMsg,
  successMsg,
} from "../../../shared/SnackBars/index";
import { JSutils } from "../../../shared/JSutils";
import make_API_call from "../../../providers/REST_API";

export const setState = (obj) => (dispatch) => {
  return dispatch(setStateAction(obj));
};

export const unSubscribeListener = (type) => (dispatch, getState) => {
  const projectsListState = getState().projects.projectsList;
  const unsubscribe = projectsListState[type].listener;
  unsubscribe();
  return dispatch(unSubscribeListenerAction({ type }));
};
export const load_Dash_Projects =
  () =>
  (dispatch, getState, { getFirebase }) => {
    dispatch(loadAllDashProjectsRequest());
    const uid = getState().firebase.auth.uid;
    console.log("entered");
    const subscribe = getFirebase()
      .firestore()
      .collection("PROJECTS")
      .where("isExist", "==", true)
      .onSnapshot(
        (snap) => {
          const data = JSutils._array_to_object(
            snap.docs.map((doc) => doc.data()),
            "cid"
          );
          return dispatch(loadAllDashProjectsSuccess(data));
        },
        (err) => {
          console.error(err);
          const msg = "Failed to load All Projects List";
          errorMsg(msg);
          return dispatch(loadAllDashProjectsFailure(msg));
        }
      );

    dispatch(setListener(subscribe, "allDashProjects"));
  };
export const loadAllProjects =
  () =>
  (dispatch, getState, { getFirebase }) => {
    dispatch(loadAllProjectsRequest());
    const uid = getState().firebase.auth.uid;
    const modules = getState().employee.employeeModules.accessModules;
    const subscribe = getFirebase()
      .firestore()
      .collection("PROJECTS")
      .where("isExist", "==", true)
      .onSnapshot(
        (snap) => {
          const data = JSutils._array_to_object(
            snap.docs
              .map((doc) => doc.data())
              .filter(
                (item) =>
                  uid in item.Users ||
                  modules.includes("console-customization") ||
                  modules.includes("task-management-manager")
              ),
            "cid"
          );
          return dispatch(loadAllProjectsSuccess(data));
        },
        (err) => {
          console.error(err);
          const msg = "Failed to load All Projects List";
          errorMsg(msg);
          return dispatch(loadAllProjectsFailure(msg));
        }
      );

    dispatch(setListener(subscribe, "allProjects"));
  };

export const loadOverDueProjects =
  () =>
  (dispatch, getState, { getFirebase }) => {
    dispatch(loadOverDueProjectsRequest());

    const uid = getState().firebase.auth.uid;
    const modules = getState().employee.employeeModules.accessModules;
    const subscribe = getFirebase()
      .firestore()
      .collection("PROJECTS")
      .onSnapshot(
        (snap) => {
          const data = JSutils._array_to_object(
            snap.docs
              .map((doc) => doc.data())
              .filter(
                (item) =>
                  (uid in item.Users ||
                    modules.includes("console-customization") ||
                    modules.includes("task-management-manager")) &&
                  item.isExist &&
                  item.status === "Open" &&
                  new Date(item.enddate) < new Date() &&
                  new Date(item.startdate) < new Date()
              ),
            "cid"
          );
          return dispatch(loadOverDueProjectsSuccess(data));
        },
        (err) => {
          console.error(err);
          const msg = "Failed to load OverDue Projects List";
          errorMsg(msg);
          return dispatch(loadOverDueProjectsFailure(msg));
        }
      );

    dispatch(setListener(subscribe, "overDueProjects"));
  };

export const loadInProgressProjects =
  () =>
  (dispatch, getState, { getFirebase }) => {
    dispatch(loadInProgressProjectsRequest());
    const uid = getState().firebase.auth.uid;
    const modules = getState().employee.employeeModules.accessModules;
    const subscribe = getFirebase()
      .firestore()
      .collection("PROJECTS")
      .where("isExist", "==", true)
      .where("status", "==", "Open")
      .onSnapshot(
        (snap) => {
          const data = JSutils._array_to_object(
            snap.docs
              .map((doc) => doc.data())
              .filter(
                (item) =>
                  uid in item.Users ||
                  modules.includes("console-customization") ||
                  modules.includes("task-management-manager")
              ),
            "cid"
          );
          return dispatch(loadInProgressProjectsSuccess(data));
        },
        (err) => {
          console.error(err);
          const msg = "Failed to load InProgress Projects List";
          errorMsg(msg);
          return dispatch(loadInProgressProjectsFailure(msg));
        }
      );

    dispatch(setListener(subscribe, "inProgressProjects"));
  };

export const loadClosedProjects =
  () =>
  (dispatch, getState, { getFirebase }) => {
    dispatch(loadClosedProjectsRequest());
    const uid = getState().firebase.auth.uid;
    const modules = getState().employee.employeeModules.accessModules;
    const subscribe = getFirebase()
      .firestore()
      .collection("PROJECTS")
      .where("isExist", "==", true)
      .where("status", "==", "Closed")
      .onSnapshot(
        (snap) => {
          const data = JSutils._array_to_object(
            snap.docs
              .map((doc) => doc.data())
              .filter(
                (item) =>
                  uid in item.Users ||
                  modules.includes("console-customization") ||
                  modules.includes("task-management-manager")
              ),
            "cid"
          );
          return dispatch(loadClosedProjectsSuccess(data));
        },
        (err) => {
          console.error(err);
          const msg = "Failed to load Closed Projects List";
          errorMsg(msg);
          return dispatch(loadClosedProjectsFailure(msg));
        }
      );

    dispatch(setListener(subscribe, "closedProjects"));
  };

export const loadProject =
  (payload) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch(loadProjectRequest());
    const { projectID } = payload;
    const subscribe = getFirebase()
      .firestore()
      .collection("PROJECTS")
      .doc(projectID)
      .onSnapshot(
        (snap) => {
          const data = snap.data();
          return dispatch(loadProjectSuccess(data));
        },
        (err) => {
          console.error(err);
          const msg = "Failed to load  Project";
          errorMsg(msg);
          return dispatch(loadProjectFailure(msg));
        }
      );

    dispatch(setListener(subscribe, "projectData"));
  };
export const loadProjectTimeLine =
  (payload) =>
  (dispatch, getState, { getFirebase }) => {
    console.log("Project-Timeline");
    dispatch(loadProjectTimelineRequest());
    const { projectID } = payload;
    const subscribe = getFirebase()
      .firestore()
      .collection("PROJECTS")
      .doc(projectID)
      .collection("PROJECT_TIMELINE")
      .orderBy("createdAt", "desc")
      .onSnapshot(
        (snap) => {
          const data = JSutils._array_to_object(
            snap.docs.map((doc) => doc.data()),
            "id"
          );
          return dispatch(loadProjectTimelineSuccess(data));
        },
        (err) => {
          console.error(err);
          const msg = "Failed To Load  Project Timeline";
          errorMsg(msg);
          return dispatch(loadProjectTimelineFailure(msg));
        }
      );

    dispatch(setListener(subscribe, "projectTimeLineData"));
  };
