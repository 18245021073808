import React from "react";
import {
  Button,
  Grid,
  TableCell as MuiTableCell,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import NewProject from '../../../NewProject'
function Presentation(props) {
  const { state } = props
  const projectsList = Object.values(state["allDashProjects"].data)
  
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className="d-flex justify-content-between">
            <div className="card">
              <div className="card-body">
                <h3 style={{ textAlign: "center" }}> TaskManagement</h3>
              </div>
            </div>
            <div>
              <div className="d-flex">
                <div>
                  <NewProject projectsList={projectsList}/>
                  {/* <Link>
                    <Button variant="contained" color="primary">
                      add a new project
                    </Button>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Presentation;
