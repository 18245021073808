const initState = {
  accessModules: [],
  isFetching: false,
  error: null,
  allModules: null,
  isLoaded: false,
  isEmpty: false,
}

export default initState
