import React, { useEffect, useState } from 'react'
import {
  Grid,
  Button,
  TextField,
  MenuItem,
  Tooltip,
  IconButton,
  AppBar,
  Dialog,
  Typography,
  FormControlLabel,
  Checkbox,
  Paper,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  List,
  FormHelperText,
  Tab,
  Tabs,
  DialogContent
} from "@material-ui/core"
import AddIcon from '@material-ui/icons/Add';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import styles from "../../styles/dialogStyles"
import { useBulkPaymentsContext } from './context';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  InfoOutlined
} from "@material-ui/icons"

function AddDiscount(props) {
  const { openedDialog, invoiceID, closeDialog, openDialog, invoicedAmount } = props
  const open = openedDialog === invoiceID
  const { state, services, stateSetters } = useBulkPaymentsContext()
  const [theDiscount, setTheDiscount] = useState(state.discountDivisions?.[invoiceID] || [])

  useEffect(() => {
    if (state.discountDivisions?.[invoiceID]?.length)
      setTheDiscount(JSON.parse(JSON.stringify(state.discountDivisions[invoiceID])))
  }, [JSON.stringify(state.discountDivisions[invoiceID])])

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const setDiscountsState = (discountDetails) => {
    // stateSetters.setDiscountDivisions((prev) => ({ ...prev, [invoiceID]: discountDetails }))
    console.log(discountDetails);
    setTheDiscount(JSON.parse(JSON.stringify(discountDetails)))
  }

  const onAddDiscount = () => {
    let discountDetails = theDiscount || []
    discountDetails = [
      ...discountDetails,
      {
        name: "",
        value: 0,
        type: "",
      },
    ]
    setDiscountsState(discountDetails)
  }

  const onRemoveDiscount = (index) => {
    let discountDetails = []
    discountDetails = theDiscount.filter((_, ind) => index !== ind)
    setDiscountsState(discountDetails)
  }

  const handleDiscountDetails = (event, index) => {
    const { name, value } = event.target
    let data = theDiscount
    if (index <= data.length - 1) {
      if (name === "value") {
        if (value === "") {
          data[index]["value"] = "";
        } else {
          data[index][name] = Number(value)
        }
      } else {
        if (name === "type")
          data[index]["value"] = "";
        data[index][name] = value;
      }
    }
    setDiscountsState(data)
  }

  useEffect(() => {
    if (open && (state?.discountDivisions?.[invoiceID] || [])?.length < 1) {
      onAddDiscount()
    }
  }, [open])

  const onSubmitDiscountForm = () => {
    stateSetters.setDiscountDivisions((prev) => ({ ...prev, [invoiceID]: theDiscount }))
    closeDialog()
  }

  return (
    <div>
      <IconButton variant="contained" color="primary" onClick={() => openDialog(invoiceID)}>
        <AddIcon />
      </IconButton>
      <Dialog
        onClose={closeDialog}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={closeDialog}>
          Add Discount {" "}
          <span className="custom-chip chip-warning">{`${invoiceID}`}</span>
        </DialogTitle>
        <DialogContent dividers>
          <List dense>
            {
              theDiscount?.map((item, index) => {
                return (
                  <ListItem style={{ marginTop: '5px' }} >
                    <ListItemIcon>
                      <div className="d-flex flex-row">
                        <div className="mr-1">
                          <TextField
                            size="small"
                            variant="outlined"
                            value={item["name"]}
                            name="name"
                            onChange={(e) => handleDiscountDetails(e, index)}
                            label="Name"
                            fullWidth
                            style={{ width: '120px' }}
                          />
                        </div>
                        <div className="mr-1 mb-1">
                          <TextField
                            size="small"
                            label="Select"
                            variant="outlined"
                            disabled={item.name === ""}
                            value={item["type"]}
                            onChange={(e) => handleDiscountDetails(e, index)}
                            select
                            style={{ width: '100px' }}
                            name="type"
                          >
                            <MenuItem value="byValue">By Value</MenuItem>
                            <MenuItem value="byPercentage">By Percentage</MenuItem>
                          </TextField>
                        </div>
                        <div className="mr-1">
                          <TextField
                            size="small"
                            type="number"
                            variant="outlined"
                            disabled={item.type === ""}
                            value={item["value"]}
                            onChange={(e) => {
                              const val = Number(e.target.value)
                              if (
                                (theDiscount[index]["type"] === "byPercentage" &&
                                  val < 100) ||
                                theDiscount[index]["type"] === "byValue"
                              ) {
                                theDiscount[index]["value"] = val
                                handleDiscountDetails(e, index)
                              }
                            }}
                            label="Discount"
                            name="value"
                            style={{ width: '120px' }}
                          />
                        </div>
                        <div className="d-flex">
                          {theDiscount.length > 1 ? (
                            <div>
                              <Tooltip title="Remove this discount">
                                <IconButton onClick={() => onRemoveDiscount(index)}>
                                  <RemoveCircleOutlineIcon
                                    className="text-danger"
                                    fontSize="small"
                                  />
                                </IconButton>
                              </Tooltip>
                            </div>
                          ) : null}
                          {index === theDiscount.length - 1 ? (
                            <div>
                              <Tooltip title="Add multiple discounts">
                                <IconButton onClick={onAddDiscount}>
                                  <AddCircleOutlineIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </ListItemIcon>
                    <ListItemSecondaryAction>
                      <h3 style={{ fontWeight: 500, width: '100px', textAlign: 'right' }}>
                        $ {services.calculateDiscount(invoicedAmount, [theDiscount[index]])}
                      </h3>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })
            }
          </List>
          <div className='d-flex justify-content-spacearound' >
            <Button
              variant={'contained'}
              color={'secondary'}
              size='small'
              onClick={closeDialog}
            >
              Cancel
            </Button>
            <Button
              variant={'contained'}
              color={'primary'}
              size='small'
              onClick={onSubmitDiscountForm}
            >
              Add
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default AddDiscount
