import React, { useCallback, useEffect, useState } from "react";
import FlairTable, { convertMaterialToAgGridColumns } from "../../shared/FlairTable";
import {  Typography } from "@material-ui/core";
// import CommentHoverCard from "./commentHoverCard";
import "./styles.css";
import { Link } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import {
  Button,
  Badge,
  Row,
  Col,
} from "react-bootstrap";
import * as yup from "yup";
import SelectFormField from "../../shared/components/BootStrapFormFields/SelectFormField";
import { FiUsers  } from "react-icons/fi";
import {RiExchangeDollarLine } from "react-icons/ri";
import { useQuery } from "@tanstack/react-query";
import { getAllCertifications, getAllSkills, getEmployeesSkillData } from "../../api/services/default/resourcemanagement";
import { Constants } from "../../shared/constants";
import CommentHoverCard from "./commentHoverCard";


const schema = yup.object().shape({
  billableClient: yup.array().of(yup.string()),
  endClient: yup.array().of(yup.string()),
  clientDomain: yup.array().of(yup.string()),
  skills: yup.array().of(yup.string()),
  certifications: yup.array().of(yup.string()),
});



function ResourceManagementGroupList() {
 const initialValues = { 
  billableClient: [],
  endClient: [],
  clientDomain: [],
  certifications: [],
  ratings: [],
}
  const [filteredData, setFilteredData] = useState([]);
  const [TableData, setTableData] = useState([]);
  const tableRef = React.useRef();

   useQuery({
    queryKey: ["getAllSkills"],
    queryFn: () => getAllSkills(),
    onSuccess(data) {
      const prevColumns = tableRef.current?.getColumnDefs();
      const updatedColumns = prevColumns.filter((column) => column.field !== "skills");
      const newColumns = [
        ...updatedColumns,
        ...convertMaterialToAgGridColumns([{
          title: "Skills",
          field: "skills",
          children: data?.skills?.map((skill) => {
            return {
              field: skill,
              columnGroupShow: data?.skills[0] === skill ? "closed" : "open",
              filtering: false,
              cellRendererFramework: (rowData) => {
                const colData = rowData?.data?.skills;
                const skillData = colData?.find((item) => item?.skill === skill);
                if(!skillData) return null;
                return (
                  <div style={{paddingTop:3}}>
                     <CommentHoverCard
                       comment={skillData?.review}
                       value={skillData?.rating}
                     />
                    </div>
                )
              }
            }
          }),
        }]),
      ];
      tableRef.current?.setColumnDefs(newColumns);
    },
   })
   const { data : allCertifications } = useQuery({
    queryKey: ["getAllCertifications"],
    queryFn: () => getAllCertifications(),
   })

  const { isLoading } = useQuery({
    queryKey: ["getEmployeesSkillData"],
    queryFn: () => getEmployeesSkillData(),
    onSuccess: (data) => {
      const groupedByEmployee = data?.reduce((acc, employee) => {
        const { employeeDetails, billableClient, endClient, clientDomain, placementId } = employee;
        const existingEmployee = acc.find(
          (emp) => emp.employeeDetails.id === employeeDetails.id
        );
        if (existingEmployee) {
          existingEmployee.clients = [
            ...existingEmployee.clients,
            { billableClient, endClient, clientDomain,placementId },
          ];
        } else {
          acc.push({ employeeDetails, clients: [{ billableClient, endClient, clientDomain,placementId }] });
        }
        return acc;
      }, []);
      const TableData = groupedByEmployee?.map((item) => {
        const filteredClients = item.clients.filter(client => client?.endClient?.name && client?.billableClient?.name );
        if (
          !item?.employeeDetails?.name ||
          filteredClients.length === 0 
        ) {
          return null;
        }
        return {
          employeeId :item.employeeDetails.id,
          allCertificationsData : allCertifications?.certifications,
          certifications : item.employeeDetails.certifications,
          skills: item.employeeDetails.skills,
          placementId : filteredClients.map(client => client?.placementId),
          businessDisplayName: item.employeeDetails.name,
          endClient: filteredClients.map(client => client.endClient.name),
          billableClient: filteredClients.map(client => client.billableClient.name),
          clientDomain: filteredClients.map(client => client.clientDomain),
          allSkillsData : "",
        };
      }).filter(Boolean);
     
      setFilteredData(TableData);
      setTableData(TableData);
    }
  })
 
  
  const columns = [
    {
      title: "Employee Name",
      field: "businessDisplayName",
      dataType: "String",
      render: (rowData) => {
        return (
          <Link  to={"/console/employees/" + rowData.employeeId}>
            <Typography>{rowData.businessDisplayName}</Typography>
          </Link>
        );
      },
    },
    {
      title: "Client Domain",
      field: "clientDomain",
      dataType: "String",
      filtering: false,
     // headerClass: ".rotate-header .text-header",
      // width: 160,
      // cellStyle: {
      //   width: 150,
      //   minWidth: 150,
      //   whiteSpace: "normal",
      // },
      render: (rowData) => {
        const data = rowData.clientDomain;
        return (
          <div>
            {data.map((item, index) => (
             item ? 
          <div key={index}>   
            <Badge
          component="div"
          pill={true}
          style={{ padding: 6, fontSize: 12}}
          bg="primary"
          key={index}
        >
          {item}
        </Badge> </div>
           :
         <div>
      
          <Badge
             component="div"
             pill={true}
             style={{ padding: 6, fontSize: 12}}
            bg="transparent"
             key={index}
           >
             No Domain
           </Badge>
         </div>
            ))}
          </div>
        );
      },
    },
    {
      title: "Placement Number",
      field: "placementId",

      render: (rowData) => {
        const data = rowData.placementId;
        return (
          <div>
            {data.map((item, index) => (
            <div key={index}>
            <Link
            key={index}
              to={"/console/placements/" + rowData.employeeId + "/" + item}
            >
            <Badge
             component="div"
             pill={true}
            style={{ padding: 6, fontSize: 12}}
             bg="primary"
           >
             {item?.toString()}
           </Badge> 
          </Link> 
            </div>
               
            ))}
          </div>
        );
      },
    },
    {
      title: "End Client",
      field: "endClient",
      dataType: "String",
      render: (rowData) => {
        const data = rowData.endClient;
        return (
          <div>
            {data.map((item, index) => (
              <div key={index} >
                <div>  <FiUsers  size={18} style={{marginTop:3,marginRight:8}}/>{item}</div>
                </div>
            ))}
          </div>
        );
      },
    },
    {
      title: "Billable Client",
      field: "billableClient",
      dataType: "String",
      render: (rowData) => {
        const data = rowData.billableClient;
        return (
          <div>
          {data.map((item, index) => (
            <div key={index} >
              <div> <RiExchangeDollarLine className="text-danger" size={20} style={{marginTop:3,marginRight:8}}/>{item}</div>
              </div>
          ))}
        </div>
         
        );
      },
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
    },
    {
      title: "Certifications",
      field: "certifications",
      dataType: "String",
      render: (rowData) => {
        const certifiedColumnData = rowData?.certifications;
        const certifiedData = certifiedColumnData?.filter((item) => {
          return rowData?.allCertificationsData?.includes(item?.category)}
          );  
        return (
          <div>
            {
               certifiedData?.map((item, index) => (
                <div key={index}>
                  <a
                  href={item?.certificatedocument}
                  target="_blank"
                  rel="noreferrer">
                  <i>{item?.category}</i>
                   </a>
                </div>
               ))
            }
          </div>
         
        )
      },
    }
  ];

  const optionMapper = (i) => ({ value: i, label: i });

  const customizedGridOptions = {
    disableQuickFilter: true,
    defaultColDef: {
      sortable: true,
      resizable: true,
    },
  };

  const uniqueClientDomains = TableData?.flatMap((item) =>
    item?.clientDomain?.map((item) => item)?.filter(Boolean)
  );
  const uniqueBillableClients = TableData?.flatMap((item) =>
    item?.billableClient?.map((item) => item)?.filter(Boolean)
  );
  const uniqueEndClients = TableData?.flatMap((item) =>
    item?.endClient?.map((item) => item)?.filter(Boolean)
  );
  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    ListFilter(values);
  };

  const ListFilter = (values) => {
    const { billableClient, endClient, clientDomain,certifications, ratings } = values;
    const filteredData = TableData?.filter((item) => {
      const isBillableClientMatched = billableClient?.every((billableClient) =>
        item.billableClient?.some(
          (itemBillableClient) => itemBillableClient === billableClient
        )
      );
      const isEndClientMatched = endClient?.every((endClient) =>
        item.endClient.some((itemEndClient) => itemEndClient === endClient)
      );
      const isClientDomainMatched = clientDomain?.every((clientDomain) =>
        item.clientDomain?.some(
          (itemClientDomain) => itemClientDomain === clientDomain
        )
      );
      const isCertificationMatched = certifications?.every((certificate) =>
        item.certifications.some((item) => item.category === certificate)
      );
      const isRatingMatched = ratings?.every((rating) =>
        item?.skills?.some((item) => item.rating === rating)
      );
      return (
        isBillableClientMatched && isEndClientMatched && isClientDomainMatched && isCertificationMatched && isRatingMatched
      );
    });
    
    setFilteredData(filteredData);
  };

  const getRowHeight = useCallback((params) => {
    const data = params.data;
    let maxHeight = 0;
    for (let i = 0; i < columns?.length; i++) {
      const colDef = columns[i];
      const colData = data[colDef.field];
      const lineHeight = 35;
      if (Array.isArray(colData)) {
        const height = colData?.length * lineHeight;
        if (height > maxHeight) maxHeight = height;
      }
    }
    return maxHeight;
  }, []);

  return (
    <div>
      <div>
          <Formik
            validationSchema={schema}
            initialValues={{
              ...initialValues,
            }}
            onSubmit={handleSubmit}
            onReset={(e) => console.log('[onReset]', e)}
          >
            {({ resetForm, handleReset }) => {
              return (
                <Form>
                  <Row>
                    <Col xl={2}>
                      <Field
                        name="clientDomain"
                        type="select"
                        label="Client Domain"
                        isMulti={true}
                        options={[...new Set(uniqueClientDomains)]?.map(
                          optionMapper
                        ) || []}
                        component={SelectFormField}
                      />
                    </Col>
                    <Col xl={2}>
                      <Field
                        name="billableClient"
                        type="select"
                        label="Billable Client"
                        isMulti={true}
                        options={[...new Set(uniqueBillableClients)].map(
                          optionMapper
                        )|| []}
                        component={SelectFormField}
                      />
                    </Col>
                    <Col xl={2}>
                      <Field
                        name="endClient"
                        type="select"
                        label="End Client"
                        isMulti={true}
                        options={[...new Set(uniqueEndClients)].map(
                          optionMapper
                        )|| []}
                        component={SelectFormField}
                      />
                    </Col>
                    <Col xl={2}>
                      <Field
                        name="certifications"
                        type="select"
                        label="Certifications"
                        isMulti={true}
                        options={allCertifications?.certifications?.map(
                          optionMapper
                        )|| []}
                        component={SelectFormField}
                      />
                    </Col>
                    <Col xl={2}>
                      <Field
                        name="ratings"
                        type="select"
                        label="Ratings"
                        isMulti={true}
                        options={Constants?.SKILL_RATINGS}
                        component={SelectFormField}
                      />
                    </Col>
                    <Col xl={2}>
                      <div style={{justifyContent:'space-evenly', display:'flex'}} >
                      <Button
                        type="submit"
                        style={{ marginTop: "24px" }}
                        className="btn btn-success px-10 btn-md"
                      >
                        Filter
                      </Button>
                      <Button
                        type="button"
                        onClick={(e) => {
                          setFilteredData(TableData);
                          resetForm({
                            values: initialValues
                          })
                        }}
                        style={{ marginTop: "24px" }}
                        className="btn btn-success px-10 btn-md"
                      >
                        Reset
                      </Button>
                      </div>
                     
                    </Col>
                    
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </div>
        <FlairTable
          title="Employee Skill Audit"
          data={filteredData}
          tableRef={tableRef}
          columns={columns}
          isLoading={isLoading}
          getRowHeight={getRowHeight}
          hideSearch={true}
         customizedGridOptions={customizedGridOptions}
        />
      </div>
    
  );
}

export default ResourceManagementGroupList;

 