import React, { useEffect } from "react"
import { Button } from "react-bootstrap"
import Dialog from "@material-ui/core/Dialog"
import AppBar from "@material-ui/core/AppBar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import Slide from "@material-ui/core/Slide"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import MenuItem from "@material-ui/core/MenuItem"
import DateFnsUtils from "@date-io/date-fns"
import SunEditor from "suneditor-react"
import Checkbox from "@material-ui/core/Checkbox"
// import { DataContext } from '../../../../../contexts/data'
import FormControlLabel from "@material-ui/core/FormControlLabel"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import Autocomplete from "@material-ui/lab/Autocomplete"
import validate from "../../../../../../shared/validation"
import { addDays } from "date-fns"
import useStyles from "../../../../Styles/NewTaskStyles"
import MetaInfo from "../../../../../../shared/getMetaInfo"
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Presentation(props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const {
    text,
    handleCreate,
    handleChange,
    handleDateChange,
    handleDropdown,
    clearValues,
    state,
    project,
    names,
    handleDescription,
    tasksList
  } = props
  // const [state] = useContext(DataContext)
  const handleStartDateChange = (date) => {
    //setSelectedStartDate(date)
    handleDateChange("startdate", date)
  }

  const handleEndDateChange = (date) => {
    //setSelectedEndDate(date)
    handleDateChange("enddate", date)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    clearValues()
  }
  useEffect(() => {
    if (state.isCreated) setOpen(false)
  }, [state.isCreated])
  let labels = Object.values(project.labels).filter((e) => e.isExist)
  const projectAssignee = Object.values(project.Users).filter(e =>names[e.uid].status === "active").map(users => users.uid)
  const checkValidTaskRange = (startdate, enddate) => {
    const initialDateDiff = new Date(startdate) - new Date(project.startdate)
    const finalDateDiff =
      new Date(project.enddate).setHours(0, 0, 0, 0) -
      new Date(enddate).setHours(0, 0, 0, 0)
    return initialDateDiff >= 0 && finalDateDiff >= 0
  }
  // console.log(tasksList)
  //  const taskExists =   tasksList.filter(
  //   (task) =>
  //     task.title.trim().toLowerCase() ===
  //     state.name.trim().toLowerCase()
  // ).length
 const metaInfo = new MetaInfo()
  return (
    <div>
        <Button
          className="btn btn-success px-6 btn-md text-high"        
          onClick={handleClickOpen}
        >
          {text}
        </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        disableEscapeKeyDown
        disableBackdropClick
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <div className="d-flex justify-content-between p-2">
            <div>
              <IconButton
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div>
              <Typography component="h1" variant="h4" align="center" style={{ color: "whitesmoke" }}>
                {text}
              </Typography>
            </div>
            <div></div>
          </div>
        </AppBar>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <React.Fragment>
              <Typography variant="h6" gutterBottom></Typography>
              <form
                onSubmit={(e) => {
                  handleCreate(e,handleClose)
                //  handleClose()
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="tm-new-task-taskname"
                      name="name"
                      label="Name"
                      value={state.name}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      fullWidth
                    />
                     {/* {taskExists ? (
                      <p className="text-danger">
                        This title already exists! choose another title
                      </p>
                    ) : null} */}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="tm-new-task-tasktype"
                      name="type"
                      label="Task type"
                      value={state.type}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      fullWidth
                      select
                      required
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Task">Task</MenuItem>
                      <MenuItem value="Bug">Bug</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="tm-new-task-taskstatus"
                      name="status"
                      label="Status"
                      value={state.status}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      fullWidth
                      select
                      required
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Open">Open</MenuItem>
                      <MenuItem value="InProgress">InProgress</MenuItem>
                      <MenuItem value="Review">Review</MenuItem>
                      <MenuItem value="Closed">Closed</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <KeyboardDatePicker
                            required="required"
                            style={{ marginTop: "-2px" }}
                            fullWidth
                            minDate={project.startdate}
                            maxDate={project.enddate}
                            id="tm-new-task-startdate"
                            label="Start date"
                            format="MM/dd/yyyy"
                            value={state.startdate ? state.startdate : null}
                            onChange={handleStartDateChange}
                            name="startdate"
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <KeyboardDatePicker
                            style={{ marginTop: "-2px" }}
                            fullWidth
                            required
                            maxDate={project.enddate}
                            minDate={state.startdate}
                            value={state.enddate ? state.enddate : null}
                            id="tm-new-task-enddate"
                            label="End date"
                            format="MM/dd/yyyy"
                            onChange={handleEndDateChange}
                            name="enddate"
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="tm-new-task-taskPriority"
                      name="priority"
                      label="Priority"
                      value={state.priority}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      select
                      fullWidth
                      required
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Urgent">Urgent</MenuItem>
                      <MenuItem value="High">High</MenuItem>
                      <MenuItem value="Medium">Medium</MenuItem>
                      <MenuItem value="Low">Low</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      multiple
                      id="tm-new-task-assignees"
                      options={projectAssignee}
                      getOptionLabel={(option) => metaInfo.emailToName(option)}
                      filterSelectedOptions
                      onChange={(e, v) => handleDropdown("assignee", v)}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          label="Add employees *"
                        />
                      )}
                    />
                  </Grid>
                  {project.useLabels ? (
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        required="required"
                        id="tm-new-task-labels"
                        options={labels}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span
                              className={classes.color}
                              style={{ backgroundColor: option.colorCode }}
                            />
                            <div className={classes.text}>{option.name}</div>
                          </React.Fragment>
                        )}
                        filterSelectedOptions
                        onChange={(e, v) =>
                          handleDropdown(
                            "labels",
                            v.map((item) => item.id)
                          )
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} label="Labels" />
                        )}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="tm-new-task-set-reminder"
                              onChange={(e) =>
                                handleChange("setReminder", e.target.checked)
                              }
                            />
                          }
                          label="Set reminder"
                        />
                      </Grid>
                      {state.setReminder ? (
                        <Grid item xs={6}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              required
                              style={{ marginTop: "-2px" }}
                              fullWidth
                              id="tm-new-task-reminder-date"
                              label="Reminder date"
                              format="MM/dd/yyyy"
                              minDate={addDays(new Date(), 1)}
                              maxDate={project.enddate}
                              helperText={
                                isNaN(Date.parse(state.reminderDate))
                                  ? "Select reminder date"
                                  : ""
                              }
                              value={
                                state.reminderDate ? state.reminderDate : null
                              }
                              onChange={(date) =>
                                handleChange("reminderDate", date)
                              }
                              name="reminderDate"
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <SunEditor
                      placeholder="Description"
                      onChange={(data) =>
                        handleDescription("description", data)
                      }
                      setOptions={{
                        buttonList: [
                          [
                            "undo",
                            "redo",
                            "formatBlock",
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                            "fontColor",
                            "hiliteColor",
                            "removeFormat",
                            "outdent",
                            "indent",
                            "align",
                            "horizontalRule",
                            "list",
                            "lineHeight",
                            "table",
                            "link",
                          ],
                        ],
                        mode: "Balloon-always",
                      }}
                    />
                  </Grid>
                </Grid>
                <br />

                <div align="center">
                  <Button
                    variant="success"
                    id="tm-new-task-submit"
                    type="submit"
                    className={classes.submit }
                    disabled={
                      state.setReminder
                        ? state.name &&
                        // !taskExists&&
                          state.type &&
                          state.status &&
                          state.priority &&
                          validate.checkDateDiff(
                            state.startdate,
                            state.enddate
                          ) &&
                          checkValidTaskRange(state.startdate, state.enddate) &&
                          state.reminderDate &&
                          state.assignee.length
                          ? false
                          : true
                        : state.name &&
                        // !taskExists&&
                          state.type &&
                          state.status &&
                          state.priority &&
                          validate.checkDateDiff(
                            state.startdate,
                            state.enddate
                          ) &&
                          checkValidTaskRange(state.startdate, state.enddate) &&
                          state.assignee.length
                        ? false
                        : true
                    }
                  >
                    Create
                  </Button>
                </div>
              </form>
            </React.Fragment>
          </Paper>
        </main>
      </Dialog>
    </div>
  )
}
