import { FormLabel } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { differenceInCalendarDays, eachDayOfInterval } from "date-fns";
import React, { useEffect, useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import { configuration } from "../config/companyConfig";
import { transactionListReducer } from "../Services/Deductions/reducers/transactionsListReducer";
import { getTimesheetsSkeleton, loadAlreadySubmittedTimesheets } from "../Services/Timesheets/middleware";
import { CalendarFormatter } from "../shared/CalendarFormatter";
import {
  checkIfDateLiesBetweenTwoDates,
  generateTimesheetSkeleton,
  getAllDatesFromSubmission,
  getDateRangeFromRanges,
} from "../utils/TimesheetsUtils";

function useTimesheetCalendar(selectionCallback) {
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [dateRanges, setDateRanges] = useState([]);
  const [selectedRange, setRange] = useState([]);
  const [placementSettings, setPlacementSettings] = useState({});
  const [cycles, setCycles] = useState([]);
  const [initialMonth, setInitialMonth] = useState();
  const [disableDaysAfter, setDisableAfter] = useState(new Date());
  const [disableDaysBefore, setDisableBefore] = useState(new Date());
  const [displayMessage, setDisplayMessage] = useState("");
  const [openType, setOpenType] = useState("new");
  const [alreadySubmittedTimesheets, setAlreadySubmittedTimesheets] = useState(
    []
  );
  const [pendingModifiers, setPendingModifiers] = useState([]);
  const [rejectedModifiers, setRejectedModifiers] = useState([]);
  const [approvedModifiers, setApprovedModifiers] = useState([]);

  const modifiersStyles = {
    approved: {
      color: "#66cc00",
      backgroundColor: "#e6ffe6",
    },
    pending: {
      color: "#ffc107",
      backgroundColor: "#fffdee",
    },
    rejected: {
      color: "#e65c00",
      backgroundColor: "#ffe0cc",
    },
  };

  const initializeState = () => {
    setInitialMonth()
    setDisableAfter(new Date())
    setDisableBefore(new Date())
    setDisplayMessage()
    setRange([])
    setDateRanges([])
  }


  const initializeCalendar = async (
    placementInfoSettings,
    timesheetCycles,
    openType = "new",
    timesheetInfo = {}
  ) => {
    setOpenType(openType);
    if (openType === "view" || openType === "edit" || openType === "defaulter") {
      const submittedTimesheetDateRange = eachDayOfInterval({
        start: new Date(timesheetInfo.startDate),
        end: new Date(timesheetInfo.endDate),
      });

      setRange(submittedTimesheetDateRange);
      setInitialMonth(new Date(timesheetInfo.startDate));
      setDisableAfter(new Date(timesheetInfo.endDate));
      setDisableBefore(new Date(timesheetInfo.startDate));
    } else {
      // const c = timesheetCycles.map((t) => ({ ...t, date: new Date(t.date) }));
      // const ranges = generateTimesheetSkeleton(
      //   c,
      //   new Date(placementInfoSettings.endDate)
      // );


      try {
        const skeletonResponse = await getTimesheetsSkeleton(placementInfoSettings.placementID)
        const ranges = skeletonResponse.data
        setPlacementSettings(placementInfoSettings);
        setCycles(timesheetCycles);
        setDateRanges(ranges);
        setInitialMonth(new Date(placementInfoSettings.startDate));
        setDisableAfter(new Date(placementInfoSettings.endDate));
        setDisableBefore(new Date(placementInfoSettings.startDate));

        const alreadySubmitted = await loadAlreadySubmittedTimesheets(
          placementInfoSettings.placementID
        );
        let pending = [],
          rejected = [],
          approved = [],
          submitted = [];
        pending = alreadySubmitted.pending.map(d => new Date(d));
        rejected = alreadySubmitted.rejected.map(d => new Date(d));
        approved = alreadySubmitted.approved.map(d => new Date(d));
        submitted = alreadySubmitted.submitted.map(d => new Date(d));

        setPendingModifiers(pending);
        setRejectedModifiers(rejected);
        setApprovedModifiers(approved);
        setAlreadySubmittedTimesheets(submitted);
      } catch (error) {
        console.error(error);
      }
    }
    setDisplayCalendar(true);
  };

  const handleDateSelection = (date) => {
    if (openType !== "new") return;
    let dateRange = [];
    if (differenceInCalendarDays(new Date(), date) < 0) {
      dateRange = [];
      setDisplayMessage("No date range formed for the selected date");
    } else {
      // check if date is already submitted or not
      const checkIfDateAlreadySubmitted = alreadySubmittedTimesheets.some(
        (submittedDate) =>
          checkIfDateLiesBetweenTwoDates(date, submittedDate, submittedDate)
      );
      if (checkIfDateAlreadySubmitted) {
        dateRange = [];
        setDisplayMessage("Already submitted");
      } else {
        const range = getDateRangeFromRanges(date, dateRanges);
        if (range.startDate === undefined) {
          dateRange = [];
        } else {
          const checkTodayDateIncludesOrNot = checkIfDateLiesBetweenTwoDates(
            new Date(),
            range.startDate,
            range.endDate
          );
          if (checkTodayDateIncludesOrNot) {
            dateRange = [];
            setDisplayMessage("No date range formed for the selected date");
          } else {
            dateRange = eachDayOfInterval({
              start: new Date(range.startDate),
              end: new Date(range.endDate),
            });
            setDisplayMessage("");
          }
        }
      }
    }
    setRange(dateRange);
    selectionCallback(dateRange.map(d => CalendarFormatter.standardDateFormat(d)));
  };

  useEffect(() => {
    console.log(dateRanges);
    if (dateRanges.length > 0) {
      handleDateSelection(new Date());
      setInitialMonth(new Date());
    }
  }, [dateRanges.length])


  const renderCalendar = () => {
    if (displayCalendar)
      return (
        <div>
          <DayPicker
            initialMonth={initialMonth}
            selectedDays={selectedRange}
            showOutsideDays
            format={configuration.dateformatter}
            onDayClick={handleDateSelection}
            disabledDays={[
              {
                after: disableDaysAfter,
                before: disableDaysBefore,
              },
            ]}
            modifiersStyles={modifiersStyles}
            modifiers={{
              pending: pendingModifiers,
              rejected: rejectedModifiers,
              approved: approvedModifiers,
            }}
          />
          <div>
            <FormLabel error={true}>{displayMessage}</FormLabel>
          </div>
        </div>
      );
    return (
      <div className="d-flex justify-content-between">
        <div />
        <Skeleton variant="rect" width={350}>
          <div style={{ paddingTop: "300px" }} />
        </Skeleton>
        <div />
      </div>
    );
  };

  return {
    renderCalendar,
    initializeCalendar,
    selectedRange,
    initializeState,
  };
}

export default useTimesheetCalendar;
