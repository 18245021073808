import React from "react"
import Presentation from "./Presentation"

function Container(props) {
  return (
    <div>
      <Presentation accounts={props.accounts} clientID={props.client.id} />
    </div>
  )
}

export default Container
