import React, { useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Constants } from "../../../../../../shared/constants";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import SelectFormField from "../../../../../../shared/components/BootStrapFormFields/SelectFormField";
import FileUploadForm from "../../../../../../shared/components/BootStrapFormFields/FileUploadForm";
import AppModalHeader from "../../../../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../../../../shared/components/Modals/AppModalFooter";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	getAllSchools,
	getDsosBySchoolId,
} from "../../../../../../api/services/flair-shared/schools";
import YearPickerField from "../../../../../../shared/components/BootStrapFormFields/DatePickerField";
import { updateEmployee } from "../../../../../../api/services/default/employee";
import CreatableSelectFormField from "../../../../../../shared/components/BootStrapFormFields/CreatableSelectFormField";
import { useDialog } from "../../../../../../hooks/useDialog";
import AddSchoolModal from "../../../../../Console/components/Schools/AddSchoolModal";
import AddDsoModal from "../../../../../Console/components/Schools/AddDsoModal";
import { useSelector } from "react-redux";

const schema = yup.object().shape({
	schoolname: yup.string().required("School name is required"),
	dsoname: yup.string().test(
		"checkSelectField",
		"DSO is required field for Masters Degree",
		function(value){
			const { selectfield } = this.parent
			if(selectfield === 'Masters'){
				return value
			}
			return true		
		}
	),
	selectfield: yup.string().required("Degree is required"),
	certificate: yup.string().required("Certificate is required"),
	startdate: yup.string().required("Start date is required"),
	enddate: yup
		.string()
		.required("End date is required")
		.test(
			"checkEndDate",
			"End date should be greater than start date",
			function (value) {
				const { startdate } = this.parent;
				return new Date(value) > new Date(startdate);
			}
		),
});

export default function EducationHistoryV1EditModal(props) {
	const { data, employeeID, idx, onHide } = props;
	const optionMapper = (i) => ({ value: i, label: i });

	const educationFields = useSelector(
		(state) =>
			state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.educationhistory?.fields?.filter(
				(field) => field.name === "selectfield"
			)?.[0]?.values || []
	);

	const {
		open: openAddSchool,
		handleOpen: handleOpenAddSchool,
		handleClose: handleCloseAddSchool,
	} = useDialog();
	const {
		open: openAddDso,
		handleOpen: handleOpenAddDso,
		handleClose: handleCloseAddDso,
	} = useDialog();

	const schoolCallbackRef = React.useRef();
	const dsoCallbackRef = React.useRef();

	const [selectedSchool, setSelectedSchool] = React.useState(null);

	const queryClient = useQueryClient();

	const { data: schools } = useQuery({
		queryKey: ["getAllSchools"],
		queryFn: getAllSchools,
	});

	const { data: dsos, refetch } = useQuery({
		queryKey: ["getDsosBySchoolId", selectedSchool],
		queryFn: () => getDsosBySchoolId(selectedSchool),
		enabled: !!selectedSchool,
	});

	useEffect(() => {
		if (!isNaN(idx)) {
			setSelectedSchool(data[idx]?.schoolname);
			refetch();
		}
	}, [idx]);

	const useUpdateProfile = useMutation({
		queryKey: ["updateEmployee", employeeID],
		mutationFn: ({ employeeID, data }) => updateEmployee(employeeID, data),
		onSuccess: () => {
			queryClient.invalidateQueries(["getEmployee", employeeID]);
			onHide();
		},
	});

	const loadInitialValues = () => {
		if (!isNaN(idx)) {
			return data[idx];
		} else {
			return {};
		}
	};

	const getTitleText = () => {
		if (!isNaN(idx)) {
			return "Edit Education History";
		} else {
			return "Create Education History";
		}
	};

	const getSaveButtonText = () => {
		if (!isNaN(idx)) {
			return "Save Changes";
		} else {
			return "Create";
		}
	};

	const handleSubmit = async (values, actions) => {
		actions.setSubmitting(true);
		const formData = { ...values };

		let educationhistory = data?.map((item) => item) || [];
		if (!isNaN(idx)) {
			educationhistory[idx] = formData;
		} else {
			educationhistory.push(formData);
		}

		const payload = {
			educationhistory,
		};

		await useUpdateProfile.mutateAsync({ employeeID, data: payload });
		actions.setSubmitting(false);
		onHide();
	};

	return (
		<>
			<AddSchoolModal
				open={openAddSchool}
				handleClose={() => {
					handleCloseAddSchool();
					schoolCallbackRef.current();
				}}
				onCreate={() => {
					schoolCallbackRef.current();
				}}
			/>
			<AddDsoModal
				open={openAddDso}
				handleClose={() => {
					handleCloseAddDso();
					dsoCallbackRef.current();
				}}
				onCreate={() => {
					dsoCallbackRef.current();
					queryClient.invalidateQueries(["getDsosBySchoolId", selectedSchool]);
				}}
				schoolId={selectedSchool}
			/>
			<Modal
				{...props}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Formik
					validationSchema={schema}
					initialValues={loadInitialValues()}
					onSubmit={handleSubmit}
				>
					{(formik) => {
						return (
							<Form>
								<AppModalHeader title={getTitleText()} />
								<Modal.Body>
									<Row>
										<Col xl={4}>
											<Field
												name="selectfield"
												type="select"
												options={educationFields.map(optionMapper)}
												onSelect={(value) => {
													formik.setFieldValue("schoolname", "");
												}}
												label={"Select Field *"}
												component={SelectFormField}
											/>
										</Col>
										
											<Col xl={4}>
											<Field
												name="schoolname"
												type="select"
												label={"School Name *"}
												options={
													schools?.map((item) => ({
														value: item.id,
														label: item.name,
													})) || []
												}
												component={CreatableSelectFormField}
												onSelect={(value) => {
													setSelectedSchool(value);
													formik.setFieldValue("dsoname", "");
												}}
												handleCreate={(value, cb) => {
													handleOpenAddSchool();
													schoolCallbackRef.current = cb;
												}}
											/>
										</Col>
										
										{
											formik?.values?.selectfield === 'Masters' ?
											<Col xl={4}>
											<Field
												name="dsoname"
												type="select"
												label={"Dso Name *"}
												options={
													dsos?.map((item) => ({
														value: item.id,
														label: item.name,
													})) || []
												}
												component={CreatableSelectFormField}
												handleCreate={(value, cb) => {
													handleOpenAddDso();
													dsoCallbackRef.current = cb;
												}}
											/>
										</Col>
										:
										<></>
										}
										<Col xl={4}>
											<Field
												name="startdate"
												type="text"
												label={"Start Date *"}
												component={YearPickerField}
												showYearPicker={true}
												dateFormat={"yyyy"}
											/>
										</Col>
										<Col xl={4}>
											<Field
												name="enddate"
												type="text"
												label={"End Date *"}
												component={YearPickerField}
												minDate={formik.values.startdate}
												showYearPicker={true}
												dateFormat={"yyyy"}
											/>
										</Col>
										<Col xl={4}>
											<Field
												name="certificate"
												component={FileUploadForm}
												label="Certificate *"
												type="text"
												employeeId={employeeID}
												sectionKey="educationhistory"
											/>
										</Col>
									</Row>
								</Modal.Body>
								<AppModalFooter
									saveButtonText={getSaveButtonText()}
									saveButtonDisabled={
										!formik.isValid || formik.isSubmitting || !formik.dirty
									}
								/>
							</Form>
						);
					}}
				</Formik>
			</Modal>
		</>
	);
}
