import React from "react"
import { RiBuildingLine } from "react-icons/ri"
import { Typography } from "@material-ui/core"
import Edit from "../Edit"

function Presentation(props) {
  const { locationsline1 = "", locationsline2 = "", locationscity = "", locationsstate_name = "", locationszip = "", locationscountry = "" } = props?.invoiceLocation || {}
  return (
    <div className="row">
      <div className="col-11">
        <Typography color="textSecondary" gutterBottom>
          <RiBuildingLine size={24} /> Invoice Location
        </Typography>
        <Typography>
          {[locationsline1, locationsline2, locationscity, locationscountry, locationsstate_name, locationszip]
            .filter((item) => item !== "")
            .join(", ")}
        </Typography>
      </div>
      <div className="col-1">
        <Edit id={props.id} invoiceLocationId={props.invoiceLocationId} />
      </div>
    </div>
  )
}

export default Presentation
