import React from "react"
import ModuleAccess from "../Services/Console/components/ModuleLevelAccess"

function ModuleAccessPage(props) {
  return (
    <div>
      <ModuleAccess />
    </div>
  )
}

export default ModuleAccessPage
