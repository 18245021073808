import React, { useEffect, useState } from "react"
import Presentation from "./Presentation"
import validate from "../../../../../shared/validation"
import { connect } from "react-redux"
import { inviteEmployee } from "../../../middleware"
import { CalendarFormatter } from "../../../../../shared/CalendarFormatter"

function Container(props) {
	const { _invite_employee, hideInviteEmployeeDialog = () => { } } = props
	const initialState = {
		firstname: "",
		lastname: "",
		phonenumber: "",
		email: "",
		dob: "",
		branch: "",
	}
	const [state, setState] = useState(initialState)
	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const onInvite = () => {
		_invite_employee(state)
		handleClose()
	}

	const handleClose = () => {
		setOpen(false)
		hideInviteEmployeeDialog()
		setState(initialState)
	}

	const handleChange = (e) => {
		const key = e.target.name
		const value = e.target.value
		if (key === "email")
			setState({
				...state,
				[key]: value.toLowerCase(),
			})
		else if (key === "branch")
			setState({
				...state,
				[key]: value,
			})
		else if (key !== "dob")
			setState({
				...state,
				[key]: validate.nameFormatterToUpperCase(value),
			})
	}

	const handleDateChange = (key, value) => {
		if (!isNaN(Date.parse(value)))
			setState({
				...state,
				[key]: CalendarFormatter.standardDateFormat(value),
			})
	}


	return (
		<div>
			<Presentation
				{...state}
				handleChange={handleChange}
				handleDateChange={handleDateChange}
				handleClickOpen={handleClickOpen}
				onInvite={onInvite}
				handleClose={handleClose}
				open={open}
				{...props}
			/>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => {
	return {
		branchList: state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.personal?.fields?.filter((field) => field.name === "branch")?.[0]?.values || []
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		_invite_employee: (payload) => {
			dispatch(inviteEmployee(payload))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
