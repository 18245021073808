import React, { useState } from "react"
import Presentation from "./Presentation"
import { checkName, uploadBusinessInformation } from "../../../../middleware"
import { connect } from "react-redux"
import { uploadToStorage } from "../../../../../../shared/fileUploader"

function Container(props) {
  const { businessInformation, setBusinessInformation, _check_name } = props

  const [isUploading, setIsUploading] = useState(false)

  const handleFile = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      fileUpload(file)
    }
  }

  const fileUpload = async (file) => {
    const fileName = ["Clientlogo", "_", new Date().toISOString()].join("")
    const filePath = `Clients/${fileName}`
    setIsUploading(true)
    uploadToStorage(file, filePath, fileName, "profilePic")
      .then((url) => {
        console.log(url)
        handleKeyValuePair("logo", url)
        setIsUploading(false)
      })
      .catch((err) => {
        console.error(err)
        setIsUploading(false)
      })
  }

  const handleChange = (event) => {
    if (event.target.name === "businessName") _check_name(event.target.value)
    setBusinessInformation({
      name: event.target.name,
      value: event.target.value,
    })
  }

  const handleChangePrimeVendor = () => {
    setBusinessInformation({
      name: "isPrimeVendor",
      value: !businessInformation.isPrimeVendor,
    })
  }

  const handleKeyValuePair = (name, value) => {
    setBusinessInformation({ name, value })
  }

  return (
    <div>
      <Presentation
        businessInformation={businessInformation}
        handleChange={handleChange}
        handleKeyValuePair={handleKeyValuePair}
        isUploading={isUploading}
        handleFile={handleFile}
        handleChangePrimeVendor={handleChangePrimeVendor}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    businessInformation: state.client.newClient.businessInformation,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setBusinessInformation: (payload) => {
      dispatch(uploadBusinessInformation(payload))
    },
    _check_name: (name) => {
      dispatch(checkName(name))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
