import React from "react"
import ProjectHandler from "../Services/TaskManagement/components/ProjectHandler/Index"

function ProjectHandlerPage(props) {
	return (
		<div>
			<ProjectHandler {...props}/>
		</div>
	)
}

export default ProjectHandlerPage
