import React, { useContext, useEffect } from "react"
import Presentation from "./Presentation"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import { compose } from "redux"
import { connect } from "react-redux"
import validation from "../../../../../shared/validation"
import { useHistory } from "react-router-dom"
function Container(props) {
  const tab = Number(validation.getQueryParameter("tab"))
  const [tabValue, setTabValue] = React.useState(tab)
  const { trackLetterRequestsSubmissions } = props

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  useEffect(() => {
    setTabValue(tab)
  }, [tab])

  return (
    <div>
      <Presentation
        {...props}
        tabValue={tabValue}
        handleTabChange={handleTabChange}
        trackLetterRequestsSubmissions={
          trackLetterRequestsSubmissions
            ? trackLetterRequestsSubmissions
            : {
                approvedRequests: 0,
                pendingRequests: 0,
                rejectedRequests: 0,
                totalRequests:0
              }
        }
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { myReq } = ownProps
  let emp = state.firebase.auth.uid
  const data = state.firestore.data.trackLetterRequestsSubmissions
    ? myReq
      ? state.firestore.data.trackLetterRequestsSubmissions.tracker[emp]
      : state.firestore.data.trackLetterRequestsSubmissions.tracker
    : state.firestore.data.trackLetterRequestsSubmissions

  return {
    trackLetterRequestsSubmissions:  data,
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "ID_TRACKER",
        doc: "letter_requests",
        storeAs: "trackLetterRequestsSubmissions",
      },
    ]
    return []
  })
)(Container)
