import ACTION from "./actionTypes"

export const setListener = (listener, type) => ({
  type: ACTION.SET_LISTENER,
  payload: {
    listener,
    type,
  },
});


export const placementTimesheetsDataReq = () => ({
  type: ACTION.TIMESHEETS_PLACEMENTS_REQ,
});

export const placementTimesheetsDataSuccess = (payload) => ({
  type: ACTION.TIMESHEETS_PLACEMENTS_SUCCESS,
  payload,
});

export const placementTimesheetsDataFailure = (payload) => ({
  type: ACTION.TIMESHEETS_PLACEMENTS_FAILURE,
  payload,
});

export function reuqestPlacementList() {
  return {
    type: ACTION.PLACEMENTLIST_REQUEST,
  }
}

export function placementListSuccess(payload) {
  return {
    type: ACTION.PLACEMENTLIST_SUCCESS,
    payload,
  }
}

export function placementListFailure(error) {
  return {
    type: ACTION.PLACEMENTLIST_FAILURE,
    error,
  }
}

export function documentsRequest() {
  return {
    type: ACTION.DOCUMENTS_REQUEST,
  }
}

export function documentsSuccess(payload) {
  return {
    type: ACTION.DOCUMENTS_SUCCESS,
    payload,
  }
}

export function documentsFailure(error) {
  console.error(error)
  return {
    type: ACTION.DOCUMENTS_FAILURE,
    error,
  }
}

export function locationsRequest() {
  return {
    type: ACTION.LOCATIONS_REQUEST,
  }
}

export function locationsSuccess(payload) {
  return {
    type: ACTION.LOCATIONS_SUCCESS,
    payload,
  }
}

export function locationsFailure(error) {
  console.error(error)
  return {
    type: ACTION.LOCATIONS_FAILURE,
    error,
  }
}



export const employeePlacementDataReq = () => ({
  type: ACTION.EMPLOYEE_PLACEMENTS_REQ,
});

export const employeePlacementDataSuccess = (payload) => ({
  type: ACTION.EMPLOYEE_PLACEMENTS_SUCCESS,
  payload,
});

export const employeePlacementDataFailure = (payload) => ({
  type: ACTION.EMPLOYEE_PLACEMENTS_FAILURE,
  payload,
});
