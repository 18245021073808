import React from "react"
import PayrollTabs from "../Services/Payrolls/components/PayrollTabs"

function PayrollsListPage() {
  return (
    <>
      <PayrollTabs />
    </>
  )
}

export default PayrollsListPage
