import React from "react"
import ArticleHistory from "../Services/Wiki/components/ArticleHistory"
import { Breadcrumbs } from "@material-ui/core"
import { Link } from "react-router-dom"

function ArticleHistoryPage(props) {
  const { articleId } = props.match.params
  return (
    <div>
      <div>
        <ArticleHistory {...props.match.params} />
      </div>
    </div>
  )
}

export default ArticleHistoryPage
