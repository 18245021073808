import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles({
  root: {
    //minWidth: 300,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  table: {
    backgroundColor: "#fafafa",
    overflowX: "inherit",
    width: "100%",
  },
  card: {
    backgroundColor: "#fafafa",
    //minWidth: 300,
  },
})
export const customStyles = {
  employeeExist: {
    color: "#4183c4",
    textTransform: "capitalize",
  },
  employeeNonExist: {
    color: "#626466",
    textTransform: "capitalize",
  },
}
