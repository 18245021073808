import React,{useEffect,useState} from "react"
import { Grid, TextField, Button ,Tooltip, Chip, Typography} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import {
  AddCircleOutline as AddCircleOutlineIcon,
} from "@material-ui/icons";
import EditContact from "../../../../../Clients/components/ViewClient/clientComponents/Contacts/EditContact";
import { getAllClientContacts } from "../../../../middleware";
import { isExists } from "date-fns";

function Presentation(props) {
  const {
    handleEmployees,
    companyIDs,
    benchSalesList,
    handleSubmit,
    fillableSections,
    clientList,
    recruitersList
  } = props
  const [clientContacts,setClientContacts] = useState([])
  const [recruiter,setRecruiter] = useState([])
  const [requiredMangers, setManagers] = useState([]);

  useEffect(() => {
    const requiredMangers = clientContacts ? clientContacts.filter((contact) =>
    recruitersList.includes(contact.email)
    ) : [];
    setManagers(requiredMangers);
  }, [recruitersList, clientContacts]);
  console.log(requiredMangers,'requiredMangers',recruitersList,clientContacts)
  const fetchAllClientContacts = async () => {
    try {
      const data = await getAllClientContacts(clientList)
      console.log(data,'clientdetails_data')
      setClientContacts(data)
      let Managers  = data
      .filter((contact)  => (
        contact.contactType === "Recruiter" && contact.isExist  === true
      )) 
      setRecruiter(Managers)
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if(clientList){
      fetchAllClientContacts()
    }
  }, [clientList])
  console.log(clientContacts,recruiter)
  return (
    <div>
      <div className="text-right mb-4" style={{marginLeft:'auto'}}>
      <Tooltip title="Add contact">
              <EditContact
                btnContent={<AddCircleOutlineIcon />}
                clientId={''}
                edit={false}
                clientList={clientList}
                isWorkLocation={true}
                contact={{}}
                isPlacement={true}
              />
            </Tooltip>
      </div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ minWidth: 240, maxWidth: "100%" }}>
              <Autocomplete
                multiple
                options={benchSalesList}
                getOptionLabel={(option) => option.name}
                value={benchSalesList.filter((employee) => {
                  return companyIDs.includes(employee.companyID)
                })}
                filterSelectedOptions
                onChange={(_, v) =>
                  handleEmployees("companyIDs",v.map((item) => item.companyID))
                }
                renderInput={(params) => (
                  <TextField fullWidth {...params} label="Add employees *" />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ minWidth: 240, maxWidth: "100%" }}>
              <Autocomplete
                multiple
                id="placements-recruiter"
                options={recruiter}
                getOptionLabel={(option) => option.representativeName +" - "+ option.email+" - "+option.workPhone}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={
                        <div>
                        <Typography style={{ whiteSpace: "normal" }}>
                          {option.representativeName}
                        </Typography>
                        <Typography style={{ whiteSpace: "normal" }}>
                        { option.email}
                      </Typography>
                      <Typography style={{ whiteSpace: "normal" }}>
                        {option.workPhone}
                      </Typography>
                      </div>
                      }
                      {...getTagProps({ index })}
                      style={{ height: "100%" }}
                    />
                  ))
                }
                sty
                value={
                  recruiter.filter((emp) => recruitersList.includes(emp.email) )
                }
                filterSelectedOptions
                onChange={(_, v) =>
                  handleEmployees("recruitersList",v.map((item) => item.email))
                }
                renderInput={(params) => (
                  <TextField fullWidth {...params} label="Recruitment Manager *" />
                )}
                renderOption={(option) => {
                  return (
                    <>
                      <div>
                        {option.representativeName} 
                        <div>
                          {option.email}
                          <div>
                          {option.workPhone}
                          </div>
                        </div>

                      </div>
                    </>
                  );
                }}
              />
            </div>
          </Grid>
        </Grid>
        <br />
        <Button
          color={
            fillableSections.includes("recruitment_details")
              ? "primary"
              : "secondary"
          }
          variant="contained"
          type="submit"
          disabled={companyIDs.length && requiredMangers.length ? false : true}
        >
          {fillableSections.includes("recruitment_details") ? "Done" : "Update"}
        </Button>
      </form>
    </div>
  )
}

export default Presentation
