import React, { useContext } from "react";
import { Context } from "../component_state/context";
import { Autocomplete } from "@material-ui/lab";
import { TextField, MenuItem } from "@material-ui/core";
import MetaInfo from "../../../../../shared/getMetaInfo";

function Presentation(props) {
  const { setState, state } = props;

  const placements = Object.values(state.placements).filter(
    (item) => new Date(item.projectEndDate) > new Date()
  );

  const obj = new MetaInfo();
  return (
    <div>
      {state.type === "edit" || state.type === "view" || state.type === "defaulter" ? (
        <TextField
          select
          label={"Select Placement"}
          id="SubmitTimesheet-SelectPlacement-state-placementID"
          required
          fullWidth
          value={state.placementID}
          size="small"
        >
          <MenuItem value={state.placementID}>
            {obj.emailToName(state.employeeID) +
              "_" +
              obj.clientIdToName(state.clientId) +
              "_" +
              state.placementID}
          </MenuItem>
        </TextField>
      ) : (
        <Autocomplete
          options={Object.values(placements)}
          renderOption={(option) => {
            return (
              <span>
                {obj.emailToName(option.employeeID) +
                  "_" +
                  obj.clientIdToName(option.clientId) +
                  "_" +
                  option.placementID}
              </span>
            );
          }}
          getOptionLabel={(option) => {
            return (
              obj.emailToName(option.employeeID) +
              "_" +
              obj.clientIdToName(option.clientId) +
              "_" +
              option.placementID
            );
          }}
          value={placements[state.placementID]}
          id="SubmitTimesheet-SelectPlacement-placements-state-placementID"
          onChange={(e, v) => {
            if (v) {
              setState({
                isAlreadySubmitted: false,
                placementID: v.placementID,
                clientId: v.clientId,
                timesheetEmployeeID: v.employeeID,
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              required
              size="small"
              {...params}
              label="Select Placement"
            />
          )}
        />
      )}
    </div>
  );
}

export default Presentation;
