import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getAllSchools } from "../../../../api/services/flair-shared/schools";
import FlairTable from "../../../../shared/FlairTable";
import CreateNewSchool from "./CreateNewSchool";
import { Link } from "react-router-dom";

export default function SchoolList() {
	const columns = [
		{
			title: "Name",
			field: "name",
			render: (row) => (
				<Link to={`/console/schools/${row.id}`}>{row.name}</Link>
			),
		},
		{ title: "Address", field: "address" },
		{ title: "City", field: "city" },
		{ title: "State", field: "state" },
		{ title: "Zip", field: "zip" },
		{ title: "Country", field: "country" },
		{ title: "DSO Count", field: "dsoCount" },
	];

	const { data: schools, isLoading } = useQuery({
		queryKey: ["getAllSchools"],
		queryFn: () => getAllSchools(),
	});

	const rows =
		schools?.map((school) => ({
			id: school.id,
			name: school.name,
			address: [school.line1, school.line2].filter(Boolean).join(", "),
			city: school.city,
			state: school.state,
			zip: school.zip,
			country: school.country,
			dsoCount: school.dsoIds?.length || 0,
		})) || [];
	
	if (isLoading) {
		return <div>Loading...</div>;
	}

	return (
		<>
			<FlairTable
				data={rows}
				columns={columns}
				title="Schools"
				actions={{
					icon: () => <CreateNewSchool />,
					tooltip: 'Create New School',
					isFreeAction: true,
					position: 'toolbar'
				}}
			/>
		</>
	);
}
