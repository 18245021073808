import React, { useEffect } from 'react'
import Presentation from "./Presentation"
import { clientReject } from "../../../middleware/invoiceList"
import validation from "../../../../../shared/validation"
import { connect } from "react-redux"

function Container(props) {
  const { _client_reject } = props
  const invoiceID = decodeURIComponent(validation.getQueryParameter("invoiceID"))
  useEffect(() => {
    const rejectedBy = validation.getQueryParameter("rejectedBy")
    const reason = window.prompt("Reason for rejection: ");
    if (reason.length > 0) {
      _client_reject(rejectedBy, invoiceID, reason)
    }
  }, [])
  return (
    <div>
      <Presentation invoiceID={invoiceID} />
    </div>
  )
}


const mapStateToProps = (state) => ({
  state: state.invoice.createInvoice
})

const mapDispatchToProps = (dispatch) => ({
  _client_reject: (email, invoiceID, reason) => dispatch(clientReject(email, invoiceID, reason))
})


export default connect(mapStateToProps, mapDispatchToProps)(Container)
