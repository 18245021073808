import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { getExpiredList, onIgnore } from "../../MiddleWare";
import { useSelector } from "react-redux";
import Presentation from "./Presentation";
import { loadProfileTemplate } from "../../../Console/middleware/profileBuilder";
import { loadEmployeeProfile } from "../../../EmployeeManagment/middleware/dynamicProfileRender";

function Container(props) {
  const [state, setstate] = useState(true);
  let empModules = useSelector(
    (state) => state.employee.employeeModules.accessModules
  );
  let LoginEmployeeId = useSelector((state) => state.firebase.auth.uid);

  const { isLoaded } = props;
  const dispatch = useDispatch();
  let isManager = true;
  useEffect(() => {
    dispatch(getExpiredList(isManager));
    dispatch(loadProfileTemplate());
    dispatch(loadEmployeeProfile(props.employeeId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (
      empModules.includes("employees-manager") ||
      empModules.includes("console-customization")
    ) {
      setstate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const OnIgnore = (type, EmpId, List) => {
    List.push(type);
    let isIgnored = List;
    let payloadData = {
      isIgnored,
      EmpId,
    };
    dispatch(onIgnore(payloadData));
  };
  const OnEnableDoc = (type, EmpId, List=[]) => {
    let list = List.filter((e) => e !== type);
    let isIgnored = list;
    let payloadData = {
      isIgnored,
      EmpId,
    };
    dispatch(onIgnore(payloadData));
  };
  if (isLoaded)
    return (
      <div>
        <Presentation
          state={state}
          {...props}
          empModules={empModules}
          LoginEmployeeId={LoginEmployeeId}
          OnIgnore={OnIgnore}
          OnEnableDoc={OnEnableDoc}
        />
      </div>
    );
  return <p>Loading</p>;
}
const mapStateToProps = (state, ownProps) => {
  return {
    ExpiredListData: state.ExpiredDocs.expiredList.expiredItems,
    isLoaded: state.ExpiredDocs.expiredList.isLoaded,
  };
};
export default compose(connect(mapStateToProps))(Container);
