import React from "react"
import EditDefaulters from "../Services/Timesheets/components/Defaulters/EditDefaulters/index"

import validation from "../shared/validation"

function EditDefaultersPage(props) {

  return (
    <div>
      <EditDefaulters
       timesheetID={validation.getQueryParameter("timesheetID")}
       placementID={validation.getQueryParameter("placementID")}
       employeeID={validation.getQueryParameter("employeeID")}
       startDate={validation.getQueryParameter("startDate") || ""}
       endDate={validation.getQueryParameter("endDate") || ""}
      />
    </div>
  )
}

export default EditDefaultersPage
