import { defaultInstance } from "../../instances/default.instance";

/**
 * @param {Number} month
 * @param {Number} year
 * @param {AbortSignal?} signal
 * @returns {Promise<Array<Record>>}
 * @param {string} type
 * @param {boolean} isManager
 * @param {string?} employeeId
 * 
 */
export const getTimesheetsReport = (month, year, signal) => {
	if (isNaN(month) || isNaN(year)) {
		return Promise.reject(new Error("Month and year are required"));
	}
	const params = new URLSearchParams({ month, year });
	return defaultInstance({
		method: "GET",
		url: `/employees/timesheet-overview?${params}`,
		signal,
	});
};

/**
 * @param {String} type
 * @param {Number?} take
 * @param {String?} cursor
 * @param {Boolean?} isManager
 * @param {String?} employeeId
 * @param {String[]?} clientIds
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const getAllTimesheets = (type, isManager = false, employeeId, clientIds, skip, take, signal) => {
	const params = new URLSearchParams();
	if (type) params.append("type", type);
	if (isManager) params.append("isManager", isManager);
	if (employeeId?.length > 0) params.append("employeeId", employeeId);
	if (clientIds?.length > 0) params.append("clientIds", clientIds);
	if (take) params.append("take", take);
	if (skip) params.append("skip", skip);

	return defaultInstance({
		method: "GET",
		url: `/employees/getAll?${params.toString()}`,
		signal,
	});
};