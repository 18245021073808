import React, { useState } from "react"
import { Grid, Tabs, Tab, TextField, MenuItem, Button } from "@material-ui/core"
import DateFnsUtils from "@date-io/date-fns"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import {
  MobileNumberFormatter,
  CurrencyFormatter,
  DoubleDigitFormatter,
  PayratePercentageFormatter
} from "../../../../../../../shared/customNumberFormats"
import { AttachFile as AttachFileIcon } from "@material-ui/icons"
import { GoFileSymlinkFile } from "react-icons/go"
import validate from "../../../../../../../shared/validation"
import TabPanel from "../../../../../../../shared/tabPanel"
import History from "../History"

function Presentation(props) {
  const {
    state,
    handleSubmit,
    handleChange_Numbers,
    handleChange_Date,
    handleChange_Strings,
    handleUpdate,
    clearState,
    min_date,
    handleDiscount,
    fileURL,
    handleFile,
    isUploading,
    historyData
  } = props
  const billingTypes = [
    "Per Hour",
    "Per Day",
    "Per Week",
    "Per Month",
    "Per Annum",
  ]
  const payTypes = ["Hourly", "Fixed Pay"]
  const discountTypes = [
    { value: 'byPercentage', label: 'By Percentage' },
    { value: 'byValue', label: 'By Value' }
  ]
  const [value, setValue] = useState(0)
  const handleTabChange = (_, value) => setValue(value)

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Tabs value={value} onChange={handleTabChange} variant="scrollable">
          <Tab label="History" />
          <Tab label="Rates" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <History
            history={historyData}
            handleUpdate={handleUpdate}
            handleTabChange={handleTabChange}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div>
            <div>
              <div>
                <h3>
                  <u>Billing Rate:</u>
                </h3>
              </div>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      select
                      label="Billing Type"
                      size="small"
                      name="billingType"
                      id="placements-payments-billingType"
                      value={state.billingType ? state.billingType : ""}
                      fullWidth
                      style={{ minWidth: "100%" }}
                      required
                      onChange={handleChange_Strings}
                    >
                      {billingTypes.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label="Billing Rate"
                      size="small"
                      fullWidth
                      type="text"
                      id="placements-payments-billingRate"
                      required
                      name="billingRate"
                      value={state.billingRate ? state.billingRate : ""}
                      onChange={handleChange_Numbers}
                      InputProps={{
                        inputComponent: CurrencyFormatter,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label="OT Billing Rate"
                      size="small"
                      type="text"
                      id="placements-payments-OTbillingRate"
                      required  
                      fullWidth
                      name="OTbillingRate"
                      value={state.OTbillingRate ? state.OTbillingRate : ""}
                      onChange={handleChange_Numbers}
                      InputProps={{
                        inputComponent: CurrencyFormatter,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      variant="outlined"
                      label="PO Number"
                      type="text"
                      id="placements-payments-purchaseOrderNumber"
                      fullWidth
                      name="purchaseOrderNumber"
                      value={
                        state.purchaseOrderNumber
                          ? state.purchaseOrderNumber
                          : ""
                      }
                      onChange={handleChange_Numbers}
                      InputProps={{
                        inputComponent: MobileNumberFormatter,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <br />
            <div>
							<h3>
								<u>Discount:</u>
							</h3>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={4}>
									<TextField
										label='Name'
										size='small'
										variant='outlined'
										type='text'
										id='placements-payments-discounts-name'
										fullWidth
										name='name'
										value={state.discountDetails.name}
										onChange={handleDiscount}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										select
										label='Type'
										id='placements-payments-discounts-type'
										fullWidth
										name='type'
										style={{ minWidth: '100%', marginTop: "-10px" }}
										value={state.discountDetails.type}
										onChange={handleDiscount}
									>
                    {discountTypes.map(({ value, label }) => {
                      return (
                        <MenuItem key={value} value={value}>{label}</MenuItem>
                      )
                    })}
									</TextField>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										label='Discount'
										size='small'
										variant='outlined'
										type='number'
										id='placements-payments-discounts-value'
										fullWidth
										name='value'
										value={state.discountDetails.value}
										onChange={handleDiscount}
									/>
								</Grid>
							</Grid>
						</div>
            <br />
            <div>
              <h3>
                <u>Pay Rate:</u>
              </h3>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      select
                      label="Pay Type"
                      id="placements-payments-payType"
                      fullWidth
                      name="payType"
                      required
                      style={{ minWidth: "100%" }}
                      value={state.payType}
                      onChange={handleChange_Strings}
                    >
                      {payTypes.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {state.payType === "Hourly" ? (
                    <Grid item xs={4}>
                      <TextField
                        label="Percentage"
                        required
                        size="small"
                        variant="outlined"
                        type="text"
                        id="placements-payments-percentage"
                        fullWidth
                        name="percentage"
                        value={state.percentage <= 100 ? state.percentage : ""}
                        onChange={handleChange_Numbers}
                        InputProps={{
                          inputComponent: PayratePercentageFormatter,
                        }}
                      />
                    </Grid>
                  ) : null}
                  {state.payType === "Fixed Pay" ? (
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        variant="outlined"
                        required
                        label="Fixed pay"
                        type="text"
                        id="placements-payments-fixedPay"
                        fullWidth
                        name="fixedPay"
                        value={
                          isNaN(parseInt(state.fixedPay)) ? "" : state.fixedPay
                        }
                        onChange={handleChange_Numbers}
                        InputProps={{
                          inputComponent: CurrencyFormatter,
                        }}
                      />
                    </Grid>
                  ) : null}
                  {state.payType === "Hourly" ? (
                    <>
                      <Grid item xs={4}>
                        <TextField
                          size="small"
                          variant="outlined"
                          label="Employee Pay Rate"
                          type="text"
                          id="placements-payments-employeePayRate"
                          fullWidth
                          name="employeePayRate"
                          required
                          value={
                            isNaN(state.employeePayRate)
                              ? ""
                              : state.employeePayRate
                          }
                          InputProps={{
                            inputComponent: CurrencyFormatter,
                            readOnly: true,
                          }}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          size="small"
                          variant="outlined"
                          label="Employee OT Pay Rate"
                          type="text"
                          id="placements-payments-OTpayRate"
                          fullWidth
                          name="OTpayRate"
                          value={state.OTpayRate ? state.OTpayRate : ""}
                          onChange={handleChange_Numbers}
                          InputProps={{
                            inputComponent: CurrencyFormatter,
                            readOnly: true,
                            disabled: state.disableOT,
                          }}
                        />
                      </Grid>
                    </>
                  ) : null}
                  <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        required
                        fullWidth
                        size="small"
                        // minDate={min_date}
                        style={{ marginTop: "3px", marginRight: "10px" }}
                        id="placements-payments--effectiveDate"
                        label="Effective Date"
                        format="MM/dd/yyyy"
                        value={state.effectiveDate ? state.effectiveDate : null}
                        onChange={(date) =>
                          handleChange_Date("effectiveDate", date)
                        }
                        name="effectiveDate"
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        required
                        minDate={state.effectiveDate}
                        id="placements-payments--effectiveUntil"
                        label="End date"
                        format="MM/dd/yyyy"
                        value={
                          state.effectiveUntil ? state.effectiveUntil : null
                        }
                        onChange={(date) =>
                          handleChange_Date("effectiveUntil", date)
                        }
                        name="effectiveUntilDate"
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </div>
            </div>
            <br />
            <div>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div style={{ marginTop: "6px" }}>
                    <input
                      style={{ display: 'none' }}
                      id="placements-documents-file"
                      type="file"
                      onChange={handleFile}
                    />
                    <label htmlFor="placements-documents-file">
                      <Button variant="contained" color="primary" component="span">
                        <span>
                          <AttachFileIcon fontSize="small" color="inherit" /> Attachment
                        </span>
                      </Button>
                    </label>
                    <div className="d-flex justify-content-between">
                      <p>
                        {fileURL ? (
                          <a target="_blank" rel="noopener noreferrer" href={fileURL}>
                            <GoFileSymlinkFile size={22} />{" "}
                          </a>
                        ) : (
                          <p>No file choosen</p>
                        )}
                      </p>
                    </div>
                  </div>
                  {isUploading ? <p>Uploading please wait...</p> : ""}
                </Grid>
              </Grid>
            </div>
            <br />
            {state.update ? (
              <span>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={
                    !validate.checkDateDiff(
                      state.effectiveDate,
                      state.effectiveUntil
                    ) ||
                      !state.billingType ||
                      !state.billingRate ||
                      !state.payType ||
                      (state.payType === "Hourly"
                        ? !state.percentage
                        : !state.fixedPay) ||
                        isUploading
                      ? true
                      : false
                  }
                >
                  Update
                </Button>{" "}
                <Button
                  onClick={clearState}
                  color="inherit"
                  variant="contained"
                >
                  Cancel
                </Button>
              </span>
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  !validate.checkDateDiff(
                    state.effectiveDate,
                    state.effectiveUntil
                  ) ||
                    !state.billingType ||
                    !state.billingRate ||
                    !state.payType ||
                    (state.payType === "Hourly"
                      ? !state.percentage
                      : !state.fixedPay)
                    ? true
                    : false
                }
              >
                Done
              </Button>
            )}
          </div>
        </TabPanel>
      </form>
    </div>
  )
}

export default Presentation
