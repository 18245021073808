import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DeleteModal from '../DeleteModal/delete';
import EducationHistoryV1EditModal from './EducationHistoryV1EditModal';
import EducationHistoryV2EditModal from './EducationHistoryV2EditModal';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { updateEmployee } from '../../../../../../api/services/default/employee';
import { configuration } from '../../../../../../config/companyConfig';
import { getSchool } from '../../../../../../api/services/flair-shared/schools';

function EducationHistoryEditModal(props) {
  if (configuration.hasFlairSharedAccess) {
    return <EducationHistoryV1EditModal {...props} />;
  }
  return <EducationHistoryV2EditModal {...props} />;
}

const EducationHistory = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowDel, delModalShow] = React.useState(false);
    const {data,employeeID, isPersonal} = props
    const [index, setIndex] = React.useState(undefined)

    const queryClient = useQueryClient();

    const handleEdit = (idx) => {
        setModalShow(true)
        setIndex(idx)
    }

    const handleAdd = () => {
        setModalShow(true)
        setIndex(undefined)
    }

    const useGetSchoolsResult = useQueries({
      queries: data?.map((res) => {
        return {
          queryKey: ["getSchool", res.schoolname],
          queryFn: () => getSchool(res.schoolname),
          enabled: configuration.hasFlairSharedAccess && !!res.schoolname,
        };
      }) || []
    })

    const filteredSchools = useGetSchoolsResult?.map((res) => res.data) || []

    const getSchoolData = (schoolname, property) => {
      if (configuration.hasFlairSharedAccess) {
        return filteredSchools?.find((res) => res?.id === schoolname)?.[property] || "";
      }
      if (property === "zipcode") {
        return filteredSchools?.find((res) => res?.schoolname === schoolname)?.["ZipCode"] || "";
      }
      return data?.find((res) => res?.schoolname === schoolname)?.[property] || "";
    }

    const useUpdateProfile = useMutation({
			queryKey: ["updateEmployee", employeeID],
			mutationFn: ({ employeeID, data }) => updateEmployee(employeeID, data),
			onSuccess: () => {
				queryClient.invalidateQueries(["getEmployee", employeeID]);
        delModalShow(false);
        setModalShow(false);
			},
		});

    const handleDelete = (idx) => {
      delModalShow(true);
      setIndex(idx)
    };
  
    const handleDeleteData = async (index) => {
      const educationhistory = data?.filter((_, idx) => (idx !== index))
      const payload = {
        educationhistory
      }
      useUpdateProfile.mutate({ employeeID, data: payload });
    }

    return (
        <>
            <EducationHistoryEditModal
                show={modalShow}
                employeeID={employeeID}
                data={data}
                idx={index}
                onHide={() => setModalShow(false)}
            />
            <DeleteModal
                show={modalShowDel}
                index={index}
                handleDelete={() => handleDeleteData(index)}
                onHide={() => delModalShow(false)}
            />
            <Card>
                <Card.Body>
                    <div className="d-flex justify-content-between">
                        <h5 className="card-title mb-3 header-title">Education History</h5>
                        {
                            isPersonal ? <></> : 
                            <Link to="#" className="linkbtn me-1 mt-0" onClick={() => handleAdd()}><i className="fa-solid fa-square-plus fs-17"></i></Link>
                        }
                    </div>
                    {
                        data?.map((res , idx) => {
                            return (
                              <div key={res.schoolname} className="emplbg mb-2">
                                <small>Education {idx}</small>
                                <div className="d-flex justify-content-between mt-2">
                                  <div>
                                    <div className="d-flex">
                                      <div className="keyicon">
                                        <i class="fa-solid fa-graduation-cap"></i>
                                      </div>
                                      <div className="ms-2">
                                        <h5 className="mb-1 mt-0 align-self-center fw-bold fs-15">
                                          {
                                            getSchoolData(
                                              res?.schoolname,
                                              "name"
                                            )
                                          }
                                        </h5>
                                        <h6 className="text-muted fw-normal mt-0 mb-1">
                                          Field : {res?.selectfield}
                                        </h6>
                                        <h6 className="text-muted fw-normal mt-0 mb-1">
                                          {res?.startdate + "-" + res?.enddate}
                                        </h6>
                                        <h6 className="text-muted fw-normal mt-0 mb-0">
                                          {[
                                            getSchoolData(
                                              res?.schoolname,
                                              "line1"
                                            ),
                                            getSchoolData(
                                              res?.schoolname,
                                              "line2"
                                            ),
                                            getSchoolData(
                                              res?.schoolname,
                                              "city"
                                            ),
                                          ].filter(i => Boolean(i)).join(", ")}
                                        </h6>
                                        <h6 className="text-muted fw-normal mt-0 mb-0">
                                          {[getSchoolData(
                                              res?.schoolname,
                                              "state"
                                            ),
                                            getSchoolData(
                                              res?.schoolname,
                                              "country"
                                            ),
                                            getSchoolData(
                                              res?.schoolname,
                                              "zipcode"
                                            ),].filter(i => Boolean(i)).join(", ")}
                                        </h6>
                                        {/* <h6 className="text-muted fw-normal mt-0 mb-0">{link}</h6> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex">
                                    <a
                                      href={res?.certificate}
                                      target="_blank"
                                      class="wauth me-1 badge-soft-danger badge rounded-pill fw-medium"
                                      rel="noreferrer"
                                    >
                                      <i className="fa-regular fa-folder-open pe-1"></i>
                                      View Document
                                    </a>
                                    {isPersonal ? (
                                      <></>
                                    ) : (
                                      <>
                                        <Link
                                          to="#"
                                          className="linkbtn me-1 mt-0"
                                          onClick={() => handleEdit(idx)}
                                        >
                                          <i className="fa-solid fa-pen fs-17"></i>
                                        </Link>
                                        <Link
                                          to="#"
                                          className="linkbtn danger mt-0"
                                          onClick={() => handleDelete(idx)}
                                        >
                                          <i className="fa-regular fa-trash-can fs-17"></i>
                                        </Link>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                        })
                    }
                </Card.Body>
            </Card>
        </>
    );
};
export default EducationHistory;