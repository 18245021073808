import React from "react"
import ViewArticle from "../Services/Wiki/components/ViewArticle"
import { Breadcrumbs } from "@material-ui/core"
import { Link } from "react-router-dom"

function ViewArticlePage(props) {
  const { articleId } = props
  return (
    <div>
      <div>
        <ViewArticle
          {...props.match.params}
          isHistory={false}
          historyId={null}
        />
      </div>
    </div>
  )
}

export default ViewArticlePage
