import React from "react";
import { TextField, makeStyles, Button, Drawer, Grid, Typography } from "@material-ui/core";
import { FiEdit3 } from "react-icons/fi";
import { Edit as EditIcon } from "@material-ui/icons";
import validate from "../../../../../../../shared/validation";
import { LocationOn as LocationOnIcon } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  setDrawer: {
    width: "380px",
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  }
}));;

function Presentation(props) {
  const {state, handleSubmit,client_locations,address,handleChangeAddress } = props;
  const classes = useStyles();

  const [drawerState, setState] = React.useState({
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;;
    }
    setState({ ...drawerState, [anchor]: open })
  }

  return (
    <div>
      <Button onClick={toggleDrawer("right", true)}>
        <EditIcon />{" "}
      </Button>
      <Drawer anchor={"right"} open={drawerState["right"]} onClose={toggleDrawer("right", false)}>
        <div className="m-3">
          <div className="text-center">
            <FiEdit3 fontSize="50px" />
            <h3>
              <u>Edit Invoice Location</u>
            </h3>
          </div>
          <form onSubmit={handleSubmit}>
            <Autocomplete
              id="client-detials-invoice-address-select"
              fullWidth
              options={client_locations}
              classes={{
                option: classes.icon,
              }}
              value={address}
              onChange={(_, value) => {
                if (value) {
                  handleChangeAddress(value)
                }
              }}
              style={{ marginTop: "3px" }}
              autoHighlight
              getOptionLabel={(option) =>
                option.locationsline1 +
                " " +
                option.locationsline2 +
                " " +
                option.locationscity +
                " " +
                option.locationsstate_name +
                " " +
                option.locationszip +
                " " +
                option.locationscountry
              }
              renderOption={(option) => (
                <React.Fragment>
                  <Grid container alignItems="center">
                    <Grid item>
                      <LocationOnIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                      <span style={{ fontWeight: 700 }}>
                        {option.locationsline1 + " " + option.locationsline2}
                      </span>
                      <Typography variant="body2" color="textSecondary">
                        {option.locationscity +
                          " " +
                          option.locationsstate_name +
                          " " +
                          option.locationscountry +
                          " " +
                          option.locationszip}
                      </Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Address *"
                  id="client-details-address"
                  size="small"
                  autoComplete="off"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <br />
            <div className="text-center">
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                onClick={toggleDrawer("right", false)}
                disabled={
                  !state.newLocationID.trim()
                }
              >
                Update
              </Button>
            </div>
          </form>
        </div>
      </Drawer>
    </div>
  )
}

export default Presentation
