import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import MetaInfo from '../../../../../shared/getMetaInfo';

const Keymatrix = (props) => {
    const {data,placements,essRequests}= props
    const metaInfo = new MetaInfo ()
    return (
        <Row>
            <Col xl={3}>
                <Card>
                    <Card.Body>
                        <div className="d-flex justify-content-between text-success">
                            <div className="iconfont">
                                <i className="fa-solid fa-briefcase"></i>
                                <div className="mt-1 fw-medium fs-15">Placements</div>
                            </div>
                            <h1 className="my-0 align-self-center text-success">{placements}</h1>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col xl={3}>
                <Card>
                    <Card.Body>
                        <div className="d-flex justify-content-between text-success">
                            <div className="iconfont">
                                <i className="fa-solid fa-file-pen"></i>
                                <div className="mt-1 fw-medium fs-15">Ess Requests</div>
                            </div>
                            <h1 className="my-0 align-self-center text-success">{essRequests}</h1>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col xl={3}>
                <Card>
                    <Card.Body>
                        <div className="d-flex justify-content-between text-danger">
                            <div className="iconfont">
                                <i className="fa-solid fa-graduation-cap"></i>
                                <div className="mt-1 fw-medium fs-15">Certifications</div>
                            </div>
                            <h1 className="my-0 align-self-center text-danger">{data?.certifications?.length}</h1>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col xl={3}>
                <Card>
                    <Card.Body>
                        <div className="text-danger">
                            <h4 className="my-0">Reporting Manager</h4>
                            <div className="d-flex text-success mt-2">
                                <i className="fa-solid fa-user fs-18 align-self-center"></i>
                                <div className="fw-medium fs-18 ps-2">{metaInfo.getEmployeeKey(data?.personal?.reportingmanager, "name")}</div>
                            </div>
                            
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};
export default Keymatrix;