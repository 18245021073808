import React from 'react';
import { Nav } from 'react-bootstrap';
import { useProfileDashboardContext } from '../../MenuBar/context';

const Sidetabs = () => {
    const { stateSetters } = useProfileDashboardContext();
    return (
        <Nav as="ul" justify variant="pills" className="border-0 p-0" onSelect={e => stateSetters.setTabValue(e)}>
            <Nav.Item as="li" key="20">
                <Nav.Link className="cursor-pointer" eventKey="personalinfo">
                    <span className="d-sm-block">Personal Information</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="21">
                <Nav.Link className="cursor-pointer" eventKey="mailingaddress">
                    <span className="d-sm-block">Mailing Address</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="22">
                <Nav.Link className="cursor-pointer" eventKey="emergency">
                    <span className="d-sm-block">Emergency Contacts</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="23">
                <Nav.Link className="cursor-pointer" eventKey="employment">
                    <span className="d-sm-block">Employment History</span>
                </Nav.Link>
            </Nav.Item>
           
            <Nav.Item as="li" key="24">
                <Nav.Link className="cursor-pointer" eventKey="workauth">
                    <span className="d-sm-block">Work Authorization</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="25">
                <Nav.Link className="cursor-pointer" eventKey="education">
                    <span className="d-sm-block">Education History</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="26">
                <Nav.Link className="cursor-pointer" eventKey="skills">
                    <span className="d-sm-block">Skills</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="27">
                <Nav.Link className="cursor-pointer" eventKey="certification">
                    <span className="d-sm-block">Certifications</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="28">
                <Nav.Link className="cursor-pointer" eventKey="passport">
                    <span className="d-sm-block">Passport</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="29">
                <Nav.Link className="cursor-pointer" eventKey="documents">
                    <span className="d-sm-block">Documents</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="30">
                <Nav.Link className="cursor-pointer" eventKey="i9-form">
                    <span className="d-sm-block">I-9 Form</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="31">
                <Nav.Link className="cursor-pointer" eventKey="e-verification">
                    <span className="d-sm-block">E-Verify</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="32">
                <Nav.Link className="cursor-pointer" eventKey="bankdetails">
                    <span className="d-sm-block">Bank Details</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="33">
                <Nav.Link className="cursor-pointer" eventKey="ssn">
                    <span className="d-sm-block">SSN</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="34">
                <Nav.Link className="cursor-pointer" eventKey="w4">
                    <span className="d-sm-block">W4</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="35">
                <Nav.Link className="cursor-pointer" eventKey="401k">
                    <span className="d-sm-block">401k</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="36">
                <Nav.Link className="cursor-pointer" eventKey="employeeaduit">
                    <span className="d-sm-block">Employee Audit</span>
                </Nav.Link>
            </Nav.Item>
        </Nav>
    );
};
export default Sidetabs;