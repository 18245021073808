import React, { useState } from "react";
import {
  Grid,
  Paper,
} from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import { useNewInvoiceContext } from './context';
import validate from '../../../../shared/validation'

function Notifiers() {
  const { state, stateSetters } = useNewInvoiceContext()
  const [helperText, setHelperText] = useState({
    to: '',
    cc: '',
    bcc: '',
  })

  return (
    <Paper className="p-2">
      <div className="mb-2">
        <h3>
          <u>Notifiers</u>
        </h3>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ChipInput
            value={state.notifiers.to}
            // onAdd={(chip) => handleAdd("to", chip)}
            // onDelete={(_, index) => handleDelete("to", index)}
            allowDuplicates={false}
            label="To *"
            fullWidth
            // helperText={helperText.to}
            size="small"
            newChipKeyCodes={[13, 9, 32]}
          />
        </Grid>
        <Grid item xs={12}>
          <ChipInput
            value={state.notifiers.cc}
            // onAdd={(chip) => handleAdd("cc", chip)}
            // onDelete={(_, index) => handleDelete("cc", index)}
            allowDuplicates={false}
            label="CC"
            fullWidth
            // helperText={helperText.cc}
            size="small"
            newChipKeyCodes={[13, 9, 32]}
          />
        </Grid>
        <Grid item xs={12}>
          <ChipInput
            value={state.notifiers?.bcc}
            onAdd={(chip) => {
              if (validate.checkEmail(chip)) {
                stateSetters.setNotifiers({
                  ...state.notifiers,
                  bcc: [...state.notifiers?.bcc, chip]
                })
                setHelperText({
                  to: '',
                  cc: '',
                  bcc: '',
                })
              } else {
                setHelperText({
                  ...helperText,
                  bcc: "Enter valid email"
                })
              }
            }}
            onDelete={(_, index) => {
              const data = state.notifiers?.bcc
              data.splice(index, 1)
              stateSetters.setNotifiers({
                ...state.notifiers,
                bcc: data
              })
            }}
            allowDuplicates={false}
            label="BCC"
            fullWidth
            helperText={helperText.bcc}
            size="small"
            newChipKeyCodes={[13, 9, 32]}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Notifiers