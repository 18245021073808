import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { DeleteOutline, Edit as EditIcon } from "@material-ui/icons";
import validate from "../../../../../../../shared/validation";
import { tsCycle, weekStartDays } from "../Index/Presentation";
import useCustomDialog from "../../../../../../../hooks/useCustomDialog";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { CalendarFormatter } from "../../../../../../../shared/CalendarFormatter";
import { useDispatch } from "react-redux";
import { updatePlacement, updatePlacementTimesheetDetails } from "../../../../../middleware";
import { errorMsg } from "../../../../../../../shared/SnackBars/index";
import { isValid } from "date-fns";
function Presentation(props) {
  const { history, handleUpdate, handleTabChange, placementID, employeeID } =
    props;
  const { renderContent, closeDialog, openDialog, isDialogOpen } =
    useCustomDialog();
  const [timesheetCycle, setCycle] = useState("");
  const [startDay, setStartDay] = useState("");
  const [implementationDate, setImplementationDate] = useState(null);
  const [cycleIndex, setCycleIndex] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setMinMax = (index) => {
    if (index !== null && index - 1 >= 0) {
      const min = new Date(history[index - 1].date);
      min.setDate(min.getDate() + 1);
      setMinDate(min);
    } else {
      // default min date
      setMinDate(new Date("1900-01-01"));
    }
    if (index !== null && index + 1 <= history.length - 1) {
      console.log(index);
      const max = new Date(history[index + 1].date);
      max.setDate(max.getDate() - 1);
      setMaxDate(max);
    } else {
      // default max date
      setMaxDate(new Date("2100-01-01"));
    }
  };

  const addCycle = () => {
    const newCycle = {
      range: parseInt(timesheetCycle, 10),
      startDay: startDay,
      date: implementationDate,
    };

    if (
      newCycle.startDay==="" ||
      isNaN(newCycle.range)||
      newCycle.date===null ||
      !isValid(new Date(newCycle.date)) 
    )
      return errorMsg("Please fill the details of new cycle");

    const modifiedCycles = [...history, newCycle];

    updatePlacementTimesheetDetails({ cycle:modifiedCycles,after: newCycle,before: {},type:"addCycle"}, employeeID,placementID,closeDialog,() => {})
  };

  const updateCycle = () => {
    const modifiedCycle = {
      range: parseInt(timesheetCycle, 10),
      startDay: startDay,
      date: implementationDate,
    };

    const modifiedCycles = [...history];

    modifiedCycles[cycleIndex] = modifiedCycle;

    if (
      modifiedCycle.date === null ||
      isValid(modifiedCycle.date)
    )
      return errorMsg("Please give valid date");

      updatePlacementTimesheetDetails({ cycle:modifiedCycles,after: modifiedCycle,before: history[cycleIndex],type:"updateCycle"}, employeeID,placementID,closeDialog,() => {})
  };

  const deleteCycle = (deleteIndex) => {
    if (history.length === 1)
      return window.alert("Cannot perform delete when only cycle exists!");
    if (!window.confirm("Are you sure?")) return;
    const modifiedCycles = history.filter((_, index) => index !== deleteIndex);
    updatePlacementTimesheetDetails({ cycle:modifiedCycles,after: {},before: history[deleteIndex],type:"deleteCycle"}, employeeID,placementID,closeDialog,() => {})
  };

  useEffect(() => {
    // CAUTION: the below state setting should be proper else the dialog mis behaves
    if (!isDialogOpen) {
      setCycle("");
      setStartDay("");
      setImplementationDate(null);
      setCycleIndex(null);
      setMinMax(null);
    }
  }, [isDialogOpen]);

  const columns = [
    { title: "Date", field: "date" },
    { title: "Range", field: "range", render: (row) => tsCycle[row.range] },
    { title: "Start Date", field: "startDate" },
    {
      title: "Actions",
      field: "",
      render: (rowData) => (
        <span>
          <IconButton
            onClick={() => {
              setCycle(rowData.range);
              setStartDay(rowData.startDay);
              setImplementationDate(rowData.date);
              setCycleIndex(rowData.index);
              setMinMax(parseInt(rowData.index));
              openDialog();
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            hidden={history.length === 1}
            onClick={() => {
              deleteCycle(parseInt(rowData.index));
            }}
          >
            <DeleteOutline />
          </IconButton>
        </span>
      ),
    },
  ];

  let data = [];

  history &&
    history.forEach((record, index) => {
      data.push({
        ...record,
        date: record.date,
        range: record.range,
        startDate: !(record.range === 3 || record.range === 4)
          ? record.startDay
          : "---",
        index: index,
      });
    });

  const dialogContent = () => {
    return (
      <div style={{ marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              select
              label="Timesheet Cycle"
              size="small"
              value={timesheetCycle}
              fullWidth
              required
              id="placements-timesheets-timesheetCycle"
              onChange={(e) => {
                const cycle = e.target.value;
                setCycle(cycle);
                if (parseInt(cycle) === 3 || parseInt(cycle) === 4) {
                  setStartDay(CalendarFormatter.getDayFromDate(cycle));
                }
              }}
              name="timesheetCycle"
            >
              {tsCycle.map((option) => (
                <MenuItem
                  key={option}
                  value={tsCycle.indexOf(option).toString()}
                >
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {parseInt(timesheetCycle) !== 3 && parseInt(timesheetCycle) !== 4 ? (
            <Grid item xs={4}>
              <TextField
                select
                label="Week start day"
                size="small"
                value={startDay}
                fullWidth
                required
                id="placements-timesheets-startDay"
                name="startDay"
                onChange={(e) => setStartDay(e.target.value)}
              >
                {weekStartDays.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ) : null}

          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                required
                fullWidth
                id="placements-timesheets-implementationDate"
                size="small"
                label="Implementation date"
                format="MM/dd/yyyy"
                value={implementationDate ? implementationDate : null}
                onChange={(date) => {
                  setImplementationDate(
                    CalendarFormatter.standardDateFormat(date)
                  );
                  if (
                    parseInt(timesheetCycle) === 3 ||
                    parseInt(timesheetCycle) === 4
                  ) {
                    setStartDay(
                      CalendarFormatter.getDayFromDate(timesheetCycle)
                    );
                  }
                }}
                minDate={minDate}
                maxDate={maxDate}
                KeyboardButtonProps={{
                  "aria-lablel": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <br />
        <br />
        <div className="w-100 text-center">
          {cycleIndex === null ? (
            <Button onClick={addCycle} variant={"contained"} color="primary">
              Add
            </Button>
          ) : (
            <Button
              onClick={updateCycle}
              variant={"contained"}
              color="secondary"
            >
              Update
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div style={{ textAlign: "right", width: "100%" }}>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            setMinMax(history.length);
            openDialog();
          }}
        >
          <span>Add Cycle</span>
        </Button>
      </div>
      <MaterialTable
        title=""
        data={data}
        columns={columns}
        options={{
          paginationPosition: "top",
          pageSize: 5,
          paginationType: "normal",
          filtering: true,
        }}
      />

      {renderContent({
        title: cycleIndex === null ? "Add New Cycle" : "Update Cycle",
        dialogContent: dialogContent(),
        hideButton: true,
      })}
    </div>
  );
}

export default Presentation;
