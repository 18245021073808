import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import Presentation from "./Presentation";
import { addSectionToPlacement, updatePlacement } from "../../../../middleware";
//import { getStates } from "../../../../../../shared/middleware"
import stateLoader from "../../../../../../providers/stateLoader";
import CircularSpinner from "../../../../../../shared/circularSpinner";

function Container(props) {
  const {
    worklocation_data,
    placement,
    profile,
    addSectionToPlacement,
    updatePlacement,
    contacts_data = [],
    clientdetails_data
  } = props;
  console.log("clientdetails_data",clientdetails_data,worklocation_data);
  const initState = {
    type: "",
    email: "",
    phonenumber: "",
    phoneExtension: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    amendmentRequired: false,
    fillableSections: [],
    id: "",
    employeeID: profile.uid,
    states: [],
    managers: [],
    endClientContacts:[]
  };

  
  const [endClientId,setEndClientId] = useState("")

  const [clientList,setClientList] = useState([])

  const [state, setState] = useState(initState);

  console.log("countryData",state.country)

  const [contacts, setContacts] = useState([]);

  const callback = () => {};
  console.log(worklocation_data, placement, contacts_data);
  useEffect(() => {
    if (isLoaded(worklocation_data) && !isEmpty(worklocation_data)) {
      const { managers = [] } = worklocation_data;
      console.log(managers,"managers")
      setState((prevState) => ({
        ...prevState,
        type: worklocation_data.type,
        email: worklocation_data.email,
        phonenumber: worklocation_data.phonenumber,
        phoneExtension: worklocation_data.phoneExtension,
        line1: worklocation_data.line1,
        line2: worklocation_data.line2,
        city: worklocation_data.city,
        state: worklocation_data.state,
        country: worklocation_data.country,
        zip: worklocation_data.zip,
        amendmentRequired: worklocation_data.amendmentRequired,
        managers: managers,
      }));
      if (worklocation_data.state && worklocation_data.country) {
        loadStates(worklocation_data.country);
      }
    } else setState(() => ({ ...initState }));
    if (isLoaded(contacts_data) && !isEmpty(contacts_data)) {
      const contacts = contacts_data.map((contact) => {
        return { ...contact };
      });
      setContacts(contacts);
    }
    setState((prevState) => ({
      ...prevState,
      fillableSections: placement.fillableSections,
      id: placement.id,
    }));
    //  return () => setState(() => ({ ...initState }))
  }, [worklocation_data, JSON.stringify(placement), contacts_data.length]);


  useEffect(() => {
    if (isLoaded(clientdetails_data) && !isEmpty(clientdetails_data)) {
      console.log(clientdetails_data,clientdetails_data[0],'dfl')
    const EndClientId = (clientdetails_data[0].clients) ? (Object.values(clientdetails_data[0].clients)
                                      .filter((client) =>
                                                 client.clientType === "End Client" 
                                              ).length ? Object.values(clientdetails_data[0].clients)
                                              .filter((client) =>
                                                         client.clientType === "End Client" 
                                                      )[0].clientId : "" ):""
    const clientList = (clientdetails_data[0].clients) ? (Object.values(clientdetails_data[0].clients)).map((e) =>e.clientId)
                                      :[]                                    
    setEndClientId(EndClientId)
    setClientList(clientList)
    }
  },[clientdetails_data])



  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChecked = (event) => {
    const { name, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleKeyValuePair = (key, value) => {
    console.log("key,value", key, value);

    
    // if (key === "country") {
    //   if (value) loadStates(value)
    // }
    setState((state) => ({ ...state, [key]: value }));
  };

  const loadStates = async (code) => {
    try {
      let states = await stateLoader(code);
      setState((state) => ({ ...state, states }));
    } catch (error) {
      console.error(error);
      setState((state) => ({ ...state, states: [] }));
    }
  };

  const handleSubmit = (e) => {
    //props.handleChange("panel3")
    e.preventDefault();
    const { closeTab } = props;
    const payload = {};
    Object.entries(state).forEach(([key, value]) => {
      if (
        key !== "fillableSections" &&
        key !== "id" &&
        key !== "employeeID" &&
        key !== "states" &&
        key !== "endClientContacts"
      ) {
        payload[key] = value;
      }
    });
    if (state.fillableSections.includes("worklocation")) {
      addSectionToPlacement(
        payload,
        "worklocation",
        state.employeeID,
        state.id,
        callback,
        closeTab
      );
    } else {
      updatePlacement(
        payload,
        "worklocation",
        state.employeeID,
        state.id,
        callback,
        closeTab
      );
    }
  };

  if (isLoaded(worklocation_data) && isLoaded(clientdetails_data)) {
   
    console.log("--------------WORK_LOCATION---------------",state);
    return (
      <Presentation
        {...state}
        contacts={contacts}
        endClientId={endClientId}
        clientList={clientList}
        handleChange={handleChange}
        handleChecked={handleChecked}
        handleSubmit={handleSubmit}
        handleKeyValuePair={handleKeyValuePair}
      />
    );
  }
  return (
    <CircularSpinner />
  );
}

const mapStateToProps = (state, ownProps) => {
  const { ordered, data } = state.firestore;

  return {
    worklocation_data: data.worklocation_data,
    contacts_data: ordered.contacts_data,
    clientdetails_data:ordered.clientdetails_data
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addSectionToPlacement: (
      payload,
      sectionName,
      uid,
      placementID,
      callback,
      closeTab
    ) => {
      dispatch(
        addSectionToPlacement(
          payload,
          sectionName,
          uid,
          placementID,
          callback,
          closeTab
        )
      );
    },
    updatePlacement: (
      payload,
      sectionName,
      uid,
      placementID,
      callback,
      closeTab
    ) => {
      dispatch(
        updatePlacement(
          payload,
          sectionName,
          uid,
          placementID,
          callback,
          closeTab
        )
      );
    },
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    const { placement } = props;
    if (!placement.id) return [];
    return [
      {
        collection: "EMPLOYEES",
        doc: placement.employeeID,
        subcollections: [
          {
            collection: "PLACEMENTS",
            doc: placement.id,
            subcollections: [
              {
                collection: "SETTINGS",
                doc: "worklocation",
                storeAs: "worklocation",
              },
            ],
            storeAs: "placement_worklocation",
          },
        ],
        storeAs: "worklocation_data",
      },
      {
        collection: "EMPLOYEES",
        doc: placement.employeeID,
        subcollections: [
          {
            collection: "PLACEMENTS",
            doc: placement.id,
            subcollections: [
              {
                collection: "SETTINGS",
                doc: "client_details",
                storeAs: "client_details",
              },
            ],
            storeAs: "clientdetails_data",
          },
        ],
        storeAs: "clientdetails_data",
      },
      {
        collectionGroup: "CLIENTS_CONTACTS",
        where: [["clientId", "==", placement.clientId]],
        storeAs: "contacts_data",
      },
    ];
  })
)(Container);
