import React, { useState, useEffect, useContext } from 'react'
import Presentation from "./Presentation"
import { approveTimesheet } from "../../../middleware"
import { connect } from "react-redux"
import { Context } from "../component_state/context"
import { useQueryClient } from '@tanstack/react-query'

function Container(props) {
  console.log(props)
  const { employeeID, placementID, timesheetID, isApproved,RejectedTimesheetsFetch, isRejected, isInvoiced, isDefaulter, timesheetManager } = props.row
  const { _approve_timesheet, loggedInEmployee, isList ,isManager, queryEmployeeID } = props
  const [open, setOpen] = useState(false);
  const [hideBtn, setHideBtn] = useState(true)
  const [state, handle] = useContext(Context)

  const setState = (obj) => {
    handle({
      type: "SET_STATE",
      data: obj
    })
  }

  const queryClient = useQueryClient();

  useEffect(() => {
    // button hide conditions
    /* 
      1) if it is not his/her own timesheet
      2) if it is not approved
      3) if it is not defaulter
    */
    if (loggedInEmployee !== employeeID && !isApproved && !isDefaulter && !isInvoiced) {
      setHideBtn(false)
    }
  }, [isApproved, isDefaulter, isInvoiced])

  const onApprove = () => {
    // sending payload as empty for now, in future there may be possibility of sending non-empty
    handleClose()
    _approve_timesheet({}, employeeID, placementID, timesheetID, state.condition, setState, state, isManager, queryClient, queryEmployeeID)
  }


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Presentation
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        onApprove={onApprove}
        hideBtn={hideBtn}
        employeeID={employeeID}
      />
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    loggedInEmployee: state.firebase.auth.uid,
    modules: state.employee.employeeModules.accessModules,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    _approve_timesheet: (payload, employeeID, placementID, timesheetID, condition, setState, state, isManager, queryClient, queryEmployeeID) => dispatch(approveTimesheet(payload, employeeID, placementID, timesheetID, condition, setState, state, isManager, queryClient, queryEmployeeID))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
