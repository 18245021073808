import ACTION from "../actions/actionTypes"
import projectDashboardState from "./projectDashboardState"

export default function projectDashboardReducer(state = projectDashboardState, { type, payload }) {
  switch (type) {
    case ACTION.LOAD_CLIENT_LAYERS_FOR_PROJECT_REQ:
      return {
        ...projectDashboardState,
      }

    case ACTION.LOAD_CLIENT_LAYERS_FOR_PROJECT_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        error: null,
        data: payload,
      }

    case ACTION.LOAD_CLIENT_LAYERS_FOR_PROJECT_FAILURE:
      return {
        ...state,
        isLoaded: true,
        error: payload,
        data: {
          req_placements: [],
          clients_layers: [],
          vendors_data: [],
        },
      }

    case ACTION.SET_LISTENER_FOR_PROJECT:
      return {
        ...state,
        listener: payload,
      }

    case ACTION.LOAD_PROJECT_META_INFO_REQ:
      return {
        ...state,
        meta_info: {
          ...projectDashboardState.meta_info,
        },
      }

    case ACTION.LOAD_PROJECT_META_INFO_SUCCESS:
      return {
        ...state,
        meta_info: {
          ...state.meta_info,
          isLoaded: true,
          data: payload,
          error: null,
        },
      }

    case ACTION.LOAD_PROJECT_META_INFO_FAILURE:
      return {
        ...state,
        meta_info: {
          ...state.meta_info,
          isLoaded: true,
          error: payload,
          data: projectDashboardState.meta_info.data,
        },
      }

    case ACTION.UNSUBSCRIBE_LISTENER_FOR_PROJECT:
      return {
        ...state,
        listener: () => {},
      }

    default:
      return state
  }
}
