import React from 'react'
import {
  Grid,
  Typography,
  AccordionSummary,
  Accordion,
  Badge,
  AccordionDetails
} from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TimesheetsTreeView from "./InvoiceTreeView"
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useNewInvoiceContext } from '../context';
import { OpenInNew as OpenInNewIcon } from "@material-ui/icons"

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    // '&:hover > $content': {
    //   backgroundColor: theme.palette.action.hover,
    // },
    // '&:focus > $content, &$selected > $content': {
    //   backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
    //   color: 'var(--tree-view-color)',
    // },
    // '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
    //   backgroundColor: 'transparent',
    // },
  },
  content: {
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
    backgroundColor: "transparent"
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    flexGrow: 1,
  },
}));


function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;
  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          {/* <LabelIcon color="inherit" className={classes.labelIcon} /> */}
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          {/* <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography> */}
        </div>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}


function TimesheetsSelector() {
  const { state, stateSetters } = useNewInvoiceContext()
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Timesheets</Typography>
        {/* <span className="custom-chip chip-success">{count}</span> */}
      </AccordionSummary>
      <AccordionDetails>
        <TreeView
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
        >
          {
            state.timesheetsAccordingToInvoiceFrequency?.map((cycle, index) => {
              if (Object.values(cycle.timesheets).length)
                return (
                  <div>
                    <StyledTreeItem nodeId={index} labelText={cycle.cycleStartDate + '-' + cycle.cycleEndDate}>
                      <StyledTreeItem
                        // nodeId="5"
                        style={{ marginLeft: '-20px' }}
                        labelText={
                          <FormControlLabel
                            // checked={condition2}
                            control={
                              <Checkbox
                                size='small'
                                checked={Object.keys(cycle.timesheets).every(date => date in state.selectedTimesheets)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    stateSetters.setSelectedTimesheets((alreadySelected) => ({
                                      ...alreadySelected,
                                      ...cycle.timesheets
                                    }))
                                  } else {
                                    stateSetters.setSelectedTimesheets((alreadySelected) => {
                                      const modified = JSON.parse(JSON.stringify(alreadySelected))
                                      Object.keys(cycle.timesheets).forEach((date) => delete modified[date])
                                      return modified
                                    })
                                  }
                                }}
                              />
                            }
                            label={
                              <span style={{ fontSize: '10px' }} >{'Select all'}</span>
                            }
                          />
                        }
                      />
                      <Grid container>
                        {Object.values(cycle.timesheets)
                          .map((item) => {
                            return (
                              <Grid item xs={4}>
                                <StyledTreeItem
                                  // nodeId="5"
                                  style={{ marginLeft: '-20px' }}
                                  labelText={
                                    <FormControlLabel
                                      checked={item.date in state.selectedTimesheets}
                                      control={
                                        <Checkbox
                                          size='small'
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              stateSetters.setSelectedTimesheets((alreadySelected) => ({
                                                ...alreadySelected,
                                                [item.date]: item
                                              }))
                                            } else {
                                              stateSetters.setSelectedTimesheets((alreadySelected) => {
                                                const modified = JSON.parse(JSON.stringify(alreadySelected))
                                                delete modified[item.date]
                                                return modified
                                              })
                                            }
                                          }}
                                        />
                                      }
                                      label={
                                        <span style={{ fontSize: '10px' }} >{item.date}</span>
                                      }
                                    />
                                  }
                                />
                              </Grid>
                            )
                          })}
                      </Grid>

                    </StyledTreeItem>
                  </div>
                )
              return <div></div>
            })
          }
        </TreeView>
      </AccordionDetails>
    </Accordion>
  )
}

export default TimesheetsSelector
