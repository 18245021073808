import { defaultInstance } from "../../../instances/default.instance";

/**
 * @param {String} id
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const getGlobalPayrollSettings = (signal) => {
  return defaultInstance({
    method: "GET",
    url: `/payrolls/v2/global-settings`,
    signal,
  });
}

/**
 * @param {String} id
 * @param {Record} data
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const updateGlobalPayrollSettings = (data, signal) => {
  return defaultInstance({
    method: "PUT",
    url: `/payrolls/v2/global-settings`,
    data,
    signal,
  });
}