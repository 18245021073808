import React from "react"
import {
  Table,
  Paper,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Box,
  Collapse,
  IconButton,
  Typography,
} from "@material-ui/core"
import { OpenInNew as OpenInNewIcon } from "@material-ui/icons"
import { withStyles, createStyles } from "@material-ui/core/styles"
import { TimesheetsUTILS } from "../../../../shared/JSutils"
import validate from "../../../../shared/validation"
import EditTimesheet from "./EditTimesheet"
import { useNewInvoiceContext } from "./context"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell)

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow)

function StandardTimeTable(props) {
  const { state, services } = useNewInvoiceContext()
  const { hideActions = false } = props
  const timesheetsArr = Object.values(state.selectedTimesheets)
  const [open, setOpen] = React.useState(false);
  const isExternalInvoice = state.invoiceBy == "external"

  if (timesheetsArr?.length < 1) return <span></span>

console.log("timesheetsArr", timesheetsArr)
  const { minDate, maxDate } = services.getMinAndMaxDates(Object.keys(state.selectedTimesheets))
  const totalTime = services.getTotalTime(timesheetsArr.map(sh => sh.standardTime))
  const employeeName = services.getNameOfTheEmployeeById(state.employeeID)
  const billingRates = services.getBillingRatesInvolvedInTheTimesheets(timesheetsArr)
  const amount = services.getTotalAmountForTheTimesheetsAccordingToBillingRate(timesheetsArr)
  const totalTimeInHrs = services.getTotalTimeInHours(timesheetsArr.map(sh => sh.standardTime))
  return (
    <div>
      <>
        <h2>
          <u>Standard Time</u>
        </h2>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No</StyledTableCell>
                <StyledTableCell>Service Summary</StyledTableCell>
                <StyledTableCell>Activity</StyledTableCell>
                <StyledTableCell>Quantity(hh:mm)</StyledTableCell>
                <StyledTableCell>Total hours</StyledTableCell>
                <StyledTableCell>Attachment</StyledTableCell>
                {
                  !isExternalInvoice ?
                    <>
                      <StyledTableCell>Rate</StyledTableCell>
                      <StyledTableCell>Amount</StyledTableCell>
                    </>
                    :
                    null
                }

              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell>
                  {employeeName +
                    "-" +
                    state.employeeID}
                </StyledTableCell>
                <StyledTableCell>
                  {minDate + ' - ' + maxDate}
                </StyledTableCell>
                <StyledTableCell>
                  {totalTime}
                </StyledTableCell>
                <StyledTableCell>
                  {totalTimeInHrs}
                </StyledTableCell>
                <StyledTableCell>
                  {
                    // attachmentDetails.publicURL.length ? (
                    //   <a target="_blank" href={attachmentDetails.publicURL}>
                    //     <OpenInNewIcon fontSize="small" />
                    //   </a>
                    // ) : 
                    (
                      "No attachment"
                    )}
                </StyledTableCell>
                {
                  !isExternalInvoice ?
                    <>
                      <StyledTableCell>
                        {billingRates.map((i) => validate.currencyFormatterUs(i)).join(",")}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {validate.currencyFormatterUs(amount)}
                      </StyledTableCell>
                    </>
                    :
                    null
                }


              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0, border: '0px solid transparent' }} colSpan={8}>
                  <Collapse sx={{ width: '100%' }} in={open} timeout="auto" unmountOnExit>
                    <TableContainer component={Paper}>
                      <Table stickyHeader aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>S.No</StyledTableCell>
                            <StyledTableCell>Service Summary</StyledTableCell>
                            <StyledTableCell>Activity</StyledTableCell>
                            <StyledTableCell>Quantity(hh/mm)</StyledTableCell>
                            <StyledTableCell>Attachment</StyledTableCell>
                            {
                              !isExternalInvoice ?
                                <>
                                  <StyledTableCell>Rate</StyledTableCell>
                                  <StyledTableCell>Amount</StyledTableCell>
                                </>
                                :
                                null
                            }

                            {
                              !hideActions && <StyledTableCell>Actions</StyledTableCell>
                            }
                          </TableRow>

                        </TableHead>
                        <TableBody>
                          {
                            timesheetsArr.sort((a, b) => Date.parse(new Date(a.date)) - Date.parse(new Date(b.date))).map((item, index) => {
                              const { standardTime } = item
                              const perDayAmount = services.getTotalAmountForTheTimesheetsAccordingToBillingRate([item])
                              return (
                                <StyledTableRow>
                                  <StyledTableCell>
                                    {index + 1}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {employeeName +
                                      "-" +
                                      state.employeeID}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {item.date}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {standardTime}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {
                                      typeof item.attachment !== 'string' && item.attachment.length > 0 ? item.attachment.map((e)=> {
                                        return (
                                          <a target="_blank" href={e.publicURL}>
                                            <OpenInNewIcon fontSize="small" />
                                          </a>
                                        )
                                      }) 
                                      :(typeof item.attachment === 'string' ? 
                                       (
                                        <a target="_blank" href={item.attachment}>
                                          <OpenInNewIcon fontSize="small" />
                                        </a>
                                      ) :
                                        (
                                          "No attachment"
                                        ))}
                                  </StyledTableCell>
                                  {
                                    !isExternalInvoice ?
                                      <>
                                        <StyledTableCell>
                                          {validate.currencyFormatterUs(item.billingRate)}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                          {validate.currencyFormatterUs(perDayAmount)}
                                        </StyledTableCell>
                                      </>
                                      :
                                      null
                                  }
                                  {
                                    !hideActions &&
                                    <StyledTableCell>
                                      <EditTimesheet 
                                        timeSheetDetails = {item}
                                        perDayAmount = {perDayAmount}
                                        employeeName = {employeeName}
                                        employeeID = {state.employeeID}
                                        workedHours = {standardTime}
                                        rate = {item.billingRate}
                                        type = {"standardTime"}
                                      />
                                    </StyledTableCell>
                                  }

                                </StyledTableRow>
                              )
                            })
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Collapse>
                </StyledTableCell>
              </StyledTableRow>
              {/* )
                })} */}

              {
                state.invoiceBy !== "external" ?
                  <TableRow>
                    <TableCell colSpan={5} />
                    <TableCell>
                      <h4>Subtotal</h4>
                    </TableCell>
                    <TableCell align="right">
                      <h4>
                        {validate.currencyFormatterUs(amount)}
                      </h4>
                    </TableCell>
                  </TableRow>
                  : <span></span>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </div>
  )
}

export default StandardTimeTable