import { defaultInstance } from "../../../instances/default.instance";

/**
 * @param {Record} data
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const createEmployeePayrollSettings = (data, signal) => {
  return defaultInstance({
    method: "POST",
    url: "/payrolls/v2/employee-settings",
    data,
    signal,
  });
}

/**
 * @param {AbortSignal?} signal
 * @returns {Promise<Array<Record>>}
 */
export const getAllEmployeesPayrollSettings = (signal) => {
  return defaultInstance({
    method: "GET",
    url: "/payrolls/v2/employee-settings",
    signal,
  });
}

/**
 * @param {String} id
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const getEmployeePayrollSettings = (id, signal) => {
  return defaultInstance({
    method: "GET",
    url: `/payrolls/v2/employee-settings/${id}`,
    signal,
  });
}

/**
 * @param {String} id
 * @param {Record} data
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const updateEmployeePayrollSettings = (id, data, signal) => {
  return defaultInstance({
    method: "PUT",
    url: `/payrolls/v2/employee-settings/${id}`,
    data,
    signal,
  });
}

/**
 * @param {String} id
 * @param {AbortSignal?} signal
 * @returns {Promise<String>}
 */
export const deleteEmployeePayrollSettings = (id, signal) => {
  return defaultInstance({
    method: "DELETE",
    url: `/payrolls/v2/employee-settings/${id}`,
    signal,
  });
}