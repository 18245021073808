import React from "react"
import History from "../Services/History/Index"

function HistoryPage() {
  return (
    <div>
      <History />
    </div>
  )
}

export default HistoryPage
