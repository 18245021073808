import React from "react"
import MaterialTable, { MTableToolbar } from "material-table"
import { BsDownload } from "react-icons/bs"
import { Chip, Button } from "@material-ui/core"
import { downloadasZip } from "../../../../../../../shared/downloadZip"
import AddDocuments from "../UploadDocuments"

function Presentation(props) {
  const { data, isLoading, clientId, client_documents } = props

  const columns = [
    {
      title: "Document type",
      field: "documentType",
      lookup: {
        0: "Incorporation",
        1: "PO",
        2: "MSA",
        3: "COI",
        4: "W9",
        5: "MSA &PO",
        6: "Others",
      },
    },
    {
      title: "Status",
      field: "status",
      lookup: { 0: "Active", 1: "Inactive" },
      render: (rowData) => {
        if (rowData.status === 0)
          return (
            <Chip
              style={{
                width: "100%",
                backgroundColor: "#21ba45",
                color: "white",
              }}
              label="Active"
            />
          )
        return (
          <Chip
            style={{ width: "100%", backgroundColor: "orange", color: "white" }}
            label="Inactive"
          />
        )
      },
      customFilterAndSearch: (value, rowData) => {
        if (value.length === 0) return true
        return value.includes(rowData.status.toString())
      },
      width: "125px",
    },
    { title: "Effective Date", field: "effectiveDate" },
    { title: "Effective Until", field: "effectiveUntilDate" },
    {
      title: "Document",
      field: "work_doc",
      render: (row) => {
        const { name, url } = row.work_doc
        return (
          <a target="_blank" rel="noopener noreferrer" href={url}>
            {name.substr(0, name.lastIndexOf("_"))}
          </a>
        )
      },
    },
  ]

  return (
    <div>
      <MaterialTable
        data={data}
        columns={columns}
        title=""
        isLoading={isLoading || !client_documents.isLoaded}
        options={{
          paginationPosition: "top",
          pageSize: 10,
          paginationType: "normal",
          filtering: true,
          selection: true,
          columnsButton: true,
        }}
        components={{
          Toolbar: (props) => {
            return (
              <div>
                <MTableToolbar {...props} />
                <div style={{ padding: "0 10px" }}>
                  <div className="d-flex">
                    <div>
                      <Button
                        color="secondary"
                        onClick={(e) =>
                          downloadasZip(e, data, `${clientId} docs`)
                        }
                        variant="contained"
                      >
                        Download all <BsDownload size={20} />
                      </Button>
                    </div>
                    <div className="ml-3">
                      <AddDocuments clientId={clientId} />
                    </div>
                  </div>
                </div>
              </div>
            );
          },
        }}
        actions={[
          {
            tooltip: "Download All",
            icon: () => <BsDownload size={18} />,
            onClick: (evt, data) =>
              downloadasZip(evt, data, `${clientId} docs`),
          },
        ]}
      />
    </div>
  );
}

export default Presentation
