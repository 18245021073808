import React from "react"
import MaterialTable from "material-table"
import { IconButton } from "@material-ui/core"
import { Edit as EditIcon } from "@material-ui/icons"
import validate from "../../../../../../../shared/validation"

function Presentation(props) {
  const { history, handleUpdate, handleTabChange } = props
  /* *
    * The billing types will be in the form of "Per " so splitting them and getting second one
  */
  const columns = [
		{ title: 'Effective Date', field: 'effectiveDate', type: 'date' },
		{ title: 'Effective Until', field: 'effectiveUntil', type: 'date' },
		{
			title: 'Billing Rate',
			field: 'billingRate',
			render: (row) => {
				return (
					<div>
						{validate.currencyFormatterUs(row.billingRate)}/
						{row.billingType.split('Per ')[1]}
					</div>
				)
			},
			type: 'numeric',
		},
		{
			title: 'OT Billing Rate',
			field: 'OTbillingRate',
			render: (row) => {
				return (
					<div>
						{validate.currencyFormatterUs(row.OTbillingRate)}/
						{row.billingType.split('Per ')[1]}
					</div>
				)
			},
			type: 'numeric',
		},
		{
			title: 'Discount',
			field: 'discount',
			render: (row) => {
				if (row.discountType === 'byPercentage') {
					return <div>{row.discount}%</div>
				}
				return <div>{validate.currencyFormatterUs(row.discount)}</div>
			},
			type: 'numeric',
		},
		{ title: 'PO Number', field: 'purchaseOrderNumber', type: 'numeric' },
		{
			title: 'Percentage',
			field: 'percentage',
			render: ({ percentage }) => {
				return <div>{percentage}%</div>
			},
			type: 'numeric',
		},
		{
			title: 'Emp. Pay Rate',
			field: 'employeePayRate',
			render: (row) => {
				if (row.payType === 'Fixed Pay')
					return <div>{validate.currencyFormatterUs(row.fixedPay)}</div>

				if (row.payType === 'Hourly')
					return (
						<div>
							{validate.currencyFormatterUs(row.employeePayRate)}/Hourly
						</div>
					)

				return <div>{row.employeePayRate}</div>
			},
			type: 'numeric',
		},
		{
			title: 'OT Pay Rate',
			field: 'OTpayRate',
			render: (row) => {
				if (row.payType === 'Fixed Pay')
					return <div>{validate.currencyFormatterUs(row.fixedPay)}</div>

				if (row.payType === 'Hourly')
					return <div>{validate.currencyFormatterUs(row.OTpayRate)}/Hourly</div>

				return <div>{row.OTpayRate}</div>
			},
			type: 'numeric',
		},
		// { title: "Created At", field: "createdAt" },
		// { title: "Updated On", field: "updatedOn" },
		{
			title: "Actions",
			field: "",
			render: (rowData) => (
				<span>
					<IconButton
						onClick={(e) => {
							e.preventDefault()
							handleTabChange(null, 1)
							handleUpdate(e, rowData.tableData.id)
						}}
					>
						<EditIcon />
					</IconButton>
				</span>
			),
		},
	]

  let data = []

  history &&
    history.forEach((record) => {
      data.push({
        ...record,
        OTbillingRate: (record.OTbillingRate),
        effectiveDate: validate.dateFormatter(record.effectiveDate),
        effectiveUntil: validate.dateFormatter(record.effectiveUntil),
        fixedPay: (record.fixedPay),
        employeePayRate: (record.employeePayRate),
        OTpayRate: (record.OTpayRate),
        billingRate: (record.billingRate),
        discount: record.discountDetails?.value ?? 0,
        discountType: record.discountDetails?.type ?? ''
      })
    })

  console.log(history)

  return (
    <div>
      <MaterialTable
        title=""
        data={data}
        columns={columns}
        options={{
          paginationPosition: "top",
          pageSize: 10,
          paginationType: "normal",
          columnsButton: true,
          filtering: true,
        }}
      />
    </div>
  )
}

export default Presentation
