import React from "react"
import { Avatar, Tooltip } from "@material-ui/core"
import MetaInfo from "../../../../../../shared/getMetaInfo"
import { isBefore ,isAfter} from "date-fns";
import InvoiceLocation from "../InvoiceLocation/Index"
import DiscountDetails from "../Accounts/DiscountDetails"
import { Link } from "react-router-dom"

function Presentation(props) {
  const { client, accounts, placements_list, names,placements_settings_list, clients_locations,clientData } = props

  const metaInfo = new MetaInfo()

  const activePlacements = placements_list.filter(
    (placement) => new Date(placement.projectEndDate) > new Date()
  )
  const inactivePlacements = placements_list.filter(
    (placement) => new Date(placement.projectEndDate) < new Date()
  )

  const activeConsultants = activePlacements
  .filter((p) => {
    const clientDetails = placements_settings_list?.find((c) => c.placementID === p.id);
    if (!clientDetails) return false;
    const clients = Object.keys(clientDetails?.clients || {});
    const isClientExists = clients.includes(client.clientId);
    return (
      isClientExists &&
      !p.draft &&
      isBefore(new Date(), new Date(p.endDate)) &&
      !(p?.closingReason?.length > 0)
    );
  })
  .map((p) => p.employeeID);

  
  const inactiveConsultants = inactivePlacements
  .filter((p) => {
    const clientDetails = placements_settings_list?.find(
      (c) => c.placementID === p.id
    );
    if (!clientDetails) return false;
    const clients = Object.keys(clientDetails?.clients || {});
    const isClientExists = clients.includes(client.clientId);
    return (
      isClientExists &&
      !p.draft &&
      (isAfter(new Date(), new Date(p.endDate)) ||
        (p?.closingReason?.length > 0))
    );
  })
  .map((p) => p.employeeID);


  const activeDetailsExpanded = [...new Set(activeConsultants)].map(
    (employeeID) => names[employeeID]
  )

  const inActiveDetailsExpanded = [...new Set(inactiveConsultants)]
    .filter((uid) => !activeConsultants.includes(uid))
    .map((employeeID) => names[employeeID])

  const CardMaker = ({ name, value }) => {
    return (
      <div
        className={"custom-card p-5 text-center col-sm-2 border"}
        style={cardStyles}
      >
        <h5>{name}</h5>
        <h2>{value}</h2>
      </div>
    )
  }

  const invoiceLocation = clients_locations.filter((loc) => loc.id === client.invoiceLocationId)
  const count = clientData.activePlacementsList.map((employeeID) => names[employeeID])
const inActiveCount  = clientData.inActivePlacementsList.map((employeeID) => names[employeeID]).filter((item) => item !== undefined && 
!count.includes(item)
)
console.log({inActiveCount,c:clientData.inActivePlacementsList})
  return (
    <div>
      <div className="row">
        <CardMaker name="Net terms" value={client.netTerms} />
        <CardMaker name="Job termination notice" value={client.jobTerminationNotice} />
        <CardMaker name="Client type" value={client.category} />
        <div className="col-sm-3 custom-card border">
          <h2>Active consultants ({count.length}) </h2>
          <div className="row d-flex">
            {count.map((item) => (
              <div className="col">
                <Link to={`/console/employees/${item.uid}`}>
                  <Tooltip title={metaInfo.emailToName(item.uid)}>
                    <Avatar src={item.photoURL} />
                  </Tooltip>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="col-sm-3 custom-card border">
          <h2>Inactive consultants ({inActiveCount.length }) </h2>
          <div className="row d-flex">
            {inActiveCount.map((item) => (
              <div className="col">
                <Link to={`/console/employees/${item.uid}`}>
                  <Tooltip title={metaInfo.emailToName(item.uid)}>
                    <Avatar src={item.photoURL} />
                  </Tooltip>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="col-sm-6 custom-card border">
          <InvoiceLocation invoiceLocation={invoiceLocation[0]} invoiceLocationId = {client?.invoiceLocationId} id={client.id} />
        </div>
        <div className="col-sm-6 custom-card border">
          <DiscountDetails accounts={accounts[0]} client={client} />
        </div>
      </div>
    </div>
  )
}

export default Presentation

const cardStyles = {
  width: "200px",
}
