import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase"
import { compose } from "redux"
import Presentation from "./Presentation"
import { addSectionToPlacement, updatePlacement } from "../../../../middleware"
import CircularSpinner from "../../../../../../shared/circularSpinner"

function Container(props) {
  const {
    recruitmentDetails_data,
    benchSalesList,
    placement,
    addSectionToPlacement,
    profile,
    updatePlacement,
    contacts_data,
    clientdetails_data
  } = props

  const initState = {
    companyIDs: [],
    benchSalesList: [],
    fillableSections: [],
    id: "",
    employeeID: profile.uid,
    recruitersList: []
  }

  const [state, setState] = useState(initState)

  const [clientList, setClientList] = useState([])
  const callback = () => { }

  const handleEmployees = (key, value) => {
    setState((state) => ({ ...state, [key]: value }));
  };
  useEffect(() => {
    if (isLoaded(recruitmentDetails_data) && !isEmpty(recruitmentDetails_data)) {
      const { recruitersList = [] } = recruitmentDetails_data;
      setState((prevState) => ({
        ...prevState,
        companyIDs: recruitmentDetails_data.companyIDs,
        recruitersList: recruitmentDetails_data.recruitersList ? recruitmentDetails_data.recruitersList : []
      }))
    } else setState(() => ({ ...initState }))
    setState((prevState) => ({
      ...prevState,
      fillableSections: placement.fillableSections,
      id: placement.id,
      benchSalesList,
    }))

    // return () => setState(() => ({ ...initState }))
  }, [recruitmentDetails_data, benchSalesList, placement])
  useEffect(() => {
    if (isLoaded(clientdetails_data) && !isEmpty(clientdetails_data)) {

      const clientList = (clientdetails_data.clients) ? (Object.values(clientdetails_data.clients)).map((e) => e.clientId)
        : []
      setClientList(clientList)
    }
  }, [clientdetails_data])

  const handleSubmit = (e) => {
    //props.handleChange("panel7")
    e.preventDefault()
    const { closeTab } = props
    if (state.fillableSections.includes("recruitment_details")) {
      console.log("create")
      addSectionToPlacement(
        { companyIDs: state.companyIDs, recruitersList: state.recruitersList },
        "recruitment_details",
        state.employeeID,
        state.id,
        callback,
        closeTab
      )
    } else {
      console.log("update")
      updatePlacement(
        {
          companyIDs: state.companyIDs,
          recruitersList: state.recruitersList
        },
        "recruitment_details",
        state.employeeID,
        state.id,
        callback,
        closeTab
      )
    }
  }

  if (isLoaded(recruitmentDetails_data) && isLoaded(contacts_data) && isLoaded(clientdetails_data)) {
    console.log("--------------RECRUITMENT---------------", clientList);
    return (
      <Presentation
        {...state}
        clientList={clientList}
        handleEmployees={handleEmployees}
        handleSubmit={handleSubmit}
      />
    )
  }

  return (
    <CircularSpinner />
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    recruitmentDetails_data: state.firestore.data.recruitmentDetails_data,
    contacts_data: state.firestore.data.contacts_data,
    clientdetails_data: state.firestore.data.clientdetails_data,
    benchSalesList: Object.entries(state.employee.employeeList.metaInfoofEmployees.data)
      .map(([key, value]) => {
        return {
          ...value,
        }
      })
      .filter(
        (item) =>
          (item.uid !== ownProps.profile.uid &&
            item.department === "Bench Sales") ||
          item.designation === "admin"
      ),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addSectionToPlacement: (payload, sectionName, uid, placementID, callback, closeTab) => {
      dispatch(addSectionToPlacement(payload, sectionName, uid, placementID, callback, closeTab))
    },
    updatePlacement: (payload, sectionName, uid, placementID, callback, closeTab) => {
      dispatch(updatePlacement(payload, sectionName, uid, placementID, callback, closeTab))
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    const { placement } = props
    console.log(props, "props")
    if (!placement.id) return []
    return [
      {
        collection: "EMPLOYEES",
        doc: placement.employeeID,
        subcollections: [
          {
            collection: "PLACEMENTS",
            doc: placement.id,
            subcollections: [
              {
                collection: "SETTINGS",
                doc: "recruitment_details",
                storeAs: "recruitmentDetails",
              },
            ],
            storeAs: "placement_recruitmentDetails",
          },
        ],
        storeAs: "recruitmentDetails_data",
      },
      {
        collection: "EMPLOYEES",
        doc: placement.employeeID,
        subcollections: [
          {
            collection: "PLACEMENTS",
            doc: placement.id,
            subcollections: [
              {
                collection: "SETTINGS",
                doc: "client_details",
                storeAs: "client_details",
              },
            ],
            storeAs: "clientdetails_data",
          },
        ],
        storeAs: "clientdetails_data",
      },
      {
        collectionGroup: "CLIENTS_CONTACTS",
        where: [["clientId", "==", placement.clientId]],
        storeAs: "contacts_data",
      },
    ]
  })
)(Container)
