import { isBefore } from "date-fns";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import CircularSpinner from "../../../../shared/circularSpinner";
import Presentation from "./Presentation";
import { getAllClients } from "../../../../api/services/default/clients";
import { useQuery } from "@tanstack/react-query";

function Container(props) {
  const { clients, placements_list, names, placements_settings_list } = props

  const [state, setState] = useState({
    data: [],
    status: false,
  })

	const { data: clientsData,isFetched :isLoading } = useQuery({
		queryKey: ["getAllClients"],
		queryFn: () => getAllClients(),

	});
  useEffect(() => {
    const statusList = ["Active", "Inactive"]
    if (isLoading  ) {
        let data =  clientsData.length &&(Object.values(clientsData))?.map((client) => {
        const count = client.activePlacementsList.map((employeeID) => names[employeeID])
        return {
          businessDisplayName: client.businessDisplayName,
          businessName: client.businessName,
          clientType: client.category,
          clientId: client.clientId,
          netTerms: client.netTerms,
          jobTerminationNotice: client.jobTerminationNotice,
          status:
            count.length === 0
              ? statusList.indexOf("Inactive")
              : statusList.indexOf("Active"),
          activeConsultants: count.length,
          totalPlacements: client.totalPlacements,
          activePlacements: client.activePlacements,
        };
      });
      setState((prevState) => ({
        ...prevState,
        data,
        status: true,
      }))
    }
  }, [clients, placements_list, names, placements_settings_list,isLoading,clientsData])
  if (state.status) return <Presentation {...state} />
  return (
    <CircularSpinner />
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    names: state.employee.employeeList.metaInfoofEmployees.data,
  }
}

export default connect(mapStateToProps)(Container)