import { defaultInstance } from "../../instances/default.instance";

/**
 * @param {Record} data
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const createLayout = (data, signal) => {
	return defaultInstance({
		method: "POST",
		url: "/layout",
		data,
		signal,
	});
};

/**
 * @param {String?} tableUrl
 * @param {AbortSignal?} signal
 * @returns {Promise<Array<Record>>}
 */
export const getAllLayouts = (tableUrl, signal) => {
  const params = new URLSearchParams();
  if (tableUrl) {
    params.append("tableUrl", tableUrl);
  }
  return defaultInstance({
    method: "GET",
    url: `/layout?${params.toString()}`,
    signal,
  });
};

/**
 * @param {String} id
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const getLayout = (id, signal) => {
  return defaultInstance({
    method: "GET",
    url: `/layout/${id}`,
    signal,
  });
};

/**
 * @param {String} id
 * @param {Array<Record>} data
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const updateLayout = (id, data, signal) => {
  return defaultInstance({
    method: "PUT",
    url: `/layout/${id}`,
    data,
    signal,
  });
};

/**
 * @param {String} id
 * @param {AbortSignal?} signal
 * @returns {Promise<String>}
 */
export const deleteLayout = (id, signal) => {
  return defaultInstance({
    method: "DELETE",
    url: `/layout/${id}`,
    signal,
  });
};