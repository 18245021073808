import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import { compose } from "redux"
import Presentation from "./Presentation"
import { uploadToStorage } from "../../../../shared/fileUploader"
import { addNewArticle, updateArticle } from "../../middleware"
import { useHistory } from "react-router-dom"
import CircularSpinner from "../../../../shared/circularSpinner"

function Container(props) {
  const {
    articleId,
    isEdit,
    categoryMetaInfo,
    selectedArticle,
    onAddArticle,
    onUpdateArticle,
    search,
  } = props

  const history = useHistory()

  const [state, setState] = useState({
    title: "",
    categoryId: "",
    content: "",
    isUploading: false,
    attachments: [],
    categoryMetaInfo: [],
  })

  useEffect(() => {
    if (isLoaded(categoryMetaInfo)) {
      const metaInfo = Object.entries(categoryMetaInfo[0].types).map(
        ([key, value]) => {
          return {
            name: value,
            id: key,
          }
        }
      )
      setState((state) => ({ ...state, categoryMetaInfo: metaInfo }))
    }

    if (search !== "new") setState((state) => ({ ...state, title: search }))

    if (isEdit) {
      if (isLoaded(selectedArticle)) {
        const { categoryId, title, content, attachments } = selectedArticle[0]
        setState((state) => ({
          ...state,
          categoryId,
          title,
          content,
          attachments,
        }))
      }
    }
  }, [selectedArticle, categoryMetaInfo])

  const handleChange = (event) => {
    const { name, value } = event.target
    setState((state) => ({ ...state, [name]: value }))
  }

  const handleKeyValuePairs = (key, value) => {
    setState((state) => ({ ...state, [key]: value }))
  }

  const handleFile = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      setState((state) => ({ ...state, file }))
      console.log(file.name)
      fileUpload(file)
    }
  }

  const fileUpload = async (file) => {
    const fileName = file.name.split(".")[0]
    const filePath = `Wiki/Documents/${fileName}`
    setState((prevState) => ({ ...prevState, isUploading: true }))
    uploadToStorage(file, filePath, fileName, "file")
      .then((url) => {
        console.log(url)
        setState((prevState) => ({
          ...prevState,
          attachments: prevState.attachments.concat({ name: file.name, url }),
          isUploading: false,
        }))
      })
      .catch((err) => {
        console.error(err)
        setState((prevState) => ({ ...prevState, isUploading: false }))
      })
  }

  const handleDeleteAttachment = (index) => {
    const updatedAttachments = state.attachments.filter((_, i) => i !== index)
    setState((state) => ({ ...state, attachments: updatedAttachments }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (isEdit) {
      console.log("edit")
      onUpdateArticle(
        {
          title: state.title,
          content: state.content,
          attachments: state.attachments,
        },
        articleId,
        state.categoryId,
        history
      )
    } else {
      console.log("new")
      onAddArticle(
        {
          title: state.title,
          content: state.content,
          attachments: state.attachments,
        },
        state.categoryId,
        history
      )
    }
  }

  return isLoaded(categoryMetaInfo) && state.categoryMetaInfo.length ? (
    <div>
      <Presentation
        {...state}
        isEdit={isEdit}
        handleChange={handleChange}
        handleKeyValuePairs={handleKeyValuePairs}
        handleFile={handleFile}
        handleDeleteAttachment={handleDeleteAttachment}
        handleSubmit={handleSubmit}
      />
    </div>
  ) : (
    <CircularSpinner />
  )
}

const mapStateToProps = (state, ownProps) => {
  const firestore = state.firestore.ordered
  return {
    selectedArticle: firestore.selectedArticle,
    categoryMetaInfo: firestore.categoryMetaInfo,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onAddArticle: (payload, categoryId, history) => {
      dispatch(addNewArticle(payload, categoryId, history))
    },
    onUpdateArticle: (payload, articleId, categoryId, history) => {
      dispatch(updateArticle(payload, articleId, categoryId, history))
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (props.isEdit)
      return [
        {
          collection: "WIKI",
          where: [
            ["id", "==", props.articleId],
            ["isExist", "==", true],
          ],
          storeAs: "selectedArticle",
        },
        {
          collection: "ID_TRACKER",
          doc: "categories",
          storeAs: "categoryMetaInfo",
        },
      ]
    return [
      {
        collection: "ID_TRACKER",
        doc: "categories",
        storeAs: "categoryMetaInfo",
      },
    ]
  })
)(Container)
