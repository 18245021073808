import { defaultInstance } from "../../instances/default.instance";

/**
 * @param {Record} params
 * @param {String?} params.clientId
 * @param {String?} params.employeeID
 * @param {Boolean?} params.draft
 * @param {Boolean?} params.includeSectionsWithEmployee
 * @param {AbortSignal?} signal
 * @returns {Promise<Record[]>}
 */
export const getAllClients = ( signal) => {
  const query = new URLSearchParams();
  return defaultInstance({
    method: 'GET',
    url: `/clients/v2?${query.toString()}`,
    signal,
  });
};

/**
 * @param {String} id
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const getClient = (id, signal) => {
  return defaultInstance({
    method: 'GET',
    url: `/clients/v2/${id}`,
    signal,
  });
};

/**
 * @param {String} id
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const getClientAccounts = (id, signal) => {
  return defaultInstance({
    method: 'GET',
    url: `/clients/v2/accounts/${id}`,
    signal,
  });
};


/**
 * @param {String} id
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const getClientLocations = (id, signal) => {
  return defaultInstance({
    method: 'GET',
    url: `/clients/v2/locations/${id}`,
    signal,
  });
};

/**
 * @param {String} id
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const getClientContacts = (id, signal) => {
  return defaultInstance({
    method: 'GET',
    url: `/clients/v2/contacts/${id}`,
    signal,
  });
};