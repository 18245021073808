import React from "react";
import { Formik, Form, Field } from "formik";
import { Row, Col, Modal } from "react-bootstrap";
import AppModalHeader from "../../../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../../../shared/components/Modals/AppModalFooter";
import TextFormField from "../../../../../shared/components/BootStrapFormFields/TextFormField";
import SelectFormField from "../../../../../shared/components/BootStrapFormFields/SelectFormField";
import { useDialog } from "../../../../../hooks/useDialog";
import { FiSettings } from "react-icons/fi";
import * as yup from "yup";
import {
	getEmployeePayrollSettings,
	updateEmployeePayrollSettings,
} from "../../../../../api/services/default/payrolls/employee-settings";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import MetaInfo from "../../../../../shared/getMetaInfo";
import { getAllPlacements } from "../../../../../api/services/default/placements";
import { CalendarFormatter } from "../../../../../shared/CalendarFormatter";
import { differenceInDays } from "date-fns";
import CheckBoxField from "../../../../../shared/components/BootStrapFormFields/CheckBoxField";

const schema = yup.object().shape({
	dealPay: yup.number().required("Deal Pay is required"),
	dealPeriod: yup.number().required("Deal Period is required"),
	insurance: yup.number().required("Insurance is required"),
	fixedPay: yup
		.number()
		.required("Fixed pay is required")
		.test(
			"is-between-min-max",
			"Fixed pay must be between the minimum and maximum wage",
			function (value) {
				const { minWage, maxWage } = this.parent;
				return value >= minWage && value <= maxWage;
			}
		),
	defaultPlacement: yup
		.string()
		.test(
			"require-for-active",
			"Default placement is required",
			function (value) {
				const { isNonWorking } = this.parent;
				return !isNonWorking ? Boolean(value) : true;
			}
		),
});

export default function EmployeeSettings({
	id,
	reportingDate,
	workAuthData,
	isNonWorking = false,
}) {
	const { open, handleOpen, handleClose } = useDialog();
	const metaInfo = new MetaInfo();

	const queryClient = useQueryClient();

	const { data, refetch } = useQuery({
		queryKey: ["getEmployeePayrollSettings", id],
		queryFn: () => getEmployeePayrollSettings(id),
		enabled: Boolean(id) && open,
	});

	const { data: placements } = useQuery({
		queryKey: ["getAllPlacements", { employeeID: id }],
		queryFn: () => getAllPlacements({ employeeID: id, draft: false }),
		enabled: Boolean(id) && open,
	});

	const workAuth = workAuthData?.length
		? workAuthData[workAuthData.length - 1]
		: {};
	const useUpdate = useMutation({
		mutationFn: ({ id, data }) => updateEmployeePayrollSettings(id, data),
	});

	const handleSubmit = async (values, actions) => {
		actions.setSubmitting(true);
		await useUpdate.mutateAsync({
			id: id,
			data: {
				dealPay: values.dealPay,
				dealPeriod: values.dealPeriod,
				insurance: values.insurance,
				fixedPay: values.fixedPay,
				defaultPlacement: values.defaultPlacement,
			},
		});
		await refetch();
		await queryClient.invalidateQueries(["getGeneralReport", reportingDate]);
		handleClose();
		actions.resetForm();
		actions.setSubmitting(false);
	};

	const mapStatus = (active) => {
		if (active === "Y") {
			return "Active";
		} else if (active === "N") {
			return "Inactive";
		}
		return "N/A";
	};

	const optionMapper = (placement) => {
		const billableClient = placement.clients?.billableClient ?? {};
		const endClient = placement.clients?.endClient ?? {};
		return {
			value: placement.placementID,
			label: [
				placement.placementID,
				metaInfo.clientIdToName(billableClient?.clientId),
				metaInfo.clientIdToName(endClient?.clientId),
			]
				.filter(Boolean)
				.join("_"),
		};
	};

	return (
		<>
			<button className="btn" onClick={handleOpen}>
				<FiSettings size={20} className="text-success" />
			</button>
			<Modal show={open} onHide={handleClose} size="lg" centered>
				<Formik
					validationSchema={schema}
					initialValues={{
						...data,
						...workAuth,
						...data?.placement?.placementData,
						isNonWorking,
					}}
					onSubmit={handleSubmit}
					enableReinitialize
					validateOnMount
				>
					{(formik) => {
						return (
							<Form>
								<AppModalHeader title="Employee Payroll Settings" />
								<Modal.Body>
									<Row>
										<Col xl={12}>
											<div className="emplbg mb-2">
												<div className="d-block d-lg-flex justify-content-between my-1">
													<div>
														<div className="d-flex">
															<div className="keyicon fs-15 fw-medium">
																{formik.values?.work_type}
															</div>
															<div className="ms-2">
																<h5 className="mb-2 mt-0 align-self-center fw-bold fs-15">
																	{formik.values?.work_type}{" "}
																	<span
																		className={
																			formik.values?.active === "Y"
																				? "text-success fw-medium mt-0 mb-1"
																				: "text-warning fw-medium mt-0 mb-1"
																		}
																	>
																		{mapStatus(formik.values?.active)}
																	</span>
																</h5>
																<h6 className="text-muted fw-normal mt-0 mb-1">
																	Number {formik.values?.work_number}
																</h6>
																<h6 className="text-muted fw-normal mt-0 mb-1">
																	<span className="pe-2">
																		Valid From {formik.values?.valid_from}
																	</span>{" "}
																	Expiry {formik.values?.expiry_date}
																</h6>
																<h6 className="text-muted fw-normal mt-0 mb-1">
																	Issued on {formik.values?.work_issue}
																</h6>
															</div>
														</div>
													</div>
													<div className="d-flex mt-2 mt-lg-0">
														<a
															href={formik.values?.document}
															target="_blank"
															className="wauth me-1 badge-soft-danger badge rounded-pill fw-medium"
															rel="noreferrer"
														>
															<i className="fa-regular fa-folder-open pe-1"></i>
															View Document
														</a>
													</div>
												</div>
											</div>
										</Col>
									</Row>
									<Row>
										<Col xl={12}>
											<div className="d-flex justify-content-between align-items-center">
												<p
													className="text-bold mb-0"
													style={{ fontSize: "14px", fontWeight: "bold" }}
												>
													MinWage: {formik.values?.minWage}
												</p>
												<p
													className="text-bold mb-0"
													style={{ fontSize: "14px", fontWeight: "bold" }}
												>
													{"<="}
												</p>
												<Field
													name="fixedPay"
													label={"Fixed pay *"}
													type="number"
													component={TextFormField}
												/>
												<p
													className="text-bold mb-0"
													style={{ fontSize: "14px", fontWeight: "bold" }}
												>
													{"<="}
												</p>
												<p
													className="text-bold mb-0"
													style={{ fontSize: "14px", fontWeight: "bold" }}
												>
													MaxWage: {formik.values?.maxWage}
												</p>
											</div>
										</Col>
									</Row>
									<Row>
										<Col sm={12} xl={5}>
											<Col xl={12}>
												<Field
													name="dealPay"
													type="number"
													label={"Deal pay *"}
													component={TextFormField}
												/>
											</Col>
											<Col xl={12}>
												<Field
													name="dealPeriod"
													type="number"
													label={"Deal period *"}
													component={TextFormField}
												/>
											</Col>
											<Col xl={12}>
												<Field
													name="insurance"
													type="number"
													label={"Insurance *"}
													component={TextFormField}
												/>
											</Col>
											{!isNonWorking && (
												<Col xl={12}>
													<Field
														name="defaultPlacement"
														label="Default Placement *"
														type="select"
														options={placements?.map(optionMapper)}
														component={SelectFormField}
													/>
												</Col>
											)}
										</Col>
										<Col sm={12} xl={7}>
											<Row>
												<Col xl={6}>
													<Field
														name="dealPeriodDays"
														label="Deal Pending Days"
														type="number"
														component={TextFormField}
													/>
												</Col>
												<Col xl={6}>
													<Field
														name="isDealDone"
														label="Deal Done"
														component={CheckBoxField}
														disabled={true}
													/>
												</Col>
												<Col xl={12}>
													<DealPeriodCycles settings={formik.values} />
												</Col>
											</Row>
										</Col>
									</Row>
								</Modal.Body>
								<AppModalFooter
									saveButtonDisabled={
										!formik.isValid || formik.isSubmitting || !formik.dirty
									}
								/>
							</Form>
						);
					}}
				</Formik>
			</Modal>
		</>
	);
}

function DealPeriodCycles({ settings }) {
	return (
		<table className="table table-striped">
			<thead>
				<th className="text-center">Start Date</th>
				<th className="text-center">End Date</th>
				<th className="text-center">Deal duration</th>
			</thead>
			<tbody>
				{settings?.cycles?.map((cycle) => {
					const startDate = new Date(cycle.startDate);
					const endDate = new Date(cycle.endDate);

					return (
						<tr key={startDate}>
							<td className="text-center">
								{CalendarFormatter.standardDateFormat(cycle.startDate)}
							</td>
							<td className="text-center">
								{CalendarFormatter.standardDateFormat(cycle.endDate)}
							</td>
							<td className="text-center">
								<p className="badge badge-soft-success rounded-pill">
									{formatDifferenceInDays(startDate, endDate)}
								</p>
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}

function formatDifferenceInDays(startDate, endDate) {
	const diff = differenceInDays(endDate, startDate) + 1;
	return `${diff} day${diff > 1 ? "s" : ""}`;
}
