import { defaultInstance } from "../../../instances/default.instance";

/**
 * @param {Number} month
 * @param {Number} year
 * @param {String[]} employeeIds
 * @param {AbortSignal?} signal
 * @returns {Promise<Array<Record>>}
 */
export const getGeneralReport = (month, year, employeeIds, signal) => {
	if (isNaN(month) || isNaN(year)) {
		return Promise.reject(new Error("Month and year are required"));
	}
	const params = new URLSearchParams({ month, year });
	if (Array.isArray(employeeIds)) {
		params.append("employeeIds", employeeIds);
	}
	return defaultInstance({
		method: "GET",
		url: `/payrolls/v2/report?${params}`,
		signal,
	});
};

/**
 * @param {Number} month
 * @param {Number} year
 * @param {String[]} employeeIds
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const getExitReport = (month, year, employeeIds, signal) => {
	if (isNaN(month) || isNaN(year)) {
		return Promise.reject(new Error("Month and year are required"));
	}
	const params = new URLSearchParams({ month, year, isExitReport: true });
	if (Array.isArray(employeeIds)) {
		params.append("employeeIds", employeeIds);
	}
	return defaultInstance({
		method: "GET",
		url: `/payrolls/v2/report?${params}`,
		signal,
	});
};

/**
 * @param {String} id
 * @param {Record} data
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const clearEmployee = (id, data, signal) => {
	return defaultInstance({
		method: "PUT",
		url: `/payrolls/v2/report/${id}/clear-employee`,
		data,
		signal,
	});
};

/**
 * @param {Record} data
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const freezePayroll = (data, signal) => {
	return defaultInstance({
		method: "POST",
		url: `/payrolls/v2/report/freeze`,
		data,
		signal,
	});
};

/**
 * @param {Record} data
 * @param {Array<string>} data.soaIds
 * @param {AbortSignal?} signal
 * @returns {Promise<String>}
 */
export const rollbackPayroll = (data, signal) => {
	return defaultInstance({
		method: "PUT",
		url: `/payrolls/v2/report/rollback`,
		data,
		signal,
	});
};

/**
 * @param {Record} data
 * @param {Array<string>} data.soaIds
 * @param {AbortSignal?} signal
 * @returns {Promise<String>}
 */
export const markAsPaid = (data, signal) => {
	return defaultInstance({
		method: "PUT",
		url: `/payrolls/v2/report/mark-as-paid`,
		data,
		signal,
	});
};

/**
 * @param {Record} data
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 * @returns {Promise<Record>}
 */
export const createBonusPay = (data, signal) => {
	return defaultInstance({
		method: "POST",
		url: `/payrolls/v2/report/bonus-pay`,
		data,
		signal,
	});
};

/**
 * @param {String} id
 * @param {Record} data
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const updateBonusPay = (id, data, signal) => {
	return defaultInstance({
		method: "PUT",
		url: `/payrolls/v2/report/bonus-pay/${id}`,
		data,
		signal,
	});
};
