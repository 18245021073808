import React from "react"
import MaterialTable, { MTableToolbar } from "material-table"

function CustomTable({ data, columns, ToolBar, isToolBar, isLoading, selection = true }) {
  return (
    <div>
      <MaterialTable
        title=""
        data={data}
        columns={columns}
        isLoading={isLoading}
        options={{
          pageSize: 10,
          paginationPosition: "top",
          paginationType: "normal",
          filtering: true,
          selection: selection,
          columnsButton: true,
          exportButton: true,
        }}
        components={{
          Toolbar: (props) => {
            return (
              <div>
                <MTableToolbar {...props} />
                <div style={{ padding: "0 10px" }}>
                  {isToolBar ? <ToolBar /> : null}
                </div>
              </div>
            )
          },
        }}
      />
    </div>
  )
}

export default CustomTable
