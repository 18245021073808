import React from "react";
import { Paper, Tabs, Tab, Badge, AppBar } from "@material-ui/core";
import PropTypes from "prop-types";
import ProjectList from "../ProjectTable";
import { Link } from "react-router-dom";
import TabPanel from "../../../../../shared/tabPanel";
import NewProject from "../../NewProject";
import useStyles from "../../../Styles/ProjectListStyles";

export default function TabsView(props) {
  const classes = useStyles();
  const {
    handleTabChange,
    tabValue,
    modules,
    listAll,
    state,
    projectsCount = { totalCount: 0, Closed: 0, InProgress: 0, OverDue: 0 },
    reqProjects = [],
    isClientView = false,
  } = props;
  const projectsList = Object.values(state["allProjects"].data);
  if (isClientView)
    return (
      <ProjectList
        condition={0}
        reqProjects={reqProjects}
        isClientView={isClientView}
      />
    );
  return (
    <div>
      <div className={classes.projectsarea}>
        <AppBar position="static" color="default">
          <Paper square>
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              aria-label="disabled tabs example"
            >
              <Tab
                label="All"
                // label={
                //   <Badge
                //     color="primary"
                //     children="All"
                //     max="100000"
                //     badgeContent={projectsCount.totalCount}
                //   />
                // }
              />
              <Tab
                label="InProgress"
                // label={
                //   <Badge
                //     color="primary"
                //     children="InProgress"
                //     max="100000"
                //     badgeContent={projectsCount.Open}
                //   />
                // }
              />
              <Tab
                label="OverDue"
                // label={
                //   <Badge
                //     color="primary"
                //     children="OverDue"
                //     max="100000"
                //     badgeContent={projectsCount.OverDue}
                //   />
                // }
              />
              <Tab
                label="Closed"
                // label={
                //   <Badge
                //     color="primary"
                //     children="Closed"
                //     max="100000"
                //     badgeContent={projectsCount.Closed}
                //   />
                // }
              />
            </Tabs>
            {/* {modules.includes("task-management-manager") ||
              modules.includes("console-customization") ? (
                <div
                  style={{
                    marginTop: "-47px",
                    marginBottom: "7px",
                  }}
                >
                  <div className={classes.flex}>
                    <NewProject projectsList={projectsList} />
                  </div>
                </div>
              ) : null} */}
          </Paper>
        </AppBar>
        <TabPanel value={tabValue} index={0}>
          <ProjectList
            condition={0}
            reqProjects={reqProjects}
            isClientView={isClientView}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ProjectList
            condition={1}
            reqProjects={reqProjects}
            isClientView={isClientView}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <ProjectList
            condition={2}
            reqProjects={reqProjects}
            isClientView={isClientView}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <ProjectList
            condition={3}
            reqProjects={reqProjects}
            isClientView={isClientView}
          />
        </TabPanel>
      </div>
    </div>
  );
}
