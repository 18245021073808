import React from "react"
import VisibilityIcon from "@material-ui/icons/Visibility"
import {
  IconButton,
  Tooltip
} from "@material-ui/core"
import { Modal,Button } from "react-bootstrap"
import VoidInvoice from "./VoidInvoice"
import InvoicePreview from "../../InvoicePreview/index"
import FollowupInvoice from "./FollowupInvoice"
import EditFollowup from "./EditFollowup"
import { useDialog } from "../../../../../hooks/useDialog"
import AppModalHeader from "../../../../../shared/components/Modals/AppModalHeader"

function InvoicePreviewAction(props) {
  const { open, handleOpen, handleClose } = useDialog();
  const { isBulkPaymentPreviewAction = false, renderComponent } = props; 
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Tooltip title="Preview">
        {
          !isBulkPaymentPreviewAction ?
          <IconButton style={{ color: "#2C9273", padding: " 0px 5px"}} onClick={handleOpen}>
            <VisibilityIcon size={20} />
          </IconButton>
            :
            <div style={{cursor: "pointer"}} onClick={handleOpen}>
              {renderComponent()}
            </div>
        }
      </Tooltip>
      <div>
      <Modal show={open} onHide={handleClose} centered size="xl">
        <AppModalHeader title="Invoice Preview" />
        <Modal.Body>
          <InvoicePreview invoiceID={props.invoiceID} />
        </Modal.Body>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              {
                !isBulkPaymentPreviewAction ?
                <>
                  <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
                    {props.followupComment || props.tagType? (
                      <EditFollowup
                        showButton={true}
                        followupComment={props.followupComment}
                        invoiceID={props.invoiceID}
                        tagType={props.tagType}
                        employeeID={props.employeeID}
                      />
                      ) : (
                        <FollowupInvoice
                          invoiceID={props.invoiceID}
                          isPaymentDone={props.isPaymentDone}
                          isVoid={props.isVoid}
                          employeeID={props.employeeID}
                        />
                      )}
                  </div>
                  <div style={{  marginRight: '10px'}}>
                  <VoidInvoice
                    invoiceID={props.invoiceID}
                    isVoid={props.isVoid}
                    isPaymentDone={props.isPaymentDone}
                    isPreviewVoid={true}
                    employeeID={props.employeeID}
                  />
                  </div>
                </>
                :
                null
        }
        <div style={{  marginRight: '10px' }}>
        <Button onClick={handleClose} className='btn-secondary btn-md px-6 text-high' style={{width:100}}>
          Close
        </Button>
        </div>
        </div>
      </Modal>
      </div>
    </div>
  );
}

export default InvoicePreviewAction
