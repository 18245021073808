import React from "react";
import Validations from "../shared/validation";
import { useHistory } from "react-router-dom";

export function useTabs() {
	const [tabValue, setTabValue] = React.useState(
		Validations.getQueryParameter("tab") || "0"
	);
	const history = useHistory();

	const handleTabChange = (newValue) => {
		history.push(`?tab=${newValue}`);
		setTabValue(newValue);
	};

	return {
		tabValue,
		handleTabChange,
	};
}
