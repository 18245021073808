import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import { compose } from "redux"
import { rejectExpenses } from "../../middleware"
import Presentation from "./Presentation"
function Container(props) {
  const [state, stateSetter] = useState({
    open: false,
    description: "",
    hideBtn: true,
  })

  const setState = (obj) => {
    stateSetter((prevState) => ({
      ...prevState,
      ...obj,
    }))
  }
  const { empCode, isApproved, isRejected, placementID, id } = props.row
  const expenses_Details = props[`expenses_Details_${placementID}`]

  if (isLoaded(expenses_Details)) {
    const onReject = () => {
      const { rejectExpenses } = props
      handleClose()
      console.log(state.description)
      rejectExpenses(state.description, empCode, placementID, id)
      // _approve_timesheet({}, employeeID, placementID, timesheetID)
    }

    const handleClickOpen = () => {
      setState({ open: true })
    }
    const handleClose = () => {
      setState({ open: false })
    }
    return (
      <div>
        <Presentation
          {...props}
          state={state}
          setState={setState}
          expenses_Details={expenses_Details}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          onReject={onReject}
          employeeID={empCode}
        />
      </div>
    )
  }
  return  <div className="actionLoader">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
}
const mapStateToProps = (state, ownProps) => {
  console.log(state.employee.employeeModules.accessModules)
  return {
    [`expenses_Details_${ownProps.row.placementID}`]: state.firestore.ordered[
      `expenses_Details_${ownProps.row.placementID}`
    ]
      ? state.firestore.ordered[
          `expenses_Details_${ownProps.row.placementID}`
        ][0]
      : state.firestore.ordered[`expenses_Details_${ownProps.row.placementID}`],

    employee_Profile: state.firebase.auth.uid,
    modules: state.employee.employeeModules.accessModules,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    rejectExpenses: (reason, employeeId, placementId, expenseId) => {
      dispatch(rejectExpenses(reason, employeeId, placementId, expenseId))
    },
  }
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (!props[`expenses_Details_${props.row.placementID}`])
      return [
        {
          collection: "EMPLOYEES",
          doc: props.employee_Profile,
          subcollections: [
            {
              collection: "PLACEMENTS",
              doc: props.row.placementID,
              subcollections: [
                {
                  collection: "SETTINGS",
                  doc: "expense_details",
                  storeAs: `expenses_Details_${props.row.placementID}`,
                },
              ],
            },
          ],
          storeAs: `expenses_Details_${props.row.placementID}`,
        },
      ]
    return []
  })
)(Container)
