import React, { useState } from "react";
import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDialog } from "../../../../hooks/useDialog";
import AppModalHeader from "../../../../shared/components/Modals/AppModalHeader";
import TextFormField from "../../../../shared/components/BootStrapFormFields/TextFormField";
import AppModalFooter from "../../../../shared/components/Modals/AppModalFooter";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
	getCompanyDetails,
	updateCompanyDetails,
} from "../../../../api/services/default/company-details";
import CheckBoxField from "../../../../shared/components/BootStrapFormFields/CheckBoxField";
import { RegexExp } from "../../../../shared/regex";
import FileUploadForm from "../../../../shared/components/BootStrapFormFields/FileUploadForm";
import RichTextEditor from "../../../../shared/components/BootStrapFormFields/RichTextEditor";

const schema = yup.object().shape({
	companyName: yup.string().required("Required"),
	e_VerifyNumber: yup.string().required("Required"),
	ein_Number: yup.string().required("Required"),
	accountsMailId: yup.string().required("Required"),
	address: yup.string().required("Required"),
	hrMailId: yup.string().required("Required"),
	immigrationMailId: yup.string().required("Required"),
	mailId: yup.string().required("Required"),
	phoneno: yup
		.string()
		.matches(RegexExp.phonenumber, "Invalid Phone Number")
		.required("Required"),
	autoFillPayableTo: yup.boolean().required("Required"),
	invoiceAutoGenerate: yup.boolean().required("Required"),
	invoiceAutoSend: yup.boolean().required("Required"),
	invoicePrefix: yup.string().required("Required"),
	seperator: yup.string().required("Required"),
	beginFrom: yup.string().required("Required"),
	payableTo: yup.string().required("Required"),
	waterMark: yup.string().required("Required"),
	companyLogo: yup.string().required("Required"),
});

export default function EditCompanyDetails() {
	const { open, handleClose, handleOpen } = useDialog();
	const [companyDetails, setCompanyDetails] = useState({});

	const { refetch } = useQuery({
		queryKey: ["getCompanyDetails"],
		queryFn: () => getCompanyDetails(),
		onSuccess(data) {
			setCompanyDetails({
				companyName: data.companyName,
				e_VerifyNumber: data.e_VerifyNumber,
				ein_Number: data.ein_Number,
				...data.images,
				...data.contactDetails,
				...data.invoiceDetails,
				...data.invoiceDetails?.invoiceNumberFormat,
			});
		},
	});

	const useUpdateCompanyDetails = useMutation({
		mutationFn: ({ data }) => updateCompanyDetails(data),
	});

	const handleSubmit = async (values, actions) => {
		actions.setSubmitting(true);
		await useUpdateCompanyDetails.mutateAsync({
			data: {
				companyName: values.companyName,
				e_VerifyNumber: values.e_VerifyNumber,
				ein_Number: values.ein_Number,
				images: {
					companyLogo: values.companyLogo,
					waterMark: values.waterMark,
				},
				contactDetails: {
					address: values.address,
					phoneno: values.phoneno,
					mailId: values.mailId,
					accountsMailId: values.accountsMailId,
					immigrationMailId: values.immigrationMailId,
					hrMailId: values.hrMailId,
				},
				invoiceDetails: {
					autoFillPayableTo: values.autoFillPayableTo,
					invoiceAutoGenerate: values.invoiceAutoGenerate,
					invoiceAutoSend: values.invoiceAutoSend,
					invoiceNumberFormat: {
						invoicePrefix: values.invoicePrefix,
						seperator: values.seperator,
						beginFrom: values.beginFrom,
					},
					payableTo: values.payableTo,
				},
			},
		});
		await refetch();
		handleClose();
		actions.resetForm();
		actions.setSubmitting(false);
	};

	return (
		<>
			<Button variant="primary" onClick={handleOpen}>
				<i className="fas fa-edit"></i>
			</Button>
			<Modal scrollable={true} show={open} onHide={handleClose} centered size="xl">
				<Formik
					validationSchema={schema}
					initialValues={companyDetails}
					enableReinitialize
					onSubmit={handleSubmit}
				>
					{({ isValid, isSubmitting, dirty }) => (
						<>
							<AppModalHeader title="Edit Company Details" />
							<Modal.Body>
								<Form id="companyDetailsForm">
									<Row>
										<Col xs={12}>
											<h3 className="text-underline mb-3">Company Details: </h3>
										</Col>
										<Col xl={4}>
											<Field
												component={TextFormField}
												label="Company Name"
												name="companyName"
												placeholder="Company Name"
											/>
										</Col>
										<Col xl={4}>
											<Field
												component={TextFormField}
												label="E-Verify Number"
												name="e_VerifyNumber"
												placeholder="E-Verify Number"
											/>
										</Col>
										<Col xl={4}>
											<Field
												component={TextFormField}
												label="EIN-Number"
												name="ein_Number"
												placeholder="EIN-Number"
											/>
										</Col>
									</Row>
									<Row>
										<Col xs={12}>
											<h3 className="text-underline mb-3">Contact Details:</h3>
										</Col>
										<Col xl={4}>
											<Field
												component={TextFormField}
												label="Address"
												name="address"
												placeholder="Address"
											/>
										</Col>
										<Col xl={4}>
											<Field
												component={TextFormField}
												label="Phone Number"
												name="phoneno"
												placeholder="Phone Number"
											/>
										</Col>
										<Col xl={4}>
											<Field
												component={TextFormField}
												label="Mail Id"
												name="mailId"
												placeholder="Mail Id"
											/>
										</Col>
										<Col xl={4}>
											<Field
												component={TextFormField}
												label="Accounts Mail Id"
												name="accountsMailId"
												placeholder="Accounts Mail Id"
											/>
										</Col>
										<Col xl={4}>
											<Field
												component={TextFormField}
												label="HR Mail Id"
												name="hrMailId"
												placeholder="HR Mail Id"
											/>
										</Col>
										<Col xl={4}>
											<Field
												component={TextFormField}
												label="Immigration Mail Id"
												name="immigrationMailId"
												placeholder="Immigration Mail Id"
											/>
										</Col>
									</Row>
									<Row>
										<Col xs={12}>
											<h3 className="text-underline mb-3">Invoice Details:</h3>
										</Col>
										<Col xl={4}>
											<Field
												component={CheckBoxField}
												label="Auto Fill Payable To"
												name="autoFillPayableTo"
												placeholder="Auto Fill Payable To"
											/>
										</Col>
										<Col xl={4}>
											<Field
												component={CheckBoxField}
												label="Invoice Auto Generate"
												name="invoiceAutoGenerate"
												placeholder="Invoice Auto Generate"
											/>
										</Col>
										<Col xl={4}>
											<Field
												component={CheckBoxField}
												label="Invoice Auto Send"
												name="invoiceAutoSend"
												placeholder="Invoice Auto Send"
											/>
										</Col>
										<Col xl={4}>
											<Field
												component={TextFormField}
												label="Invoice Prefix"
												name="invoicePrefix"
												placeholder="Invoice Prefix"
											/>
										</Col>
										<Col xl={4}>
											<Field
												component={TextFormField}
												label="Seperator"
												name="seperator"
												placeholder="Seperator"
											/>
										</Col>
										<Col xl={4}>
											<Field
												component={TextFormField}
												label="Begin From"
												name="beginFrom"
												placeholder="Begin From"
											/>
										</Col>
										<Col xl={4}>
											<Field
												component={RichTextEditor}
												label="Payable To"
												name="payableTo"
												placeholder="Payable To"
												options={{
													mode: "Balloon-always",
												}}
												height="auto"
											/>
										</Col>
									</Row>
									<Row>
										<Col xs={12}>
											<h3 className="text-underline mb-3">Images: </h3>
										</Col>
										<Col xl={6}>
											<Field
												component={FileUploadForm}
												label="Company Logo"
												name="companyLogo"
												storageDir="CompanyDetails"												
											/>
										</Col>
										<Col xl={6}>
											<Field
												component={FileUploadForm}
												label="Water Mark"
												name="waterMark"
												storageDir="CompanyDetails"
											/>
										</Col>
									</Row>
								</Form>
							</Modal.Body>
							<AppModalFooter
								formId={"companyDetailsForm"}
								saveButtonDisabled={!isValid || isSubmitting || !dirty}
							/>
						</>
					)}
				</Formik>
			</Modal>
		</>
	);
}
