import { CardContent, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { FaBalanceScale, FaFileInvoiceDollar } from 'react-icons/fa'
import { BsCreditCard } from 'react-icons/bs'
import { GrUnlink } from "react-icons/gr"
const paperStyle = {

    padding: "1px",
    height: "85%",
    borderColor: "#DF6589FF",

};
const useStyles = makeStyles({
    Card: {
        "&:hover": {
            color: "grey"
        },
        backgroundColor: "White",
        borderRadius: "20px solid",
    },

});
const Cards = ({ state }) => {
    const classes = useStyles();
    return (
        <Grid item container spacing={3}>
            <Grid item xs={3}>
                <Paper elevation={2} style={paperStyle} className={classes.Card} type="button" >
                    <CardContent style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Typography className="h2 font-weight-bold mb-0" style={{ textAlign: "left" }}>
                                <h2 style={{
                                    fontWeight: "normal", color: "#cc8706"
                                }}>{state.openBalance}</h2>
                            </Typography>
                            <div>
                                <Typography
                                    className="text-uppercase text-muted mb-0"
                                    gutterBottom={true}
                                    style={{ textAlign: "left", paddingTop: "10px" }}
                                >
                                    Open Balance
                                </Typography>
                            </div>
                        </div>
                        <div >
                            <FaBalanceScale size={50} style={{
                                color: "#cc8706", opacity: "0.7"
                            }} />
                        </div>
                    </CardContent>
                </Paper>
            </Grid>
            <Grid item xs={3}>
                <Paper elevation={2} style={paperStyle} className={classes.Card} type="button" >
                    <CardContent style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Typography className="h2 font-weight-bold mb-0" style={{ textAlign: "left" }}>
                                <h2 style={{ fontWeight: "normal", color: "#053ba8" }}>{state.paymentAmount}</h2>
                            </Typography>
                            <div>
                                <Typography
                                    className="text-uppercase text-muted mb-0"
                                    gutterBottom={true}
                                    style={{ textAlign: "left", paddingTop: "10px" }}
                                >
                                    Total Credit from Payment
                                </Typography>
                            </div>
                        </div>
                        <div >
                            <BsCreditCard size={50} style={{ color: "#053ba8", opacity: "0.7" }} />
                        </div>
                    </CardContent>
                </Paper>
            </Grid>
            <Grid item xs={3}>
                <Paper elevation={2} style={paperStyle} className={classes.Card} type="button" >
                    <CardContent style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Typography className="h2 font-weight-bold mb-0" style={{ textAlign: "left" }}>
                                <h2 style={{ fontWeight: "normal", color: "#229106" }}>{state.amountLinked}</h2>
                            </Typography>
                            <div>
                                <Typography
                                    className="text-uppercase text-muted mb-0"
                                    gutterBottom={true}
                                    style={{ textAlign: "left", paddingTop: "10px" }}
                                >
                                    Amount Linked to Invoice
                                </Typography>
                            </div>
                        </div>
                        <div >
                            <FaFileInvoiceDollar size={50} style={{ color: "#229106", opacity: "0.7" }} />
                        </div>
                    </CardContent>
                </Paper>
            </Grid>
            <Grid item xs={3}>
                <Paper elevation={2} style={paperStyle} className={classes.Card} type="button" >
                    <CardContent style={{ display: 'flex', justifyContent: 'space-between', border: "20px" }} >
                        <div>
                            <Typography className="h2 font-weight-bold mb-0" style={{ textAlign: "left" }}>
                                <h2 style={{ fontWeight: "normal", color: "#bf2a2a" }}>{state.unlinkedAmount}</h2>
                            </Typography>
                            <div>
                                <Typography
                                    className="text-uppercase text-muted mb-0"
                                    gutterBottom={true}
                                    style={{ textAlign: "left", paddingTop: "10px" }}
                                >
                                    Unlinked Amount
                                </Typography>
                            </div>
                        </div>
                        <div >
                            <GrUnlink size={50} style={{ color: "#bf2a2a", opacity: "0.7" }} />
                        </div>
                    </CardContent>
                </Paper>
            </Grid>
        </Grid >
    )
}

export default Cards
