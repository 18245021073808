import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import Presentation from "./Presentation"
import { editClientDetails } from "../../../../../middleware"
import { firestoreConnect,isLoaded } from "react-redux-firebase"
import { compose } from "redux"

function Container(props) {
  const { invoiceLocation, editClientDetails,client_locations,invoiceLocationId } = props
  const [address, setAddress] = useState({});
  const [state, setState] = useState({
    newLocationID: "",
  })

  // console.log(invoiceLocations, "invoiceLocations=========================");

  // const [presentInvoiceId, setpresentInvoiceId] = useState("");
  useEffect(() => {
    if (isLoaded(client_locations))
      setAddress(
        client_locations.filter(
          (item) => (item?.id === invoiceLocationId
            )
        )[0]
      );
  }, [client_locations]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let invoiceLocationId = state.newLocationID 
    editClientDetails({invoiceLocationId})
  }

  const handleChangeAddress = (value) => {
    setAddress(value)
    setState((prevState) => {
      return {
        ...prevState,
        newLocationID: value.id,
      }
    })
  }

  return (
    <div>
      <Presentation
        state={state}
        handleSubmit={handleSubmit}
        client_locations={client_locations}
        address={address}
        handleChangeAddress={handleChangeAddress}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    client_locations : state.firestore.ordered.client_locations
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    editClientDetails: (payload) => {
      dispatch(editClientDetails(payload, ownProps.id))
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "CLIENTS",
        doc: props.id,
        subcollections: [
          {
            collection: "CLIENTS_LOCATIONS",
            where: [["isExist", "==", true]],
          },
        ],
        storeAs: "client_locations",
      },
    ]
  })
)(Container)