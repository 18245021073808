import React from "react"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import Presentation from "./Presentation"
import { compose } from "redux"
import { connect } from "react-redux"
import { createTasks, createsubTasks } from "../../../../middleware"
function Container(props) {
  const { tasksListData } = props

  let tasksList = []
  const [state, setState] = React.useState({
    name: "",
    type: "",
    status: "",
    startdate: "",
    enddate: "",
    priority: "",
    labels: [],
    assignee: [],
    projectAssignee: [],
    isCreated: false,
    setReminder: false,
    reminderDate: "",
  })
  const [description, setDescription] = React.useState("")
  const handleChange = (key, value) => {
    if (key === "setReminder" && value === true) {
      setState({
        ...state,
        status: "Closed",
        setReminder: true,
      })
    } else if (key === "setReminder" && value === false) {
      setState({
        ...state,
        status: "",
        setReminder: false,
      })
    } else if (key === "reminderDate" && !isNaN(Date.parse(value))) {
      setState({
        ...state,
        reminderDate: new Date(value).toISOString(),
      })
    } else if (key === "reminderDate" && isNaN(Date.parse(value))) {
      setState({
        ...state,
        reminderDate: "",
      })
    } else if (key !== "reminderDate")
      setState({
        ...state,
        [key]: value,
      })
  }
  const handleDateChange = (key, value) => {
    if (!isNaN(Date.parse(value)))
      setState({
        ...state,
        [key]: value.toISOString(),
      })
  }
  // const isCreated = (bool) => {
  //   if (bool)
  //     setState({
  //       ...state,
  //       isCreated: true,
  //     })
  // }
  const handleDescription = (key, value) => {
    setDescription({
      [key]: value,
    })
  }
  const handleDropdown = (key, value) => {
    setState({
      ...state,
      [key]: value,
    })
  }

  const clearValues = () => {
    setState({
      ...state,
      name: "",
      type: "",
      status: "",
      startdate: "",
      enddate: "",
      priority: "",
      labels: [],
      assignee: [],
      description: "",
      projectAssignee: [],
      isCreated: false,
    })
  }
  const handleCreate = (e, handleClose) => {
    e.preventDefault()
    const { createTasks, createSubTasks } = props
    const {
      name,
      type,
      status,
      startdate,
      enddate,
      priority,
      assignee,
      labels,
      setReminder,
      reminderDate,
    } = state
    let id = props.projectId
    let data = {
      title: name,
      type,
      status,
      startdate: new Date(startdate).setHours(0, 0, 0, 0),
      enddate: new Date(enddate).setHours(0, 0, 0, 0),
      priority,
      assignee,
      description: description ? Object.values(description)[0] : "",
      labels,
      setReminder,
      reminderDate,
      category: props.category,
      id,
    }
    if (props.category === "task") {
      createTasks(data)
    }
    if (props.category === "subtask") {
      let data = {
        title: name,
        type,
        status,
        startdate: new Date(startdate).setHours(0, 0, 0, 0),
        enddate: new Date(enddate).setHours(0, 0, 0, 0),
        priority,
        assignee,
        description: description ? Object.values(description)[0] : "",
        labels,
        setReminder,
        reminderDate,
        category: props.category,
        id,
        taskId: props.taskId,
      }
      createSubTasks(data, handleClose)
    }

    clearValues()
  }
  if (isLoaded(props.project)) {
    tasksList = Object.values(tasksListData["tasksListData"].data)
    return (
      <div>
        <Presentation
          {...props}
          state={state}
          tasksList={tasksList}
          handleChange={handleChange}
          handleDateChange={handleDateChange}
          handleCreate={handleCreate}
          handleDropdown={handleDropdown}
          clearValues={clearValues}
          handleDescription={handleDescription}
        />
      </div>
    )
  }
  return <p>Loading....</p>
}

const mapStateToProps = (state, ownProps) => {
  return {
    id: ownProps.projectId,
    project: state.firestore.ordered.project
      ? state.firestore.ordered.project[0]
      : state.firestore.ordered.projectsList.filter(
        (project) => project.id === ownProps.projectId
      )[0],
    names: state.employee.employeeList.metaInfoofEmployees.data,
    tasksListData: state.projects.tasksList,
  }

}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createTasks: (payload, handleClose) => {
      dispatch(createTasks(payload, handleClose))
    },
    createSubTasks: (payload, handleClose) => {
      dispatch(createsubTasks(payload, handleClose))
    },
  }
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (!props.project)
      return [
        {
          collection: "PROJECTS",
          doc: props.projectId,
          storeAs: "project",
        },
      ]
    return []
  })
)(Container)
