import React, { useState } from "react"
import Presentation from "./Presentation"
import { connect } from "react-redux"
import { projectUpdation } from "../../../middleware"


function Container(props) {

	const [state, setState] = useState({
    title: props.project[0].title,
    startdate: props.project[0].startdate,
    enddate: props.project[0].enddate,
    status: props.project[0].status,
    firsttitle: props.project[0].title,
    useLabels: props.project[0].useLabels,
    useTimeline: props.project[0].useTimeline,
  })
  const handleCheck = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    })
  }

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const handleDateChange = (key, value) => {
    if (!isNaN(Date.parse(value)))
      setState({
        ...state,
        [key]: value.toISOString(),
      })
  }

  // if (state.firsttitle == "") {
  //   setState({
  //     firsttitle: state.title,
  //   })
  // }
  const oldContent = {
    ...state,
    enddate: state.enddate,
  }
  const handleDropdown = (key, value) => {
    setState({
      ...state,
      [key]: value,
    })
  }

  const handleEmployees = (value) => {
    setState({
      ...state,
      employees: value,
    })
  }
  const handleUpdate = (e) => {
    e.preventDefault()
    const { projectUpdation } = props
    const { title, status, startdate, enddate, useLabels, useTimeline } = state
    let projectInfo = {
      title,
      status,
      startdate: new Date(startdate).setHours(0, 0, 0, 0),
      enddate: new Date(enddate).setHours(0, 0, 0, 0),
      useLabels,
      useTimeline,
      id: props.project[0].id,
      cid: props.project[0].cid,
    }
    projectUpdation(projectInfo)
    // dispatch({
    //   type: UPDATE_PROJECT,
    //   payload: {
    //     title,
    //     status,
    //     startdate,
    //     enddate,
    //     useLabels,
    //     useTimeline,
    //   },
    // })
  }
	
	return (
    <div>
      <Presentation
        {...props}
        {...state}
        handleChange={handleChange}
        handleDateChange={handleDateChange}
        handleEmployees={handleEmployees}
        //errCount={errCount}
        handleUpdate={handleUpdate}
        handleDropdown={handleDropdown}
        oldContent={oldContent}
        handleCheck={handleCheck}
      />
    </div>
  )
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    projectUpdation: (payload) => {
      dispatch(projectUpdation(payload))
    },
  }
}

export default connect(null, mapDispatchToProps)(Container)
