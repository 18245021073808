import React from "react";
import DocumentsEditModal from "../../../../EmployeeManagment/components/NewProfileUI/general/Documents/documents";
import PassportEditModal from "../../../../EmployeeManagment/components/NewProfileUI/general/Passport/passport";
import WorkAuthorizationEditModal from "../../../../EmployeeManagment/components/NewProfileUI/general/WorkAuthorization/workauth";
import MailingAddressEditModal from "../../../../EmployeeManagment/components/NewProfileUI/general/MailingAddress/mailingaddress";
import EVerifyEditModal from "../../../../EmployeeManagment/components/NewProfileUI/general/Everification/everify";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { updateWorkQueueDocument } from "../../../MiddleWare";
import { useHistory } from "react-router-dom";

export default function UpdateDocument({
	employeeId,
	sectionName = "default",
	workQueueId,
}) {
	const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

  const sectionTypes = {
    workauth: "array",
    passport: "array",
    documents: "array",
    mailingaddress: "object",
    "e-verification": "array",
    default: "object",
  };

  const onUpdate = (formData) => {
    const payload = {
      data: formData,
      uid: employeeId,
      workQueueDocId: workQueueId,
      sectionName:sectionName,
      sectionType: sectionTypes[sectionName],
    }
    dispatch(updateWorkQueueDocument(payload, callback))
  };

  const callback = () => {
		handleClose();
    history.push("/home");
  }

	const components = {
		workauth: (
			<WorkAuthorizationEditModal
				employeeID={employeeId}
				show={open}
				onHide={handleClose}
				isFromWorkQueue={true}
        onUpdate={onUpdate}
			/>
		),
		passport: (
			<PassportEditModal
				employeeID={employeeId}
				show={open}
				onHide={handleClose}
				isFromWorkQueue={true}
        onUpdate={onUpdate}
			/>
		),
		documents: (
			<DocumentsEditModal
				employeeID={employeeId}
				show={open}
				onHide={handleClose}
				isFromWorkQueue={true}
        onUpdate={onUpdate}
			/>
		),
		mailingaddress: (
			<MailingAddressEditModal
				employeeID={employeeId}
				show={open}
				onHide={handleClose}
				isFromWorkQueue={true}
        onUpdate={onUpdate}
			/>
		),
		"e-verification": (
			<EVerifyEditModal
				employeeID={employeeId}
				show={open}
				onHide={handleClose}
				isFromWorkQueue={true}
        onUpdate={onUpdate}
			/>
		),
		default: null,
	};

	return (
		<>
			<Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Update
      </Button>
			{components[sectionName]}
		</>
	);
}
