import React from "react";
import { useDialog } from "../../../../hooks/useDialog";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Modal from "react-bootstrap/Modal";
import AppModalHeader from "../../../../shared/components/Modals/AppModalHeader";
import TextFormField from "../../../../shared/components/BootStrapFormFields/TextFormField";
import AppModalFooter from "../../../../shared/components/Modals/AppModalFooter";
import { resetUserPassword } from "../../middleware/employeeList";
import { FaKey } from "react-icons/fa";

const schema = Yup.object().shape({
	newPassword: Yup.string()
		.required("Required")
		.test(
			"passwords-length",
			"Password must be at least 8 characters",
			function (value) {
				return value?.length >= 8;
			}
		),
	confirmPassword: Yup.string()
		.required("Required")
		.test("passwords-match", "Passwords must match", function (value) {
			return this.parent.newPassword === value;
		}),
});

export default function ResetPassword({ email }) {
	const { open, handleOpen, handleClose } = useDialog();

	const handleSubmit = async (values, { setSubmitting }) => {
		setSubmitting(true);
		await resetUserPassword({ email, password: values.newPassword });
		setSubmitting(false);
		handleClose();
	};

	return (
		<>
			<Tooltip title="Reset Password">
				<IconButton style={{ color: "#2C9273" }} onClick={handleOpen}>
					<FaKey size={20} />
				</IconButton>
			</Tooltip>
			<Modal
				show={open}
				onHide={handleClose}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Formik
					initialValues={{ newPassword: "", confirmPassword: "" }}
					validationSchema={schema}
					onSubmit={handleSubmit}
				>
					{({ isSubmitting, isValid, dirty }) => (
						<Form>
							<AppModalHeader title="Reset Password" />
							<Modal.Body>
								<Field
									type="password"
									name="newPassword"
									component={TextFormField}
									label="New Password"
									placeholder="Enter new password"
									id="newPassword"
								/>
								<Field
									type="password"
									name="confirmPassword"
									component={TextFormField}
									label="Confirm Password"
									placeholder="Confirm new password"
									id="confirmPassword"
								/>
							</Modal.Body>
							<AppModalFooter
								onClickCancel={handleClose}
								saveButtonDisabled={!isValid || !dirty || isSubmitting}
							/>
						</Form>
					)}
				</Formik>
			</Modal>
		</>
	);
}
