import React, { useEffect } from "react";
import { Backdrop, Button, CircularProgress, Grid, Paper, TextField } from "@material-ui/core";
import { BulkPaymentsContextContextProvider, useBulkPaymentsContext } from "./context";
import { Autocomplete } from "@material-ui/lab";
import PaymentDivision from "./PaymentDivision";
import InvoicesPaymentDivison from "./InvoicesPaymentDivison";
import Cards from "./Cards"
import PaymentOverview from "./PaymentOverview";
import ViewUnlinkedAmounts from "./ViewUnlinkedAmounts";
import BulkPaymentsHistory from "./BulkPaymentsHistory";


function BulkPayment(props) {
  const { bulkPaymentId, editMode = false, tabValue } = props
  const { state, services, stateSetters } = useBulkPaymentsContext();

  useEffect(() => {
    services.getClients()
  }, [])

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={5}>
          <Autocomplete
            id="bulk-payment-select-criteria-select-client"
            options={state.clients}
            hidden={editMode}
            value={
              state.clients.filter(c => c.clientId === state.selectedClientId)?.[0] || {}
            }
            getOptionLabel={(option) => option.businessDisplayName}
            onChange={(e, v) => {
              if (v) {
                stateSetters.setSelectedClientId(v.clientId);

              }
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                label="Select Client"
                id="bulk-payment-select-criteria-select-client"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <div style={{ textAlign: 'right', marginTop: '14px' }} >
            <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "flex-end" }}>
              <Button
                color={editMode ? 'secondary' : 'primary'}
                variant="contained"
                disabled={
                  state.paymentAmount < 0 ||
                  !state.paymentMethod ||
                  isNaN(Date.parse(state.paymentDate)) ||
                  (!state.noReference && (state.referenceNumber.length < 1 || state.attachment.publicURL.length < 1)) ||
                  state.isUploading ||
                  !state.selectedClientId
                }
                onClick={editMode ? () => services.handleUpdateBulkPayment(bulkPaymentId) : services.handleBulkPayment}
              >
                {editMode ? "Update Payment" : "Make Payment"}
              </Button>
              &nbsp;
              <BulkPaymentsHistory bulkPaymentId={bulkPaymentId} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <PaymentDivision />
        </Grid>
        <ViewUnlinkedAmounts />
        <Grid item xs={12} sm={12}>
          <Cards state={state} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <InvoicesPaymentDivison />
        </Grid>
      </Grid>

    </div >
  );
}

export default BulkPayment