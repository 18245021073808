import React from "react";
import MaterialTable from "material-table";
import { Button } from "@material-ui/core";
import UpdateDocument from "../Actions/UpdateDocument/index";
import { Link } from "react-router-dom";
import MetaInfo from "../../../../shared/getMetaInfo";
import ReviewDocument from "../Actions/ReviewDocument/reviewDocument";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import styles from "./styles.module.scss";
import classNames from "classnames";

function HomePage(props) {
  const {
    employeeId,
    ExpiredListData,
    state,
    LoginEmployeeId,
    OnIgnore,
    OnEnableDoc,
  } = props;

  const metaInfo = new MetaInfo();
  let particularList = ExpiredListData?.find((e) => e.id ===employeeId || e.EmpId === employeeId )
  const tableData = particularList?.docs.map((doc) => {
    return {
      employeeId: employeeId,
      name: metaInfo.emailToName(employeeId),
      document: doc.document,
      type: doc.type,
      isIgnored: particularList?.isIgnored?.includes(doc.type || doc.document),
      isIgnoredList: particularList?.isIgnored ?? [],
      sectionName: doc.section,
      id: doc.id,
      status: doc.status || "",
      reviewId: doc.reviewId || "",
      rejectedReason: doc.rejectedReason || "",
    }
  })

  const columns = [
    { title: "Expired Documents", field: "document", dataType: "String" },
    { title: "Type", field: "type", dataType: "String" },
    {
      title: "Actions",
      field: "",
      dataType: "",
      render: (rowData) => {
        if (rowData.status === "submitted") {
          return (
						<ReviewDocument
							employeeId={rowData.employeeId}
							sectionName={rowData.sectionName}
							reviewId={rowData.reviewId}
							workQueueId={rowData.id}
						/>
					)
        }

        return (
          <span className="d-flex">
            <span
              key={rowData.email}
              className="d-flex"
              style={{ marginTop: "8px" }}
            >
              {rowData.status === "rejected" ? (
								<span>
									<OverlayTrigger
										placement="top"
										overlay={
											<Tooltip placement="top">
												Rejected Reason: {rowData?.rejectedReason}
											</Tooltip>
										}
									>
										<i
											className={classNames({
												"bi bi-exclamation-circle": true,
												[styles.warningIcon]: true,
											})}
										></i>
									</OverlayTrigger>
								</span>
							) : null}
              {
                (!state || LoginEmployeeId === rowData.employeeId) ?
                
                  rowData.doc === "Placement" ? (
                  <Link
                    to={
                      "/console/placements/" +
                      rowData.employeeId +
                      "/" +
                      rowData.placementId
                    }
                  >
                    <Button variant="outlined" color="primary">
                      Update
                    </Button>
                  </Link>
                ) : (
                  <UpdateDocument employeeId={rowData.employeeId} sectionName={rowData.sectionName} workQueueId={rowData.id} />
                )
                :<span></span>
              }
              &nbsp;&nbsp;
              {(state || LoginEmployeeId === rowData.employeeId) ? (
                <span></span>
              ) : !rowData.isIgnored ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to Igonre this item ?"
                      )
                    )
                      OnIgnore(rowData.type ? rowData.type : rowData.document , rowData.employeeId, rowData?.isIgnoredList ??  []);
                  }}
                >
                  Ignore
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to Enable this item ?"
                      )
                    )
                      OnEnableDoc(
                        "" !== rowData?.type ? rowData.type : rowData.document,
                        rowData.employeeId,
                        rowData?.isIgnoredList ?? []
                      );
                  }}
                >
                  Enable
                </Button>
              )}
            </span>
          </span>
        );
      },
    },
  ];
  return (
    <div>
      <div style={{ display: "flex" }}>
        {state ? (
          <Button
            color="primary"
            variant="contained"
            style={{ marginLeft: "auto" }}
            onClick={() => {
              console.log(employeeId, "employeeId+employeeId");
            }}
          >
            Skip and Continue
          </Button>
        ) : (
          ""
        )}
      </div>
      <div>
        <MaterialTable
          title=""
          columns={columns}
          data={tableData}
          style={{ width: "100%" }}
          options={{
            rowStyle: {
              height: "1em",
            },
            pageSize: 10,
            paginationPosition: "top",
            filtering: true,
            columnsButton: true,
            selection: true,
          }}
        />
      </div>
    </div>
  );
}

export default HomePage;