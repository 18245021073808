import React from "react"
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogContentText,
  DialogTitle
} from "@material-ui/core"
import FeatherIcon from "feather-icons-react/build/FeatherIcon"
import { useAnnouncementContext } from "./context"
import { useSelector } from "react-redux"
import parser from "html-react-parser";

function AnnouncementDialog(props) {
  const { state,services } = useAnnouncementContext()
  const [open, setOpen] = React.useState(false)
  const { announcement } = props
  const auth = useSelector((state) => state.firebase.auth);


  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  
  const handleAcknowledge = async () => {
    await services.acknowledgeAnnouncement(handleClose,announcement.id)
    await services.getEmployeeAnnouncements(auth.uid)
  }

  const handleDecline = async () =>{
    await services.declineAnnouncement(handleClose,announcement.id)
    await services.getEmployeeAnnouncements(auth.uid)
  }


  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <FeatherIcon icon="eye"/>
      </IconButton>
      <Dialog
        scroll="paper"
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="scroll-dialog-title">{announcement.subject}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText style={{ color: "black" }} ref={descriptionElementRef} tabIndex={-1}>
            {parser(announcement.body)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleDecline} variant={"contained"} color="secondary" style={{marginRight:"10px"}} disabled={announcement.declined}>Decline</Button>
            <Button onClick={handleAcknowledge} variant={"contained"} color="primary" disabled={announcement.acknowledged}>Acknowledge</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AnnouncementDialog
