import React from "react"
import MetaInfo from "../../../../shared/getMetaInfo"
import CustomTable from "../../../../shared/customTable"
import { Link } from "react-router-dom"
import { isLoaded } from "react-redux-firebase"
import validate from "../../../../shared/validation"

function Presentation(props) {
  const { article_history, history, categoryMetaInfo } = props
  const metaInfo = new MetaInfo()
  const columns = [
    {
      title: "Article Title",
      field: "title",
      render: (rowData) => (
        <span>
          <Link
            to={"/console/wiki/" + rowData.articleId + "/" + rowData.historyId}
          >
            {rowData.title}
          </Link>
        </span>
      ),
    },
    { title: "Category", field: "category" },
    { title: "Type", field: "type" },
    { title: "Created On", field: "createdAt" },
    { title: "Created By", field: "createdBy" },
  ]

  let data = []

  history &&
    history.forEach((doc) => {
      const { title, categoryId } = doc.eventDetails.after
      data.push({
        title,
        category: categoryMetaInfo[categoryId],
        createdAt: validate.dateAndTimeFormatter(doc.createdAt),
        createdBy: metaInfo.emailToName(doc.actionBy),
        type: doc.type,
        historyId: doc.id,
        articleId: doc.subject.wikiArticleId,
      })
    })

  return (
    <div>
      <CustomTable
        data={data}
        columns={columns}
        isLoading={!isLoaded(article_history)}
        isToolBar={false}
      />
    </div>
  )
}

export default Presentation
