import React from "react"
import StaffList from "../Services/EmployeeManagment/components/StaffList"

function StaffListPage() {
	return (
		<div>
			<StaffList />
		</div>
	)
}

export default StaffListPage
