import React, { useEffect } from 'react';
import { Card, Row, Col, Modal, Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import WorkAuthorizationEditModal from './workauth';
import { useDispatch } from "react-redux";
import { updateProfile } from "../../../../middleware/dynamicProfileRender";
import DeleteModal from '../DeleteModal/delete';
import {CalendarFormatter} from '../../../../../../shared/CalendarFormatter'
import { useQuery } from '@tanstack/react-query';
import { getEmployee } from '../../../../../../api/services/default/employee';
import { Skeleton } from '@material-ui/lab';

const WorkAuth = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowDel, delModalShow] = React.useState(false);
    const { employeeID, isPersonal} = props
    const [index, setIndex] = React.useState(undefined)
    const { data : employeeData = {} } = useQuery({
        queryKey: ["getEmployee", employeeID],
        queryFn: () => getEmployee(employeeID),
        enabled: !!employeeID,
      })
    useEffect(() => {}, [employeeData]);
    const handleEdit = (idx) => {
        setModalShow(true)
        setIndex(idx)
    }

    const handleDelete = (idx) => {
      delModalShow(true);
      setIndex(idx)
    };

    const dispatch = useDispatch()

    const handleDeleteData = (index) => {
        if (index > -1) { 
            employeeData?.workauth?.splice(index, 1); 
          }
        let workauth = employeeData?.workauth?.map((item) => item)
        delModalShow(false)
        const payload = {
            workauth
        }
        setModalShow(false)
        dispatch(updateProfile(payload,employeeID,payload))
    }

    const handleAdd = () => {
        setModalShow(true)
        setIndex(undefined)
    }

    const mapStatus = (active) => {
        if(active === "Y") {
            return "Active"
        } else if (active === "N") {
            return "Inactive"
        }
        return "N/A"
    }

    return (
        <>
        {Object.values(employeeData).length ? (
        <>
            <WorkAuthorizationEditModal
                show={modalShow}
                employeeID={employeeID}
                data={employeeData?.workauth}
                idx={index}
                onHide={() => setModalShow(false)}
            />
            <DeleteModal
                show={modalShowDel}
                index={index}
                handleDelete={() => handleDeleteData(index)}
                onHide={() => delModalShow(false)}
            />
            <Card>
                <Card.Body>
                    <div className="d-flex justify-content-between">
                        <h5 className="card-title mb-3 header-title">Work Authorization</h5>
                        {
                            isPersonal ? <></>
                             : 
                            <Link to="#" className="linkbtn me-1 mt-0" onClick={() => handleAdd()}><i className="fa-solid fa-square-plus fs-17"></i></Link>
                        }
                    </div>
                    {
                        employeeData?.workauth?.map((res, idx) => {
                            return (
                                <div className="emplbg mb-2">
                        <div className="d-block d-lg-flex justify-content-between my-1">
                            <div>
                                <div className="d-flex">
                                    <div className="keyicon fs-15 fw-medium">{res?.work_type}</div>
                                    <div className="ms-2">
                                        <h5 className="mb-2 mt-0 align-self-center fw-bold fs-15">{res?.work_type} <span className={
                                            res?.active === 'Y' ? "text-success fw-medium mt-0 mb-1" : "text-warning fw-medium mt-0 mb-1"
                                        }>{mapStatus(res?.active)}</span></h5>
                                        <h6 className="text-muted fw-normal mt-0 mb-1">Number {res?.work_number}</h6>
                                        <h6 className="text-muted fw-normal mt-0 mb-1"><span className="pe-2">Valid From {CalendarFormatter.standardDateFormat(res?.validfrom)}</span> Expiry {CalendarFormatter.standardDateFormat(res?.work_exp)}</h6>
                                        <h6 className="text-muted fw-normal mt-0 mb-1">Issued on {CalendarFormatter.standardDateFormat(res?.work_issue)}</h6>
                                        <h6 className="text-muted fw-normal mt-0 mb-1">Min Wage {res?.minWage}</h6>
                                        <h6 className="text-muted fw-normal mt-0 mb-1">Max Wage {res?.maxWage}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex mt-2 mt-lg-0">
                                <a href={res?.document} target="_blank" class="wauth me-1 badge-soft-danger badge rounded-pill fw-medium" rel="noreferrer"><i className="fa-regular fa-folder-open pe-1"></i>View Document</a>
                                {
                                    isPersonal ? <></>
                                    :
                                   <>
                                    <Link to="#" className="linkbtn me-1 mt-0" onClick={() => handleEdit(idx)}><i className="fa-solid fa-pen fs-17"></i></Link>
                                    <Link to="#" className="linkbtn danger mt-0" onClick={() => handleDelete(idx)}><i className="fa-regular fa-trash-can fs-17"></i></Link>
                                   </>
                                }
                               
                            </div>
                        </div>
                    </div>
                            )
                        })
                    }
                    
                    {/* <div className="emplbg">
                        <div className="d-block d-lg-flex justify-content-between my-1">
                            <div>
                                <div className="d-flex">
                                    <div className="keyicon fs-15 fw-medium">H-2</div>
                                    <div className="ms-2">
                                        <h5 className="mb-1 mt-0 align-self-center fw-bold fs-15">H-B</h5>
                                        <h6 className="text-muted fw-normal mt-0 mb-1">Number 12345678</h6>
                                        <h6 className="text-muted fw-normal mt-0 mb-1"><span className="pe-2">Issued on 01/01/2021</span> Expiry 01/01/2021</h6>
                                        <h6 className="text-muted fw-normal mt-0 mb-1">Country - India</h6>
                                        <h6 className="text-muted fw-normal mt-0 mb-0">Aline Number - 475443</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex mt-2 mt-lg-0">
                                <Link to="#" class="wauth me-1 badge-soft-danger badge rounded-pill fw-medium"><i className="fa-regular fa-folder-open pe-1"></i>View Document</Link>
                                <Link to="#" className="linkbtn me-1 mt-0" onClick={() => setModalShow(true)}><i className="fa-solid fa-pen fs-17"></i></Link>
                                <Link to="#" className="linkbtn danger mt-0" onClick={() => delModalShow(true)}><i className="fa-regular fa-trash-can fs-17"></i></Link>
                            </div>
                        </div>
                    </div> */}
                </Card.Body>
            </Card>
        </>
         ) : (
            <Skeleton variant="rounded" height={260} />
        )}
        </>
    );
};
export default WorkAuth;