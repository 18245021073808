import React from 'react'
import { Tab, Nav } from 'react-bootstrap' 
import { useTabs } from '../../../hooks/useTabs'
import GeneralReport from './GeneralReport'
import ExitReport from './ExitReport'

export default function PayrollTabs() {
  const { handleTabChange, tabValue } = useTabs()

  const tabs = {
    '0': 'General Report',
    '1': 'Exit Report'
  }

  return (
    <Tab.Container activeKey={tabValue} onSelect={handleTabChange}>
      <Nav justify variant="pills" as="ul">
        {Object.keys(tabs).map((tab) => (
          <Nav.Item as="li" key={tab}>
            <Nav.Link className="cursor-pointer" eventKey={tab}>
              <span className="d-sm-block">
                {tabs[tab]}
              </span>
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <Tab.Content className='text-muted'>
        <Tab.Pane eventKey="0">
          {tabValue === '0' && <GeneralReport />}
        </Tab.Pane>
        <Tab.Pane eventKey="1">
          {tabValue === '1' && <ExitReport />}
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  )
}
