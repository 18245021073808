import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import { compose } from "redux"
import { approveExpenses } from "../../middleware"
import Presentation from "./Presentation"
function Container(props) {
  const [open, setOpen] = useState(false)
  const [hideBtn, setHideBtn] = useState(true)
  const { empCode, isApproved, isRejected, placementID, id } = props.row
  const expenses_Details = props[`expenses_Details_${placementID}`]
  if (isLoaded(expenses_Details)) {
    const onApprove = () => {
      const { approveExpenses } = props
      handleClose()
      console.log(id, placementID)

      approveExpenses(empCode, placementID, id)
    }

    const handleClickOpen = () => {
      setOpen(true)
    }
    const handleClose = () => {
      setOpen(false)
    }
    return (
      <div>
        <Presentation
          {...props}
          open={open}
          expenses_Details={expenses_Details}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          onApprove={onApprove}
          employeeID={empCode}
        />
      </div>
    )
  }
  return <div className="actionLoader">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
</div>
}
const mapStateToProps = (state, ownProps) => {
  console.log(state.firebase.auth.uid)
  return {
    [`expenses_Details_${ownProps.row.placementID}`]: state.firestore.ordered[
      `expenses_Details_${ownProps.row.placementID}`
    ]
      ? state.firestore.ordered[
          `expenses_Details_${ownProps.row.placementID}`
        ][0]
      : state.firestore.ordered[`expenses_Details_${ownProps.row.placementID}`],

    employee_Profile: state.firebase.auth.uid,
    modules: state.employee.employeeModules.accessModules,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    approveExpenses: (employeeId, placementId, expenseId) => {
      dispatch(approveExpenses(employeeId, placementId, expenseId))
    },
  }
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (!props.expenses_Details)
      return [
        {
          collection: "EMPLOYEES",
          doc: props.row.empCode,
          subcollections: [
            {
              collection: "PLACEMENTS",
              doc: props.row.placementID,
              subcollections: [
                {
                  collection: "SETTINGS",
                  doc: "expense_details",
                  storeAs: `expenses_Details_${props.row.placementID}`,
                },
              ],
            },
          ],
          storeAs: `expenses_Details_${props.row.placementID}`,
        },
      ]
    return []
  })
)(Container)
