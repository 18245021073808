import React from "react"
import MaterialTable, { MTableToolbar } from "material-table"
import { Tooltip, Typography, withStyles } from "@material-ui/core"
import { Link } from "react-router-dom"
import Button from "@material-ui/core/Button"
// import { DataContext } from "../../../../contexts/data"
import Chip from "@material-ui/core/Chip"
import { configuration } from "../../../../../config/companyConfig"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import NewTask from "../taskHandleComponents/NewTask"
// import Validation from "../../../../../shared/validation"
import { differenceInDays } from "date-fns"
import { useStyles, customStyles } from "../../../Styles/TaskTableStyles"
import MetaInfo from "../../../../../shared/getMetaInfo"
// const useStyles1 = makeStyles((theme) => ({
//   root: {
//     flexShrink: 0,
//     marginLeft: theme.spacing(2.5),
//   },
// }))

// const useRowStyles = makeStyles({
//   root: {
//     "& > *": {
//       borderBottom: "unset",
//     },
//   },
// })

export default function Presentation(props) {
  // const [state] = useContext(DataContext)
  const classes = useStyles()
  const { taskList, id, project, employee } = props
  const metaInfo = new MetaInfo()
  const formatter = (date) => {
    let final = ""
    try {
      final = Intl.DateTimeFormat(
        configuration["date-code"],
        configuration.dateformat
      ).format(new Date(date))
    } catch (error) {
      console.log(error)
      final = date
    }
    return final
  }
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip)
  // const validate = {
  //   emailToName: (uid) => {
  //     try {
  //       return state.names.filter((item) => item.uid === uid)[0].name
  //     } catch (error) {
  //       console.log(error)
  //       return uid
  //     }
  //   },
  // }
  dayjs.extend(relativeTime)

  const diff = dayjs()
  const getDueBy = (enddate, status) => {
    if (status === "Completed" || status === "Closed") return false
    return new Date().setHours(0, 0, 0, 0) -
      new Date(enddate).setHours(0, 0, 0, 0) >
      0
      ? true
      : false
  }

  const formatDueBy = (enddate, status) => {
    if (getDueBy(enddate, status)) {
      const days = differenceInDays(
        new Date().setHours(0, 0, 0, 0),
        new Date(enddate).setHours(0, 0, 0, 0)
      )
      if (days === 1) return days.toString() + " day"
      return days.toString() + " days"
    }
    return "---"
  }

  let tasksList = taskList
  let data = []
  let labels = project.useLabels
  let projectMembers = Object.values(project.Users).map((user) => user.uid)
  let allAssignees = []
  let temp = []

  if (taskList) {
    taskList.forEach((task) => {
      temp.push(task.assignee && Object.values(task.assignee))
    })
    temp = [...new Set(temp)]
    if (employee.role === "user") projectMembers = temp
    projectMembers.forEach((member) => {
      let count = 0
      taskList.forEach((task) => {
        if (task.assignee.includes(member)) {
          count++
        }
      })
      allAssignees.push({
        uid: member,
        count: count,
      })
    })
  }
  // const validate = new Validation()

  const projectLabels = Object.values(project.labels).filter((e) => e.isExist)
  tasksList &&
    tasksList.forEach((task) => {
      data.push({
        title: task.title,
        id: task.id,
        cid: task.cid,
        startdate: task.startdate,
        enddate: task.enddate,
        status: task.status,
        assignees: task.assignee && task.assignee,
        createdByName: metaInfo.emailToName(task.createdBy),
        type: task.type,
        labels: projectLabels.filter(
          (item) => task.labels && Object.values(task.labels).includes(item.id)
        ),
        assignee:
          task.assignee && task.assignee.map((e) => metaInfo.emailToName(e)),
        createdBy: task.createdBy,
        projectTaskId: project.cid + "-" + task.cid,
        category: task.category,
        priority: task.priority,
        taskId: task.category === "subtask" ? task.taskId : "",
        dueby: formatDueBy(task.enddate, task.status),
        projectId: id,
        labelsList: projectLabels
          .filter(
            (item) =>
              task.labels &&
              Object.values(task.labels).filter((items) => items.id === item.id)
          )
          .map((e) => e.name),
        labelsMap: projectLabels.filter(
          (item) => task.labels && Object.values(task.labels).includes(item.id)
        ).map(item => item.name).join(" ")
      })
    })
  console.log(data)
  const columns = [
    {
      title: "TaskId",
      field: "projectTaskId",
      render: (rowData) => {
        return <Chip size="small" label={rowData.projectTaskId} />
      },
      dataType: "String",
    },
    {
      title: "Labels",
      field: "labelsMap",
      render: (rowData) => {
        return rowData.labels.length === 0
          ? ""
          : rowData.labels.map((element) => (
            <span>
              <Chip
                size="small"
                style={{
                  backgroundColor: `${element.colorCode}`,
                  color: "white",
                  marginBottom: "2px",
                }}
                label={element.name}
              />
              <br />
            </span>
          ))
      },
      dataType: "",
    },
    {
      title: "Title",
      field: "title",
      render: (rowData) => {
        let row = rowData
        return (
          <div style={{ display: "flex" }}>
            <span>
              {row.category === "task" ? (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography className="text-center" color="inherit">
                        {row.title}
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <Link
                    to={"/console/projects/" + row.projectId + "/" + row.id}
                    style={{ marginLeft: "4px" }}
                  >
                    {row.title.trim().length > 35 ? (
                      <Typography variant="body1">
                        {row.title.trim().substring(0, 35) + "..."}
                        <br />
                      </Typography>
                    ) : (
                        <Typography variant="body1">
                          {row.title.trim()}
                          <br />
                        </Typography>
                      )}
                  </Link>
                </HtmlTooltip>
              ) : (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography className="text-center" color="inherit">
                          {row.title}
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <Link
                      to={"/console/projects/" + row.projectId + "/" + row.id}
                      style={{ marginLeft: "4px" }}
                    >
                      {row.title.trim().length > 35 ? (
                        <Typography variant="body1">
                          {row.title.trim().substring(0, 35) + "..."}
                          <br />
                        </Typography>
                      ) : (
                          <Typography variant="body1">
                            {row.title.trim()}
                            <br />
                          </Typography>
                        )}
                    </Link>
                  </HtmlTooltip>
                )}
              <p style={{ fontSize: "14px" }}>
                {row.type === "Task" ? (
                  <Chip
                    size="small"
                    style={{ backgroundColor: "#17a2b8", color: "white" }}
                    label="Task"
                  />
                ) : (
                    <Chip
                      size="small"
                      style={{ backgroundColor: "#db2828", color: "white" }}
                      label="Bug"
                    />
                  )}
                &nbsp;&nbsp;&nbsp;
                {row.status === "Open" ? (
                  <Chip
                    variant="outlined"
                    size="small"
                    style={{
                      borderColor: "#21ba45",
                      fontWeight: "bold",
                      color: "#21ba45",
                    }}
                    label="Open"
                  />
                ) : row.status === "InProgress" ? (
                  <Chip
                    variant="outlined"
                    size="small"
                    style={{
                      borderColor: "#f78a14",
                      fontWeight: "bold",
                      color: "#f78a14",
                    }}
                    label="InProgress"
                  />
                ) : (
                      <Chip variant="outlined" size="small" label={row.status} />
                    )}
                &nbsp;&nbsp;&nbsp;
                {formatter(row.startdate)}
                &nbsp;&nbsp;&nbsp;
                {formatter(row.enddate)}
              </p>
            </span>
          </div>
        )
      },
      width: "auto",
      dataType: "String",
    },
    {
      title: "CreatedBy",
      field: "createdByName",
      dataType: "String",
    },
    {
      title: "Due Passed By ",
      field: "dueby",
      defaultSort: "asc",
      dataType: "Numeric",
    },
    { title: "Priority", field: "priority" },
    {
      title: "Assignee",
      field: "assignee",
      render: (rowData) => {
        let row = rowData
        return (
          row.assignees &&
          row.assignees.map((employee) => (
            <span>
              <Link to={"/console/employees/" + employee}>
                <Button
                  type="a"
                  style={
                    employee in project.Users
                      ? customStyles.employeeExist
                      : customStyles.employeeNonExist
                  }
                  size="small"
                >
                  {metaInfo.emailToName(employee)}
                </Button>
              </Link>
              <br />
            </span>
          ))
        )
      },
      dataType: "",
    },
  ]
  // const [newColumns, setNewColumns] = useState([])
  // const [filterBy, setFilterBy] = useState({ data: [], isFiltered: false })
  // const handleChange = (filteredData, isFiltered, newCol) => {
  //   setFilterBy({
  //     data: filteredData,
  //     isFiltered: isFiltered,
  //   })
  //   setNewColumns(newCol)
  // }
  let updatedColumns = columns.filter((column) => column.title !== "Labels")
  return (
    <div>
    <NewTask
        projectId={project.id}
        text="New task"
        category="task"
      />  
      <MaterialTable
        title=""
        columns={
          labels
            ? // ? newColumns.length === 0
            columns
            : // : newColumns
            updatedColumns
        }
        // data={!filterBy.isFiltered ? data : filterBy.data}
        data={data}
        // columns={columns}
        parentChildData={(row, rows) => rows.find((a) => a.id === row.taskId)}
        style={{ width: "100%" }}
        options={{
          rowStyle: {
            height: "1em",
          },
          pageSize: 10,
          paginationPosition: "top",
          columnsButton: true,
          paginationType: "normal",
          filtering: true,
          // selection: true,
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
                {/* <span>
                  <FilterBy
                    data={data}
                    columns={newColumns.length === 0 ? columns : newColumns}
                    handleChange={handleChange}
                  />
                </span> */}
              </div>
          ),
        }}
      />
    </div>
  )
}
