const ACTION = {
  SET_NEW_CLIENT_BUSINESS_INFORMATION: "SET_NEW_CLIENT_BUSINESS_INFORMATION",

  SET_NEW_CLIENT_CONTACTS: "SET_NEW_CLIENT_CONTACTS",

  SET_NEW_CLIENT_ACCOUNTS: "SET_NEW_CLIENT_ACCOUNTS",

  SET_NEW_CLIENT_LOCATIONS: "SET_NEW_CLIENT_LOCATIONS",

  SET_TO_INITIAL_STATE: "SET_TO_INITIAL_STATE",

  COUNTRIES_REQUEST: "COUNTRIES_REQUEST",
  COUNTRIES_SUCCESS: "COUNTRIES_SUCCESS",
  COUNTRIES_FAILURE: "COUNTRIES_FAILURE",

  LOAD_CLIENT_PROJECTS_REQUEST: "LOAD_CLIENT_PROJECTS_REQUEST",
  LOAD_CLIENT_PROJECTS_SUCCESS: "LOAD_CLIENT_PROJECTS_SUCCESS",
  LOAD_CLIENT_PROJECTS_FAILURE: "LOAD_CLIENT_PROJECTS_FAILURE",

  GET_CLIENT_DOCUMENTS_REQ: "GET_CLIENT_DOCUMENTS_REQ",
  GET_CLIENT_DOCUMENTS_SUCCESS: "GET_CLIENT_DOCUMENTS_SUCCESS",
  GET_CLIENT_DOCUMENTS_FAILURE: "GET_CLIENT_DOCUMENTS_FAILURE",

  GET_CLIENTS_LOCATIONS_REQ: "GET_CLIENTS_LOCATIONS_REQ",
  GET_CLIENTS_LOCATIONS_SUCCESS: "GET_CLIENTS_LOCATIONS_SUCCESS",
  GET_CLIENTS_LOCATIONS_FAILURE: "GET_CLIENTS_LOCATIONS_FAILURE",

  GET_INVOICE_LOCATION_REQ: "GET_INVOICE_LOCATION_REQ",
  GET_INVOICE_LOCATION_SUCCESS: "GET_INVOICE_LOCATION_SUCCESS",
  GET_INVOICE_LOCATION_FAILURE: "GET_INVOICE_LOCATION_FAILURE",

  GET_META_INFO_CLIENTS_REQUEST: "GET_META_INFO_CLIENTS_REQUEST",
  GET_META_INFO_CLIENTS_SUCCESS: "GET_META_INFO_CLIENTS_SUCCESS",
  GET_META_INFO_CLIENTS_FAILURE: "GET_META_INFO_CLIENTS_FAILURE",
};

export default ACTION;
