import React from 'react';
import { Nav } from 'react-bootstrap';

const Navtab = () => {
    return (
        <Nav as="ul" justify variant="pills" className="border-0 p-0">
            <Nav.Item as="li" key="10">
                <Nav.Link className="cursor-pointer" eventKey="Dashboard">
                    <span className="d-sm-block">Dashboard</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="11">
                <Nav.Link className="cursor-pointer" eventKey="General">
                    <span className="d-sm-block">General</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="12">
                <Nav.Link className="cursor-pointer" eventKey="Placements">
                    <span className="d-sm-block">Placements</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="13">
                <Nav.Link className="cursor-pointer" eventKey="Timesheets">
                    <span className="d-sm-block">Timesheets</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="14">
                <Nav.Link className="cursor-pointer" eventKey="Expenses">
                    <span className="d-sm-block">Expenses</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="15">
                <Nav.Link className="cursor-pointer" eventKey="Invoices">
                    <span className="d-sm-block">Invoices</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="16">
                <Nav.Link className="cursor-pointer" eventKey="Documents">
                    <span className="d-sm-block">Documents</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="17">
                <Nav.Link className="cursor-pointer" eventKey="Deductions">
                    <span className="d-sm-block">Deductions</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="18">
                <Nav.Link className="cursor-pointer" eventKey="SOA">
                    <span className="d-sm-block">SOA</span>
                </Nav.Link>
            </Nav.Item>
        </Nav>
    );
};
export default Navtab;