import React from 'react'
import { TbNotContainsIcon } from '../SvgIcons/TbNotContainsIcon'
import { TbContainsIcon } from '../SvgIcons/TbContainsIcon'
import { TbStartsWithIcon } from '../SvgIcons/TbStartsWithIcon'
import { TbNotStartsWithIcon } from '../SvgIcons/TbNotStartsWithIcon'
import { TbEndsWithIcon } from '../SvgIcons/TbEndsWithIcon'
import { TbNotEndsWithIcon } from '../SvgIcons/TbNotEndsWithIcon'
import { TbEqualsIcon } from '../SvgIcons/TbEqualsIcon'
import { TbGreaterThanIcon } from '../SvgIcons/TbGreaterThanIcon'
import { TbNotEqualsIcon } from '../SvgIcons/TbNotEqualsIcon'
import { TbGreaterOrEqualsToIcon } from '../SvgIcons/TbGreaterOrEqualsToIcon'
import { TbLessThanIcon } from '../SvgIcons/TbLessThanIcon'
import { TbLessOrEqualsToIcon } from '../SvgIcons/TbLessOrEqualsToIcon'
import { TbBetweenIcon } from '../SvgIcons/TbBetweenIcon'
import FilterListIcon from '@material-ui/icons/FilterList'

export const handleFilterChange = (column) => (event) => {
  column.filterText = event.target.value
}

export const onKeyDown = (onEnter) => (ev) => {
  if(ev.keyCode === 13 && onEnter) {
    ev.preventDefault()
    onEnter()
  }
}

export const getOperatorText = (value, text) => {
  switch (value) {
    case 'NotContains':
    case 'Contains':
    case 'StartsWith':
    case 'NotStartsWith':
    case 'EndsWith':
    case 'NotEndsWith':
    case 'Equals':
    case 'NotEquals':
    case 'Between':
      return text
    case 'Gt':
      return 'Greater than'
    case 'Gte':
      return 'Greater than or equals to'
    case 'Lt':
      return 'Less than'
    case 'Lte':
      return 'Less than or equals to'
    default:
      return 'None'
  }
}

export const getOperatorIcon = (operator) => {
  switch (operator) {
    case 'NotContains':
      return <TbNotContainsIcon />
    case 'Contains':
      return <TbContainsIcon />
    case 'StartsWith':
      return <TbStartsWithIcon />
    case 'NotStartsWith':
      return <TbNotStartsWithIcon />
    case 'EndsWith':
      return <TbEndsWithIcon />
    case 'NotEndsWith':
      return <TbNotEndsWithIcon />
    case 'Equals':
      return <TbEqualsIcon />
    case 'NotEquals':
      return <TbNotEqualsIcon />
    case 'Gt':
      return <TbGreaterThanIcon />
    case 'Gte':
      return <TbGreaterOrEqualsToIcon />
    case 'Lt':
      return <TbLessThanIcon />
    case 'Lte':
      return <TbLessOrEqualsToIcon />
    case 'Between':
      return <TbBetweenIcon />
    default:
      return <FilterListIcon />
  }
}

export const NumericOperators = [
  { value: 'None', title: 'None' },
  { value: 'Equals', title: 'Equals' },
  { value: 'Between', title: 'Between' },
  { value: 'Gte', title: '>=' },
  { value: 'Gt', title: '>' },
  { value: 'Lte', title: '<=' },
  { value: 'Lt', title: '<' },
];

export const StringOperators = [
  { value: 'None', title: 'None' },
  { value: 'Equals', title: 'Equals' },
  { value: 'NotEquals', title: 'Not Equals' },
  { value: 'Contains', title: 'Contains' },
  { value: 'NotContains', title: 'Not Contains' },
  { value: 'StartsWith', title: 'Starts With' },
  { value: 'NotStartsWith', title: 'Not Starts With' },
  { value: 'EndsWith', title: 'Ends With' },
  { value: 'NotEndsWith', title: 'Not Ends With' },
];

export const BooleanOperators = [
  { value: 'None', title: 'None' },
  { value: 'Equals', title: 'Equals' },
  { value: 'NotEquals', title: 'Not Equals' },
];

export const getOperators = (column) => {
  switch (column.dataType) {
      case 'String':
          return StringOperators;
      case 'Numeric':
      case 'Date':
          return NumericOperators;
      case 'Boolean':
          return BooleanOperators;
      default:
          return [];
  }
};

