import Axios, { AxiosRequestConfig } from 'axios'
import { configuration } from '../../config/companyConfig';
import { InterceptorService } from '../../shared/InterceptorService';

const AXIOS_INSTANCE = Axios.create({
  baseURL: configuration.REST_api,
});
const interceptorService = new InterceptorService(AXIOS_INSTANCE);
interceptorService.addRequestInterceptor().addResponseInterceptor();

/**
 * @param {AxiosRequestConfig} config
 */
export const defaultInstance = (config) => {
  const source = Axios.CancelToken.source()
  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token }).then(
    ({ data }) => data,
  )

  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query')
  }

  return promise
}