import React from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Tooltip,
  IconButton,
  FormControlLabel,
  Checkbox,
  Paper,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  List,
} from "@material-ui/core";
import { CalendarFormatter } from "../../../../../shared/CalendarFormatter";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  InfoOutlined,
} from "@material-ui/icons";
import { GoFileSymlinkFile } from "react-icons/go";
import { CurrencyFormatter } from "../../../../../shared/customNumberFormats";
import { useInvoiceListContext } from "../context";
import SunEditor from "suneditor-react";
import validate from "../../../../../shared/validation";
import Button from "react-bootstrap/Button";
import { uploadToStorage } from "../../../../../shared/fileUploader"

function MakeOrUpdatePayment(props) {
  const {
    paymentState,
    isUpdatePayment,
    setPaymentState,
    invoicedAmount,
    handleClose,
    handleLoad,
    clearState,
    invoiceID,
    setIsEdit,
    isUpdatePaymentFromBulkPayment = false,
    receivedAmount,
    receivedAmountTillNow,
    discountReceivedTillNow,
    openBalance,
    isEdit
  } = props;
  const { state, services } = useInvoiceListContext();

  const onRemoveDiscount = (index) => {
    let discountDetails = [];
    discountDetails = paymentState.discountDetails.filter(
      (_, ind) => index !== ind
    );
    setPaymentState((prevState) => ({ ...prevState, discountDetails }));
  };

  const onAddDiscount = () => {
    let discountDetails = paymentState.discountDetails;
    discountDetails = [
      ...discountDetails,
      {
        name: "",
        value: 0,
        type: "",
      },
    ];
    setPaymentState((prevState) => ({ ...prevState, discountDetails }));
  };

  const handleFile = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      setPaymentState((prevState) => ({ ...prevState, file, isUploading: true }))
      const fileName = `${paymentState.referenceNumber}`
      const filePath = `Payments/${invoiceID}/${fileName}`
      uploadToStorage(file, filePath, fileName, "file")
        .then((url) => {
          setPaymentState((prevState) => ({
            ...prevState,
            attachment: {
              publicURL: url,
              sourcePath: `${filePath}.${file.name.split(".")[1]}`,
            },
            isUploading: false,
          }))
        })
        .catch((err) => {
          console.error(err)
          setPaymentState((prevState) => ({ ...prevState, isUploading: false }))
        })
    }
  }

  const handleChange = (key, value) => {
    if (key === "paymentAmount") {
      if (!isUpdatePaymentFromBulkPayment) {
        setIsEdit(false)
      }
      setPaymentState((prevState) => {
        return ({ ...prevState, [key]: value })
      })
    }
    else {
      setPaymentState((prevState) => ({ ...prevState, [key]: value }))
    }
  }

  const handleDiscountDetails = (event, index) => {
    setIsEdit(false)
    const { name, value } = event.target
    let data = paymentState.discountDetails
    if (index <= data.length - 1) {
      if (name === "value") {
        if (value === "") {
          data[index]["value"] = "";
        } else {
          data[index][name] = Number(value)
        }
      } else {
        if (name === "type")
          data[index]["value"] = "";
        data[index][name] = value;
      }
    }
    setPaymentState((prevState) => ({ ...prevState, discountDetails: data }))
  }

  const handleUpdatePayment = async (e, handleClose, handleLoad) => {
    e.preventDefault();
    let emptyDiscount = [
      {
        name: "",
        value: 0,
        type: "",
      },
    ]
    const discount = paymentState.discountDetails
    const payload = {
      paymentType: paymentState.paymentMethod,
      referenceNumber: paymentState.referenceNumber,
      attachmentDetails: {
        sourcePath: paymentState.attachment.sourcePath,
        publicURL: paymentState.attachment.publicURL,
      },
      paymentDate: paymentState.paymentDate,
      clientID: paymentState.clientId,
      paymentAmount: paymentState.paymentAmount,
      discountDetails: discount.length < 2 && discount[0].type === "" ? [] : discount ? discount : emptyDiscount,
      invoiceID: invoiceID,
      receivedAmount: isUpdatePaymentFromBulkPayment ? receivedAmount : paymentState.receivedAmount,
      noReference: paymentState.noReference,
      paymentDoneAmount: 0,
      paymentID: paymentState.paymentID,
      paymentNotes: paymentState.paymentNotes

    }
    services.updatePayment(payload, clearState, handleClose, handleLoad)
  }

  const handlePayment = (e, handleClose, handleLoad) => {
    e.preventDefault();
    const discount = paymentState.discountDetails
    const payload = {
      paymentType: paymentState.paymentMethod,
      referenceNumber: paymentState.referenceNumber,
      attachmentDetails: {
        sourcePath: paymentState.attachment.sourcePath,
        publicURL: paymentState.attachment.publicURL,
      },
      paymentDate: paymentState.paymentDate,
      clientID: paymentState.clientId,
      paymentAmount: paymentState.paymentAmount,
      discountDetails: discount.length < 2 && discount[0].type === "" ? [] : discount,
      invoiceID: invoiceID,
      receivedAmount: paymentState.receivedAmount,
      noReference: paymentState.noReference,
      paymentDoneAmount: 0,
      paymentNotes: paymentState.paymentNotes
    }
    services.newPayment(payload, clearState, handleClose, handleLoad)
  }

  return (
    <div>
      <Paper elevation={2} style={{ padding: '10px', marginBottom: '10px' }} >
        <div style={{ fontSize: '18px', fontWeight: 'lighter' }} className="d-flex justify-content-spacebetween">
          <span>
            <u>Invoiced Amount: {validate.currencyFormatterUs(invoicedAmount)}</u>
          </span>
          <span>
            <u>Received Amount: {validate.currencyFormatterUs(receivedAmountTillNow)}</u>
          </span>
          <span>
            <u>Total Discount received till now: {validate.currencyFormatterUs(discountReceivedTillNow)}</u>
          </span>
          <span>
            <u>Open Balance:
              {validate.currencyFormatterUs(openBalance + (isEdit ? 0 : Math.abs(paymentState.discountDoneDetails) + paymentState.paymentDoneAmount) - (isEdit ? 0 : Math.abs(paymentState.paymentAmount))
                - (isEdit ? 0 : Math.abs(services.calculateDiscount(invoicedAmount, paymentState.discountDetails))))}</u>
          </span>
        </div>
      </Paper>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          isUpdatePayment
            ? handleUpdatePayment(e, handleClose, handleLoad)
            : handlePayment(e, handleClose, handleLoad);
        }}
      >
        <Paper elevation={2} style={{ padding: "10px" }}>
          <div>
            <h3>
              <u>Payment:</u>
            </h3>
          </div>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  required
                  select
                  style={{ minWidth: "100%", marginTop: "-1px" }}
                  label="Payment method"
                  value={paymentState.paymentMethod}
                  name="paymentMethod"
                  onChange={(e) => handleChange("paymentMethod", e.target.value)}
                >
                  <MenuItem value="cheque">By Cheque</MenuItem>
                  <MenuItem value="online">By Online</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  onChange={(e) => {
                    if (!isNaN(e.target.value))
                      handleChange("paymentAmount", Number(e.target.value));
                  }}
                  value={paymentState.paymentAmount}
                  fullWidth
                  required
                  label="Payment Amount"
                  name="paymentAmount"
                  InputProps={{
                    inputComponent: CurrencyFormatter,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    required
                    fullWidth
                    value={
                      paymentState.paymentDate ? paymentState.paymentDate : null
                    }
                    label="Payment Date"
                    name="paymentDate"
                    format="MM/dd/yyyy"
                    onChange={(date) => {
                      if (!isNaN(Date.parse(date)))
                        handleChange(
                          "paymentDate",
                          CalendarFormatter.standardDateFormat(date)
                        );
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={paymentState.noReference}
                      onChange={(e) => {
                        handleChange("noReference", e.target.checked);
                      }}
                      disabled={state.isInvoiceEdit}
                    />
                  }
                  label="No Reference Number"
                />
              </Grid>
              {!paymentState.noReference ? (
                <>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      required={!paymentState.noReference}
                      fullWidth
                      label="Reference Number"
                      name="refereceNumber"
                      value={paymentState.referenceNumber}
                      onChange={(e) =>
                        e.target.value.match(/^[a-z0-9]+$/i) ||
                          e.target.value.length === 0
                          ? handleChange("referenceNumber", e.target.value)
                          : () => { }
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      // style={{ display: "none" }}
                      id="contained-button-file-wafile"
                      type="file"
                      onChange={handleFile}
                      disabled={!paymentState.referenceNumber}
                      required={
                        !paymentState.noReference &&
                        !paymentState.attachment.publicURL.length
                      }
                      variant="outlined"
                      size="small"
                      label="Reference Document"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <div className="d-flex justify-content-between">
                      <p>
                        {paymentState.attachment.publicURL.length ? (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={paymentState.attachment.publicURL}
                          >
                            <GoFileSymlinkFile size={22} />
                          </a>
                        ) : null}{" "}
                      </p>
                    </div>
                    {paymentState.isUploading ? (
                      <p>Uploading please wait...</p>
                    ) : (
                      ""
                    )}
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12}>
                <div>
                  <h3>
                    <u>Notes:</u>
                  </h3>
                </div>
                <SunEditor
                  placeholder="Please Enter Notes here.*"
                  onChange={(content) => handleChange("paymentNotes", content)}
                  setContents={paymentState?.paymentNotes}
                  height={100}
                  setOptions={{
                    buttonList: [
                      [
                        "undo",
                        "redo",
                        "blockquote",
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "subscript",
                        "superscript",
                        "outdent",
                        "indent",
                        "list",
                        "table",
                        "link",
                        "image",
                        "fullScreen",
                        "showBlocks",
                        "print",
                        "save",
                      ],
                    ],
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <List dense>
                    <ListItem>
                      <ListItemText>
                        {<h2 style={{ fontWeight: 500 }}>Payment Amount</h2>}
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <h1>
                          {validate.currencyFormatterUs(
                            paymentState.paymentAmount
                          )}
                        </h1>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <h3>
                      <u>Amount to be Discounted:</u>
                      <Tooltip
                        title={`Applied using invoiced amount ${validate.currencyFormatterUs(
                          invoicedAmount
                        )}`}
                      >
                        <InfoOutlined style={{ marginBottom: "3px" }} />
                      </Tooltip>
                    </h3>
                    {paymentState.discountDetails.map((item, index) => {
                      return (
                        <ListItem style={{ marginTop: "5px" }}>
                          <ListItemIcon>
                            <div className="d-flex flex-row">
                              <div className="mr-1">
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  value={item["name"]}
                                  name="name"
                                  onChange={(e) =>
                                    handleDiscountDetails(e, index)
                                  }
                                  label="Name"
                                  fullWidth
                                />
                              </div>
                              <div className="mr-1 mb-1">
                                <TextField
                                  size="small"
                                  label="Select"
                                  variant="outlined"
                                  disabled={item.name === ""}
                                  value={item["type"]}
                                  onChange={(e) =>
                                    handleDiscountDetails(e, index)
                                  }
                                  select
                                  style={{ width: "140px" }}
                                  name="type"
                                >
                                  <MenuItem value="byValue">By Value</MenuItem>
                                  <MenuItem value="byPercentage">
                                    By Percentage
                                  </MenuItem>
                                </TextField>
                              </div>
                              <div className="mr-1">
                                <TextField
                                  size="small"
                                  type="number"
                                  variant="outlined"
                                  disabled={item.type === ""}
                                  value={item["value"]}
                                  onChange={(e) => {
                                    const val = Number(e.target.value);
                                    if (
                                      (paymentState.discountDetails[index][
                                        "type"
                                      ] === "byPercentage" &&
                                        val < 100) ||
                                      paymentState.discountDetails[index][
                                      "type"
                                      ] === "byValue"
                                    ) {
                                      paymentState.discountDetails[index][
                                        "value"
                                      ] = val;
                                      handleDiscountDetails(e, index);
                                    }
                                  }}
                                  label="Discount"
                                  name="value"
                                />
                              </div>
                              <div className="d-flex">
                                {paymentState.discountDetails.length > 1 ? (
                                  <div>
                                    <Tooltip title="Remove this discount">
                                      <IconButton
                                        onClick={() => onRemoveDiscount(index)}
                                      >
                                        <RemoveCircleOutlineIcon
                                          className="text-danger"
                                          fontSize="small"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                ) : null}
                                {index ===
                                  paymentState.discountDetails.length - 1 ? (
                                  <div>
                                    <Tooltip title="Add multiple discounts">
                                      <IconButton onClick={onAddDiscount}>
                                        <AddCircleOutlineIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </ListItemIcon>
                          <ListItemSecondaryAction>
                            <h2 style={{ fontWeight: 500 }}>
                              ${" "}
                              {services.calculateDiscount(invoicedAmount, [
                                paymentState.discountDetails[index],
                              ])}
                            </h2>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
              </Grid>
            </div>
          </div>
        </Paper>

        <br />
        <div className="text-center">
          {isUpdatePayment ? (
            <Button
              className="btn btn-success rounded-pill px-4 btn-lg"
              type="submit"
              disabled={
                paymentState.paymentAmount === "" ||
                paymentState.paymentAmount < 0
              }
            >
              Update Payment
            </Button>
          ) : (
            <Button
              className="btn btn-success rounded-pill px-4 btn-lg"
              type="submit"
              disabled={
                paymentState.paymentAmount === "" ||
                paymentState.paymentAmount < 0 ||
                state.isAddingPayment
              }
            >
              Make Payment
            </Button>
          )}
        </div>
      </form>
    </div>
  );
}

export default MakeOrUpdatePayment;
