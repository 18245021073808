import React, {useEffect, useState} from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import Statistics from "./statistics";
import InvoicesChart from "./invoicesChart";
import Duefive from "./due";
import Paidfive from "./paid";
import State from "./state";
import * as yup from "yup";
import DateFormField from "../../../../shared/components/BootStrapFormFields/DateFormField";
import { useInvoiceListContext } from "../ListingInvoices/context";
import { Skeleton } from "@material-ui/lab";
import { CalendarFormatter } from "../../../../shared/CalendarFormatter";


const schema = yup.object().shape({
  fromDate: yup.string().required("From Date is required"),
  toDate: yup.string().required("To Date is required"),
});

const InvoiceDashboard = ({ handleTabValue, ...props }) => {
  const { state, services } = useInvoiceListContext()
  const [invoiceData, setInvoiceData] = useState({})
  const [invoiceMonthCount, setInvoiceMonthCount] = useState({})
  const [lastfiveInvData, setlastfiveInvData] = useState({})
  const [fromDate, setFromDate] = useState(CalendarFormatter.mmddyyyyFormat(new Date(new Date().getFullYear(),0,1)))
  const [toDate, setToDate] = useState(CalendarFormatter.mmddyyyyFormat(new Date()))
  let initialDates = {
    fromDate,
    toDate,
  }
  useEffect(() => {
  services.getInvoicesDashboardData(initialDates.fromDate, initialDates.toDate)
  services.getDashboardMonthCount(initialDates.fromDate, initialDates.toDate)
  services.getLastFiveDashboardInvoices()
  },[])

  useEffect(() => {
    setInvoiceData(state.dashboardData)
    setlastfiveInvData(state.lastfiveDashboardInvoices)
    setInvoiceMonthCount(state.dashboardMonthCount)
  },[state.dashboardData, state.lastfiveDashboardInvoices, state.dashboardMonthCount])

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    setFromDate(values.fromDate)
    setToDate(values.toDate)
    services.getInvoicesDashboardData(values.fromDate, values.toDate)
    services.getDashboardMonthCount(values.fromDate, values.toDate)
   actions.setSubmitting(false);
  };


  return (
    <>
      <Row>
        <Col>
          <div className="d-flex" style={{justifyContent:"space-between"}}>
            <div><h4 className="page-title">Invoice Dashboard</h4></div>
            <div>
            <Formik 
               validationSchema={schema}
               initialValues={{
               ...initialDates
               }}
               onSubmit={handleSubmit}>
                {
                  (formik) => {
                    return(
                  <Form>
                   <Row>
                  <Col>
                   <Field
                      name="fromDate"
                      type="text"
                      component={DateFormField}
                    />
                    </Col>
                    <Col style={{marginTop:6}}>
                    <h4 className="page-title">to</h4>
                    </Col>
                    <Col>
                    <Field
                      name="toDate"
                      type="text"
                      component={DateFormField}
                    />
                    </Col>
                    <Col>
                    <button
                    type='submit'
                    className="btn btn-success px-4 btn-md"
                    disabled={
                      !formik.isValid ||
                      formik.isSubmitting ||
                      !formik.dirty 
                    }
								>
                  Apply
                  </button>
                    </Col>
                   </Row>
                 </Form>
                    )
                  }
                }
              </Formik>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={4}>
        {
            state.dashboardDataLoaded && invoiceData ?
            <Skeleton animation='wave' variant="rounded" height={500} />
            :
            <State setTabValue={handleTabValue}  paidDetails={lastfiveInvData?.lastfivePaidInv} {...props} Invoices={invoiceData}/> 
          }
        </Col>
        <Col xl={8}>
          {
            state.dashboardDataLoaded && invoiceData ?
            <Skeleton animation='wave'  variant="rounded" height={260} />
            :
            <Statistics allInvoices={invoiceData?.allInvoices}/>           
          }
          {
           state.dashboardMonthCountLoader && invoiceMonthCount ?
            <div style={{padding:4}}>
              <Skeleton animation='wave'  variant="rounded" height={260} />
            </div>
            
            :
            <InvoicesChart  Invoices={invoiceMonthCount}/> 
          }
        
          <Row>
            <Col xl={6}>
            {
            state?.lastfiveDashboardInvoiceLoader && lastfiveInvData?
            <Skeleton animation='wave'  variant="rounded" style={{marginTop:1}} height={260} />
            :
            <Paidfive paidDetails={lastfiveInvData?.lastfivePaidInv}/> 
            } 
            </Col>
            <Col xl={6}>
            {
            state?.lastfiveDashboardInvoiceLoader && lastfiveInvData?
            <Skeleton animation='wave'  variant="rounded" style={{marginTop:1}} height={260} />
            :
            <Duefive dueDetails={lastfiveInvData?.lastfiveDueInv}/> 
            } 
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
 

export default InvoiceDashboard;
