import React, { useState } from "react";
import {
  Grid,
  Typography,
  Accordion as ExpansionPanel,
  AccordionSummary as ExpansionPanelSummary,
  AccordionDetails as ExpansionPanelDetails,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import {
  Done as DoneIcon,
  ExpandMore as ExpandMoreIcon,
  AddCircleOutline as AddCircleOutlineIcon,
} from "@material-ui/icons";
import General from "../newPlacementComponents/General";
import ClientDetails from "../newPlacementComponents/ClientDetails/Index";
import Payments from "../newPlacementComponents/Payments/Index";
import Invoices from "../newPlacementComponents/Invoices";
import Documents from "../newPlacementComponents/Documents";
import RecruitmentDetails from "../newPlacementComponents/RecruitmentDetails";
import WorkLocation from "../newPlacementComponents/WorkLocation";
import TimeSheetDetails from "../newPlacementComponents/Timesheets/Index";
import ExpenseDetails from "../newPlacementComponents/ExpenseDetails";
import EditContact from "../../../../Clients/components/ViewClient/clientComponents/Contacts/EditContact";
import ClosePlacement from "../ClosePlacement";
import ReopenPlacement from "../ReopenPlacement";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(4),
  },
}));

function Presentation(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState("");
  const { placement, profile, clientDetails_data } = props;
  console.log(placement, "allModules");
  const handleChange = (panel) => {
    setExpanded(panel === expanded ? "" : panel);
  };
  return (
    <div>
      <Grid container className={classes.table}>
        <Grid item xs={6} className="p-2">
          <Typography variant="body1">
            <h2>
              {profile?.name + "_" + profile?.companyID + "_" + profile?.status}
            </h2>
          </Typography>
        </Grid>
        <Grid item xs={6} className="p-2">
          <div className="d-flex" style={{ justifyContent: "flex-end" }}>
            {placement?.closingReason ? (
              <ReopenPlacement placement={placement} employeeID={profile?.uid} />
            ) : (
              <ClosePlacement placement={placement} employeeID={profile?.uid} />
            )}
          </div>
        </Grid>
        <Grid item xs={12} className="p-2">
          <Typography variant="body1">
            <h3 style={{ textDecoration: "underline" }}>Note:</h3>Placement can
            be initiated with general tab and no other tab will be opened until
            general tab gets filled
          </Typography>
        </Grid>
        <Grid item xs={6} className="p-2">
          <Grid item xs={12} className="mb-2">
            <ExpansionPanel
              expanded={expanded === "general"}
              onChange={() => handleChange("general")}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {placement.id ? (
                  <Typography
                    variant="h5"
                    className="text-center"
                    style={{ color: "green" }}
                  >
                    {" "}
                    <DoneIcon color="inherit" /> General
                  </Typography>
                ) : (
                  <Typography variant="h5" className="text-center">
                    General
                  </Typography>
                )}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <General
                  placement={placement}
                  profile={profile}
                  closeTab={handleChange}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} className="mb-2">
            <ExpansionPanel
              expanded={
                (placement.id ? true : false) && expanded === "documents"
              }
              onChange={() => handleChange("documents")}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {placement.hasOwnProperty("fillableSections") &&
                !placement.fillableSections.includes("documents") ? (
                  <Typography
                    variant="h5"
                    className="text-center"
                    style={{ color: "green" }}
                  >
                    {" "}
                    <DoneIcon color="inherit" /> Documents
                  </Typography>
                ) : (
                  <Typography variant="h5" className="text-center">
                    Documents
                  </Typography>
                )}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{ width: "100%" }}>
                  <Documents
                    placement={placement}
                    profile={profile}
                    closeTab={handleChange}
                  />
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>

          <Grid item xs={12} className="mb-2">
            <ExpansionPanel
              expanded={
                (placement.id ? true : false) && expanded === "worklocation"
              }
              onChange={() => handleChange("worklocation")}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {placement.hasOwnProperty("fillableSections") &&
                !placement.fillableSections.includes("worklocation") ? (
                  <Typography
                    variant="h5"
                    className="text-center"
                    style={{ color: "green" }}
                  >
                    {" "}
                    <DoneIcon color="inherit" /> Work Location
                  </Typography>
                ) : (
                  <Typography variant="h5" className="text-center">
                    Work Location
                  </Typography>
                )}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <WorkLocation
                  placement={placement}
                  profile={profile}
                  closeTab={handleChange}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>

          <Grid item xs={12} className="mb-2">
            <ExpansionPanel
              expanded={
                (placement.id ? true : false) && expanded === "timesheets"
              }
              onChange={() => handleChange("timesheets")}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {placement.hasOwnProperty("fillableSections") &&
                !placement.fillableSections.includes("timesheets") ? (
                  <Typography
                    variant="h5"
                    className="text-center"
                    style={{ color: "green" }}
                  >
                    {" "}
                    <DoneIcon color="inherit" />
                    TimeSheet Details
                  </Typography>
                ) : (
                  <Typography variant="h5" className="text-center">
                    TimeSheet Details
                  </Typography>
                )}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{ width: "100%" }}>
                  <TimeSheetDetails
                    placement={placement}
                    profile={profile}
                    closeTab={handleChange}
                  />
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
        <Grid item xs={6} className="p-2">
          <Grid item xs={12} className="mb-2">
            <ExpansionPanel
              expanded={
                (placement.id ? true : false) && expanded === "client_details"
              }
              onChange={() => handleChange("client_details")}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {placement.hasOwnProperty("fillableSections") &&
                !placement.fillableSections.includes("client_details") &&
                (profile.category === "C2C" ?
                Object.values(clientDetails_data?.clients ?? []).some(
                  (item) => item.clientType === "Sub-Contractor"
                ) : profile.category !== "C2C" ? true : false) ? (
                  <Typography
                    variant="h5"
                    className="text-center"
                    style={{ color: "green" }}
                  >
                    {" "}
                    <DoneIcon color="inherit" /> Client Details
                  </Typography>
                ) : (
                  <Typography variant="h5" className="text-center">
                    Client Details
                  </Typography>
                )}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{ maxWidth: "100%" }}>
                  <ClientDetails
                    placement={placement}
                    profile={profile}
                    closeTab={handleChange}
                  />
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} className="mb-2">
            <ExpansionPanel
              expanded={
                (placement.id ? true : false) && expanded === "invoices"
              }
              onChange={() => handleChange("invoices")}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {placement.hasOwnProperty("fillableSections") &&
                !placement.fillableSections.includes("invoices") ? (
                  <Typography
                    variant="h5"
                    className="text-center"
                    style={{ color: "green" }}
                  >
                    {" "}
                    <DoneIcon color="inherit" /> Invoices
                  </Typography>
                ) : (
                  <Typography variant="h5" className="text-center">
                    Invoices
                  </Typography>
                )}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Invoices
                  placement={placement}
                  profile={profile}
                  closeTab={handleChange}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} className="mb-2">
            <ExpansionPanel
              expanded={
                (placement.id ? true : false) &&
                expanded === "recruitment_details"
              }
              onChange={() => handleChange("recruitment_details")}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {placement.hasOwnProperty("fillableSections") &&
                !placement.fillableSections.includes("recruitment_details") ? (
                  <Typography
                    variant="h5"
                    className="text-center"
                    style={{ color: "green" }}
                  >
                    {" "}
                    <DoneIcon color="inherit" /> Recruitment Details
                  </Typography>
                ) : (
                  <Typography variant="h5" className="text-center">
                    Recruitment Details
                  </Typography>
                )}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <RecruitmentDetails
                  placement={placement}
                  profile={profile}
                  closeTab={handleChange}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12}>
            <ExpansionPanel
              expanded={
                (placement.id ? true : false) && expanded === "expense_details"
              }
              onChange={() => handleChange("expense_details")}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {placement.hasOwnProperty("fillableSections") &&
                !placement.fillableSections.includes("expense_details") ? (
                  <Typography
                    variant="h5"
                    className="text-center"
                    style={{ color: "green" }}
                  >
                    {" "}
                    <DoneIcon color="inherit" /> Expense Details
                  </Typography>
                ) : (
                  <Typography variant="h5" className="text-center">
                    Expense Details
                  </Typography>
                )}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <ExpenseDetails
                  placement={placement}
                  profile={profile}
                  closeTab={handleChange}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
        <Grid item xs={12} className="p-2">
          <ExpansionPanel
            expanded={(placement.id ? true : false) && expanded === "payments"}
            onChange={() => handleChange("payments")}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              {placement.hasOwnProperty("fillableSections") &&
              !placement.fillableSections.includes("payments") ? (
                <Typography
                  variant="h5"
                  className="text-center"
                  style={{ color: "green" }}
                >
                  {" "}
                  <DoneIcon color="inherit" /> Payments
                </Typography>
              ) : (
                <Typography variant="h5" className="text-center">
                  Payments
                </Typography>
              )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: "100%" }}>
                <Payments
                  placement={placement}
                  profile={profile}
                  closeTab={handleChange}
                />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Grid>
    </div>
  );
}

export default Presentation;
