import React from "react";
import Button from "react-bootstrap/Button";
import AddSchoolModal from "./AddSchoolModal";
import { useDialog } from "../../../../hooks/useDialog";

export default function CreateNewSchool() {
	const { open, handleClose, handleOpen } = useDialog();

	return (
		<>
			<Button variant="primary" size="lg" block onClick={handleOpen}>
				Create New School
			</Button>
			<AddSchoolModal open={open} handleClose={handleClose} />
		</>
	);
}
