import React,{useEffect} from "react";
import { AnnouncementsContextProvider,useAnnouncementContext } from "./context";
import { Backdrop, CircularProgress, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import validate from "../../shared/validation"
import { Link } from "react-router-dom";
import FlairTable from "../../shared/FlairTable"
import TabPanel from "../../shared/tabPanel";
import EmployeeSort from "../EmployeeManagment/components/EmployeeSort/EmployeeSort";
import { useDialog } from "../../hooks/useDialog";
import { Button, Modal } from "react-bootstrap";
import AppModalHeader from "../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../shared/components/Modals/AppModalFooter";
import { boolean, object, string } from "yup";
import { Field, Form, Formik } from "formik";
import TextFormField from "../../shared/components/BootStrapFormFields/TextFormField";
import RichTextEditor from "../../shared/components/BootStrapFormFields/RichTextEditor";
import CheckBoxField from "../../shared/components/BootStrapFormFields/CheckBoxField";

function AnnouncementsComponent(props) {
  const { state,services } = useAnnouncementContext();

  useEffect(() => {
    services.getAllAnnouncements()
  }, [])

  const columns = [
    {
      title: "Subject",
      field: "subject",
      dataType: "",
      filtering:false,
      width:500,
      render: (rowData) => {
        return (
            <div style={{display:"flex",flexDirection:"column"}}>
              <Link to={"/console/Announcements/" + rowData.id + "?subject=" + rowData.subject }>{rowData.subject}</Link>
              <Typography className="d-flex" variant="caption" color="textSecondary">
              CreatedOn:{" "}
              <em className="mr-1">
                {validate.dateAndTimeFormatter(rowData.createdAt)}
              </em></Typography>
            </div>
        )
      },
    },
    {
      title: "Announced to",
      field: "announceTo",
      dataType: "",
      filtering:false,
      render: (rowData) => {
        return (
            <div style={{display:"flex",flexDirection:"column"}}>
              <div className="text-center">Announced to</div>
              <div className="text-center">{[...new Set([...(rowData?.announceTo) || [],...(rowData?.acknowledged) || [],...(rowData?.declined) || []])].length}</div>
            </div>
        )
      },
    },
    {
      title: "Acknowledged By",
      field: "acknowledged",
      dataType: "",
      filtering:false,
      render: (rowData) => {
        return (
            <div style={{display:"flex",flexDirection:"column"}}>
              <div className="text-center" style={{display:"flex",flexDirection:"column"}}>Acknowledged By</div>
              <div className="text-center">{rowData.acknowledged.length}</div>
            </div>
        )
      },
    },
    {
      title: "Declined By",
      field: "declined",
      dataType: "",
      filtering:false,
      render: (rowData) => {
        return (
            <div style={{display:"flex",flexDirection:"column"}}>
              <div className="text-center" style={{display:"flex",flexDirection:"column"}}>Declined By</div>
              <div className="text-center">{rowData.declined.length}</div>
            </div>
        )
      },
    }
  ]

  const rows = state?.allAnnouncements?.map((announcement) => {
    return {
      id:announcement.id,
      subject:announcement.subject,
      createdAt:announcement.createdAt,
      announceTo:announcement.announceTo,
      acknowledged:announcement.acknowledged,
      declined:announcement.declined
    }
  })

  return (
    <>
      <Backdrop style={{ color: "#fff", zIndex: 4000 }} open={state.showLoader}>
          <CircularProgress color="inherit" />
      </Backdrop>
      <FlairTable
        title=""
        data={rows}
        columns={columns}
      />
    </>
  );
}

const schema = object().shape({
  subject: string().required("Subject is required"),
  body: string().required("Body is required"),
  sendMail: boolean().optional()
})

function AddAnnouncementDialog({ tableRef }) {
  const { handleClose, handleOpen, open } = useDialog()
  const { services } = useAnnouncementContext()

  const handleSubmit = (values, actions) => {
    const selectedRows = tableRef.current?.getSelectedRows();
    if (selectedRows.length === 0) {
      alert("Please select at least one employee")
      return
    }
    actions.setSubmitting(true)
    const employeeIds = selectedRows.map((row) => row.id)
    const payload = {
      to : employeeIds,
      bcc : [],
      subject: values.subject,
      groupType: "",
      groupList: [],
      body: values.body,
      sendMail: values.sendMail
    }
    services.addNewAnnouncemnt(payload, () => {
      handleClose()
      actions.setSubmitting(false)
    })
  }

  return (
    <>
      <Button variant="primary" onClick={handleOpen}>
        Add Announcement
      </Button>
      <Modal show={open} onHide={handleClose} size="lg" centered>
        <Formik initialValues={{ subject: "", body: "", sendMail: false }} validationSchema={schema} onSubmit={handleSubmit}>
          {({ isSubmitting, isValid }) => (
            <>
              <AppModalHeader title="Add Announcement" handleClose={handleClose} />
              <Modal.Body>
                <Form id="add-announcement-form">
                  <p className="text-muted">({tableRef.current?.getSelectedRows().length} employees selected)</p>
                  <Field name="subject" label="Subject" component={TextFormField} />
                  <Field name="body" label="Body" component={RichTextEditor} />
                  <Field name="sendMail" label="Send Mail" component={CheckBoxField} />
                </Form>
              </Modal.Body>
              <AppModalFooter onClickCancel={handleClose} formId="add-announcement-form" saveButtonDisabled={isSubmitting || !isValid} />
            </>
          )}
        </Formik> 
      </Modal>
    </>
  )
}

function AddAnnouncement() {
  const tableRef = React.useRef(null)

  const onSelectionChange = React.useCallback(() => {}, []);

  return (
    <EmployeeSort 
      condition={0}
      actions={{
        icon: () => <AddAnnouncementDialog tableRef={tableRef} />,
        tooltip: "Add Announcement",
        isFreeAction: true,
        position: "toolbar",
        onClick: () => {}
      }}
      tableRef={tableRef}
      onCellClicked={(params) => params.node.setSelected(true)}
      onSelectionChange={onSelectionChange}
    />
  )
}

function AnnoucementTabs() {
  const [tabValue, setTabValue] = React.useState(0)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  } 

  return (
    <div style={{ height: "100vh" }}>
      <Paper square>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="disabled tabs example"
        >
          <Tab label="Send Announcements" />
          <Tab label="All Announcements" />
        </Tabs>
      </Paper>
      <TabPanel value={tabValue} index={0}>
        <AddAnnouncement />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <AnnouncementsComponent />
      </TabPanel>
    </div>
  );
}


const AllAnnouncements = (props) => <AnnouncementsContextProvider><AnnoucementTabs {...props} /> </AnnouncementsContextProvider>
export default AllAnnouncements