import { Field, Form, Formik } from "formik";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { RegexExp } from "../../../../shared/regex";
import {
	createSchool,
	updateSchool,
} from "../../../../api/services/flair-shared/schools";
import AppModalHeader from "../../../../shared/components/Modals/AppModalHeader";
import TextFormField from "../../../../shared/components/BootStrapFormFields/TextFormField";
import CountryField from "../../../../shared/components/BootStrapFormFields/CountryField";
import StateField from "../../../../shared/components/BootStrapFormFields/StateField";
import AppModalFooter from "../../../../shared/components/Modals/AppModalFooter";

const schema = yup.object().shape({
	name: yup.string().required("School name is required"),
	line1: yup.string().required("Address line 1 is required"),
	line2: yup.string(),
	city: yup.string().required("City is required"),
	state: yup.string().required("State is required"),
	zip: yup.string().required("Zip is required"),
	country: yup.string().required("Country is required"),
});

export default function AddSchoolModal({
	open,
	handleClose,
	size = "xl",
	isEdit = false,
	school = {},
	onCreate = () => { },
}) {
	const initialValues = {
		name: "",
		line1: "",
		line2: "",
		city: "",
		state: "",
		zip: "",
		country: "",
		...school,
	};

	const queryClient = useQueryClient();
	const useAddNewSchool = useMutation({
		mutationFn: (values) => createSchool(values),
		onSuccess: () => {
			queryClient.invalidateQueries(["getAllSchools"]);
		},
	});

	const useUpdateSchool = useMutation({
		mutationFn: ({ id, data }) => updateSchool(id, data),
		onSuccess: () => {
			queryClient.invalidateQueries(["getSchool", school.id]);
		},
	});

	const handleSubmit = async (values, actions) => {
		actions.setSubmitting(true);
		if (isEdit) {
			await useUpdateSchool.mutateAsync({ id: school.id, data: values });
		} else {
			await useAddNewSchool.mutateAsync(values);
		}
		actions.setSubmitting(false);
		handleClose();
		onCreate();
	};

	return (
		<>
			<Modal show={open} onHide={handleClose} size={size} centered>
				<Formik
					enableReinitialize
					validationSchema={schema}
					initialValues={initialValues}
					onSubmit={handleSubmit}
				>
					{({ values, setFieldValue, isSubmitting, isValid, dirty }) => {
						return (
							<Form>
								<AppModalHeader
									title={isEdit ? "Edit School" : "Add New School"}
								/>
								<Modal.Body>
									<Row>
										<Col xl={4}>
											<Field
												name="name"
												label="School Name *"
												component={TextFormField}
												required
											/>
										</Col>
										<Col xl={4}>
											<Field
												name="line1"
												label="Address Line 1*"
												component={TextFormField}
												required
											/>
										</Col>
										<Col xl={4}>
											<Field
												name="line2"
												label="Address Line 2"
												component={TextFormField}
											/>
										</Col>
										<Col xl={4}>
											<Field
												name="city"
												label="City *"
												component={TextFormField}
												required
											/>
										</Col>
										<Col xl={4}>
											<Field
												name="country"
												label="Country *"
												component={CountryField}
												onChangeCallback={() => {
													setFieldValue("state", "");
												}}
												required
											/>
										</Col>
										<Col xl={4}>
											<Field
												name="state"
												label="State *"
												component={StateField}
												country={values.country}
												required
											/>
										</Col>
										<Col xl={4}>
											<Field
												name="zip"
												label="Zip *"
												component={TextFormField}
												required
											/>
										</Col>
									</Row>
								</Modal.Body>
								<AppModalFooter
									saveButtonDisabled={!isValid || !dirty || isSubmitting}
									saveButtonText={isEdit ? "Update" : "Add School"}
								/>
							</Form>
						);
					}}
				</Formik>
			</Modal>
		</>
	);
}
