/* eslint-disable no-use-before-define */
import React, { useState } from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import useStyles from "../../Styles/ActiveMemberStyles"


export default function Presentation(props) {
  const classes = useStyles()
  const { handleEmployees, data, isAdded, selected } = props
  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        options={data}
        id="tm-active-members"
        getOptionLabel={(option) => option.name}
        value={selected}
        filterSelectedOptions
        onChange={(e, v) => handleEmployees(v.map((item) => item))}
        renderInput={(params) => (
          <TextField fullWidth {...params} label="Add employees" />
        )}
      />
    </div>
  )
}


