import React from "react";
import NewProfileUI from "../NewProfileUI";
import { ProfileDashboardContextProvider } from "./context";

export default function MenuBar({ Id }) {
	return (
		<ProfileDashboardContextProvider>
			<NewProfileUI Id={Id} />
		</ProfileDashboardContextProvider>
	);
}
