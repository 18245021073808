import React, { useEffect } from "react"
import { connect } from "react-redux"
import Presentation from "./Presentation"
import { projectListData } from "../../../../middleware/projectListDashboard"
import { loadClosedProjects,loadInProgressProjects,loadOverDueProjects } from "../../../../middleware/projectsList"
import Spinner from "../../../../../../shared/circularSpinner"

function Container(props) {
  const { role, _get_data, dashboardData,projectstate,_load_InProgress_Project,_load_Closed_Project,_load_Overdue_Project } = props

  useEffect(() => {
    _load_Closed_Project();
    _load_InProgress_Project();
    _load_Overdue_Project();
    _get_data()
  }, [])

  if (dashboardData.isLoading) return <Spinner />

  return (
    <div>
      <Presentation role={role} dashboardData={dashboardData.data} projectstate={projectstate} />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    role:
      state.employee.employeeModules.accessModules.includes("console-customization") ||
      state.employee.employeeModules.accessModules.includes("task-management-manager"),
    dashboardData: state.projects.projectListDashboard,
    state: state.projects.projectsList,
    projectstate:state.projects.projectsList
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    _get_data: () => {
      dispatch(projectListData())
    },
    _load_Closed_Project :() => dispatch(loadClosedProjects()),
    _load_InProgress_Project:() => dispatch(loadInProgressProjects()),
    _load_Overdue_Project:() => dispatch(loadOverDueProjects()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
