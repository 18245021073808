import { eachDayOfInterval, isWithinInterval, addDays } from "date-fns"
import { CalendarFormatter } from "./CalendarFormatter";

export class TimesheetsUTILS {
  static calc_hours(arr) {
    const hours = [],
      minutes = [];
    arr.map(item => item.value).forEach((item) => {
      const [h, m] = item.split(":");
      hours.push(parseInt(h));
      minutes.push(parseInt(m));
    });
    let totalHours = hours.reduce((a, b) => a + b, 0);
    let totalMinutes = minutes.reduce((a, b) => a + b, 0);
    totalHours = parseInt(totalHours + totalMinutes / 60);
    let remainingMinutes = totalMinutes % 60;
    console.log(totalHours +
      ":" +
      "0".repeat(2 - remainingMinutes.toString().length) +
      remainingMinutes.toString())
    return (
      totalHours +
      ":" +
      "0".repeat(2 - remainingMinutes.toString().length) +
      remainingMinutes.toString()
    );
  }


  static getPayRate(timesheet, payRateDetails, workType) {
    return workType === "standardTime"
      ? payRateDetails[0].billingRate
      : payRateDetails[0].OTbillingRate
    // const start = new Date(timesheet.startDate).setHours(0, 0, 0, 0),
    //   end = new Date(timesheet.endDate).setHours(0, 0, 0, 0)
    // if (payRateDetails.length === 1) {
    //   return workType === "standardTime"
    //     ? payRateDetails[0].billingRate
    //     : payRateDetails[0].OTbillingRate
    // }
    // for (let i = 0; i < payRateDetails.length; i++) {
    //   // check both dates in range or not
    //   const payRate = payRateDetails[i]
    //   const checkStartRange = isWithinInterval(start, {
    //     start: new Date(payRate.effectiveDate).setHours(0, 0, 0, 0),
    //     end: new Date(payRate.effectiveUntil).setHours(0, 0, 0, 0),
    //   })

    //   const checkEndRange = isWithinInterval(end, {
    //     start: new Date(payRate.effectiveDate).setHours(0, 0, 0, 0),
    //     end: new Date(payRate.effectiveUntil).setHours(0, 0, 0, 0),
    //   })

    //   if (checkStartRange && checkEndRange) {
    //     return workType === "standardTime"
    //       ? payRate.billingRate
    //       : payRate.OTbillingRate
    //   } else if (checkStartRange || checkEndRange) {
    //     return workType === "standardTime"
    //       ? payRate.billingRate
    //       : payRate.OTbillingRate + ", " + workType === "standardTime"
    //         ? payRateDetails[i + 1].billingRate
    //         : payRateDetails[i + 1].OTbillingRate
    //   } else if (checkEndRange) {
    //     return workType === "standardTime"
    //       ? payRate.billingRate
    //       : payRate.OTbillingRate + ", " + workType === "standardTime"
    //         ? payRateDetails[i - 1].billingRate
    //         : payRateDetails[i - 1].OTbillingRate
    //   } else {
    //     return workType === "standardTime"
    //       ? payRateDetails.reverse()[0].billingRate
    //       : payRateDetails.reverse()[0].OTbillingRate
    //   }
    // }
  }

  static multiPayratesHandler(timesheets, paymentSettings) {
    let organizedPayRates = {
      payRatesDatesConsideration: {},
      totalPayAmount: {}
    }
    let dummyObj = {}
    let standardEmployeePayAmount = 0
    let OtEmployeePayAmount = 0
    let standardBillingAmount = 0
    let OtBillingAmount = 0
    let payRatesSeparation = {}

    const getRateDetails = (timesheetDate) => {
      for (let i = 0; i < paymentSettings.data.length; i++) {
        const paymentDetails = paymentSettings.data[i]
        let checkTimeSheetdate = !paymentDetails.effectiveDate || !paymentDetails.effectiveUntil ? true : isWithinInterval(new Date(timesheetDate).setHours(0, 0, 0, 0), {
          start: new Date(paymentDetails.effectiveDate).setHours(0, 0, 0, 0),
          end: new Date(paymentDetails.effectiveUntil).setHours(0, 0, 0, 0),
        })
        if (checkTimeSheetdate) {
          return {
            ...paymentDetails,
            payRate: paymentDetails.employeePayRate,
            OTpayRate: paymentDetails.OTpayRate,
            billingRate: paymentDetails.billingRate,
            OTbillingRate: paymentDetails.OTbillingRate
          }
        }
      }
    }

    timesheets.forEach(timesheet => {
      // let range;
      payRatesSeparation = {
        ...payRatesSeparation,
        [CalendarFormatter.standardDateFormat(timesheet.startDate) + "-" + CalendarFormatter.standardDateFormat(timesheet.endDate)]: {
          "standardTime": this.getPayRateByTimeSheet(timesheet, paymentSettings.data, "standardTime").payRatesSeparation,
          "OtTime": this.getPayRateByTimeSheet(timesheet, paymentSettings.data, "OtTime").payRatesSeparation,
        }
      }
      for (let index = 0; index < timesheet.workdetails.standardTime.length; index++) {
        const eachTime = timesheet.workdetails.standardTime[index]
        const rates = getRateDetails(eachTime.date)
        // range = timesheet.startDate + "-" + eachTime.value
        dummyObj = {
          [CalendarFormatter.standardDateFormat(new Date(eachTime.date))]: {
            standardTime: eachTime.value,
            OtTime: timesheet.workdetails.OTtime[index].value,
            payRate: rates.payRate,
            OTpayRate: rates.OTpayRate,
            billingRate: rates.billingRate,
            OTbillingRate: rates.OTbillingRate,
            billingType: rates.billingType,
            payType: rates.payType
          }
        }
        standardEmployeePayAmount += this.calcTotalAmount(eachTime.value, rates.payRate)
        OtEmployeePayAmount += this.calcTotalAmount(timesheet.workdetails.OTtime[index].value, rates.OTpayRate)
        standardBillingAmount += this.calcTotalAmount(eachTime.value, rates.billingRate)
        OtBillingAmount += this.calcTotalAmount(timesheet.workdetails.OTtime[index].value, rates.OTbillingRate)
        organizedPayRates.payRatesDatesConsideration = {
          ...organizedPayRates.payRatesDatesConsideration,
          ...dummyObj
        }
      }

    })

    organizedPayRates.totalPayAmount = {
      standardEmployeePayAmount,
      OtEmployeePayAmount,
      standardBillingAmount,
      OtBillingAmount
    }
    return {
      payRatesDatesConsideration: organizedPayRates.payRatesDatesConsideration,
      payRatesSeparation,
      paymentSettings: paymentSettings.data
    };
  }

  static getPayRateByTimeSheet(timesheet, payRateDetails, workType) {
    let pay_rates = {
      payRatesSeparation: [],
      payRatesDatesConsideration: {},
      totalPayAmount: {},
    }
    let totalAmount = 0

    // Checking Time Sheet Date is in payrate dates are not
    const checkTimeSheetdate = (timeSheetDate, payRateEffectiveDate, payRateEffectiveUntil) => {
      // console.log(new Date(timeSheetDate), new Date(payRateEffectiveDate), new Date(payRateEffectiveUntil));
      // for some invoices we are not storing effective dates
      if (!payRateEffectiveDate || !payRateEffectiveUntil)
        return true
      return isWithinInterval(new Date(timeSheetDate).setHours(0, 0, 0, 0), {
        start: new Date(payRateEffectiveDate).setHours(0, 0, 0, 0),
        end: new Date(payRateEffectiveUntil).setHours(0, 0, 0, 0),
      })
    }

    for (let i = 0; i < payRateDetails.length; i++) {
      const payRate = payRateDetails[i]

      for (let j = 0; j < timesheet.workdetails.standardTime.length; j++) {
        let timeSheetDate = timesheet.workdetails.standardTime[j]
        let OTtimeSheetDate = timesheet.workdetails.OTtime[j]
        let dummyObj = {}
        let dummyAmount = 0

        if (checkTimeSheetdate(timeSheetDate.date, payRate.effectiveDate, payRate.effectiveUntil)) {

          if (workType === "standardTime") {
            dummyObj = {
              [timeSheetDate.date]: {
                time: timeSheetDate.value,
                payRate: payRate.employeePayRate,
                OTpayRate: payRate.OTpayRate,
                billingRate: payRate.billingRate,
                OTbillingRate: payRate.OTbillingRate
              }
            }
            dummyAmount += this.calcTotalAmount(timeSheetDate.value, payRate.billingRate)
            if (!pay_rates.payRatesSeparation.includes(payRate.billingRate)) {
              pay_rates.payRatesSeparation.push(payRate.billingRate)
            }
          } else {
            dummyObj = {
              [OTtimeSheetDate.date]: {
                time: OTtimeSheetDate.value,
                payRate: payRate.employeePayRate,
                OTpayRate: payRate.OTpayRate,
                billingRate: payRate.billingRate,
                OTbillingRate: payRate.OTbillingRate
              }
            }
            dummyAmount += this.calcTotalAmount(OTtimeSheetDate.value, payRate.OTbillingRate)
            if (!pay_rates.payRatesSeparation.includes(payRate.OTbillingRate)) {
              pay_rates.payRatesSeparation.push(payRate.OTbillingRate)
            }
          }
          console.log(dummyAmount, "dummyAmountdummyAmountdummyAmountdummyAmount")

        }
        pay_rates.payRatesDatesConsideration = {
          ...pay_rates.payRatesDatesConsideration,
          ...dummyObj,
        }
        totalAmount += dummyAmount
        pay_rates.totalPayAmount = {
          totalAmount: parseFloat(totalAmount.toFixed(2))
        }
      }
    }
    console.log(pay_rates, "<============dummyAmount")
    return pay_rates
  }

  static calcTotalTime(arr) {
    const hours = [],
      minutes = [];
    arr.forEach((item) => {
      const [h, m] = item.split(":");
      hours.push(parseInt(h));
      minutes.push(parseInt(m));
    });
    let totalHours = hours.reduce((a, b) => a + b, 0);
    let totalMinutes = minutes.reduce((a, b) => a + b, 0);
    console.log(arr, hours, minutes)
    totalHours = parseInt(totalHours + totalMinutes / 60);
    let remainingMinutes = totalMinutes % 60;
    console.log(totalHours +
      ":" +
      "0".repeat(2 - remainingMinutes.toString().length) +
      remainingMinutes.toString())
    return (
      totalHours +
      ":" +
      "0".repeat(2 - remainingMinutes.toString().length) +
      remainingMinutes.toString()
    );
  }

  static calcTotalAmount = (workingTime, payRateAmount) => {
    const [h, m] = workingTime.split(":");
    // alert(workingTime + "-" + Math.floor(h * payRateAmount + (payRateAmount * m) / 60) + "-" + payRateAmount)
    return Number(
      parseFloat(h * payRateAmount + (payRateAmount * m) / 60).toFixed(2)
    );
  }

  static calcAmount(timesheet, payRateDetails, workType) {
    const start = new Date(timesheet.startDate).setHours(0, 0, 0, 0),
      end = new Date(timesheet.endDate).setHours(0, 0, 0, 0)
    console.log(payRateDetails, workType)
    return this.calcTotalAmount(
      this.calcTotalTime(timesheet.workdetails[workType].map(t => t.value)),
      workType === "standardTime"
        ? payRateDetails[0].billingRate
        : payRateDetails[0].OTbillingRate
    )
    for (let i = 0; i < payRateDetails.length; i++) {
      // check both dates in range or not
      const payRate = payRateDetails[i]
      const checkStartRange = isWithinInterval(start, {
        start: new Date(payRate.effectiveDate).setHours(0, 0, 0, 0),
        end: new Date(payRate.effectiveUntil).setHours(0, 0, 0, 0),
      })

      const checkEndRange = isWithinInterval(end, {
        start: new Date(payRate.effectiveDate).setHours(0, 0, 0, 0),
        end: new Date(payRate.effectiveUntil).setHours(0, 0, 0, 0),
      })

      if (checkStartRange && checkEndRange) {
        return this.calcTotalAmount(
          this.calcTotalTime(timesheet.workdetails[workType].map(t => t.value)),
          workType === "standardTime"
            ? payRate.billingRate
            : payRate.OTbillingRate
        )
      } else if (checkStartRange || checkEndRange) {
        let totalAmount = 0
        for (let i = 0; i < timesheet.workdetails[workType].length; i++) {
          const date = addDays(start, i)
          totalAmount += this.IterateThroughEveryPaymentRate(
            date,
            payRateDetails,
            timesheet.workdetails[workType].map(t => t.value)[i],
            workType
          )
        }
        return totalAmount
      } else {
        return this.calcTotalAmount(
          this.calcTotalTime(timesheet.workdetails[workType].map(t => t.value)),
          workType === "standardTime"
            ? payRateDetails[0].billingRate
            : payRateDetails[0].OTbillingRate
        )
      }
    }
  }

  static IterateThroughEveryPaymentRate(
    date,
    payRateDetails,
    workingTime,
    workType
  ) {
    for (let i = 0; i < payRateDetails.length; i++) {
      const start = new Date(payRateDetails[i].effectiveDate).setHours(0, 0, 0, 0),
        end = new Date(payRateDetails[i].effectiveUntil).setHours(0, 0, 0, 0)
      const checkRange = isWithinInterval(date, {
        start: start,
        end: end,
      })
      if (checkRange) {
        const [h, m] = workingTime.split(":")
        return Math.floor(
          h *
          payRateDetails[i][
          workType === "standardTime" ? "billingRate" : "OTbillingRate"
          ] +
          (payRateDetails[i][
            workType === "standardTime" ? "billingRate" : "OTbillingRate"
          ] *
            m) /
          60
        )
      }
    }
  }



  static getBillableHours(start, end) {
    let hours = 0
    try {
      const days = eachDayOfInterval({
        start: new Date(start),
        end: new Date(end),
      })
      days.forEach((day) => {
        if (new Date(day).getDay() > 0 && new Date(day).getDay() < 6) {
          hours += 8
        }
      })
      return hours
    } catch (error) {
      hours = 0
      return hours
    }

  }
}

export class InvoiceUTILS {
  static _form_ranges_between(start, end, interval) {
    const ranges = []
    for (let i = new Date(start); i <= new Date(end); i = addDays(i, interval)) {
      ranges.push({
        start: new Date(i).setHours(0, 0, 0, 0),
        end: addDays(i, interval)
      })
    }

    return ranges
  }

  static _calc_discount_details(discountDetails, amount) {
    return discountDetails.reduce((initial, item) => {
      if (item.type === "byValue") {
        return initial - Number(item.value)
      } else if (item.type === "byPercentage" && Number(item.value) > 0) {
        return initial - Number((amount * Number(item.value)) / 100)
      } else {
        return initial - 0
      }
    }, 0)

  }

  static CalcGrandTotal(discountDetails, subTotal) {
    const discountAmount =
      discountDetails.reduce((initial, item) => {
        if (item.type === "byValue") {
          return initial - Number(item.value)
        } else if (item.type === "byPercentage" && Number(item.value) > 0) {
          return initial - Number((subTotal * Number(item.value)) / 100)
        } else {
          return initial - 0
        }
      }, 0)
    return (subTotal + discountAmount).toFixed(2)
  }
}

export class JSutils {
  static _array_to_object(array, key) {
    return array.reduce((initial, item) => {
      return {
        ...initial,
        [item[key]]: item
      }
    }, {})
  }

  static _sum(arr) {
    return arr.reduce((a, b) => a + b, 0)
  }

  static _array_to_chunk(arr, num) {
    const chunkedArray = []
    for (let i = 0; i < arr.length; i += num) {
      chunkedArray.push(arr.slice(i, i + num))
    }
    return chunkedArray
  }

  static isPropValuesEqual(subject, target, propNames) {
    return propNames.every((propName) => subject[propName] === target[propName])
  }

  static getUniqueItemsByProperties(items, propNames) {
    const propNamesArray = Array.from(propNames)

		return items.filter(
			(item, index, array) =>
				index ===
				array.findIndex((foundItem) =>
					JSutils.isPropValuesEqual(foundItem, item, propNamesArray)
				)
		)
  };

  static extractTextFromHTML(html) {
    const span = document.createElement("span")
    span.innerHTML = html
    return span.textContent || span.innerText
  }
}
