import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  table: {
    width: "100%",
    backgroundColor: "#fafafa",
  },
})

export default useStyles
