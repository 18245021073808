import React from "react";
import Presentation from "./Presentation";
import { connect } from "react-redux";
import { createLabels, updateLabels, deleteLabels } from "../../middleware";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";

function Container(props) {
  const checkColor = (name) => {
    const exp = /^#[0-9a-f]{3}([0-9a-f]{3})?$/i;
    return exp.test(name.trim());
  };

  const LabelNameValidation = {
    checkName: (name) => {
      const exp = /^[a-zA-Z \-  0-9\b]{1,}$/;
      return exp.test(name.trim());
    },
  };

  const deleteLabel = (e, resolve, reject) => {
    const { deleteLabels } = props;
    let data = {
      id: e.id,
      projectId: props.project[0].id,
    };
    deleteLabels(data, resolve, reject);
  };

  const updateLabel = (newData, oldData, resolve, reject) => {
    const { updateLabels } = props;

    if (newData.label && newData.color) {
      if (checkColor(newData.color)) {
        if (LabelNameValidation.checkName(newData.label)) {
          let data = {
            projectId: props.project[0].id,
            labelName: newData.label,
            labelId: newData.id,
            labelColorCode: newData.color,
          };

          updateLabels(data, resolve, reject);
        } else {
          return alert(
            "Label name is invalid. No special charaters are allowed"
          );
        }
      } else {
        return alert("color code is invalid");
      }
    } else if (!newData.label) {
      return alert("Name should not be empty string");
    } else if (!newData.color) {
      return alert("Color should not be empty string");
    }
  };

  const createNewLabel = (newData, resolve, reject) => {
    console.log(newData, props.project[0].id);
    const { createLabels } = props;
    if (newData.label && newData.color) {
      if (checkColor(newData.color)) {
        if (LabelNameValidation.checkName(newData.label)) {
          let data = {
            projectId: props.project[0].id,
            labelName: newData.label,
            labelId: newData.id,
            labelColorCode: newData.color,
          };

          updateLabels(data, resolve, reject);
        } else {
          reject();
          return alert(
            "Label name is invalid. No special charaters are allowed"
          );
        }
        let data = {
          labelName: newData.label,
          labelColorCode: newData.color,
          projectId: props.project[0].id,
        };
        createLabels(data, resolve, reject);
      } else {
        reject();
        return alert("color code is invalid");
      }
    } else if (!newData.label) {
      reject();
      return alert("Name should not be empty string");
    } else if (!newData.color) {
      reject();
      return alert("Color should not be empty string");
    }
  };
  if (isLoaded(props.project))
    return (
      <div>
        <Presentation
          {...props}
          createNewLabel={createNewLabel}
          updateLabel={updateLabel}
          deleteLabel={deleteLabel}
        />
      </div>
    );
  return <p>Loading.....</p>;
}
const mapStateToProps = (state, ownProps) => {
  return {
    project: state.firestore.ordered.project,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createLabels: (payload, resolve, reject) => {
      dispatch(createLabels(payload, resolve, reject));
    },
    updateLabels: (payload, resolve, reject) => {
      dispatch(updateLabels(payload, resolve, reject));
    },
    deleteLabels: (payload, resolve, reject) => {
      dispatch(deleteLabels(payload, resolve, reject));
    },
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "PROJECTS",
        doc: props.projectId,
        storeAs: "project",
      },
    ];
  })
)(Container);
