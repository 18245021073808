const initState = {
  businessInformation: {
    businessName: "",
    contactNumber: "",
    website: "",
    jobTerminationNotice: "",
    category: "",
    businessDisplayName: "",
    email: "",
    federalId: "",
    netTerms: "",
    fax: "",
    logo: "",
    permanentPlacement: {
      netTerms: "",
      commissionType: "",
      commission: "",
    },
    status: false,
    countries: [],
    states: [],
    helperText: "",
    isChecking: false,
    invoiceLocationId: 0,
    isPrimeVendor:false
  },
  contacts: {
    contactsList: [
      {
        key: 0,
        id: 0,
        representativeName: "",
        jobTitle: "",
        contactType: "",
        gender: "",
        email: "",
        mobile: "",
        workPhone: "",
        homePhone: "",
        workPhoneExtension: "",
        isDraft: true,
        location: null,
      },
    ],
    status: false,
  },
  accounts: {
    jobTitle: "",
    email: "",
    homePhone: "",
    services: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNumber: "",
    mobileNumber: "",
    to: [],
    cc: [],
    bcc: [],
    helperText: {
      to: "",
      cc: "",
      bcc: "",
    },
    status: "",
    discountDetails: [
      {
        name: "",
        value: 0,
        type: "",
      },
    ],
    discountStatus: false,
  },
  locations: {
    locationsList: [
      {
        key: 0,
        id: 0,
        locationsline1: "",
        locationsline2: "",
        locationscountry: "",
        locationsstate_name: "",
        locationscity: "",
        locationszip: "",
        isDraft: true,
      },
    ],
    status: false,
  },
};

export default initState;
