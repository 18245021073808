import ACTION from "./actionTypes"

export const documentsReq = () => ({
  type: ACTION.LOAD_EMPLOYEE_DOCS_REQ,
})

export const documentsSuccess = (payload) => ({
  type: ACTION.LOAD_EMPLOYEE_DOCS_SUCCESS,
  payload,
})

export const documentsFailure = (payload) => ({
  type: ACTION.LOAD_EMPLOYEE_DOCS_FAILURE,
  payload,
});
