import React, { useEffect } from 'react';
import { Card, Row, Col, Modal, Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateProfile } from '../../../../middleware/dynamicProfileRender';
import SSNEditModal from './ssn';
import DeleteModal from '../DeleteModal/delete';
import { useQuery } from '@tanstack/react-query';
import { getEmployee } from '../../../../../../api/services/default/employee';
import { Skeleton } from '@material-ui/lab';




const Ssn = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowDel, delModalShow] = React.useState(false);
    const {employeeID,isPersonal} = props
    const [index, setIndex] = React.useState(undefined)
    const { data : employeeData = {} } = useQuery({
        queryKey: ["getEmployee", employeeID],
        queryFn: () => getEmployee(employeeID),
        enabled: !!employeeID,
      })
    useEffect(() => {}, [employeeData]);
    const handleEdit = (idx) => {
        setModalShow(true)
        setIndex(idx)
    }

    const handleAdd = () => {
        setModalShow(true)
        setIndex(undefined)
    }

    const dispatch = useDispatch()

    const handleDelete = (idx) => {
        delModalShow(true);
        setIndex(idx)
      };
  
    const handleDeleteData = (index) => {
        if (index > -1) { 
            employeeData?.ssn?.splice(index, 1); 
          }
        delModalShow(false)
        let ssn = employeeData?.ssn?.map((item) => item)
        const payload = {
            ssn
        }
        setModalShow(false)
        dispatch(updateProfile(payload,employeeID,payload))
    }

    return (
        <>
        {Object.values(employeeData).length ? (
        <>
            <SSNEditModal
                show={modalShow}
                employeeID={employeeID}
                data={employeeData?.ssn}
                idx={index}
                onHide={() => setModalShow(false)}
            />
             <DeleteModal
                 show={modalShowDel}
                 index={index}
                 handleDelete={() => handleDeleteData(index)}
                 onHide={() => delModalShow(false)}
            />
            <Card>
                <Card.Body>
                    <div className="d-flex justify-content-between">
                        <h5 className="card-title mb-3 header-title">SSN</h5>
                        {
                            isPersonal ? <></> :
                            <Link to="#"className="linkbtn me-1 mt-0" onClick={() => handleAdd()}><i className="fa-solid fa-square-plus fs-17"></i></Link>
                        }
                    </div>
                    {
                        employeeData?.ssn?.map((res, idx) => {
                            return (
                                <div className="emplbg mb-2">
                        <div className="d-block d-lg-flex justify-content-between my-1">
                            <div>       
                                    <div className="ms-2">
                                        <h4 className="text-muted fw-bold mt-2">{res?.ssn}</h4>
                                    </div>
                            </div>
                            <div className="d-flex mt-2 mt-lg-0">
                                <a href={res?.ssndocument} target="_blank" class="wauth me-1 badge-soft-danger badge rounded-pill fw-medium" rel="noreferrer"><i className="fa-regular fa-folder-open pe-1"></i>View Document</a>
                                {
                                    isPersonal ? <></> :
                                    <>
                                     <Link to="#" className="linkbtn me-1 mt-0" onClick={() => handleEdit(idx)}><i className="fa-solid fa-pen fs-17"></i></Link>
                                <Link to="#" className="linkbtn danger mt-0" onClick={() => handleDelete(idx)}><i className="fa-regular fa-trash-can fs-17"></i></Link>
                            
                                    </>
                                }
                               </div>
                        </div>
                    </div>
                            )
                        })
                    }
                    
                </Card.Body>
            </Card>
        </>
         ) : (
            <Skeleton variant="rounded" height={260} />
        )}
        </>
    );
};
export default Ssn;