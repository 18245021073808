import React from 'react'
import {
  TextField,
  Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Paper
} from "@material-ui/core"
import SunEditor from "suneditor-react"
import { useNewInvoiceContext } from './context';


function MoreInformation() {
  const { state, stateSetters } = useNewInvoiceContext()

  return (
    <Paper className="p-2" >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Notes"
            multiline
            fullWidth
            rows={4}
            variant="outlined"
            name="notesDetails-notes"
            value={state.notes}
            onChange={(e) => stateSetters.setNotes(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Statement Memo"
            multiline
            fullWidth
            rows={4}
            variant="outlined"
            name="notesDetails-statementsMemo"
            value={state.statementMemo}
            onChange={(e) =>stateSetters.setStatementMemo(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={<Checkbox />}
            fullWidth
            name="notesDetails-includeInvoicePDF"
            label="Include notes in invoice PDF"
            onChange={(e) => stateSetters.setIncludeInvoicePDF( e.target.checked)}
            checked={state.includeInvoicePDF}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            label="Invoice category"
            fullWidth
            name="notesDetails-invoiceCategory"
            value={state.invoiceCategory}
            onChange={(e) => stateSetters.setInvoiceCategory(e.target.value)}
            variant="outlined"
          >
            <MenuItem value="">None</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SunEditor
            placeholder="Payable To"
            name="notesDetails-payableTo"
            onChange={(e) => stateSetters.setPayableTo(e)}
            setContents={state.payableTo}
            setOptions={{
              buttonList: [
                [
                  "undo",
                  "redo",
                  "font",
                  "fontSize",
                  "formatBlock",
                  "paragraphStyle",
                  "blockquote",
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                  "fontColor",
                  "hiliteColor",
                  "textStyle",
                  "removeFormat",
                  "outdent",
                  "indent",
                  "align",
                  "horizontalRule",
                  "list",
                  "lineHeight",
                  "table",
                  "link",
                  "image",
                  "video",
                  "fullScreen",
                  "showBlocks",
                  "codeView",
                  "preview",
                  "print",
                  "save",
                  "template",
                ],
              ],
              mode: "Balloon-always",
            }}
          />
          </Grid>
        <Grid item xs={12} sm={12}>
          <h3>
            <u>Additional Info</u>
          </h3>
          <SunEditor
            placeholder="Type the info here"
            onChange={(data) => {
              stateSetters.setAdditionalInfo(data)
            }}
            setContents={state.additionalInfo}
            setOptions={{
              buttonList: [
                [
                  "undo",
                  "redo",
                  "font",
                  "fontSize",
                  "formatBlock",
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                  "fontColor",
                  "hiliteColor",
                  "removeFormat",
                  "outdent",
                  "indent",
                  "align",
                  "horizontalRule",
                  "list",
                  "lineHeight",
                  "table",
                  "link",
                  "image",
                  "video",
                  "showBlocks",
                  "codeView",
                ],
              ],
              mode: "Balloon-always",
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default MoreInformation