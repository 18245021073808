import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(2),
  },
  edit: {
    backgroundColor: "#1cacd7",
    marginBottom: theme.spacing(2),
  },
  history: {
    marginBottom: theme.spacing(2),
  },
  delete: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
  customWidth: {
    maxWidth: 100,
  },
  root: {
    minWidth: 275,
    marginTop: theme.spacing(6),
  },
}))

export default useStyles
