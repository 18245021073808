import React from 'react';
import { Nav } from 'react-bootstrap';

const Navtab = () => {
    return (
        <Nav as="ul" justify variant="pills" className="border-0 p-0">
            <Nav.Item as="li" key="1">
                <Nav.Link className="cursor-pointer" eventKey="Details">
                    <span className="d-sm-block">Details</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="2">
                <Nav.Link className="cursor-pointer" eventKey="Contacts">
                    <span className="d-sm-block">Contacts</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="3">
                <Nav.Link className="cursor-pointer" eventKey="Accounts">
                    <span className="d-sm-block">Accounts</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="4">
                <Nav.Link className="cursor-pointer" eventKey="Locations">
                    <span className="d-sm-block">Locations</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="5">
                <Nav.Link className="cursor-pointer" eventKey="Placements">
                    <span className="d-sm-block">Placements</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="6">
                <Nav.Link className="cursor-pointer" eventKey="Documents">
                    <span className="d-sm-block">Documents</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="7">
                <Nav.Link className="cursor-pointer" eventKey="Expenses">
                    <span className="d-sm-block">Expenses</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="8">
                <Nav.Link className="cursor-pointer" eventKey="Invoices">
                    <span className="d-sm-block">Invoices</span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" key="9">
                <Nav.Link className="cursor-pointer" eventKey="Projects">
                    <span className="d-sm-block">Projects</span>
                </Nav.Link>
            </Nav.Item>
        </Nav>
    );
};
export default Navtab;