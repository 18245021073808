import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import Presentation from './Presentation'
import { addSectionToPlacement, updatePlacement } from '../../../../middleware'
import { uploadToStorage } from '../../../../../../shared/fileUploader'
import { CalendarFormatter } from '../../../../../../shared/CalendarFormatter'

function Container(props) {
	const {
		documents_data,
		profile,
		placement,
		req_client,
		addSectionToPlacement,
		updatePlacement,
	} = props

	const initState = {
		documentType: '',
		status: '',
		work_doc: {},
		effectiveDate: '',
		effectiveUntilDate: '',
		documents: [],
		isUploading: false,
		progress: 0,
		update: false,
		index: null,
		fillableSections: [],
		id: '',
		employeeID: profile.uid,
	}

	const [state, setState] = useState(initState)

	useEffect(() => {
		if (isLoaded(documents_data) && !isEmpty(documents_data)) {
			setState((prevState) => ({
				...prevState,
				documents: documents_data.documents.map((doc, index) => {
					return {
						...doc,
						index,
					}
				}),
			}))
		} else {
			setState((state) => ({ ...state, documents: [] }))
		}
		setState((prevState) => ({
			...prevState,
			fillableSections: placement.fillableSections,
			id: placement.id,
		}))
		if (!state.update)
			setState((prevState) => ({
				...prevState,
				effectiveDate: placement.startDate,
				effectiveUntilDate: placement.endDate,
			}))
		//return () => setState((state) => ({ ...initState }))
	}, [documents_data, placement, state.update])

	const handleChange = (event) => {
		const { name, value } = event.target
		if (name === 'documentType') {
			setState((prevState) => ({ ...prevState, work_doc: {} }))
		}
		setState((prevState) => ({
			...prevState,
			[name]: value,
		}))
	}

	const handleFile = (e) => {
		if (e.target.files[0]) {
			const file = e.target.files[0]
			setState(() => ({ ...state, file }))
			fileUpload(file, state.documentType)
		}
	}

	const clearState = () => {
		setState((prevState) => ({
			...prevState,
			documentType: '',
			status: '',
			work_doc: {},
			effectiveDate: placement.startDate,
			effectiveUntilDate: placement.endDate,
			documents: [],
			isUploading: false,
			progress: 0,
			update: false,
			index: null,
		}))
	}

	const handleUpdate = (e, rowData) => {
		e.preventDefault()
		setState((prevState) => ({
			...prevState,
			update: true,
			documentType: rowData.documentType,
			status: rowData.status,
			effectiveDate: rowData.effectiveDate,
			effectiveUntilDate: rowData.effectiveUntilDate,
			work_doc: rowData.work_doc,
			index: rowData.index,
		}))
	}

	const handleDelete = (e, rowData) => {
		const { closeTab } = props
		e.preventDefault()
		const payload = {
			delete: true,
			index: rowData.index,
		}
		updatePlacement(
			payload,
			'documents',
			state.employeeID,
			state.id,
			clearState,
			closeTab
		)
	}

	const fileUpload = async (file, type) => {
		const { placementID } = placement
		const clientName = req_client.businessDisplayName
		const otherFileName = [
			clientName,
			'_',
			placementID,
			'_',
			type,
			'_',
			new Date().toISOString(),
		].join('')
		const poFileName = [
			profile.name,
			'_',
			placementID,
			'_',
			'PO',
			'_',
			new Date().toISOString(),
		].join('')
		const fileName = type === 'PO' ? poFileName : otherFileName
		const filePath = `Placements/${placementID}/Documents/${fileName}`
		setState((prevState) => ({ ...prevState, isUploading: true }))
		uploadToStorage(file, filePath, fileName, 'file')
			.then((url) => {
				console.log(url)
				setState((prevState) => ({
					...prevState,
					work_doc: {
						...prevState.work_doc,
						name: fileName,
						url: url,
					},
					isUploading: false,
				}))
			})
			.catch((err) => {
				console.error(err)
				setState((prevState) => ({ ...prevState, isUploading: false }))
			})
	}

	const handleDateChange = (key, value) => {
		if (!isNaN(Date.parse(value))) {
			const date = new Date(value)
			date.setHours(0, 0, 0, 0)
			setState((prevState) => ({
				...prevState,
				[key]: CalendarFormatter.standardDateFormat(date),
			}))
		}
	}

	const handleSubmit = (e) => {
		//props.handleChange("panel2")
		e.preventDefault()
		const { closeTab } = props
		if (state.fillableSections.includes('documents')) {
			const payload = {
				documents: [
					{
						documentType: state.documentType,
						status: state.status,
						work_doc: state.work_doc,
						effectiveDate: state.effectiveDate,
						effectiveUntilDate: state.effectiveUntilDate,
					},
				],
			}
			addSectionToPlacement(
				payload,
				'documents',
				state.employeeID,
				state.id,
				clearState,
				closeTab
			)
		} else {
			const payload = {
				document: {
					documentType: state.documentType,
					status: state.status,
					work_doc: state.work_doc,
					effectiveDate: state.effectiveDate,
					effectiveUntilDate: state.effectiveUntilDate,
				},
				index: state.update ? state.index : state.documents.length,
			}
			updatePlacement(
				payload,
				'documents',
				state.employeeID,
				state.id,
				clearState,
				closeTab
			)
		}
	}

	if (isLoaded(documents_data)) {
		console.log('--------------DOCUMENTS---------------')
		return (
			<Presentation
				{...state}
				handleChange={handleChange}
				handleDateChange={handleDateChange}
				handleFile={handleFile}
				handleSubmit={handleSubmit}
				handleDelete={handleDelete}
				clearState={clearState}
				handleUpdate={handleUpdate}
			/>
		)
	}

	return (
		<div className='spinner'>
			<div className='bouncer'>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => {
	const { data } = state.firestore
	return {
		documents_data: data.documents_data,
		req_client: data.clients_meta_info
			? data.clients_meta_info[ownProps.placement.clientId]
			: {},
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		addSectionToPlacement: (
			payload,
			sectionName,
			uid,
			placementID,
			clearState,
			closeTab
		) => {
			dispatch(
				addSectionToPlacement(
					payload,
					sectionName,
					uid,
					placementID,
					clearState,
					closeTab
				)
			)
		},
		updatePlacement: (
			payload,
			sectionName,
			uid,
			placementID,
			clearState,
			closeTab
		) => {
			dispatch(
				updatePlacement(
					payload,
					sectionName,
					uid,
					placementID,
					clearState,
					closeTab
				)
			)
		},
	}
}

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	firestoreConnect((props) => {
		const { placement } = props
		if (!placement.id) return []
		return [
			{
				collection: 'EMPLOYEES',
				doc: placement.employeeID,
				subcollections: [
					{
						collection: 'PLACEMENTS',
						doc: placement.id,
						subcollections: [
							{
								collection: 'SETTINGS',
								doc: 'documents',
								storeAs: 'document',
							},
						],
						storeAs: 'placement_document',
					},
				],
				storeAs: 'documents_data',
			},
		]
	})
)(Container)
