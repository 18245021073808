import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const Documents = (props) => {
    const {data} = props
    return (
        <Card>
            <Card.Body>
                <h5 className="card-title mb-3 header-title">Documents</h5>
                <Row>
                    <Col>
                    {
                        Object.keys(data).includes("i-9form") ?
                        <div className="uoweg">
                        <div className="d-flex justify-content-between">
                            <div className="uowegtext">I-9 Form</div>
                            <i className="fa-solid fa-circle-check align-self-center"></i>
                        </div>
                    </div>
                    :
                    <div className="uoweg red">
                    <div className="d-flex justify-content-between">
                        <div className="uowegtext">I-9 Form</div>
                        <i className="fa-solid fa-circle-xmark align-self-center"></i>
                    </div>
                </div>
                    }
                       
                    </Col>
                    <Col>
                    {
                        Object.keys(data).includes("e-verification") ?
                        <div className="uoweg">
                        <div className="d-flex justify-content-between">
                            <div className="uowegtext">E-verify</div>
                            <i className="fa-solid fa-circle-check align-self-center"></i>
                        </div>
                    </div>
                    :
                    <div className="uoweg red">
                    <div className="d-flex justify-content-between">
                        <div className="uowegtext">E-verify</div>
                        <i className="fa-solid fa-circle-xmark align-self-center"></i>
                    </div>
                </div>
                    }  
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="uoweg">
                            <div className="d-flex justify-content-between">
                                <div className="uowegtext">Insurance</div>
                                <i className="fa-solid fa-circle-check align-self-center"></i>
                            </div>
                        </div>
                    </Col>
                    <Col>
                    {
                        Object.keys(data).includes("bankdetails") ?
                        <div className="uoweg">
                            <div className="d-flex justify-content-between">
                                <div className="uowegtext">Bank Details</div>
                                <i className="fa-solid fa-circle-check align-self-center"></i>
                            </div>
                        </div>
                        :
                        <div className="uoweg red">
                        <div className="d-flex justify-content-between">
                            <div className="uowegtext">Bank Details</div>
                            <i className="fa-solid fa-circle-xmark align-self-center"></i>
                        </div>
                    </div>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                    {
                        Object.keys(data).includes("ssn") ?
                        <div className="uoweg pb-0">
                            <div className="d-flex justify-content-between">
                                <div className="uowegtext">SSN</div>
                                <i className="fa-solid fa-circle-check align-self-center"></i>
                            </div>
                        </div>
                        :
                        <div className="uoweg pb-0 red">
                        <div className="d-flex justify-content-between">
                            <div className="uowegtext">SSN</div>
                            <i className="fa-solid fa-circle-xmark align-self-center"></i>
                        </div>
                    </div>
                        }
                    </Col>
                    <Col>
                    {
                         Object.keys(data).includes("w4") ?
                         <div className="uoweg pb-0">
                         <div className="d-flex justify-content-between">
                             <div className="uowegtext">W4 Form</div>
                             <i className="fa-solid fa-circle-check align-self-center"></i>
                         </div>
                     </div>
                     :
                     <div className="uoweg red pb-0">
                     <div className="d-flex justify-content-between">
                         <div className="uowegtext">W4 Form</div>
                         <i className="fa-solid fa-circle-xmark align-self-center"></i>
                     </div>
                 </div>
                    }
                       
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
export default Documents;