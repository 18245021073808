import React from "react"
import ClientSort from "../Services/Clients/components/ClientSort"

function ClientListPage(props) {
  return (
    <div>
      <ClientSort />
    </div>
  )
}

export default ClientListPage
