import React from 'react'
import { getInvoicesDefaulters } from '../../../../api/services/default/invoices';
import { useQuery } from '@tanstack/react-query';
import FlairTable from '../../../../shared/FlairTable';
import EmployeeHoverCard from '../../../../shared/components/EmployeeHoverCard';
import { Link } from "react-router-dom"
import Button from "react-bootstrap/Button"
import MetaInfo from '../../../../shared/getMetaInfo';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


function InvoiceDefaulters({
    clientID = "",
    employeeID = "",
}) {
    const { data: defaulters, isLoading } = useQuery({
        queryKey: ["getInvoicesDefaulters"],
        queryFn: () => getInvoicesDefaulters({
            clientID,
            employeeID,
        }),
    });

    const metaInfo = new MetaInfo();

    const columns = [
        {
            title: "Employee",
            field: "employeeName",
            render: (row) => (
                <EmployeeHoverCard employeeID={row.employeeID} />
            ),
        },
        { title: "Billable Client", field: "clientName" },
        { title: "Placement ID", field: "placementID" },
        { title: "Branch", field: "branch" },
        { title: "Timesheets to be invoiced", field: "totalPending" },
        {
            title: "Duration", field: "duration",
            render: (row) => {
                return (
                    <div title={row.timesheets.join(",")} >
                        {row.duration}
                    </div>
                )
            }
        },
    ];

    const data = defaulters?.map((row) => {
        return {
            employeeID: row.employeeID,
            employeeName: metaInfo.emailToName(row.employeeID),
            billableClient: row.billableClient,
            clientName: metaInfo.clientIdToName(row.billableClient),
            placementID: row.placementID,
            branch: metaInfo.getEmployeeKey(row.employeeID, "branch"),
            totalPending: row.totalPending,
            duration: row.duration,
            timesheets: row.timesheets,
        };
    })

    return (
        <div>
            <FlairTable
                data={data}
                columns={columns}
                isLoading={isLoading}
                title="Schools"
                actions={{
                    icon: () => (
                        <Link to='/console/invoiceslist/newinvoice'>
                            <Button className="btn btn-success px-4 btn-md text-high">Add New Invoice</Button>
                        </Link>
                    ),
                    tooltip: 'Add New Invoice',
                    isFreeAction: true,
                    position: 'toolbar',
                }}
            />
        </div>
    )
}

export default InvoiceDefaulters