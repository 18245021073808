import React from "react";
import { Container } from "react-bootstrap";
import routes from "../routes";
import { Switch, Route } from "react-router-dom";
import Breadcrumb from "../shared/BreadCrumbs";
import UnAuthorized from "../shared/unAuth";
import HistoryPage from "../pages/HistoryPage";
import OrgChartPage from "../pages/OrgChartPage";
import ProfilePage from "../pages/ProfilePage";
import { Redirect, useLocation } from "react-router";
import homePage from "../Services/Dashboard/components/Index";
import StaffListPage from "../pages/StaffListPage";
import CheckAnnouncement from "../Services/Announcements/CheckAnnouncement";

// Import Layouts
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import EmployeeAnnouncementPage from "../pages/EmployeeAnnouncementPage"

function Contains(props) {
  const { onSignout, accessModules, profile, companyLogo, companyPrefix } =
    props;
  
  let location = useLocation();
  let currentPath = [];
  let search = [];
  currentPath = location.pathname !== "/" ? location.pathname.split("/") : [];
  search = location.search !== "?" ? location.search.split("=") : [];
  if (currentPath.length > 0) currentPath.shift();

  return (
    <div id="wrapper">
      <Header logo={companyLogo.companyLogo} onSignout={onSignout} imageURL={profile.imageURL} />
      <Sidebar accessModules={accessModules} profile={profile} />
      <div className="content-page">
        <div className="content">
          <Container fluid>
            <CheckAnnouncement />
            <div className="mb-3">
              <Breadcrumb currentPath={currentPath} search={search} companyPrefix={companyPrefix} />
            </div>
            <Switch>
              {routes.map(({ path, component, moduleName }) => {
                if (
                  (accessModules.includes(moduleName) ||
                    accessModules.includes("console-customization")) &&
                  profile.status === "active"
                )
                  return (
                    <Route key={path} exact path={path} component={component} />
                  );
                return (
                  <Route
                    key={path}
                    exact
                    path={path}
                    component={UnAuthorized}
                  />
                );
              })}
              <Route exact path="/" component={homePage} />
              <Route exact path="/home" component={homePage} />
              <Route exact path="/console/history" component={HistoryPage} />
              <Route
                exact
                path="/console/orgchart/:id"
                component={OrgChartPage}
              />
              <Route exact path="/console/profile" component={ProfilePage} />
              <Route exact path="/console/staff" component={StaffListPage} />
              <Route exact path="/announcements" component={EmployeeAnnouncementPage} />
              <Redirect from="/" to={`/`} />
            </Switch>
          </Container>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Contains;
