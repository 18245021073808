import React, { useEffect } from "react"
import { connect } from "react-redux"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import { compose } from "redux"
import { _set_state } from "../../../middleware/letterTemplate"
import Presentation from "./Presentation"
import $ from "jquery"

function Container(props) {
  const { letters, lettersData, _set_state, letterTemplates } = props

  useEffect(() => {}, [letters])

  const onSelect = (id) => {
    $("#template_panel_1").trigger("click")
    const letter = lettersData[id]
    const payload = {
      id: letter.id,
      name: letter.name,
      type: letter.type,
      content: letter.content,
      updating: true,
    }
    _set_state(payload)
  }

  return isLoaded(letters) ? (
    <div>
      <Presentation
        letters={letters}
        onSelect={onSelect}
        letterTypes={letterTemplates.letterTypes}
      />
    </div>
  ) : (
    <p>Loading letters...</p>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { data, ordered } = state.firestore
  return {
    letters: ordered.templates,
    lettersData: data.templates,
    letterTemplates: data.letterTemplates,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    _set_state: (payload) => {
      dispatch(_set_state(payload))
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(() => {
    return [
      {
        collection: "LETTER_TEMPLATES",
        where: [["isExist", "==", true]],
        storeAs: "templates",
      },
    ]
  })
)(Container)
