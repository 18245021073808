import React from "react";
import { Grid, TextField, MenuItem, Button,makeStyles,Typography } from "@material-ui/core";
import validate from "../../../../../../../shared/validation";
import CountryField from "../../../../../../../shared/components/Geo/CountryField";
import StateField from "../../../../../../../shared/components/Geo/StateField";
import {
  MobileNumberFormatter,
  NumberFormatCustom,
} from "../../../../../../../shared/customNumberFormats";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { LocationOn as LocationOnIcon } from "@material-ui/icons";
const useStyles = makeStyles({
  setDrawer: {
    width: "380px",
  },
});
function Presentation(props) {
  const classes = useStyles();
  const address = {};
  const {
    contact,
    handleChange,
    onSubmit,
    locations,
    handleKeyValuePair,
    handleLocation,
  } = props;
  const location = locations.filter((e) => e.locationId === contact.location) ? 
  locations.filter((e) => e.locationId === contact.location)[0]
    : null;
console.log(location)
  const genderList = ["Female", "Male", "Others"];
  return (
    <div className="custom-card">
      <form onSubmit={onSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              label="Representative Name"
              name="representativeName"
              value={contact.representativeName}
              onChange={handleChange}
              id="new-client-accounts-representativeName"
              helperText={
                contact.representativeName.length
                  ? validate.checkName(contact.representativeName)
                    ? ""
                    : "Enter valid representative name"
                  : ""
              }
              size="small"
              required
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Job Title"
              name="jobTitle"
              value={contact.jobTitle}
              onChange={handleChange}
              id="new-client-accounts-contact-jobTitle"
              helperText={
                contact.jobTitle.length
                  ? validate.checkName(contact.jobTitle)
                    ? ""
                    : "Enter valid job title"
                  : ""
              }
              size="small"
              required
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              select
              label="Gender"
              name="gender"
              value={contact.gender}
              onChange={handleChange}
              id="new-client-accounts-gender"
              size="small"
              required
              fullWidth
              variant="outlined"
            >
              {genderList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Email"
              name="email"
              value={contact.email}
              onChange={handleChange}
              id="new-client-accounts-contact-email"
              helperText={
                contact.email.length
                  ? validate.checkEmail(contact.email)
                    ? ""
                    : "Enter valid email"
                  : ""
              }
              size="small"
              required
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Mobile"
              name="mobile"
              required
              value={contact.mobile}
              onChange={handleChange}
              id="new-client-accounts-contact-mobile"
              helperText={
                contact.mobile.length
                  ? validate.checkNumber(contact.mobile)
                    ? ""
                    : "Enter valid phone number"
                  : ""
              }
              size="small"
              //required
              fullWidth
              variant="outlined"
              InputProps={{
                inputComponent: MobileNumberFormatter,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <TextField
                  label="Work Phone"
                  name="workPhone"
                  value={contact.workPhone}
                  onChange={handleChange}
                  id="new-client-accounts-contact-workPhone"
                  // helperText={
                  //   contact.workPhone.length
                  //     ? validate.checkNumber(contact.workPhone)
                  //       ? ""
                  //       : "Enter valid work phone"
                  //     : ""
                  // }
                  size="small"
                  //required
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    inputComponent: MobileNumberFormatter,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Ext."
                  name="workPhoneExtension"
                  value={contact.workPhoneExtension}
                  id="new-client-accounts-contact-workPhoneExtension"
                  onChange={handleChange}
                  // helperText={
                  // 	workPhoneExtension.length
                  // 		? validate.checkNumber(workPhoneExtension)
                  // 			? ''
                  // 			: 'Enter valid work phone'
                  // 		: ''
                  // }
                  size="small"
                  //required
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Home Phone"
              name="homePhone"
              value={contact.homePhone}
              onChange={handleChange}
              id="new-client-accounts-contact-homePhone"
              // helperText={
              //   contact.homePhone.length
              //     ? validate.checkNumber(contact.homePhone)
              //       ? ""
              //       : "Enter valid home phone"
              //     : ""
              // }
              size="small"
              fullWidth
              variant="outlined"
              InputProps={{
                inputComponent: MobileNumberFormatter,
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <Autocomplete
              id="clients_newClient_Contacts_form"
              fullWidth
              filterSelectedOptions
              options={locations}
              classes={{
                option: classes.icon,
              }}
              value={location}
              onChange={(_, value, e) => {
                if (value) {
                  handleLocation(value.locationId);
                  console.log(value.locationId,location,"location");
                }

                //  if (value) {
                //    onChange("selectAddress", value.id)
                //  }
              }}
              style={{ marginTop: "3px" }}
              autoHighlight
              getOptionLabel={(option) =>
                option.locationsline1 +
                " " +
                option.locationsline2 +
                " " +
                option.locationscity +
                " " +
                option.locationsstate_name +
                " " +
                option.locationszip +
                " " +
                option.locationscountry
              }
              renderOption={(option) => (
                <React.Fragment>
                  <Grid container alignItems="center">
                    <Grid item>
                      <LocationOnIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                      <span style={{ fontWeight: 700 }}>
                        {option.locationsline1 + " " + option.locationsline2}
                      </span>
                      <Typography variant="body2" color="textSecondary">
                        {option.locationscity +
                          " " +
                          option.locationsstate_name +
                          " " +
                          option.locationscountry +
                          " " +
                          option.locationszip}
                      </Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Locations *"
                  size="small"
                  autoComplete="off"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <br />
            <br />
            <br />
            <br />
            <p>Note:Click Save Button to Save your Contacts Data</p>
            {/* <CountryField
              handleChange={(e) =>
                handleKeyValuePair("country", e.target.value)
              }
              label="Country"
              required={true}
              value={contact.country}
            /> */}
          </Grid>
          <Grid item xs={3}>
            {/* <TextField
              label="Address Line 1"
              name="line1"
              value={contact.line1}
              onChange={handleChange}
              id="new-client-accounts-contact-line1"
              helperText={
                contact.line1.length
                  ? validate.checkAddress(contact.line1)
                    ? ""
                    : "Enter valid address"
                  : ""
              }
              size="small"
              required
              fullWidth
              variant="outlined"
            /> */}
          </Grid>
          <Grid item xs={3}>
            {/* <TextField
              label="Address Line 2"
              name="line2"
              value={contact.line2}
              onChange={handleChange}
              id="new-client-accounts-contact-line2"
              // helperText={
              //   contact.line2.length
              //     ? validate.checkAddress(contact.line2)
              //       ? ""
              //       : "Enter valid address"
              //     : ""
              // }
              size="small"
              fullWidth
              variant="outlined"
            /> */}
          </Grid>
          <Grid item xs={3}>
            {/* <TextField
              label="City"
              name="city"
              value={contact.city}
              onChange={handleChange}
              size="small"
              required
              fullWidth
              variant="outlined"
              id="new-client-accounts-contact-city"
            /> */}
          </Grid>
          <Grid item xs={3}>

            {/* <StateField
              handleChange={(e) =>
                handleKeyValuePair("state_name", e.target.value)
              }
              label="State"
              required={true}
              value={contact.state_name}
              country={contact.country}

          </Grid>
          <Grid item xs={3}>
            {/* <TextField
              label="Zip Code"
              name="zip"
              value={contact.zip}
              onChange={handleChange}
              id="new-client-accounts-contact-zip"
              helperText={
                contact.zip.length
                  ? validate.checkZip(contact.zip)
                    ? ""
                    : "Enter valid zip code"
                  : ""
              }
              size="small"
              required
              fullWidth
              variant="outlined"
            /> */}
          </Grid>
        </Grid>
        <br />
        <div className="text-center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              contact.representativeName.trim().length <= 0 ||
              !validate.checkName(contact.representativeName.trim()) ||
              !contact.gender.trim() ||
              contact.jobTitle.trim().length <= 0 ||
              !validate.checkEmail(contact.email)
            }
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Presentation
