import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "../../../styles/navbarStyles";
import { useHistory, useLocation } from "react-router-dom";
import Layout from "../../../layouts"

function Presentation(props) {
  return <Layout {...props} />
}

export default Presentation;
