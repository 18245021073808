import React from "react"
import WikiSettings from "../Services/Wiki/components/WikiSettings"
import { Breadcrumbs } from "@material-ui/core"
import { Link } from "react-router-dom"

function WikiSettingsPage(props) {
  return (
    <div>
      <WikiSettings />
    </div>
  )
}

export default WikiSettingsPage
