//import firebase from "../../../config/fbConfig"
import {
  clientLayersForProjectFailure,
  clientLayersForProjectReq,
  clientLayersForProjectSuccess,
  setListenerForProject,
  projectMetaInfoReq,
  projectMetaInfoSuccess,
  projectMetaInfoFailure,
  unsubscribeProjectListener,
} from "../actions/actionCreators"
import MetaInfo from "../../../shared/getMetaInfo"

export const project_meta_info = (projectId) => async (dispatch, getState, { getFirebase }) => {
  console.log(projectId)
  const firebase = getFirebase()
  dispatch(projectMetaInfoReq())
  let totalTickets = 0
  let ticketsMetaInfo = {
    open: 0,
    inProgress: 0,
    review: 0,
    closed: 0,
  }
  let totalOverDue = 0
  let overDueData = {
    urgenet: 0,
    high: 0,
    medium: 0,
    low: 0,
  }
  const projectMembersData = {}
  const subscribe = firebase
    .firestore()
    .collection("ID_TRACKER")
    .doc("task_management")
    .onSnapshot(
      (snap) => {
        if (!snap.exists)
          return dispatch(
            projectMetaInfoSuccess({
              totalTickets,
              ticketsMetaInfo,
              projectMembersData,
            })
          )
        const data = snap.data()
        if (data.hasOwnProperty("tasks_tracker") && data.hasOwnProperty("employees_task_tracker")) {
          if (data.tasks_tracker.hasOwnProperty(projectId)) {
            Object.entries(data.tasks_tracker[projectId]).forEach(([key, value]) => {
              switch (key) {
                case "totalCount":
                  totalTickets = value
                  break
                case "Open":
                  ticketsMetaInfo.open = value.count
                  break
                case "InProgress":
                  ticketsMetaInfo.inProgress = value.count
                  break
                case "Review":
                  ticketsMetaInfo.review = value.count
                  break
                case "Closed":
                  ticketsMetaInfo.closed = value.count
                  break
                case "Overdue":
                  Object.entries(value).forEach(([secondaryKey, secondaryValue]) => {
                    if (typeof secondaryValue === "object") {
                      Object.entries(secondaryValue).forEach(([teritaryKey, teritaryValue]) => {
                        switch (teritaryKey) {
                          case "High":
                            overDueData.high += teritaryValue
                            break
                          case "Low":
                            overDueData.low += teritaryValue
                            break
                          case "Urgent":
                            overDueData.high += teritaryValue
                            break
                          case "Medium":
                            overDueData.medium += teritaryValue
                            break
                          default:
                            break
                        }
                      })
                    } else if (secondaryKey === "count") {
                      totalOverDue = secondaryValue
                    }
                  })
                  break
                default:
                  break
              }
            })
          }
          if (data.employees_task_tracker.hasOwnProperty(projectId)) {
            Object.entries(data.employees_task_tracker[projectId]).forEach(([key, value]) => {
              const totalTickets = value.totalCount
              const closedTicekts = value.hasOwnProperty("Closed") ? value["Closed"].count : 0
              const expectTobeClosed = totalTickets - closedTicekts
              projectMembersData[key] = {
                totalTickets,
                closedTicekts,
                expectTobeClosed,
                overDueData,
                totalOverDue,
              }
            })
          }
        }
        return dispatch(
          projectMetaInfoSuccess({
            totalTickets,
            ticketsMetaInfo,
            projectMembersData,
            overDueData,
            totalOverDue,
          })
        )
      },
      (err) => {
        return dispatch(projectMetaInfoFailure(err))
      }
    )
  return dispatch(setListenerForProject(subscribe))
}

export const unsubscribeListener = () => async (dispatch) => {
  return dispatch(unsubscribeProjectListener())
}

export const vendorsData = (projectId) => async (dispatch, getState, { getFirebase }) => {
  const clients_layers_promises = [],
    clients_layers = [],
    clients_info = []
  dispatch(clientLayersForProjectReq())
  const firebase = getFirebase()
  return firebase
    .firestore()
    .collectionGroup("SETTINGS")
    .where("id", "==", "timesheets")
    .where("linkToProject", "==", projectId)
    .get()
    .then((timesheet_settings) => {
      if (timesheet_settings.size < 1) throw new Error("no-projects")
      return timesheet_settings.docs.map((doc) => doc.data().placementID)
    })
    .then((placementIDs) => {
      placementIDs.forEach((id) => {
        clients_layers_promises.push(
          firebase
            .firestore()
            .collectionGroup("SETTINGS")
            .where("id", "==", "client_details")
            .where("placementID", "==", id)
            .get()
        )
      })
      return Promise.all(clients_layers_promises)
    })
    .then((snaps) => {
      snaps.forEach((snap) => {
        clients_info.push(snap.docs.map((doc) => doc.data())[0])
        clients_layers.push(
          snap.docs.map((doc) => {
            const clients = Object.entries(doc.data().clients)
              .map(([_, value]) => value)
              .filter((item) => item.clientId !== "")
            return {
              clients,
            }
          })[0]
        )
      })
      return firebase
        .firestore()
        .collectionGroup("PLACEMENTS")
        .where("isExist", "==", true)
        .orderBy("id", "asc")
        .get()
    })
    .then((placements_list) => {
      const clients_layers_placement_ids = clients_info.map((item) => item.placementID)
      const req_placements = placements_list.docs
        .map((doc) => doc.data())
        .filter((placement) => clients_layers_placement_ids.includes(placement.id))
      const vendors = [...new Set(req_placements.map((placement) => placement.clientId))]
      let vendorsData = vendors.map((vendor) => {
        return {
          name: new MetaInfo().clientIdToName(vendor),
          id: vendor,
          employees: [],
        }
      })
      vendorsData.forEach((vendor) => {
        req_placements.forEach((placement) => {
          if (placement.clientId === vendor.id) {
            if (!vendor.employees.includes(placement.employeeID))
              vendor.employees.push(placement.employeeID)
          }
        })
      })
      return dispatch(
        clientLayersForProjectSuccess({
          clients_layers,
          req_placements,
          vendors_data: vendorsData,
        })
      )
    })
    .catch((err) => {
      console.error(err)
      if (err.toString().match("no-projects"))
        return dispatch(
          clientLayersForProjectSuccess({
            clients_layers: [],
            req_placements: [],
            vendors_data: [],
          })
        )
      return dispatch(clientLayersForProjectFailure(err))
    })
}
