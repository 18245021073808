import React, { useEffect } from 'react';
import { Card, Row, Col, Modal, Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BankDetailsEditModal from './bankdetails';
import { Skeleton } from '@material-ui/lab';
import { useQuery } from '@tanstack/react-query';
import { getEmployee } from '../../../../../../api/services/default/employee';




const BankDetails = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const {employeeID,isPersonal} = props 
    const { data : employeeData = {} } = useQuery({
        queryKey: ["getEmployee", employeeID],
        queryFn: () => getEmployee(employeeID),
        enabled: !!employeeID,
      })
    useEffect(() => {}, [employeeData]);
    return (
        <>
        {Object.values(employeeData).length ? (
        <>
            <BankDetailsEditModal
                show={modalShow}
                employeeID={employeeID}
                data={employeeData?.bankdetails}
                onHide={() => setModalShow(false)}
            />
            <Card>
                <Card.Body>
                    <div className="d-flex justify-content-between">
                        <h5 className="card-title mb-3 header-title">Bank Details</h5>
                        {
                            isPersonal ?
                            <></>
                            :
                            <Link to="#" className="linkbtn" onClick={() => setModalShow(true)}><i className="fa-solid fa-pen"></i></Link>
                        }
                    </div>
                    <Row>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">Bank Name</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.bankdetails?.bankname}</div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">Account type</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.bankdetails?.accounttype}</div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">Account Number</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.bankdetails?.accountnumber}</div>
                            </div>
                        </Col>
                        <Col xl={4}>
                            <div className="mb-3">
                                <div className="text-muted">Paper and Eletronic Number</div>
                                <div className="fs-16 fw-medium text-base">{employeeData?.bankdetails?.['paper&electronicnumber']}</div>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
         ) : (
            <Skeleton variant="rounded" height={260} />
        )}
        </>
    );
};
export default BankDetails;