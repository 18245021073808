import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import Presentation from "./Presentation";
import validate from "../../../../../../shared/validation";
import { addSectionToPlacement, updatePlacement } from "../../../../middleware";
import { CalendarFormatter } from "../../../../../../shared/CalendarFormatter";
import CircularSpinner from "../../../../../../shared/circularSpinner";

function Container(props) {
  const {
    invoices_data,
    placement,
    profile,
    addSectionToPlacement,
    updatePlacement,
    timesheets_data,
    accounts,
    contacts_data = [],
    companyDetails,
    clientdetails_data
  } = props;

  const companyAccountsMailId = companyDetails?.contactDetails?.accountsMailId || ''

  const initState = {
    frequency: "",
    OT: true,
    POnumber: false,
    pointOfContactMailId: "",
    attachFlairExpense: false,
    attachFlairTimesheets: false,
    bcc: [],
    billingAddress: "",
    calculationType: "",
    cc: [],
    frequencyStartDate: "",
    pointOfContactName: "",
    pointOfContactPhoneNo: "",
    to: [],
    value: 0,
    index: "",
    helperText: {
      to: "",
      cc: "",
      bcc: ""
    },
    fillableSections: [],
    id: "",
    employeeID: profile.uid
  };

  const [state, setState] = useState(initState);
  const [contacts, setContacts] = useState([]);
  const [clientList,setClientList] = useState([])

  useEffect(() => {
    if (isLoaded(clientdetails_data) && !isEmpty(clientdetails_data)) {
   
    const clientList = (clientdetails_data.clients) ? (Object.values(clientdetails_data.clients)).map((e) =>e.clientId)
                                      :[]                                    
    setClientList(clientList)
    }
  },[clientdetails_data])
  useEffect(() => {
    if (isLoaded(invoices_data) && !isEmpty(invoices_data)) {
      setState((prevState) => ({
        ...prevState,
        frequency: (invoices_data.frequency - 1).toString(),
        OT: invoices_data.OT,
        POnumber: invoices_data.POnumber,
        pointOfContactMailId: invoices_data.pointOfContactMailId,
        attachFlairExpense: invoices_data.attachFlairExpense,
        attachFlairTimesheets: invoices_data.attachFlairTimesheets,
        bcc: invoices_data.bcc,
        billingAddress: invoices_data.billingAddress,
        calculationType: invoices_data.calculationType,
        cc: invoices_data.cc,
        frequencyStartDate: invoices_data.frequencyStartDate,
        pointOfContactName: invoices_data.pointOfContactName,
        pointOfContactPhoneNo: invoices_data.pointOfContactPhoneNo,
        to: invoices_data.to
      }));
    } else {
      setState(() => ({
        ...initState,
        frequencyStartDate: placement.startDate,
        bcc: [companyAccountsMailId]
      }));
    }
    if (isLoaded(timesheets_data) && !isEmpty(timesheets_data)) {
      setState((state) => ({
        ...state,
        index: timesheets_data.cycle[timesheets_data.cycle.length - 1].range
      }));
    }
    if (isLoaded(contacts_data) && !isEmpty(contacts_data)) {
      const contacts = contacts_data.map((contact) => {
        return { ...contact };
      });
      setContacts(contacts);
    }
    setState((prevState) => ({
      ...prevState,
      fillableSections: placement.fillableSections,
      id: placement.id
    }));
    //return () => setState(() => ({ ...initState }))
  }, [
    invoices_data,
    JSON.stringify(placement),
    accounts,
    contacts_data.length,
    timesheets_data
  ]);

  const handleTab = (e, v) => {
    setState({ ...state, value: v });
  };

  const handleSubmit = (e) => {
    //props.handleChange("panel6")
    e.preventDefault();
    const { closeTab } = props;
    const payload = {};
    Object.entries(state).forEach(([key, value]) => {
      if (
        key !== "fillableSections" &&
        key !== "id" &&
        key !== "employeeID" &&
        key !== "helperText" &&
        key !== "index" &&
        key !== "value"
      ) {
        if (key === "frequency") {
          payload[key] = parseInt(value) + 1;
        } else if (key === "frequencyStartDate") {
          payload[key] = CalendarFormatter.standardDateFormat(value);
        } else {
          payload[key] = value;
        }
      }
    });
    if (state.fillableSections.includes("invoices")) {
      addSectionToPlacement(
        payload,
        "invoices",
        state.employeeID,
        state.id,
        callback,
        closeTab
      );
    } else {
      updatePlacement(
        payload,
        "invoices",
        state.employeeID,
        state.id,
        callback,
        closeTab
      );
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value
    });
  };

  const handleAdd = (name, chip) => {
    let data = state[name];
    if (validate.checkEmail(chip)) {
      data = [...data, chip];
      setState({
        ...state,
        helperText: {
          to: "",
          cc: "",
          bcc: ""
        }
      });
    } else {
      setState((prevState) => ({
        ...prevState,
        helperText: {
          ...prevState.helperText,
          [name]: "Enter valid email"
        }
      }));
    }
    setState({ ...state, [name]: data });
  };

  const handleDelete = (name, index) => {
    let data = state[name].filter((_, i) => i !== index);
    setState({ ...state, [name]: data });
  };

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    setState({
      ...state,
      [name]: checked
    });
  };

  const callback = () => { };

  const handleDateChange = (key, value) => {
    if (!isNaN(Date.parse(value))) {
      const date = new Date(value);
      date.setHours(0, 0, 0, 0);
      setState({
        ...state,
        [key]: CalendarFormatter.standardDateFormat(date)
      });
    }
  };

  const handleKeyValuePair = (key, value) => {
    console.log(key, value);
    setState((state) => ({ ...state, [key]: value }));
  };

  // if (Object.keys(timesheets_data).length > 0) return <p>Fill the timesheet details</p>;
  if (isLoaded(invoices_data) && isLoaded(contacts_data) && isLoaded(clientdetails_data)) {
    console.log("--------------INVOICES---------------");
    return (
      <Presentation
        {...state}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleDateChange={handleDateChange}
        handleAdd={handleAdd}
        handleDelete={handleDelete}
        handleTab={handleTab}
        contacts={contacts}
        handleSubmit={handleSubmit}
        handleKeyValuePair={handleKeyValuePair}
        clientId={placement.clientId}
        clientList={clientList}
      />
    );
  }

  return (
    <CircularSpinner />
  );
}

const mapStateToProps = (state, ownProps) => {
  const { data, ordered } = state.firestore;
  return {
    invoices_data: data.invoices_data,
    timesheets_data: state.placement.sectionsData.timesheets.data || {},
    accounts: ordered.accounts,
    contacts_data: ordered.contacts_data,
    companyDetails: state.console?.companyDetails || {},
    clientdetails_data:state.firestore.data.clientdetails_data,

  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addSectionToPlacement: (
      payload,
      sectionName,
      uid,
      placementID,
      callback,
      closeTab
    ) => {
      dispatch(
        addSectionToPlacement(
          payload,
          sectionName,
          uid,
          placementID,
          callback,
          closeTab
        )
      );
    },
    updatePlacement: (
      payload,
      sectionName,
      uid,
      placementID,
      callback,
      closeTab
    ) => {
      dispatch(
        updatePlacement(
          payload,
          sectionName,
          uid,
          placementID,
          callback,
          closeTab
        )
      );
    }
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    const { placement } = props;
    if (!placement.id) return [];
    return [
      {
        collection: "EMPLOYEES",
        doc: placement.employeeID,
        subcollections: [
          {
            collection: "PLACEMENTS",
            doc: placement.id,
            subcollections: [
              {
                collection: "SETTINGS",
                doc: "invoices",
                storeAs: "invoices",
              },
            ],
            storeAs: "placement_invoices",
          },
        ],
        storeAs: "invoices_data",
      },
      {
        collectionGroup: "CLIENTS_ACCOUNTS",
        where: [["clientId", "==", placement.clientId]],
        storeAs: "accounts",
      },
      {
        collectionGroup: "CLIENTS_CONTACTS",
        where: [
          ["isExist", "==", true],
          ["clientId", "==", placement.clientId],
        ],
        storeAs: "contacts_data",
      },
      {
        collection: "EMPLOYEES",
        doc: placement.employeeID,
        subcollections: [
          {
            collection: "PLACEMENTS",
            doc: placement.id,
            subcollections: [
              {
                collection: "SETTINGS",
                doc: "client_details",
                storeAs: "client_details",
              },
            ],
            storeAs: "clientdetails_data",
          },
        ],
        storeAs: "clientdetails_data",
      },
    ];
  })
)(Container);
