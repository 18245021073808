// import ACTION from "../actions/actionTypes";
import initialState from "./dynamciDocState";
import { ACTION } from "../actions/actionTypes";
export const dynamicDocRender = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTION.SET_STATE:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
