import React from "react"
// import CustomTable from "../../../../../shared/customTable"
import { Link } from "react-router-dom"
import validate from "../../../../../shared/validation"
import MetaInfo from "../../../../../shared/getMetaInfo"
import MaterialTable, { MTableToolbar } from "material-table"
import CustomFilters from "../../../../../shared/components/CustomFilter"

function ToolBarComponent() {
  return []
}
function Presentation(props) {
  const {
    state,
    condition,
    tabPair,
    loggedInEmployee,
    isClientView,
    reqProjects,
  } = props
  const metaInfo = new MetaInfo()
  const statusList = ["Open", "Closed"]

  const columns = [
    {
      title: "Project name",
      field: "clientname",
      render: (rowData) => (
        <Link style={{ color: "blue" }} to={"/console/projects/" + rowData.id}>
          {rowData.clientname}
        </Link>
      ),
      width: "auto",
      dataType: "String",
    },
    {
      title: "Start date",
      field: "startdate",
      width: "auto",
      dataType: "Date",
      type: "date",
    },
    {
      title: "End date",
      field: "enddate",
      width: "auto",
      dataType: "Date",
      type: "date",
    },
    { title: "Project ID", field: "cid", width: "auto", dataType: "String" },
    {
      title: "Status",
      field: "status",
      width: "auto",
      dataType: "",
      lookup: { 0: "Open", 1: "Closed" },
    },
  ]
  let num = Date.parse(new Date())

  const filterData = Object.values(state[tabPair[condition]].data).filter(
    (e) => e.startdate < num
  )
  const data = isClientView
    ? Object.values(state[tabPair[condition]].data)
        .map((item) => {
          return {
            clientname: item.title,
            id: item.id,
            cid: item.cid,
            startdate: validate.dateFormatter(item.startdate),
            enddate: validate.dateFormatter(item.enddate),
            status: statusList.indexOf(item.status),
          }
        })
        .filter((e) => reqProjects.includes(e.id))
    : Object.values(state[tabPair[condition]].data).map((item) => {
        return {
          clientname: item.title,
          id: item.id,
          cid: item.cid,
          startdate: validate.dateFormatter(item.startdate),
          enddate: validate.dateFormatter(item.enddate),
          status: statusList.indexOf(item.status),
        }
      })
  const [newColumns, setNewColumns] = React.useState([])
  const [filterBy, setFilterBy] = React.useState({
    data: [],
    isFiltered: false,
  })
  const handleChange = (
    filteredData = [],
    isFiltered = false,
    newCol = columns
  ) => {
    console.log(filteredData, isFiltered, newCol)
    setFilterBy({
      data: filteredData,
      isFiltered: isFiltered,
    })

    setNewColumns(newCol)
    }
  function CustomTable({ data, columns, ToolBar, isToolBar, isLoading }) {
    return (
      <div>
        <MaterialTable
          title=""
          data={!filterBy.isFiltered ? data : filterBy.data}
          columns={columns}
          isLoading={isLoading}
          options={{
            pageSize: 10,
            paginationPosition: "top",
            paginationType: "normal",
            filtering: true,
            selection: true,
            columnsButton: true,
            exportButton: true,
          }}
          components={{
            Toolbar: (props) => {
              return (
                <div>
                  <MTableToolbar {...props} />
                  <div style={{ padding: "0 10px" }}>
                    <CustomFilters
                      columns={!filterBy.isFiltered ? columns : newColumns}
                      data={data}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
              )
            },
          }}
        />
      </div>
    )
  }
  return (
    <div>
      <CustomTable
        data={data}
        isLoading={
          state[tabPair[condition]].noOfLoadings === 0
            ? state[tabPair[condition]].isLoading
            : false
        }
        columns={columns}
        isToolBar={true}
        ToolBar={ToolBarComponent}
      />
    </div>
  )
}

export default Presentation
