import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import TextFormField from "../../../../../../shared/components/BootStrapFormFields/TextFormField";
import SelectFormField from "../../../../../../shared/components/BootStrapFormFields/SelectFormField";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../../middleware/dynamicProfileRender";
import AppModalHeader from "../../../../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../../../../shared/components/Modals/AppModalFooter";
import { RegexExp } from "../../../../../../shared/regex";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateEmployee } from "../../../../../../api/services/default/employee";

const schema = yup.object().shape({
  accountnumber: yup
    .string()
    .required("Account Number is required")
    .matches(RegexExp.alphanumeric),
  bankname: yup.string().required("Bank Name is required"),
  accounttype: yup.string().required("Account type is required"),
  "paper&electronicnumber": yup
    .string()
    .required("Required field")
    .matches(RegexExp.number, "Please enter the value in digits only"),
});

export default function BankDetailsEditModal(props) {
  const { data, employeeID, onHide } = props;
  const optionMapper = (i) => ({ value: i, label: i });
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const useUpdateProfile = useMutation({
		queryKey: ["updateEmployee", employeeID],
		mutationFn: ({ employeeID, data }) => updateEmployee(employeeID, data),
		onSuccess: () => {
			queryClient.invalidateQueries(["getEmployee", employeeID]);
			onHide();
		},
	});
  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);

    const payload = {
      bankdetails: values,
    };
    await useUpdateProfile.mutateAsync({ employeeID, data: payload });
		actions.setSubmitting(false);
    
  };
  const bankType = useSelector(state => state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.bankdetails?.fields?.filter((field) => field.name === "accounttype")?.[0]?.values || [])


  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        validationSchema={schema}
        initialValues={{ ...data }}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <AppModalHeader title="Edit Bank Details" />
              <Modal.Body>
                <Row>
                  <Col xl={4}>
                    <Field
                      name="accountnumber"
                      type="text"
                      label={"Account Number *"}
                      component={TextFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="bankname"
                      type="text"
                      label={"Bank Name *"}
                      component={TextFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="accounttype"
                      type="select"
                      options={bankType?.map(optionMapper)}
                      label={"Account type *"}
                      component={SelectFormField}
                    />
                  </Col>
                  <Col xl={4}>
                    <Field
                      name="paper&electronicnumber"
                      type="text"
                      label={"Paper & Electronic Number"}
                      component={TextFormField}
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <AppModalFooter
                saveButtonText="Save changes"
                saveButtonDisabled={
                  !formik.isValid || formik.isSubmitting || !formik.dirty
                }
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
