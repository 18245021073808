import React from "react";
import { useDialog } from "../../../hooks/useDialog";
import { Modal } from "react-bootstrap";
import AppModalHeader from "../../../shared/components/Modals/AppModalHeader";
import AppModalFooter from "../../../shared/components/Modals/AppModalFooter";
import { FaEdit, FaTrash, FaTrashRestore } from "react-icons/fa";
import { Formik, Form, Field } from "formik";
import TextFormField from "../../../shared/components/BootStrapFormFields/TextFormField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { saveDraftDeduction } from "../../../api/services/default/deductions";

export default function SelectDeductions({
	employeeID,
	canEdit,
	reportingDate,
	deductionsData = [],
	totalDeductions = 0,
}) {
	const { open, handleOpen, handleClose } = useDialog();
	const {
		open: openEdit,
		handleOpen: handleOpenEdit,
		handleClose: handleCloseEdit,
	} = useDialog();
	const [rows, setRows] = React.useState([]);
	const [deduction, setDeduction] = React.useState({});
	const [isEdited, setIsEdited] = React.useState(false);
	const [isSubmitting, setIsSubmitting] = React.useState(false);

	const queryClient = useQueryClient();
	const useSaveDraft = useMutation({
		mutationFn: ({ data }) => saveDraftDeduction(data),
		async onSuccess() {
			await queryClient.invalidateQueries(["getGeneralReport", reportingDate]);
			setIsSubmitting(false);
			handleClose();
		},
	});

	React.useEffect(() => {
		if (open) {
			setRows(JSON.parse(JSON.stringify(deductionsData)));
		}
	}, [deductionsData, open]);

	const columns = [
		{
			title: "Given Date",
			field: "givenDate",
			dataType: "Date",
		},
		{
			title: "Deduction Effective from",
			field: "effectiveFrom",
			dataType: "Date",
		},
		{
			title: "Remaining Balance",
			field: "remainingBalance",
			dataType: "Numeric",
		},
		{
			title: "Deduction Amount",
			field: "amountTobeDeducted",
			dataType: "Numeric",
		},
		{ title: "Advance", field: "amount", dataType: "Numeric" },
	];

	const handleReset = () => {
		setRows(JSON.parse(JSON.stringify(deductionsData)));
	};

	const handleSaveDeduction = (values) => {
		setIsEdited(true);
		setRows((prevRows) => {
			return prevRows.map((row) => {
				if (row.id === values.id) {
					return {
						...row,
						amountTobeDeducted: values.amountTobeDeducted,
						isEdited: true,
					};
				}
				return row;
			});
		});
		handleCloseEdit();
	};

	const handleSkip = async (id) => {
		handleSaveDeduction({ id, amountTobeDeducted: 0 });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!isEdited) return handleClose();
		setIsSubmitting(true);
		const editedRows = rows.filter((row) => row.isEdited);
		const data = {
			employeeId: employeeID,
			deductions: editedRows.map((row) => {
				const { isEdited, ...rest } = row;
				return rest;
			}),
		};

		useSaveDraft.mutate({ data });
	};

	if (!canEdit) {
		return <span className="text-black">{totalDeductions}</span>;
	}

	return (
		<>
			<button
				className="btn"
				style={{ marginTop: "-8px" }}
				onClick={handleOpen}
			>
				<span className="text-black">{totalDeductions}</span>
			</button>
			<Modal show={open} onHide={handleClose} centered size="lg">
				<form onSubmit={handleSubmit}>
					<AppModalHeader title="Select Deductions" />
					<Modal.Body style={{ height: "60vh" }}>
						<div style={{ height: "100%", overflowY: "auto" }}>
							<div className="d-flex justify-content-end">
								<button
                  type="button"
									className="btn"
									onClick={handleReset}
								>
                  <div className="border border-danger p-2 rounded-3">
                  <FaTrashRestore size={20} className="text-danger" />{" "}
									<span className="text-danger">Reset</span>
                  </div>
								</button>
							</div>
							<table className="table table-bordered table-striped table-fixed">
								<thead>
									<tr>
										<th>#</th>
										{columns.map((column) => (
											<th key={column.field}>{column.title}</th>
										))}
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{rows.map((deduction, index) => {
										return (
											<tr key={deduction.id}>
												<th scope="row">{index + 1}</th>
												{columns.map((column) => {
													return (
														<td key={column.field}>
															{deduction[column.field]}
														</td>
													);
												})}
												<td>
													<div className="d-flex">
														<button
															type="button"
															className="btn"
															onClick={() => {
																handleOpenEdit();
																setDeduction(deduction);
															}}
														>
															<FaEdit size={20} className="text-primary" />
														</button>
														<button
															type="button"
															className="btn"
															onClick={() => handleSkip(deduction.id)}
														>
															<FaTrash size={20} className="text-danger" />
														</button>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
							<Modal
								show={openEdit}
								onHide={handleCloseEdit}
								centered
								size="md"
								backdrop="static"
							>
								<Formik
									onSubmit={handleSaveDeduction}
									initialValues={deduction}
								>
									{({ dirty, isSubmitting, values }) => (
										<Form id="subEditForm">
											<AppModalHeader title="Edit Deduction" />
											<Modal.Body>
												<Field
													name="amountTobeDeducted"
													label="Deduction Amount"
													type="number"
													component={TextFormField}
												/>
											</Modal.Body>
											<AppModalFooter
												formId="subEditForm"
												saveButtonDisabled={
													!dirty || isSubmitting || !values.amountTobeDeducted
												}
											/>
										</Form>
									)}
								</Formik>
							</Modal>
						</div>
					</Modal.Body>
					<AppModalFooter saveButtonDisabled={isSubmitting} />
				</form>
			</Modal>
		</>
	);
}
