import React, { useState,useEffect } from "react";
import {
  Dialog,
  Typography,
  DialogContent,
  Button,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import styles from "../../../../styles/dialogStyles";
import { withStyles,makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { useNewInvoiceContext } from "./context";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useHistory } from "react-router-dom"

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(type, existing_notifiers, changed_notifiers) {
  return { type, existing_notifiers, changed_notifiers};
}

function UpdateNotifiers(props) {
  const classes = useStyles();
  const { isNotifiersChanged } = props;
  console.log(isNotifiersChanged,"isNotifiersChanged")
  const [open, setOpen] = useState(isNotifiersChanged);
  const { services,state } = useNewInvoiceContext();
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateNotifiers = () => {
    let isUpdatingNotifiers = true;
    services.updateInvoice(history,isUpdatingNotifiers);
    handleClose();
  };

  useEffect(() => {
    setOpen(isNotifiersChanged)
  }, [isNotifiersChanged])
  
  const rows = [
    createData('to', state?.notifiers?.to, state?.placementNotifiers?.to),
    createData('cc', state?.notifiers?.cc, state?.placementNotifiers?.cc),
    createData('bcc', state?.notifiers?.bcc, state?.placementNotifiers?.bcc)
  ];

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableEscapeKeyDown
        disableBackdropClick
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          You are about to update notifiers ?
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer component={Paper} style={{overflow:"hidden"}}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Existing Notifiers</TableCell>
                <TableCell>Changed Notifiers</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.type}>
                  <TableCell component="th" scope="row">
                    {row.type}
                  </TableCell>
                  <TableCell>{row.existing_notifiers.map((email)=>{
                    return(
                      <div key={email}>{email}</div>
                    )
                  })}</TableCell>
                  <TableCell>{row.changed_notifiers.map((email)=>{
                    return(
                      <div key={email}>{email}</div>
                    )
                  })}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleUpdateNotifiers}
            variant="contained"
            color="primary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UpdateNotifiers;
