import React, { useEffect, useState } from "react"
import {
  IconButton,
  AppBar,
  Dialog,
  Typography,
  Paper,
  Tab,
  Tabs,
  Tooltip
} from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"
import { FaCashRegister } from "react-icons/fa"
import useStyles from "../../../../../shared/styles/dialogStyles"
import { CalendarFormatter } from "../../../../../shared/CalendarFormatter"
import { useInvoiceListContext } from "../context";
import TabPanel from "../../../../../shared/tabPanel"
import History from './paymentHistory'
import validation from "../../../../../shared/validation"
import MakeOrUpdatePayment from "./MakeOrUpdatePayment"
import { useQueryClient } from "@tanstack/react-query"
import { Link } from "react-router-dom"

function Payment(props) {
  const { invoiceID, openBalance, invoicedAmount, receivedAmountTillNow, discountReceivedTillNow, isPaymentDone, employeeID, clientID = ""
  } = props
  const { state, services } = useInvoiceListContext()
  const classes = useStyles()
  const [open, setOpen] = useState(false)


  const initialState = {
    clientId: "",
    paymentMethod: "",
    paymentDate: CalendarFormatter.standardDateFormat(new Date()),
    referenceNumber: "",
    attachment: {
      sourcePath: "",
      publicURL: "",
    },
    isUploading: false,
    paymentAmount: "",
    discountDoneDetails: 0,
    discountDetails: [
      {
        name: "",
        value: 0,
        type: "",
      },
    ],
    receivedAmount: 0,
    noReference: false,
    paymentDoneAmount: 0,
    paymentID: "",
    paymentNotes: ""
  }
  const [isEdit, setIsEdit] = useState(false)
  const [isUpdatePayment, setIsUpdatePayment] = useState(false)
  const [paymentState, setPaymentState] = useState(initialState)
  const [componentKey, setComponentKey] = useState(Date.now())
  const [value, setValue] = useState(0)
  const handleTabChange = (_, value) => setValue(value)
  const queryClient = useQueryClient();
  const tab = validation.getQueryParameter("tab") || "0";
  const [tabValue, setTabValue] = useState(tab)

  const tabValueToTypeMap = {
    "1": "due",
    "2": "generated",
    "3": "sent",
    "4": "void",
    "5": "paid",
    "6": "all"
  };
  
  let type = tabValueToTypeMap[tabValue];

  const handleUpdate = (e, data) => {
    e.preventDefault()
    setPaymentState(() => ({
      ...data,
    }))
  }
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setIsEdit(false)
    setIsUpdatePayment(false)
    setValue(0)
  }

  useEffect(() => {
    if (open) {
      setPaymentState(initialState)
      setComponentKey(Date.now())
    }
  }, [open])

  const clearState = () => {
    setPaymentState(initialState)
    setIsEdit(false)
    setIsUpdatePayment(false)
  }

  const handleLoad = async()=>{
    await queryClient.invalidateQueries(["getInvoices",type,{clientID, employeeID}])
  }

  return (
    <div key={componentKey} >
      <Tooltip title="Make Payment">
        <Link style={{ color: "#2C9273", paddingLeft: "5px", paddingRight: "5px"}} 
          onClick={(e) => {
            e.preventDefault()
            handleClickOpen()
          }}
        >
          <FaCashRegister size={20} /></Link>
      </Tooltip>
      <Dialog disableEscapeKeyDown disableBackdropClick open={open} fullWidth maxWidth="lg">
        <AppBar className={classes.appBar}>
          <div className="d-flex justify-content-between p-2">
            <div>
              <IconButton color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </div>
            <div>
              <Typography component="h1" variant="h4" align="center" style={{ color: "whitesmoke" }}>
                Payment
              </Typography>
            </div>
            <div></div>
          </div>
        </AppBar>
          <Tabs value={value} onChange={handleTabChange} variant="scrollable">
            <Tab label="History" />
            <Tab label="Payment" disabled={isPaymentDone} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <History
              // invoiceID={invoiceID}
              invoicedAmount={invoicedAmount}
              services={services}
              invoiceID={invoiceID}
              setIsEdit={setIsEdit}
              setIsUpdatePayment={setIsUpdatePayment}
              handleUpdate={handleUpdate}
              // history={state.paymentHistory}
              handleTabChange={handleTabChange}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <main style={{padding:"0px 15px"}}>
              <MakeOrUpdatePayment
                paymentState = {paymentState}
                isUpdatePayment = {isUpdatePayment}
                setPaymentState = {setPaymentState} 
                invoicedAmount = {invoicedAmount} 
                handleClose = {handleClose} 
                handleLoad = {handleLoad}
                clearState = {clearState} 
                invoiceID = {invoiceID}
                setIsEdit = {setIsEdit}
                receivedAmountTillNow = {receivedAmountTillNow}
                discountReceivedTillNow = {discountReceivedTillNow}
                openBalance = {openBalance}
                isEdit = {isEdit}
              />
            </main>
          </TabPanel>
      </Dialog>
    </div>
  )
}

export default Payment
