import React, { useEffect } from 'react'
import TagsTable from './tagsTable'
import { useQuery } from "@tanstack/react-query";
import { getTagsData } from '../../../../../api/services/default/invoices';
import Loader from "../../../../../shared/circularSpinner"
import Validations from '../../../../../shared/validation';


function Tags() {
  const { data : tagsData,isLoading } = useQuery({
    queryKey: ["getTagsData"],
    queryFn: () => getTagsData(),
  })

  console.log("tagsUpdate",tagsData)
  if (isLoading) {
    return <Loader />
  }

  const columns = [
    { title: 'Target', displayTitle: 'Target'},
    { title: 'CheckIssuedAmount', displayTitle: 'Check Issued' },
    { title: 'PTPAmount', displayTitle: 'PTP' },
    { title: 'ACHAmount', displayTitle: 'ACH' },
    { title: 'CashNotAppliedAmount', displayTitle: 'Cash Not Applied' },
    { title: 'InternalAmount', displayTitle: 'Internal' },
    { title: 'SubcontractAmount', displayTitle: 'Subcontract' },
    { title: 'PaidWhenPaidAmount', displayTitle: 'Paid When Paid' },
    { title: 'CollectionsAmount',displayTitle: 'Collections'},
    { title: 'RemittanceAmount',displayTitle: 'Remittance'},
    { title: 'ShortPay',displayTitle: 'Short Pay'},
    { title: 'LossofPay',displayTitle: 'Loss Of Pay'}
  ];
  
  const rows = tagsData? [tagsData]: [];
    return (
      <>
      <table className="table table-bordered table-striped table-fixed">
        <thead>
          <tr>
            <th>#</th>
            {columns.map((column) => (
              <th key={column.title}>{column.displayTitle}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((rowData, index) => (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              {columns.map((column) => (
                <td key={column.title}>{Validations.currencyFormatterUs(rowData[column.title])}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <TagsTable/>
      </>
    );
}

export default Tags;
