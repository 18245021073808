import React from "react"
import ClientList from "../ClientList"

function Presentation(props) {
  const { data } = props
  return <ClientList info={data} />
}

export default Presentation

/*
function Presentation(props) {
  const { data } = props
  const [value, setValue] = React.useState(0)
  const handleTab = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
          label="Active"
            // label={
            //   <Badge
            //     color="primary"
            //     children="Active"
            //     badgeContent={
            //       data.filter((item) => item.activeConsultants !== 0).length
            //     }
            //   />
            // }
          />
          <Tab
          label="InActive"
            // label={
            //   <Badge
            //     color="primary"
            //     children="InActive"
            //     badgeContent={
            //       data.filter((item) => item.activeConsultants === 0).length
            //     }
            //   />
            // }
          />
          <Tab
          label="All"
            // label={
            //   <Badge
            //     color="primary"
            //     children="All"
            //     badgeContent={data.length}
            //   />
            // }
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ClientList
          info={data.filter((item) => item.activeConsultants !== 0)}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ClientList
          info={data.filter((item) => item.activeConsultants === 0)}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ClientList info={data} />
      </TabPanel>
    </div>
  )
}

export default Presentation

*/
