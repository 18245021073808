import React, { useState } from "react"
import Presentation from "./Presentation"
import { useQuery } from "@tanstack/react-query"
import { getEmployees } from "../../../../../api/services/default/employee"

export default function Container(props) {
  const {
    condition,
  } = props
  const [selected, setSelected] = useState([])
  const [originalList, setOriginalList] = useState([])

  const { data, isLoading } = useQuery({
    queryKey: ["getEmployees", condition],
    queryFn: () => getEmployees(condition),
  })

  const handleSelect = (employee) => {
    if (selected.includes(employee)) {
      const index = selected.indexOf(employee)
      let newlist = selected
      newlist.splice(index, 1)
      setSelected(newlist)
    } else {
      let append = [...selected, employee]
      setSelected(append)
    }
  }

  const handleSelectAll = (e, employees) => {
    let allMails = []
    employees.forEach((item) => {
      if (originalList.includes(item)) allMails.push(item)
    })
    if (e.target.checked) setSelected(allMails)
    else setSelected([])
  }

  const tabPair = [
    "allEmployees",
    "activeEmployees",
    "inActiveEmployees",
    "suspendedEmployees",
    "selectedEmployees",
  ]

  return (
    <div>
      <Presentation
        employeelist={data}
        condition={condition}
        tabPair={tabPair}
        isLoading={isLoading}
        {...props}
        selected={selected}
        originalList={originalList}
        handleSelect={handleSelect}
        handleSelectAll={handleSelectAll}
      />
    </div>
  )
}