import { defaultInstance } from "../../instances/default.instance";

/**
 * @param {Record} params
 * @param {String?} params.clientId
 * @param {String?} params.employeeID
 * @param {Boolean?} params.draft
 * @param {Boolean?} params.includeSectionsWithEmployee
 * @param {AbortSignal?} signal
 * @returns {Promise<Record[]>}
 */
export const getAllPlacements = (params, signal) => {
  const { clientId, employeeID, draft, includeSectionsWithEmployee } = params;
  const query = new URLSearchParams();
  if (clientId) {
    query.append('clientId', clientId);
  }
  if (employeeID) {
    query.append('employeeID', employeeID);
  }
  if (draft) {
    query.append('draft', draft);
  }
  if (includeSectionsWithEmployee) {
    query.append('includeSectionsWithEmployee', includeSectionsWithEmployee);
  }
  return defaultInstance({
    method: 'GET',
    url: `/placements/v2?${query.toString()}`,
    signal,
  });
};

/**
 * @param {String} id
 * @param {AbortSignal?} signal
 * @returns {Promise<Record>}
 */
export const getPlacement = (id, signal) => {
  return defaultInstance({
    method: 'GET',
    url: `/placements/v2/${id}`,
    signal,
  });
};