import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import FeatherIcon from 'feather-icons-react';
import SimpleBar from 'simplebar-react';
import ChangePasword from "../Services/Authentication/components/ChangePassword";
// images
import profilePic from '../assets/images/users/avatar-1.jpg';
import avatar2 from '../assets/images/users/avatar-2.jpg';
import avatar3 from '../assets/images/users/avatar-3.jpg';
import logoSm from '../assets/images/logo-sm.png';
import logoDark from '../assets/images/logo-dark.png';
import logoLight from '../assets/images/logo-light.png';
import { FcAdvertising } from "react-icons/fc";

const Header = (props) => {
    const history = useHistory();
    let location = useLocation();
    let currentPath = [];
    let search = [];
    currentPath = location.pathname !== "/" ? location.pathname.split("/") : [];
    search = location.search !== "?" ? location.search.split("=") : [];
    if (currentPath.length > 0) currentPath.shift();
    const {logo, imageURL, onSignout} = props
    return (
        <div className="navbar-custom">
            <div className="container-fluid">
                <div className="logo-box">
                    <Link to="/" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src={logo} alt="" height="20" />
                        </span>
                        <span className="logo-lg">
                            <img src={logo} alt="" height="50" />
                        </span>
                    </Link>
                    <Link to="/" className="logo logo-light">
                        <span className="logo-sm">
                            <img src={logo} alt="" height="20" />
                        </span>
                        <span className="logo-lg">
                            <img src={logo} alt="" height="50" />
                        </span>
                    </Link>
                </div>

                <ul className="list-unstyled topnav-menu float-end mb-0">
                    <li className="d-none d-lg-block">
                        <div className="app-search">
                            <input type="" className="form-control" placeholder="Search..." />
                            <button className="btn input-group-text"><i className="uil uil-search"></i></button>
                        </div>
                    </li>
                    <li className="dropdown notification-list topbar-dropdown">
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-notification" className="nav-link dropdown-toggle position-relative cursor-pointer">
                                <FeatherIcon icon="bell" />
                                <span className="badge bg-danger rounded-circle noti-icon-badge">6</span>
                            </Dropdown.Toggle>
{/* 
                            <Dropdown.Menu className="dropdown-menu-end dropdown-lg">
                                <div>
                                    <div className="dropdown-item noti-title">
                                        <h5 className="m-0">
                                            <span className="float-end">
                                                <Link to="#" className="text-dark"><small>Clear All</small></Link>
                                            </span>Notification
                                        </h5>
                                    </div>
                                    <SimpleBar style={{ maxHeight: 238 }} className="noti-scroll">
                                        <Link to="#" className="dropdown-item notify-item border-bottom">
                                            <div className="notify-icon bg-primary"><i className="uil uil-user-plus" /></div>
                                            <p className="notify-details">New user registered.<small className="text-muted">5 hours ago</small>
                                            </p>
                                        </Link>
                                        <Link to="#" className="dropdown-item notify-item border-bottom">
                                            <div className="notify-icon">
                                                <img src={avatar2} className="rounded-circle img-fluid" alt=""/>
                                            </div>
                                            <p className="notify-details">Karen Robinson</p>
                                            <p className="text-muted mb-0 user-msg">
                                                <small>Wow ! this admin looks good and awesome design</small>
                                            </p>
                                        </Link>
                                        <Link to="#" className="dropdown-item notify-item border-bottom">
                                            <div className="notify-icon">
                                                <img src={avatar3} className="rounded-circle img-fluid" alt=""/>
                                            </div>
                                            <p className="notify-details">Cristina Pride</p>
                                            <p className="text-muted mb-0 user-msg">
                                                <small>Hi, How are you? What about our next meeting</small>
                                            </p>
                                        </Link>
                                        <Link to="#" className="dropdown-item notify-item border-bottom active">
                                            <div className="notify-icon bg-success"><i className="uil uil-comment-message" /> </div>
                                            <p className="notify-details">
                                                Jaclyn Brunswick commented on Dashboard<small className="text-muted">1 min ago</small>
                                            </p>
                                        </Link>
                                        <Link to="#" className="dropdown-item notify-item border-bottom">
                                            <div className="notify-icon bg-danger"><i className="uil uil-comment-message" /></div>
                                            <p className="notify-details">
                                                Caleb Flakelar commented on Admin<small className="text-muted">4 days ago</small>
                                            </p>
                                        </Link>
                                        <Link to="#" className="dropdown-item notify-item">
                                            <div className="notify-icon bg-primary">
                                                <i className="uil uil-heart" />
                                            </div>
                                            <p className="notify-details">
                                                Carlos Crouch liked <b>Admin</b> <small className="text-muted">13 days ago</small>
                                            </p>
                                        </Link>
                                    </SimpleBar>
                                    <Link to="#" className="dropdown-item text-center text-primary notify-item notify-all">
                                        View all <i className="fe-arrow-right" />
                                    </Link>
                                </div>
                            </Dropdown.Menu> */}
                        </Dropdown>
                    </li>
                    <li className="dropdown notification-list">
                        <Link className="nav-link right-bar-toggle arrow-none btn btn-link" to="/announcements">
                            <FcAdvertising />
                        </Link>
                    </li>
                    <li className="dropdown notification-list">
                        <Link className="nav-link right-bar-toggle arrow-none btn btn-link shadow-none" to="#">
                            <FeatherIcon icon="settings" />
                        </Link>
                    </li>
                    <li className="dropdown notification-list topbar-dropdown">
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-custom-components" className="nav-link dropdown-toggle nav-user me-0">
                                <img src={imageURL} className="rounded-circle" alt=""/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-menu dropdown-menu-end profile-dropdown ">
                                <div className="dropdown-header noti-title">
                                    <h6 className="text-overflow m-0">Welcome !</h6>
                                </div>

                                <Link to="/console"  className="dropdown-item notify-item">
                                    <i data-feather="user" className="uil uil-user icon-xs me-1"></i><span>Console</span>
                                </Link>

                                <Link to={`/console/profile`} className="dropdown-item notify-item">
                                    <i data-feather="lock" className="uil uil-padlock icon-xs me-1"></i><span>Profile</span>
                                </Link>

                                <Link to="#" className="dropdown-item notify-item" onClick={() => window.open("https://workforce.flairtechno.com/", "_self")}>
                                <i
                                    data-feather="company-switch"
                                    className="uil uil-arrows-h icon-xs me-1"
                                ></i>
                                <span> Switch Company</span>
                                </Link>

                                <div className="dropdown-divider"></div>        
                                 <ChangePasword />
             
                                <Link to="#" className="dropdown-item notify-item" onClick={() => onSignout(history)}>
                                    <i data-feather="log-out" className="uil uil-sign-out-alt icon-xs me-1"></i><span>Logout</span>
                                </Link>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                </ul>

                <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
                    <li>
                        <button
                            className="button-menu-mobile d-block">
                            <FeatherIcon icon="menu" />
                            <i className="fe-menu"></i>
                        </button>
                    </li>

                    <li>
                        <button className="button-menu d-none">
                            <FeatherIcon icon="menu" />
                        </button>
                    </li>

                    <li>
                        <Link
                            to="#"
                            className={classNames('navbar-toggle nav-link')}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Header;