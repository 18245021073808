const ACTION = {
  // invoice list
  SET_STATE: "SET_STATE",

  SET_LISTENER: "SET_LISTENER",
  UNSUBSCRIBE_LISTENER: "UNSUBSCRIBE_LISTENER",

  GET_PREVIEW_INVOICE_REQ: "GET_PREVIEW_INVOICE_REQ",
  GET_PREVIEW_INVOICE_SUCCESS: "GET_PREVIEW_INVOICE_SUCCESS",
  GET_PREVIEW_INVOICE_FAILURE: "GET_PREVIEW_INVOICE_FAILURE",

  DOWNLOAD_INVOICE_REQ: "DOWNLOAD_INVOICE_REQ",
  DOWNLOAD_INVOICE_SUCCESS: "DOWNLOAD_INVOICE_SUCCESS",
  DOWNLOAD_INVOICE_FAILURE: "DOWNLOAD_INVOICE_FAILURE",

  LOAD_OPEN_INVOICES_REQ: "LOAD_OPEN_INVOICES_REQ",
  LOAD_OPEN_INVOICES_SUCCESS: "LOAD_OPEN_INVOICES_SUCCESS",
  LOAD_OPEN_INVOICES_FAILURE: "LOAD_OPEN_INVOICES_FAILURE",

  LOAD_GENERATED_INVOICES_REQ: "LOAD_GENERATED_INVOICES_REQ",
  LOAD_GENERATED_INVOICES_SUCCESS: "LOAD_GENERATED_INVOICES_SUCCESS",
  LOAD_GENERATED_INVOICES_FAILURE: "LOAD_GENERATED_INVOICES_FAILURE",

  LOAD_VOID_INVOICES_REQ: "LOAD_VOID_INVOICES_REQ",
  LOAD_VOID_INVOICES_SUCCESS: "LOAD_VOID_INVOICES_SUCCESS",
  LOAD_VOID_INVOICES_FAILURE: "LOAD_VOID_INVOICES_FAILURE",

  LOAD_PAID_INVOICES_REQ: "LOAD_PAID_INVOICES_REQ",
  LOAD_PAID_INVOICES_SUCCESS: "LOAD_PAID_INVOICES_SUCCESS",
  LOAD_PAID_INVOICES_FAILURE: "LOAD_PAID_INVOICES_FAILURE",

  LOAD_DUE_INVOICES_REQ: "LOAD_DUE_INVOICES_REQ",
  LOAD_DUE_INVOICES_SUCCESS: "LOAD_DUE_INVOICES_SUCCESS",
  LOAD_DUE_INVOICES_FAILURE: "LOAD_DUE_INVOICES_FAILURE",

  LOAD_ALL_INVOICES_REQ: "LOAD_ALL_INVOICES_REQ",
  LOAD_ALL_INVOICES_SUCCESS: "LOAD_ALL_INVOICES_SUCCESS",
  LOAD_ALL_INVOICES_FAILURE: "LOAD_ALL_INVOICES_FAILURE",

  MAKE_INVOICE_VOID_REQ: "MAKE_INVOICE_VOID_REQ",
  MAKE_INVOICE_VOID_SUCCESS: "MAKE_INVOICE_VOID_SUCCESS",
  MAKE_INVOICE_VOID_FAILURE: "MAKE_INVOICE_VOID_FAILURE",

  SEND_INVOICE_TO_CLIENT_REQ: "SEND_INVOICE_TO_CLIENT_REQ",
  SEND_INVOICE_TO_CLIENT_SUCCESS: "SEND_INVOICE_TO_CLIENT_SUCCESS",
  SEND_INVOICE_TO_CLIENT_FAILURE: "SEND_INVOICE_TO_CLIENT_FAILURE",

  LOAD_INVOICE_ATTACHMENTS_REQ: "LOAD_INVOICE_ATTACHMENTS_REQ",
  LOAD_INVOICE_ATTACHMENTS_SUCCESS: "LOAD_INVOICE_ATTACHMENTS_SUCCESS",
  LOAD_INVOICE_ATTACHMENTS_FAILURE: "LOAD_INVOICE_ATTACHMENTS_FAILURE",

  LOAD_MAIL_RECEIVERS_REQ: "LOAD_MAIL_RECEIVERS_REQ",
  LOAD_MAIL_RECEIVERS_SUCCESS: "LOAD_MAIL_RECEIVERS_SUCCESS",
  LOAD_MAIL_RECEIVERS_FAILURE: "LOAD_MAIL_RECEIVERS_FAILURE",

  SEND_INVOICE_REMINDER_REQ: "SEND_INVOICE_REMINDER_REQ",
  SEND_INVOICE_REMINDER_SUCCESS: "SEND_INVOICE_REMINDER_SUCCESS",
  SEND_INVOICE_REMINDER_FAILURE: "SEND_INVOICE_REMINDER_FAILURE",

  SEND_INVOICE_BULK_MAIL_REQ: "SEND_INVOICE_BULK_MAIL_REQ",
  SEND_INVOICE_BULK_MAIL_SUCCESS: "SEND_INVOICE_BULK_MAIL_SUCCESS",
  SEND_INVOICE_BULK_MAIL_FAILURE: "SEND_INVOICE_BULK_MAIL_FAILURE",

  // create invoice
  SET_CREATE_INVOICE_STATE: "SET_CREATE_INVOICE_STATE",

  LOAD_PLACEMENTS_REQ: "LOAD_PLACEMENTS_REQ",
  LOAD_PLACEMENTS_SUCCESS: "LOAD_PLACEMENTS_SUCCESS",
  LOAD_PLACEMENTS_FAILURE: "LOAD_PLACEMENTS_FAILURE",

  LOAD_CLIENTS_REQ: "LOAD_CLIENTS_REQ",
  LOAD_CLIENTS_SUCCESS: "LOAD_CLIENTS_SUCCESS",
  LOAD_CLIENTS_FAILURE: "LOAD_CLIENTS_FAILURE",

  LOAD_INVOICE_SETTINGS_REQ: "LOAD_INVOICE_SETTINGS_REQ",
  LOAD_INVOICE_SETTINGS_SUCCESS: "LOAD_INVOICE_SETTINGS_SUCCESS",
  LOAD_INVOICE_SETTINGS_FAILURE: "LOAD_INVOICE_SETTINGS_FAILURE",

  LOAD_PAYMENT_SETTINGS_REQ: "LOAD_PAYMENT_SETTINGS_REQ",
  LOAD_PAYMENT_SETTINGS_SUCCESS: "LOAD_PAYMENT_SETTINGS_SUCCESS",
  LOAD_PAYMENT_SETTINGS_FAILURE: "LOAD_PAYMENT_SETTINGS_FAILURE",

  LOAD_TIMESHEETS_REQ: "LOAD_TIMESHEETS_REQ",
  LOAD_TIMESHEETS_SUCCESS: "LOAD_TIMESHEETS_SUCCESS",
  LOAD_TIMESHEETS_FAILURE: "LOAD_TIMESHEETS_FAILURE",

  LOAD_EXPENSES_REQ: "LOAD_EXPENSES_REQ",
  LOAD_EXPENSES_SUCCESS: "LOAD_EXPENSES_SUCCESS",
  LOAD_EXPENSES_FAILURE: "LOAD_EXPENSES_FAILURE",

  GENERATE_INVOICE_REQ: "GENERATE_INVOICE_REQ",
  GENERATE_INVOICE_SUCCESS: "GENERATE_INVOICE_SUCCESS",
  GENERATE_INVOICE_FAILURE: "GENERATE_INVOICE_FAILURE",

  LOAD_INVOICE_DETAILS_REQ: "LOAD_INVOICE_DETAILS_REQ",
  LOAD_INVOICE_DETAILS_SUCCESS: "LOAD_INVOICE_DETAILS_SUCCESS",
  LOAD_INVOICE_DETAILS_FAILURE: "LOAD_INVOICE_DETAILS_FAILURE",

  UPDATE_INVOICE_DETAILS_REQ: "UPDATE_INVOICE_DETAILS_REQ",
  UPDATE_INVOICE_DETAILS_SUCCESS: "UPDATE_INVOICE_DETAILS_SUCCESS",
  UPDATE_INVOICE_DETAILS_FAILURE: "UPDATE_INVOICE_DETAILS_FAILURE",

  UPDATE_PLACEMENT_REQ: "UPDATE_PLACEMENT_REQ",
  UPDATE_PLACEMENT_SUCCESS: "UPDATE_PLACEMENT_SUCCESS",
  UPDATE_PLACEMENT_FAILURE: "UPDATE_PLACEMENT_FAILURE",

  CLIENT_APPROVE_REQ: "CLIENT_APPROVE_REQ",
  CLIENT_APPROVE_SUCCESS: "CLIENT_APPROVE_SUCCESS",
  CLIENT_APPROVE_FAILURE: "CLIENT_APPROVE_FAILURE",

  CLIENT_REJECT_REQ: "CLIENT_REJECT_REQ",
  CLIENT_REJECT_SUCCESS: "CLIENT_REJECT_SUCCESS",
  CLIENT_REJECT_FAILURE: "CLIENT_REJECT_FAILURE",


  CLIENT_ADDRESS_FOR_INVOICE_EMPLOYEE_REQ:"CLIENT_ADDRESS_FOR_INVOICE_EMPLOYEE_REQ",
  CLIENT_ADDRESS_FOR_INVOICE_EMPLOYEE_SUCCESS:"CLIENT_ADDRESS_FOR_INVOICE_EMPLOYEE_SUCCESS",
  CLIENT_ADDRESS_FOR_INVOICE_EMPLOYEE_FAILURE:"CLIENT_ADDRESS_FOR_INVOICE_EMPLOYEE_FAILURE",

}

export default ACTION
