export const initstate = {
  countries: {
    list: [],
    isLoaded: false,
    isEmpty: false,
    error: null,
  },
  states: {
    list: [],
    isLoaded: false,
    isEmpty: false,
    error: null,
  },
}
