import { makeStyles } from "@material-ui/core"

const useStylesFacebook = makeStyles({
	root: {
		position: "relative",
		marginTop: "4px",
		marginRight: "3px",
	},
	top: {
		color: "#eef3fd",
	},
	bottom: {
		color: "#6798e5",
		animationDuration: "550ms",
		position: "absolute",
		left: 0,
	},
	reverseImage: {
		WebkitTransform: "scaleX(-1)",
		transform: "scaleX(-1)",
	},
})

export default useStylesFacebook
