import React, { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import TimesheetTable from "../TimesheetTable";
import Defaulters from "../Defaulters";
import TimesheetDashboard from "../../TimesheetsDashboard/index";
import TimesheetsHistory from "../TimesheetsHistory";
import validations from "../../../../../shared/validation";
import { useHistory } from "react-router-dom";
import TimesheetOverview from "../TimesheetOverview";

export default function TabsView(props) {
  const {
    pendingRanges,
    approvedRanges,
    listAll,
    rejectedRanges,
    isManager,
    employeeID,
    loggedInEmployee
  } = props;
  const tab = Number(validations.getQueryParameter("tab"))
  const [tabValue, setTabValue] = useState(tab ? tab.toString() : "0");
  const history = useHistory();
  const pathName = window.location.pathname

  const setTabChangeValue = (newValue) => {
    history.push(`${pathName}?tab=${newValue}`)
    setTabValue(newValue);
  }

  React.useEffect(() => {
    setTabValue((prevTab) => !listAll || !isManager ? "1" : prevTab)
  }, [listAll])

  

  return (
    <Tab.Container activeKey={tabValue} onSelect={setTabChangeValue}>
      <Nav as="ul" justify variant="pills" className="">
       {
          isManager && listAll && (
            <Nav.Item as="li" key="0">
            <Nav.Link className="cursor-pointer" eventKey={"0"}>
              <span className="d-sm-block">Dashboard</span>
            </Nav.Link>
          </Nav.Item>
          )
       }
        <Nav.Item as="li" key="1">
          <Nav.Link className="cursor-pointer" eventKey={"1"}>
            <span className="d-sm-block">Submitted</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" key="2">
          <Nav.Link className="cursor-pointer" eventKey={"2"}>
            <span className="d-sm-block">Approved</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" key="3">
          <Nav.Link className="cursor-pointer" eventKey={"3"}>
            <span className="d-sm-block">
              {isManager ? "Defaulters" : "Due"}
            </span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" key="4">
          <Nav.Link className="cursor-pointer" eventKey={"4"}>
            <span className="d-sm-block">Rejected</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" key="5">
          <Nav.Link className="cursor-pointer" eventKey={"5"}>
            <span className="d-sm-block">All</span>
          </Nav.Link>
        </Nav.Item>
        {isManager && listAll ? (
          <Nav.Item as="li" key="6">
            <Nav.Link className="cursor-pointer" eventKey={"6"}>
              <span className="d-sm-block">Timesheets History</span>
            </Nav.Link>
          </Nav.Item>
        ) : null}
        {isManager && listAll ? (
        <Nav.Item as="li" key="7">
            <Nav.Link className="cursor-pointer" eventKey={"7"}>
              <span className="d-sm-block">Timesheets Overview</span>
            </Nav.Link>
          </Nav.Item>
          ) : null}
      </Nav>
      <Tab.Content className="text-muted">
        {
          isManager && listAll && (
        <Tab.Pane eventKey={"0"}>
          <TimesheetDashboard handleTabValue={setTabValue} {...props} />
        </Tab.Pane>
          )
        }
        <Tab.Pane eventKey={"1"}>
          {tabValue === "1" && (
            <TimesheetTable 
              condition={"submittedTimesheets"} 
              count={pendingRanges}   
              loggedInEmployee={loggedInEmployee} 
              isManager={isManager && listAll}
            />
          )}
        </Tab.Pane>
        <Tab.Pane eventKey={"2"}>
          {tabValue === "2" && (
            <TimesheetTable 
              condition={"approvedTimesheets"} 
              count={approvedRanges}   
              loggedInEmployee={loggedInEmployee}
              isManager={isManager && listAll}
            />
          )}
        </Tab.Pane>
        <Tab.Pane eventKey={"3"}>
         {tabValue === "3" && (
          <Defaulters employeeID={employeeID} />
          )}
        </Tab.Pane>
        <Tab.Pane eventKey={"4"}>
          {tabValue === "4" && (
            <TimesheetTable 
              condition={"rejectedTimesheets"} 
              count={rejectedRanges}   
              loggedInEmployee={loggedInEmployee}
              isManager={isManager && listAll}
            />
          )}
        </Tab.Pane>
        <Tab.Pane eventKey={"5"}>
          {tabValue === "5" && (
            <TimesheetTable 
              condition={"allTimesheets"} 
              count={0}   
              loggedInEmployee={loggedInEmployee}
              isManager={isManager && listAll}
            />
          )}
        </Tab.Pane>
        {isManager && listAll ? (
          <Tab.Pane eventKey={"6"}>
            <TimesheetsHistory />
          </Tab.Pane>
        ) : null}
          {isManager && listAll ? (
         <Tab.Pane eventKey={"7"}>
          {tabValue === "7" && (
            <TimesheetOverview />
          )}
        </Tab.Pane>
         ) : null}
      </Tab.Content>
    </Tab.Container>
  );
}
