import React from "react"
import NewArticle from "../Services/Wiki/components/NewArticle"

function CreateArticlePage(props) {
  return (
    <div>
      <NewArticle isEdit={false} {...props.match.params} />
    </div>
  )
}

export default CreateArticlePage
