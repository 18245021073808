import React from "react";
import FlairTable from "../../../../shared/FlairTable";
import { Link } from "react-router-dom";
import { Chip } from "@material-ui/core";
import TableCards from "./TableCards";
import { useQuery } from "@tanstack/react-query";
import { getAllSoa } from "../../../../api/services/default/payrolls/soa";
import validate from "../../../../shared/validation";
import { format } from "date-fns";
import { CalendarFormatter } from "../../../../shared/CalendarFormatter";

function sumValuesByKeys(arr, keysToBeAdded) {
	const result = {};

	arr?.length > 0 &&
		arr?.forEach((obj) => {
			Object.keys(obj).forEach((key) => {
				if (keysToBeAdded.includes(key)) {
					if (!result[key]) {
						result[key] = 0;
					}
					result[key] += isNaN(Number(obj[key])) ? 0 : Number(obj[key]);
				}
			});
		});

	return result;
}

export default function SoaTable({ id }) {
  const { data: soa, isLoading } = useQuery({
    queryKey: ["getAllSOA", { employeeID: id }],
    queryFn: () => getAllSoa({ employeeID: id }),
    enabled: !!id,
  })

	const columns = [
		{
			title: "Status",
			field: "status",
			dataType: "String",
		},
		{
			title: "Month",
			field: "month",
			dataType: "String",
		},
		{
			title: "Year",
			field: "year",
			dataType: "String",
		},
		{
			title: "Placement ID",
			field: "placementID",
			render: (rowData) => {
				if (rowData?.placementID)
					return (
						<Link to={"/console/placements/" + id + "/" + rowData.placementID}>
							<Chip
								className="kk-chip bg-success text-light w-75 c-pointer"
								label={rowData?.placementID?.toString()}
							/>
						</Link>
					);
			},
		},
		{
			title: "Total Revenue",
			field: "totalRevenue",
			dataType: "String",
			render: (row) => !isNaN(row?.totalRevenue) ? validate.currencyFormatterUs(row?.totalRevenue) : '--'
		},
		{
			title: "Earned Pay",
			field: "earnedPay",
			dataType: "String",
			render: (row) => !isNaN(row?.earnedPay) ? validate.currencyFormatterUs(row?.earnedPay) : '--'
		},
		{
			title: "Pay",
			field: "pay",
			dataType: "String",
			render: (row) => !isNaN(row?.pay) ? validate.currencyFormatterUs(row?.pay) : '--'
		},
		{
			title: "Deductions",
			field: "deductions",
			dataType: "String",
			render: (row) => !isNaN(row?.deductions) ? validate.currencyFormatterUs(row?.deductions) : '--'
		},
		{
			title: "Insurance",
			field: "insurance",
			dataType: "String",
			render: (row) => !isNaN(row?.insurance) ? validate.currencyFormatterUs(row?.insurance) : '--'
		},
		{
			title: "Bonus",
			field: "bonus",
			dataType: "String",
			render: (row) => !isNaN(row?.bonus) ? validate.currencyFormatterUs(row?.bonus) : '--'
		},
	];

	const data = soa?.map((item) => {
		const reportingDate = CalendarFormatter.stringToDate(item.reportingDate);
		return {
			status: item.employeeStatus.join(", ") ?? "--",
			month: format(reportingDate, "MMMM"),
			year: format(reportingDate, "yyyy"),
			placementID: item.placementID ?? "--",
			totalRevenue: item.totalInvoicedPay,
			earnedPay: item.totalEarnedPay,
			pay: item.totalPay,
			deductions: item.deductions,
			insurance: item.insurance,
			bonus: item.bonus,
		};
	}) ?? [];

	const keysToBeAdded = [
		"totalRevenue",
		"earnedPay",
		"profit",
		"pay",
		"deductions",
		"insurance",
		"bonus",
	];

	const result = sumValuesByKeys(data, keysToBeAdded);

	return (
		<div>
			<TableCards result={result} />
			<FlairTable
				title="Payroll Report"
				data={data}
				columns={columns}
				isLoading={isLoading}
			/>
		</div>
	);
}