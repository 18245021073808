import axios from "axios";
import {
  onLoginFailure,
  onLoginSuccess,
  onLoginRequest,
  onSignoutRequest,
  onSignoutSuccess,
  onSignoutFailure
} from "../actions/actionCreators";
import {
  waitingMsg,
  stopWaitMsg,
  errorMsg,
  successMsg
} from "../../../shared/SnackBars/index";
import firebase from "../../../config/fbConfig";
import { configuration } from "../../../config/companyConfig";
import make_API_call from "../../../providers/REST_API";

const MASTER_API = `https://us-central1-flair-auth.cloudfunctions.net/api`;
export const MASTER_URL = `https://workforce.flairtechno.com`;

const base_url = configuration.REST_api;

export function onLogin(creds) {
  return (dispatch, getState, { getFirebase }) => {
    dispatch(onLoginRequest());
    const firebase = getFirebase();
    firebase
      .auth()
      .signInWithEmailAndPassword(creds.email, creds.password)
      .then(({ user }) => {
        console.log(user);
        dispatch(onLoginSuccess());
        return user.getIdToken();
      })
      .then((idToken) => {
        // assigning token
        console.log(idToken);
        axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
        axios.defaults.baseURL = base_url;
        return;
      })
      .catch((err) => {
        console.error(err);
        if (
          err.code === "auth/wrong-password" ||
          err.code === "auth/user-not-found"
        )
          alert("Incorrect email or password.");
        else alert(err.message);
        dispatch(onLoginFailure());
      });
  };
}

export const loginByToken =
  (token, history) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch(onLoginRequest());
    return axios
      .get(`${configuration.sso.api}/auth/verifytoken?token=${token}`)
      .then((res) => {
        return axios.get(
          `${base_url}/auth/customtoken?email=${res.data.email}`
        );
      })
      .then((res) => {
        const token = res.data.token;
        const firebase = getFirebase();
        return firebase.auth().signInWithCustomToken(token);
      })
      .then(({ user }) => {
        console.log(user);
        dispatch(onLoginSuccess());
        return user.getIdToken();
      })
      .then((idToken) => {
        // assigning token
        console.log(idToken);
        axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
        axios.defaults.baseURL = base_url;
        return history.push("/");
      })
      .catch((err) => {
        console.error(err);
        console.log(err.response, err.message);
        if (err.response.data.message === "Unauthorized")
          errorMsg("User is either not active or Unauthorised");
        else errorMsg("Failed to login");
        dispatch(onLoginFailure());
      });
  };

export const attachTokenListener = () => {
  firebase.auth().onIdTokenChanged((user) => {
    if (user) {
      user
        .getIdTokenResult()
        .then((tokenResult) => {
          console.log(tokenResult);
          const idToken = tokenResult.token;
          axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
          axios.defaults.baseURL = base_url;
          return;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  });
};

export function onSignout(history) {
  return async (dispatch, getState, { getFirebase }) => {
    dispatch(onSignoutRequest());
    const firebase = getFirebase();
    try {
      await firebase.auth().signOut();
      if (!configuration.isSelfHosted) {
        window.open(`${configuration.sso.client}/signout`, "_self");
      } else {
        window.location.reload();
      }
      dispatch(onSignoutSuccess());
    } catch (err) {
      console.error(err);
      dispatch(onSignoutFailure());
    }
  };
}

export function forgetPassword(email) {
  return (dispatch) => {
    waitingMsg("Requesting server...");
    axios
      .post(`${base_url}/auth/forgotpassword?email=${email}`)
      .then((response) => {
        console.log(response);
        stopWaitMsg();
        successMsg(response.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.error(err);
        stopWaitMsg();
        errorMsg(err.response.data.message);
      });
  };
}

export const changeUserPassword = async ({ password }) => {
	try {
		waitingMsg("Changing your password...");
		if (!configuration.isSelfHosted) {
			const tokenResponse = await make_API_call("get", "/auth/customSsoToken");
			const token = tokenResponse.token;
      const instance = axios.create({ baseURL: configuration.sso.api });
			await instance.put(
				'/auth/changepassword',
				{ password },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
		} else {
			await make_API_call("put", "/auth/changepassword", { password });
      window.location.reload();
		}
		stopWaitMsg();
		successMsg("Password changed successfully");
	} catch (error) {
		stopWaitMsg();
		console.error(error);
		if (error.response?.data?.message) {
			errorMsg(error.response.data.message);
		} else if (error.message) {
			errorMsg(error.message);
		} else {
			errorMsg("Failed to reset password");
		}
	}
};

export const getUserCustomClaims = async () => {
  try {
    const tokenResult = await firebase.auth().currentUser.getIdTokenResult();
    const claims = tokenResult.claims;
    return claims;
  } catch (error) {
    console.error(error);
    return null;
  }
};