import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";

const Letterrequest = () => {
  const apexBarChartOpts = {
    chart: {
      foreColor: "#979797",
      height: 320,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "60%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 7,
      colors: ["transparent"],
    },
    colors: ["#FFAB00", "#A9CB8E", "#FF8F73"],
    legend: {
      show: true,
    },
    xaxis: {
      categories: [
        "ATL",
        "NJ",
        "NC",
        "FL",
        "DAL",
        "AUS",
        "SA",
        "VA",
        "STL",
        "MN",
        "CA-N",
        "SPO",
      ],
      axisBorder: {
        show: true,
        color: "rgba(0,0,0,0.05)",
      },
      axisTicks: {
        show: true,
        color: "rgba(0,0,0,0.05)",
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
        color: "rgba(0,0,0,0.05)",
      },
      axisTicks: {
        show: true,
        color: "rgba(0,0,0,0.05)",
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: ["transparent", "transparent"],
        opacity: 0.2,
      },
      borderColor: "rgba(0, 0, 0, .1)",
    },
    fill: {
      opacity: 1,
    },
  };

  const apexBarChartData = [
    {
      name: "Submitted",
      data: [40, 25, 30, 20, 25, 35, 30, 20, 10, 15, 15, 20],
    },
    {
      name: "Approved",
      data: [30, 20, 25, 15, 10, 20, 35, 25, 20, 30, 35, 40],
    },
    {
      name: "Rejected",
      data: [15, 25, 10, 20, 25, 30, 10, 20, 30, 15, 25, 15],
    },
  ];

  return (
    <div className="card-border">
      <div className="d-flex justify-content-between card-border-title mt-1 pb-0">
        <div className="">Letter Requests</div>
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            All Requests <i className="uil uil-angle-down ps-1"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1">Offer Letter</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Experience Letter</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Relieving Letter</Dropdown.Item>
            <Dropdown.Item href="#/action-4">Handbook Signature</Dropdown.Item>
            <Dropdown.Item href="#/action-5">Travel Document</Dropdown.Item>
            <Dropdown.Item href="#/action-6">H1-B Document</Dropdown.Item>
            <Dropdown.Item href="#/action-7">Payroll</Dropdown.Item>
            <Dropdown.Item href="#/action-8">I-983</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="card-body pt-0">
        <Chart
          options={apexBarChartOpts}
          series={apexBarChartData}
          type="bar"
          className="apex-charts mt-1"
          height={320}
          dir="ltr"
        />
        <div className="d-flex justify-content-center mt-2">
          <Link to="#" className="me-1 mt-0">
            <i className="uil uil-angle-left fs-17"></i> Prev 30 days{" "}
          </Link>
          <span className="align-self-center px-1">|</span>
          <Link to="#" className="mt-0 ms-1">
            Next 30 days <i className="uil uil-angle-right fs-17"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Letterrequest;
