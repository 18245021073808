import frontendKeyConfigurations from "./frontend-key-configurations.json"

const isDevelopment = window.location.href.includes("http://localhost");
const isSelfHosted = frontendKeyConfigurations.sso === undefined;
const hasFlairSharedAccess = frontendKeyConfigurations.flair_shared_api !== undefined;
// const isDevelopment = false;
// const isSelfHosted = true;
// const hasFlairSharedAccess = false;

export const configuration = {
  client: 'Eficens Systems LLC',
  web_url: 'https://eficensit.flairtechno.com/',
  owner: 'Flair',
  flair_logo:
    'https://firebasestorage.googleapis.com/v0/b/flair-technologies.appspot.com/o/images%2Fownerlogo.png?alt=media&token=be5b502b-6435-41c1-a61d-8e8519b66b6b',
  logo: 'https://firebasestorage.googleapis.com/v0/b/flair-d7b59.appspot.com/o/reactlogo.png?alt=media&token=5692ca8d-6307-409e-b4b8-e42518c00ae5',
  PDF_header:
    'https://firebasestorage.googleapis.com/v0/b/flair-d7b59.appspot.com/o/companycustoms%2FPDF_header.png.jpg?alt=media&token=adb60ffc-5e49-49f2-9f86-d793e9e69701',
  PDF_footer:
    'https://firebasestorage.googleapis.com/v0/b/flair-d7b59.appspot.com/o/companycustoms%2FPDF_footer.png.jpg?alt=media&token=6054e5aa-f1f3-4e60-bd53-a1bfb0ac5abb',
  bg_color: '',
  REST_api: isDevelopment
    ? frontendKeyConfigurations.api.local
    : frontendKeyConfigurations.api.prod,
  isSelfHosted,
  sso: isSelfHosted
    ? null
    : isDevelopment
      ? {
        api: frontendKeyConfigurations.sso.api.local,
        client: frontendKeyConfigurations.sso.client.local,
      }
      : {
        api: frontendKeyConfigurations.sso.api.prod,
        client: frontendKeyConfigurations.sso.client.prod,
      },
  empcode: '',
  dateformat: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
  contactdetails: {
    address: '5400 Laurel Springs Pkwy, Suite 603, Suwanee GA 30024',
    phoneno: '+1 678-892-9800',
    mailId: 'contact@eficensittest.com',
    eVerifyNo: '1463449',
    einNo: '84-3443670',
  },
  timeformat: {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
    timeZone: 'America/New_York',
  },
  'date-code': 'en-US',
  email: 'no-reply@flairtechno.com',
  letterTypes: [
    'Offer Letter',
    'Experience Letter',
    'Relieving Letter',
    'Hand Book Signature',
    'Travel Document Content',
    'H1B Document',
    'Payroll Issue',
    'I-983',
  ],
  draggableLabels: [
    'TodayDate',
    'UniversityName',
    'UniversityAddress',
    'FirstName',
    'LastName',
    'CompanyName',
    'JobTitle',
    'EffectiveDate',
    'CompanyEverifyNumber',
    'CompanyEIN',
    'CompanyAddress',
    'HRMailId',
    'AuthorizedPersonSignature',
    'AuthorizedName',
    'AuthorizedPersonDesignation',
  ],
  dateformatter: 'MM/dd/yyyy',
  hasFlairSharedAccess,
  flair_shared_api: hasFlairSharedAccess
    ? isDevelopment
      ? frontendKeyConfigurations.flair_shared_api.local
      : frontendKeyConfigurations.flair_shared_api.prod
    : undefined,
  flair_shared_api_key: hasFlairSharedAccess
    ? frontendKeyConfigurations.flair_shared_api.api_key
    : undefined,
}