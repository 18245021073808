import React from "react"
import EmployeeList from "../../EmployeeList"

export default function Presentation(props) {
  const {
    employeelist,
    condition,
    selected,
    handleSelectAll,
    handleSelect,
    isLoading,
    actions,
    tableRef,
    onCellClicked,
    onSelectionChange,
  } = props

  return (
    <EmployeeList
      handleSelectAll={handleSelectAll}
      selected={selected}
      handleSelect={handleSelect}
      employeelist={employeelist}
      isLoading={isLoading}
      condition={condition}
      actions={actions}
      tableRef={tableRef}
      onCellClicked={onCellClicked}
      onSelectionChange={onSelectionChange}
    />
  )
}
