import React from "react";
import ProjectList from "../Services/TaskManagement/components/dashboards/ProjectlistDashboard/Index";

function ProjectListPage(props) {
  return (
    <div>
      <ProjectList />
    </div>
  );
}

export default ProjectListPage;
