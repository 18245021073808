import { getIn } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

export default function RichTextEditor({ field, form, required, options = {}, ...props }) {
	const errorText =
		 getIn(form.errors, field.name);

	return (
		<Form.Group className="mb-3">
			<Form.Label>{props.label}</Form.Label>
			<SunEditor
				placeholder="Please type here..."
				setContents={field.value}
				onChange={(data) => {
					return form.setFieldValue(field.name, data, true);
				}}
				setDefaultStyle="background-color: transparent;"
				height={200}
				setOptions={{
					buttonList: [
						[
							"undo",
							"redo",
							"font",
							"fontSize",
							"formatBlock",
							"bold",
							"underline",
							"italic",
							"strike",
							"subscript",
							"superscript",
							"fontColor",
							"hiliteColor",
							"removeFormat",
							"outdent",
							"indent",
							"align",
							"horizontalRule",
							"list",
							"lineHeight",
							"table",
							"link",
							"image",
							"video",
							"showBlocks",
							"codeView",
						],
					],
          ...options
				}}
				{...props}
			/>
			{errorText && <Form.Text className="text-danger">{errorText}</Form.Text>}
		</Form.Group>
	);
}
