const ACTION = {
  SET_STATE: "SET_STATE",

  SET_LISTENER: "SET_LISTENER",

  UNSUBSCRIBE_LISTENER: "UNSUBSCRIBE_LISTENER",

  MODULES_REQUEST: "MODULES_REQUEST",
  MODULES_SUCCESS: "MODULES_SUCCESS",
  MODULES_FAILURE: "MODULES_FAILURE",

  ALL_MODULES_REQUEST: "ALL_MODULES_REQUEST",
  ALL_MODULES_SUCCESS: "ALL_MODULES_SUCCESS",
  ALL_MODULES_FAILURE: "ALL_MODULES_FAILURE",

  EMPLOYEES_REQUEST: "EMPLOYEES_REQUEST",
  EMPLOYEES_SUCCESS: "EMPLOYEES_SUCCESS",
  EMPLOYEES_FAILURE: "EMPLOYEES_FAILURE",

  EMPLOYEES_RESET_PASSWORD_REQUEST: "EMPLOYEES_RESET_PASSWORD_REQUEST",
  EMPLOYEES_RESET_PASSWORD_SUCCESS: "EMPLOYEES_RESET_PASSWORD_SUCCESS",
  EMPLOYEES_RESET_PASSWORD_FAILURE: "EMPLOYEES_RESET_PASSWORD_FAILURE",

  INVITE_EMPLOYEE_REQUEST: "INVITE_EMPLOYEE_REQUEST",
  INVITE_EMPLOYEE_SUCCESS: "INVITE_EMPLOYEE_SUCCESS",
  INVITE_EMPLOYEE_FAILURE: "INVITE_EMPLOYEE_FAILURE",

  SET_EXCEL_INVITES: "SET_EXCEL_INVITES",

  EMPLOYEE_REGISTRATION_REQUEST: "EMPLOYEE_REGISTRATION_REQUEST",
  EMPLOYEE_REGISTRATION_SUCCESS: "EMPLOYEE_REGISTRATION_SUCCESS",
  EMPLOYEE_REGISTRATION_FAILURE: "EMPLOYEE_REGISTRATION_FAILURE",

  LOAD_EMPLOYEE_PROFILE_REQUEST: "LOAD_EMPLOYEE_PROFILE_REQUEST",
  LOAD_EMPLOYEE_PROFILE_SUCCESS: "LOAD_EMPLOYEE_PROFILE_SUCCESS",
  LOAD_EMPLOYEE_PROFILE_FAILURE: "LOAD_EMPLOYEE_PROFILE_FAILURE",

  UPDATE_EMPLOYEE_PROFILE_REQUEST: "UPDATE_EMPLOYEE_PROFILE_REQUEST",
  UPDATE_EMPLOYEE_PROFILE_SUCCESS: "UPDATE_EMPLOYEE_PROFILE_SUCCESS",
  UPDATE_EMPLOYEE_PROFILE_FAILURE: "UPDATE_EMPLOYEE_PROFILE_FAILURE",

  ENABLE_EMPLOYEE_REQUEST: "ENABLE_EMPLOYEE_REQUEST",
  ENABLE_EMPLOYEE_SUCCESS: "ENABLE_EMPLOYEE_SUCCESS",
  ENABLE_EMPLOYEE_FAILURE: "ENABLE_EMPLOYEE_FAILURE",

  SUSPEND_EMPLOYEE_REQUEST: "SUSPEND_EMPLOYEE_REQUEST",
  SUSPEND_EMPLOYEE_SUCCESS: "SUSPEND_EMPLOYEE_SUCCESS",
  SUSPEND_EMPLOYEE_FAILURE: "SUSPEND_EMPLOYEE_FAILURE",

  UPDATE_SUSPEND_EMPLOYEE_REQUEST: "UPDATE_SUSPEND_EMPLOYEE_REQUEST",
  UPDATE_SUSPEND_EMPLOYEE_SUCCESS: "UPDATE_SUSPEND_EMPLOYEE_SUCCESS",
  UPDATE_SUSPEND_EMPLOYEE_FAILURE: "UPDATE_SUSPEND_EMPLOYEE_FAILURE",

  LOAD_ALL_EMPLOYEES_REQUEST: "LOAD_ALL_EMPLOYEES_REQUEST",
  LOAD_ALL_EMPLOYEES_SUCCESS: "LOAD_ALL_EMPLOYEES_SUCCESS",
  LOAD_ALL_EMPLOYEES_FAILURE: "LOAD_ALL_EMPLOYEES_FAILURE",

  LOAD_ACTIVE_EMPLOYEES_REQUEST: "LOAD_ACTIVE_EMPLOYEES_REQUEST",
  LOAD_ACTIVE_EMPLOYEES_SUCCESS: "LOAD_ACTIVE_EMPLOYEES_SUCCESS",
  LOAD_ACTIVE_EMPLOYEES_FAILURE: "LOAD_ACTIVE_EMPLOYEES_FAILURE",

  LOAD_INACTIVE_EMPLOYEES_REQUEST: "LOAD_INACTIVE_EMPLOYEES_REQUEST",
  LOAD_INACTIVE_EMPLOYEES_SUCCESS: "LOAD_INACTIVE_EMPLOYEES_SUCCESS",
  LOAD_INACTIVE_EMPLOYEES_FAILURE: "LOAD_INACTIVE_EMPLOYEES_FAILURE",

  LOAD_SUSPENDED_EMPLOYEES_REQUEST: "LOAD_SUSPENDED_EMPLOYEES_REQUEST",
  LOAD_SUSPENDED_EMPLOYEES_SUCCESS: "LOAD_SUSPENDED_EMPLOYEES_SUCCESS",
  LOAD_SUSPENDED_EMPLOYEES_FAILURE: "LOAD_SUSPENDED_EMPLOYEES_FAILURE",

  LOAD_SELECTED_EMPLOYEES_REQUEST: "LOAD_SELECTED_EMPLOYEES_REQUEST",
  LOAD_SELECTED_EMPLOYEES_SUCCESS: "LOAD_SELECTED_EMPLOYEES_SUCCESS",
  LOAD_SELECTED_EMPLOYEES_FAILURE: "LOAD_SELECTED_EMPLOYEES_FAILURE",

  LOAD_INVITATION_EMAIL_REQUEST: "LOAD_INVITATION_EMAIL_REQUEST",
  LOAD_INVITATION_EMAIL_SUCCESS: "LOAD_INVITATION_EMAIL_SUCCESS",
  LOAD_INVITATION_EMAIL_FAILURE: "LOAD_INVITATION_EMAIL_FAILURE",

  LOAD_INVITATED_EMPIFNO_REQUEST: "LOAD_INVITATED_EMPIFNO_REQUEST",
  LOAD_INVITATED_EMPIFNO_SUCCESS: "LOAD_INVITATED_EMPIFNO_SUCCESS",
  LOAD_INVITATED_EMPIFNO_FAILURE: "LOAD_INVITATED_EMPIFNO_FAILURE",

  GET_META_INFO_EMPLOYEE_REQUEST: "GET_META_INFO_EMPLOYEE_REQUEST",
  GET_META_INFO_EMPLOYEE_SUCCESS: "GET_META_INFO_EMPLOYEE_SUCCESS",
  GET_META_INFO_EMPLOYEE_FAILURE: "GET_META_INFO_EMPLOYEE_FAILURE",
}


export default ACTION;
