import { defaultInstance } from "../../instances/default.instance";

/**
 * @returns {Promise<Array<Record>>}
 */
export const loadCountries = () => {
  return defaultInstance({
    method: 'GET',
    url: '/loadcountries',
  });
};

/**
 * @param {string} code
 * @param {AbortSignal?} signal
 * @returns {Promise<Array<Record>>}
 */
export const loadStates = async (code, signal) => {
  return defaultInstance({
    method: 'GET',
    url: `/loadstates?countrycode=${code}`,
    signal,
  });
};