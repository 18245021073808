import React, { useEffect } from 'react'
import { useHistoryContext } from '../context'
import Presentation from './Presentation'

export default function Container() {
	const { state, services } = useHistoryContext()

	useEffect(() => {
		services.getModules()
	}, [])

	if (state.isLoading) {
		return <p>Loading...</p>
	}

	return (
		<React.Fragment>
			<Presentation />
		</React.Fragment>
	)
}
