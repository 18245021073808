const ACTION = {
  SET_STATE: "SET_STATE",

  SET_LISTENER: "SET_LISTENER",

  UNSUBSCRIBE_LISTENER: "UNSUBSCRIBE_LISTENER",

  NEW_PROJECT_REQUEST: "NEW_PROJECT_REQUEST",
  NEW_PROJECT_SUCCESS: "NEW_PROJECT_SUCCESS",
  NEW_PROJECT_FAILURE: "NEW_PROJECT_FAILURE",

  UPDATE_PROJECT_REQUEST: "UPDATE_PROJECT_REQUEST",
  UPDATE_PROJECT_SUCCESS: "UPDATE_PROJECT_SUCCESS",
  UPDATE_PROJECT_FAILURE: "UPDATE_PROJECT_FAILURE",

  ADD_MEMBERS_REQUEST: "ADD_MEMBERS_REQUEST",
  ADD_MEMBERS_SUCCESS: "ADD_MEMBERS_SUCCESS",
  ADD_MEMBERS_FAILURE: "ADD_MEMBERS_FAILURE",

  LOAD_PROJECT_TIMELINE_REQUEST: "LOAD_PROJECT_TIMELINE_REQUEST",
  LOAD_PROJECT_TIMELINE_SUCCESS: "LOAD_PROJECT_TIMELINE_SUCCESS",
  LOAD_PROJECT_TIMELINE_FAILURE: "LOAD_PROJECT_TIMELINE_FAILURE",

  REMOVE_MEMBER_REQUEST: "REMOVE_MEMBER_REQUEST",
  REMOVE_MEMBER_SUCCESS: "REMOVE_MEMBER_SUCCESS",
  REMOVE_MEMBER_FAILURE: "REMOVE_MEMBER_FAILURE",

  CREATE_LABELS_REQUEST: "CREATE_LABELS_REQUEST",
  CREATE_LABELS_SUCCESS: "CREATE_LABELS_SUCCESS",
  CREATE_LABELS_FAILURE: "CREATE_LABELS_FAILURE",

  DELETE_LABELS_REQUEST: "DELETE_LABELS_REQUEST",
  DELETE_LABELS_SUCCESS: "DELETE_LABELS_SUCCESS",
  DELETE_LABELS_FAILURE: "DELETE_LABELS_FAILURE",

  UPDATE_LABELS_REQUEST: "UPDATE_LABELS_REQUEST",
  UPDATE_LABELS_SUCCESS: "UPDATE_LABELS_SUCCESS",
  UPDATE_LABELS_FAILURE: "UPDATE_LABELS_FAILURE",

  UPDATE_PERMISSIONS_REQUEST: "UPDATE_PERMISSIONS_REQUEST",
  UPDATE_PERMISSIONS_SUCCESS: "UPDATE_PERMISSIONS_SUCCESS",
  UPDATE_PERMISSIONS_FAILURE: "UPDATE_PERMISSIONS_FAILURE",

  NEW_TASKS_REQUEST: "NEW_TASKS_REQUEST",
  NEW_TASKS_SUCCESS: "NEW_TASKS_SUCCESS",
  NEW_TASKS_FAILURE: "NEW_TASKS_FAILURE",

  EDIT_TASKS_REQUEST: "EDIT_TASKS_REQUEST",
  EDIT_TASKS_SUCCESS: "EDIT_TASKS_SUCCESS",
  EDIT_TASKS_FAILURE: "EDIT_TASKS_FAILURE",

  LINK_TASKS_REQUEST: "LINK_TASKS_REQUEST",
  LINK_TASKS_SUCCESS: "LINK_TASKS_SUCCESS",
  LINK_TASKS_FAILURE: "LINK_TASKS_FAILURE",

  UNLINK_TASKS_REQUEST: "UNLINK_TASKS_REQUEST",
  UNLINK_TASKS_SUCCESS: "UNLINK_TASKS_SUCCESS",
  UNLINK_TASKS_FAILURE: "UNLINK_TASKS_FAILURE",

  NEW_SUBTASKS_REQUEST: "NEW_SUBTASKS_REQUEST",
  NEW_SUBTASKS_SUCCESS: "NEW_SUBTASKS_SUCCESS",
  NEW_SUBTASKS_FAILURE: "NEW_SUBTASKS_FAILURE",

  NEW_COMMENTS_REQUEST: "NEW_COMMENTS_REQUEST",
  NEW_COMMENTS_SUCCESS: "NEW_COMMENTS_SUCCESS",
  NEW_COMMENTS_FAILURE: "NEW_COMMENTS_FAILURE",

  UPDATE_COMMENTS_REQUEST: "UPDATE_COMMENTS_REQUEST",
  UPDATE_COMMENTS_SUCCESS: "UPDATE_COMMENTS_SUCCESS",
  UPDATE_COMMENTS_FAILURE: "UPDATE_COMMENTS_FAILURE",

  DELETE_COMMENTS_REQUEST: "DELETE_COMMENTS_REQUEST",
  DELETE_COMMENTS_SUCCESS: "DELETE_COMMENTS_SUCCESS",
  DELETE_COMMENTS_FAILURE: "DELETE_COMMENTS_FAILURE",

  LOAD_ALL_PROJECTS_REQUEST: "LOAD_ALL_PROJECTS_REQUEST",
  LOAD_ALL_PROJECTS_SUCCESS: "LOAD_ALL_PROJECTS_SUCCESS",
  LOAD_ALL_PROJECTS_FAILURE: "LOAD_ALL_PROJECTS_FAILURE",

  LOAD_DASH_ALL_PROJECTS_REQUEST: "LOAD_DASH_ALL_PROJECTS_REQUEST",
  LOAD_DASH_ALL_PROJECTS_SUCCESS: "LOAD_DASH_ALL_PROJECTS_SUCCESS",
  LOAD_DASH_ALL_PROJECTS_FAILURE: "LOAD_DASH_ALL_PROJECTS_FAILURE",

  LOAD_INPROGRESS_PROJECTS_REQUEST: "LOAD_INPROGRESS_PROJECTS_REQUEST",
  LOAD_INPROGRESS_PROJECTS_SUCCESS: "LOAD_INPROGRESS_PROJECTS_SUCCESS",
  LOAD_INPROGRESS_PROJECTS_FAILURE: "LOAD_INPROGRESS_PROJECTS_FAILURE",

  LOAD_OVERDUE_PROJECTS_REQUEST: "LOAD_OVERDUE_PROJECTS_REQUEST",
  LOAD_OVERDUE_PROJECTS_SUCCESS: "LOAD_OVERDUE_PROJECTS_SUCCESS",
  LOAD_OVERDUE_PROJECTS_FAILURE: "LOAD_OVERDUE_PROJECTS_FAILURE",

  LOAD_CLOSED_PROJECTS_REQUEST: "LOAD_CLOSED_PROJECTS_REQUEST",
  LOAD_CLOSED_PROJECTS_SUCCESS: "LOAD_CLOSED_PROJECTS_SUCCESS",
  LOAD_CLOSED_PROJECTS_FAILURE: "LOAD_CLOSED_PROJECTS_FAILURE",

  LOAD_PROJECT_REQUEST: "LOAD_PROJECT_REQUEST",
  LOAD_PROJECT_SUCCESS: "LOAD_PROJECT_SUCCESS",
  LOAD_PROJECT_FAILURE: "LOAD_PROJECT_FAILURE",

  LOAD_TASK_REQUEST: "LOAD_TASK_REQUEST",
  LOAD_TASK_SUCCESS: "LOAD_TASK_SUCCESS",
  LOAD_TASK_FAILURE: "LOAD_TASK_FAILURE",

  LOAD_TASKSLIST_REQUEST: "LOAD_TASKSLIST_REQUEST",
  LOAD_TASKSLIST_SUCCESS: "LOAD_TASKSLIST_SUCCESS",
  LOAD_TASKSLIST_FAILURE: "LOAD_TASKSLIST_FAILURE",

  LOAD_TASK_COMMENTS_REQUEST: "LOAD_TASK_COMMENTS_REQUEST",
  LOAD_TASK_COMMENTS_SUCCESS: "LOAD_TASK_COMMENTS_SUCCESS",
  LOAD_TASK_COMMENTS_FAILURE: "LOAD_TASK_COMMENTS_FAILURE",

  LOAD_ALL_TASKS_REQUEST: "LOAD_ALL_TASKS_REQUEST",
  LOAD_ALL_TASKS_SUCCESS: "LOAD_ALL_TASKS_SUCCESS",
  LOAD_ALL_TASKS_FAILURE: "LOAD_ALL_TASKS_FAILURE",

  LOAD_INPROGRESS_TASKS_REQUEST: "LOAD_INPROGRESS_TASKS_REQUEST",
  LOAD_INPROGRESS_TASKS_SUCCESS: "LOAD_INPROGRESS_TASKS_SUCCESS",
  LOAD_INPROGRESS_TASKS_FAILURE: "LOAD_INPROGRESS_TASKS_FAILURE",

  LOAD_OVERDUE_TASKS_REQUEST: "LOAD_OVERDUE_TASKS_REQUEST",
  LOAD_OVERDUE_TASKS_SUCCESS: "LOAD_OVERDUE_TASKS_SUCCESS",
  LOAD_OVERDUE_TASKS_FAILURE: "LOAD_OVERDUE_TASKS_FAILURE",

  LOAD_CLOSED_TASKS_REQUEST: "LOAD_CLOSED_TASKS_REQUEST",
  LOAD_CLOSED_TASKS_SUCCESS: "LOAD_CLOSED_TASKS_SUCCESS",
  LOAD_CLOSED_TASKS_FAILURE: "LOAD_CLOSED_TASKS_FAILURE",

  LOAD_REVIEW_TASKS_REQUEST: "LOAD_REVIEW_TASKS_REQUEST",
  LOAD_REVIEW_TASKS_SUCCESS: "LOAD_REVIEW_TASKS_SUCCESS",
  LOAD_REVIEW_TASKS_FAILURE: "LOAD_REVIEW_TASKS_FAILURE",

  LOAD_OPEN_TASKS_REQUEST: "LOAD_OPEN_TASKS_REQUEST",
  LOAD_OPEN_TASKS_SUCCESS: "LOAD_OPEN_TASKS_SUCCESS",
  LOAD_OPEN_TASKS_FAILURE: "LOAD_OPEN_TASKS_FAILURE",

  LOAD_SUB_TASKS_REQUEST: "LOAD_SUB_TASKS_REQUEST",
  LOAD_SUB_TASKS_SUCCESS: "LOAD_SUB_TASKS_SUCCESS",
  LOAD_SUB_TASKS_FAILURE: "LOAD_SUB_TASKS_FAILURE",

  LOAD_CLIENT_LAYERS_FOR_PROJECT_REQ: "LOAD_CLIENT_LAYERS_FOR_PROJECT_REQ",
  LOAD_CLIENT_LAYERS_FOR_PROJECT_SUCCESS:
    "LOAD_CLIENT_LAYERS_FOR_PROJECT_SUCCESS",
  LOAD_CLIENT_LAYERS_FOR_PROJECT_FAILURE:
    "LOAD_CLIENT_LAYERS_FOR_PROJECT_FAILURE",

  LOAD_PROJECT_META_INFO_REQ: "LOAD_PROJECT_META_INFO_REQ",
  LOAD_PROJECT_META_INFO_SUCCESS: "LOAD_PROJECT_META_INFO_SUCCESS",
  LOAD_PROJECT_META_INFO_FAILURE: "LOAD_PROJECT_META_INFO_FAILURE",

  SET_LISTENER_FOR_PROJECT: "SET_LISTENER_FOR_PROJECT",
  UNSUBSCRIBE_LISTENER_FOR_PROJECT: "UNSUBSCRIBE_LISTENER_FOR_PROJECT",

  PROJECT_LIST_COUNT_REQ: "PROJECT_LIST_COUNT_REQ",
  PROJECT_LIST_COUNT_SUCCESS: "PROJECT_LIST_COUNT_SUCCESS",
  PROJECT_LIST_COUNT_FAILURE: "PROJECT_LIST_COUNT_FAILURE",
};

export default ACTION
