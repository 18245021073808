import React, { useEffect, useState } from "react"
import Presentation from "./Presentation"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import { compose } from "redux"
import { connect } from "react-redux"
import validation from "../../../../../shared/validation"
import { useHistory } from "react-router-dom"

function Container(props) {
  const { modules } = props
  const tab = Number(validation.getQueryParameter("tab"))
  const [tabValue, setTabValue] = React.useState(tab)
  const history = useHistory();

  const handleTabChange = (event, newValue) => {
      const pathName = window.location.pathname
      history.push(`${pathName}?tab=${newValue}`)
      setTabValue(newValue);
  };

  const [employeesCount, setEmployeesCount] = useState({
    active: 0,
    suspended: 0,
    inactive: 0,
    all: 0,
  })

  useEffect(() => {
    if (isLoaded(props.employeesCount)) {
      if (props.employeesCount.hasOwnProperty("tracker")) {
        let temp = {
          active: 0,
          suspended: 0,
          inactive: 0,
          all: 0,
        }
        Object.entries(props.employeesCount.tracker).forEach(([key, value]) => {
          setEmployeesCount((state) => ({
            ...state,
            [key]: value,
          }))
        })
      }
    }
  }, [props.employeesCount])

  useEffect(() => {
    setTabValue(tab)
  }, [tab])

  return (
    <div>
      <Presentation
        {...props}
        modules={modules}
        tabValue={tabValue}
        employeesCount={employeesCount}
        handleTabChange={handleTabChange}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    modules: state.employee.employeeModules.accessModules,
    loggedInEmployee: state.firebase.auth.uid,
    employeesCount: state.firestore.data.employeesCount
  }
}


export default compose(connect(mapStateToProps), firestoreConnect((props) => {
  if ((props.modules.includes("employees-manager") || props.modules.includes("console-customization")))
    return [
      {
        collection: "ID_TRACKER",
        doc: "employees",
        storeAs: "employeesCount",
      },
    ]
  return []
}))(Container)