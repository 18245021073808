import React, { useEffect, useState, useRef } from 'react'
import MaterialTable from 'material-table'
import { useInvoiceListContext } from '../context'
import Cards from './Cards'

export default function InDetailTable(props) {
	const { range = 0 } = props
	const { state, services } = useInvoiceListContext()

	const tableRef = useRef(MaterialTable);
	const [filteredDataRows, setFilteredDataRows] = useState([]);

	const [isLoading, setIsLoading] = useState(true)
	const [rows, setRows] = useState([])

	useEffect(() => {
		setFilteredDataRows(tableRef.current.state.data)
	}, [(tableRef.current.state && tableRef.current.state.data)])

	useEffect(() => {
		services.getArAgingDataInDetail(range, setIsLoading, setRows)
	}, [range])

	useEffect(() => {}, [filteredDataRows])

	const handleSearchChange = (_) => {
		setFilteredDataRows(tableRef.current.state.data);
	}

	const columns = [
		{ title: 'Invoice Date', field: 'invoiceDate', type: 'date' },
		{ title: 'Invoice Num', field: 'invoiceId' },
		{ title: 'Client', field: 'clientName' },
		{ title: 'Due Date', field: 'dueDate', type: 'date' },
		{ title: 'Due Days', field: 'dueDays' },
		{ title: 'Invoiced amount', field: 'amount', type: 'currency' },
		{ title: 'Open Balance', field: 'openBalance', type: 'currency' },
	]

	return (
		<div>
			<Cards invoices={filteredDataRows} />
			<MaterialTable
				title=''
				columns={columns}
				data={rows}
				tableRef={tableRef}
				isLoading={isLoading}
				onSearchChange={handleSearchChange}
				onFilterChange={handleSearchChange}
				options={{
					pageSize: 10,
					filtering: true,
					paginationPosition: 'both',
					paginationType: 'normal',
					exportButton: true,
					pageSizeOptions: [10, 20, 50, 100, 250],
					emptyRowsWhenPaging: false,
					exportFileName: 'A/R Aging In Detail View'
				}}
			/>
		</div>
	)
}