import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Button,
  FormControlLabel,
  FormGroup,
  Tooltip,
  Chip,
  Typography
} from "@material-ui/core";
import {
  AddCircleOutline as AddCircleOutlineIcon,
} from "@material-ui/icons";
import IOSSwitch from "../../../../../../shared/iosSwitch";
import CountryField from "../../../../../../shared/components/Geo/CountryField";
import StateField from "../../../../../../shared/components/Geo/StateField";
import {
  NumberFormatCustom,
  MobileNumberFormatter,
} from "../../../../../../shared/customNumberFormats";
import validate from "../../../../../../shared/validation";
import EditContact from "../../../../../Clients/components/ViewClient/clientComponents/Contacts/EditContact";


import { Autocomplete } from "@material-ui/lab";
import { getEndClientContacts } from "../../../../middleware";

function Presentation(props) {
  const {
    amendmentRequired,
    type,
    email,
    phonenumber,
    phoneExtension,
    line1,
    line2,
    city,
    state,
    country,
    zip,
    handleChecked,
    handleChange,
    handleKeyValuePair,
    handleSubmit,
    fillableSections,
    managers,
    endClientId,
    clientList,
  } = props;
  const types = ["Onsite", "Remote"];
  const [clientContacts,setClientContacts] = useState([])
  const [requiredMangers, setManagers] = useState([]);
const [reportingMangers,setReportingManagers] = useState([])
  useEffect(() => {
    const requiredMangers = clientContacts ? clientContacts.filter((contact) =>
      managers.includes(contact.email)
    ) : [];
    setManagers(requiredMangers);
  }, [managers, clientContacts]);


  const fetchEndClientContacts = async () => {
    try {
      const data = await getEndClientContacts(endClientId)
      setClientContacts(data)
      let Managers  = data
      .filter((contact)  => (
        contact.contactType === "Reporting Manager"
      )) 
      setReportingManagers(Managers)
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if(endClientId!== ''){
    fetchEndClientContacts()
    }
  }, [endClientId])
console.log("clientdetails_data",endClientId)
  return (
    <div>
      <div className="text-right mb-4">
      <Tooltip title="Add contact">
              <EditContact
                btnContent={<AddCircleOutlineIcon />}
                clientId={endClientId}
                edit={false}
                clientList={clientList}
                isWorkLocation={true}
                contact={{}}
                isPlacement={true}
              />
            </Tooltip>
      </div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              select
              label="Work Location Type"
              size="small"
              value={type}
              fullWidth
              required
              name="type"
              id="placements-worklocation-type"
              onChange={handleChange}
            >
              {types.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              label="Work Email"
              size="small"
              fullWidth
              helperText={
                email.length
                  ? validate.checkEmail(email)
                    ? ""
                    : "Enter valid work email"
                  : ""
              }
              value={email}
              name="email"
              id="placements-worklocation-email"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <TextField
                  variant="outlined"
                  label="Work Phone"
                  size="small"
                  fullWidth
                  helperText={
                    phonenumber.length
                      ? validate.checkNumber(phonenumber)
                        ? ""
                        : "Enter valid work phone number"
                      : ""
                  }
                  value={phonenumber}
                  id="placements-worklocation-phonenumber"
                  name="phonenumber"
                  onChange={handleChange}
                  InputProps={{
                    inputComponent: MobileNumberFormatter,
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  variant="outlined"
                  label="Ext."
                  size="small"
                  fullWidth
                  value={phoneExtension}
                  name="phoneExtension"
                  id="placements-worklocation-phoneExtension"
                  onChange={handleChange}
                  type="text"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              label="Address Line 1"
              size="small"
              fullWidth
              required
              value={line1}
              name="line1"
              id="placements-worklocation-line1"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              label="Address Line 2"
              size="small"
              fullWidth
              // required
              value={line2}
              name="line2"
              id="placements-worklocation-line2"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              label="City"
              size="small"
              fullWidth
              required
              value={city}
              name="city"
              id="placements-worklocation-city"
              onChange={handleChange}
            />
          </Grid>
          {console.log("country176",country)}
          <Grid item xs={4}>
            <CountryField
              handleChange={(e) =>
                handleKeyValuePair("country", e.target.value)
              }
              key={country}
              label="Country"
              required={true}
               value={country}
            />
          </Grid>
          <Grid item xs={4}>
            <StateField
              handleChange={(e) => handleKeyValuePair("state", e.target.value)}
              label="State"
              required={true}
              key={state}
              value={state}
              country={country}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              label="Zip Code"
              size="small"
              fullWidth
              required
              value={zip}
              helperText={
                zip.length
                  ? validate.checkZip(zip)
                    ? ""
                    : "Enter valid zip code"
                  : ""
              }
              name="zip"
              id="placements-worklocation-zip"
              onChange={handleChange}
            />
          </Grid>
          {/* 
            This requirment is negotiated and will be discussed again.
            Mismatch - End client
          */}
          <Grid item xs={12}>
            <div style={{ minWidth: "100%" }}>
              <Autocomplete
                multiple
                filterSelectedOptions
                id="placements-worklocation-managers"
                options={reportingMangers}
                value={requiredMangers}
                getOptionLabel={(option) => option.representativeName +" - "+ option.email+" - "+option.workPhone}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={
                        <div>
                        <Typography style={{ whiteSpace: "normal" }}>
                          {option.representativeName}
                        </Typography>
                        <Typography style={{ whiteSpace: "normal" }}>
                        { option.email}
                      </Typography>
                      <Typography style={{ whiteSpace: "normal" }}>
                        {option.workPhone}
                      </Typography>
                      </div>
                      }
                      {...getTagProps({ index })}
                      style={{ height: "100%" }}
                    />
                  ))
                }
                onChange={(e, v) => {
                  if (v) {
                    handleKeyValuePair(
                      "managers",
                      v.map((item) => item.email)
                    )
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Work Location Reporting Managers" variant="standard" />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<IOSSwitch />}
                name="amendmentRequired"
                checked={amendmentRequired}
                onChange={handleChecked}
                label="Amendment Required"
                id="placements-worklocation-amendmentRequired"
              />
            </FormGroup>
          </Grid>
          
        </Grid>
        <br />
        <Button
          type="submit"
          variant="contained"
          color={
            fillableSections.includes("worklocation") ? "primary" : "secondary"
          }
          disabled={
            !type ||
            !validate.checkAddress(line1) ||
            !city.trim() ||
            !validate.checkZip(zip) ||
            !state ||
            !country ||
            !(phonenumber.length === 0 || validate.checkNumber(phonenumber)) ||
            !(email.length === 0 || validate.checkEmail(email))
          }
        >
          {fillableSections.includes("worklocation") ? "Done" : "Update"}
        </Button>
      </form>
    </div>
  );
}

export default Presentation;
