import { combineReducers } from "redux"
import { clientLocationsReducer } from "./clientLocationsReducer"
import { placementListReducer } from "./placementListReducer"
import sectionsDataReducer from "./sectionsDataReducer";

const rootReducer = combineReducers({
  placements: placementListReducer,
  clientlocations: clientLocationsReducer,
  sectionsData: sectionsDataReducer,
});

export default rootReducer
