import React from 'react'
import TabsView from "../TabsView"

function Presentation(props) {
  return (
    <div>
      <TabsView {...props} />
    </div>
  )
}

export default Presentation
