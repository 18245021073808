import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import { compose } from "redux"
import CircularSpinner from "../../../../../shared/circularSpinner"
import Presentation from "./Presentation"

function Container(props) {
  const defaultProps = {
    branch: "",
    dob: "",
    firstname: "",
    lastname: "",
    phonenumber: ""
  }
  const { formData, registeringEmail, empInfo = defaultProps,settingFormState,setErr = () => {},errState = {} } = props
  const [state, setState] = useState({})
  let personalError = 0
  let emergencyError = 0

  useEffect(() => {
    if (isLoaded(formData)) {
      let tempState = {}
      formData.forEach((item) => {
        tempState = {
          ...tempState,
          [item.section]: {},
        }
      })

      formData && formData.forEach((item) => {
        let portion = { ...tempState[item.section] }
        item.fields.forEach((field) => {

          if (field.isExist) {
            if (
              item.section === "personalinformation" &&
              field.name === "emailid"
            ) {
              portion[field.name] = registeringEmail
            } else if (
              (item.section === "personalinformation") &&
              (empInfo?.personal ? Object.keys(empInfo?.personal).includes(field?.name) : false)
            ) {
                if ((field?.name === "firstname" || field?.name === "lastname" || field?.name === "phonenumber" || field?.name === "emailid" || field?.name === "dob" || field?.name === "gender" || field?.name === "maritalstatus") && empInfo?.personal[field.name] === "") {
                  personalError++
                }
                portion[field.name] = empInfo?.personal[field.name]
            } else if (
              item.section === "mailingaddress" &&
              (empInfo?.mailingaddress ? Object.keys(empInfo?.mailingaddress).includes(field?.name) : false)
            ){
              portion[field.name] = empInfo?.mailingaddress[field.name]
            } else if (
              item.section === "emergencycontact" &&
              Object.keys(empInfo?.emergencycontact?.length > 0 ? empInfo?.emergencycontact[0] : false).includes(field.name)
            ){
              if ((field?.name === "name" || field?.name === "phone") && empInfo?.employmenthistory[0] === "") {
                emergencyError++
              }
              portion[field.name] = empInfo?.emergencycontact[0][field.name]
            }  else if (
              item.section === "employementhistory" &&
              Object.keys(empInfo?.employmenthistory?.length > 0 ? empInfo?.employmenthistory[0] : false).includes(field.name)
            ){
              portion[field.name] = empInfo?.employmenthistory[0][field.name]
            } else if (
              item.section === "workauthorization" &&
              Object.keys(empInfo?.workauth?.length > 0 ? empInfo?.workauth[0] : false).includes(field.name)
            ){
              portion[field.name] = empInfo?.workauth[0][field.name]
            } else {
              portion[field.name] = ""
            }
          }
        })

        tempState = {
          ...tempState,
          [item.section]: portion,
        }
      })
      tempState = {
        ...tempState,
        formData,
      }

      setErr({
        ...errState,
        personalOk: personalError === 0 ? true : false,
        emergencyOk: (emergencyError === 0 && empInfo?.emergencycontact?.length > 0) ? true : false,
      });

      setState((prevState) => ({
        ...prevState,
        ...tempState,
      }))
      
      settingFormState(tempState)
    }
  }, [formData, registeringEmail])

  const handleChange = (key, value, sectionName) => {
    // const key = event.target.name
    // const value = event.target.value
    let change = {
      ...state[sectionName],
      [key]: value,
    }
    setState({
      ...state,
      [sectionName]: change,
    })
    props.handleStateSet(sectionName, change)
  }

  const handleDateChange = (key, value, sectionName) => {
    let change = {}
    console.log(key, value, sectionName)
    if (!isNaN(Date.parse(value))) {
      change = {
        ...state[sectionName],
        [key]: value,
      }
      setState({
        ...state,
        [sectionName]: {
          ...state[sectionName],
          [key]: value,
        },
      })
    }
    props.handleStateSet(sectionName, change)
  }
  if (
    state.hasOwnProperty("formData") &&
    Object.keys(state).length &&
    state.formData.length
  ) {
    return (
      <div>
        <Presentation
          {...state}
          handleChange={handleChange}
          handleDateChange={handleDateChange}
        />
      </div>
    )
  }

  return (
    <CircularSpinner />
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    formData: state.firestore.ordered.formData,
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "INVITATION_FORM_BUILDER",
        orderBy: ["index", "asc"],
        storeAs: "formData",
      },
    ]
  })
)(Container)
