import { useState } from "react";
import { read, utils } from "xlsx";
import * as yup from "yup";
import { AlertService } from "../shared/alerts";

export default function useExcelReader({ validationSchema }) {
	const [rows, setRows] = useState([]);
	const [errors, setErrors] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const alertService = new AlertService();

	const handleFileChange = (e) => {
		if (!e.target.files) return;
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.onload = (e) => {
			const data = e.target?.result;
			const workbook = read(data, { type: "binary" });
			const sheetName = workbook.SheetNames[0];
			console.log({ sheetName });
			const sheet = workbook.Sheets[sheetName];

			const rows = utils.sheet_to_json(sheet, {
				blankrows: false,
				defval: null,
				dateNF: "yyyy-mm-dd",
			});

			setTotalRows(rows.length);
			validateData(rows);
		};
		reader.readAsBinaryString(file);
	};

	const validateData = async (rows) => {
		try {
			const promises = rows.map(async (row, index) => {
				try {
					await validationSchema.validate(row, { abortEarly: false });
					const castedRow = validationSchema.cast(row);
					return castedRow;
				} catch (validationErrors) {
					if (validationErrors instanceof yup.ValidationError) {
						setErrors((prev) => [
							...prev,
							{ id: index + 1, error: validationErrors },
						]);
					}
					return null;
				}
			});
			const results = await Promise.all(promises);
			const validRows = results.filter((result) => result !== null);
			setRows(
				validRows.map((row, index) => ({
					...row,
					id: index + 1,
					status: "pending",
				}))
			);
			if (errors.length > 0) {
				alertService.errorMessage(
					"Error",
					"File contains errors, please fix them and try again."
				);
			}
		} catch (error) {
			console.log("[useExcelReader] validateData error: ", error);
		}
	};

	const reset = () => {
		setRows([]);
		setErrors([]);
		setTotalRows(0);
	};

	return { rows, errors, handleFileChange, reset, totalRows, setRows };
}
