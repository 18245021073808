import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Presentation from "./Presentation";
import {
  addSectionToPlacement,
  getPlacementsTimesheetsData,
  updatePlacement,
  getEmployeePlacements,
} from "../../../../../middleware";
import { placementTimesheetsDataSuccess } from "../../../../../actions/actionCreators";
import { CalendarFormatter } from "../../../../../../../shared/CalendarFormatter";
import CircularSpinner from "../../../../../../../shared/circularSpinner";

function Container(props) {
  const dispatch = useDispatch();
  const { timesheets, placement, profile } = props;
  const initState = {
    timesheetCycle: "",
    startDay: "",
    approvalBy: "",
    attachMandatory: true,
    enableTask: false,
    makeMandatory: false,
    linkToProject: "",
    approvals: [],
    projectsList: [],
    cycle: [],
    implementationDate: placement.startDate,
    fillableSections: placement.fillableSections,
    id: placement.id,
    employeeID: profile.uid,
    data: [],
    index: null,
    update: false,
  };
  const [state, setState] = useState(initState);
  const timesheetsCycles = timesheets.data.cycle;
  useEffect(() => {
    dispatch(getPlacementsTimesheetsData(placement.id, profile.companyID));
    dispatch(getEmployeePlacements(profile.companyID));
    return dispatch(
      placementTimesheetsDataSuccess({
        data: {},
      })
    );
  }, [placement.id, profile.companyID]);

  useEffect(() => {
    const { data, isLoading, approvals, projectsList } = timesheets;
    if (!isLoading) {
      if (Object.keys(data).length > 0) {
        const reqCycle = data.cycle[data.cycle.length - 1];
        const { range, startDay, date } = reqCycle;
        setState((prevState) => ({
          ...prevState,
          cycle: data.cycle,
          approvalBy: data.approvalBy[0],
          attachMandatory: data.attachMandatory,
          enableTask: data.enableTask,
          makeMandatory: data.makeMandatory,
          linkToProject: data.linkToProject,
          timesheetCycle: range.toString(),
          startDay,
          implementationDate: date,
          projectsList,
          approvals,
        }));
      } else {
        setState((prevState) => ({
          ...initState,
          projectsList,
          approvals,
        }));
      }
    }
  }, [JSON.stringify(timesheets), placement, profile]);

  const handleSubmit = (e) => {
    //props.handleChange("panel4")
    e.preventDefault();
    const { closeTab } = props;
    const payload = {};
    Object.entries(state).forEach(([key, value]) => {
      if (
        key !== "fillableSections" &&
        key !== "id" &&
        key !== "employeeID" &&
        key !== "approvals" &&
        key !== "timesheetCycle" &&
        key !== "cycle" &&
        key !== "startDay" &&
        key !== "implementationDate" &&
        key !== "projectsList" &&
        key !== "approvalBy"
      ) {
        payload[key] = value;
      }
    });
    payload["approvalBy"] = [state.approvalBy];
    payload["cycle"] = [
      ...state.cycle,
      {
        range: parseInt(state.timesheetCycle),
        startDay:
          parseInt(state.timesheetCycle) !== 3 &&
          parseInt(state.timesheetCycle) !== 4
            ? state.startDay
            : CalendarFormatter.getDayFromDate(state.implementationDate),
        date: CalendarFormatter.standardDateFormat(state.implementationDate),
      },
    ];
    //payload["companyIDs"] = [state.approvalBy]
    if (state.fillableSections.includes("timesheets")) {
      dispatch(
        addSectionToPlacement(
          payload,
          "timesheets",
          state.employeeID,
          state.id,
          callback,
          closeTab
        )
      );
    } else {
      delete payload["cycle"];
      dispatch(
        updatePlacement(
          payload,
          "timesheets",
          state.employeeID,
          state.id,
          callback,
          closeTab
        )
      );
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdate = (e, id) => {
    e.preventDefault();
    console.log("iddfgdafudkajfkdzfkdzj", id);
    setState((prevState) => ({
      ...prevState,
      ...prevState.data[id],
      update: true,
      index: id,
    }));
  };

  const callback = () => {};

  const handleChecked = (event) => {
    const { name, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleKeyValuePair = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const handleDateChange = (key, value) => {
    if (!isNaN(Date.parse(value))) {
      const date = new Date(value);
      date.setHours(0, 0, 0, 0);
      setState({
        ...state,
        [key]: date.toISOString(),
      });
    }
  };

  if (!timesheets.isLoading) {
    return (
      <Presentation
        {...state}
        key={
          "timesheets created " +
          placement?.fillableSections.includes("timesheets")
        }
        isCreation={placement?.fillableSections.includes("timesheets")}
        handleUpdate={handleUpdate}
        history={timesheetsCycles}
        handleChange={handleChange}
        handleDateChange={handleDateChange}
        handleChecked={handleChecked}
        handleKeyValuePair={handleKeyValuePair}
        handleSubmit={handleSubmit}
      />
    );
  }

  return (
    <CircularSpinner />
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    timesheets: state.placement.sectionsData.timesheets,
  };
};

export default connect(mapStateToProps)(Container);
