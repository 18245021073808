import React from 'react'
import { Autocomplete } from "@material-ui/lab";
import {
  TextField,
  Grid,
  MenuItem,
  Paper,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useNewInvoiceContext } from './context';


function SelectionCriteria() {
  const { state, stateSetters, services } = useNewInvoiceContext()
  const completelyExternal = state.invoiceBy === "external" && state.toClient
  const externalLinkedWithEmployee = state.invoiceBy === "external" && !state.toClient
  return (
    <>
      <Paper className="p-2">
        <Grid container spacing={3}>
          <Grid container spacing={3} item xs={9}>
            <Grid item xs={12} sm={3}>
              <TextField
                select
                label="Create Invoice By"
                style={{ minWidth: "100%" }}
                onChange={(e) => {
                  stateSetters.setInvoiceBy(e.target.value)
                  services.clearState(["clientID", "placementID", "employeeID", "netTerms", "poNumber","clientDetails",'invoiceLocation'])
                }}
                value={state.invoiceBy}
                disabled={state.isInvoiceEdit}
                name="invoiceBy"
                id="invoices-create-invoice-invoiceBy"
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="client">By Client</MenuItem>
                <MenuItem value="external">By External</MenuItem>
                <MenuItem value="employee">By Employee</MenuItem>
              </TextField>
            </Grid>
            {
              state.invoiceBy === "external" ?
                <Grid item xs={12} sm={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.toClient}
                        defaultChecked
                        name="toClient"
                        onChange={(e) => {
                          stateSetters.setToClient(e.target.checked)
                          services.clearState(["clientDetails"])
                        }}
                        disabled={state.isInvoiceEdit}
                      />
                    }
                    label="To Client"
                  />
                </Grid>
                :
                null
            }

            <>
              {
                state.invoiceBy === "client" || completelyExternal ?
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      options={state.clients}
                      value={state.isInvoiceEdit ? (state.clients.filter(c => c.id === state.clientID)?.[0] || null) : state.clients[state.clientID]}
                      getOptionLabel={(option) => option.businessDisplayName}
                      disabled={state.isInvoiceEdit}
                      style={{ minWidth: "100%" }}
                      onChange={(e, v) => {
                        if (v) {
                          stateSetters.setClientId(v.clientId)
                          console.log("internal",v.isInternal)
                          stateSetters.setIsInternal(v.isInternal)
                          stateSetters.setNetTerms(v?.netTerms ? parseInt(v?.netTerms) : "")
                          services.clearState(["placementID", "employeeID", "poNumber"])
                        }
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} label="Select Client" />
                      )}
                    />
                  </Grid>
                  :
                  null
              }
            </>
            <>
              {
                state.invoiceBy === "employee" || externalLinkedWithEmployee ?
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      options={state.employees}
                      key={state.invoiceBy}
                      value={state.isInvoiceEdit ? (state.employees.filter(c => c.uid === state.employeeID)?.[0] || null) : state.employees[state.employeeID]}
                      getOptionLabel={(option) => services.getNameOfTheEmployeeById(option.uid)}
                      disabled={state.isInvoiceEdit}
                      style={{ minWidth: "100%" }}
                      onChange={(e, v) => {
                        if (v) {
                          stateSetters.setEmployeeID(v.uid)
                          console.log("category",v.personal.category)
                          stateSetters.setEmployeeCategory(v.personal.category)
                          services.clearState(["placementID", "clientID", "netTerms", "poNumber","clientDetails",'invoiceLocation'])
                        }
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} label="Select Employee" />
                      )}
                    />
                  </Grid>
                  :
                  null
              }
            </>
            <>
              {
                state.invoiceBy === "employee" || state.invoiceBy === "client" || externalLinkedWithEmployee ?
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      key={state.invoiceBy === "employee" || externalLinkedWithEmployee ? state.employeeID : state.clientID}
                      options={state.placements}
                      value={state.isInvoiceEdit ? (state.placements.filter(c => c.id === state.placementID)?.[0] || null) : state.placements[state.placementID]}
                      getOptionLabel={(option) => services.getNameOfTheEmployeeById(option.employeeID) + ' - ' + option?.placementID}
                      style={{ minWidth: "100%" }}
                      disabled={state.isInvoiceEdit}
                      onChange={(e, v) => {
                        if (v) {
                          stateSetters.setPlacementID(v.placementID)
                          stateSetters.setEmployeeID(v.employeeID)
                          stateSetters.setClientId(v.clientId)
                          console.log("paidd",v.paidWhenPaid)
                          stateSetters.setPaidWhenPaid(v.paidWhenPaid)
                          stateSetters.setNetTerms(v?.netTerms ? parseInt(v?.netTerms) : "")
                          stateSetters.setPoNumber(v?.metaInfo?.poNumber || '')
                          services.clearState([])
                        }
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} label="Select Placement" />
                      )}
                    />
                  </Grid>
                  :
                  null
              }
            </>
            {
              state.invoiceBy === "external" ?
                <>
                  <Grid item xs={6}>
                    <TextField
                      required
                      fullWidth
                      size="small"
                      value={state.clientDetails?.name}
                      label="Name"
                      variant="outlined"
                      focused
                    />
                  </Grid>
                  <Grid item xs={4} sm={6}>
                    <TextField
                      rows={5}
                      fullWidth
                      required
                      multiline
                      value={state.clientDetails?.address}
                      size="small"
                      label="Address"
                      variant="outlined"
                    />
                  </Grid>
                </>
                :
                null
            }

          </Grid>

          <Grid item xs={3}>
            <h2 className="text-right" style={{ fontWeight: 500 }}>
              ${state.grandTotal}
            </h2>
          </Grid>

        </Grid>
      </Paper>
    </>
  )
}

export default SelectionCriteria