import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Popover, CircularProgress } from "@material-ui/core";

import { BiComment } from "react-icons/bi";
const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
    width: "250px",
  },
  large: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  row: {
    paddingTop: "5px",
  },
}));

function CommentHoverCard({ value, comment }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
      <div>
        {
          value === '0' 
          ? 
          <CircularProgress variant="determinate"  thickness={2} size={20} className="text-success"   value={Math.round(100/4 * 4)}/>
          : 
          <CircularProgress variant="determinate"  thickness={22} size={20} className="text-success" style={{opacity :  value * 0.2 + 0.2}}  value={Math.round(100/4 * value)}/>
        }
        </div>
      </Typography>
      {
        comment && value && comment.length > 0 && (
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className={classes.row}>
          <span >
            <BiComment className="text-success" size={20}/>
            &nbsp;&nbsp;&nbsp;{comment}
          </span>
        </div>
      </Popover>
        )
      }
      
    </div>
  );
}

export default CommentHoverCard;
