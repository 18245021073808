import React from "react"
import ConsoleCustomisation from "../Services/Console/components/Index"

function ConsolePage(props) {
  return (
    <div>
      <ConsoleCustomisation />
    </div>
  )
}

export default ConsolePage
