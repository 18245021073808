import React from "react";
import FlairTable, {
	convertMaterialToAgGridColumns,
} from "../../../shared/FlairTable";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
	freezePayroll,
	getGeneralReport,
	rollbackPayroll,
} from "../../../api/services/default/payrolls/reports";
import { getDaysInMonth, getMonth, getYear, isWithinInterval } from "date-fns";
import validate from "../../../shared/validation";
import MetaInfo from "../../../shared/getMetaInfo";
import { CalendarFormatter } from "../../../shared/CalendarFormatter";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import DatePickerField from "../../../shared/components/BootStrapFormFields/DatePickerField";
import { TimesheetHover } from "./TimesheetHover";
import GlobalSettings from "./GlobalSettings";
import { Link } from "react-router-dom";
import { AlertService } from "../../../shared/alerts";
import GeneralReportActions from "./Actions/General/Actions";
import SelectDeductions from "./SelectDeductions";
import PayrollCheckerModal from "./Actions/PayrollChecker/PayrollCheckerModal";
import AddBonusPay from "./AddBonusPay";
import "./styles.css";
import SelectFormField from "../../../shared/components/BootStrapFormFields/SelectFormField";

export default function GeneralReport() {
	const [reportingDate, setReportingDate] = React.useState();
	const [employeeIds, setEmployeeIds] = React.useState([]);
	const [dates, setDates] = React.useState([]);
	const tableRef = React.useRef();
	const metaInfo = new MetaInfo();

	const discountMapper = (discountDetails) => {
		if (Object.keys(discountDetails).length > 0) {
			const { type, value } = discountDetails;
			if (type === "byPercentage") {
				return `${value}%`;
			}
			return validate.currencyFormatterUs(value);
		}
		return "--";
	};

	const {
		isFetching: isLoading,
		refetch,
		data,
	} = useQuery({
		queryKey: ["getGeneralReport", reportingDate, employeeIds],
		queryFn: () =>
			getGeneralReport(reportingDate?.getMonth(), reportingDate?.getFullYear(), employeeIds),
		enabled: false,
	});

	const rows = React.useMemo(() => {
		return data?.map((item) => {
			const timesheetsDates = dates
				.map((date, index) => {
					const isDateInPlacementRange =
						item.startDate && item.endDate
							? isWithinInterval(new Date(date), {
									start: new Date(item.startDate),
									end: new Date(item.endDate),
								})
							: false;
					return {
						[`DateOfMonth${index}`]:
							item.timesheetsDataset[date]?.totalHours ??
							(isDateInPlacementRange ? "0" : "--"),
					};
				})
				.reduce(function (acc, x) {
					for (const key in x) acc[key] = x[key];
					return acc;
				}, {});

			return {
				employeeName: item.employeeName,
				employeeID: item.employeeID,
				payrollId: item.payrollId,
				eVerifyDate: item.eVerifyDate,
				i9Date: item.i9Date,
				employeeStatus: item.employeeStatus
					.map((i) => i?.status ?? "")
					.join(", "),
				employeeStatusData: item.employeeStatus,
				dateOfJoining: item.dateOfJoining,
				dateOfSuspension: item.dateOfSuspension,
				companyName: item.companyName,
				clientName: item.clientName,
				branch: item.branch,
				placementID: item.placementID,
				workAuthorizationType: item.workAuthorizationType
					.map(
						(i) => `${i?.work_type} - ${i?.valid_from} - ${i?.expiry_date}`
					)
					.join(", "),
				workAuthData: item.workAuthorizationType,
				employmentGap: (() => {
					const workTypes =
						item.workAuthorizationType.map((i) => i?.work_type ?? "") ?? [];
					if (workTypes.includes("No Auth")) {
						return "Yes";
					}
					if (workTypes.length > 0) {
						return "No";
					}
					return "";
				})(),
				latestWorkAuth: (() => {
					const workTypes =
						item.workAuthorizationType.map((i) => i?.work_type ?? "") ?? [];
					if (workTypes.length > 0) {
						return workTypes[workTypes.length - 1];
					}
					return "";
				})(),
				startDate: item.startDate,
				endDate: item.endDate,
				employeeCategory: item.employeeCategory,
				billingRate: item.billingRate
					.map(validate.currencyFormatterUs)
					.join(", "),
				payRate: item.payRate.map(validate.currencyFormatterUs).join(", "),
				payPercentage: item.payPercentage.map((i) => `${i}%`).join(", "),
				effectiveUntil: item.effectiveUntil.join(", "),
				invoiceFrequency: item.invoiceFrequency,
				hrs: (() => {
					if (item.totalMissingDays > 0) {
						return `Partial`;
					}
					return "Full";
				})(),
				missingDays: item.totalMissingDays,
				submittedHours: item.submittedHours,
				approvedHours: item.approvedHours,
				totalHours: item.totalEntireHours,
				totalEarnedPay: item.totalEarnedPay
					? validate.currencyFormatterUs(item.totalEarnedPay)
					: "--",
				totalPay: item.totalPay
					? validate.currencyFormatterUs(item.totalPay)
					: "--",
				invoicedPay: item.totalInvoicedPay
					? validate.currencyFormatterUs(item.totalInvoicedPay)
					: "--",
				bonus: item.bonus ? validate.currencyFormatterUs(item.bonus) : "--",
				timesheetsDataset: item.timesheetsDataset,
				lastSubmission: item.submissionData[0]?.submissionDate ?? "",
				lastApproval: item.submissionData[0]?.approvalDate ?? "",
				submissionData: item.submissionData ?? [],
				discount: item.discountDetails.map(discountMapper).join(", "),
				isFreezed: item.isFreezed ?? false,
				canRollBackIfFreezed: item.canRollBackIfFreezed ?? false,
				soaId: item.soaId,
				isSoaPaid: item.isSoaPaid,
				insurance: item.insurance
					? validate.currencyFormatterUs(item.insurance)
					: "--",
				defaultPlacementId: item.defaultPlacementId,
				...timesheetsDates,
				status: item.isFreezed ? "Freezed" : "Not Freezed",
				reportingDate: reportingDate,
				totalDeductions: item.totalDeductions
					? validate.currencyFormatterUs(item.totalDeductions)
					: "--",
				deductionsData: item.deductionsData ?? [],
				bonusPay: item.bonusPay
					? validate.currencyFormatterUs(item.bonusPay)
					: "--",
				bonusPayData: item.bonusPayData?.length ? item.bonusPayData[0] : {},
				isNonWorking: item.isNonWorking ?? false,
			};
		});
	}, [data, dates, reportingDate]);

	const useFreezePayroll = useMutation({
		mutationFn: ({ data }) => freezePayroll(data),
		onSuccess() {
			refetch();
		},
	});

	const useRollbackPayroll = useMutation({
		mutationFn: ({ data }) => rollbackPayroll(data),
		onSuccess() {
			refetch();
		},
	});

	const excelStyles = React.useMemo(() => {
		return [
			{
				id: "freezed-row",
				interior: {
					color: "#c7bebe",
					pattern: "Solid",
				},
			},
			{
				id: "non-working-row",
				interior: {
					color: "#f1f184",
					pattern: "Solid",
				},
			},
			{
				id: "no-default-placement-row",
				interior: {
					color: "#f5b88f",
					pattern: "Solid",
				},
			},
			{
				id: "approved",
				interior: {
					color: "#006400",
					pattern: "Solid",
				},
			},
			{
				id: "rejected",
				interior: {
					color: "#ff0000",
					pattern: "Solid",
				},
			},
			{
				id: "pending",
				interior: {
					color: "#ffa500",
					pattern: "Solid",
				},
			},
			{
				id: "no-placement",
				interior: {
					color: "#dbdbd9",
					pattern: "Solid",
				},
			}
		]
	}, []);

	const datesCellClassRules = React.useMemo(() => {
		const getTimesheetsStatus = (params) => {
			const row = params.data;
			const date = params.colDef.headerName;
			const isDateInPlacementRange =
				row.startDate && row.endDate
					? isWithinInterval(new Date(date), {
							start: new Date(row.startDate),
							end: new Date(row.endDate),
						})
					: false;
			return { isDateInPlacementRange, status: row.timesheetsDataset[date]?.status };
		};

		return {
			"approved": (params) => {
				const { isDateInPlacementRange, status } = getTimesheetsStatus(params);
				console.log(isDateInPlacementRange, status);
				return isDateInPlacementRange && status === "approved";
			},
			"rejected": (params) => {
				const { isDateInPlacementRange, status } = getTimesheetsStatus(params);
				return isDateInPlacementRange && status === "rejected";
			},
			"pending": (params) => {
				const { isDateInPlacementRange, status } = getTimesheetsStatus(params);
				return isDateInPlacementRange && status === "pending";
			},
			"no-placement": (params) => {
				const { isDateInPlacementRange } = getTimesheetsStatus(params);
				return !isDateInPlacementRange
			},
		}
	}, []);

	const defaultCellClassRules = React.useMemo(() => {
		return {
			"freezed-row": (params) => params.data?.isFreezed,
			"non-working-row": (params) =>
				params.data?.isNonWorking && !params.data?.isFreezed,
			"no-default-placement-row": (params) => (!params.data?.defaultPlacementId || !params.data?.placementID) && !params.data?.isNonWorking,
		}
	}, []);

	const columns = [
		{
			title: "Actions",
			field: "status",
			editable: "never",
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			checkboxSelection: true,
			showDisabledCheckboxes: true,
			render: (row) => (
				<GeneralReportActions row={row} reportingDate={reportingDate} />
			),
		},
		{
			title: "Employee ID",
			field: "employeeID",
			render: (row) => (
				<Link to={`/console/employees/${row.employeeID}`}>
					{row.employeeID}
				</Link>
			),
		},
		{ title: "Payroll ID", field: "payrollId" },
		{ title: "Employee Name", field: "employeeName" },
		{
			title: "Employee Status",
			field: "employeeStatus",
			render: (row) => {
				const { employeeStatusData } = row;
				return employeeStatusData?.map((item) => {
					return (
						<div key={item?.id}>
							{item.status} - {item.activated_date}
						</div>
					);
				});
			},
		},
		{
			title: "E-Verify Date",
			field: "eVerifyDate",
			render: (row) => {
				const eVerifyData = row.eVerifyDate?.split(",");
				return eVerifyData?.map((item) => {
					return <div key={item}>{item}</div>;
				});
			},
		},
		{
			title: "I-9 Date",
			field: "i9Date",
			render: (row) => {
				const i9Data = row.i9Date?.split(",");
				return i9Data?.map((item) => {
					return <div key={item}>{item}</div>;
				});
			},
		},
		{ title: "Date of joining", field: "dateOfJoining" },
		{ title: "Company", field: "companyName" },
		{ title: "Client", field: "clientName" },
		{ title: "Branch", field: "branch" },
		{
			title: "Job Code",
			field: "placementID",
			render: (row) => (
				<Link to={`/console/placements/${row.employeeID}/${row.placementID}`}>
					{row.placementID}
				</Link>
			),
		},
		{
			title: "Work Authorization",
			field: "workAuthorizationType",
			render: (row) => {
				const { workAuthData } = row;

				return (
					<table className="table table-bordered table-hover table-sm">
						<tbody>
							{workAuthData?.map((item) => {
								return (
									<tr className="p-0" key={item?.id}>
										<td width="32%" className="border-0">
											{item.work_type}
										</td>
										<td className="border-0">{item.valid_from}</td>
										<td className="border-0">{item.expiry_date}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				);
			},
		},
		{ title: "Employment Gap", field: "employmentGap" },
		{ title: "Latest Work auth", field: "latestWorkAuth" },
		{ title: "Job Start Date", field: "startDate" },
		{ title: "Job End Date", field: "endDate" },
		{ title: "Employee Category", field: "employeeCategory" },
		{ title: "Bill Rate", field: "billingRate" },
		{ title: "Pay Rate", field: "payRate" },
		{ title: "Pay Percentage", field: "payPercentage" },
		{ title: "Discount", field: "discount" },
		{ title: "Effective Until", field: "effectiveUntil" },
		{ title: "Invoice Frequency", field: "invoiceFrequency" },
		{ title: "Hours", field: "hrs" },
		{ title: "Missing Days", field: "missingDays" },
		{
			title: "Last submission date",
			field: "lastSubmission",
			cellClass: "custom-chip c-bg-sky text-dark",
			render: (row) => {
				return (
					<TimesheetHover
						data={row.submissionData}
						columnNameToDisplay="submissionDate"
					/>
				);
			},
		},
		{
			title: "Last approval date",
			field: "lastApproval",
			cellClass: "custom-chip c-bg-sky text-dark",
			render: (row) => {
				return (
					<TimesheetHover
						data={row.submissionData}
						columnNameToDisplay="approvedAt"
					/>
				);
			},
		},
		{ title: "Submitted Hours", field: "submittedHours" },
		{ title: "Approved Hours", field: "approvedHours" },
		{ title: "Total Hours", field: "totalHours" },
		{ title: "Invoiced Pay", field: "invoicedPay" },
		{ title: "Total Earned Pay", field: "totalEarnedPay" },
		{ title: "Total Pay", field: "totalPay" },
		{
			title: "Bonus Pay",
			field: "bonusPay",
			render: (row) => (
				<AddBonusPay
					isFreezed={row.isFreezed}
					canEdit={!row.isFreezed && row.placementID === row.defaultPlacementId}
					employeeID={row.employeeID}
					reportingDate={row.reportingDate}
					bonusPayData={row.bonusPayData}
					bonusPay={row.bonusPay}
				/>
			),
		},
		{ title: "Insurance", field: "insurance" },
		{
			title: "Total Deductions",
			field: "totalDeductions",
			render: (row) => (
				<SelectDeductions
					canEdit={!row.isFreezed && row.placementID === row.defaultPlacementId}
					employeeID={row.employeeID}
					reportingDate={row.reportingDate}
					deductionsData={row.deductionsData}
					totalDeductions={row.totalDeductions}
				/>
			),
		},
		{ title: "Bonus", field: "bonus" },
	];

	const getRowHeight = React.useCallback((params) => {
		const { data } = params;
		let maxHeight = 35;
		const { workAuthData, employeeStatusData } = data;
		const workAuthDataLength = workAuthData?.length ?? 0;
		const employeeStatusDataLength = employeeStatusData?.length ?? 0;

		if (workAuthDataLength > 1) {
			const height = 35 * workAuthDataLength;
			maxHeight = height > maxHeight ? height : maxHeight;
		}

		if (employeeStatusDataLength > 1) {
			const height = 35 * employeeStatusDataLength;
			maxHeight = height > maxHeight ? height : maxHeight;
		}

		return maxHeight;
	}, []);

	const handleGenerateReport = async (values, actions) => {
		actions.setSubmitting(true);
		const { date } = values;
		const rDate = new Date(date);
		setEmployeeIds(values.employeeIds);
		setReportingDate(rDate);
		if (!date || isNaN(Date.parse(new Date(rDate)))) return;
		const month = getMonth(rDate);
		const year = getYear(rDate);
		const days = getDaysInMonth(new Date(year, month));
		const _dates = [];

		for (let i = 1; i <= days; i++) {
			const formDateFormat = CalendarFormatter.standardDateFormat(
				new Date(year, month, i)
			);
			_dates.push(formDateFormat);
		}

		setDates(_dates);
		const prevColumns = tableRef.current?.getColumnDefs();
		const updatedColumns = prevColumns
			.filter(
				(item) => !item.field.startsWith("DateOfMonth")
			)
			.map((item) => ({ ...item, cellClassRules: defaultCellClassRules }) ?? []);
		const newColumns = [
			...updatedColumns,
			...convertMaterialToAgGridColumns(
				_dates.map((date, index) => {
					return {
						title: date,
						field: `DateOfMonth${index}`,
						headerClass:
							new Date(date).getDay() === 0 || new Date(date).getDay() === 6
								? "bg-blue"
								: undefined,
						cellClassRules: datesCellClassRules,
					};
				})
			),
		];
		tableRef.current?.setColumnDefs(newColumns);
		await refetch();
		actions.setSubmitting(false);
	};

	const handleFreezePayroll = async () => {
		const selectedRows = tableRef.current?.getSelectedRows();
		if (!selectedRows?.length) return;
		const selectedData = selectedRows.map((item) => ({
			placementID: item.placementID,
			employeeID: item.employeeID,
			isFreezed: item.isFreezed,
			isNonWorking: item.isNonWorking,
		}));
		const alert = new AlertService();

		const filteredData = selectedData.filter((item) => item.isFreezed) ?? [];

		if (filteredData.length > 0) {
			const result = await alert.confirmMessage(
				"Selected employees are already freezed. Do you want remove them from the list?",
				`<div style="max-height: 300px; overflow-y: auto;">
					<table class="table table-striped table-bordered">
						<thead>
							<tr>
								<th>Employee ID</th>
								<th>Placement ID</th>
							</tr>
						</thead>
						<tbody>
							${filteredData
								.map(
									(item) => `
										<tr>
											<td>${item.employeeID}</td>
											<td>${item.placementID}</td>
										</tr>
									`
								)
								.join(" ")}
						</tbody>
					</table>
				</div>`
			);
			if (!result.isConfirmed) return;
		}

		const result = await alert.confirmMessage(
			"Are you sure you want to freeze payroll for selected employees?",
			`<div style="max-height: 300px; overflow-y: auto;">
				<table class="table table-striped table-bordered">
					<thead>
						<tr>
							<th>Employee ID</th>
							<th>Placement ID</th>
						</tr>
					</thead>
					<tbody>
						${selectedData
							.filter((item) => !item.isFreezed)
							.map(
								(item) => `
									<tr>
										<td>${item.employeeID}</td>
										<td>${item.placementID}</td>
									</tr>
								`
							)
							.join(" ")}
					</tbody>
				</table>
			</div>`
		);
		if (!result.isConfirmed) return;
		useFreezePayroll.mutate({
			data: {
				month: reportingDate.getMonth(),
				year: reportingDate.getFullYear(),
				ids: selectedData
					.filter((item) => !item.isFreezed)
					.map((item) => ({
						placementID: item.placementID,
						employeeID: item.employeeID,
						isNonWorking: item.isNonWorking,
					})),
			},
		});
	};

	const handleUnfreezePayroll = async () => {
		const selectedRows = tableRef.current?.getSelectedRows();
		if (!selectedRows?.length) return;
		const selectedData = selectedRows.map((item) => ({
			placementID: item.placementID,
			employeeID: item.employeeID,
			soaId: item.soaId,
		}));
		const alert = new AlertService();

		const filteredData = selectedData.filter((item) => !!item.soaId) ?? [];

		if (filteredData.length !== selectedData.length) {
			const result = await alert.confirmMessage(
				"Selected employees do not have SOA. Do you want to remove them from the list?",
				`<div style="max-height: 300px; overflow-y: auto;">
					<table class="table table-striped table-bordered">
						<thead>
							<tr>
								<th>Employee ID</th>
								<th>Placement ID</th>
							</tr>
						</thead>
						<tbody>
							${selectedData
								.filter((item) => !item.soaId)
								.map(
									(item) => `
										<tr>
											<td>${item.employeeID}</td>
											<td>${item.placementID}</td>
										</tr>
									`
								)
								.join(" ")}
						</tbody>
					</table>
				</div>`
			);
			if (!result.isConfirmed) return;
		}

		const result = await alert.confirmMessage(
			"Are you sure you want to unfreeze payroll for selected employees?",
			`<div style="max-height: 300px; overflow-y: auto;">
				<table class="table table-striped table-bordered">
					<thead>
						<tr>
							<th>Employee ID</th>
							<th>Placement ID</th>
						</tr>
					</thead>
					<tbody>
						${filteredData
							.map(
								(item) => `
									<tr>
										<td>${item.employeeID}</td>
										<td>${item.placementID}</td>
									</tr>
								`
							)
							.join(" ")}
					</tbody>
				</table>
			</div>`
		);
		if (!result.isConfirmed) return;
		useRollbackPayroll.mutate({
			data: {
				soaIds: filteredData.map((item) => item.soaId),
			},
		});
	};

	const onSelectionChange = React.useCallback(() => {}, []);

	const isRowSelectable = React.useMemo(() => {
		return (rowNode) => {
			return (
				!!rowNode.data?.employeeID &&
				(!!rowNode.data?.placementID || rowNode.data?.isNonWorking) &&
				(!rowNode.data?.isFreezed || rowNode.data?.canRollBackIfFreezed) &&
				(!!rowNode.data?.defaultPlacementId || rowNode.data?.isNonWorking) &&
				!rowNode.data?.isSoaPaid
			);
		};
	}, []);

	return (
		<Container fluid className="p-0 m-0 mt-3 mb-3">
			<Row>
				<Col xl={6}>
					<div className="d-flex gap-2">
						<GlobalSettings />
						<Button onClick={handleFreezePayroll} variant="primary">
							Freeze Payroll
						</Button>
						<Button onClick={handleUnfreezePayroll} variant="primary">
							Unfreeze Payroll
						</Button>
						<PayrollCheckerModal
							freezedRows={data?.filter(
								(item) =>
									item.isFreezed &&
									item.placementID === item.defaultPlacementId &&
									!item.isSoaPaid
							)}
							reportingDate={reportingDate}
						/>
					</div>
				</Col>
				<Col xl={6}>
					<Formik
						initialValues={{
							date: reportingDate,
							employeeIds: employeeIds,
						}}
						onSubmit={handleGenerateReport}
					>
						{({ values, isSubmitting }) => (
							<Form className="d-flex gap-3 align-items-center justify-content-end">
								<Field
									name="employeeIds"
									label="Employee"
									component={SelectFormField}
									isMulti
									options={Object.values(metaInfo.names)
										.filter(
											(item) =>
												item.status !== "suspended" &&
												item.type !== "staff" &&
												(item.employeeStatus === "Working" ||
													item.employeeStatus === "Bench" ||
													item.employeeStatus === "Training")
										)
										.map((item) => ({
											value: item.uid,
											label: item.name,
										}))}
									placeholder="Select at most 30 employees"
									styles={{
										control: (styles) => ({
											...styles,
											width: "250px",
										}),
									}}
								/>
								<Field
									name="date"
									label="Reporting Date"
									component={DatePickerField}
									dateFormat="MMMM yyyy"
									showMonthYearPicker
								/>
								<Button type="submit" disabled={isSubmitting || !values.date}>
									Generate Report
								</Button>
							</Form>
						)}
					</Formik>
				</Col>
			</Row>
			<FlairTable
				columns={columns}
				data={rows}
				tableRef={tableRef}
				isLoading={
					reportingDate
						? isLoading ||
						  useFreezePayroll.isLoading ||
						  useRollbackPayroll.isLoading
						: false
				}
				getRowHeight={getRowHeight}
				onSelectionChange={onSelectionChange}
				isRowSelectable={isRowSelectable}
				onCellClicked={(params) => params.node.setSelected(true)}
				excelStyles={excelStyles}
			/>
		</Container>
	);
}
