import {
	errorMsg,
	stopWaitMsg,
	successMsg,
	waitingMsg,
} from "./SnackBars/index";
import axios from "axios";
import firebase from "../config/fbConfig";

export class InterceptorService {
	constructor(axiosInstance) {
		this._axiosInstance = axiosInstance;
	}

	static get instance() {
		if (!this._instance) {
			this._instance = new InterceptorService(axios);
		}
		return this._instance;
	}

	addBearerToken() {
		axios.interceptors.request.use(async (config) => {
			console.log("[InterceptorService] addBearerToken");
			try {
				const token = await firebase.auth().currentUser.getIdToken();
				if (token) {
					config.headers.Authorization = `Bearer ${token}`;
				}
			} catch (error) {
				console.log("[InterceptorService] addBearerToken error", error);
			}
			return config;
		});
	}

	addRequestInterceptor() {
		this._axiosInstance.interceptors.request.use(
			async (config) => {
				if (!["get"].includes(config.method || "")) {
					waitingMsg("Please wait...");
				}
				try {
					const token = await firebase.auth().currentUser.getIdToken();
					if (token) {
						config.headers.Authorization = `Bearer ${token}`;
					}
				} catch (error) {
					console.log("[InterceptorService] addBearerToken error", error);
				}
				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);
		return this;
	}

	addResponseInterceptor() {
		this._axiosInstance.interceptors.response.use(
			(response) => {
				stopWaitMsg();
				if (!["get"].includes(response.config.method || "")) {
					if (
						response.data?.message &&
						typeof response.data.message === "string"
					) {
						successMsg(response.data.message);
					}
				}
				return response;
			},
			(error) => {
				stopWaitMsg();
				if (error.response) {
					if (error.response.data?.message) {
						errorMsg(error.response.data.message);
					} else {
						errorMsg("Something went wrong");
					}
				}
				return Promise.reject(error);
			}
		);
		return this;
	}
}
