export const ACTION = {
  CLIENT_INVOICES_SET_LISTENER: "CLIENT_INVOICES_SET_LISTENER",
  CLIENT_INVOICES_REMOVE_LISTENER: "CLIENT_INVOICES_REMOVE_LISTENER",

  LOAD_CLIENT_INVOICES_REQ: "LOAD_CLIENT_INVOICES_REQ",
  LOAD_CLIENT_INVOICES_SUCCESS: "LOAD_CLIENT_INVOICES_SUCCESS",
  LOAD_CLIENT_INVOICES_FAILURE: "LOAD_CLIENT_INVOICES_FAILURE",

  LOAD_INVOICE_PAYMENTS_HISTORY_REQ: "LOAD_INVOICE_PAYMENTS_HISTORY_REQ",
  LOAD_INVOICE_PAYMENTS_HISTORY_SUCCESS: "LOAD_INVOICE_PAYMENTS_HISTORY_SUCCESS",
  LOAD_INVOICE_PAYMENTS_HISTORY_FAILURE: "LOAD_INVOICE_PAYMENTS_HISTORY_FAILURE",

  LOAD_INVOICE_HISTORY_REQ: "LOAD_INVOICE_HISTORY_REQ",
  LOAD_INVOICE_HISTORY_SUCCESS: "LOAD_INVOICE_HISTORY_SUCCESS",
  LOAD_INVOICE_HISTORY_FAILURE: "LOAD_INVOICE_HISTORY_FAILURE",

}
