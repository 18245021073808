/* eslint-disable no-use-before-define */
import React from "react"
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import Popper from "@material-ui/core/Popper"
import SettingsIcon from "@material-ui/icons/Settings"
import CloseIcon from "@material-ui/icons/Close"
import DoneIcon from "@material-ui/icons/Done"
import { Autocomplete } from "@material-ui/lab"
import ButtonBase from "@material-ui/core/ButtonBase"
import InputBase from "@material-ui/core/InputBase"
import { Link } from "react-router-dom"
import useStyles from "../../../../Styles/TaskLabelsStyles"

const theme = createMuiTheme({
  //palette,
})

export default function Presentation(props) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [pendingValue, setPendingValue] = React.useState([])
  //const { labels, handleChange, taskLabels } = props
  //console.log(props)
  const { handleChange, task, project } = props
  let labels = Object.values(project.labels).filter((e) => e.isExist)
  let reqLabels
  if (task[0].labels) {
    reqLabels = task[0].labels
  } else {
    reqLabels = []
  }
  let taskLabels = reqLabels.length
    ? labels.filter((item) => reqLabels.includes(item.id))
    : []

  const handleClick = (event) => {
    setPendingValue(taskLabels)
    setAnchorEl(event.currentTarget)
  }
  console.log(taskLabels)
  const handleClose = (event, reason) => {
    if (reason === "toggleInput") {
      return
    }
    //setValue(pendingValue);
    handleChange("taskLabels", pendingValue)
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "github-label" : undefined

  return (
    <React.Fragment>
      <div className={classes.root}>
        <ButtonBase
          disableRipple
          className={classes.button}
          aria-describedby={id}
          onClick={handleClick}
        >
          <span>Labels</span>
          <SettingsIcon />
        </ButtonBase>
        {taskLabels.length
          ? taskLabels.map((label) => (
              <div
                key={label.name}
                className={classes.tag}
                style={{
                  backgroundColor: label.colorCode,
                  color: "white",
                }}
              >
                {label.name}
              </div>
            ))
          : ""}
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={classes.popper}
        onMouseDownCapture={(event) => event.preventDefault()}
      >
        <div className={classes.header}>Apply labels to this task</div>
        <Autocomplete
          open
          onClose={handleClose}
          id="tm-labels-task-labels"
          multiple
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
          }}
          value={pendingValue}
          onChange={(event, newValue) => {
            handleChange("taskLabels", newValue)
            setPendingValue(newValue)
          }}
          disableCloseOnSelect
          disablePortal
          renderTags={() => null}
          renderOption={(option, { selected }) => (
            <ThemeProvider theme={theme}>
              <DoneIcon
                className={classes.iconSelected}
                style={{ visibility: selected ? "visible" : "hidden" }}
              />
              <span
                className={classes.color}
                style={{ backgroundColor: option.colorCode }}
              />
              <div className={classes.text}>{option.name}</div>
              <CloseIcon
                className={classes.close}
                style={{ visibility: selected ? "visible" : "hidden" }}
              />
            </ThemeProvider>
          )}
          options={[...labels].sort((a, b) => {
            // Display the selected labels first.
            let ai = taskLabels.indexOf(a)
            ai = ai === -1 ? taskLabels.length + labels.indexOf(a) : ai
            let bi = taskLabels.indexOf(b)
            bi = bi === -1 ? taskLabels.length + labels.indexOf(b) : bi
            return ai - bi
          })}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <InputBase
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              className={classes.inputBase}
            />
          )}
        />
        <span className="p-6">
          <Link to={"/console/projects/" + project.id + "/labels"}>
            Edit Labels
          </Link>
        </span>
      </Popper>
    </React.Fragment>
  )
}

/*let labels = state.projectList.filter(project => project.id === state.project.id)[0].labels
  const tasklabels = state.taskList.filter(task => task.id === state.task.id)[0].labels
  let taskLabels = labels.filter(item => tasklabels.includes(item.id))*/
