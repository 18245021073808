import React from "react"
import SubmitTimesheet from "../Services/Timesheets/components/SubmitTimesheet/Index"
import validation from "../shared/validation"

function TimesheetSubmitPage(props) {
  return (
    <div>
      <SubmitTimesheet
        type={props.match.params.type}
        timesheetID={validation.getQueryParameter("timesheetID")}
        placementID={validation.getQueryParameter("placementID")}
        employeeID={validation.getQueryParameter("employeeID")}
        startDate={validation.getQueryParameter("startDate") || ""}
        endDate={validation.getQueryParameter("endDate") || ""}
      />
    </div>
  )
}

export default TimesheetSubmitPage
