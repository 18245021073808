import React, {useEffect, useState} from "react";
import { Row, Col,Card } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import DateFormField from "../../../../shared/components/BootStrapFormFields/DateFormField";
import { Animations } from "../../../../shared/skeletonLoader";
import { Skeleton } from "@material-ui/lab";
import { CalendarFormatter } from "../../../../shared/CalendarFormatter";
import { _get_expenses_graph_dashboard } from "../../middleware/expenseList";
import { _get_expenses_dashboard_count } from "../../middleware/expenseList";
import { _get_expenses_dashboard_Remaining_count } from "../../middleware/expenseList";
import HLExpenses from "./HLExpenses";
import ExpensesMonthsGraph from "./ExpensesMonthsGraph"
import Amounts from "./Amounts"
import Graphs from "./pie";
import RecentExpenses from "./RecentExpenses";


const schema = yup.object().shape({
  fromDate: yup.string().required("From Date is required"),
  toDate: yup.string().required("To Date is required"),
});


 const ExpensesDashboard = ({  setTabValue,...props }) => {
 const [ExpensesCountData , setExpensesCountData] = React.useState()
 const [ExpensesRemainingData , setExpensesRemainingData] = React.useState()
 const [ExpenseGraphData , setExpenseGraphData] = React.useState()
  const [fromDate, setFromDate] = useState(CalendarFormatter.mmddyyyyFormat(new Date(new Date().getFullYear(),0,1)))
  const [toDate, setToDate] = useState(CalendarFormatter.mmddyyyyFormat(new Date()))
  let initialDates = {
    fromDate,
    toDate,
  }
 
console.log('fromDate',initialDates.fromDate,initialDates.toDate)
  
    const getExpensesData = async () => {
    const ExpenseData= await _get_expenses_dashboard_count()
      setExpensesCountData(ExpenseData?.data)
    }
    console.log("ExpensesCountData",ExpensesCountData)
  
    const getExpensesRemainingData = async () => {
      const ExpenseData= await _get_expenses_dashboard_Remaining_count()
      setExpensesRemainingData(ExpenseData?.data)
      }

      console.log("ExpensesRemainingData",ExpensesRemainingData)

      const getExpenseGraphData = async (fromDate,toDate) => {
        const ExpenseData = await _get_expenses_graph_dashboard(fromDate,toDate)
        setExpenseGraphData(ExpenseData?.data)
        }

        console.log("ExpenseGraphData",ExpenseGraphData)

        useEffect(() => {
            getExpensesData()
            getExpensesRemainingData()
            getExpenseGraphData(initialDates.fromDate,initialDates.toDate)
          },[initialDates.fromDate,initialDates.toDate])

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    setFromDate(values.fromDate)
    setToDate(values.toDate) 
    getExpenseGraphData(values.fromDate, values.toDate)
    getExpensesData()
    getExpensesRemainingData()
    actions.setSubmitting(false);
  };




return (
    <div>
    <Row>
<Col>
<div className="d-flex" style={{justifyContent:"space-between"}}>
<h4 className="page-title">Expenses Dashboard</h4>
<Formik 
               validationSchema={schema}
               initialValues={{
               ...initialDates
               }}
               onSubmit={handleSubmit}>
                {
                  (formik) => {
                    return(
                  <Form>
                   <Row>
                  <Col>
                   <Field
                      name="fromDate"
                      type="text"
                      component={DateFormField}
                    />
                    </Col>
                    <Col style={{marginTop:6}}>
                    <h4 className="page-title">to</h4>
                    </Col>
                    <Col>
                    <Field
                      name="toDate"
                      type="text"
                      component={DateFormField}
                    />
                    </Col>
                    <Col>
                    <button
                    type='submit'
                    className="btn btn-success px-4 btn-md"
                    disabled={
                      !formik.isValid ||
                      formik.isSubmitting ||
                      !formik.dirty 
                    }
								>
                  Apply
                  </button>
                    </Col>
                   </Row>
                 </Form>
                    )
                  }
                }
              </Formik>

</div>
</Col>
</Row>

      <Row>
      <Col xl={4}>
        {ExpenseGraphData?
      <RecentExpenses  setTabValue={setTabValue} {...props} ExpenseGraphData={ExpenseGraphData?.Expenses__Dashboard_1}
       ExpensesCountData={ExpensesCountData?.Recent_Submitted_Expenses}
       />:
       <Skeleton animation='wave' variant="rounded" height={500} />
      }
      </Col>    

<Col xl={8}>
<div>
    {ExpenseGraphData?
    <Amounts ExpenseGraphData={ExpenseGraphData?.Expenses__Dashboard_1}/>
    :<Skeleton variant="rectangular" width={600} height={500} />
 }
</div>
<div>

{ExpenseGraphData?
<ExpensesMonthsGraph ExpenseGraphData={ExpenseGraphData} />
    :<Skeleton variant="rectangular" width={800} height={500} />
  }


</div>
<div>
<Row>
   
<Graphs ExpensesRemainingData={ExpensesRemainingData}/>

<HLExpenses ExpensesRemainingData={ExpensesRemainingData}/>
</Row>

</div>
</Col>
</Row> 
</div>
);
}
export default ExpensesDashboard