import React,{useEffect, useState} from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Dialog, Typography, DialogContent, Button, DialogActions, IconButton,TextField } from "@material-ui/core"
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Edit,Close } from "@material-ui/icons"
import { useNewInvoiceContext } from "./context"
import styles from "../../styles/dialogStyles"
import NumberFormat from 'react-number-format'
import { isWithinInterval } from 'date-fns';


function TimeEntryFormat(props) {
  const { services } = useNewInvoiceContext()
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      decimalScale={2}
      format="##:##"
      isAllowed={services.getformatTime}
      mask={['h', 'h', 'm', 'm']}
      placeholder="hh:mm"
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
    // isNumericString
    />
  );
}

function EditTimesheet(props) {
  const { state,services,stateSetters } = useNewInvoiceContext()
  const { timeSheetDetails, rate, employeeID, employeeName,type } = props
  const [open, setOpen] = useState(false);
  const [workedHours,setWorkedHours] =useState(props.workedHours)
  const [isDisable, setIsDisable] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false)
  const timeInHours = services.getTimeInHours(workedHours)
  

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    if(!isUpdating){
      setOpen(false);
    }
  };

  const updateTimesheetLocally = () =>{
    const date = timeSheetDetails.date
    let checkDateInCycle = null
    let invoiceFrequencies = JSON.parse(JSON.stringify(state.timesheetsAccordingToInvoiceFrequency)) 
    state.timesheetsAccordingToInvoiceFrequency.forEach((cycle,index) => {
      checkDateInCycle = isWithinInterval(new Date(date),{ start: new Date(cycle.cycleStartDate), end: new Date(cycle.cycleEndDate) })
      if(checkDateInCycle){
        invoiceFrequencies[index]={
            ...invoiceFrequencies[index],
            timesheets:{
              ...invoiceFrequencies[index].timesheets,
              [date]:{
                ...invoiceFrequencies[index].timesheets[date],
                [type]:workedHours
              }
            }
        }
      }
    })
    stateSetters.setSelectedTimesheets({
      ...state.selectedTimesheets,
      [date]:{
        ...state.selectedTimesheets[date],
        [type]:workedHours
      }
    })
    stateSetters.setTimesheetsAccordingToInvoiceFrequency(invoiceFrequencies)
    setIsUpdating(false)
    handleClose()
  }

  const setChangeTime = (time) => {
    const [h, m] = time.split(":");
    if ((isNaN(h) || Number(h) > 23) || (isNaN(m) || Number(m) > 59)){
      setIsDisable(true)
    }else{
      setIsDisable(false)
    }
    setWorkedHours(time)
  }

  const onUpdate = async () => {
    let payload = {
      date : timeSheetDetails.date,
      workedHours : workedHours,
      workType : type
    }
    let timesheetId = state.selectedTimesheets[timeSheetDetails.date].timesheetId
    try {
      setIsUpdating(true)
      await services.updateTimesheet(payload,timesheetId,updateTimesheetLocally)
    } catch (error) {
      setIsUpdating(false)
    }
  }



  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  return (
    <div>
      <IconButton onClick={handleClickOpen}><Edit /></IconButton>
      <Dialog
        open={open}
        aria-labelledby="customized-dialog-title">
        <DialogTitle 
        onClose={isUpdating?null:handleClose}
        id="customized-dialog-title">
          {timeSheetDetails.date}
        </DialogTitle>
        <DialogContent>
          <div className='d-flex p-1 justify-content-spacebetween'>
            <div style={keyFieldStyle}>Service Summary :</div>
            <div style={{color:"green",...keyValueStyle}}>{employeeName+"-"+employeeID}</div>
          </div>
          <div className='d-flex p-1 justify-content-spacebetween'>
            <div style={keyFieldStyle}>Rate :</div>
            <div style={keyValueStyle}>${rate}</div>
          </div>
          <div className='d-flex p-1 justify-content-spacebetween'>
            <div style={keyFieldStyle}>Worked Hours :</div>
            <div><TextField 
                  InputProps={{ inputComponent: TimeEntryFormat }} 
                  type="text" 
                  style={{width: '47px',fontWeight: "bolder"}} 
                  variant="standard" 
                  autoFocus 
                  onChange={(e) => setChangeTime(e.target.value)} 
                  value={workedHours} 
                />
            </div>
          </div>
          <div className='d-flex p-1 justify-content-spacebetween'>
            <div style={keyFieldStyle}>Amount for <span className='custom-chip chip-warning'>{workedHours}</span> :</div>
            <div style={keyValueStyle}>${(rate*timeInHours).toFixed(2)}</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={onUpdate} 
            variant="contained" 
            disabled = {isUpdating ? true : isDisable}
            color="secondary">
            {isUpdating ? "Updating" : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditTimesheet

const keyFieldStyle = {
  fontSize: "1.2rem",
  color: "black",
  fontWeight: 500,
  marginRight: "15px"
}

const keyValueStyle = {
  fontSize: "1rem",
  fontWeight: "bolder"
}