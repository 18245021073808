import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import Presentation from "./Presentation"
import { _set_state } from "../../../middleware/letterTemplate"
import { configuration } from "../../../../../config/companyConfig"
import { createTemplate, updateTemplate, deleteTemplate } from "../../../middleware/letterTemplate"

function Container(props) {
  const {
    _state,
    _set_state,
    letterTemplates,
    createTemplate,
    updateTemplate,
    deleteTemplate,
  } = props

  const [state, setState] = useState({
    content: "",
    type: "",
    name: "",
    helperText: [],
  })

  useEffect(() => {
    if (_state.updating) {
      setState((prevState) => {
        return {
          ...prevState,
          content: _state.content,
          name: _state.name,
          type: _state.type,
        }
      })
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          content: _state.defaultContent,
        }
      })
    }
  }, [])

  const handleContent = (data) => {
    setState((prevState) => ({ ...prevState, content: data }))
    let str = data.toString()
    let newArray = []
    let arr = str.split(" ")
    arr.forEach((element) => {
      var tmpStr = element.match("~(.*)~")
      if (tmpStr !== null) newArray.push(tmpStr[1])
    })
    setState((prevState) => ({
      ...prevState,
      helperText: newArray.filter((str) => !configuration.draggableLabels.includes(str)),
    }))
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleCreate = (e) => {
    e.preventDefault()
    const { name, content, type } = state
    createTemplate({ name, content, type })
  }

  const handleUpdate = (e) => {
    e.preventDefault()
    const { name, content, type } = state
    const payload = {
      content: content || _state.content,
      type: type || _state.type,
      name: name || _state.name,
    }
    updateTemplate(payload, _state.id)
  }

  const handleDelete = (e) => {
    e.preventDefault()
    deleteTemplate(_state.id, cancelUpdate)
  }

  const cancelUpdate = () => {
    const payload = {
      id: "",
      name: "",
      type: "",
      defaultContent: _state.defaultContent,
      content: _state.defaultContent,
      updating: false,
    }

    _set_state(payload)

    setState((prevState) => ({
      ...prevState,
      content: _state.defaultContent,
      type: "",
      name: "",
    }))
  }

  return (
    <div>
      <Presentation
        {...state}
        {...letterTemplates}
        state={_state}
        handleContent={handleContent}
        handleChange={handleChange}
        handleCreate={handleCreate}
        cancelUpdate={cancelUpdate}
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    _state: state.console.letterTemplates,
    letterTemplates: state.firestore.data.letterTemplates,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    _set_state: (payload) => {
      dispatch(_set_state(payload))
    },
    createTemplate: (payload) => {
      dispatch(createTemplate(payload))
    },
    updateTemplate: (payload, id) => {
      dispatch(updateTemplate(payload, id))
    },
    deleteTemplate: (id, callback) => {
      dispatch(deleteTemplate(id, callback))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
