import ACTION from "../actions/actionTypes"
import { initState } from "./clientProjectsState"

export function clientProjectsReducer(state = initState, action) {
  switch (action.type) {
    case ACTION.LOAD_CLIENT_PROJECTS_SUCCESS:
      return {
        ...state,
        placementSettings: action.payload,
        isLoaded: true,
        isEmpty: false,
        error: null,
      }

    case ACTION.LOAD_CLIENT_PROJECTS_FAILURE:
      return {
        ...state,
        placementSettings: [],
        isLoaded: true,
        isEmpty: true,
        error: action.err,
      }

    case ACTION.LOAD_CLIENT_PROJECTS_REQUEST:
      return {
        ...state,
        isLoaded: false,
        isEmpty: false,
      }

    default:
      return state
  }
}
