import React from 'react'
import { IconButton, Dialog, Typography, DialogContent, Button, DialogActions } from "@material-ui/core"
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline"
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import HighlightOff from "@material-ui/icons/HighlightOff"
import SunEditor from "suneditor-react"
import Skeleton from '@material-ui/lab/Skeleton';

function Presentation(props) {
  const { state,setState} = props
  // const metaInfo = new MetaInfo();

  if (!state.isSettingsLoaded)
    return (
      <div  >
       <div>
        <Typography component="div" variant={"h1"}>
          <Skeleton />
        </Typography>
        <Typography component="div" variant={"h3"}>
          <Skeleton />
        </Typography>
        <Typography component="div" variant={"h3"}>
          <Skeleton />
        </Typography>
        <Typography component="div" variant={"h3"}>
          <Skeleton />
        </Typography>
      </div>
      </div>
    )
  return (
    <div>
            Status Report:
            <br/>
            Update your weekly status report before submitting timesheets.
            <br/>
(Make sure to give atleast 100 characters and  what was done by you in the project)




          <div>
            <SunEditor
              placeholder={"Status Report*"}
              setContents={state.statusReport}
              id="Status-Repor"
              width="100%"
              height="100%"
              onChange={(data) => setState({ statusReport: data })}
              setOptions={{
                buttonList: [
                  [
                    "undo",
                    "redo",
                    "formatBlock",
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                    "fontColor",
                    "hiliteColor",
                    "removeFormat",
                    "outdent",
                    "indent",
                    "align",
                    "horizontalRule",
                    "list",
                    "lineHeight",
                    "table",
                    "link",
                  ],
                ],
              }}
            />
          </div>
       
    </div>
  )
}

export default Presentation
