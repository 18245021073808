import React, { useEffect } from "react"
import Button from "react-bootstrap/Button"
import { Link } from "react-router-dom"
import PlacementsList from "../Index"
import { useQuery } from "@tanstack/react-query"
import { getEmployee } from "../../../../../api/services/default/employee"
import ProspectPlacement from "../ProspectPlacement"

export default function Presentation(props) {
  const { id, loggedInEmployee, names} = props
  const status = names[id]?.status;
  const { data: employeeData = {} } = useQuery({
    queryKey: ["getEmployee", id],
    queryFn: () => getEmployee(id),
    enabled: !!id,
  });
  useEffect(() => {}, [employeeData]);
  const employeeStatus = employeeData?.employeeStatus;
  const NewPlacementAction = {
    icon: () => (
      <Link to={"/console/employees/" + id + "/newplacement"}>
        <Button className="btn btn-success px-4 btn-md text-high">
          New Placement
        </Button>
      </Link>
    ),
    tooltip: "New Placement",
    isFreeAction: true,
    position: "toolbar",
  };
  const NewPlacementDisableAction = {
    icon: () => <ProspectPlacement />,
    tooltip: "New Placement",
    isFreeAction: true,
    position: "toolbar",
  };
  return (
    <div>
      <PlacementsList
        NewPlacementAction={
          employeeStatus === "Prospect"
            ? NewPlacementDisableAction
            : loggedInEmployee !== id && status === "active"
            ? NewPlacementAction
            : {}
        }
        listAll={false}
        employeeId={id}
        clientView={false}
        clientId={null}
      />
    </div>
  );
}
