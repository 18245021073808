import React, { useEffect } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import { Tooltip, Fab, Grid, Paper } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
// import { DataContext } from '../../../contexts/data'
import TaskManager from "../../Tasks/TaskManager"
import SettingsTab from "../../ProjectHandleComponents/Settings"
import { Settings } from "@material-ui/icons"
import validate from "../../../../../shared/validation"
import { Link, Redirect } from "react-router-dom"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
//import useStyles from '../../Styles/ProjectHandlerStyles'
import Alert from "@material-ui/lab/Alert"
import ProjectDashBoard from "../../dashboards/ProjectDashBoard"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

// function a11yProps(index) {
//   return {
//     id: `vertical-tab-${index}`,
//     'aria-controls': `vertical-tabpanel-${index}`,
//   }
// }
const useSwipableStyles = makeStyles({
  list: {
    width: "auto",
  },
  fullList: {
    width: "auto",
  },
})

export default function Presentation(props) {
  // const classes = useStyles()
  const swipableClasses = useSwipableStyles()
  // const [value, setValue] = React.useState(0)

  const [swipableState, setSwipableState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })
  const { project, handleComponentUpdate, id, modules, EmpDetails, _load_project } = props

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return
    }

    setSwipableState({ ...swipableState, [anchor]: open })
  }

  // const handleChange = (event, newValue) => {
  //   setValue(newValue)
  // }

  let employee = {}
  let access = false
  //  const id = project[0].id
  let supervisorCount = 0
  try {
    const employees = Object.values(project[0].Users)
    employee = employees.filter((user) => user.uid === EmpDetails.uid)[0]
    if (employees.map((user) => user.uid).includes(EmpDetails.uid)) access = true
    else if (project[0].createdBy === EmpDetails.email) access = true
  } catch (error) {
    employee = {}
    access = false
  }
  //  checking supervisor count
  try {
    const employees = project[0].Users
    employees.forEach((member) => {
      if (validate.checkSupervisor(member.uid)) supervisorCount++
    })
  } catch (error) {}

  //console.log(employee)
  const list = (anchor) => (
    <div
      className={clsx(swipableClasses.list, {
        [swipableClasses.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <SettingsTab project={project} projectId={id} handleComponentUpdate={handleComponentUpdate} />
    </div>
  )

  if (
    EmpDetails.role === "admin" ||
    (access && (employee.create || employee.update || employee.read))
  )
    return (
      <div>
        {modules.includes("task-management-manager") ||
        modules.includes("console-customization") ? (
          <ProjectDashBoard projectId={props.match.params.projectId} />
        ) : (
          <Redirect to={`/console/projects/${props.match.params.projectId}/tasks?tab=0`} />
        )}
      </div>
    )
  return (
    <Alert className="text-center" variant="filled" severity="error">
      You are unauthorized to view this content
    </Alert>
  )
}
