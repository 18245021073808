import React from "react";
import EmployeeSettings from "./EmployeeSettings";

export default function GeneralReportActions({ row }) {
	return (
		<div className="d-flex align-items-center">
			<EmployeeSettings
				id={row.employeeID}
				reportingDate={row.reportingDate}
				workAuthData={row.workAuthData}
				isNonWorking={row.isNonWorking}
			/>
		</div>
	);
}
