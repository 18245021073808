import React, { useEffect, useState, useRef } from "react"
import Presentation from "./Presentation"
import { connect } from "react-redux"
import { getMetaInfoofEmployees, getModules } from "../../EmployeeManagment/middleware"
import { useDispatch, useSelector } from "react-redux";
import { getUserCustomClaims, onSignout } from "../../Authentication/middleware"
import { compose } from "redux"
import { firestoreConnect, isLoaded } from "react-redux-firebase"
import { attachTokenListener } from "../../Authentication/middleware/index"
import IdleTimer from "react-idle-timer"
import { useHistory } from "react-router-dom"
import ChangeTempPassword from "../../EmployeeManagment/components/ChangeTempPassword"
import { loadProfileTemplate } from "../../Console/middleware/profileBuilder"
import { loadEmployeeProfile } from "../../EmployeeManagment/middleware/dynamicProfileRender"
import CircularSpinner from "../../../shared/circularSpinner"
import { getMetaInfoofClients } from "../../Clients/middleware"

function Container(props) {
  const {
    isFetching,
    getModules,
    accessModules,
    names,
    clients_meta_info,
    companyPrefix,
    load_profile_template,
    get_meta_info_of_employees,
    get_meta_info_of_clients
  } = props
  const IdleTimeRef = useRef(null)
  const [isCustomClaimsLoaded, setIsCustomClaimsLoaded] = useState(false)
  const [claims, setClaims] = useState(null)
  const [state, setState] = useState({
    isTimedOut: false,
  })
  let employeeId = useSelector((state) => state.firebase.auth.uid);

  const dispatch = useDispatch();

  useEffect(() => {
    attachTokenListener();
    get_meta_info_of_employees()
    get_meta_info_of_clients()
		getModules();
		dispatch(loadEmployeeProfile(employeeId));
		load_profile_template();

		const fetchClaims = async () => {
			try {
				setIsCustomClaimsLoaded(false);
				const claims = await getUserCustomClaims();
        console.log({claims})
				setClaims(claims);
				setIsCustomClaimsLoaded(true);
			} catch (error) {
				console.error(error);
				setIsCustomClaimsLoaded(true);
			}
		};

		fetchClaims();
  }, [])

  const history = useHistory()

  const handleOnIdle = (event) => {
    // console.log("last active", idleTimer.getLastActiveTime())
    if (state.isTimedOut) {
      // localStorage.setItem('imageURL','')
      // localStorage.setItem('role','')
      // localStorage.setItem('email','')
      return setState({
        ...state,
        isTimedOut: true,
      })
    }
    props.onSignout(history)
  }
  const handleOnActive = (event) => {
    // console.log(event, "Active")
  }

  const handleOnAction = (e) => {
    //console.log(e, "action")
  }


  if (
    !isFetching &&
    accessModules.length &&
    Object.keys(names).length &&
    isLoaded(clients_meta_info) &&
    isLoaded(companyPrefix) &&
    isCustomClaimsLoaded
  ) {
    if (claims?.isResetted === true) {
      return <ChangeTempPassword uid={props.profile.uid} />
    }
    return (
      <>
        <IdleTimer
          ref={IdleTimeRef}
          onIdle={handleOnIdle}
          onActive={handleOnActive}
          onAction={handleOnAction}
          element={document}
          debounce={250}
          timeout={90 * 10000}
        />
        <Presentation {...props} />
      </>
    )
  }
  return (
    <CircularSpinner />
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    accessModules: state.employee.employeeModules.accessModules,
    isFetching: state.employee.employeeModules.isFetching,
    names: state.employee.employeeList.metaInfoofEmployees.data,
    clients_meta_info: state.client.clientsMetaInfo.metaInfoofClients.data,
    profile: state.firebase.profile,
    companyLogo: state.console.companyDetails["images"],
    companyPrefix: state.console.companyDetails["companyID"],
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSignout: (history) => {
      dispatch(onSignout(history))
    },
    getModules: () => {
      dispatch(getModules())
    },
    load_profile_template: () => {
      dispatch(loadProfileTemplate())
    },
    get_meta_info_of_employees: () => {
      dispatch(getMetaInfoofEmployees())
    },
    get_meta_info_of_clients: () => {
      dispatch(getMetaInfoofClients())
    }
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "META_INFO",
        doc: "employees",
        storeAs: "names",
      },
      {
        collection: "CLIENTS",
        where: [
          ["isExist", "==", true],
        ],
        storeAs: "clients_meta_info",
      },
    ]
  })
)(Container)
