import React from "react"
import {
  Typography,
  Grid,
  Divider,
  Tooltip,
  Fab,
  Paper,
  Avatar,
  Chip,
  IconButton,
  Card,
  CardContent,
} from "@material-ui/core"
import useStyles from "../../styles/viewArticleStyles"
import MetaInfo from "../../../../shared/getMetaInfo"
import { Link, useHistory } from "react-router-dom"
import validate from "../../../../shared/validation"
import parser from "html-react-parser"
import { HtmlTooltip } from "../../../../shared/toolTip"
import { blue } from "@material-ui/core/colors"
import { GoFileSymlinkFile } from "react-icons/go"
import {
  Edit,
  History,
  RestoreFromTrash,
  Email,
  RestorePage,
  Delete as DeleteIcon,
} from "@material-ui/icons"
import { FaRegThumbsUp, FaRegThumbsDown, FaFileExcel } from "react-icons/fa"
import Comments from "../Comments"

function Presentation(props) {
  const {
    data,
    isHistory,
    categoryMetaInfo,
    onDeleteArticle,
    articleId,
    onRestoreArticle,
    onFollowOrUnfollow,
    auth,
    OnVote,
    access_modules,
    onRevertArticle,
  } = props
  const classes = useStyles()
  const metaInfo = new MetaInfo()
  const history = useHistory()
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10}>
          <Paper className={classes.paper}>
            <div className="d-flex">
              <div>
                <Tooltip title={metaInfo.emailToName(data.createdBy)}>
                  <Avatar src={metaInfo.getImage(data.createdBy)} />
                </Tooltip>
              </div>
              <div className="ml-4">
                <Typography variant="h6">{data.title}</Typography>
                <div>
                  <Typography variant="caption">
                    UpdatedBy: <Chip size="small" label={metaInfo.emailToName(data.updatedBy)} />
                  </Typography>
                  <Typography variant="caption" className="ml-2">
                    UpdatedOn: {validate.dateAndTimeFormatter(data.updatedAt)}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={classes.grid}>
              {typeof data.content === "string" ? (
                <Typography variant="body1">{parser(data.content)}</Typography>
              ) : null}
            </div>
            {!isHistory ? (
              <React.Fragment>
                <Divider />
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography className="text-center" color="inherit">
                            I like this
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <IconButton onClick={(e) => OnVote(articleId, "upVotes")}>
                        {data.upVotes.includes(auth.uid) ? (
                          <FaRegThumbsUp style={{ color: blue[500] }} />
                        ) : (
                          <FaRegThumbsUp />
                        )}
                      </IconButton>
                    </HtmlTooltip>
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography className="text-center" color="inherit">
                            I dislike this
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <IconButton onClick={(e) => OnVote(articleId, "downVotes")}>
                        {data.downVotes.includes(auth.uid) ? (
                          <FaRegThumbsDown style={{ color: blue[500] }} />
                        ) : (
                          <FaRegThumbsDown />
                        )}
                      </IconButton>
                    </HtmlTooltip>
                  </div>
                  <div className="mt-2">
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography className="text-center" color="inherit">
                            When somebody makes an update here,you will get an email with details
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      {!data.following.includes(auth.uid) ? (
                        <IconButton
                          size="small"
                          onClick={(e) => onFollowOrUnfollow(articleId, "follow")}
                        >
                          <Email />
                          Follow
                        </IconButton>
                      ) : (
                        <IconButton
                          size="small"
                          onClick={(e) => onFollowOrUnfollow(articleId, "unfollow")}
                        >
                          <Email />
                          Unfollow
                        </IconButton>
                      )}
                    </HtmlTooltip>
                  </div>
                </div>
              </React.Fragment>
            ) : null}
          </Paper>
          <Grid item xs={12}>
            <Card className={classes.root}>
              <CardContent>
                <h2>Attachments:</h2>
                {data.hasOwnProperty("attachments")
                  ? data.attachments.map((doc, index) => (
                      <Grid item xs={12} key={index}>
                        <p>
                          <a target="_blank" rel="noopener noreferrer" href={doc.url}>
                            <GoFileSymlinkFile size={22} /> {doc.name}
                          </a>
                        </p>
                      </Grid>
                    ))
                  : null}
              </CardContent>
            </Card>
          </Grid>
          {!isHistory && data.isExist ? (
            <Grid item xs={12}>
              <Card className={classes.root}>
                <CardContent>
                  <h2>Comments:</h2>
                  <Comments articleId={articleId} />
                </CardContent>
              </Card>
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={2}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5" color="textSecondary">
                Filed Under
              </Typography>
              <br />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" style={{ textTransform: "capitalize" }}>
                {categoryMetaInfo.types[data.categoryId]
                  ? categoryMetaInfo.types[data.categoryId]
                  : categoryMetaInfo.archived[data.categoryId]}
              </Typography>
              <br />
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <br />
            </Grid>
            <React.Fragment>
              <Grid item xs={12}>
                <Typography variant="h5" color="textSecondary">
                  Created On
                </Typography>
                <br />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" style={{ textTransform: "capitalize" }}>
                  {validate.dateFormatter(data.createdAt)}
                </Typography>
                <br />
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <br />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" color="textSecondary">
                  Created By
                </Typography>
                <br />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" style={{ textTransform: "capitalize" }}>
                  {metaInfo.emailToName(data.createdBy)}
                </Typography>
                <br />
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <br />
              </Grid>
            </React.Fragment>
            {data.isExist ? (
              isHistory ? (
                <div>
                  <Tooltip title="Revert Changes">
                    <Fab
                      size="medium"
                      color="secondary"
                      onClick={() =>
                        onRevertArticle(
                          {
                            title: data.title,
                            content: data.content,
                            attachments: data.attachments,
                          },
                          articleId,
                          data.categoryId,
                          history
                        )
                      }
                      className={classes.history}
                    >
                      <RestorePage />
                    </Fab>
                  </Tooltip>
                </div>
              ) : access_modules.includes("wiki-manager") ||
                access_modules.includes("console-customization") ||
                auth.uid === data.createdBy ? (
                <div className="d-flex">
                  <div className="mr-4">
                    <Tooltip title="Edit Article">
                      <Link to={"/console/wiki/" + data.id + "/edit"} style={{ color: "white" }}>
                        <Fab size="medium">
                          <Edit />
                        </Fab>
                      </Link>
                    </Tooltip>
                  </div>
                  <div className="mr-4">
                    <Tooltip title="History">
                      <Link
                        to={"/console/wiki/" + data.id + "/history"}
                        style={{ color: "white" }}
                      >
                        <Fab size="medium" color="primary">
                          <History />
                        </Fab>
                      </Link>
                    </Tooltip>
                  </div>
                  <div>
                    <Tooltip title="Delete Article">
                      <Fab
                        size="medium"
                        color="secondary"
                        onClick={() => onDeleteArticle(articleId, history)}
                      >
                        <DeleteIcon />
                      </Fab>
                    </Tooltip>
                  </div>
                </div>
              ) : null
            ) : (
              <div>
                <Tooltip title="Restore article">
                  <Fab
                    size="medium"
                    color="secondary"
                    onClick={() => onRestoreArticle(articleId, data.categoryId, history)}
                    className={classes.history}
                  >
                    <RestoreFromTrash />
                  </Fab>
                </Tooltip>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Presentation
