import React, { useEffect } from "react"
import { connect } from "react-redux"
import { getPaymentsHistory, getInvoiceHistory } from "../../../../middleware"
import Presentation from "./Presentation"
import MetaInfo from "../../../../../../shared/getMetaInfo"
import parser from 'html-react-parser'

function Container(props) {
  const { history, isEmpty, isLoaded, onClickHistory, totalAmount, _get_invoice_history, state } = props
  const metaObj = new MetaInfo()

  useEffect(() => { }, [props.id])

  const getInformationBasedOnDataChange = (info = { eventDetails: {}, type: "" }) => {
    if (info.type === "madePayment") {
      return `Received amount $${info.paymentAmount}`
    }
    if (info.type === "invoiceCreated") {
      if('actionDesc' in info){
        return <span>{parser(info?.actionDesc)}</span>
      }else{
        return (
          <span>Created invoice {info?.subject?.invoiceID}</span>
        )
      } 
    }
    else if (info.type === "invoiceToVoid") {
      if('actionDesc' in info){
        return <span>{parser(info?.actionDesc)}</span>
      }else{
        return (
          <span>
            <span>Invoice Void {info?.subject?.invoiceID}</span>{' '}
            <span>{parser(
              'Void remarks' +
                (info?.eventDetails?.after?.voidReason
                  ? ' - ' + info?.eventDetails?.after.voidReason
                  : '')
            )}</span>
          </span>
        )
      }
    }
    else if (info.type === "sendInvoiceToClient") {
      if('actionDesc' in info){
        return <span>{parser(info?.actionDesc)}</span>
      }else{
        return(
          <span>
            Invoice sent to client {info?.subject?.invoiceID}
          </span>
        )
      }
    }
    else if (info.type === "reminderToClient") {
      if('actionDesc' in info){
        return <span>{parser(info?.actionDesc)}</span>
      }else{
        return(
          <span>
            Sent invoice remainder to client {info?.subject?.invoiceID}
          </span>
        )
      }
    }
    else if (info.type === "clientApproveInvoice") {
      if('actionDesc' in info){
        return <span>{parser(info?.actionDesc)}</span>
      }else{
        return(
          <span>
            Client Approved invoice {info?.subject?.invoiceID}
          </span>
        )
      }
    }
    else if (info.type === "clientRejectInvoice") {
      if('actionDesc' in info){
        return <span>{parser(info?.actionDesc)}</span>
      }else{
        return(
          <span>
            Client Rejected invoice {info?.subject?.invoiceID}
          </span>
        )
      }
    }
    else if (info.type === "invoiceUpdated") {
      if('actionDesc' in info){
        return <span>{parser(info?.actionDesc)}</span>
      }else{
        return (
          <span>Updated invoice {info?.subject?.invoiceID}</span>
        )
      }
    }
    else if (info.type === "invoiceToVoidReasonUpdated") {
      if('actionDesc' in info){
        return <span>{parser(info?.actionDesc)}</span>
      }else{
        return (
          <span>
            <span>Invoice follow up {info?.subject?.invoiceID}</span>{' '}
            <span>{parser(
              'Void remarks updated' +
                (info?.eventDetails?.after?.voidReason
                  ? ' - ' + info?.eventDetails?.after.voidReason
                  : '')
            )}</span>
          </span>
        )
      }
    }

    else if (info.type === "invoiceFollowup"){
      if('actionDesc' in info){
        return <span>{parser(info.actionDesc)}</span>
      }else{
        return (
          <span>
            <span>Invoice follow up {info.subject.invoiceID}</span>{' '}
            <span>{parser(
              'Follow up' +
                (info?.eventDetails?.after?.followupComment
                  ? ' - ' + info?.eventDetails?.after.followupComment
                  : '')
            )}</span>
          </span>
        )
      }
    }
    else if (info.type === "invoiceFollowupUpdated"){
      if('actionDesc' in info){
        return <span>{parser(info.actionDesc)}</span>
      }else{
        return (
          <span>
            <span>Invoice follow up {info.subject.invoiceID}</span>{' '}
            <span>{parser(
              'Follow up updated' +
                (info?.eventDetails?.after?.followupComment
                  ? ' - ' + info?.eventDetails?.after.followupComment
                  : '')
            )}</span>
          </span>
        )
      }
    }else if (info.type === "newPaymentForInvoice" || info.type === "updatePaymentForInvoice") {
      return <span>{parser(info.actionDesc)}</span>
    }

    return ""
  }

  return (
    <div>
      <Presentation
        history={history}
        isLoaded={isLoaded}
        isEmpty={isEmpty}
        onClickHistory={onClickHistory}
        totalAmount={totalAmount}
        _get_invoice_history={_get_invoice_history}
        state={state}
        getInformationBasedOnDataChange={getInformationBasedOnDataChange}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    history: state.payments.history.payments_history,
    isLoaded: state.payments.history.isLoaded,
    isEmpty: state.payments.history.isEmpty,
    state: state.payments.history
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClickHistory: () => {
      dispatch(getPaymentsHistory(ownProps.id))
    },
    _get_invoice_history: () => dispatch(getInvoiceHistory(ownProps.id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
