const ACTION = {
  SET_COMPANY_DETAILS: "SET_COMPANY_DETAILS",

  LOAD_COMPANY_DETAILS_REQ: "LOAD_COMPANY_DETAILS_REQ",
  LOAD_COMPANY_DETAILS_SUCCESS: "LOAD_COMPANY_DETAILS_SUCCESS",
  LOAD_COMPANY_DETAILS_FAILURE: "LOAD_COMPANY_DETAILS_FAILURE",

  LOAD_ALL_MODULES_REQ: "LOAD_ALL_MODULES_REQ",
  LOAD_ALL_MODULES_SUCCESS: "LOAD_ALL_MODULES_SUCCESS",
  LOAD_ALL_MODULES_FAILURE: "LOAD_ALL_MODULES_FAILURE",

  UPDATE_MODULES_REQ: "UPDATE_MODULES_REQ",
  UPDATE_MODULES_SUCCESS: "UPDATE_MODULES_SUCCESS",
  UPDATE_MODULES_FAILURE: "UPDATE_MODULES_FAILURE",

  SET_LETTER_CONTENT: "SET_LETTER_CONTENT",
}

export default ACTION
