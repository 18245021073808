import React from "react";
import { connect } from "react-redux";
import { updatePlacement } from "../../middleware/index";
import Notes from "../../../../shared/components/Notes";

function PlacementNotes(props) {
    const { rowData, _update_placement, refetch } = props;
    const { placementId, placementNotes, employeeId, employeeName } = rowData

    const callback = () => {
        if (typeof refetch === "function") {
            refetch();
        }
    }

    const closeTab = (tab) => { }

    const handleExcuteNotesFunctions = async (notesState) => {
        let payload = {
            placementNotes:notesState
        }
        _update_placement(
            payload,
            "general",
            employeeId,
            placementId,
            callback,
            closeTab
        )
    };

    return (
        <Notes notes = {placementNotes} notesName={employeeName} handleExcuteNotesFunctions = {handleExcuteNotesFunctions}/>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        _update_placement: (
            payload,
            sectionName,
            uid,
            placementID,
            callback,
            closeTab
        ) => {
            dispatch(updatePlacement(
                payload,
                sectionName,
                uid,
                placementID,
                callback,
                closeTab
            ))
        }
    }
}

export default connect(null, mapDispatchToProps)(PlacementNotes)