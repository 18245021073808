import React from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import {
  IconButton,
  Tooltip,
  ButtonGroup,
  // Button,
  // Chip,
} from "@material-ui/core";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import { isLoaded } from "react-redux-firebase";
import EditLocation from "../EditLocation";

function Presentation(props) {
  const { locations, deleteLocation, client } = props;

  const columns = [
    { title: "Line1", field: "locationsline1" },
    { title: "Line2", field: "locationsline2" },
    { title: "City", field: "locationscity" },
    { title: "State", field: "locationsstate_name" },
    { title: "Country", field: "locationscountry" },
    { title: "Zip", field: "locationszip" },
    {
      title: "Actions",
      field: "",
      render: (row) => (
        <div className="d-flex">
          {/* 
          <IconButton onClick={() => onDeleteLocation(row.id)}>
            <DeleteIcon />
          </IconButton> */}
          <EditLocation
            btnContent={<EditIcon />}
            clientId={props.client.id}
            location={row}
            edit={true}
          />
          <IconButton
            onClick={(e) =>
              deleteLocation({ clientId: client.id, locationId: row.id })
            }
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  let data = [];
  locations &&
    locations.forEach((location) => {
      data.push({
        ...location,
      });
    });
  return (
    <div>
      <MaterialTable
        title=""
        isLoading={!isLoaded(locations)}
        data={data}
        columns={columns}
        options={{
          paginationPosition: "top",
          pageSize: 10,
          paginationType: "normal",
          filtering: true,
          selection: true,
          columnsButton: true,
        }}
        components={{
          Toolbar: (tableProps) => (
            <div>
              <MTableToolbar {...tableProps} />
              <div style={{ padding: "0 10px" }}>
                <Tooltip title="Add Location">
                  <ButtonGroup
                    variant="contained"
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <EditLocation
                      location={{}}
                      btnContent={<AddCircleOutlineIcon />}
                      clientId={props.client.id}
                      edit={false}
                    />
                  </ButtonGroup>
                </Tooltip>
              </div>
            </div>
          ),
        }}
      />
    </div>
  );
}

export default Presentation;

// const emptyValues = {
//   locationsline1: "",
//   locationsline2: "",
//   locationscity: "",
//   locationsstate_name: "",
//   locationscountry: "",
//   locationszip: "",
// }
