export default {
  profileTemplate: {
    isLoading: true,
    data: {},
    error: ""
  },
  employeeProfile: {
    isLoading: true,
    data: {},
    error: "",
    isUpdating: false,
    errorWhileUpdating: ""
  },
  editingSection: {
    access_key: "",
    sortPriority: null,
    fields: [],
    sectionName: "",
  },
  editingIndex: null,
  editingData: {},
  saveWorkAuth: {
    isLoading: false,
    error: "",
  },
}