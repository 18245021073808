import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import Presentation from "./Presentation"
import { addSectionToPlacement, getLocations, updatePlacement } from "../../../../../middleware"

function Container(props) {
  const {
    item,
    clients,
    clients_list,
    tabList,
    index,
    addSectionToPlacement,
    updatePlacement,
    fillableSections,
    uid,
    id,
    clients_locations_state,
    getclientLocations,
    submitClientDetails,
    employeeCategory
  } = props

  const [reqClient, setReqClient] = useState({})
  const [isEnableBtn, setisEnableBtn] = useState(false)

  useEffect(() => {
    if (item.clientId) {
      setReqClient(clients[item.clientId])
      getLocations(item.clientId)
    }
  }, [item, reqClient, clients])

  useEffect(() => {
    let condition = (employeeCategory === "C2C") ? (tabList.map((tab) => tab.clientType)).includes("Sub-Contractor") : true
    setisEnableBtn(condition)
  },[JSON.stringify(tabList)])

  const getLocations = (value) => {
    getclientLocations(value)
  }


  const callback = () => { }

  const handleChange = (event) => {
    const { name, value } = event.target
    let data = tabList
    data[index] = {
      ...data[index],
      [name]: value,
    }

    props.handleChange(data)
  }

  const handleCheck = (event) => {
    const { name, checked } = event.target
    let data = tabList
    data[index] = {
      ...data[index],
      [name]: checked,
    }

    props.handleChange(data)
  }

  const onChange = (key, value) => {
    let data = tabList
    data[index] = {
      ...data[index],
      [key]: value,
    }

    props.handleChange(data)
  }

  const handleSubmit = (e) => {
    //props.handleChange("panel5")
    const { closeTab } = props
    e.preventDefault()
    // const client = {}
    // Object.entries(item).forEach(([key, value]) => {
    //   if (key !== "id" && key !== "key" && key !== "selectAddressList" && key !== "isDraft") {
    //     client[key] = value
    //   }
    // })
    // if (fillableSections.includes("client_details")) {
    //   addSectionToPlacement(
    //     { clients: { [client.clientId]: { ...client } } },
    //     "client_details",
    //     uid,
    //     id,
    //     callback,
    //     closeTab
    //   )
    // } else {
    //   console.log("update")
    //   updatePlacement({ client }, "client_details", uid, id, callback, closeTab)
    // }
    submitClientDetails(e)
  }

  return (
    <Presentation
      handleChange={handleChange}
      handleCheck={handleCheck}
      reqClient={reqClient}
      clients_list={clients_list}
      clientIds={tabList.map((item) => item.clientId)}
      item={item}
      onChange={onChange}
      handleSubmit={handleSubmit}
      clients_locations_state={clients_locations_state}
      isEnableBtn={isEnableBtn}
    />
  )
}

const mapStateToProps = (state, ownProps) => {
  const { data } = state.firestore
  return {
    clients: data.clients_meta_info ? data.clients_meta_info : {},
    clients_list: data.clients_meta_info
      ? Object.values(data.clients_meta_info)
      : [],
    clients_locations_state: state.placement.clientlocations,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addSectionToPlacement: (payload, sectionName, uid, placementID, callback, closeTab) => {
      dispatch(addSectionToPlacement(payload, sectionName, uid, placementID, callback, closeTab))
    },
    updatePlacement: (payload, sectionName, uid, placementID, callback, closeTab) => {
      dispatch(updatePlacement(payload, sectionName, uid, placementID, callback, closeTab))
    },
    getclientLocations: (clientId) => {
      dispatch(getLocations(clientId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
