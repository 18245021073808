import React from "react";
import {
  Grid,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import validate from "../../../../../../../shared/validation";
import CountryField from "../../../../../../../shared/components/Geo/CountryField";
import StateField from "../../../../../../../shared/components/Geo/StateField";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
function Presentation(props) {
  const {
    location,
    handleChange,
    onSubmit,
    handleKeyValuePair,
    handleInvoiceLocation,
    invoiceLocation,
  } = props;
  console.log(invoiceLocation);
  return (
    <div className="custom-card">
      <form onSubmit={onSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <CountryField
              handleChange={(e) =>
                handleKeyValuePair("locationscountry", e.target.value)
              }
              label="Country"
              required={true}
              value={location.locationscountry}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Address Line 1"
              name="locationsline1"
              value={location.locationsline1}
              id="new-client-location-locationsline1"
              variant="outlined"
              helperText={
                location.locationsline1.length
                  ? validate.checkAddress(location.locationsline1)
                    ? ""
                    : "Enter valid address"
                  : ""
              }
              size="small"
              required
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Address Line 2"
              name="locationsline2"
              value={location.locationsline2}
              id="new-client-location-locationsline2"
              helperText={
                location.locationsline2.length
                  ? validate.checkAddress(location.locationsline2)
                    ? ""
                    : "Enter valid address"
                  : ""
              }
              variant="outlined"
              size="small"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="City"
              name="locationscity"
              value={location.locationscity}
              id="new-client-location-locationscity"
              helperText={
                location.locationscity.length
                  ? validate.checkAddress(location.locationscity)
                    ? ""
                    : "Enter valid address"
                  : ""
              }
              variant="outlined"
              size="small"
              required
              fullWidth
              onChange={handleChange}
            />
            {/* <CountryField
              handleChange={(e) =>
                handleKeyValuePair("locationscountry", e.target.value)
              }
              label="Country"
              required={true}
              value={location.locationscountry}
            /> */}
          </Grid>
          <Grid item xs={4}>
            <StateField
              handleChange={(e) => handleKeyValuePair("locationsstate_name", e.target.value)}
              label="State"
              required={true}
              value={location.locationsstate_name}
              country={location.locationscountry}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Zip code"
              name="locationszip"
              value={location.locationszip}
              id="new-client-location-locationszip"
              helperText={
                location.locationszip.length
                  ? validate.checkZip(location.locationszip)
                    ? ""
                    : "Enter valid zip code"
                  : ""
              }
              variant="outlined"
              size="small"
              required
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={<Checkbox checked={invoiceLocation} />}
              fullWidth
              label="invoice Location"
              value={invoiceLocation}
              onClick={handleInvoiceLocation}

              // checked={state["includeInvoicePDF"]}
            />
          </Grid>
        </Grid>
        <br />
        <p>Note:Click Save Button to Save your Locations Data</p>
        <div className="text-center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              !location.locationsline1.trim() ||
              !location.locationscountry.trim() ||
              !location.locationscity.trim() ||
              !location.locationsstate_name.trim() ||
              !validate.checkZip(location.locationszip)
            }
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Presentation
