import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import CircularSpinner from "../../../../shared/circularSpinner";
import { getExpiredList, onHold, onEnable ,onReviewSuspended} from "../../MiddleWare";
import Presentation from "./Presentation";

function Container(props) {
  const { isLoaded } = props;
  let employeeId = useSelector((state) => state.firebase.auth.uid);

  const dispatch = useDispatch();
  let isManager = true;
  useEffect(() => {
    dispatch(getExpiredList(isManager));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onUpdate = (data) => {
    console.log(data);
  };
  const onReview = (data,uid,date) => {
    console.log("sn",data);
    dispatch(onReviewSuspended(data,uid,date))
  }
  const onIgnore = (data) => {
    console.log(data);
  };

  const onEnableAccount = (data) => {
    console.log(data);
    dispatch(onEnable(data.Id));
  };
  const onHoldAccount = (data) => {
    console.log(data);
    dispatch(onHold(data.Id));
  };

  if (isLoaded)
    return (
      <div>
        <Presentation
          {...props}
          onUpdate={onUpdate}
          onIgnore={onIgnore}
          onReview={onReview}
          onHoldAccount={onHoldAccount}
          onEnableAccount={onEnableAccount}
          employeeId={employeeId}
        />
      </div>
    );
  return (
    <CircularSpinner />
  );
}
const mapStateToProps = (state, ownProps) => {
  return {
    ExpiredListData: state.ExpiredDocs.expiredList.expiredItems,
    isLoaded: state.ExpiredDocs.expiredList.isLoaded,
  };
};
export default compose(connect(mapStateToProps))(Container);
