const ACTION = {
  SET_STATE: "SET_STATE",

  SET_LISTENER: "SET_LISTENER",

  UNSUBSCRIBE_LISTENER: "UNSUBSCRIBE_LISTENER",

  REQUEST_LETTER_REQUEST: "REQUEST_LETTER_REQUEST",
  REQUEST_LETTER_SUCCESS: "REQUEST_LETTER_SUCCESS",
  REQUEST_LETTER_FAILURE: "REQUEST_LETTER_FAILURE",

  LOAD_ALL_REQUEST_LETTER_REQUEST: "LOAD_ALL_REQUEST_LETTER_REQUEST",
  LOAD_ALL_REQUEST_LETTER_SUCCESS: "LOAD_ALL_REQUEST_LETTER_SUCCESS",
  LOAD_ALL_REQUEST_LETTER_FAILURE: "LOAD_ALL_REQUEST_LETTER_FAILURE",

  LOAD_PENDING_REQUEST_LETTER_REQUEST: "LOAD_PENDING_REQUEST_LETTER_REQUEST",
  LOAD_PENDING_REQUEST_LETTER_SUCCESS: "LOAD_PENDING_REQUEST_LETTER_SUCCESS",
  LOAD_PENDING_REQUEST_LETTER_FAILURE: "LOAD_PENDING_REQUEST_LETTER_FAILURE",

  LOAD_REJECTED_REQUEST_LETTER_REQUEST: "LOAD_REJECTED_REQUEST_LETTER_REQUEST",
  LOAD_REJECTED_REQUEST_LETTER_SUCCESS: "LOAD_REJECTED_REQUEST_LETTER_SUCCESS",
  LOAD_REJECTED_REQUEST_LETTER_FAILURE: "LOAD_REJECTED_REQUEST_LETTER_FAILURE",

  LOAD_ISSUED_REQUEST_LETTER_REQUEST: "LOAD_ISSUED_REQUEST_LETTER_REQUEST",
  LOAD_ISSUED_REQUEST_LETTER_SUCCESS: "LOAD_ISSUED_REQUEST_LETTER_SUCCESS",
  LOAD_ISSUED_REQUEST_LETTER_FAILURE: "LOAD_ISSUED_REQUEST_LETTER_FAILURE",

  HTML_CONTENT_REQUEST: "HTML_CONTENT_REQUEST",
  HTML_CONTENT_SUCCESS: "HTML_CONTENT_SUCCESS",
  HTML_CONTENT_FAILURE: "HTML_CONTENT_FAILURE",

  REJECT_LETTER_REQUEST_REQUEST: "REJECT_LETTER_REQUEST_REQUEST",
  REJECT_LETTER_REQUEST_SUCCESS: "REJECT_LETTER_REQUEST_SUCCESS",
  REJECT_LETTER_REQUEST_FAILURE: "REJECT_LETTER_REQUEST_FAILURE",

  APPROVE_LETTER_REQUEST_REQUEST: "APPROVE_LETTER_REQUEST_REQUEST",
  APPROVE_LETTER_REQUEST_SUCCESS: "APPROVE_LETTER_REQUEST_SUCCESS",
  APPROVE_LETTER_REQUEST_FAILURE: "APPROVE_LETTER_REQUEST_FAILURE",
}

export default ACTION
