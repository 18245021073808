import React from "react";
import { Modal } from "react-bootstrap";
import { useDialog } from "../../hooks/useDialog";
import FlairTable from "../../shared/FlairTable";
import AppModalHeader from "../../shared/components/Modals/AppModalHeader";
import { FaEye } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
import { getAllDeductionTransactions } from "../../api/services/default/deductions";

export default function TransactionsList({ deductionId }) {
	const { open, handleClose, handleOpen } = useDialog();
	const { data: transactions, isLoading } = useQuery({
		queryKey: ["getAllDeductionTransactions", deductionId],
		queryFn: () => getAllDeductionTransactions(deductionId),
		enabled: !!deductionId && open,
	});

	const columns = [
		{ title: "Deducted On", field: "deductedOn" },
		{ title: "Deducted Amount", field: "deductedAmount" },
		{ title: "Remaining balance", field: "remainingBalance" },
	];

	return (
		<>
			<button className="btn mt-1" onClick={handleOpen}>
				<FaEye style={{ fontSize: "18px" }} className="text-success" />
			</button>

			<Modal show={open} onHide={handleClose} centered size="xl">
				<AppModalHeader title="Transaction History" />
				<Modal.Body style={{ height: "80vh" }}>
					<div style={{ height: "100%", overflowY: "auto" }}>
						<FlairTable
							columns={columns}
							isToolBar={false}
							isLoading={isLoading}
							data={transactions}
						/>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
