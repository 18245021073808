import React from "react"
import EditArticle from "../Services/Wiki/components/NewArticle"
import { Breadcrumbs } from "@material-ui/core"
import { Link } from "react-router-dom"

function EditArticlePage(props) {
  return (
    <div>
      <div>
        <EditArticle isEdit={true} {...props.match.params} />
      </div>
    </div>
  )
}

export default EditArticlePage
