import React from 'react'
import InvoicePreview from '../Services/Invoices/components/InvoicePreview/index'

function InvoicePreviewPage() {
  return (
    <div>
        <InvoicePreview />
    </div>
  )
}

export default InvoicePreviewPage