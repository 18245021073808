import React, { useEffect } from "react";
import { connect } from "react-redux";
import { onLogin, MASTER_URL } from "../../middleware";
import Presentation from "./Presentation";
import { configuration } from "../../../../config/companyConfig";

function Container(props) {
  useEffect(() => {
    if (!configuration.isSelfHosted)
      return window.open(`${MASTER_URL}/signout`, "_self");
  }, []);
  return (
    <div>
      <Presentation {...props} />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  console.log(state);
  return {
    auth: state.firebase.auth,
    companyLogo: state.console.companyDetails["images"]
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onLogin: (creds) => {
      dispatch(onLogin(creds));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
