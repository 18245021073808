import React, {  useState } from "react"
import { Nav, Tab } from "react-bootstrap";
import PropTypes from "prop-types"
import ExpenseList from "../ExpenseTable"
import { Link, useHistory } from "react-router-dom"
import TabPanel from "../../../../../shared/tabPanel"
import DashBoard from '../../../../Expenses/components/DashBoard/index'
import validations from "../../../../../shared/validation";

export default function TabsView(props) {
  const {  modules, listAll, pendingRanges, approvedRanges, rejectedRanges, myCount } = props
  const tab = Number(validations.getQueryParameter("tab"))
  const isManager = modules.includes("timesheets-manager") || modules.includes("console-customization");
  const [tabValue, setTabValue] = useState(tab)
  const history = useHistory();
  const pathName = window.location.pathname

  const setTabChangeValue = (newValue) => {
    history.push(`${pathName}?tab=${newValue}`)
    setTabValue(newValue);
  }

  React.useEffect(() => {
    setTabValue((prevTab) => !listAll || !isManager ? "1" : prevTab)
  }, [listAll])
  return (
    <Tab.Container activeKey={tabValue} onSelect={setTabChangeValue}>
    <Nav as="ul" justify variant="pills" className="">
      {
        isManager && listAll && (
      <Nav.Item as="li" key="0">
        <Nav.Link className="cursor-pointer" eventKey={"0"}>
          <span className="d-sm-block">Dashboard</span>
        </Nav.Link>
      </Nav.Item>
        )
      }
      <Nav.Item as="li" key="1">
        <Nav.Link className="cursor-pointer" eventKey={"1"}>
          <span className="d-sm-block">Submitted</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item as="li" key="2">
        <Nav.Link className="cursor-pointer" eventKey={"2"}>
          <span className="d-sm-block">Approved</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item as="li" key="3">
        <Nav.Link className="cursor-pointer" eventKey={"3"}>
          <span className="d-sm-block">Rejected</span>
        </Nav.Link>
      </Nav.Item>
      {(modules.includes("timesheets-manager") && listAll) ||
          (modules.includes("console-customization") && listAll) ? (
      <Nav.Item as="li" key="4">
        <Nav.Link className="cursor-pointer" eventKey={"4"}>
          <span className="d-sm-block">My Expenses</span>
        </Nav.Link>
      </Nav.Item>
         ) : null}
    </Nav>
    <Tab.Content className="text-muted">
      {
        isManager && listAll && (
      <Tab.Pane eventKey={"0"}>    
      <DashBoard setTabValue={setTabValue} {...props} />
      </Tab.Pane>
        )
      }
      <Tab.Pane eventKey={"1"}>
      <ExpenseList condition={0}  {...props} />
      </Tab.Pane>
      <Tab.Pane eventKey={"2"}>
      <ExpenseList condition={1} {...props} />
      </Tab.Pane>
      <Tab.Pane eventKey={"3"}>
      <ExpenseList condition={2} {...props} />
      </Tab.Pane>
      {(modules.includes("timesheets-manager") && listAll) ||
          (modules.includes("console-customization") && listAll) ? (
      <Tab.Pane eventKey={"4"}>
      <ExpenseList condition={3} {...props} />
      </Tab.Pane>
            ) : null}
    </Tab.Content>
  </Tab.Container>
  )
}
